import { Button, Col, Form, Row, UploadFile, UploadProps, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import Upload, { RcFile } from "antd/lib/upload";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchKnowledgeBankDocumentProcessFile,
  fetchKnowledgeBankDocumentUploadSignedUrl,
} from "../../../redux/reducers/knowledge-bank.slice";
import {
  convertFileSizeFromBytes,
  readAsArrayBufferAsync,
} from "../../../shared/Utils/utilities";
import PopupMessageComponent from "../../../shared/layouts/message.layout";

function UploadDocumentComponent({
  formVisible,
  setFormVisible,
  reloadList,
  knowledge_bank_id,
}: any) {
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListData, setFileListData] = useState([] as any);
  const [errorFileListData, setErrorFileListData] = useState([] as any);
  const [uploading, setUploading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false as any);
  const [modalType, setModalType] = useState("" as any);
  const [modalButtons, setModalButtons] = useState((<></>) as any);
  const [modalTitle, setModalTitle] = useState("" as any);
  const [modalSubTitle, setModalSubTitle] = useState("" as any);

  const handleUpload = async () => {
    try {
      const file: any = fileListData?.[0]?.file;
      const response = await dispatch(
        fetchKnowledgeBankDocumentUploadSignedUrl({
          filename: file?.name,
          knowledge_bank_id: knowledge_bank_id,
        })
      );

      if (!response?.payload?.data?.url) {
        removeFile();
        message.error("Something went wrong.", 10);
      }
      const { url, file_id: upload_id } = response?.payload?.data;
      try {
        const binaryFile = await readAsArrayBufferAsync(file as RcFile);
        setUploading(true);
        const payload: any = {
          headers: {
            "Content-Type": file.type || "",
            // "x-ms-blob-type": "BlockBlob",
          },
          method: "PUT",
          data: binaryFile,
          url: url,
          timeout: 0,
        };
        const fileUploaded = await axios.request(payload);
        if (fileUploaded.status === 200 || fileUploaded.status === 201) {
          setUploading(false);
          setFileListData([
            { file, upload_id, url, fileUploaded: fileUploaded },
          ]);
          startValidation(upload_id);
        }
      } catch (e) {
        removeFile();
        setUploading(false);
        message.error("Something went wrong.", 10);
      }
    } catch (e) {
      removeFile();
      setUploading(false);
      message.error("Something went wrong.", 10);
    }
  };
  const removeFile = () => {
    setFileList([]);
    setFileListData([]);
    setErrorFileListData([]);
  };
  const startValidation = async (file_id: string) => {
    const res = await dispatch(fetchKnowledgeBankDocumentProcessFile(file_id));
    if (res?.payload?.status == 200) {
      openProcessingModal();
    } else {
      message.error("Some error occured while uploading file", 10);
    }
  };
  const props: UploadProps = {
    showUploadList: false,
    onRemove: (file) => {
      removeFile();
    },
    beforeUpload: (file) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 2 MB in bytes
      if (file.size > maxSizeInBytes) {
        message.warning("File size must be up to 10 MB", 10);
        setErrorFileListData([{ file }]);
        return false;
      }
      if (fileListData.length >= 2) {
        message.warning("You can only upload max 1 files", 10);
        return false;
      }
      const { name } = file;
      let ext: any = name?.split(".");
      ext = ext?.length > 0 ? ext[ext.length - 1] : "";
      if (["txt", "pdf", "ppt", "pptx"].includes(ext)) {
      } else {
        message.error(
          "Uploaded file extension should be .txt, .pdf, .ppt or .pptx",
          10
        );
        return;
      }

      setFileList([file]);
      setFileListData([{ file }]);
      // handleUpload(file, ext);
      return false;
    },
    maxCount: 1,
    fileList,
  };

  const closeModalPopup = () => {
    setModalVisible(false);
    setModalType("");
    setModalButtons(<></>);
    setModalSubTitle("");
    setFormVisible(false);
    setModalTitle("");
    reloadList();
  };
  const openProcessingModal = () => {
    setModalTitle("Processing");
    setModalType("processing");
    setModalSubTitle(
      <div className="text-center">
        File has been uploaded successfully and is under processing. <br />
        Incase of any issues, Error file will be generated.
      </div>
    );
    setModalButtons(<></>);
    setModalVisible(true);
    setTimeout(() => {
      closeModalPopup();
    }, 7000);
  };
  const openSuccessModal = () => {
    setModalTitle("File Uploaded");
    setModalType("success");
    setModalSubTitle("");
    setModalButtons(<></>);
    setModalVisible(true);
  };
  const openErrorModal = (error_file_url: string) => {
    setModalTitle("Error");
    setModalType("error");
    setModalSubTitle(
      <div className="text-center">
        Seems like there are issues with the uploaded file.
        <br />
        Error file is generated for your reference.
        <br />
        Please rectify errors and upload the file again.
      </div>
    );
    setModalButtons(
      <>
        <Row>
          <Col span={24}>
            <a href={error_file_url}>
              <Button size="large" type="primary" block>
                Download Error File
              </Button>
            </a>
          </Col>
        </Row>
      </>
    );
    setModalVisible(true);
  };

  return (
    <>
      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={false}
        title="Upload File"
        className="modalFormButton form-modal"
        style={{ paddingBottom: "0px" }}
        centered={true}
      >
        <div className="pd20">
          <Form name={"ticket_form"}>
            <Row gutter={20} align="bottom">
              <Col span={24}>
                {fileListData?.length == 0 && errorFileListData?.length == 0 && (
                  <div className="admin-upload-component pd20 mb-20">
                    <img
                      src={
                        require("../../../assets/icons/cloud-upload.svg")
                          .default
                      }
                      width="140"
                    />
                    <p className="title margin0">Upload Document</p>
                    <p className="sub-text margin0">
                      (Max file size allowed 5Mb)
                    </p>
                    <p className="sub-text margin0">
                      (Files allowed: .txt, .pdf, .ppt, .pptx)
                    </p>
                    <Upload {...props}>
                      <Button loading={uploading} type="primary">
                        Select File
                      </Button>
                    </Upload>
                  </div>
                )}
                {(fileListData?.length == 1 ||
                  errorFileListData?.length == 1) && (
                  <>
                    <div className="ant-form-item-label">
                      <label>Selected File</label>
                    </div>
                    <div
                      className={`admin-file-component ${
                        errorFileListData?.length == 1 ? "danger" : ""
                      }`}
                    >
                      <Row align="middle" gutter={12} className="pb-2">
                        <Col flex="none">
                          <img
                            src={
                              require("../../../assets/icons/file-conf.svg")
                                .default
                            }
                            height={50}
                          />
                        </Col>
                        <Col flex="auto">
                          <p className="title margin0">
                            {fileListData[0]?.file?.name ||
                              errorFileListData[0]?.file?.name}
                          </p>
                          <p className="sub-text margin0">
                            {convertFileSizeFromBytes(
                              fileListData[0]?.file?.size ||
                                errorFileListData[0]?.file?.size
                            )}{" "}
                            {fileListData?.[0]?.upload_id && `• File Uploaded`}
                          </p>
                        </Col>
                        <Col flex="none">
                          <Row>
                            <Col>
                              <Button
                                type="link"
                                onClick={removeFile}
                                icon={
                                  <img
                                    src={
                                      require("../../../assets/icons/close.svg")
                                        .default
                                    }
                                  />
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {fileListData?.length == 1 &&
                                !fileListData[0].upload_id && (
                                  <span className={`percent success`}>0%</span>
                                )}
                              {fileListData?.length == 1 &&
                                fileListData[0].upload_id && (
                                  <span className={`percent success`}>
                                    100%
                                  </span>
                                )}
                              {errorFileListData?.length == 1 && (
                                <span className={`percent danger`}>Failed</span>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <div
                            className={`progress-bar ${
                              fileListData?.length == 1 &&
                              fileListData[0].upload_id
                                ? "success"
                                : ""
                            } ${
                              errorFileListData?.length == 1 ? "danger" : ""
                            }`}
                          ></div>
                        </Col>
                      </Row>
                    </div>
                    {errorFileListData?.length == 1 && (
                      <Row>
                        <Col className={`admin-error-message pt-2`} span={24}>
                          Error: File size is too large to be uploaded. Files
                          larger than <br />
                          5mb size are not supported.
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col span={20}>
                    <Button
                      type="primary"
                      className="mt15"
                      size="large"
                      disabled={!(fileListData?.length == 1)}
                      block
                      onClick={handleUpload}
                    >
                      Upload File
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
        {/* <Button
          type="primary"
          className="mt15"
          size="large"
          block
          onClick={() => startValidation("1")}
        >
          Temp
        </Button> */}
      </Modal>
      {modalVisible && (
        <PopupMessageComponent
          open={modalVisible}
          onCancel={closeModalPopup}
          title={modalTitle}
          subTitle={modalSubTitle}
          type={modalType}
          buttons={modalButtons}
        />
      )}
    </>
  );
}
export default UploadDocumentComponent;
