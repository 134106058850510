import { Col, Modal, Row, Tag, Timeline } from "antd";
import moment from "moment";
// import ReactHtmlParser from 'react-html-parser';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchUploadedFileByIds } from "../../redux/reducers/tickets.slice";
import { formatDate, formatDropDownValue, truncateText50 } from "../Utils/utilities";
import { TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS } from "../components/permission.constants";
import FileViewLayout from "./file-view.layout";


type Props = {
  details: any;
  type: any;
  theme?: string;
};
function TimelineLayout({ details, type, theme = "", }: Props) {
  const { t } = useTranslation();
  const [showEmailModal, setshowEmailModal] = useState(null as any)
  const [emailData, setemailData] = useState(null as any)

  const setEmailDataAndShow = (id: any) => {
    setshowEmailModal(true)
    setemailData(details?.filter((val: any) => {
      return val?.ticket_email_id === id;
    })[0]?.ticket_email_data)
  }

  const [activeFile, setActiveFile] = useState(null as any);
  const [fileUrl, setfileUrl] = useState(null as any);
  const [fileviewer, setFileViewer] = useState(false as any);
  const [fileExtention, setfileExtention] = useState(null as any)

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const dispatch = useDispatch();

  function viewDocuments(id: any) {
    setActiveFile(id);
    getFileUrl(id);
    setFileViewer(true);
  }

  function getFileUrl(id: any) {
    setfileUrl(null);
    dispatch(
      fetchUploadedFileByIds(id)
    ).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        const fileExtensions = val?.payload?.data?.data?.name?.split('.').pop();

        setfileUrl(val?.payload?.data?.data);
        setfileExtention(fileExtensions)


        // debugger
      }
    });

  }


  return (
    <>
      <Timeline mode={"left"} className={`crm-timeline ${theme}`}>
        {details?.map((row: any, index: number) => {
          return (
            <Timeline.Item key={index}>
              <Row gutter={2}>
                <Col flex="none" className="gutter-row history-timeline-date">
                  {moment(row?.created_at).format("D MMM'YY")}
                  <br />
                  <span className="text-secondary">
                    {moment(row.created_at).format("LT")}
                  </span>
                </Col>
                <Col className="gutter-row history-timeline-details">
                  <div className="timeline-details">
                    <Row gutter={8}>
                      <Col
                        {...{ xs: 24, sm: 24, md: 19, lg: 19, xl: 19 }}
                        className="gutter-row"
                      >
                        <span className="capitalize">
                          {type ? type : ""}{" "}
                          {index < details?.length - 1
                            ? row?.status?.name
                            : "Created"}
                          {formatDropDownValue(row?.status?.name) ===
                            "Follow-up"
                            ? " on " + formatDate(row?.follow_up_date)
                            : ""}
                          {row?.priority && row?.priority !== null && (
                            <>
                              {" "}
                              |{" "}
                              {row?.priority?.name
                                ? row?.priority?.name + " Priority"
                                : ""}
                            </>
                          )}
                          {row?.due_date && (
                            <> | Due Date {formatDate(row?.due_date)}</>
                          )}
                          {
                            row?.type?.name && (
                              <>
                                {" "} | {t("CRM.Tickets.Fields.Ticket Sub Type")}: {formatDropDownValue(row?.sub_type?.name)}
                              </>
                            )
                          }
                          {
                            row?.sub_type?.name && (
                              <>
                                {" "} |  {t("CRM.Tickets.Fields.Ticket Category")}: {formatDropDownValue(row?.category?.name)}
                              </>
                            )
                          }
                          {row?.assigned_user?.first_name && (
                            <>
                              {" "}
                              | assigned to{" "}
                              {row?.assigned_user?.first_name
                                ? row?.assigned_user?.first_name
                                : ""}{" "}
                              {row?.assigned_user?.last_name
                                ? row?.assigned_user?.last_name
                                : ""}
                            </>
                          )}
                          {row.text}
                        </span>
                        <br />
                        {row?.remarks && (
                          <p className="text-secondary em">
                            Remarks : {row?.remarks}
                          </p>
                        )}
                        {
                          row?.ticket_email_data?.subject && <span>
                            <b>
                              {row?.ticket_email_data?.subject} -
                            </b>

                          </span>
                        }

                        {
                          row?.ticket_email_data?.msg_html && <p dangerouslySetInnerHTML={{ __html: truncateText50(row?.ticket_email_data?.msg_html, 50) }}></p>

                        }
                        <br />
                        {
                          row?.ticket_email_data?.attachments?.length > 0 && <p className="text-secondary">{row?.ticket_email_data?.attachments?.length} Attachment(s) included</p>
                        }

                      </Col>
                      <Col
                        {...{ xs: 24, sm: 24, md: 5, lg: 5, xl: 5 }}
                        className="gutter-row text-right"
                      >
                        <span className="text-secondary em capitalize">
                          {" "}
                          By{" "}
                          {row?.action_by?.first_name && (
                            <>
                              {row?.action_by?.first_name
                                ? row?.action_by?.first_name
                                : ""}{" "}
                              {row?.action_by?.last_name
                                ? row?.action_by?.last_name
                                : ""}
                            </>
                          )}{" "}
                          {!row?.action_by?.first_name && `System`}{" "}

                        </span>
                        {
                          row?.ticket_email_data ?
                            <p><b><a href="#" onClick={() => { setEmailDataAndShow(row?.ticket_email_id) }} >View Email</a></b></p> :
                            !row?.ticket_email_data && row?.remarks ? (myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS) !== -1) && <Tag>Internal Note</Tag> : ""

                        }
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Timeline.Item>
          );
        })}
      </Timeline>

      <Modal
        visible={showEmailModal}
        style={{ paddingBottom: "0px" }}
        footer={false}
        centered
        title={"Email View"}
        closable
        onCancel={() => setshowEmailModal(false)}
      >

        <Row>
          <Col span={4}>From: </Col>
          <Col> {emailData?.from}</Col>
        </Row>
        <Row>
          <Col span={4}>To: </Col>
          <Col> {emailData?.to}</Col>
        </Row>
        <Row>
          <Col span={4}>Date: </Col>
          <Col>  {emailData?.date ? formatDate(emailData?.date) : "-"}</Col>
        </Row>
        <Row>
          <Col span={4}>Subject: </Col>
          <Col> {emailData?.subject}</Col>
        </Row>
        <Row>
          {/* <Col>Subject: </Col> */}
          <Col> {emailData?.description}</Col>
        </Row>
        <Row>
          <Col>
            <span dangerouslySetInnerHTML={{ __html: emailData?.msg_html }}></span>
          </Col>
        </Row>

        <Row>
          {
            emailData?.attachments?.map((val: any, index: any) => {
              return (
                <>
                  <Col span={24}>
                    <b><a href="#" onClick={() => { viewDocuments(val.id) }} >View Attachment {index + 1}</a></b>
                  </Col>
                </>
              )
            })
          }
        </Row>

      </Modal>
      <Modal
        centered
        footer={false}
        visible={fileviewer}
        onCancel={() => setFileViewer(false)}
        width={700}
      >
        <Row className="crm-app" style={{ backgroundColor: "#FFFFFF" }}>
          <Col
            span={24}
            style={{ maxHeight: "calc(100vh - 20px)", overflow: "auto" }}
          >
            <Row>
              <Col span={24} className={"padding10"}>
                {/* { activeFile && !fileUrl && <FileViewLayout modal={false} details={activeFile} fileviewer={true} total={0} file={{result: activeFile.result }}></FileViewLayout> } */}
                {fileUrl && (
                  <FileViewLayout
                    modal={false}
                    details={activeFile}
                    fileviewer={true}
                    total={0}
                    file={{ result: fileUrl.url }}
                    fileExtension={fileExtention}

                  ></FileViewLayout>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default TimelineLayout;
