import { Transfer } from "antd";
import Drawer from "antd/lib/drawer";
import { TransferDirection } from "antd/lib/transfer";
import { useState } from "react";

type Props = {
  audienceInfo: any;
  visible: boolean;
  setVisible: any;
};
const mockData = Array.from({ length: 20 }).map((_, i) => ({
  key: i.toString(),
  name: `Name ${i + 1}`,
  phone: `784528522${i + 1}`,
}));
function ManageAudience({ audienceInfo, visible, setVisible }: Props) {
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const handleChange = (
    newTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setTargetKeys(newTargetKeys);

    console.log("targetKeys: ", newTargetKeys);
    console.log("direction: ", direction);
    console.log("moveKeys: ", moveKeys);
  };

  const handleSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);

    console.log("sourceSelectedKeys: ", sourceSelectedKeys);
    console.log("targetSelectedKeys: ", targetSelectedKeys);
  };

  const onAddUsers = (values: any) => {};
  const onRemoveUsers = (values: any) => {};

  return (
    <>
      <Drawer
        title={"Manage Audience"}
        visible={visible}
        onClose={() => setVisible(false)}
        width={1000}
        footer={false}
      >
        <Transfer
          dataSource={mockData}
          titles={["Source", "Target"]}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={handleChange}
          onSelectChange={handleSelectChange}
          //   onScroll={handleScroll}
          render={(item) => item.name}
          oneWay
          style={{ marginBottom: 16 }}
        />
      </Drawer>
    </>
  );
}
export default ManageAudience;
