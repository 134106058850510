import { Conversation } from "../../../shared/layouts/crm-chat-widget";

function ChatBotPage() {
  return (
    <div className="chat-bot-page">
      <Conversation open={true} setOpen={() => {}} pageView={true} />
    </div>
  );
}
export default ChatBotPage;
