import { Col, Divider, Row, Select, Space, Switch, Typography } from "antd";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchBranches } from "../../redux/reducers/branch.slice";
import { fetchDepartments } from "../../redux/reducers/department.slice";
import { fetchDoctors } from "../../redux/reducers/doctor.slice";
import { RootState } from "../../shared/constants";
import PageHeader from "../../shared/layouts/page-header.layout";
import { Doctor } from "../../shared/types/doctor.type";
import { Patient } from "../../shared/types/patient.type";
import { BookAppointmentFilter } from "../../shared/Utils/filters";
import DoctorAppointmentCard from "./doctor-appointment-card";
const { Option } = Select;
const { Title } = Typography;

type Props = {
  app?: string;
  type?: string;
  details?: any;
};

const BookAppointment: React.FunctionComponent<Props> = ({
  app = "admin",
  type = "",
  details,
}: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { doctors, loading } = useSelector(
    (state: RootState) => state.doctor || {}
  );
  const { Patients } = useSelector((state: RootState) => state.patient);

  const onChange = (value: any) => {
    console.log(value);
  };
  useEffect(() => {
    dispatch(
      fetchDoctors({
        type: "",
        query: "",
      })
    );
    dispatch(fetchDepartments());
    dispatch(fetchBranches());
  }, [dispatch]);
  // console.log(type)
  // console.log(location.state)
  // console.log(details)
  return (
    <Fragment>
      {app == "admin" && (
        <Row
          className="white-background"
          style={{ margin: "-20px", padding: "40px" }}
        >
          <Col span={12}>
            <Space>
              <Select placeholder="Doctor Appointment">
                <Option value="">Doctor Appointment</Option>
              </Select>
              <Select placeholder="Choose Patient">
                {Patients.map((patient: Patient) => {
                  return (
                    <Option value="1">
                      <Space split={<Divider type="vertical"></Divider>}>
                        <span>{patient.name} (28,M)</span>
                        <span>Cash</span>
                      </Space>
                    </Option>
                  );
                })}
              </Select>
            </Space>
          </Col>
          <Col span={24}>
            <Divider></Divider>
          </Col>
          <Col span={24}>
            <PageHeader title={"Book Appointment"} subtitle={""}></PageHeader>
          </Col>
        </Row>
      )}
      <Row className="bg-app">
        <Col>
          <BookAppointmentFilter
            onChange={(value: any) => onChange(value)}
          ></BookAppointmentFilter>
        </Col>
      </Row>
      <Row className="pd20">
        <Col span={12}>
          <Title level={5}>{doctors?.length} Doctors Available</Title>
        </Col>
        <Col span={12} className="text-right">
          <p className="primary-color-text">
            <i>Show Video Consultation Only</i> <Switch size="small"></Switch>{" "}
          </p>
        </Col>
        <Col span={24}>
          {doctors?.map((doctor: Doctor) => {
            return (
              <DoctorAppointmentCard
                doctor={doctor}
                loading={loading}
                patientDetails={
                  type == "lead" && app == "crm"
                    ? details?.contact?.patient
                    : location?.state
                }
                linkType={type}
                linkDetails={details}
              ></DoctorAppointmentCard>
            );
          })}
        </Col>
      </Row>
    </Fragment>
  );
};

export default BookAppointment;
