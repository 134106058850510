// const { google } = require("googleapis");

import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Col, PageHeader, Row, Spin, Table } from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import {
  requestGoogleAccountDetails,
  requestGoogleConnect,
} from "../../../redux/reducers/social.slice";
import { formatDate, getURLParams } from "../../../shared/Utils/utilities";
import { ROUTE_CRM_GOOGLE_CONNECT } from "../../../shared/routes/crm.routes.constants";

declare global {
  interface Window {
    google: any;
  }
}

function GoogleConnectPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlParams = getURLParams();
  console.log(urlParams);
  const google = window.google;
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  const [connected, setConnected] = useState(false as boolean);
  const [loading, setLoading] = useState(false as boolean);
  const [code, setCode] = useState(null as any);
  const [accountDetails, setAccountDetails] = useState(null as any);
  const scopes =
    "https://www.googleapis.com/auth/plus.business.manage https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

  const client = google.accounts.oauth2.initCodeClient({
    client_id:
      "39714728723-grtcegjf8sc91tjrnnsslv76ti1u2k7g.apps.googleusercontent.com",
    scope: scopes,
    ux_mode: "redirect",
    access_type: "offline",
    redirect_uri: window.location.href,
  });
  useEffect(() => {
    requestAccountDetails();
  }, []);
  useEffect(() => {
    if (urlParams?.code) {
      setCode(urlParams.code);
    }
  }, [urlParams]);

  useEffect(() => {
    if (!loading && code) {
      setLoading(true);
    }
    if (loading && code) {
      googleConnectInit();
    }
  }, [code, loading]);
  async function googleConnectInit() {
    const res = await dispatch(requestGoogleConnect({ code }));
    // console.log(res?.payload);
    // if (res?.payload?.data?.data) {
    window.location.href = ROUTE_CRM_GOOGLE_CONNECT;
    // }
  }
  async function requestAccountDetails() {
    const res = await dispatch(requestGoogleAccountDetails());
    console.log(res?.payload);
    if (res?.payload?.data?.data) {
      setAccountDetails(res?.payload?.data?.data);
      setConnected(true);
    }
  }
  // useEffect(() => {
  //   if (accountDetails?.id) {
  //     requestBussinessDetails();
  //   }
  // }, [accountDetails]);
  // async function requestBussinessDetails() {
  //   const res = await dispatch(
  //     requestBusinessLocationsList(accountDetails?.id)
  //   );
  //   if (res?.payload?.data?.data) {
  //     setBusinessLocations(res?.payload?.data?.data);
  //   }
  // }
  return (
    <>
      <Fragment>
        <PageHeader
          ghost={false}
          title={
            <>
              <Row
                style={screenType.mobile ? { paddingLeft: "25px" } : {}}
                align="middle"
              >
                <Col flex="none">Google My Business</Col>
                <Col>
                  {connected ? (
                    <span className="follow-up-pending-header">
                      <CheckCircleFilled className="text-success" /> Connected
                    </span>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </>
          }
          extra={
            !connected && (
              <Button
                loading={loading}
                onClick={() => !loading && client.requestCode()}
              >
                Authorize with Google
              </Button>
            )
          }
        ></PageHeader>
        {/* {JSON.stringify(accountDetails)} */}
        <Spin spinning={loading}>
          <Row className="crm-dashboard">
            <Col span={24} className="crm-tabs padding10">
              <Row className="padding10">
                <Col span={24}>
                  <Table
                    className="crm-table"
                    loading={false}
                    dataSource={
                      accountDetails
                        ? [
                            {
                              ...accountDetails,
                            },
                          ]
                        : []
                    }
                    columns={[
                      {
                        title: "User ID",
                        width: 200,
                        dataIndex: "google_user_id",
                      },
                      {
                        title: "Profile Name",
                        width: 200,
                        dataIndex: "display_name",
                      },
                      {
                        title: "Email",
                        width: 200,
                        dataIndex: "display_name",
                      },
                      {
                        title: "Added Date",
                        width: 200,
                        dataIndex: "created_at",
                        render: (a: any) => {
                          return formatDate(moment(a).format());
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Fragment>
    </>
  );
}
export default GoogleConnectPage;

//   const oauth2Client = new google.auth.OAuth2(
//     "39714728723-grtcegjf8sc91tjrnnsslv76ti1u2k7g.apps.googleusercontent.com",
//     "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",
//     "http://locahost:3000/google-connect"
//   );

//   // generate a url that asks permissions for Blogger and Google Calendar scopes
//

//   const url = oauth2Client.generateAuthUrl({
//     // 'online' (default) or 'offline' (gets refresh_token)
//     access_type: "offline",

//     // If you only need one scope you can pass it as a string
//     scope: scopes,
//   });
