export const ROUTE_CRM_LOGIN = (account: string) => `/${account}/crm/login`;
export const ROUTE_CRM_LANDING = `/crm`;
export const ROUTE_CRM_DASHBOARD = `/crm/dashboard`;
export const ROUTE_CRM_DASHBOARD_NEW = `/crm/dashboardNew`;
export const ROUTE_CRM_TICKET_LIST = `/crm/tickets`;
export const ROUTE_CRM_TICKET_DETAILS = (id: string) => `/crm/ticket/${id}`;
export const ROUTE_CRM_LEADS_LIST = `/crm/leads`;
export const ROUTE_CRM_LEAD_DETAILS = (id: string) => `/crm/lead/${id}`;
export const ROUTE_CRM_CONTACTS = `/crm/contacts`;
export const ROUTE_CRM_CONTACTS_DETAILS = (id: string) => `/crm/contacts/${id}`;
export const ROUTE_CRM_METADATA = `/crm/metadata`;
export const ROUTE_CRM_CALL_LOG_CREATE = `/crm/create-call-log`;
export const ROUTE_CRM_CALL_LOGS = `/crm/call-logs`;
export const ROUTE_CRM_CALL_LOG_DETAILS = (id: string) => `/crm/call-log/${id}`;
export const ROUTE_CRM_SOCIAL_PROFILES = `/crm/social-profiles`;
export const ROUTE_CRM_ASSIGNMENT = `/crm/assignment`;
export const ROUTE_CRM_ACTIONMAP = `/crm/actionmap`;
export const ROUTE_CRM_MASTER_DATA_UPLOAD = `/crm/data-upload`;
export const ROUTE_CRM_SLARULES = `/crm/sla-rules`;
export const ROUTE_CRM_SLARULES_ADD = `/crm/add-sla-rules`;
export const ROUTE_CRM_CHAT = `/crm/chat`;
export const ROUTE_CRM_ADMIN_CHAT = `/crm/admin-chat`;
export const ROUTE_CRM_AGENTS = `/crm/agents`;

export const ROUTE_CRM_POSTS_FEED = `/crm/post/feed`;
export const ROUTE_CRM_POSTS_CALENDAR = `/crm/post/calendar`;
export const ROUTE_CRM_POST_NEW = `/crm/post/new-post`;

export const ROUTE_CRM_LEAD_PAGES = `/crm/advertise/facebook-pages`;
export const ROUTE_CRM_FACEBOOK_AUDIENCE_MANAGE = `/crm/advertise/facebook-audience`;
// export const ROUTE_CRM_LEAD_FORMS = `/crm/advertise/forms`;
export const ROUTE_CRM_GOOGLE_CONNECT = `/crm/google-connect`;

export const ROUTE_CRM_BOOK_APPOINTMENT = (id: string) =>
  `/crm/book-appointment/${id}`; //lead id
export const ROUTE_CRM_ORDER_MEDICINE = (id: string) =>
  `/crm/order-medicine/${id}`; //lead id

export const ROUTE_CRM_KNOWLEDGE_BANK_LIST = `/rag/knowledge-bank`;
export const ROUTE_CRM_BOT_CHAT = `/rag/bot`;
export const ROUTE_CRM_KNOWLEDGE_BANK_DOCUMENTS = (
  bank_name: string,
  id: string
) => `/rag/knowledge-bank/${bank_name}/${id}`;

//// APIS
export const CONTACTS = `task/contact`;
export const DOWNLOADCALLLOGDATA = `task/download-call-log-data`;
export const CONTACT_CREATE_FROM_PATIENT = `task/create-contact-from-patient`;
export const CONTACT_BY_ID = (id: string) => `task/contact/${id}`;
export const CONTACT_AND_PATIENT_BY_MOBILE = (phone: string) =>
  `task/find-contact-and-patient-by-mobile/${phone}`;
export const LEADS = `task/lead`;
export const REASONLISTLEAD = `task/sub-disposition-mappings`;
export const LEADS_COUNT = `task/lead/get-count`;
export const METADATA = `master/task-drop-down`;
export const ALLTASKDATA = `master/all-task-data`;
export const METADATAVALUES = `master/task-drop-down-values`;
export const PATCH_METADATA_LABEL = (id: string) =>
  `master/task-drop-down/${id}`;
export const PATCH_METADATA_VALUES = (id: string) =>
  `master/task-drop-down-values/${id}`;
export const METAALLTASKDROPDOWN = `master/all-task-dropdown`;
export const METATASKTYPE = `master/task-type`;
export const METATASKTYPEUPDATE = `master/task-type/update`;
export const METATASKSUBTYPE = `master/task-sub-type`;
export const METATASKSUBTYPEBYID = (id: string) =>
  `master/task-sub-type?task_type_id=${id}`;
export const METATASKSUBTYPEPPOST = `master/task-sub-type`;
export const METATASKSUBTYPEUPDATE = `master/task-sub-type/update`;
export const METATASKCATEGORYPOST = `master/task-category`;
export const METATASKCATEGORY = (id: string) =>
  `master/task-category?task_sub_type_id=${id}`;
export const METATASKCATEGORYUPDATE = `master/task-category/update`;
export const LEAD_STATUS = `task/lead-status`;
export const LEAD_BY_ID = (id: string) => `task/lead/${id}`;
export const GET_PATIENT_ADDRESS = (id: string) =>
  `patients/get-patients-address-by-id-for-crm/${id}`;
export const LEAD_SERVICE_MAP = `task/lead/services`;
export const LEAD_DOWNLOAD = `task/download-lead-data`;

export const ACTIONMAPPATCH = (id: string) => `task/task/action-mapping/${id}`;

export const CALL_LOGS = `task/call-log`;
export const CALL_INITIATE = `task/make-a-call`;
export const CALL_LOG_BY_ID = (id: string) => `task/call-log/${id}`;
export const CALL_LOG_LINK = `task/call-log-link`;
export const CALL_LOG_DELINK = (id: string) => `task/call-log-link/${id}`;

export const INTALK_LOGIN = `task/login-in-talk`;
export const INTALK_LOGOUT = `task/logout-in-talk`;

export const TICKETS = `task/ticket`;
export const TICKETS_NPS_DETAILS = `task/get-nps-details`;
export const TICKETS_HEALTH_DETAILS = `task/get-health-profile-data`;
export const TICKETS_COUNT = `task/ticket/get-count`;
export const TICKET_DETAIL_BY_ID = (id: string) => `task/ticket/${id}`;
export const TICKET_DOCTOR_SUPPORT = `task/ticket/doctor-support`;
export const REPLY_TO_REVIEW = (id: any) =>
  `task/thbdemo/reply-to-review/${id}`; ///:slug/reply-to-review/:ticket_id
export const ACTIVITY_HISTORY = (id: string) => `task/ticket/timeline/${id}`;
export const VIDEO_LOGS = (id: string, dates: string) =>
  `roomlogs/audit/${id}?date=${dates}`;
export const PAYMENT_HISTORY = (id: string) =>
  `payment/get-history?order_id=${id}`;
export const UPDATE_TICKET_TYPE = (id: string) =>
  `task/update-ticket-type/${id}`;

export const UPDATE_TICKET_ONLY_REMARKS = (id: string) =>
  `task/ticket-remarks/${id}`;

export const TICKET_SERVICE = `task/ticket/service`;
export const TICKET_DOWNLOAD = `task/download-ticket-data`;

export const ASSIGNMENT = `task/ticket/assignment-settings`;
export const ASSIGNMENTPATCH = (id: string) =>
  `task/ticket/assignment-settings/${id}`;
export const TEAMTYPES = `team-types`;
export const TEAMIDS = `teams`;

export const ACTIONMAP = `task/task/action-mapping`;
export const AGENTS = `users`;

export const NEW_PATIENT_FROM_CONTACT = `task/create-patient-from-contact`;
export const NEW_PATIENT_FROM_CONTACT_DIRECT = `patients`;

export const GET_DASHBOARD_DATA = `task/get-dashboard-data`;

export const BULKUPDATETICKET = `task/bulk-ticket`;
export const BULKUPDATELEAD = `task/bulk-lead`;

export const GET_NPS_BY_MOBILE_NUMBER = (mobile: string) =>
  `task/get-nps-rating?mobile=${mobile}`;
export const GET_NOTIFICATION_SETTINGS_BY_CONTACT = (id: string) =>
  `task/get-notification-settings/${id}`;
export const ADD_NEW_NOTIFICATION = `task/create-notification`;
export const ENABLE_DISABLE_NOTIFICATION = (id: string) =>
  `task/enable-disable-notification/${id}`;

let ragPrefix = "rag";

export const API_CRM_KNOWLEDGE_BANK_LIST = `${ragPrefix}/knowledge/listKnowledgeBank?viewAll=false`;
export const API_CRM_KNOWLEDGE_BANK_CREATE = `${ragPrefix}/knowledge/createKnowledgeBank`;
export const API_CRM_KNOWLEDGE_BANK_UPDATE = (id: any) =>
  `${ragPrefix}/knowledge/updateKnowledgeBank/${id}`;
export const API_CRM_KNOWLEDGE_BANK_DOCUMENTS_LIST = (id: string) =>
  `${ragPrefix}/document/listDocuments?knowledge_bank_id=${id}&view_all=false`;
export const API_CRM_KNOWLEDGE_BANK_DOCUMENTS_CHUNKS = (id: string) =>
  `${ragPrefix}/document/documentDetails?file_id=${id}`;
export const API_CRM_KNOWLEDGE_BANK_DOCUMENTS_GET_URL = (id: string) =>
  `${ragPrefix}/document/getdocument?file_id=${id}`;
export const API_CRM_KNOWLEDGE_BANK_DOCUMENT_UPDATE = (id: any) =>
  `${ragPrefix}/document/updateDocument/${id}`;

export const API_CRM_KNOWLEDGE_BANK_DOCUMENTS_PROCESS_FILE = `${ragPrefix}/document/processfile`;
export const API_CRM_KNOWLEDGE_BANK_DOCUMENTS_GET_UPLOAD_SIGNED_URL = `${ragPrefix}/document/uploadurl`;
export const API_CRM_ASK_QUESTION_KNOWLEDGE_BANK = `${ragPrefix}/questions/ask`;
export const API_CRM_SIMILAR_QUESTION_KNOWLEDGE_BANK = `${ragPrefix}/questions/similarQuestions`;
export const API_CRM_RECENT_QUESTION_KNOWLEDGE_BANK = `${ragPrefix}/questions/freshQuestions`;
export const API_CRM_ANSWER_BY_QUESTION_ID_KNOWLEDGE_BANK = (id: any) =>
  `${ragPrefix}/answers/answersForQuestionId/${id}`;
export const API_CRM_GET_MODELS_KNOWLEDGE_BANK = `${ragPrefix}/common/getmodels`;
