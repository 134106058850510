// Generated File. Please dont change
export const USER_MODULE_ACTIONS_ASSIGN_ACCESS_GROUP =
  "user_module.actions.assign_access_group";

export const USER_MODULE_ACTIONS_READ_USER_TYPE =
  "user_module.actions.read_user_type";

export const USER_MODULE_ACTIONS_UPDATE_LOGINS =
  "user_module.actions.update_logins";

export const USER_MODULE_ACTIONS_READ_REQUEST_LOGS =
  "user_module.actions.read_request_logs";

export const USER_MODULE_ACTIONS_GET_MY_PROFILE =
  "user_module.actions.get_my_profile";

export const USER_MODULE_ACTIONS_FETCH_MY_NOTIFICATION_TEMPLATES =
  "user_module.actions.fetch_my_notification_templates";

export const USER_MODULE_ACTIONS_UPDATE_SELF_NOTIFICATION_PREFERENCE =
  "user_module.actions.update_self_notification_preference";

export const USER_MODULE_ACTIONS_READ = "user_module.actions.read";

export const USER_MODULE_ACTIONS_CREATE = "user_module.actions.create";

export const USER_MODULE_ACTIONS_UPDATE = "user_module.actions.update";

export const USER_MODULE_ACTIONS_DELETE = "user_module.actions.delete";

export const DEPARTMENT_MODULE_ACTIONS_CREATE_MANY_SYNC =
  "department_module.actions.create_many_sync";

export const DEPARTMENT_MODULE_ACTIONS_READ = "department_module.actions.read";

export const DEPARTMENT_MODULE_ACTIONS_CREATE =
  "department_module.actions.create";

export const DEPARTMENT_MODULE_ACTIONS_UPDATE =
  "department_module.actions.update";

export const DEPARTMENT_MODULE_ACTIONS_DELETE =
  "department_module.actions.delete";

export const APPOINTMENT_MODULE_ACTIONS_GET_PAYMENT_LINK =
  "appointment_module.actions.get_payment_link";

export const APPOINTMENT_MODULE_ACTIONS_GET_PAYMENT_STATUS =
  "appointment_module.actions.get_payment_status";

export const APPOINTMENT_MODULE_ACTIONS_GET_IPD_APPOINTMENTS =
  "appointment_module.actions.get_ipd_appointments";

export const APPOINTMENT_MODULE_ACTIONS_CREATE_IPD_APPOINTMENT =
  "appointment_module.actions.create_ipd_appointment";

export const APPOINTMENT_MODULE_ACTIONS_CREATE_INSTANT_APPOINTMENT =
  "appointment_module.actions.create_instant_appointment";

export const APPOINTMENT_MODULE_ACTIONS_ADD_ONE_APPOINTMENT_ATTENDANT =
  "appointment_module.actions.add_one_appointment_attendant";

export const APPOINTMENT_MODULE_ACTIONS_ADD_MULTIPLE_APPOINTMENT_ATTENDANT =
  "appointment_module.actions.add_multiple_appointment_attendant";

export const APPOINTMENT_MODULE_ACTIONS_REMOVE_ATTENDANT =
  "appointment_module.actions.remove_attendant";

export const APPOINTMENT_MODULE_ACTIONS_ADD_APPOINTMENT_NOTE =
  "appointment_module.actions.add_appointment_note";

export const APPOINTMENT_MODULE_ACTIONS_UPDATE_APPOINTMENT_NOTE =
  "appointment_module.actions.update_appointment_note";

export const APPOINTMENT_MODULE_ACTIONS_UPDATE_APPOINTMENT_STATUS =
  "appointment_module.actions.update_appointment_status";

export const APPOINTMENT_MODULE_ACTIONS_PATIENT_CHECK_IN =
  "appointment_module.actions.patient_check_in";

export const APPOINTMENT_MODULE_ACTIONS_APPOINTMENT_CHECK_OUT =
  "appointment_module.actions.appointment_check_out";

export const APPOINTMENT_MODULE_ACTIONS_FIND_BY_EXTERNAL_BOOKING_NO =
  "appointment_module.actions.find_by_external_booking_no";

export const APPOINTMENT_MODULE_ACTIONS_GET_FREQUENT_VISITED_DOCTORS =
  "appointment_module.actions.get_frequent_visited_doctors";

export const APPOINTMENT_MODULE_ACTIONS_ATTEMPT_ATTENDANT_OTP =
  "appointment_module.actions.attempt_attendant_otp";

export const APPOINTMENT_MODULE_ACTIONS_VERIFY_ATTENDANT_OTP =
  "appointment_module.actions.verify_attendant_otp";

export const APPOINTMENT_MODULE_ACTIONS_PREVIOUS_SHIFT_APPOINTMENTS =
  "appointment_module.actions.previous_shift_appointments";

export const APPOINTMENT_MODULE_ACTIONS_ANALYTICS =
  "appointment_module.actions.analytics";

export const APPOINTMENT_MODULE_ACTIONS_VIDEO_CALL_RATING =
  "appointment_module.actions.video_call_rating";

export const APPOINTMENT_MODULE_ACTIONS_VISITED_PATIENTS =
  "appointment_module.actions.visited_patients";

export const APPOINTMENT_MODULE_ACTIONS_UPDATE_APPOINTMENT_TICKET =
  "appointment_module.actions.update_appointment_ticket";

export const APPOINTMENT_MODULE_ACTIONS_GETAPPOINTMENTTICKET =
  "appointment_module.actions.getAppointmentTicket";

export const APPOINTMENT_MODULE_ACTIONS_UPDATEPATIENTAPPOINTMENTSTATE =
  "appointment_module.actions.updatePatientAppointmentState";

export const APPOINTMENT_MODULE_ACTIONS_UPDATEAPPOINTMENTATTRIBUTES =
  "appointment_module.actions.updateAppointmentAttributes";

export const APPOINTMENT_MODULE_ACTIONS_GET_APPOINTMENT_FEE =
  "appointment_module.actions.get_appointment_fee";

export const APPOINTMENT_MODULE_ACTIONS_READ =
  "appointment_module.actions.read";

export const APPOINTMENT_MODULE_ACTIONS_CREATE =
  "appointment_module.actions.create";

export const APPOINTMENT_MODULE_ACTIONS_UPDATE =
  "appointment_module.actions.update";

export const APPOINTMENT_MODULE_ACTIONS_DELETE =
  "appointment_module.actions.delete";

export const BRANCH_MODULE_ACTIONS_CREATE_MANY_SYNC =
  "branch_module.actions.create_many_sync";

export const BRANCH_MODULE_ACTIONS_READ = "branch_module.actions.read";

export const BRANCH_MODULE_ACTIONS_CREATE = "branch_module.actions.create";

export const BRANCH_MODULE_ACTIONS_UPDATE = "branch_module.actions.update";

export const BRANCH_MODULE_ACTIONS_DELETE = "branch_module.actions.delete";

export const ACCESS_GROUP_MODULE_ACTIONS_ALL_SCOPES =
  "access_group_module.actions.all_scopes";

export const ACCESS_GROUP_MODULE_ACTIONS_READ =
  "access_group_module.actions.read";

export const ACCESS_GROUP_MODULE_ACTIONS_CREATE =
  "access_group_module.actions.create";

export const ACCESS_GROUP_MODULE_ACTIONS_UPDATE =
  "access_group_module.actions.update";

export const ACCESS_GROUP_MODULE_ACTIONS_DELETE =
  "access_group_module.actions.delete";

export const ROLE_MODULE_ACTIONS_ALL_ACTIONS =
  "role_module.actions.all_actions";

export const ROLE_MODULE_ACTIONS_ALL_MODULES =
  "role_module.actions.all_modules";

export const ROLE_MODULE_ACTIONS_READ = "role_module.actions.read";

export const ROLE_MODULE_ACTIONS_CREATE = "role_module.actions.create";

export const ROLE_MODULE_ACTIONS_UPDATE = "role_module.actions.update";

export const ROLE_MODULE_ACTIONS_DELETE = "role_module.actions.delete";

export const TEAM_MODULE_ACTIONS_READ = "team_module.actions.read";

export const TEAM_MODULE_ACTIONS_CREATE = "team_module.actions.create";

export const TEAM_MODULE_ACTIONS_UPDATE = "team_module.actions.update";

export const TEAM_MODULE_ACTIONS_DELETE = "team_module.actions.delete";

export const PATIENT_MODULE_ACTIONS_CREATE_PATIENT =
  "patient_module.actions.create_patient";

export const PATIENT_MODULE_ACTIONS_UPDATE_PATIENT =
  "patient_module.actions.update_patient";

export const PATIENT_MODULE_ACTIONS_GET_ALL_PATIENTS =
  "patient_module.actions.get_all_patients";

export const PATIENT_MODULE_ACTIONS_GET_MY_PROFILE =
  "patient_module.actions.get_my_profile";

export const PATIENT_MODULE_ACTIONS_GET_PATIENT_BY_ID =
  "patient_module.actions.get_patient_by_id";

export const PATIENT_MODULE_ACTIONS_GET_PATIENT_BY_MOBILE =
  "patient_module.actions.get_patient_by_mobile";

export const PATIENT_MODULE_ACTIONS_GET_RECOMMENDED_MEMBERS =
  "patient_module.actions.get_recommended_members";

export const PATIENT_MODULE_ACTIONS_GET_MY_ADDRESSES =
  "patient_module.actions.get_my_addresses";

export const PATIENT_MODULE_ACTIONS_GET_ADDRESSES_BY_PATIENT_ID =
  "patient_module.actions.get_addresses_by_patient_id";

export const PATIENT_MODULE_ACTIONS_ADD_ADDRESS =
  "patient_module.actions.add_address";

export const PATIENT_MODULE_ACTIONS_UPDATE_ADDRESS =
  "patient_module.actions.update_address";

export const PATIENT_MODULE_ACTIONS_ADD_RELATIONSHIP =
  "patient_module.actions.add_relationship";

export const PATIENT_MODULE_ACTIONS_GET_MY_RELATIONS =
  "patient_module.actions.get_my_relations";

export const PATIENT_MODULE_ACTIONS_DELETE_RELATION =
  "patient_module.actions.delete_relation";

export const PATIENT_MODULE_ACTIONS_GET_NON_LINKED_MEMBERS =
  "patient_module.actions.get_non_linked_members";

export const PATIENT_MODULE_ACTIONS_ADD_TAGS =
  "patient_module.actions.add_tags";

export const PATIENT_MODULE_ACTIONS_DELETE_TAGS =
  "patient_module.actions.delete_tags";

export const PATIENT_MODULE_ACTIONS_GET_MY_TAGS =
  "patient_module.actions.get_my_tags";

export const PATIENT_MODULE_ACTIONS_GET_PATIENT_TAGS =
  "patient_module.actions.get_patient_tags";

export const PATIENT_MODULE_ACTIONS_ADD_BOOKMARK_DOC =
  "patient_module.actions.add_bookmark_doc";

export const PATIENT_MODULE_ACTIONS_GET_BOOKMARK_DOC =
  "patient_module.actions.get_bookmark_doc";

export const PATIENT_MODULE_ACTIONS_DELETE_BOOKMARK_DOC =
  "patient_module.actions.delete_bookmark_doc";

export const PATIENT_MODULE_ACTIONS_ADD_ATTACHMENT =
  "patient_module.actions.add_attachment";

export const PATIENT_MODULE_ACTIONS_GET_ATTACHMENT_BY_ID =
  "patient_module.actions.get_attachment_by_id";

export const PATIENT_MODULE_ACTIONS_ADD_MERGE_PATIENT =
  "patient_module.actions.add_merge_patient";

export const PATIENT_MODULE_ACTIONS_GET_DOC_SIGNED_URL =
  "patient_module.actions.get_doc_signed_url";

export const PATIENT_MODULE_ACTIONS_ADD_PATIENT_DOC =
  "patient_module.actions.add_patient_doc";

export const PATIENT_MODULE_ACTIONS_GET_PATIENT_DOC =
  "patient_module.actions.get_patient_doc";

export const PATIENT_MODULE_ACTIONS_REMOVE_PATIENT_DOC =
  "patient_module.actions.remove_patient_doc";

export const PATIENT_MODULE_ACTIONS_UPDATE_DOCUMENT_QC =
  "patient_module.actions.update_document_qc";

export const PATIENT_MODULE_ACTIONS_ADD_VERIFIED_MOBILE =
  "patient_module.actions.add_verified_mobile";

export const PATIENT_MODULE_ACTIONS_GET_ALL_PATIENTS_BY_UHID =
  "patient_module.actions.get_all_patients_by_uhid";

export const PATIENT_MODULE_ACTIONS_CREATE_PATIENTS_FROM_SYNC_APPOINTMENTS =
  "patient_module.actions.create_patients_from_sync_appointments";

export const PATIENT_MODULE_ACTIONS_UPDATE_PROFILE_IMAGE =
  "patient_module.actions.update_profile_image";

export const PATIENT_MODULE_ACTIONS_READ = "patient_module.actions.read";

export const PATIENT_MODULE_ACTIONS_CREATE = "patient_module.actions.create";

export const PATIENT_MODULE_ACTIONS_UPDATE = "patient_module.actions.update";

export const PATIENT_MODULE_ACTIONS_DELETE = "patient_module.actions.delete";

export const DOCTOR_MODULE_ACTIONS_GET_DOCTOR_SEARCH_N_SORT_CONFIG =
  "doctor_module.actions.get_doctor_search_n_sort_config";

export const DOCTOR_MODULE_ACTIONS_CREATE_DOCTOR =
  "doctor_module.actions.create_doctor";

export const DOCTOR_MODULE_ACTIONS_UPDATE_DOCTOR =
  "doctor_module.actions.update_doctor";

export const DOCTOR_MODULE_ACTIONS_ADD_SPECIALITY_MAPPING =
  "doctor_module.actions.add_speciality_mapping";

export const DOCTOR_MODULE_ACTIONS_ADD_MY_SPECIALITY_MAPPING =
  "doctor_module.actions.add_my_speciality_mapping";

export const DOCTOR_MODULE_ACTIONS_ADD_LANG_MAPPING =
  "doctor_module.actions.add_lang_mapping";

export const DOCTOR_MODULE_ACTIONS_ADD_MY_LANG_MAPPING =
  "doctor_module.actions.add_my_lang_mapping";

export const DOCTOR_MODULE_ACTIONS_GET_ALL_DOCTORS =
  "doctor_module.actions.get_all_doctors";

export const DOCTOR_MODULE_ACTIONS_GET_SEARCH_AUTOCOMPLETE =
  "doctor_module.actions.get_search_autocomplete";

export const DOCTOR_MODULE_ACTIONS_GET_MY_PROFILE =
  "doctor_module.actions.get_my_profile";

export const DOCTOR_MODULE_ACTIONS_GET_DOCTOR_BY_ID =
  "doctor_module.actions.get_doctor_by_id";

export const DOCTOR_MODULE_ACTIONS_GET_DOCTOR_BY_ID_FOR_PATIENT_APP =
  "doctor_module.actions.get_doctor_by_id_for_patient_app";

export const DOCTOR_MODULE_ACTIONS_GET_DOC_PROPS_BY_ID_FOR_PATIENT_APP =
  "doctor_module.actions.get_doc_props_by_id_for_patient_app";

export const DOCTOR_MODULE_ACTIONS_GET_PROPS_DATA =
  "doctor_module.actions.get_props_data";

export const DOCTOR_MODULE_ACTIONS_GET_PROPS_BY_DOCTOR_ID =
  "doctor_module.actions.get_props_by_doctor_id";

export const DOCTOR_MODULE_ACTIONS_CREATE_DOCTOR_PROPS =
  "doctor_module.actions.create_doctor_props";

export const DOCTOR_MODULE_ACTIONS_UPDATE_DOCTOR_PROPS =
  "doctor_module.actions.update_doctor_props";

export const DOCTOR_MODULE_ACTIONS_GET_INSTANT_DOCTORS =
  "doctor_module.actions.get_instant_doctors";

export const DOCTOR_MODULE_ACTIONS_GET_COORDINATOR_BY_ID =
  "doctor_module.actions.get_coordinator_by_id";

export const DOCTOR_MODULE_ACTIONS_GET_COORDINATOR_LIST =
  "doctor_module.actions.get_coordinator_list";

export const DOCTOR_MODULE_ACTIONS_CREATE_COORDINATOR =
  "doctor_module.actions.create_coordinator";

export const DOCTOR_MODULE_ACTIONS_REMOVE_COORDINATOR =
  "doctor_module.actions.remove_coordinator";

export const DOCTOR_MODULE_ACTIONS_UPDATE_COORDINATOR =
  "doctor_module.actions.update_coordinator";

export const DOCTOR_MODULE_ACTIONS_UPDATE_PROFILE_IMAGE =
  "doctor_module.actions.update_profile_image";

export const DOCTOR_MODULE_ACTIONS_READ = "doctor_module.actions.read";

export const DOCTOR_MODULE_ACTIONS_CREATE = "doctor_module.actions.create";

export const DOCTOR_MODULE_ACTIONS_UPDATE = "doctor_module.actions.update";

export const DOCTOR_MODULE_ACTIONS_DELETE = "doctor_module.actions.delete";

export const TASK_MODULE_COMPLETE = "task_module";

export const TASK_MODULE_ACTIONS_GET_DASHBOARD_DATA =
  "task_module.actions.get_dashborad_data";

export const TASK_MODULE_ACTIONS_MAKE_A_CALL =
  "task_module.actions.make_a_call";

export const TASK_MODULE_ACTIONS_CIPLA_FILE_UPLOAD =
  "task_module.actions.cipla_file_upload";

export const TASK_MODULE_ACTIONS_CREATE_TASK =
  "task_module.actions.create_task";

export const TASK_MODULE_ACTIONS_HIDE_BRANCH =
  "task_module.actions.hide_branch";

export const TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION =
  "task_module.actions.remove_date_restriction_on_reports";

export const TASK_MODULE_ACTIONS_HIDE_CALLING_NUMBER =
  "task_module.actions.hide_calling_number";

export const TASK_MODULE_ACTIONS_HIDE_THB_ID =
  "task_module.actions.hide_thb_id";

export const TASK_MODULE_ACTIONS_HIDE_CLIENT_DOCTOR_CODE =
  "task_module.actions.hide_client_doctor_code";

export const TASK_MODULE_ACTIONS_SHOW_REVISED_UNIQUE_ROLE =
  "task_module.actions.show_revised_unique_role";

export const TASK_MODULE_ACTIONS_SHOW_STATE = "task_module.actions.show_state";
export const TASK_MODULE_ACTIONS_SHOW_COUNTRY =
  "task_module.actions.show_country";

export const TASK_MODULE_ACTIONS_SHOW_THERAPY_FIELD =
  "task_module.actions.show_therapy_field";

export const TASK_MODULE_ACTIONS_HIDE_GENDER =
  "task_module.actions.hide_gender";

export const TASK_MODULE_ACTIONS_HIDE_AGE = "task_module.actions.hide_age";

export const TASK_MODULE_ACTIONS_HIDE_NATIONALITY =
  "task_module.actions.hide_nationality";

export const TASK_MODULE_ACTIONS_HIDE_UHID = "task_module.actions.hide_uhid";
export const TASK_MODULE_ACTIONS_SHOW_USER_TYPE_CONTACT =
  "task_module.actions.show_user_type_contact";

export const TASK_MODULE_ACTIONS_SHOW_CITY = "task_module.actions.show_city";
export const TASK_MODULE_ACTIONS_SHOW_BHQ = "task_module.actions.show_bhq";
export const TASK_MODULE_ACTIONS_SHOW_CLASS = "task_module.actions.show_class";
export const TASK_MODULE_ACTIONS_SHOW_SPECIALIZATION =
  "task_module.actions.show_specialization";

export const TASK_MODULE_ACTIONS_HIDE_TITLE = "task_module.actions.hide_title";
export const TASK_MODULE_ACTIONS_DISABLE_ASSIGN_TO_IN_CREATE_TICKET =
  "task_module.actions.disable_assign_to_in_create_ticket";

export const TASK_MODULE_ACTIONS_DISABLE_REMARKS_TICKET =
  "task_module.actions.disable_remarks_in_tickets";

export const TASK_MODULE_ACTIONS_MANDATORY_REMARKS_TICKET =
  "task_module.actions.mandatory_remarks_in_tickets";

export const TASK_MODULE_ACTIONS_MANDATORY_REMARKS_LEAD =
  "task_module.actions.mandatory_remarks_in_lead";

export const TASK_MODULE_ACTIONS_DISABLE_REMARKS_LEAD =
  "task_module.actions.disable_remarks_in_leads";

export const TASK_MODULE_ACTIONS_DISABLE_SOURCE_IN_CREATE_TICKET =
  "task_module.actions.disable_source_in_create_ticket";

export const TASK_MODULE_ACTIONS_DISABLE_DUE_DATE_IN_CREATE_TICKET =
  "task_module.actions.disable_due_date_in_create_ticket";

export const TASK_MODULE_ACTIONS_CALCULATE_DUE_DATE =
  "task_module.actions.calculate_due_date";

export const TASK_MODULE_ACTIONS_DISABLE_ASSIGNEE_IN_CREATE_TICKET =
  "task_module.actions.disable_assignee_in_create_ticket";

export const TASK_MODULE_ACTIONS_HIDE_PHONE_ICON =
  "task_module.actions.hide_phone_icon";

export const TASK_MODULE_ACTIONS_VIEW_CHAT_HISTORY =
  "task_module.actions.view_chat_history";

export const TASK_MODULE_ACTIONS_VIEW_NPS_DETAILS =
  "task_module.actions.view_nps_details";

export const TASK_MODULE_ACTIONS_SHOW_ATTACHMENT_TICKET_DETAILS_TAB =
  "task_module.actions.show_attachments_in_ticket_details_tab";

export const TASK_MODULE_ACTIONS_EDIT_TICKET_TYPE =
  "task_module.actions.edit_ticket_type";

export const TASK_MODULE_ACTIONS_EDIT_TICKET_MODULE =
  "task_module.actions.edit_ticket_module";

export const TASK_MODULE_ACTIONS_HIDE_STATUS =
  "task_module.actions.hide_status";

export const TASK_MODULE_ACTIONS_HIDE_SOURCE =
  "task_module.actions.hide_source_in_ticket_list";

export const TASK_MODULE_ACTIONS_UPDATE_TASK =
  "task_module.actions.update_task";

export const TASK_MODULE_ACTIONS_READ_TASK = "task_module.actions.read_task";

export const TASK_MODULE_ACTIONS_CREATE_CONTACT =
  "task_module.actions.create_contact";

export const TASK_MODULE_ACTIONS_UPDATE_CONTACT =
  "task_module.actions.update_contact";

export const TASK_MODULE_ACTIONS_READ_CONTACT =
  "task_module.actions.read_contact";

export const TASK_MODULE_ACTIONS_READ_CHAT = "task_module.actions.read_chat";

export const TASK_MODULE_ACTIONS_ADMIN_READ_CHAT =
  "task_module.actions.admin_read_chat";

export const TASK_MODULE_ACTIONS_CREATE_PATIENT_FROM_CONTACT =
  "task_module.actions.create_patient_from_contact";

export const TASK_MODULE_ACTIONS_READ_CONTACT_SERVICES =
  "task_module.actions.read_contact_services";

export const TASK_MODULE_ACTIONS_CREATE_LEAD =
  "task_module.actions.create_lead";

export const TASK_MODULE_ACTIONS_CREATE_LEAD_FROM_PATIENT =
  "task_module.actions.create_lead_from_patient";

export const TASK_MODULE_ACTIONS_UPDATE_LEAD =
  "task_module.actions.update_lead";

export const TASK_MODULE_ACTIONS_READ_LEAD = "task_module.actions.read_lead";

export const TASK_MODULE_ACTIONS_MANDATORY_BRANCH_IN_SLA =
  "task_module.actions.mandatory_branch_in_sla";

export const TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD =
  "task_module.actions.get_associated_lead";

export const TASK_MODULE_ACTIONS_UPSERT_LEAD =
  "task_module.actions.upsert_lead";

export const TASK_MODULE_ACTIONS_CREATE_LEAD_OPPORTUNITY =
  "task_module.actions.create_lead_opportunity";

export const TASK_MODULE_ACTIONS_READ_LEAD_OPPORTUNITY =
  "task_module.actions.read_lead_opportunity";

export const TASK_MODULE_ACTIONS_DELETE_LEAD_OPPORTUNITY =
  "task_module.actions.delete_lead_opportunity";

export const TASK_MODULE_ACTIONS_CREATE_CALL_LOGS =
  "task_module.actions.create_call_logs";

export const TASK_MODULE_ACTIONS_CREATE_CALL_LOGS_INTERNAL =
  "task_module.actions.create_call_logs_internal";

export const TASK_MODULE_ACTIONS_UPDATE_CALL_LOGS =
  "task_module.actions.update_call_logs";

export const TASK_MODULE_ACTIONS_READ_CALL_LOGS =
  "task_module.actions.read_call_logs";

export const TASK_MODULE_ACTIONS_DELETE_CALL_LOGS_LINK =
  "task_module.actions.delete_call_logs_link";

export const TASK_MODULE_ACTIONS_CREATE_TICKET =
  "task_module.actions.create_ticket";

export const TASK_MODULE_ACTIONS_UPDATE_TICKET =
  "task_module.actions.update_ticket";

export const TASK_MODULE_ACTIONS_UPDATE_TICKET_TYPE =
  "task_module.actions.update_ticket_type";

export const TASK_MODULE_ACTIONS_READ_TICKET =
  "task_module.actions.read_ticket";

export const TASK_MODULE_ACTIONS_DELETE_TICKET_SERVICE =
  "task_module.actions.delete_ticket_service";

export const TASK_MODULE_ACTIONS_READ_DOCTOR_TICKET =
  "task_module.actions.read_doctor_ticket";

export const TASK_MODULE_ACTIONS_UPSERT_TICKET =
  "task_module.actions.upsert_ticket";

export const TASK_MODULE_ACTIONS_CREATE_PATIENT_SUPPORT_TICKET =
  "task_module.actions.create_patient_support_ticket";

export const TASK_MODULE_ACTIONS_CREATE_DOCTOR_SUPPORT_TICKET =
  "task_module.actions.create_doctor_support_ticket";

export const TASK_MODULE_ACTIONS_CREATE_ASSIGNMENT_SETTINGS =
  "task_module.actions.create_assignment_settings";

export const TASK_MODULE_ACTIONS_UPDATE_ASSIGNMENT_SETTINGS =
  "task_module.actions.update_assignment_settings";

export const TASK_MODULE_ACTIONS_READ_ASSIGNMENT_SETTINGS =
  "task_module.actions.read_assignment_settings";

export const TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET =
  "task_module.actions.disable_assignment_in_tickets";

export const TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT =
  "task_module.actions.disable_add_attachment";

export const TASK_MODULE_ACTIONS_HIDE_ATTACHMENT =
  "task_module.actions.hide_add_attachment";

export const TASK_MODULE_ACTIONS_DISABLE_TICKET_STATUS_UPDATE_TICKET =
  "task_module.actions.disable_ticket_status_update_ticket";

export const TASK_MODULE_ACTIONS_HIDE_DEFAULT_VALUE_SOURCE =
  "task_module.actions.hide_default_value_source";

export const TASK_MODULE_ACTIONS_HIDE_META_TICKETS =
  "task_module.actions.hide_meta_tickets";

export const TASK_MODULE_ACTIONS_HIDE_DETAILS_CARD_TICKETS =
  "task_module.actions.hide_details_card_tickets";

export const TASK_MODULE_ACTIONS_SHOW_CLUSTER_IN_CONTACT =
  "task_module.actions.show_cluster_in_contact";

export const TASK_MODULE_ACTIONS_PHONE_EDITABLE_IN_EDIT_CONTACT =
  "task_module.actions.phone_editable_in_edit_contact";

export const TASK_MODULE_ACTIONS_DISABLE_DUE_DATE_UPDATE_TICKET =
  "task_module.actions.disable_due_date_update_ticket";

export const TASK_MODULE_ACTIONS_DISABLE_PRIORITY_UPDATE_TICKET =
  "task_module.actions.disable_priority_update_ticket";

export const TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_LEAD =
  "task_module.actions.disable_assignment_in_lead";

export const TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA =
  "task_module.actions.get_transaction_data";

export const TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS =
  "task_module.actions.add_internal_remarks";

export const TASK_MODULE_ACTIONS_SHOW_ADD_REMARKS =
  "task_module.actions.show_add_remarks";

export const TASK_MODULE_ACTIONS_UPDATE_TICKET_REMARKS_ONLY =
  "task_module.actions.update_ticket_remarks_only";

export const TASK_MODULE_ACTIONS_CLEAR_REMARKS_IN_EDIT_TICKET =
  "task_module.actions.clear_remarks_in_edit_ticket";

export const TASK_MODULE_ACTIONS_REPLY_TO_EMAIL =
  "task_module.actions.reply_to_email";

export const TASK_MODULE_ACTIONS_CREATE_TASK_TO_ACTION_MAPPING =
  "task_module.actions.create_task_to_action_mapping";

export const TASK_MODULE_ACTIONS_UPDATE_TASK_TO_ACTION_MAPPING =
  "task_module.actions.update_task_to_action_mapping";

export const TASK_MODULE_ACTIONS_READ_TASK_TO_ACTION_MAPPING =
  "task_module.actions.read_task_to_action_mapping";

export const TASK_MODULE_ACTIONS_SOCIAL_PROFILE_MENU =
  "task_module.actions.social_profile_menu";

export const TASK_MODULE_ACTIONS_FACEBOOK_PAGE_MENU =
  "task_module.actions.facebook_page_menu";
export const TASK_MODULE_ACTIONS_SHOW_SLA_PAGE =
  "task_module.actions.show_sla_page";

export const TASK_MODULE_ACTIONS_READ_ACTIVE_AGENT_PROFILE =
  "task_module.actions.get_active_agents_list";

export const TASK_MODULE_ACTIONS_READ_ALL_AGENT_PROFILE =
  "task_module.actions.get_all_agents_list";

export const TASK_MODULE_ACTIONS_CREATE_AGENT_PROFILE =
  "task_module.actions.create_agent_profile";

export const TASK_MODULE_ACTIONS_UPDATE_AGENT_PROFILE =
  "task_module.actions.update_agent_profile";

export const TASK_MODULE_ACTIONS_READ_AGENT_PROFILE =
  "task_module.actions.read_agent_profile";

export const TASK_MODULE_ACTIONS_DISABLE_PAUSE_SHIFT =
  "task_module.actions.disable_pause_shift";

export const TASK_MODULE_ACTIONS_UPDATE_MY_AGENT_PROFILE =
  "task_module.actions.update_my_agent_profile";

export const TASK_MODULE_ACTIONS_GET_MY_AGENT_PROFILE =
  "task_module.actions.get_my_agent_profile";

export const TASK_MODULE_GOOGLE_MY_BUSINESS_ACCOUNT =
  "task_module.actions.show_linked_google_account";

export const TASK_MODULE_ACTIONS_READ = "task_module.actions.read";

export const TASK_MODULE_ACTIONS_CREATE = "task_module.actions.create";

export const TASK_MODULE_ACTIONS_UPDATE = "task_module.actions.update";

export const TASK_MODULE_ACTIONS_DELETE = "task_module.actions.delete";

export const ORDERS_MODULE_ACTIONS_CREATE_ORDER =
  "orders_module.actions.create_order";

export const ORDERS_MODULE_ACTIONS_GET_ALL_ORDERS =
  "orders_module.actions.get_all_orders";

export const ORDERS_MODULE_ACTIONS_GET_ORDER_BY_ID =
  "orders_module.actions.get_order_by_id";

export const ORDERS_MODULE_ACTIONS_UPDATE_ORDER =
  "orders_module.actions.update_order";

export const ORDERS_MODULE_ACTIONS_CREATE_PAYMENT_MODE_MASTER =
  "orders_module.actions.create_payment_mode_master";

export const ORDERS_MODULE_ACTIONS_PAYMENT_MODE_LIST =
  "orders_module.actions.payment_mode_list";

export const ORDERS_MODULE_ACTIONS_ORDER_COUNT_BY_STATUS =
  "orders_module.actions.order_count_by_status";

export const ORDERS_MODULE_ACTIONS_UPDATE_ORDER_STATUS =
  "orders_module.actions.update_order_status";

export const ORDERS_MODULE_ACTIONS_READ = "orders_module.actions.read";

export const ORDERS_MODULE_ACTIONS_CREATE = "orders_module.actions.create";

export const ORDERS_MODULE_ACTIONS_UPDATE = "orders_module.actions.update";

export const ORDERS_MODULE_ACTIONS_DELETE = "orders_module.actions.delete";

export const PAYMENT_MODULE_ACTIONS_CREATE_PAYMENT_LINK =
  "payment_module.actions.create_payment_link";

export const PAYMENT_MODULE_ACTIONS_UPDATE_APPOINTMENT_PAYMENT_STATUS =
  "payment_module.actions.update_appointment_payment_status";

export const PAYMENT_MODULE_ACTIONS_GET_PAYMENT_SUMMARY_BY_ID =
  "payment_module.actions.get_payment_summary_by_id";

export const PAYMENT_MODULE_ACTIONS_SUCCESS_PAYMENT_REDIRECT =
  "payment_module.actions.success_payment_redirect";

export const PAYMENT_MODULE_ACTIONS_FAILED_PAYMENT_REDIRECT =
  "payment_module.actions.failed_payment_redirect";

export const PAYMENT_MODULE_ACTIONS_CANCEL_PAYMENT_REDIRECT =
  "payment_module.actions.cancel_payment_redirect";

export const PAYMENT_MODULE_ACTIONS_PAYMENT_LINK_REDIRECT =
  "payment_module.actions.payment_link_redirect";

export const PAYMENT_MODULE_ACTIONS_READ = "payment_module.actions.read";

export const PAYMENT_MODULE_ACTIONS_CREATE = "payment_module.actions.create";

export const PAYMENT_MODULE_ACTIONS_UPDATE = "payment_module.actions.update";

export const PAYMENT_MODULE_ACTIONS_DELETE = "payment_module.actions.delete";

export const PHARMACY_MODULE_ACTIONS_CREATE_MANY_SYNC =
  "pharmacy_module.actions.create_many_sync";

export const PHARMACY_MODULE_ACTIONS_CREATE_PHARMACY_REQUEST =
  "pharmacy_module.actions.create_pharmacy_request";

export const PHARMACY_MODULE_ACTIONS_VIEW_ORDER_REQUESTS =
  "pharmacy_module.actions.view_order_requests";

export const PHARMACY_MODULE_ACTIONS_VIEW_REQUEST_BY_ID =
  "pharmacy_module.actions.view_request_by_id";

export const PHARMACY_MODULE_ACTIONS_ADD_DRUG_ITEMS =
  "pharmacy_module.actions.add_drug_items";

export const PHARMACY_MODULE_ACTIONS_UPDATE_ORDER_REQUESTS =
  "pharmacy_module.actions.update_order_requests";

export const PHARMACY_MODULE_ACTIONS_UPDATE_ORDER_ITEMS =
  "pharmacy_module.actions.update_order_items";

export const PHARMACY_MODULE_ACTIONS_UPDATE_REQUEST_STATUS =
  "pharmacy_module.actions.update_request_status";

export const PHARMACY_MODULE_ACTIONS_DELETE_DRUG_ITEMS =
  "pharmacy_module.actions.delete_drug_items";

export const PHARMACY_MODULE_ACTIONS_ADDRESS_FROM_MAPS =
  "pharmacy_module.actions.address_from_maps";

export const PHARMACY_MODULE_ACTIONS_SEARCH_DRUG_MAX_API =
  "pharmacy_module.actions.search_drug_max_api";

export const PHARMACY_MODULE_ACTIONS_BATCH_PRICE_MAX_API =
  "pharmacy_module.actions.batch_price_max_api";

export const PHARMACY_MODULE_ACTIONS_SEARCH_DRUGS =
  "pharmacy_module.actions.search_drugs";

export const PHARMACY_MODULE_ACTIONS_CREATE_BULK_DRUGS =
  "pharmacy_module.actions.create_bulk_drugs";

export const PHARMACY_MODULE_ACTIONS_PLACE_ORDER =
  "pharmacy_module.actions.place_order";

export const PHARMACY_MODULE_ACTIONS_UPDATE_CART_MAX_TO_THB =
  "pharmacy_module.actions.update_cart_max_to_thb";

export const PHARMACY_MODULE_ACTIONS_READ = "pharmacy_module.actions.read";

export const PHARMACY_MODULE_ACTIONS_CREATE = "pharmacy_module.actions.create";

export const PHARMACY_MODULE_ACTIONS_UPDATE = "pharmacy_module.actions.update";

export const PHARMACY_MODULE_ACTIONS_DELETE = "pharmacy_module.actions.delete";

export const LOGIN_MODULE_ACTIONS_GET_OTP = "login_module.actions.get_otp";

export const LOGIN_MODULE_ACTIONS_CREATE_PASSWORD =
  "login_module.actions.create_password";

export const LOGIN_MODULE_ACTIONS_GET_MY_PERMISSIONS =
  "login_module.actions.get_my_permissions";

export const LOGIN_MODULE_ACTIONS_VERIFY_TOKEN =
  "login_module.actions.verify_token";

export const LOGIN_MODULE_ACTIONS_FORGET_PASSWORD =
  "login_module.actions.forget_password";

export const LOGIN_MODULE_ACTIONS_FORGET_PASSWORD_WITH_OTP =
  "login_module.actions.forget_password_with_otp";

export const LOGIN_MODULE_ACTIONS_RESET_PASSWORD =
  "login_module.actions.reset_password";

export const LOGIN_MODULE_ACTIONS_RESET_PASSWORD_WITH_OTP =
  "login_module.actions.reset_password_with_otp";

export const LOGIN_MODULE_ACTIONS_CHANGE_PASSWORD =
  "login_module.actions.change_password";

export const LOGIN_MODULE_ACTIONS_GET_EMAIL_CONFIRMATION_TOKEN =
  "login_module.actions.get_email_confirmation_token";

export const LOGIN_MODULE_ACTIONS_GET_EMAIL_CONFIRMATION_TOKEN_DOCTOR =
  "login_module.actions.get_email_confirmation_token_doctor";

export const LOGIN_MODULE_ACTIONS_GET_ANONYMOUS_TOKEN =
  "login_module.actions.get_anonymous_token";

export const LOGIN_MODULE_ACTIONS_GET_INTERNAL_MEDI_TOKEN =
  "login_module.actions.get_internal_medi_token";

export const LOGIN_MODULE_ACTIONS_GET_ATTENDANT_TOKEN =
  "login_module.actions.get_attendant_token";

export const LOGIN_MODULE_ACTIONS_VERIFY_OTP =
  "login_module.actions.verify_otp";

export const LOGIN_MODULE_ACTIONS_LOGIN_WITH_PASSWORD =
  "login_module.actions.login_with_password";

export const LOGIN_MODULE_ACTIONS_LOGIN_WITH_PIN =
  "login_module.actions.login_with_pin";

export const LOGIN_MODULE_ACTIONS_ATTEMPT_MEDI_INTERNAL =
  "login_module.actions.attempt_medi_internal";

export const LOGIN_MODULE_ACTIONS_SWITCH_APPLICATION =
  "login_module.actions.switch_application";

export const LOGIN_MODULE_ACTIONS_IMPERSONATE_USER =
  "login_module.actions.impersonate_user";

export const LOGIN_MODULE_ACTIONS_LOG_OUT_APPLICATION =
  "login_module.actions.log_out_application";

export const LOGIN_MODULE_ACTIONS_READ = "login_module.actions.read";

export const LOGIN_MODULE_ACTIONS_CREATE = "login_module.actions.create";

export const LOGIN_MODULE_ACTIONS_UPDATE = "login_module.actions.update";

export const LOGIN_MODULE_ACTIONS_DELETE = "login_module.actions.delete";

export const DIAGNOSTICS_MODULE_ACTIONS_CREATE_MANY_SYNC =
  "diagnostics_module.actions.create_many_sync";

export const DIAGNOSTICS_MODULE_ACTIONS_SEARCH_DIAGNOSTICS_ITEMS =
  "diagnostics_module.actions.search_diagnostics_items";

export const DIAGNOSTICS_MODULE_ACTIONS_ADD_ITEMS_CART =
  "diagnostics_module.actions.add_items_cart";

export const DIAGNOSTICS_MODULE_ACTIONS_DELETE_ITEMS_CART =
  "diagnostics_module.actions.delete_items_cart";

export const DIAGNOSTICS_MODULE_ACTIONS_ADD_ITEMS_MASTER =
  "diagnostics_module.actions.add_items_master";

export const DIAGNOSTICS_MODULE_ACTIONS_CREATE_ORDER =
  "diagnostics_module.actions.create_order";

export const DIAGNOSTICS_MODULE_ACTIONS_PLACE_ORDER =
  "diagnostics_module.actions.place_order";

export const DIAGNOSTICS_MODULE_ACTIONS_ADD_ITEM_TO_ORDER =
  "diagnostics_module.actions.add_item_to_order";

export const DIAGNOSTICS_MODULE_ACTIONS_ADD_BULK_ITEMS =
  "diagnostics_module.actions.add_bulk_items";

export const DIAGNOSTICS_MODULE_ACTIONS_CREATE_ORDER_APPOINTMENT =
  "diagnostics_module.actions.create_order_appointment";

export const DIAGNOSTICS_MODULE_ACTIONS_CREATE_CART_APPOINTMENT =
  "diagnostics_module.actions.create_cart_appointment";

export const DIAGNOSTICS_MODULE_ACTIONS_BULK_MASTER_LOADING =
  "diagnostics_module.actions.bulk_master_loading";

export const DIAGNOSTICS_MODULE_ACTIONS_GET_SLOTS =
  "diagnostics_module.actions.get_slots";

export const DIAGNOSTICS_MODULE_ACTIONS_GET_ORDER_STATUS_BY_LEAD_ID =
  "diagnostics_module.actions.get_order_status_by_lead_id";

export const DIAGNOSTICS_MODULE_ACTIONS_FETCH_ORDER_LOG =
  "diagnostics_module.actions.fetch_order_log";

export const DIAGNOSTICS_MODULE_ACTIONS_PAY_LATER_STATUS_UPDATE =
  "diagnostics_module.actions.pay_later_status_update";

export const DIAGNOSTICS_MODULE_ACTIONS_RESERVE_SLOTS =
  "diagnostics_module.actions.reserve_slots";

export const DIAGNOSTICS_MODULE_ACTIONS_SEARCH_ITEMS =
  "diagnostics_module.actions.search_items";

export const DIAGNOSTICS_MODULE_ACTIONS_DRUG_BULK_ITEM_CREATE =
  "diagnostics_module.actions.drug_bulk_item_create";

export const DIAGNOSTICS_MODULE_ACTIONS_GET_ITEMS =
  "diagnostics_module.actions.get_items";

export const DIAGNOSTICS_MODULE_ACTIONS_FETCH_ITEM_PRICE =
  "diagnostics_module.actions.fetch_item_price";

export const DIAGNOSTICS_MODULE_ACTIONS_GET_PAYMENT_LINK =
  "diagnostics_module.actions.get_payment_link";

export const DIAGNOSTICS_MODULE_ACTIONS_FETCH_SPOKE_FROM_PINCODE =
  "diagnostics_module.actions.fetch-spoke-from-pincode";

export const DIAGNOSTICS_MODULE_ACTIONS_FETCH_ITEMS_FROM_HOSPITALS =
  "diagnostics_module.actions.fetch-items-from-hospitals";

export const DIAGNOSTICS_MODULE_ACTIONS_FETCH_DGC_ITEM_PRICE =
  "diagnostics_module.actions.fetch-dgc-item-price";

export const DIAGNOSTICS_MODULE_ACTIONS_READ =
  "diagnostics_module.actions.read";

export const DIAGNOSTICS_MODULE_ACTIONS_CREATE =
  "diagnostics_module.actions.create";

export const DIAGNOSTICS_MODULE_ACTIONS_UPDATE =
  "diagnostics_module.actions.update";

export const DIAGNOSTICS_MODULE_ACTIONS_DELETE =
  "diagnostics_module.actions.delete";

export const SLOT_MODULE_ACTIONS_GET_PROCEDURE_CONFIG =
  "slot_module.actions.get_procedure_config";

export const SLOT_MODULE_ACTIONS_GET_PROCEDURE_CONFIG_BY_ID =
  "slot_module.actions.get_procedure_config_by_id";

export const SLOT_MODULE_ACTIONS_CREATE_PROCEDURE_CONFIG =
  "slot_module.actions.create_procedure_config";

export const SLOT_MODULE_ACTIONS_UPDATE_PROCEDURE_CONFIG =
  "slot_module.actions.update_procedure_config";

export const SLOT_MODULE_ACTIONS_GET_PROPERTY =
  "slot_module.actions.get_property";

export const SLOT_MODULE_ACTIONS_CREATE_PROPERTY =
  "slot_module.actions.create_property";

export const SLOT_MODULE_ACTIONS_UPDATE_PROPERTY =
  "slot_module.actions.update_property";

export const SLOT_MODULE_ACTIONS_GET_OFF_DAYS =
  "slot_module.actions.get_off_days";

export const SLOT_MODULE_ACTIONS_GET_AVAILABLE_DAYS =
  "slot_module.actions.get_available_days";

export const SLOT_MODULE_ACTIONS_CREATE_OFF_DAYS =
  "slot_module.actions.create_off_days";

export const SLOT_MODULE_ACTIONS_UPDATE_OFF_DAYS =
  "slot_module.actions.update_off_days";

export const SLOT_MODULE_ACTIONS_GET_AVAILABLE_DAY =
  "slot_module.actions.get_available_day";

export const SLOT_MODULE_ACTIONS_CREATE_AVAILABLE_DAY =
  "slot_module.actions.create_available_day";

export const SLOT_MODULE_ACTIONS_UPDATE_AVAILABLE_DAY =
  "slot_module.actions.update_available_day";

export const SLOT_MODULE_ACTIONS_REMOVE_AVAILABLE_DAY =
  "slot_module.actions.remove_available_day";

export const SLOT_MODULE_ACTIONS_GET_DOCTOR_AVAILABLE_SLOTS =
  "slot_module.actions.get_doctor_available_slots";

export const SLOT_MODULE_ACTIONS_CREATE_SLOT_ROASTER =
  "slot_module.actions.create_slot_roaster";

export const SLOT_MODULE_ACTIONS_CREATE_DOCTOR_NEXT_AVAILABLE_SLOT =
  "slot_module.actions.create_doctor_next_available_slot";

export const SLOT_MODULE_ACTIONS_GET_PRACTICE =
  "slot_module.actions.get_practice";

export const SLOT_MODULE_ACTIONS_UPDATE_PRACTICE =
  "slot_module.actions.update_practice";

export const SLOT_MODULE_ACTIONS_MARK_DELAY = "slot_module.actions.mark_delay";

export const SLOT_MODULE_ACTIONS_INSTANT_AVAILABLE_DOCTORS =
  "slot_module.actions.instant_available_doctors";

export const SLOT_MODULE_ACTIONS_INSTANT_DOCTORS_SCHEDULE =
  "slot_module.actions.instant_doctors_schedule";

export const SLOT_MODULE_ACTIONS_CREATE_NEXT_AVAILABLE_SCHEDULE_FOR_ALL_DOCTORS =
  "slot_module.actions.create_next_available_schedule_for_all_doctors";

export const SLOT_MODULE_ACTIONS_CREATE_TEMP_NEXT_AVAILABILITY_FROM_SYNC =
  "slot_module.actions.create_temp_next_availability_from_sync";

export const SLOT_MODULE_ACTIONS_GET_NEXT_AVAILABILITY_FROM_TEMP_TABLE =
  "slot_module.actions.get_next_availability_from_temp_table";

export const SLOT_MODULE_ACTIONS_DELETE_NEXT_AVAILABILITY_FROM_TEMP_TABLE =
  "slot_module.actions.delete_next_availability_from_temp_table";

export const SLOT_MODULE_ACTIONS_READ = "slot_module.actions.read";

export const SLOT_MODULE_ACTIONS_CREATE = "slot_module.actions.create";

export const SLOT_MODULE_ACTIONS_UPDATE = "slot_module.actions.update";

export const SLOT_MODULE_ACTIONS_DELETE = "slot_module.actions.delete";

export const VIDEOCHAT_MODULE_ACTIONS_VALIDATE_VC_ROOM =
  "videochat_module.actions.validate_vc_room";

export const VIDEOCHAT_MODULE_ACTIONS_GET_VC_ROOM_ACCESS_TOKEN =
  "videochat_module.actions.get_vc_room_access_token";

export const VIDEOCHAT_MODULE_ACTIONS_GET_VC_ROOM_OPEN_TOKEN =
  "videochat_module.actions.get_vc_room_open_token";

export const VIDEOCHAT_MODULE_ACTIONS_READ = "videochat_module.actions.read";

export const VIDEOCHAT_MODULE_ACTIONS_CREATE =
  "videochat_module.actions.create";

export const VIDEOCHAT_MODULE_ACTIONS_UPDATE =
  "videochat_module.actions.update";

export const VIDEOCHAT_MODULE_ACTIONS_DELETE =
  "videochat_module.actions.delete";

export const MASTER_MODULE_ACTIONS_READ = "master_module.actions.read";

export const MASTER_MODULE_ACTIONS_CREATE = "master_module.actions.create";

export const MASTER_MODULE_ACTIONS_UPDATE = "master_module.actions.update";

export const MASTER_MODULE_ACTIONS_DELETE = "master_module.actions.delete";

export const NOTIFICATION_MODULE_ACTIONS_READ =
  "notification_module.actions.read";

export const NOTIFICATION_MODULE_ACTIONS_CREATE =
  "notification_module.actions.create";

export const NOTIFICATION_MODULE_ACTIONS_UPDATE =
  "notification_module.actions.update";

export const NOTIFICATION_MODULE_ACTIONS_DELETE =
  "notification_module.actions.delete";

export const SOS_MODULE_ACTIONS_ASSIGN_ROLES =
  "sos_module.actions.assign_roles";

export const SOS_MODULE_ACTIONS_CONTACT = "sos_module.actions.contact";

export const SOS_MODULE_ACTIONS_GET_NEARESTBRANCHES =
  "sos_module.actions.get_nearestBranches";

export const SOS_MODULE_ACTIONS_READ = "sos_module.actions.read";

export const SOS_MODULE_ACTIONS_CREATE = "sos_module.actions.create";

export const SOS_MODULE_ACTIONS_UPDATE = "sos_module.actions.update";

export const SOS_MODULE_ACTIONS_DELETE = "sos_module.actions.delete";

export const INTEGRATION_MODULE_ACTIONS_ASSIGN_ROLES =
  "integration_module.actions.assign_roles";

export const INTEGRATION_MODULE_ACTIONS_READ =
  "integration_module.actions.read";

export const INTEGRATION_MODULE_ACTIONS_CREATE =
  "integration_module.actions.create";

export const INTEGRATION_MODULE_ACTIONS_UPDATE =
  "integration_module.actions.update";

export const INTEGRATION_MODULE_ACTIONS_DELETE =
  "integration_module.actions.delete";

export const MAXDATA_MODULE_ACTIONS_CREATE_SOURCE =
  "maxdata_module.actions.create_source";

export const MAXDATA_MODULE_ACTIONS_GET_BILL_PDF =
  "maxdata_module.actions.get_bill_pdf";

export const MAXDATA_MODULE_ACTIONS_GET_DOC_URL =
  "maxdata_module.actions.get_doc_url";

export const MAXDATA_MODULE_ACTIONS_CREATE_MULTI_TASK_DATA =
  "maxdata_module.actions.create_multi_task_data";

export const MAXDATA_MODULE_ACTIONS_CREATE_MEDI_TASK =
  "maxdata_module.actions.create_medi_task";

export const MAXDATA_MODULE_ACTIONS_CREATE_MEDI_TASK_DETAILS =
  "maxdata_module.actions.create_medi_task_details";

export const MAXDATA_MODULE_ACTIONS_GET_MEDI_TASK =
  "maxdata_module.actions.get_medi_task";

export const MAXDATA_MODULE_ACTIONS_UPDATE_MEDIA_TASK =
  "maxdata_module.actions.update_media_task";

export const MAXDATA_MODULE_ACTIONS_GET_LAB_ORDERS =
  "maxdata_module.actions.get_lab_orders";

export const MAXDATA_MODULE_ACTIONS_READ = "maxdata_module.actions.read";

export const MAXDATA_MODULE_ACTIONS_CREATE = "maxdata_module.actions.create";

export const MAXDATA_MODULE_ACTIONS_UPDATE = "maxdata_module.actions.update";

export const MAXDATA_MODULE_ACTIONS_DELETE = "maxdata_module.actions.delete";

export const SETTINGS_MODULE_ACTIONS_GET_USER_SETTING =
  "settings_module.actions.get_user_setting";

export const SETTINGS_MODULE_ACTIONS_UPDATE_USER_SETTING =
  "settings_module.actions.update_user_setting";

export const SETTINGS_MODULE_ACTIONS_UPDATE_SETTING =
  "settings_module.actions.update_setting";

export const SETTINGS_MODULE_ACTIONS_GET_SETTING =
  "settings_module.actions.get_setting";

export const SETTINGS_MODULE_ACTIONS_CAN_CHAT =
  "settings_module.actions.can_chat";

export const SETTINGS_MODULE_ACTIONS_READ = "settings_module.actions.read";

export const SETTINGS_MODULE_ACTIONS_CREATE = "settings_module.actions.create";

export const SETTINGS_MODULE_ACTIONS_UPDATE = "settings_module.actions.update";

export const SETTINGS_MODULE_ACTIONS_DELETE = "settings_module.actions.delete";

export const ROOMLOGS_MODULE_ACTIONS_CREATE_ROOM_LOG =
  "roomlogs_module.actions.create_room_log";

export const ROOMLOGS_MODULE_ACTIONS_GET_ROOM_LOG =
  "roomlogs_module.actions.get_room_log";

export const ROOMLOGS_MODULE_ACTIONS_GET_ALL_ROOM_LOGS =
  "roomlogs_module.actions.get_all_room_logs";

export const ROOMLOGS_MODULE_ACTIONS_READ = "roomlogs_module.actions.read";

export const ROOMLOGS_MODULE_ACTIONS_CREATE = "roomlogs_module.actions.create";

export const ROOMLOGS_MODULE_ACTIONS_UPDATE = "roomlogs_module.actions.update";

export const ROOMLOGS_MODULE_ACTIONS_DELETE = "roomlogs_module.actions.delete";

export const TEAM_TYPE_MODULE_ACTIONS_READ = "team_type_module.actions.read";

export const TEAM_TYPE_MODULE_ACTIONS_CREATE =
  "team_type_module.actions.create";

export const TEAM_TYPE_MODULE_ACTIONS_UPDATE =
  "team_type_module.actions.update";

export const TEAM_TYPE_MODULE_ACTIONS_DELETE =
  "team_type_module.actions.delete";

export const PRESCRIPTION_MODULE_ACTIONS_PATIENT_PRESCRIPTION =
  "prescription_module.actions.patient_prescription";

export const PRESCRIPTION_MODULE_ACTIONS_GET_PRESCRIPTION =
  "prescription_module.actions.get_prescription";

export const PRESCRIPTION_MODULE_ACTIONS_GET_LABREPORT =
  "prescription_module.actions.get_labreport";

export const PRESCRIPTION_MODULE_ACTIONS_GET_PRESCRIPTION_DETAILS =
  "prescription_module.actions.get_prescription_details";

export const PRESCRIPTION_MODULE_ACTIONS_GET_PRESCRIPTION_BY_ORDER_ID =
  "prescription_module.actions.get_prescription_by_order_id";

export const PRESCRIPTION_MODULE_ACTIONS_GET_PRESCRIPTION_URL =
  "prescription_module.actions.get_prescription_url";

export const PRESCRIPTION_MODULE_ACTIONS_REMOVE_PRESCRIPTION =
  "prescription_module.actions.remove_prescription";

export const PRESCRIPTION_MODULE_ACTIONS_GET_VITALS =
  "prescription_module.actions.get_vitals";

export const PRESCRIPTION_MODULE_ACTIONS_GET_EMR =
  "prescription_module.actions.get_emr";

export const PRESCRIPTION_MODULE_ACTIONS_PATIENT_DISCHARGE_SUMMARY =
  "prescription_module.actions.patient_discharge_summary";

export const PRESCRIPTION_MODULE_ACTIONS_GET_PRESCRIPTION_COUNT =
  "prescription_module.actions.get_prescription_count";

export const PRESCRIPTION_MODULE_ACTIONS_UPDATE_PRESCRIPTION =
  "prescription_module.actions.update_prescription";

export const PRESCRIPTION_MODULE_ACTIONS_READ =
  "prescription_module.actions.read";

export const PRESCRIPTION_MODULE_ACTIONS_CREATE =
  "prescription_module.actions.create";

export const PRESCRIPTION_MODULE_ACTIONS_UPDATE =
  "prescription_module.actions.update";

export const PRESCRIPTION_MODULE_ACTIONS_DELETE =
  "prescription_module.actions.delete";

export const REQUEST_LOGS_MODULE_ACTIONS_READ =
  "request_logs_module.actions.read";

export const REQUEST_LOGS_MODULE_ACTIONS_CREATE =
  "request_logs_module.actions.create";

export const REQUEST_LOGS_MODULE_ACTIONS_UPDATE =
  "request_logs_module.actions.update";

export const REQUEST_LOGS_MODULE_ACTIONS_DELETE =
  "request_logs_module.actions.delete";

export const NUDGE_MODULE_ACTIONS_GET_PATIENT_NUDGES =
  "nudge_module.actions.get_patient_nudges";

export const NUDGE_MODULE_ACTIONS_READ = "nudge_module.actions.read";

export const NUDGE_MODULE_ACTIONS_CREATE = "nudge_module.actions.create";

export const NUDGE_MODULE_ACTIONS_UPDATE = "nudge_module.actions.update";

export const NUDGE_MODULE_ACTIONS_DELETE = "nudge_module.actions.delete";

export const IPD_MODULE_ACTIONS_GET_IPD_APPOINTMENTS =
  "ipd_module.actions.get_ipd_appointments";

export const IPD_MODULE_ACTIONS_CREATE_IPD_APPOINTMENT =
  "ipd_module.actions.create_ipd_appointment";

export const IPD_MODULE_ACTIONS_READ = "ipd_module.actions.read";

export const IPD_MODULE_ACTIONS_CREATE = "ipd_module.actions.create";

export const IPD_MODULE_ACTIONS_UPDATE = "ipd_module.actions.update";

export const IPD_MODULE_ACTIONS_DELETE = "ipd_module.actions.delete";

export const INTENT_MODULE_ACTIONS_CREATE_PATIENT_INTENT =
  "intent_module.actions.create_patient_intent";

export const INTENT_MODULE_ACTIONS_GET_PATIENT_INTENT =
  "intent_module.actions.get_patient_intent";

export const INTENT_MODULE_ACTIONS_GET_PATIENT_INTENT_BY_ID =
  "intent_module.actions.get_patient_intent_by_id";

export const INTENT_MODULE_ACTIONS_GET_INTENT_PAYMENT_LINK =
  "intent_module.actions.get_intent_payment_link";

export const INTENT_MODULE_ACTIONS_READ = "intent_module.actions.read";

export const INTENT_MODULE_ACTIONS_CREATE = "intent_module.actions.create";

export const INTENT_MODULE_ACTIONS_UPDATE = "intent_module.actions.update";

export const INTENT_MODULE_ACTIONS_DELETE = "intent_module.actions.delete";

export const TEMPLATE_MODULE_ACTIONS_ASSIGN_ROLES =
  "template_module.actions.assign_roles";

export const TEMPLATE_MODULE_ACTIONS_CREATE_TEMPLATE =
  "template_module.actions.create_template";

export const TEMPLATE_MODULE_ACTIONS_GET_TEMPLATES =
  "template_module.actions.get_templates";

export const TEMPLATE_MODULE_ACTIONS_GET_ALL_TEMPLATES =
  "template_module.actions.get_all_templates";

export const TEMPLATE_MODULE_ACTIONS_GET_TEMPLATE_BY_ID =
  "template_module.actions.get_template_by_id";

export const TEMPLATE_MODULE_ACTIONS_DELETE_TEMPLATE_BY_ID =
  "template_module.actions.delete_template_by_id";

export const TEMPLATE_MODULE_ACTIONS_GET_SIGNED_URL =
  "template_module.actions.get_signed_url";

export const TEMPLATE_MODULE_ACTIONS_CREATE_GROUP =
  "template_module.actions.create_group";

export const TEMPLATE_MODULE_ACTIONS_GET_GROUPS =
  "template_module.actions.get_groups";

export const TEMPLATE_MODULE_ACTIONS_GET_GROUP_BY_ID =
  "template_module.actions.get_group_by_id";

export const TEMPLATE_MODULE_ACTIONS_ADD_TEMPLATE_GROUP =
  "template_module.actions.add_template_group";

export const TEMPLATE_MODULE_ACTIONS_GET_TEMPLATE_IN_GROUP =
  "template_module.actions.get_template_in_group";

export const TEMPLATE_MODULE_ACTIONS_DELETE_GROUP_BY_ID =
  "template_module.actions.delete_group_by_id";

export const TEMPLATE_MODULE_ACTIONS_READ = "template_module.actions.read";

export const TEMPLATE_MODULE_ACTIONS_CREATE = "template_module.actions.create";

export const TEMPLATE_MODULE_ACTIONS_UPDATE = "template_module.actions.update";

export const TEMPLATE_MODULE_ACTIONS_DELETE = "template_module.actions.delete";

export const RAG_MODULE_PERMISSIONS = {
  default: "rag_module",
  ask_question: "rag_module.actions.ask_question",
  knowledge_bank_create: "rag_module.actions.knowledge_bank_create",
  knowledge_bank_read: "rag_module.actions.knowledge_bank_read",
  knowledge_bank_update: "rag_module.actions.knowledge_bank_update",
  knowledge_bank_delete: "rag_module.actions.knowledge_bank_delete",
  documents_create: "rag_module.actions.documents_create",
  documents_read: "rag_module.actions.documents_read",
  documents_update: "rag_module.actions.documents_update",
  documents_delete: "rag_module.actions.documents_delete",
};
