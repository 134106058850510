import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewContact,
  createContactFromPatient,
  fetchContactAndPatientByMobile,
} from "../../../../redux/reducers/contacts.slice";
import { patchLead } from "../../../../redux/reducers/leads.slice";
import {
  fetchMasterGender,
  fetchMasterNationality,
} from "../../../../redux/reducers/master.slice";
import { RootState } from "../../../../shared/constants";
import ListCard from "../../../../shared/layouts/crm-listcard.layout";
import ResultModalLayout from "../../../../shared/layouts/resultmodal.layout";
import { Contact } from "../../../../shared/types/contact.type";
import { countryCodes } from "../../../../shared/Utils/dial-codes";
import {
  dobToAge,
  formatDropDownValue,
} from "../../../../shared/Utils/utilities";

const { Search } = Input;
const { Option } = Select;
const { TextArea } = Input;

type Props = {
  phone?: string;
  contacts?: Array<Contact>;
  leadFormVisible: boolean;
  setLeadFormVisible: (val: any) => void;
  saveLeadSuccess: () => void;
  leadId?: any;
  leadDetails?: any;
};

function LinkLeadContactForm({
  phone = "",
  leadFormVisible = false,
  setLeadFormVisible,
  saveLeadSuccess,
  leadId = null,
  leadDetails = {},
}: Props) {
  const dispatch = useDispatch();
  const { loading_cp, cpCombined } = useSelector(
    (state: RootState) => state.contact
  );
  const { gender, nationality, taskTypes, taskSubTypes, taskDropDown } =
    useSelector((state: RootState) => state.master);
  const [formLoading, setFormLoading] = useState(false);
  const [addNewContactVar, setaddNewContactVar] = useState(false);
  const [contactList, setContactList] = useState(false as any);
  const [phoneNumber, setPhoneNumber] = useState(phone as any);
  const [modalVisible, setModalVisible] = useState(false as any);
  const [leadData, setLeadData] = useState(false as any);
  const [form] = Form.useForm();
  const [countryCode, setCountryCode] = useState("+91");
  const { t } = useTranslation()

  useEffect(() => {
    phone !== "" && dispatch(fetchContactAndPatientByMobile(phone));
    gender?.length == 0 && dispatch(fetchMasterGender({}));
    nationality?.length == 0 && dispatch(fetchMasterNationality({}));
  }, [phone]);
  useEffect(() => {
    setCountryCode(
      cpCombined.length > 0 && cpCombined[0]?.country_code
        ? "+" + cpCombined[0]?.country_code
        : "+91"
    );
  }, [cpCombined]);

  useEffect(() => {
    if (phoneNumber !== "") {
      setContactList(
        cpCombined.map((val: any) => {
          return {
            ...val,
            id: val.id ? val.id : val.patient_id ? val.patient_id : val.uhid,
          };
        })
      );
      setaddNewContactVar(false);
    }
  }, [cpCombined]);

  const onPhoneSearch = (value: any) => {
    if (value.length < 8 && value.length > 12) {
      message.error("Please provide valid phone number!");
      return;
    }
    setPhoneNumber(value);
    dispatch(fetchContactAndPatientByMobile(value));
  };
  const onChangePhone = () => {
    setContactList(null as any);
    form.setFieldsValue({ contact_id: null });
  };

  const handleContactChange = (value: any) => {
    if (value == "new") {
      setaddNewContactVar(true);
    } else {
      setaddNewContactVar(false);
    }
  };
  const onSaveLead = async (value: any) => {
    setFormLoading(true);
    value.country_code = countryCode.replace("+", "");
    let contact_id = value.contact_id;
    const contactData = contactList?.filter((val: any) => {
      return val.id === contact_id;
    })[0];
    if (contactData?.source === "patient") {
      const res = await dispatch(
        createContactFromPatient({
          patient_id: contact_id,
          uhid: contactData?.uhid,
        })
      );
      if (res?.payload?.data?.statusCode == 200) {
        contact_id = res?.payload?.data?.data?.id;
      } else {
        return;
      }
    }
    if (contact_id == "new" && addNewContactVar) {
      const values = {
        country_code: countryCode.replace("+", ""),
        first_name: value.first_name,
        last_name: value.last_name,
        phone: value.phone_number,
        gender_id: value.gender_id,
        dob: value.dob,
        email: value.email_id,
        nationality_id: value.nationality_id,
      };
      const res = await dispatch(addNewContact(values));
      if (res?.payload?.data?.statusCode == 200) {
        contact_id = res?.payload?.data?.data?.id;
      } else {
        return;
      }
    }

    let data = {
      contact_id: contact_id,
    } as any;
    dispatch(patchLead({ id: leadId, data: data })).then((val: any) => {
      setFormLoading(false);
      if (val?.payload?.data?.statusCode == 200) {
        setModalVisible(true);
      }
    });
  };
  const onCancelModal = () => {
    saveLeadSuccess();
    setModalVisible(false);
  };
  const onSaveLeadFailed = (value: any) => {
    console.log(value);
  };

  let genderObj: any = {};
  gender?.forEach((val: any) => {
    genderObj[val?.id] = val?.name;
  });
  let nationalityObj: any = {};
  nationality?.forEach((val: any) => {
    nationalityObj[val?.id] = val?.name;
  });
  useEffect(() => {
    if (!loading_cp && phoneNumber !== "" && cpCombined.length > 0) {
      let setId = cpCombined[0].id;
      form.setFieldsValue({ contact_id: setId });
    } else {
      form.setFieldsValue({ contact_id: null });
    }
  }, [cpCombined]);

  useEffect(() => {
    if (nationality.length > 0) {
      form.setFieldsValue({
        nationality_id: nationality
          .filter((val: any) => {
            return val.name === "indian";
          })
          .map((val: any) => {
            return val.id;
          })
          .toString(),
      });
    }
  }, [nationality]);

  const prefixSelector = (
    // <Form.Item name="country_code" noStyle initialValue={countryCode}>
    <Select
      showSearch
      // disabled
      style={{ width: 100 }}
      value={countryCode}
      onChange={(evt) => setCountryCode(evt)}
      className={"noborder"}
      defaultValue={countryCode}
    >
      {countryCodes.map((item) => {
        return (
          item.show === true && (
            <Select.Option value={item.dial_code}>
              <Space>
                <span>{item.flag}</span>
                <span>{item.dial_code}</span>
              </Space>
            </Select.Option>
          )
        );
      })}
    </Select>
  );

  return (
    <>
      <Modal
        title="Link Contact"
        centered
        visible={leadFormVisible}
        onCancel={() => setLeadFormVisible(false)}
        width={1000}
        footer={false}
        className="modalFormButton form-modal"
        style={{ paddingBottom: "0px" }}
      >
        <Form
          form={form}
          name={"lead_form"}
          className="paddingx20 crm-form full-width cover create-lead-form"
          onFinish={onSaveLead}
          onFinishFailed={onSaveLeadFailed}
          initialValues={{
            phone_number: phone,
            assign_to: "",
          }}
        >
          <Row gutter={32}>
            <Col span={12}>
              {leadDetails?.service && (
                <>
                  <Row gutter={32} justify="center">
                    <Col span={24}>
                      <ListCard
                        details={leadDetails.service}
                        title={leadDetails.title}
                      ></ListCard>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            <Col span={12}>
              <Row gutter={32}>
                <Col>
                  <h4 className="text-dark">Choose Contact</h4>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col
                  {...(leadDetails?.service
                    ? { span: 24 }
                    : { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 })}
                >
                  <Form.Item
                    name={"phone_number"}
                    label="Phone Number"
                    rules={[
                      { required: true, message: "Please enter phone number" },
                    ]}
                  >
                    <Search
                      placeholder="input search text"
                      onChange={onChangePhone}
                      onSearch={onPhoneSearch}
                      maxLength={12}
                      addonBefore={prefixSelector}
                    />
                  </Form.Item>
                </Col>
                <Col
                  {...(leadDetails?.service
                    ? { span: 24 }
                    : { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 })}
                >
                  <Form.Item
                    name={"contact_id"}
                    label="Contact Name"
                    rules={[
                      { required: true, message: "Please select contact" },
                    ]}
                  >
                    <Select
                      onChange={handleContactChange}
                      placeholder={"Choose Contact"}
                      allowClear={true}
                      showSearch
                      optionFilterProp="children"
                    >
                      {contactList &&
                        contactList.map((e: any) => {
                          return (
                            <Option key={e.id} value={e.id}>
                              {e.first_name} {e.last_name}{" "}
                              {e.dob || e.gender ? "(" : ""}
                              {e.dob && (
                                <small className="text-secondary">
                                  {dobToAge(e.dob) + "yrs, "}
                                </small>
                              )}
                              {e.gender && (
                                <small className="text-secondary">
                                  {e?.gender &&
                                    e?.gender?.split("")[0]?.toUpperCase()}
                                </small>
                              )}
                              {e.dob || e.gender ? ")" : ""}
                            </Option>
                          );
                        })}
                      <Option key="new" value="new">
                        Add New Contact
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          {addNewContactVar && (
            <>
              <Row>
                <Col>
                  <h4 className="text-dark">Add Contact Details</h4>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"first_name"}
                    label="First Name"
                    rules={[
                      { required: true, message: "Please enter first name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"last_name"}
                    label="Last Name"
                    rules={[
                      { required: true, message: "Please enter last name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"gender_id"}
                    label="Gender"
                    rules={[
                      { required: true, message: "Please select Gender" },
                    ]}
                  >
                    <Select>
                      {gender.map((e: any) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {formatDropDownValue(e.name)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"email_id"}
                    label="Email ID"
                  // rules={[
                  //   { required: true, message: "Please enter Email Id" },
                  // ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"dob"}
                    label="D.O.B."
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select date of birth",
                  //   },
                  // ]}
                  >
                    <DatePicker
                      disabledDate={(d: any) => {
                        return d > moment(moment().format("YYYY-MM-DD"));
                      }}
                      format="DD MMM'YY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"nationality_id"}
                    label={t("CRM.Common.Fields.Nationality")}
                    rules={[
                      { required: true, message: "Please select nationality" },
                    ]}
                  >
                    <Select>
                      {nationality.map((e: any) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {formatDropDownValue(e.name)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row className="button-row">
            <Col span={24} style={{ paddingTop: "24px" }}>
              <Form.Item name={"submit"}>
                <Button
                  loading={formLoading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {modalVisible && (
        <ResultModalLayout
          type="success"
          modalVisible={modalVisible}
          message=""
          setModalVisible={onCancelModal}
          title={"Contact linking successful"}
          subtitle=""
        />
      )}
    </>
  );
}
export default LinkLeadContactForm;
