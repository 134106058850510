import { Col, Radio, Row, Tag } from "antd";
import moment from "moment";
import { contactNameFormat } from "../Utils/utilities";

type Props = {
  setSelectedService: (value: any) => void;
  selected: any;
  data?: any;
};

function LinkDelink({ selected, setSelectedService, data }: Props) {
  return (
    <Radio.Group
      className={"linking-radio"}
      value={selected}
      onChange={(evt: any) => setSelectedService(JSON.parse(evt.target.value))}
    >
      {data.map((item: any, index: any) => {
        return (
          <Radio.Button
            className={"mt10 full-width  ticket-appointment-radios"}
            value={JSON.stringify(item)}
          >
            <Row style={{ fontWeight: "normal" }}>
              <Col {...{ xs: 16, sm: 16, md: 8, lg: 8, xl: 8 }}>
                <p
                  className="capitalize"
                  style={{ lineHeight: "18px", marginBottom: "7px" }}
                >
                  <span className="bold-600">
                    {index + 1}. {item?.doctor?.name}
                  </span>{" "}
                  ({item?.branch?.name}) <br />{" "}
                  <span className={"sub-title"}>
                    Appointment |{" "}
                    {moment(item.appointment_date).format("Do, MMMM YYYY")}
                  </span>
                </p>
              </Col>
              <Col
                {...{ xs: 8, sm: 8, md: 4, lg: 4, xl: 4 }}
                className={"mt10"}
              >
                <Tag color="blue">{item?.latest_status?.toUpperCase()}</Tag>
              </Col>
              <Col {...{ xs: 16, sm: 16, md: 8, lg: 8, xl: 8 }}>
                <p style={{ lineHeight: "18px", marginBottom: "0px" }}>
                  <span className={"sub-title"}>Patient </span>
                  <br />{" "}
                  <span className="bold-600">
                    {contactNameFormat(
                      item?.patient?.pii?.first_name,
                      item?.patient?.pii?.last_name,
                      item?.patient?.genderMaster?.name,
                      item?.patient?.dob
                    )}
                  </span>
                </p>
              </Col>
              <Col
                {...{ xs: 8, sm: 8, md: 4, lg: 4, xl: 4 }}
                className={"mt10 text-right"}
              >
                {selected?.id === item.id ? (
                  <span className="ant-btn ant-btn-dangerous border-radius5">
                    De-Link
                  </span>
                ) : (
                  <span className="ant-btn border-radius5 doctor-border-button">
                    Link
                  </span>
                )}
              </Col>
            </Row>
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
}

export default LinkDelink;
