import { DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Tag
} from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as SearchOutlineIcon } from "../../../../src/assets/icons/searchOutlineIcon.svg";
import { fetchCalllogsList, setFilters } from "../../../redux/reducers/calllogs.slice";
import { downloadCallLogData } from "../../../redux/reducers/leads.slice";
import { TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION } from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import TablePhoneNumberCopier from "../../../shared/layouts/table-phone-number-copier.layout";
import TableLayout from "../../../shared/layouts/table.layout";
import TabsLayout from "../../../shared/layouts/tabs.layout";
import {
  ROUTE_CRM_CALL_LOG_DETAILS,
  ROUTE_CRM_LEAD_DETAILS,
  ROUTE_CRM_TICKET_DETAILS
} from "../../../shared/routes/crm.routes.constants";
import { formatDate, getFullName } from "../../../shared/Utils/utilities";
import HeaderButtons from "../components/headerbuttons";
import CreateLeadForm from "../Leads/components/createleadform";
import AddTicket from "../Tickets/components/add-ticket";
const { RangePicker } = DatePicker;

function CRMLCallLogsList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, calllogs, pagination, filters } = useSelector(
    (state: RootState) => state.calllogs
  );
  const { connection } = useSelector((state: RootState) => state.chat);
  const [isRefreshed, setisRefreshed] = useState(true);
  let callStatus = ["open", "closed"];
  const [currentCallStatus, setCurrentCallStatus] = useState(callStatus[0]);
  const [downloadModal, setdownloadModal] = useState(false);
  // const [ pageNumber, setPageNumber ] = useState(0);
  // const [filters, setFilters] = useState(null as any);
  const { profile } = useSelector((state: RootState) => state.profile);
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  // const onChange = (e:any) => {
  //     setPageNumber(e-1)
  // }

  const [downloadRangePickerValidation, setDownloadRangePickerValidation] =
    useState({ type: "", message: "" } as any);

  const downloadDataOnValuesChange = (_: any, v: any) => {

    if (_.date_range?.length === 2) {
      const start = _.date_range[0];
      const end = _.date_range[1];
      if (end.diff(start, "days") > 30) {
        setDownloadRangePickerValidation({
          type: "error",
          message: "Date range can be maximum 30 days",
        });
      } else {
        setDownloadRangePickerValidation({ type: "", message: "" });
      }
    }
  };

  let pageNumber =
    pagination?.offset && pagination?.limit
      ? pagination.offset / pagination.limit
      : 0;
  // console.log("222222",pageNumber,pagination)
  const onPageChange = (e: any, pageSize: any) => {
    pageNumber = e - 1;
    let filter = filters
      ? Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != null)
      )
      : {};
    // dispatch(fetchLeadsList({ offset: pageNumber * 10, limit: pagination.limit, status_id: selectedStatusId, ...filter }))
    dispatch(
      fetchCalllogsList({
        status: currentCallStatus,
        offset: pageNumber * pageSize,
        limit: pageSize ? pageSize : pagination.limit,
        ...filters,
      })
    );
  };

  const onTabChange = (key: any) => {
    setCurrentCallStatus(callStatus[key - 1]);
  };
  useEffect(() => {
    dispatch(
      fetchCalllogsList({
        status: currentCallStatus,
        offset: pageNumber * 10,
        limit: pagination.limit,
        ...filters,
      })
    );
  }, [currentCallStatus, pageNumber, filters]);
  let columns: any = [
    {
      title: "Call ID",
      render: (a: any) => {
        return (
          <a onClick={() => history.push(ROUTE_CRM_CALL_LOG_DETAILS(a.id))}>
            {a.call_id}
          </a>
        );
      },
    },
    {
      title: "Host ID",
      render: (a: any) => {
        return a.host_id ? a.host_id : "-";
      },
    },
    {
      title: "Call Type",
      render: (a: any) => {
        return a.call_type;
      },
    },
    {
      title: `${t("CRM.Common.Fields.Branch")}`,
      render: (a: any) => {
        return a.branch;
      },
    },
    {
      title: "Contact Name",
      render: (a: any) => {
        return getFullName(a.contact, "-");
      },
    },
    {
      title: "Mobile Number",
      render: (a: any) => {
        return (
          <TablePhoneNumberCopier
            phone={"+" + a.country_code + a.phone}
          ></TablePhoneNumberCopier>
        );
      },
    },
    {
      title: "Call Date & Time",
      render: (a: any) => {
        return formatDate(a.call_start_time);
      },
    },
    {
      title: "Call Duration",
      render: (a: any) => {
        return a.call_duration;
      },
    },
    {
      title: "Assign To",
      className: "capitalize",
      render: (a: any) => {
        return `${a.assigned_user?.first_name ? a.assigned_user?.first_name : "-"
          } ${a.assigned_user?.last_name ? a.assigned_user?.last_name : ""}`;
      },
    },
  ];
  useEffect(() => {
    const wsCurrent = connection;
    if (!wsCurrent) return;
    wsCurrent.onmessage = (e: any) => {
      const _message = JSON.parse(e.data);
      // console.log("_message", _message)
      if (
        (_message.type === "call_created" ||
          _message.type === "call_updated") &&
        isRefreshed
      ) {
        updateRefresh();
        setisRefreshed(false);
        return;
      }
    };
  });

  const updateRefresh = () => {
    setTimeout(() => {
      dispatch(
        fetchCalllogsList({
          status: currentCallStatus,
          offset: pageNumber * 10,
          limit: pagination.limit,
          ...filters,
        })
      );

      setisRefreshed(true);
    }, 5000);
  };

  if (currentCallStatus == "closed") {
    columns.push({
      title: "Linked to",
      render: (a: any) => {
        if (a.links) {
          let len = a.links?.length - 1;
          let type = "";
          type =
            a.links[0]?.type == "lead"
              ? "Lead"
              : (type = a.links[0]?.type == "ticket" ? "Ticket" : ""); // #' + a.links[0].type : '';
          return (
            <>
              <a
                className="bold-600"
                onClick={() =>
                  history.push(
                    a.links[0]?.type == "lead"
                      ? ROUTE_CRM_LEAD_DETAILS(a.links[0].lead_id)
                      : ROUTE_CRM_TICKET_DETAILS(a.links[0].ticket_id)
                  )
                }
              >
                {type}: #{a.links[0][a.links[0]?.type].display_id}
              </a>{" "}
              {len > 0 ? <Tag>+{len} More</Tag> : ""}
            </>
          );
        } else {
          return (
            <Tag className="solid-full" color="red">
              RNR
            </Tag>
          );
        }
      },
    });
  }
  const [leadFormVisible, setLeadFormVisible] = useState(false);
  const [ticketFormVisible, setTicketFormVisible] = useState(false);
  // function filterFormSubmit(changed: any, all: any) {
  //   let filtersObj = {} as any;
  //   for (let key of Object.keys(all)) {
  //     if (all[key] == "" || all[key] == null || all[key] == undefined) {
  //     } else {
  //       if (key == "range") {
  //         filtersObj.from_date = all.range[0].format();
  //         filtersObj.to_date = all.range[1].format();
  //       } else {
  //         filtersObj[key] = all[key];
  //       }
  //     }
  //   }
  //   setFilters(filtersObj);
  // }

  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] == "" || all[key] == null || all[key] == undefined) {
      } else {
        if (key == "range") {
          filtersObj.from_date = all.range
            ? all.range[0].format("YYYY-MM-DD")
            : null;
          filtersObj.to_date = all.range
            ? all.range[1].format("YYYY-MM-DD")
            : null;
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    dispatch(setFilters(filtersObj));
  }



  function sendMailList(data: any) {
    if (downloadRangePickerValidation?.type === "error" && myPermissions?.indexOf("task_module") === -1 && myPermissions?.indexOf(TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION) === -1) {
      return;
    }
    // let filter = filters
    //   ? Object.fromEntries(
    //       Object.entries(filters).filter(([_, v]) => v != null)
    //     )
    //   : {};
    let params: any = {};
    const start = data.date_range[0];
    const end = data.date_range[1];
    params.from_date = start.format("YYYY-MM-DD");
    params.to_date = end.format("YYYY-MM-DD");
    // params.from_date = filter?.from_date;
    // params.to_date = filter?.to_date;
    params.email = data?.email;
    let param = params
      ? Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null))
      : {};
    dispatch(downloadCallLogData(param)).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        message.success("Report sent to your email.");
        setdownloadModal(false);
      }
    });
  }


  const onClickDownload = () => {
    setdownloadModal(true);
  };

  useEffect(() => {
    if (
      !filters?.from_date &&
      !filters?.to_date &&
      filters?.from_date !== null &&
      filters?.to_date !== null
    ) {
      dispatch(
        setFilters({
          ...filters,
          from_date: moment().subtract(4, "days").format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
        })
      );
    }
  }, []);

  const formElements = {
    onFinish: () => { },
    onFinishFailed: () => { },
    onValuesChange: filterFormSubmit,
    initialValues: {},
    setValues: {
      ...(filters
        ? Object.fromEntries(
          Object.entries(filters).filter(([_, v]) => _ != "null")
        )
        : {}),
      range:
        filters?.from_date && filters?.to_date
          ? [moment(filters?.from_date), moment(filters?.to_date)]
          : null,
    },
    formElements: [
      {
        label: "",
        type: "Select",
        name: "call_type",
        colSpan: 5,
        allowClear: true,
        placeholder: "Call Type",
        options: [
          { name: "INCOMING", value: "incoming" },
          { name: "OUTGOING", value: "outgoing" },
          { name: "MISSED", value: "missed" },
        ],
      },
      {
        label: "",
        type: "RangePicker",
        name: "range",
        colSpan: 7,
      },
      {
        label: "",
        type: "Input",
        name: "phone",
        colSpan: 6,
        placeholder: "Search by Phone Number",
        prefix: <SearchOutlineIcon width={"13"} height={"13"} />,
      },
      {
        label: "",
        type: "Button",
        name: (
          <>
            <DownloadOutlined />
          </>
        ),
        colSpan: 4,
        flex: "none",
      },
    ],
  };

  var allTabs = [
    {
      title: "OPEN",
      component: (
        <Row className="padding10">
          <Col span={24}>
            <TableLayout
              pageType="withTab"
              defaultCurrentPage={pageNumber + 1}
              loading={loading}
              dataSource={calllogs}
              columns={columns}
              onChange={(e, ee) => onPageChange(e, ee)}
              type="genericfilter"
              total={pagination.total}
              showTotal={true}
              pagination={{
                position: ["none", "none"],
                pageSize: pagination?.limit,
              }}
              buttonEvt={() => onClickDownload()}
              formData={formElements}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: "CLOSED",
      component: (
        <Row className="padding10">
          <Col span={24}>
            <TableLayout
              pageType="withTab"
              defaultCurrentPage={pageNumber + 1}
              loading={loading}
              dataSource={calllogs}
              columns={columns}
              onChange={(e, ee) => onPageChange(e, ee)}
              type="genericfilter"
              total={pagination.total}
              showTotal={true}
              pagination={{
                position: ["none", "none"],
                pageSize: pagination?.limit,
              }}
              buttonEvt={() => onClickDownload()}
              formData={formElements}
            />
          </Col>
        </Row>
      ),
    },
  ];
  // =======

  // Top Bar

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title="Call Logs"
        extra={
          <HeaderButtons
            setLeadFormVisible={setLeadFormVisible}
            setTicketFormVisible={setTicketFormVisible}
          />
        }
      ></PageHeader>
      <Row>
        <Col span={24} className="crm-tabs">
          <TabsLayout
            AllTabs={allTabs}
            onChange={(key) => onTabChange(key)}
          ></TabsLayout>
        </Col>
      </Row>
      {leadFormVisible && (
        <CreateLeadForm
          saveLeadSuccess={() => setLeadFormVisible(false)}
          setLeadFormVisible={(val: any) => setLeadFormVisible(val)}
          leadFormVisible={leadFormVisible}
        ></CreateLeadForm>
      )}
      {ticketFormVisible && (
        <AddTicket
          onTicketSave={() => setTicketFormVisible(false)}
          setTicketFormVisible={(val: any) => setTicketFormVisible(val)}
          ticketFormVisible={ticketFormVisible}
        ></AddTicket>
      )}
      {downloadModal && (
        <Modal
          centered
          visible={downloadModal}
          onCancel={() => setdownloadModal(false)}
          title="Send Call Log List in Email"
          footer={null}
        >
          <Form
            onFinish={sendMailList}
            onValuesChange={downloadDataOnValuesChange}
            className="crm-form"
            initialValues={{ email: profile?.email }}

          >
            {
              (myPermissions?.indexOf("task_module") === -1 && myPermissions?.indexOf(TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION) === -1) ? (
                <Form.Item
                  name="date_range"
                  label="Date Range"
                  {...(downloadRangePickerValidation?.type === "error"
                    ? {
                      validateStatus: "error",
                      help: downloadRangePickerValidation?.message,
                    }
                    : {}
                  )}
                  rules={[
                    {
                      required: true,
                      message: "Please select a valid date range.",
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={(d: any) => {
                      return d > moment();
                    }}
                    style={{ width: "100%" }}
                    format="DD MMM'YY"
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="date_range"
                  label="Date Range"
                  rules={[
                    {
                      required: true,
                      //  message: "Please select a valid date range.",
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={(d: any) => {
                      return d > moment();
                    }}
                    style={{ width: "100%" }}
                    format="DD MMM'YY"
                  />
                </Form.Item>
              )
            }
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { type: "email", message: "Please enter a valid email Id." },
                { required: true, message: "Please enter an email Id." },
              ]}
            >
              <Input placeholder="Enter email Id" />
            </Form.Item>


            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Fragment>
  );
}

export default CRMLCallLogsList;
