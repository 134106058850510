import { Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD, TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA } from "../../../../shared/components/permission.constants";
import TabsLayout from "../../../../shared/layouts/tabs.layout";
import ActivityHistoryTab from "../../components/activityhistorytab";
import AssociatedLeads from "../../components/associatedleadstab";
import TabTitle from "../../components/tabtitle";
import TicketsTab from "../../components/ticketstab";
import TransactionsTab from "../../components/transactionstab";
import TicketDetailsPharmacyQCTab from "./ticketdetailstab-pharmacyqc";
import TicketDetailsPrescription from "./ticketdetailstab-prescription";
import TicketDetailsRxTab from "./ticketdetailstab-rxgeneration";

type Props = {
  ticket: any;
  actionmapData?: any;
  loading?: any;
};

function PharmacyTickets({
  ticket,
  actionmapData = {},
  loading = false,
}: Props) {
  let phone = "";
  if (ticket?.contact) {
    phone = ticket?.contact.phone;
  }
  let comp = "" as any;
  switch (actionmapData?.action_name) {
    case "document_qc":
      comp = (
        <TicketDetailsPrescription
          ticket={ticket}
          loading={loading}
          actionmapData={actionmapData}
        />
      );
      break;
    case "document_qc_instant_vc":
      comp = (
        <TicketDetailsPrescription
          ticket={ticket}
          loading={loading}
          actionmapData={actionmapData}
        />
      );
      break;
    case "case_summary":
      comp = (
        <TicketDetailsPrescription
          ticket={ticket}
          loading={loading}
          actionmapData={actionmapData}
        />
      );
      break;
    case "medicine_order_qc":
      comp = <TicketDetailsPharmacyQCTab ticket={ticket} loading={loading} />;
      break;
    case "rx_generation":
      comp = (
        <TicketDetailsRxTab ticket={ticket} actionmapData={actionmapData} />
      );
      break;
  }
  var allTabs = [];
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const { t } = useTranslation()
  allTabs = [
    { title: <TabTitle title={`${t("CRM.Tickets.Fields.Ticket")} Details`} />, component: comp },

    ...((myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD?.split(".")[0]) !== -1)
      ? [{
        title: <TabTitle title="Associated Leads" />,
        component: <AssociatedLeads phone={phone} />,
      }] : []
    ),
    {
      title: <TabTitle title={`Associated ${t("CRM.Tickets.Fields.Tickets")} `} />,
      component: <TicketsTab excludeTicketId={ticket.id} phone={phone} />,
    },
    ...((myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA?.split(".")[0]) !== -1)
      ? [{
        title: <TabTitle title="Transactions" />,
        component: <TransactionsTab phone={phone} />,
      }] : []
    ),
    // { title: <TabTitle title="Promotional Communication" />, component: <PromotionalCommunicationTab /> },
    {
      title: <TabTitle title="Activity History" />,
      component: <ActivityHistoryTab phone={phone} />,
    },
  ];

  return (
    <div className="pd20">
      <Row gutter={16}>
        <Col className="gutter-row" span={24}>
          <Card className="nopaddingcard crm-tabs">
            <TabsLayout AllTabs={allTabs}></TabsLayout>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PharmacyTickets;
