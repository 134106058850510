import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../shared/Utils/api-client";
import {
  ASSIGNMENT,
  ASSIGNMENTPATCH,
  TEAMIDS,
  TEAMTYPES,
} from "../../shared/routes/crm.routes.constants";
const config = {
  name: "assignment",
};
export const fetchAssignmentList: any = createAsyncThunk(
  `${config.name}/fetchAssignmentList`,
  async (params: any) => {
    return await Client.get(ASSIGNMENT, { params });
  }
);

// --------------

export const fetchTeamTypes: any = createAsyncThunk(
  `${config.name}/fetchTeamTypes`,
  async (params: any) => {
    return await Client.get(TEAMTYPES, { params });
  }
);

export const fetchTeamIds: any = createAsyncThunk(
  `${config.name}/fetchTeamIds`,
  async (params: any) => {
    return await Client.get(TEAMIDS, { params });
  }
);

// -------------

export const addNewAssignment: any = createAsyncThunk(
  `${config.name}/addNewAssignment`,
  async (params: any) => {
    return await Client.post(ASSIGNMENT, { ...params });
  }
);

export const editAssignment: any = createAsyncThunk(
  `${config.name}/editAssignment`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.post(ASSIGNMENTPATCH(id), data);
  }
);

export const assignementSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    assignments: [] as any,
    teamTypes: [] as any,
    teamIds: [] as any,
    pagination: { offset: 0, limit: 10, total: 0 },
    paginationAssignment: { offset: 0, limit: 10, total: 0 },
    error: "" as string,
  },
  reducers: {
    reset: (state) => {
      state.assignments = [];
      state.loading = false;
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      //  Fetch contacts
      .addCase(fetchAssignmentList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAssignmentList.fulfilled, (state, action) => {
        state.loading = false;
        state.assignments = action?.payload?.data?.data?.items;
        state.paginationAssignment = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchAssignmentList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchTeamTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.teamTypes = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })

      .addCase(fetchTeamIds.fulfilled, (state, action) => {
        state.loading = false;
        state.teamIds = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      });
  },
});

//export const {} = assignementSlice.actions;

export default assignementSlice.reducer;
