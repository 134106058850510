
import { Tabs } from "antd";
const { TabPane } = Tabs

type Props = {
    AllTabs: Array<any>,
    onChange?: (values: string) => void,
    activeTab?: any
}

function TabsLayout({ AllTabs, onChange, activeTab }: Props) {
    return (
        <div>
            <Tabs activeKey={activeTab?.toString()} onChange={onChange}>
                {
                    AllTabs?.map((tab: any, index: number) => {
                        return <TabPane className={"nopadding-tab"} tab={tab.title} key={index + 1}>
                            {tab.component}
                        </TabPane>
                    })
                }
            </Tabs>
        </div>
    );
}

export default TabsLayout;
