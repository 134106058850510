import { DeleteTwoTone, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HeaderButtons from "../../pages/crm/components/headerbuttons";
import { fetchBranches } from "../../redux/reducers/branch.slice";
import {
  enableDisableSLA,
  fetchCustomRule,
  fetchCustomRuleById,
  fetchMasterCategories,
  fetchMasterTaskDropDown,
  fetchMasterTaskSubTypes,
  fetchMasterTaskTypes,
  updateCustomRule,
} from "../../redux/reducers/master.slice";
import { fetchAgents } from "../../redux/reducers/user.slice";
import {
  formatDropDownValue,
  operationalHoursOptions,
  timeIntervals,
} from "../Utils/utilities";
import {
  TASK_MODULE_ACTIONS_READ_LEAD,
  TASK_MODULE_ACTIONS_READ_TICKET,
} from "../components/permission.constants";
import { RootState } from "../constants";
const { TextArea } = Input;
const { Option } = Select;

type Props = {};
const SLARules: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, setmodalVisible] = useState(false);
  const { agents } = useSelector((state: RootState) => state.user);
  const [form] = useForm();
  const [opportunitiesList, setOpportunitiesList] = useState([] as any);
  const [slaDataView, setslaDataView] = useState(null as any);
  const [ShowBigCard, setShowBigCard] = useState(true);
  const [showEdit, setshowEdit] = useState(false);
  const [editableId, seteditableId] = useState(null as any);
  const [slaData, setslaData] = useState(null as any);
  const [type_id, settype_id] = useState(null as any);
  const [subType_id, setsubType_id] = useState(null as any);
  const { taskTypes, taskSubTypes, taskCategories, taskDropDown } = useSelector(
    (state: RootState) => state.master
  );
  const [mode, setmode] = useState("ticket");
  const { t } = useTranslation();
  useEffect(() => {
    if (taskDropDown.length > 0) {
      let temp;

      temp = taskDropDown?.filter((val: any) => {
        return val.name == "opportunity" && val.mode == "lead";
      });
      setOpportunitiesList(temp[0]?.values);
    }
  }, [taskDropDown]);

  useEffect(() => {
    dispatch(fetchMasterTaskDropDown());
  }, [mode]);

  const [initials, setinitials] = useState({
    rule_name: null,
    description: null,
    type_id: null,
    sub_type_id: null,
    sla_default_target: {
      first_response_time: {
        day: 0,
        hours: 0,
        minutes: 0,
      },
      resolution_time: {
        day: 0,
        hours: 0,
        minutes: 0,
      },
      is_escalation: false,
    },
    category_id: null,
    mode: "ticket",
    escalation_rule: {
      escalation_level: null,
      is_first_response: true,
      tat_time: null,
      esclation_to_ids: [null],
    },
  });

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const { branches } = useSelector((state: RootState) => state.branch);
  useEffect(() => {
    (async () => {
      await dispatch(fetchBranches());
    })();
  }, []);

  const getList = async () => {
    const res = await dispatch(fetchCustomRule());
    setslaData(res?.payload?.data?.data?.items);
  };

  useEffect(() => {
    getList();
    dispatch(fetchAgents({ limit: 15000 }));

    dispatch(fetchMasterTaskTypes({ mode: "ticket" }));
  }, []);

  const [levelZeroRule, setlevelZeroRule] = useState([
    {
      escalation_level: "0",
      is_first_response: false,
      tat_time: null,
      escalation_to_ids: [],
    },
  ] as any);

  let [escalationRules, setEscalationRules] = useState([
    {
      id: new Date().getTime(),
      escalation_level: 1,
      is_first_response: false,
      tat_time: null,
      escalation_to_ids: [],
    },
  ] as any);

  const firstResponseTarget = [
    {
      key: "First Response Target",
      value: "First Response Target",
      label: "First Response Target",
    },
  ];

  const ResolutionTarget = [
    {
      key: "Resolution Target",
      value: "Resolution Target",
      label: "Resolution Target",
    },
  ];

  const getSubtype = (type: any) => {
    form.setFieldsValue({
      sub_type_id: null,
      category_id: null,
    });
    settype_id(type);
    type && dispatch(fetchMasterTaskSubTypes({ task_type_id: type }));
  };

  const getCategory = (type: any) => {
    form.setFieldsValue({
      category_id: null,
    });
    setsubType_id(type);
    type && dispatch(fetchMasterCategories({ task_sub_type_id: type }));
  };

  const addLevel = () => {
    setEscalationRules([
      ...escalationRules,
      {
        id: new Date().getTime(),
        escalation_level: escalationRules?.length + 1,
        is_first_response: false,
        tat_time: null,
        escalation_to_ids: [],
      },
    ]);
  };

  const deleteLevel = (level: any) => {
    const filteredValues = escalationRules?.filter((val: any) => {
      return val.id !== level;
    });

    const changedIds = filteredValues?.map((vals: any, index: any) => {
      return { ...vals, escalation_level: index + 1, tat_time: vals.tat_time };
    });

    //
    setEscalationRules(changedIds);
  };

  const deactivateRule = async (e: any, val: any) => {
    const res = await dispatch(
      enableDisableSLA({ id: val, data: { status: e } })
    );
    if (res?.payload?.data?.statusCode) {
      message.success({
        content: "Status updated Successfully",
      });
    } else {
      message.error({
        content: "Something went wrong.",
      });
    }

    getList();
  };
  const oncancelModal = () => {
    setmodalVisible(false);
    setshowEdit(false);
  };

  const onclickViewRule = async (id: any) => {
    setmodalVisible(true);
    const response = await dispatch(fetchCustomRuleById(id));
    setslaDataView(response?.payload?.data?.data);
    //
  };

  const onclickEditRule = async (id: any) => {
    setmodalVisible(true);
    setshowEdit(true);
    seteditableId(id);
    const response = await dispatch(fetchCustomRuleById(id));
    setslaDataView(response?.payload?.data?.data);
  };

  const onFinish = async (values: any) => {
    //
    escalationRules = [
      ...escalationRules,
      levelZeroRule?.length > 0 && levelZeroRule[0],
    ];

    const filteredRules = escalationRules?.filter(
      (rule: any) => rule?.escalation_to_ids?.length > 0 && rule?.tat_time !== null
    );

    // Create a new array of rules with the 'id' key removed
    const cleanedRules = filteredRules.map((rule: any) => {
      const { id, ...cleanedRule } = rule;
      return cleanedRule;
    });

    values.escalation_rule = cleanedRules;
    values.sla_default_target = [values.sla_default_target];
    values.mode = mode;
    console.log("values", values); // Check if the 'id' key is removed from the 'escalation_rule' array

    const response = await dispatch(
      updateCustomRule({ id: editableId, data: values })
    );

    if (response?.payload?.data?.statusCode === 200) {
      message.success({
        content: "Rule updated successfully.",
      });
      form.resetFields();
      history.goBack();
    } else {
      message.error({
        content: "Something went wrong.",
      });
    }
  };

  useEffect(() => {
    if (slaDataView) {
      // debugger
      form.setFieldsValue({
        rule_name: slaDataView?.rule_name,
        description: slaDataView?.description,
        type_id: slaDataView?.type_id,
        branch_id: slaDataView?.branch_id,
        mode: slaDataView?.mode,
        sub_type_id: slaDataView?.sub_type_id,
        opportunities: slaDataView?.opportunities,
        category_id: slaDataView?.category_id,
        sla_default_target: {
          first_response_time: {
            day: slaDataView?.sla_default_target?.[0]?.first_response_time?.day,
            hours:
              slaDataView?.sla_default_target?.[0]?.first_response_time?.hours,
            minutes:
              slaDataView?.sla_default_target?.[0]?.first_response_time
                ?.minutes,
          },
          resolution_time: {
            day: slaDataView?.sla_default_target?.[0]?.resolution_time?.day,
            hours: slaDataView?.sla_default_target?.[0]?.resolution_time?.hours,
            minutes:
              slaDataView?.sla_default_target?.[0]?.resolution_time?.minutes,
          },
          is_escalation: true,
        },
      });

      setmode(slaDataView?.mode);

      const levelzerodata = slaDataView?.escalation_settings?.filter(
        (val: any) => {
          return val.escalation_level === 0;
        }
      )[0];

      const levelZeroUpdatedData = [
        {
          escalation_level: "0",
          is_first_response: true, // Update a field, for example
          tat_time: levelzerodata?.tat_time, // Update another field, for example
          escalation_to_ids: levelzerodata?.escalation_to_ids, // Update another field, for example
          sla_rule_id: levelzerodata?.sla_rule_id,
          id: levelzerodata?.id,
        },
      ];

      setlevelZeroRule(levelZeroUpdatedData);

      slaDataView?.sub_type_id &&
        dispatch(
          fetchMasterCategories({ task_sub_type_id: slaDataView?.sub_type_id })
        );
      slaDataView?.type_id &&
        dispatch(
          fetchMasterTaskSubTypes({ task_type_id: slaDataView?.type_id })
        );
      setEscalationRules(
        slaDataView?.escalation_settings?.filter((val: any) => {
          return val.escalation_level !== 0;
        })
      );
    }
  }, [slaDataView]);

  const handleChangeMode = (value: any) => {
    setmode(value);
    form.setFieldsValue({ type_id: null });
    form.setFieldsValue({ source_id: null });
    form.setFieldsValue({ sub_type_id: null });
    form.setFieldsValue({ category_id: null });
  };

  useEffect(() => {
    dispatch(fetchMasterTaskTypes({ mode: mode }));
  }, [mode]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title={
          <Row>
            <Col flex="none">SLA Rules</Col>
          </Row>
        }
        extra={<HeaderButtons createRule={true} />}
      ></PageHeader>

      <div className="pd20  details-page-area">
        <Row gutter={20}>
          <Col span={24}>
            {slaData?.length > 0 ? (
              <p>
                <b> {slaData?.length} Rules</b>
              </p>
            ) : (
              "No Rules created."
            )}

            {slaData?.map((val: any, index: any) => {
              // if (val?.active) {
              return (
                <>
                  <Card style={{ marginBottom: "20px" }} key={val.id}>
                    <Row>
                      <Col style={{ width: "100%" }} span={22}>
                        <b>
                          {index + 1}. {val?.rule_name}
                        </b>
                        <br />
                        <small>{val?.description}</small>
                      </Col>
                      <Col span={2}>
                        <Space>
                          <Switch
                            checked={val.active === true ? true : false}
                            onChange={(e) => {
                              deactivateRule(e, val.id);
                            }}
                            checkedChildren="on"
                            unCheckedChildren="off"
                            defaultChecked={val?.active}
                          />

                          <Dropdown
                            overlay={
                              <>
                                <Menu>
                                  <Menu.Item
                                    key="0"
                                    onClick={() => onclickViewRule(val?.id)}
                                  >
                                    View Rule
                                  </Menu.Item>
                                  <Menu.Item
                                    key="1"
                                    onClick={() => onclickEditRule(val?.id)}
                                  >
                                    Edit Rule
                                  </Menu.Item>
                                </Menu>
                              </>
                            }
                            trigger={["click"]}
                          >
                            <Button
                              shape="circle"
                              type="primary"
                              size={"middle"}
                              icon={<MoreOutlined />}
                            />
                          </Dropdown>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                </>
              );
              // }
              // return null;
            })}
          </Col>
        </Row>
      </div>

      <Modal
        title={
          <>
            <Row>
              <Col span={4}> {showEdit ? "Edit Rule" : "View Rule"}</Col>
              {showEdit && (
                <Col span={20} className="text-right">
                  <Button
                    style={{ marginRight: "20px" }}
                    onClick={() => form.submit()}
                    type="primary"
                  >
                    <span style={{ color: "white" }}>Save</span>
                  </Button>
                </Col>
              )}
            </Row>
          </>
        }
        centered
        visible={modalVisible}
        onCancel={() => oncancelModal()}
        width={1200}
        footer={false}
        className="modalFormButton form-modal"
        style={{ paddingBottom: "0px" }}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form
              form={form}
              className="paddingx20 paddingtop20 crm-form full-width"
              onFinish={onFinish}
            // initialValues={initials}
            >
              {/* name and description */}
              <Card>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name={"rule_name"}
                      rules={[{ required: true, message: "Please enter Name" }]}
                    >
                      <Input disabled={!showEdit} type={"text"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="description"
                      label="Description"
                    // rules={[
                    //   { required: true, message: "Please enter description" },
                    // ]}
                    >
                      <TextArea
                        showCount
                        disabled={!showEdit}
                        maxLength={100}
                        style={{ height: 120, resize: "none" }}
                        onChange={() => { }}
                        placeholder="Description"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <br />
              {/* type subtype and module */}
              <Card title={"When a ticket satisfies these conditions:"}>
                <Row gutter={15}>
                  <Col span={8}>
                    <Form.Item name="mode" label="Mode">
                      <Select
                        showSearch={true}
                        disabled={!showEdit}
                        optionFilterProp="children"
                        onChange={handleChangeMode}
                      >
                        {myPermissions?.indexOf(
                          TASK_MODULE_ACTIONS_READ_TICKET
                        ) !== -1 ||
                          myPermissions?.indexOf(
                            TASK_MODULE_ACTIONS_READ_TICKET?.split(".")[0]
                          ) !== -1 ? (
                          <>
                            <Option value="ticket">Ticket</Option>
                          </>
                        ) : (
                          ""
                        )}

                        {myPermissions?.indexOf(
                          TASK_MODULE_ACTIONS_READ_LEAD
                        ) !== -1 ||
                          myPermissions?.indexOf(
                            TASK_MODULE_ACTIONS_READ_LEAD?.split(".")[0]
                          ) !== -1 ? (
                          <>
                            <Option value="lead">Lead</Option>
                          </>
                        ) : (
                          ""
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={"branch_id"}
                      label={t("CRM.Common.Fields.Hospital")}
                    >
                      <Select
                        allowClear={true}
                        showSearch
                        optionFilterProp="children"
                        className="capitalize"
                      >
                        {branches?.map((e: any) => {
                          return (
                            <Option key={e.id} value={e.id}>
                              {formatDropDownValue(e.name)}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={15}>
                  <Col span={8}>
                    <Form.Item label="Select Module" name={"type_id"}>
                      <Select
                        allowClear={true}
                        disabled={!showEdit}
                        showSearch
                        optionFilterProp="children"
                        onChange={(evt) => getSubtype(evt)}
                      >
                        {taskTypes?.map((e: any) => {
                          return (
                            <Option key={e.id} value={e.id}>
                              {formatDropDownValue(e.name)}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Select Type" name={"sub_type_id"}>
                      <Select
                        allowClear={true}
                        showSearch
                        optionFilterProp="children"
                        disabled={!showEdit && (type_id ? false : true)}
                        onChange={(evt) => getCategory(evt)}
                      >
                        {taskSubTypes.map((e: any) => {
                          return (
                            <Option key={e.id} value={e.id}>
                              {formatDropDownValue(e.name)}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {mode === "ticket" ? (
                    <Col span={8}>
                      <Form.Item name={"category_id"} label="Select Sub-type">
                        <Select
                          allowClear={true}
                          showSearch
                          optionFilterProp="children"
                          disabled={subType_id ? false : true}
                        >
                          {taskCategories.map((e: any) => {
                            return (
                              <Option key={e.id} value={e.id}>
                                {formatDropDownValue(e.name)}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={8}>
                      <Form.Item name={"opportunities"} label="Opportunities">
                        <Select
                          allowClear={true}
                          disabled={!showEdit}
                          showSearch
                          mode="multiple"
                          optionFilterProp="children"
                        // disabled={subType_id ? false : true}
                        >
                          {opportunitiesList?.map((e: any) => {
                            return (
                              <Option key={e.id} value={e.id}>
                                {formatDropDownValue(e.name)}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Card>

              <br />
              <Card title={"Set SLA target as:"}>
                <Card
                  title={
                    <>
                      <Row>
                        <Col span={9}>First Response Time</Col>
                        <Col span={9}>Resolution Time</Col>
                        <Col span={4}>Operational Hours</Col>
                        <Col span={2}>Escalation</Col>
                      </Row>
                    </>
                  }
                >
                  <Row>
                    <Col span={9}>
                      <Row gutter={8}>
                        <Col span={7}>
                          <Form.Item
                            label=""
                            name={
                              [
                                "sla_default_target",
                                "first_response_time",
                                "day",
                              ] as any
                            }
                          >
                            <InputNumber
                              disabled={!showEdit}
                              min={0}
                              max={31}
                              style={{ width: "100%" }}
                              addonAfter="day(s)"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            label=""
                            name={
                              [
                                "sla_default_target",
                                "first_response_time",
                                "hours",
                              ] as any
                            }
                          >
                            <InputNumber
                              disabled={!showEdit}
                              min={0}
                              max={24}
                              style={{ width: "100%" }}
                              addonAfter="hour(s)"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label=""
                            name={
                              [
                                "sla_default_target",
                                "first_response_time",
                                "minutes",
                              ] as any
                            }
                          >
                            <InputNumber
                              min={0}
                              max={60}
                              style={{ width: "100%" }}
                              addonAfter="Minute(s)"
                              disabled={!showEdit}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={9}>
                      <Row gutter={8}>
                        <Col span={7}>
                          <Form.Item
                            label=""
                            name={
                              [
                                "sla_default_target",
                                "resolution_time",
                                "day",
                              ] as any
                            }
                          >
                            <InputNumber
                              min={0}
                              max={31}
                              style={{ width: "100%" }}
                              addonAfter="day(s)"
                              disabled={!showEdit}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            label=""
                            name={
                              [
                                "sla_default_target",
                                "resolution_time",
                                "hours",
                              ] as any
                            }
                          >
                            <InputNumber
                              min={0}
                              max={24}
                              style={{ width: "100%" }}
                              addonAfter="hour(s)"
                              disabled={!showEdit}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label=""
                            name={
                              [
                                "sla_default_target",
                                "resolution_time",
                                "minutes",
                              ] as any
                            }
                          >
                            <InputNumber
                              min={0}
                              max={60}
                              style={{ width: "100%" }}
                              disabled={!showEdit}
                              addonAfter="Minute(s)"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={4}>
                      <Select
                        options={operationalHoursOptions}
                        style={{ width: "80%" }}
                        disabled={!showEdit}
                      />
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        label=""
                        name={["sla_default_target", "is_escalation"] as any}
                      >
                        <Switch checked={ShowBigCard === true ? true : false} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Card>
              <br />
              {ShowBigCard && (
                <Card title={"Send escalation when the SLA is violated."}>
                  <Card>
                    <div
                      className="ant-card-body"
                      style={{ backgroundColor: "#f7f8fb" }}
                    >
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item label="When">
                            <Select
                              defaultValue={"First Response Target"}
                              allowClear
                              onChange={(values: any) => {
                                const updatedRules = [...levelZeroRule];
                                updatedRules[0].is_first_response = values
                                  ? true
                                  : false;
                                setlevelZeroRule(updatedRules);
                              }}
                              options={firstResponseTarget}
                              style={{ width: "100%" }}
                              disabled={!showEdit}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item label=".">
                            <p>is not met, then</p>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Escalate">
                            <Select
                              disabled={!showEdit}
                              value={levelZeroRule[0]?.tat_time}
                              onChange={(values: any) => {
                                const updatedRules = [...levelZeroRule];
                                updatedRules[0].tat_time = values.toString();
                                setlevelZeroRule(updatedRules);
                              }}
                              style={{ width: "100%" }}
                            >
                              {timeIntervals?.map((val: any) => {
                                return (
                                  <Option key={val.value} value={val.value}>
                                    {formatDropDownValue(val.key)}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item label="Escalate to">
                            <Select
                              disabled={!showEdit}
                              value={levelZeroRule[0]?.escalation_to_ids}
                              showSearch
                              placeholder={"Type to search user"}
                              optionFilterProp="children"
                              mode="multiple"
                              allowClear
                              onChange={(values: any) => {
                                const updatedRules = [...levelZeroRule];
                                updatedRules[0].escalation_to_ids = values;
                                setlevelZeroRule(updatedRules);
                              }}
                            >
                              {agents?.map((val: any) => {
                                return (
                                  <Option value={val.user_id}>
                                    {val?.user?.first_name}{" "}
                                    {val?.user?.last_name !== "null" &&
                                      val?.user?.last_name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                  <br />
                  <Card>
                    <div
                      className="ant-card-body"
                      style={{ backgroundColor: "#f7f8fb" }}
                    >
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item label="When">
                            <Select
                              defaultValue={"Resolution Target"}
                              options={ResolutionTarget}
                              style={{ width: "100%" }}
                              disabled={!showEdit}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label=".">
                            <p>is not met, Escalate</p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Card>
                        {escalationRules?.map((rule: any, index: any) => {
                          return (
                            <>
                              <Row gutter={16}>
                                <Col span={8}>
                                  <Form.Item
                                    label={`Send Level ${rule.escalation_level} Escalation`}
                                  >
                                    <Select
                                      disabled={!showEdit}
                                      onChange={(values: any) => {
                                        const updatedRules = [
                                          ...escalationRules,
                                        ];
                                        updatedRules[index].tat_time = values;
                                        setEscalationRules(updatedRules);
                                      }}
                                      value={rule.tat_time}
                                      style={{ width: "100%" }}
                                    >
                                      {timeIntervals?.map((val: any) => {
                                        return (
                                          <Option
                                            key={val.value}
                                            value={val.value}
                                          >
                                            {formatDropDownValue(val.key)}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={14}>
                                  <Form.Item label="Escalate To">
                                    <Select
                                      disabled={!showEdit}
                                      onChange={(values: any) => {
                                        const updatedRules = [
                                          ...escalationRules,
                                        ];
                                        updatedRules[index].escalation_to_ids =
                                          values;
                                        setEscalationRules(updatedRules);
                                      }}
                                      value={rule.escalation_to_ids}
                                      placeholder={"Type to search user"}
                                      showSearch
                                      optionFilterProp="children"
                                      mode="multiple"
                                      allowClear
                                    >
                                      {agents?.map((val: any) => {
                                        return (
                                          <Option value={val.user_id}>
                                            {val?.user?.first_name}{" "}
                                            {val?.user?.last_name !== "null" &&
                                              val?.user?.last_name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <Form.Item label={"."}>
                                    {index !== 0 && !showEdit && (
                                      <Button
                                        onClick={() => {
                                          deleteLevel(rule.id);
                                        }}
                                      >
                                        <DeleteTwoTone />
                                      </Button>
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Divider />
                            </>
                          );
                        })}

                        {escalationRules?.length < 4 && showEdit && (
                          <Button onClick={addLevel} type={"text"}>
                            Add Escalation Level
                          </Button>
                        )}
                      </Card>
                    </div>
                  </Card>
                </Card>
              )}
            </Form>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default SLARules;
