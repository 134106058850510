import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, PageHeader, Row, Space, Switch, message } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addAgent, editAgent, fetchAgents, toggelPauseAgent } from '../../../redux/reducers/user.slice';
import { RootState } from '../../../shared/constants';
import TableLayout from '../../../shared/layouts/table.layout';
// import CreateAgent from './components/CreateAgent';
import { useTranslation } from 'react-i18next';
import CreateAgent from './components/createagent';

function Agents() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [actionType, setactionType] = useState('add')
    const [editData, seteditData] = useState(null as any)
    const [editId, seteditId] = useState('')
    const [pauseId, setpauseId] = useState('')
    const [currentStatus, setcurrentStatus] = useState()
    const [pauseData, setpauseData] = useState({})
    const { loading, agents, offset, total } = useSelector((state: RootState) => state.user);
    const [pageNumber, setPageNumber] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalVisible, setmodalVisible] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [filterVal, setfilterVal] = useState(null as any)

    const handleOk = async () => {
        setIsModalVisible(false);

        await dispatch(toggelPauseAgent({ id: pauseId, data: pauseData })).then(async (val: any) => {
            setFormLoading(false);
            if (val?.payload?.data?.statusCode === 200) {
                setmodalVisible(false);
                message.success({
                    content: 'Agent status updated successfully',
                    style: {
                        marginBottom: '10vh',
                    },
                });
                await dispatch(fetchAgents())
            }
        })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onChange = (e: any) => {
        setPageNumber(e - 1)
    }


    const handleChangePause = (value: any, records: any) => {
        setcurrentStatus(value)
        setIsModalVisible(true);
        setpauseId(records?.id)
        setpauseData({
            ...pauseData, "agent_id": records?.agent_id,
            "role": records?.role,
            "priline_number": records?.priline_number,
            "active": !value
        })
    }

    const columns = [
        {
            title: 'First Name',
            dataIndex: ['user', 'first_name'],
            className: 'capitalize',
            render: (a: any) => { return a ? a : a }
        },
        {
            title: 'Last Name',
            dataIndex: ['user', 'last_name'],
            className: 'capitalize',
            render: (a: any) => { return a ? a : a }

        },
        {
            title: 'Email',
            dataIndex: ['user', 'email'],

        },
        {
            title: `${t("CRM.Common.Fields.Branch")}`,
            dataIndex: 'branch',
            render: (a: any) => { return a?.name }

        },
        {
            title: 'Phone',
            dataIndex: ['user', 'phone'],

        },
        {
            title: 'Agent id',
            dataIndex: ['agent_id'],

        },
        {
            title: 'Status',
            dataIndex: 'active',
            render: (a: any, record: any) => {
                return (<>
                    <Space>
                        <Switch
                            checked={a === true ? true : false}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked
                            onChange={() => { handleChangePause(a, record) }}
                        />

                        <Button onClick={() => { handleEdit(record) }}  >Edit</Button>
                    </Space>
                </>)
            }

        },
    ]

    const handleEdit = (value: any) => {
        setactionType('edit')
        setmodalVisible(true)
        seteditData(value)
        seteditId(value.id)
    }





    const handleBtnEvent = () => {
        seteditData(null)
        setmodalVisible(true)
        setactionType('add')
    }

    const onSaveContact = async (values: any) => {
        values = { ...values }
        // console.log("dddddddddddddddddddddd", values)

        setFormLoading(true);
        await dispatch(addAgent(values)).then(async (val: any) => {
            setFormLoading(false);
            if (val?.payload?.data?.statusCode === 200) {
                setmodalVisible(false);
                message.success({
                    content: 'Agent created successfully',
                    style: {
                        marginBottom: '10vh',
                    },
                });
                await dispatch(fetchAgents({ offset: pageNumber * 10, limit: 10 }))
            }
        })
    };

    const onEditContact = async (values: any) => {

        let response = await dispatch(editAgent({ id: editId, data: values }));

        if (response?.payload?.data?.statusCode === 200) {
            setFormLoading(false);
            setmodalVisible(false);
            message.success({
                content: 'Agent updated successfully',
                style: {
                    marginBottom: '10vh',
                },
            });
            await dispatch(fetchAgents())
        }


    };

    const onSaveContactFailed = (errorInfo: any) => {
        //console.log('Failed:', errorInfo);
    };


    useEffect(() => {
        (async () => {
            let params = { offset: pageNumber * 10, limit: 10 } as any
            if (filterVal) {
                params.search_val = filterVal
            }
            await dispatch(fetchAgents(params))
        })()
    }, [pageNumber])

    const filterFormSubmit = async (e: any) => {
        let filterValue = e;
        setfilterVal(e)

        let params = { offset: pageNumber * 10, limit: 10 } as any
        if (filterValue) {
            params.search_val = filterValue
        }

        await dispatch(fetchAgents(params))
    }

    return (

        <Fragment>
            <PageHeader ghost={false}
                title='Agents'
            >
            </PageHeader>
            <Modal
                visible={isModalVisible}
                width={'340px'}
                centered
                footer={null}
                closable={false}>
                <p>Are you sure you want to {currentStatus !== false ? `pause` : `unpause`}  this shift ?</p>
                <Button onClick={handleCancel} style={{ width: '48%' }} >No</Button>
                <Button onClick={handleOk} style={{ width: '48%' }} type="primary">Yes</Button>


            </Modal>
            <Row className='padding10'>
                <Col span={24}>
                    <TableLayout defaultCurrentPage={pageNumber + 1} loading={loading} dataSource={agents} columns={columns} total={total} onChange={(e) => onChange(e)} buttonEvt={() => handleBtnEvent()} type='agentsfilter' filterFormSubmit={filterFormSubmit} pagination={{ position: ['none', 'none'] }} />
                </Col>
            </Row>
            {
                modalVisible && <CreateAgent editData={editData} typess={actionType} onEditContact={(e: any) => onEditContact(e)} onSaveContact={(e: any) => onSaveContact(e)} onSaveContactFailed={(e: any) => onSaveContactFailed(e)} modalVisible={modalVisible} setmodalVisible={(e: any) => setmodalVisible(e)} formLoading={formLoading} ></CreateAgent>

            }
        </Fragment>
    );
}

export default Agents;
