import { PhoneFilled } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getNpsByMobile } from "../../redux/reducers/contacts.slice";
import { dobToAge, formatDate, formatDropDownValue, getAge } from "../Utils/utilities";
import { initiateCallFn } from "../common/initCall";
import { TASK_MODULE_ACTIONS_HIDE_AGE, TASK_MODULE_ACTIONS_HIDE_GENDER, TASK_MODULE_ACTIONS_HIDE_UHID, TASK_MODULE_ACTIONS_MAKE_A_CALL, TASK_MODULE_ACTIONS_SHOW_BHQ, TASK_MODULE_ACTIONS_SHOW_CITY, TASK_MODULE_ACTIONS_SHOW_COUNTRY, TASK_MODULE_ACTIONS_SHOW_REVISED_UNIQUE_ROLE, TASK_MODULE_ACTIONS_SHOW_SPECIALIZATION, TASK_MODULE_ACTIONS_SHOW_STATE, TASK_MODULE_ACTIONS_SHOW_THERAPY_FIELD } from "../components/permission.constants";
import TablePhoneNumberCopier from "./table-phone-number-copier.layout";

type Props = {
  details?: any;
  type?: string;
  callData?: any;
  isJarvis?: any;
};

function AccentCard({ details, type = "contact", callData = {}, isJarvis }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [npsData, setNpsData] = useState(null as any);

  const initCall = async (phone: any) => {
    // dispatch(initiateCall({ cli: phone, ...callData })).then((val: any) => {
    //   message.success("Call initiated.");
    // });
    await initiateCallFn(phone, callData);
  };
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  useEffect(() => {
    let phone = "";
    if (type === "contact" && details.contact?.phone) {
      phone = details.contact.phone;
    }
    if (type === "contacts" && details?.phone) {
      phone = details.phone;
    }
    if (phone) {
      // getNps(phone);
    }
  }, []);

  console.log("type11111111111", type, details)


  const getNps = async (phone: string) => {
    dispatch(getNpsByMobile(phone)).then((val: any) => {
      if (val?.payload?.data?.data) {
        const data = val.payload.data.data;
        if (data?.rating && data?.date) {
          setNpsData({
            rating: data.rating,
            date: formatDate(data.date, "DD MMM'YY"),
          });
        }
      }
    });
  };

  return (
    <>
      <Card className="accentCard mb-15">
        {type === "number" && (
          <Row align="middle">
            <Col flex="auto">
              <p className="title mb-0">
                <span>{details.phone}</span>
              </p>
              {/* <p className="title mb-0"><span>Vinayak Gargoti</span> (24yrs, M)</p> */}
            </Col>
            <Col>

              <Space size={15}>
                <Button
                  className="flipIcon"
                  style={{ border: "none" }}
                  shape="circle"
                  onClick={() => initCall(details?.phone)}
                  icon={<PhoneFilled className="text-primary" />}
                />
                {/* <a href={`tel:${details.phone}`}>
                  <span className="whiteRoundButton">
                    <PhoneFilled
                      style={{ fontSize: "16px" }}
                      className="text-primary flipIcon"
                    />
                  </span>
                </a> */}
              </Space>
            </Col>
          </Row>
        )}
        {(type === "contact" && !isJarvis) && (
          <>
            <Row align="middle">
              <Col flex="auto">
                <p className="title mb-0">
                  <span className="capitalize">
                    {details?.contact?.first_name} {details?.contact?.last_name}
                  </span>{" "}

                  {
                    (
                      (myPermissions?.indexOf("task_module") !== -1 ||
                        myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_AGE) !== -1 ||
                        myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_GENDER) !== -1)) ? ""
                      : (details?.contact?.dob || details?.contact?.birth_year) &&
                      (`(${details?.contact?.birth_year ? getAge(details?.contact?.birth_year) : dobToAge(details?.contact?.dob)}yrs, ${details?.contact?.gender?.name?.split("")[0].toUpperCase()})`)

                  }

                </p>

              </Col>
              <Col>
                <Space size={15}>
                  {
                    (myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_MAKE_A_CALL) !== -1) && <Button
                      className="flipIcon"
                      style={{ border: "none" }}
                      shape="circle"
                      onClick={() => initCall(details.contact?.phone)}
                      icon={<PhoneFilled className="text-primary" />}
                    />
                  }

                </Space>
              </Col>
            </Row>
            {
              (myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_SPECIALIZATION) !== -1) &&
              <Row>
                <Col>
                  <p className=" mb-0">
                    <span>Specialization: {details?.contact?.specialization ? details?.contact?.specialization : "--"}  </span>

                  </p>
                </Col>
              </Row>
            }
            <Row align="middle">
              <p>
                WhatsApp Number:
                {
                  details.contact?.phone ? <TablePhoneNumberCopier
                    phone={
                      " +" + details.contact?.country_code + " " + details.contact?.phone
                    }
                  ></TablePhoneNumberCopier> : "--"
                }

                {details?.contact?.whatsapp_phone && <>| <span> WhatsApp - {details?.contact?.whatsapp_phone} </span></>}
              </p>
              {/* {details.contact?.uhid && (
                <>
                  <Divider type="vertical"></Divider>UHID:{" "}
                  {details.contact?.uhid}
                </>
              )} */}
            </Row>

            {
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_THERAPY_FIELD) !== -1 || myPermissions?.indexOf("task_module") !== -1 ? <> <Row>
                <Col>
                  <p className=" mb-0">
                    <span>Therapy Field:  {myPermissions?.indexOf("task_module") !== -1 ||
                      myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_THERAPY_FIELD) !== -1 ? (details?.contact?.therapy_field ? details?.contact?.therapy_field : "--") : " --"} </span>
                  </p>
                </Col>
              </Row></> : ""
            }


            {
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_REVISED_UNIQUE_ROLE) !== -1 || myPermissions?.indexOf("task_module") !== -1 ?
                <Row>
                  <Col>
                    <p className=" mb-0">
                      <span>Revised Unique Role: {
                        myPermissions?.indexOf("task_module") !== -1 ||
                          myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_REVISED_UNIQUE_ROLE) !== -1 ?
                          details?.contact?.revised_unique_role ? formatDropDownValue(details?.contact?.revised_unique_role) : "--" : "--"
                      } </span>
                    </p>
                  </Col>
                </Row> : ""
            }





            <Row>
              <Col>
                <p className=" mb-0">
                  <span>Email: {details?.contact?.email ? details?.contact?.email : "--"} </span>
                </p>
              </Col>
            </Row>
            {
              details?.contact?.thb_id && <Row>
                <Col>
                  <p className=" mb-0">
                    <span>THB Id: {details?.contact?.thb_id ? details?.contact?.thb_id : "--"} </span>
                  </p>
                </Col>
              </Row>
            }



            {(myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_BHQ) !== -1) &&
              <Row>
                <Col>
                  <p className=" mb-0">

                    <span>
                      BHQ: {
                        details?.contact?.city ? details?.contact?.city : "--"
                      }
                    </span>
                  </p>
                </Col>
              </Row>
            }
            {(myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_CITY) !== -1) &&
              <Row>
                <Col>
                  <p className=" mb-0">

                    <span>
                      City: {
                        details?.contact?.city ? details?.contact?.city : "--"
                      }
                    </span>
                  </p>
                </Col>
              </Row>
            }
            {(myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_STATE) !== -1) &&
              <Row>
                <Col>
                  <p className=" mb-0">

                    <span>
                      State: {
                        details?.contact?.state ? details?.contact?.state : "--"
                      }
                    </span>
                  </p>
                </Col>
              </Row>
            }
            {(myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_COUNTRY) !== -1) &&
              <Row>
                <Col>
                  <p className=" mb-0">

                    <span>
                      Country: {
                        details?.contact?.country ? details?.contact?.country : "--"
                      }
                    </span>
                  </p>
                </Col>
              </Row>
            }
            {
              (myPermissions?.indexOf("task_module") !== -1 ||
                myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_UHID) !== -1) ? "" :
                <Row>
                  <Col>
                    <p className=" mb-0">
                      {
                        (myPermissions?.indexOf("task_module") !== -1 ||
                          myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_UHID) !== -1) ? "--" : <>
                          <span>{t("CRM.Common.Fields.UHID")}: {details?.contact?.uhid ? details?.contact?.uhid : "--"}  </span>
                        </>
                      }



                    </p>
                  </Col>
                </Row>
            }


            {details?.contact?.alternate_phone && (
              <p>
                Calling No:{" "}
                <a
                  href="#alternatenumber"
                  onClick={() => initCall(details?.contact?.alternate_phone)}
                >
                  {details?.contact?.alternate_phone}
                </a>
              </p>
            )}
            {npsData !== null && (
              <div
                className="npscard"
                style={{
                  backgroundColor:
                    npsData.rating >= 9
                      ? "#37CB95"
                      : npsData.rating >= 7 && npsData.rating <= 8
                        ? "#EB872A"
                        : "#E1454E",
                }}
              >
                <p>
                  NPS: {npsData.rating} (on {npsData.date})
                </p>
              </div>
            )}
          </>
        )}
        {(type === "contact" && isJarvis) && (
          <>
            <Row align="middle">
              <Col flex="auto">
                <p className="title mb-0">
                  <span className="capitalize">
                    Project:
                  </span>{" "}
                  <span className="capitalize">
                    {details?.contact?.first_name} {details?.contact?.last_name}
                  </span>{" "}

                </p>
              </Col>

            </Row>
            <Row>
              <Col>
                <p className=" mb-0">
                  <span>{t("CRM.Common.Fields.Phone No.")}: {details?.contact?.phone ? details?.contact?.phone : "--"} </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className=" mb-0">
                  <span>Project Spoc Email: {details?.contact?.email ? details?.contact?.email : "--"} </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className=" mb-0">
                  <span>Client: {details?.branch?.name ? details?.branch?.name : "--"} </span>
                </p>
              </Col>
            </Row>
          </>
        )}
        {type === "contacts" && (
          <>
            <Row align="middle">
              <Col flex="auto">
                <p className="title mb-0">
                  <span className="capitalize">
                    {details?.first_name} {details?.last_name}
                  </span>{" "}
                  {
                    (
                      (myPermissions?.indexOf("task_module") !== -1 ||
                        myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_AGE) !== -1 ||
                        myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_GENDER) !== -1)) ? "" :
                      (details?.dob || details?.birth_year) && `(${details?.birth_year ? getAge(details?.birth_year) : dobToAge(details?.dob)}yrs,  ${details?.gender?.name ? details?.gender?.name?.split("")[0].toUpperCase() : ""})`

                  }

                </p>
              </Col>
              <Col>
                <Space>
                  {/* <Button type="link" shape="circle" icon={<EditFilled />} /> */}
                  <Button
                    className="flipIcon"
                    style={{ border: "none" }}
                    shape="circle"
                    onClick={() => initCall(details?.phone)}
                    icon={<PhoneFilled className="text-primary" />}
                  />
                  {/* <a
                    href={`tel:${"+" + details?.country_code + details?.phone}`}
                  >
                    <span className="whiteRoundButton">
                      <PhoneFilled
                        style={{ fontSize: "16px" }}
                        className="text-primary flipIcon"
                      />
                    </span>
                  </a> */}
                  {/* <Button shape="circle" icon={<MessageFilled className='text-primary' />} /> */}
                </Space>
              </Col>
            </Row>
            <Row align="middle">
              <p>
                <TablePhoneNumberCopier
                  phone={"+" + details?.country_code + details?.phone}
                ></TablePhoneNumberCopier>
              </p>
              {details?.uhid && (
                <>
                  <Divider type="vertical"></Divider>{t("CRM.Common.Fields.UHID")}: {details?.uhid}
                </>
              )}
            </Row>
            {/* | UHID: 123456789456 | */}
            {details?.alternate_phone && (
              <p>
                Alternate No:{" "}
                <a
                  href="#alternateNumber"
                  onClick={() => initCall(details?.alternate_phone)}
                >
                  {details?.alternate_phone}
                </a>
              </p>
            )}
            {npsData !== null && (
              <div
                className="npscard"
                style={{
                  backgroundColor:
                    npsData.rating >= 9
                      ? "#37CB95"
                      : npsData.rating >= 7 && npsData.rating <= 8
                        ? "#EB872A"
                        : "#E1454E",
                }}
              >
                <p>
                  NPS: {npsData.rating} (on {npsData.date})
                </p>
              </div>
            )}
            {(myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_COUNTRY) !== -1) &&
              <Row>
                <Col>
                  <p className=" mb-0">

                    <span>
                      Country: {
                        details?.country ? details?.country : "--"
                      }
                    </span>
                  </p>
                </Col>
              </Row>
            }
            {(myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_STATE) !== -1) &&
              <Row>
                <Col>
                  <p className=" mb-0">

                    <span>
                      State: {
                        details?.state ? details?.state : "--"
                      }
                    </span>
                  </p>
                </Col>
              </Row>
            }
            {(myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_CITY) !== -1) &&
              <Row>
                <Col>
                  <p className=" mb-0">

                    <span>
                      City: {
                        details?.city ? details?.city : "--"
                      }
                    </span>
                  </p>
                </Col>
              </Row>
            }

            {/* <p>4 More Profiles  <Dropdown overlay={details} placement="bottomRight"><a className="drop-down-text"> <EyeFilled className="paddingl10" /></a></Dropdown> <Divider type='vertical'></Divider> <a className="accent">View More Details</a></p>
                    <p>App Activity: Not Installed</p>
                    <p>&nbsp;</p>
                    <Button ghost>Conversion Probability: 20%</Button> <Button ghost>NPS: 8</Button> */}
          </>
        )}
        {type == "doctor" && (
          <>
            <Row align="middle">
              <Col flex="auto">
                <p className="title mb-0">
                  <span className="capitalize">{details?.name}</span>{" "}
                  {details?.doctorid && <>(ID: {details?.doctorid})</>}
                </p>
              </Col>
              <Col>
                <Space>
                  <Button
                    shape="circle"
                    onClick={() => initCall(details?.phone)}
                    icon={<PhoneFilled className="text-primary" />}
                  />
                </Space>
              </Col>
            </Row>
            <Row align="middle">{details?.speciality}</Row>
            <Row align="middle">
              <p>
                <TablePhoneNumberCopier
                  phone={details?.phone}
                ></TablePhoneNumberCopier>
              </p>
              {details?.email && (
                <>
                  <Divider type="vertical"></Divider>
                  {details?.email}
                </>
              )}
            </Row>
            {details?.location && (
              <Row align="middle">
                Location: {formatDropDownValue(details?.location)}
              </Row>
            )}
          </>
        )}
      </Card>
    </>
  );
}
export default AccentCard;
