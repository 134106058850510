import { Button, Col, Input, Row, message } from "antd";
import Form from "antd/es/form";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchAccountByDomain,
  fetchApplications,
} from "../redux/reducers/auth.slice";
import { _BASE_URL } from "../shared/Utils/api-client";
import { LOGO_URL, RootState } from "../shared/constants";
import { ROUTE_LOGIN } from "../shared/routes/route.constants";

function WebformLeadsPage() {
  const history = useHistory();
  const [form] = useForm();
  const searchParams: any = new URLSearchParams(window.location.search);
  const RouteParams: any = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const [baseUrl, setBaseUrl] = useState(_BASE_URL);
  useEffect(() => {
    localStorage.clear();
    !applications && dispatch(fetchApplications());
    // !account &&
    dispatch(fetchAccountByDomain());
  }, []);
  useEffect(() => {
    account?.id && localStorage.setItem("account_id", account?.id);
    // account?.slug && history.push(ROUTE_CRM_LOGIN(account?.slug));
  }, [applications, account]);

  return (
    // <AccountRouteFetcher>
    //   <div>
    //     {t("genDict.landing")}
    //     <Divider />
    //     {applications.length > 0 &&
    //       applications.map((application: any) => {
    //         return (
    //           <Button
    //             onClick={async () => {
    //               await dispatch(
    //                 switchApplication({
    //                   slug: application.slug,
    //                   application_id: application.id,
    //                 })
    //               );
    //               history.push(ROUTE_LOGIN(account.slug, application.slug));
    //             }}
    //           >
    //             Go {application.name} Login
    //           </Button>
    //         );
    //       })}
    //   </div>

    //   <Divider />
    //   <Card>
    //     <Row>
    //       <Col flex={"auto"}>
    //         <Input
    //           value={baseUrl}
    //           onChange={(e) => setBaseUrl(e.target.value)}
    //         />
    //         <div>
    //           Sample = <small>https://medi.hlthclub.in/vault/v1/</small>
    //         </div>
    //       </Col>
    //       <Col>
    //         <Button
    //           onClick={() => {
    //             localStorage.setItem("baseUrl", baseUrl);
    //           }}
    //         >
    //           Update Base Url
    //         </Button>
    //       </Col>
    //     </Row>
    //   </Card>
    // </AccountRouteFetcher>
    <>
      <img
        src="https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ"
        style={{
          position: "absolute",
          top: "70px",
          left: "55vw",
          maxWidth: "50vw",
          maxHeight: "70vh",
        }}
      ></img>
      <Row gutter={32} align="middle" style={{ padding: "5px 20px" }}>
        <Col flex={"auto"}>
          <img
            src={LOGO_URL || "https://thb.co.in/images/logo.svg"}
            style={{ height: "100px" }}
          ></img>
        </Col>
        <Col flex={"none"}>
          <Button
            onClick={() =>
              history.push(ROUTE_LOGIN(RouteParams["account"], "crm"))
            }
            style={{ borderRadius: "10px" }}
            type="primary"
            size="large"
          >
            Launch Demo
          </Button>
        </Col>
      </Row>
      <Row className="crm-form" style={{ padding: "5px 30px" }}>
        <Col xs={24} sm={24} md={12} lg={{ span: 8, offset: 3 }}>
          <h1>Request for Demo</h1>
          <Form
            form={form}
            onFinish={async (values: any) => {
              let meta = { ...values } as any;
              for (const key of searchParams.keys()) {
                // console.log(key);
                // console.log(searchParams.get(key));
                meta[key] = searchParams.get(key);
              }
              const payload = {
                event_name: "patient_referal_lead",
                ...values,
                meta,
              };
              fetch(
                "https://zpooeraky4.execute-api.ap-south-1.amazonaws.com/dev/" +
                  account.slug +
                  "/add-lead-webhook",
                {
                  method: "POST",
                  body: JSON.stringify(payload),
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                }
              )
                .then(function (response) {
                  return response.json();
                })
                .then(function (allData) {
                  console.log(allData);
                  if (allData?.statusCode == 200) {
                    message.success(
                      "Your message is sent. We'll get back to you."
                    );
                    form.resetFields();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            <Form.Item
              label="Name"
              name="first_name"
              rules={[{ required: true }]}
            >
              <Input size="large" style={{ borderRadius: "10px" }}></Input>
            </Form.Item>
            <Form.Item
              label="Email ID"
              name="email"
              rules={[{ required: true }]}
            >
              <Input size="large" style={{ borderRadius: "10px" }}></Input>
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[{ required: true }]}
            >
              <Input size="large" style={{ borderRadius: "10px" }}></Input>
            </Form.Item>
            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true }]}
            >
              <Input size="large" style={{ borderRadius: "10px" }}></Input>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                size="large"
                style={{ borderRadius: "10px" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row
        style={{ background: "#173444", padding: "40px 20px" }}
        justify="center"
      >
        <Col style={{ color: "#FFFFFF" }}>
          Copyright © 2023. THB. All Rights Reserved.
        </Col>
      </Row>
    </>
  );
}

export default WebformLeadsPage;
