import { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker, withGoogleMap, withScriptjs
} from "react-google-maps";
import { useDispatch } from "react-redux";
import { GOOGLE_MAP_API } from "../constants";

type Props = {
  selectedAddr?: any;
}

function MapComponent(props:Props) {
    const dispatch = useDispatch();
    const [ lat,  setLat] = useState(28.507686 as any);
    const [ long,  setLong] = useState(77.206072 as any);
    const [ zoomLevel,  setZoomLevel] = useState(8);

    const MapWithAMarker =  withScriptjs(withGoogleMap( (props: any) => {
      return (
        <GoogleMap zoom={zoomLevel} center={{ lat: lat, lng: long }}>
              <Marker
                position={{ lat: lat, lng: long }}
                //  draggable={true}
              >
              </Marker>
        </GoogleMap>
      )
    }))

    useEffect( () => {
      // console.log('eeeee wwwwww', props?.selectedAddr)
      if(props?.selectedAddr){
        setLat(props?.selectedAddr?.lat);
        setLong(props?.selectedAddr?.lng);
        setZoomLevel(14);
      }
     
    },[props?.selectedAddr])
  
    return (
        <div className="row">
            <div className="col-12 col-lg-6 col-xl">
              <div className="card mt10">
                <div className="card-body">
                  <MapWithAMarker
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API}&&v=3.exp&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `300px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    draggable={true}
                  />
                </div>
              </div>
            </div>
        </div>
    )
}
export default MapComponent;
