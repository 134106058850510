import { Button, Col, message, PageHeader, Row, Space } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAssignment,
  editAssignment,
  fetchAssignmentList,
  fetchTeamIds,
  fetchTeamTypes,
} from "../../../redux/reducers/assignment.slice";
import { RootState } from "../../../shared/constants";
import TableLayout from "../../../shared/layouts/table.layout";
import CreateAssignmentForm from "./components/createAssignmentForm";

function AssisnmentPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [actionType, setactionType] = useState("add");
  const [editData, seteditData] = useState(null as any);
  const [editId, seteditId] = useState("");
  const { loading, assignments, paginationAssignment } = useSelector(
    (state: RootState) => state.assignment
  );

  const { gender, nationality } = useSelector(
    (state: RootState) => state.master
  );
  let pageNumber =
    paginationAssignment?.offset && paginationAssignment?.limit
      ? paginationAssignment.offset / paginationAssignment.limit
      : 0;
  const onChange = (e: any) => {
    pageNumber = e - 1;
    dispatch(
      fetchAssignmentList({
        offset: pageNumber * 10,
        limit: paginationAssignment.limit,
      })
    );
  };

  const handleEdit = (value: any) => {
    setactionType("edit");
    setmodalVisible(true);
    seteditData(value);
    seteditId(value.id);
  };

  console.log("paginationAssignment", paginationAssignment, pageNumber)

  const columns = [
    {
      title: "Mode",
      dataIndex: "mode",
      className: "capitalize",
    },
    {
      title: "Source",
      dataIndex: "source",
      render: (a: any) => {
        return <>{a?.name}</>;
      },
    },
    {
      title: `${t("CRM.Tickets.Fields.Ticket Type")}/Service Type`,
      dataIndex: "type",
      render: (a: any) => {
        return <>{a?.name}</>;
      },
    },
    {
      title: `${t("CRM.Tickets.Fields.Ticket Sub Type")}/Service Sub Type`,
      dataIndex: "sub_type",
      render: (a: any) => {
        return <>{a?.name}</>;
      },
    },
    {
      title: `${t("CRM.Tickets.Fields.Ticket Category")}`,
      dataIndex: "category",
      render: (a: any) => {
        return <>{a?.name}</>;
      },
    },
    {
      title: "Action",
      render: (a: any) => {
        return (
          <Space>
            <Button
              onClick={() => {
                handleEdit(a);
              }}
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
  ];
  const [modalVisible, setmodalVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const onSaveContact = (values: any) => {
    console.log("values", values);
    // console.log("values","["+values.assigned_team_types.join()+"]")
    setFormLoading(true);
    dispatch(addNewAssignment(values)).then((val: any) => {
      setFormLoading(false);
      if (val?.payload?.data?.statusCode === 200) {
        setmodalVisible(false);
        message.success({
          content: "Assignment created successfully",
          style: {
            marginBottom: "10vh",
          },
        });
        dispatch(fetchAssignmentList());
      }
    });
  };

  const onEditContact = (values: any) => {
    console.log("values", values);
    // console.log("values","["+values.assigned_team_types.join()+"]")
    setFormLoading(true);
    dispatch(editAssignment({ id: editId, data: values })).then((val: any) => {
      setFormLoading(false);
      if (val?.payload?.data?.statusCode === 200) {
        setmodalVisible(false);
        message.success({
          content: "Assignment updated successfully",
          style: {
            marginBottom: "10vh",
          },
        });
        dispatch(fetchAssignmentList());
      }
    });
  };

  const onSaveContactFailed = (errorInfo: any) => {
    //console.log('Failed:', errorInfo);
  };

  const handleBtnEvent = () => {
    seteditData(null);
    setactionType("add");
    setmodalVisible(true);
  };

  useEffect(() => {
    dispatch(
      fetchAssignmentList({
        offset: paginationAssignment.offset,
        limit: paginationAssignment.limit,
      })
    );
    dispatch(fetchTeamTypes());
    dispatch(fetchTeamIds());
  }, []);

  return (
    <Fragment>
      <PageHeader ghost={false} title="Assignment"></PageHeader>
      <Row className="padding10">
        <Col span={24}>
          <TableLayout
            loading={loading}
            dataSource={assignments}
            columns={columns}
            onChange={(e) => onChange(e)}
            buttonEvt={() => handleBtnEvent()}
            defaultCurrentPage={pageNumber + 1}
            type="assignmentfilter"
            total={paginationAssignment.total}
            pagination={{ position: ["none", "none"] }}
          />
        </Col>
      </Row>
      {modalVisible && (
        <CreateAssignmentForm
          editData={editData}
          typess={actionType}
          onEditContact={(e: any) => onEditContact(e)}
          onSaveContact={(e: any) => onSaveContact(e)}
          onSaveContactFailed={(e: any) => onSaveContactFailed(e)}
          modalVisible={modalVisible}
          setmodalVisible={(e: any) => setmodalVisible(e)}
          formLoading={formLoading}
        ></CreateAssignmentForm>
      )}
    </Fragment>
  );
}

export default AssisnmentPage;
