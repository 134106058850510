import { PageHeader } from "antd";
//import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Topbar = (props: any) => {
  const history = useHistory();
  return (
    <>
      {/* <div className="topbar"> */}
      <PageHeader
        ghost={false}
        onBack={() =>
          props.back ? history.push(props.back) : window.history.back()
        }
        title={props.title}
        extra={props.extra}
        subTitle={props?.subTitle}
      ></PageHeader>
      {/* </div> */}
    </>
  );
};

export default Topbar;
