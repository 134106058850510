import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { attemptOTPLogin } from "../../../redux/reducers/auth.slice";
import { RootState } from "../../../shared/constants";
import { LoginWithOTP } from "../../../shared/types/login.types";
import { countryCodes } from "../../../shared/Utils/dial-codes";

interface Props {
  setLoginByPin: (b: boolean) => void;
  onUsernameChange: (string: any) => void;
  username: any;
  kind: string;
  loginsEnabled: Array<string>;
}

export const UsernameForm = ({
  onUsernameChange,
  kind,
  username,
  setLoginByPin,
  loginsEnabled,
}: Props) => {
  const { account, applications, loggingIn } = useSelector(
    (state: RootState) => state.auth
  );
  const RouteParams: any = useParams();
  const [countryCode, setCountryCode] = useState("+91" as string);
  const [, setApplicationId] = useState("");
  const [btnClass, setbtnClass] = useState("faddedButton");

  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  const [form] = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedApplication = applications?.find(
      (a: any) => a.slug === RouteParams?.source
    );
    setApplicationId(selectedApplication?.id);
  }, [applications, account]);

  const onChangeMobile = (e: any) => {
    onUsernameChange(e.target.value);
    if (e.target.value.length === 10) {
      setbtnClass("doctor-secondary-button");
    } else {
      setbtnClass("faddedButton");
    }
  };

  useEffect(() => {
    window.addEventListener(
      "keydown",
      function (e) {
        if (
          ["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(
            e.code
          ) > -1
        ) {
          e.preventDefault();
        }
      },
      false
    );
  });

  const prefixSelector = (
    <Form.Item name="country_code" noStyle initialValue={countryCode}>
      <Select
        showSearch
        disabled
        style={{ width: 100 }}
        value={countryCode}
        onChange={(evt) => setCountryCode(evt)}
        className={"noborder"}
      >
        {countryCodes.map((item) => {
          return (
            <Select.Option value={item.dial_code}>
              <Space>
                <span>{item.flag}</span>
                <span>{item.dial_code}</span>
              </Space>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  return (
    <>
      <Title
        className={screenType.desktop ? "mt20" : "mt20" + "  bold"}
        level={3}
      >
        Login with your Mobile Number
      </Title>
      

      <Form
        name="number-login"
        className="login-form"
        form={form}
        initialValues={{ username }}
        onFinish={async (values: LoginWithOTP) => {
          const response = await dispatch(
            attemptOTPLogin({
              ...values,
              account_id: account.id,
              kind: kind,
            })
          );
        }}
        inputMode="numeric"
        autoComplete="off"
      >
        <Form.Item
          name="username"
          rules={[
            {
              pattern: new RegExp("^[0-9]{10}$"),
              max: 10,
              message: "Please input valid phone number!",
              required: true,
            },
          ]}
        >
          <Input
            inputMode="numeric"
            size={"large"}
            type={"number"}
            maxLength={10}
            style={{ pointerEvents: "auto" }}
            placeholder={"Enter 10 Digit mobile number"}
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            // onKeyUp={(e) => {
            //   e.currentTarget.blur();
            // }}
            onChange={(e) => onChangeMobile(e)}
            addonBefore={prefixSelector}
            className="noborderWrapper doctor-app-box-shadow height60px border-radius overflow-hidden"
          />
        </Form.Item>
        <Row gutter={32} className={(screenType.mobile ? "" : "") + " mt15"}>
          <Col span={12} className={screenType.mobile ? "pr5" : ""}>
            {loginsEnabled.indexOf("pin") !== -1 && (
              <Button
                size="large"
                className="doctor-border-button pd15 hauto btn-shadow"
                block
                onClick={async () => {
                  try {
                    await form.validateFields();
                    setLoginByPin(true);
                  } catch (e) { }
                }}
              >
                {" "}
                Login With Pin
              </Button>
            )}
          </Col>
          <Col span={12} className={screenType.mobile ? "pl5" : ""}>
            {loginsEnabled.indexOf("otp") !== -1 && (
              <Button
                size="large"
                className={` ${btnClass}  pd15 hauto btn-shadow`}
                block
                loading={loggingIn}
                htmlType={"submit"}
                style={{ float: "right" }}
              >
                {" "}
                Get OTP
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

//
