import { PhoneFilled } from "@ant-design/icons";
import { Button, Card, Col, Collapse, Divider, Row, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppointmentById,
  getCallLogs
} from "../../../../redux/reducers/appointment.slice";
import { fetchDoctorById, fetchDoctorCoordinator, resetDoctor } from "../../../../redux/reducers/doctor.slice";
import { resetPatient } from "../../../../redux/reducers/patients.slice";
import { fetchTicketsDetails } from "../../../../redux/reducers/tickets.slice";
import {
  formatDropDownValue,
  processVideoLogs
} from "../../../../shared/Utils/utilities";
import { initiateCallFn } from "../../../../shared/common/initCall";
import { RootState } from "../../../../shared/constants";
import TimelineLayout from "../../../../shared/layouts/timeline.layout";
import GeneralTicketActions from "../../components/ticket-general-drawers";
import UpdateTicketForm from "./updateticketform";
const { Panel } = Collapse;
const { Option } = Select;

type Props = {
  ticket: any;
  actionmapData?: any;
};
function TicketAppointment({ ticket, actionmapData }: Props) {
  const dispatch = useDispatch();
  const [initAction, setInitAction] = useState(false as any);
  // const { appointment } = useSelector((state: RootState) => state.appointment);
  const [appointment, setAppointment] = useState(null as any);

  const [actionmapDataS, setactionmapData] = useState(false as any);
  const [patientData, setPatientData] = useState({
    totalAttempts: 0,
    deviceType: "",
    device: "--",
    appVersion: "--",
    appUser: "--",
    lastLoggedIn: "--",
    status: "Not Joined",
  } as any);
  const [doctorData, setDoctorData] = useState({
    totalAttempts: 0,
    deviceType: "",
    device: "--",
    appVersion: "--",
    appUser: "--",
    lastLoggedIn: "--",
    status: "Not Joined",
  } as any);
  const [currentStatus, setCurrentStatus] = useState("");

  // const [patientArr, setpatientArr] = useState([] as any);
  // const [doctorArr, setdoctorArr] = useState([] as any);
  const { coordinators } = useSelector((state: RootState) => state.doctor);
  const { doctor } = useSelector((state: RootState) => state.doctor);
  const { patient } = useSelector((state: RootState) => state.patient);

  const onFinishTicket = (values: any) => {
    dispatch(fetchTicketsDetails(ticket.id));
  };
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      dispatch(resetPatient())
      dispatch(resetDoctor())
    }
  }, [])

  const RoomEventType = {
    join: "In Consultation",
    left: "Video Call Ended",
    waiting_room_join: "In Waiting Room",
    waiting_room_left: "Left Waiting Room",
  } as any;

  const timelineDetails = ticket?.history;
  useEffect(() => {
    ticket?.services[0]?.appointment_id &&
      dispatch(fetchAppointmentById(ticket?.services[0]?.appointment_id)).then(
        (val: any) => {
          if (val?.payload?.data?.data) {
            setAppointment(val?.payload?.data?.data);
          }
        }
      );
    if (actionmapData?.action_name == "missed_call_doctor") {
      dispatch(
        fetchDoctorCoordinator({ doctor_id: ticket?.services[0]?.doctor_id })
      );
    }
  }, [actionmapData]);

  useEffect(() => {
    setactionmapData(actionmapData);
  }, [coordinators]);


  console.log("patient", patient)

  useEffect(() => {
    // ticket?.contact?.patient_id && dispatch(fetchPatientsDetailsById(ticket?.contact?.patient_id))
    ticket?.services && ticket?.services[0]?.doctor_id && dispatch(fetchDoctorById(ticket?.services[0]?.doctor_id))


  }, [ticket])


  useEffect(() => {
    if (appointment?.room_id && appointment?.appointment_date) {
      dispatch(
        getCallLogs({
          roomId: appointment.room_id,
          date: moment(appointment?.appointment_date).format("YYYY/MM/DD"),
        })
      ).then((val: any) => {
        if (val?.payload?.data?.data) {
          let callEventLogs = val?.payload?.data?.data?.call_event_logs;
          let callSummary = val?.payload?.data?.data?.call_summary;
          if (callEventLogs?.length > 0 && callSummary?.length > 0) {
            let { patientData, doctorData, currentStatus } = processVideoLogs(
              callEventLogs,
              callSummary
            );
            setCurrentStatus(currentStatus);
            setPatientData(patientData);
            setDoctorData(doctorData);
          } else {
            setCurrentStatus("Both Doctor and Patient did not join");
          }
        }
      });
    }
  }, [appointment]);
  const initCall = async (phone: any) => {
    // await dispatch(
    //   initiateCall({ cli: phone, source: "ticket", ticket_id: ticket?.id })
    // );
    // message.success("Call initiated.");
    await initiateCallFn(phone, { source: "ticket", ticket_id: ticket?.id });
  };
  return (
    <>
      <Row style={{ maxHeight: "calc(100vh - 155px)", overflowY: "scroll" }}>
        <Col span={19} className="border-right">
          <div className="bg-white pd20">
            <Row>
              <Col flex="auto">
                <h3 className="bold-600 text-dark">
                  {formatDropDownValue(ticket?.category?.name)}
                </h3>
              </Col>
              <Col>
                <Button
                  type="link"
                  onClick={() => setInitAction("audit_trail")}
                  className="bold-600"
                >
                  <u>View Audit Trail</u>
                </Button>
              </Col>
            </Row>
            <p>{ticket?.description?.description}</p>
            {actionmapDataS?.action_name == "missed_call_doctor" &&
              coordinators?.length > 0 && (
                <Row>
                  <Col>
                    Coordinator Phone No :{" "}
                    <a
                      className="text-primary"
                      onClick={() => initCall(coordinators[0]?.user?.phone)}
                    >
                      <PhoneFilled className="flipIcon" />{" "}
                      {`${coordinators[0]?.user?.country_code} ${coordinators[0]?.user?.phone}`}
                    </a>
                  </Col>
                </Row>
              )}
            {actionmapDataS?.action_name == "missed_call_doctor" &&
              coordinators?.length == 0 && (
                <Row>
                  <Col>Coordinator Phone No : NA</Col>
                </Row>
              )}
            {actionmapDataS?.action_name == "missed_call_doctor" &&
              doctor?.mobile && (
                <Row>
                  <Col>
                    <span className="em">
                      Incase coordinator is not reachable, please reach out to
                      Doctor on following No.{" "}
                    </span>

                    <a
                      className="text-primary"
                      onClick={() =>
                        initCall(
                          doctor?.mobile
                        )
                      }
                    >
                      <PhoneFilled className="flipIcon" />{" "}
                      {`${doctor?.country_code} ${doctor?.mobile}`}
                    </a>
                  </Col>
                </Row>
              )}
            {actionmapDataS?.action_name == "missed_call_patient" && (
              <>
                <Row>
                  <Col>
                    Patient Name :{" "}
                    <span className="bold-600 capitalize">
                      {" "}
                      {`${patient?.first_name
                        ? patient?.first_name
                        : ticket?.contact?.first_name
                        }`}{" "}
                      {`${patient?.last_name
                        ? patient?.last_name
                        : ticket?.contact?.last_name
                        }`}{" "}
                    </span>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Phone No :{" "}
                    <a
                      className="text-primary"
                      onClick={() =>
                        initCall(
                          patient?.mobile
                            ? patient?.mobile
                            : ticket?.contact?.phone
                        )
                      }
                    >
                      <PhoneFilled className="flipIcon" />{" "}
                      {`+91 ${patient?.mobile
                        ? patient?.mobile
                        : ticket?.contact?.phone
                        }`}
                    </a>
                  </Col>
                </Row>
              </>
            )}
            {actionmapDataS?.action_name == "vc_reminder_call" && (
              <>
                <Row>
                  <Col>
                    Patient Name :{" "}
                    <span className="bold-600 capitalize">
                      {" "}
                      {`${patient?.first_name
                        ? patient?.first_name
                        : ticket?.contact?.first_name
                        }`}{" "}
                      {`${patient?.last_name
                        ? patient?.last_name
                        : ticket?.contact?.last_name
                        }`}{" "}
                    </span>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Phone No :{" "}
                    <a
                      className="text-primary"
                      onClick={() =>
                        initCall(
                          patient?.mobile
                            ? patient?.mobile
                            : ticket?.contact?.phone
                        )
                      }
                    >
                      <PhoneFilled className="flipIcon" />{" "}
                      {`+91 ${patient?.mobile
                        ? patient?.mobile
                        : ticket?.contact?.phone
                        }`}
                    </a>
                  </Col>
                </Row>
              </>
            )}
            {currentStatus !== "" && (
              <Row align="middle">
                <Col>VC Status :</Col>
                <Col>
                  <div className="vc-status">{currentStatus}</div>
                </Col>
              </Row>
            )}
            <Card className="text-secondary pd10 mt15">
              <Row className="bg-white">
                <Col span={24} className="bold-600">
                  Patient Status
                </Col>
              </Row>
              <Row className="bg-white">
                <Col span={8}>
                  <span>
                    <small>Total Attempts : {patientData.totalAttempts}</small>
                  </span>
                </Col>

                <Col span={8}>
                  <span className="capitalize">
                    <small>Device : {patientData.device}</small>
                  </span>
                </Col>
                <Col span={8}>
                  <span>
                    <small>Version : {patientData?.appVersion}</small>
                  </span>
                </Col>
              </Row>
              <Row className="bg-white">
                <Col span={8}>
                  <span>
                    <small>App User : {patientData.appUser}</small>{" "}
                  </span>
                </Col>
                <Col span={8}>
                  <span>
                    <small>Last Logged In : {patientData.lastLoggedIn}</small>
                  </span>
                </Col>
                <Col span={8}>
                  <span>
                    <small>Video Room Status : {patientData.status}</small>
                  </span>
                </Col>
              </Row>
              <Divider />
              <Row className="bg-white">
                <Col span={24} className="bold-600">
                  Doctor Status
                </Col>
              </Row>
              <Row className="bg-white">
                <Col span={8}>
                  <span>
                    <small>Total Attempts : {doctorData.totalAttempts}</small>
                  </span>
                </Col>
                <Col span={8}>
                  <span className="capitalize">
                    <small>Device : {doctorData.device}</small>
                  </span>
                </Col>
                <Col span={8}>
                  <span>
                    <small>
                      Version :{" "}
                      {doctorData?.build_version ? doctorData?.build_version : "--"}{" "}
                    </small>
                  </span>
                </Col>
              </Row>
              <Row className="bg-white">
                <Col span={8}>
                  <span>
                    <small>App User : {doctorData.appUser}</small>
                  </span>
                </Col>
                <Col span={8}>
                  <span>
                    <small> Last Logged In : {doctorData.lastLoggedIn}</small>
                  </span>
                </Col>
                <Col span={8}>
                  <span>
                    <small>Video Room Status : {doctorData.status}</small>
                  </span>
                </Col>
              </Row>
            </Card>
          </div>

          <Collapse defaultActiveKey={["1"]} className="collapse-right-arrow">
            <Panel header={`${t("CRM.Tickets.Fields.Ticket")} History`} key="1">
              <TimelineLayout
                type="Ticket"
                details={timelineDetails}
              ></TimelineLayout>
            </Panel>
          </Collapse>
        </Col>
        <Col span={5}>
          <UpdateTicketForm
            ticket={ticket}
            parentCallback={onFinishTicket}
          ></UpdateTicketForm>
        </Col>
      </Row >
      {initAction && (
        <GeneralTicketActions
          appointment={appointment}
          action={initAction}
          hideMe={() => setInitAction(false)}
        ></GeneralTicketActions>
      )
      }
    </>
  );
}

export default TicketAppointment;
