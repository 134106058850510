import { ACCOUNT_PREFIX } from "../constants";

export const FACEBOOK_CAPTURE_PROFILE =
  "facebook/" + ACCOUNT_PREFIX + "/capture-profile";
export const FACEBOOK_GET_PROFILE = "facebook/" + ACCOUNT_PREFIX + "/profile";
export const FACEBOOK_GET_PAGE_BY_PAGE_ID = (pageId: string) =>
  "facebook/" + ACCOUNT_PREFIX + "/page/" + pageId;
export const FACEBOOK_GET_PAGES_BY_PROFILE_ID = (fbId: string) =>
  "facebook/" + ACCOUNT_PREFIX + "/pages/" + fbId;
export const FACEBOOK_SUBSCRIBE_PAGE_BY_PAGE_ID = (pageId: string) =>
  "facebook/page/subscribe/" + pageId;
export const FACEBOOK_GET_PAGE_FORMS = (pageId: string) =>
  "facebook/page/forms/" + pageId;
export const SYNC_LEADS_BYFORM_AND_PAGE_ID = (pageId: string, formId: string) =>
  "facebook/leads/forms/" + pageId + "/" + formId;
export const UPDATE_FB_CLIENT_TOKEN = (fbId: string) =>
  "facebook/" + ACCOUNT_PREFIX + "/profile/" + fbId;

export const API_GOOGLE_ACCOUNT_CONNECT =
  "task/google-oauth-client/" + ACCOUNT_PREFIX;
export const API_GOOGLE_ACCOUNT_DETAILS =
  "task/" + ACCOUNT_PREFIX + "/linked-google-account";
