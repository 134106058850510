import { Button, Col, PageHeader, Row } from "antd";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../shared/constants";
import TableLayout from "../../../../shared/layouts/table.layout";
import CreateAudience from "./components/create-audience";
import ManageAudience from "./components/manage-audience";

function ManageAudiencesPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, contacts, pagination } = useSelector(
    (state: RootState) => state.contact
  );

  let pageNumber =
    pagination?.offset && pagination?.limit
      ? pagination.offset / pagination.limit
      : 0;

  const onChange = (e: any) => {
    pageNumber = e - 1;
    // dispatch(
    //   fetchContactList({ offset: pageNumber * 10, limit: pagination.limit })
    // );
  };

  function handleReset(clearFilters: any) {
    clearFilters();
  }

  const columns = [
    {
      title: "Facebook Ad Account",
      key: "name",
      width: 1,
      fixed: "left",
      render: (record: any) => {
        return (
          <>
            <strong>{record.ad_account_name}</strong>
            <br />
            {record.ad_account_number}
          </>
        );
      },
    },
    {
      title: "Audience Name",
      key: "name",
      width: 1,
      fixed: "left",
      dataIndex: "audience_name",
    },
    {
      title: "Description",
      width: 2,
      key: "desc",
      dataIndex: "audience_description",
    },
    {
      title: "Users Count",
      width: 1,
      key: "users",
      dataIndex: "user_count",
    },
    {
      title: t("CRM.Common.Fields.Action"),
      width: 1,
      fixed: "right",
      render: (a: any) => {
        return (
          <Button type="link" onClick={() => setDetailsVisible(true)}>
            Manage
          </Button>
        );
      },
    },
  ];

  const [modalVisible, setmodalVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const onCreateAudience = (values: any) => {};

  const filterFormSubmit = (e: any) => {
    let filterValue = e.target.value;
    // dispatch(
    //   fetchContactList({
    //     search_val: filterValue,
    //     offset: pageNumber * 10,
    //     limit: pagination.limit,
    //   })
    // );
  };
  let audienceList = [
    {
      ad_account_name: "Prashant",
      ad_account_number: "4789651986262",
      audience_name: "First Aud",
      audience_description:
        "Advertise your business directly to an engaged audience. Try Facebook advertising today.",
      user_count: 100,
    },
  ];
  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title={"Audience List"}
        extra={
          <Button key="1" onClick={() => setmodalVisible(true)}>
            Create Audience
          </Button>
        }
      ></PageHeader>
      <Row className="padding10">
        <Col span={24}>
          <TableLayout
            maxWidth={1200}
            defaultCurrentPage={pageNumber + 1}
            filterFormSubmit={filterFormSubmit}
            loading={loading}
            dataSource={audienceList}
            columns={columns}
            onChange={(e) => onChange(e)}
            type=""
            total={pagination.total}
            pagination={{ position: ["none", "none"] }}
            offsetHeight={180}
          />
          {/* <TableLayout pageType='withoutTab' defaultCurrentPage={pageNumber+1} filterFormSubmit={filterFormSubmit} loading={loading} dataSource={contacts} columns={columns} onChange={(e)=>onChange(e)} buttonEvt={()=>handleButtonEvt()} type='contactsfilter' total={pagination.total} pagination={{ position: ['none', 'none'] }} /> */}
        </Col>
      </Row>
      {modalVisible && (
        <CreateAudience
          actionType=""
          editData={null}
          modalVisible={modalVisible}
          setmodalVisible={setmodalVisible}
        />
      )}
      {detailsVisible && (
        <ManageAudience
          audienceInfo={audienceList[0]}
          visible={detailsVisible}
          setVisible={setDetailsVisible}
        />
      )}
    </Fragment>
  );
}

export default ManageAudiencesPage;
