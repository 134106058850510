import { useQuery } from "react-query";
import { Client } from "../../../shared/Utils/api-client";
import { BRANCHES } from "../../routes/route.constants";

export const useFetchBranches = (params: any) => {
  const { data, error, isLoading } = useQuery("fetchBranches", () =>
    Client.get(BRANCHES, { params })
  );
  let branches = [];
  if (isLoading === false) {
    if (data?.data?.data?.items?.length > 0) {
      branches = data?.data?.data?.items;
    }
  }
  return { branches, error, isLoading };
};
