import { InfoCircleFilled } from "@ant-design/icons";
import { Col, Drawer, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches } from "../../../../../redux/reducers/branch.slice";
import { RootState } from "../../../../../shared/constants";
import FormBar from "../../../../../shared/layouts/formbar.layout";
type Props = {
  pageData: any;
  formDetails: any;
  modalVisible: boolean;
  setModalVisible: (val: any) => void;
};

function FormSettingsComponent({
  pageData,
  formDetails,
  modalVisible,
  setModalVisible,
}: Props) {
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(false);
  const { branches } = useSelector((state: RootState) => state.branch);

  useEffect(() => {
    branches?.length == 0 && dispatch(fetchBranches({}));
  }, []);

  const onFinish = (values: any) => {
    setFormLoading(true);
    let data: any = {
      page_id: formDetails.form_id,
      ...values,
    };
    // dispatch(patchFacebookPageData(data)).then((val: any) => {
    //   message.success("Branch mapped with this page updated!");
    //   setModalVisible(false);
    // });
  };

  const formBasic = {
    name: "Page Settings Form",
    onFinish: onFinish,
    onFinishFailed: () => {},
    initialValues: {},
    setValues: {},
    buttonText: "Save Settings",
    formElements: [
      {
        label: (
          <div style={{ display: "block" }}>
            <Row>
              <Col span={24}>
                {/* <Divider style={{ marginTop: "10px", marginBottom: "10px" }} /> */}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                Form level System Fields Mapping - These will override page
                level settings
              </Col>
            </Row>
          </div>
        ),
        type: "label",
        name: "none",
      },
      {
        label: (
          <>
            Branch Mapping &nbsp;&nbsp;&nbsp;
            <Tooltip title="Leads coming from this form will be mapped to selected branch.">
              <a>
                <InfoCircleFilled />
              </a>
            </Tooltip>
          </>
        ),
        type: "Select",
        name: "branch_id",
        colSpan: 12,
        options: branches?.map((val: any) => {
          return { name: val.name, id: val.id };
        }),
        allowClear: true,
      },
      {
        label: (
          <>
            Service Type &nbsp;&nbsp;&nbsp;
            <Tooltip title="Leads coming from this form will be mapped to selected service type.">
              <a>
                <InfoCircleFilled />
              </a>
            </Tooltip>
          </>
        ),
        type: "Select",
        name: "svc_type_id",
        colSpan: 12,
        options: branches?.map((val: any) => {
          return { name: val.name, id: val.id };
        }),
        allowClear: true,
      },
      {
        label: (
          <>
            Service Sub Type &nbsp;&nbsp;&nbsp;
            <Tooltip title="Leads coming from this form will be mapped to selected service subtype.">
              <a>
                <InfoCircleFilled />
              </a>
            </Tooltip>
          </>
        ),
        type: "Select",
        name: "svc_sub_type_id",
        colSpan: 12,
        options: branches?.map((val: any) => {
          return { name: val.name, id: val.id };
        }),
        allowClear: true,
      },
      {
        label: (
          <>
            Priority &nbsp;&nbsp;&nbsp;
            <Tooltip title="Leads coming from this form will be mapped to selected priority.">
              <a>
                <InfoCircleFilled />
              </a>
            </Tooltip>
          </>
        ),
        type: "Select",
        name: "priority_id",
        colSpan: 12,
        options: branches?.map((val: any) => {
          return { name: val.name, id: val.id };
        }),
        allowClear: true,
      },
      {
        label: (
          <div style={{ display: "block" }}>
            <Row>
              <Col span={24}>
                {/* <Divider style={{ marginTop: "10px", marginBottom: "10px" }} /> */}
              </Col>
            </Row>
            <Row>
              <Col span={24}>Form Fields to System Fields Mapping</Col>
            </Row>
          </div>
        ),
        type: "label",
        name: "none",
      },
      ...formDetails.form_questions?.map((question: any) => {
        return {
          label: question.label ? question.label : question.type,
          type: "Select",
          name: question.key,
          colSpan: 12,
          options: [
            { name: "First Name", id: "first_name" },
            { name: "Last Name", id: "first_name" },
            { name: "Phone Number", id: "phone" },
            { name: "Email ID", id: "email" },
            { name: "DOB", id: "dob" },
          ],
          allowClear: true,
        };
      }),
    ],
  };

  return (
    <>
      <Drawer
        title={`Form Settings`}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        width={720}
        footer={false}
        className="form-drawer"
        style={{ paddingBottom: "0px" }}
      >
        <div className="pd20">
          <Row gutter={32}>
            <Col span={6} className={"text-secondary"}>
              Form Name
            </Col>
            <Col span={16}>{formDetails.form_name}</Col>
          </Row>
          <Row gutter={32}>
            <Col span={6} className={"text-secondary"}>
              Page Name
            </Col>
            <Col span={16}>{pageData.page_name}</Col>
          </Row>
        </div>
        {/* {JSON.stringify(formDetails)} */}
        <FormBar {...formBasic} loading={formLoading}></FormBar>
      </Drawer>
    </>
  );
}

export default FormSettingsComponent;
