import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../shared/Utils/api-client";
import {
  PATIENT_DOCUMENT_FILE_BY_ID,
  PATIENT_DOCUMENT_QC_STATUS,
  PATIENT_DOCUMENT_REORDER,
} from "../../shared/routes/patient.routes.constants";
import {
  DOWNLOAD_DUMP_FILE,
  DOWNLOAD_DUMP_FILE_POST,
  PRESCRIPTION_FILE_BY_ID,
  UPLOAD_DUMP_FILE_POST,
  UPLOAD_FILE,
} from "../../shared/routes/route.constants";
const config = {
  name: "upload",
};
export const uploadFile: any = createAsyncThunk(
  `${config.name}/uploadFile`,
  async (data: any) => {
    return await Client.post(UPLOAD_FILE, data, {
      // mediURL: true,
    });
  }
);

export const downloadDump: any = createAsyncThunk(
  `${config.name}/downloadDump`,
  async (data: any) => {
    return await Client.get(DOWNLOAD_DUMP_FILE, {});
  }
);

export const downloadDumpPost: any = createAsyncThunk(
  `${config.name}/downloadDumpPost`,
  async (data: any) => {
    return await Client.post(DOWNLOAD_DUMP_FILE_POST, {});
  }
);

export const uploadDumpSeed: any = createAsyncThunk(
  `${config.name}/uploadDumpSeed`,
  async (data: any) => {
    // debugger;
    return await Client.post(UPLOAD_DUMP_FILE_POST, data, {
      removeContentType: true,
    });
  }
);

export const fetchUploadedFileById: any = createAsyncThunk(
  `${config.name}/fetchUploadedFileById`,
  async (data: any) => {
    return await Client.get(PRESCRIPTION_FILE_BY_ID(data.type, data.id), {
      mediURL: true,
    });
  }
);

export const fetchPatientDocumentFileById: any = createAsyncThunk(
  `${config.name}/fetchPatientDocumentFileById`,
  async (id: any) => {
    return await Client.get(PATIENT_DOCUMENT_FILE_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const updatePatientDocumentQC: any = createAsyncThunk(
  `${config.name}/updatePatientDocumentQC`,
  async (data: any) => {
    return await Client.patch(PATIENT_DOCUMENT_QC_STATUS, data, {
      mediURL: true,
    });
  }
);
export const updatePatientDocumentReorder: any = createAsyncThunk(
  `${config.name}/updatePatientDocumentReorder`,
  async (data: any) => {
    return await Client.patch(PATIENT_DOCUMENT_REORDER, data, {
      mediURL: true,
    });
  }
);

export const uploadSlice = createSlice({
  name: config.name,
  initialState: {
    loading: true,
    savingFile: false,
    uploaderror: "" as string,
    file: [] as any,
    signedUrl: [] as any,
    error: true as any,
  },
  reducers: {
    reset: (state) => {
      state.savingFile = false;
      state.loading = true;
      state.uploaderror = "";
      state.file = [];
      state.signedUrl = [];
      state.error = true;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(uploadFile.pending, (state) => {
        state.loading = true;
        state.uploaderror = "";
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.loading = true;
        state.file = action?.payload?.data?.data;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.loading = false;
        state.uploaderror = action.error.message;
      })

      .addCase(fetchUploadedFileById.pending, (state) => {
        state.loading = true;
        state.uploaderror = "";
        state.error = true;
      })
      .addCase(fetchUploadedFileById.fulfilled, (state, action) => {
        state.loading = false;
        state.signedUrl = action?.payload?.data?.data;
        state.error = false;
      })
      .addCase(fetchUploadedFileById.rejected, (state, action) => {
        state.loading = false;
        state.uploaderror = action.error.message;
        state.error = true;
      })
      .addCase(fetchPatientDocumentFileById.pending, (state) => {
        state.loading = true;
        state.uploaderror = "";
        state.error = false;
      })
      .addCase(fetchPatientDocumentFileById.fulfilled, (state, action) => {
        state.loading = false;
        state.signedUrl = action?.payload?.data?.data;
        state.error = true;
      })
      .addCase(fetchPatientDocumentFileById.rejected, (state, action) => {
        state.loading = false;
        state.uploaderror = action.error.message;
        state.error = false;
      });
  },
});

export const { reset } = uploadSlice.actions;

export default uploadSlice.reducer;
