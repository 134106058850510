import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DISCHARGE_SUMMARY,
  PRESCRIPTION,
  PRESCRIPTION_BOTH_TEMPLATES,
  PRESCRIPTION_BY_ID,
  PRESCRIPTION_COUNT,
  PRESCRIPTION_ITEM_LIST,
  PRESCRIPTION_LABREPORTS,
  PRESCRIPTION_TEMPLATES,
  PRESCRIPTION_TEMPLATE_GROUPS,
  PRESCRIPTION_TEMPLATE_URL_BY_ID,
  PRESCRIPTION_UPDATE,
} from "../../shared/routes/route.constants";
import { Client } from "../../shared/Utils/api-client";

const config = {
  name: "prescription",
};
export const fetchPrescriptions: any = createAsyncThunk(
  `${config.name}/fetchPrescriptions`,
  async (params: any) => {
    return await Client.get(PRESCRIPTION, {
      params,
      mediURL: true,
    });
  }
);

export const fetchPrescriptionById: any = createAsyncThunk(
  `${config.name}/fetchPrescriptionById`,
  async (id: any) => {
    return await Client.get(PRESCRIPTION_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const fetchAllPrescriptions: any = createAsyncThunk(
  `${config.name}/fetchAllPrescriptions`,
  async (params: any) => {
    return await Client.get(PRESCRIPTION, {
      params,
      mediURL: true,
    });
  }
);
export const fetchPrescriptionsLabReports: any = createAsyncThunk(
  `${config.name}/fetchPrescriptionsLabReports`,
  async (data: any) => {
    return await Client.post(PRESCRIPTION_LABREPORTS, data, {
      mediURL: true,
    });
  }
);

export const fetchPatientsDischargeSummary: any = createAsyncThunk(
  `${config.name}/fetchPatientsDischargeSummary`,
  async (uhid: any) => {
    return await Client.get(DISCHARGE_SUMMARY(uhid), {
      mediURL: true,
    });
  }
);

export const fetchPrescriptionsCount: any = createAsyncThunk(
  `${config.name}/fetchPrescriptionsCount`,
  async (params: any) => {
    return await Client.get(PRESCRIPTION_COUNT, {
      mediURL: true,
    });
  }
);

export const fetchItemList: any = createAsyncThunk(
  `${config.name}/fetchItemList`,
  async (params: any) => {
    return await Client.get(
      PRESCRIPTION_ITEM_LIST(params.type, params.searchTerm),
      { mediURL: true }
    );
  }
);

export const fetchAllTemplates: any = createAsyncThunk(
  `${config.name}/fetchalltemplates`,
  async (params: any) => {
    return await Client.get(
      PRESCRIPTION_TEMPLATES,
      { params, mediURL: true }
      // {  }
    );
  }
);

export const fetchBothTemplates: any = createAsyncThunk(
  `${config.name}/fetchBothTemplates`,
  async (params: any) => {
    return await Client.get(PRESCRIPTION_BOTH_TEMPLATES, {
      params,
      mediURL: true,
    });
  }
);

export const fetchAllTemplateGroups: any = createAsyncThunk(
  `${config.name}/fetchalltemplategroups`,
  async (params: any) => {
    return await Client.get(PRESCRIPTION_TEMPLATE_GROUPS, {
      params,
      mediURL: true,
    });
  }
);

export const geTemplateUrl: any = createAsyncThunk(
  `${config.name}/fetchTemplateUrl`,
  async (id: any) => {
    return await Client.get(PRESCRIPTION_TEMPLATE_URL_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const addPrescriptions: any = createAsyncThunk(
  `${config.name}/addPrescriptions`,
  async (data: any) => {
    return await Client.post(PRESCRIPTION, data, {
      mediURL: true,
    });
  }
);

export const updatePrescription: any = createAsyncThunk(
  `${config.name}/updatePrescription`,
  async (body: any) => {
    return await Client.patch(PRESCRIPTION_UPDATE(body?.id), body.data, {
      mediURL: true,
    });
  }
);

export const addNewPrescriptionTemplate: any = createAsyncThunk(
  `${config.name}/addNewPrescriptionTemplate`,
  async (data: any) => {
    return await Client.post(PRESCRIPTION_TEMPLATES, data, {
      mediURL: true,
    });
  }
);

export const updatePrescriptionTemplate: any = createAsyncThunk(
  `${config.name}/updatePrescriptionTemplate`,
  async (data: any) => {
    return await Client.patch(PRESCRIPTION_TEMPLATES, data, {
      mediURL: true,
    });
  }
);

export const prescriptionSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingPrescription: false,
    error: "" as string,
    prescriptions: [] as any,
    prescriptionTemplate: [] as any,
    prescriptionItemList: [] as any,
    prescriptionTemplateUrl: "" as string,
    prescriptionTemplateGroup: [] as any,
    total_prescriptions: null as any,
    labreports: [] as any,
    allPrescriptions: [] as any,
    dischargeSummary: [] as any,
  },
  reducers: {
    reset: (state) => {
      state.savingPrescription = false;
      state.loading = false;
      state.error = "";
      state.prescriptionTemplateUrl = "";
      state.prescriptions = [];
      state.prescriptionTemplate = [];
      state.prescriptionItemList = [];
      state.prescriptionTemplateGroup = [];
    },
    reset_file: (state) => {
      state.loading = false;
      state.error = "";
      state.prescriptionTemplateUrl = "";
    },
    reset_prescription: (state) => {
      state.prescriptions = [];
      state.labreports = [];
      state.loading = true;
    },
  },
  extraReducers(builder) {
    builder
      // items Fetch
      .addCase(fetchItemList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchItemList.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionItemList = action?.payload?.data?.data;
      })
      .addCase(fetchItemList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // templates Fetch
      .addCase(fetchAllTemplates.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionTemplate = action?.payload?.data?.data?.items;
      })
      .addCase(fetchAllTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchBothTemplates.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchBothTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionTemplate = action?.payload?.data?.data?.items;
      })
      .addCase(fetchBothTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //geTemplateUrl
      .addCase(geTemplateUrl.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(geTemplateUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionTemplateUrl = action?.payload?.data?.data?.result;
      })
      .addCase(geTemplateUrl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Prescription Post
      .addCase(addNewPrescriptionTemplate.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(addNewPrescriptionTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionTemplate = action?.payload?.data?.data?.items;
      })
      .addCase(addNewPrescriptionTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Prescription Patch
      .addCase(updatePrescriptionTemplate.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updatePrescriptionTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionTemplate = action?.payload?.data?.data?.items;
      })
      .addCase(updatePrescriptionTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // template Groups Fetch
      .addCase(fetchAllTemplateGroups.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllTemplateGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionTemplateGroup = action?.payload?.data?.data?.items;
      })
      .addCase(fetchAllTemplateGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Prescription Fetch
      .addCase(fetchPrescriptions.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPrescriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptions = action?.payload?.data?.data?.items;
      })
      .addCase(fetchPrescriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchAllPrescriptions.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllPrescriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.allPrescriptions = action?.payload?.data?.data?.items;
      })
      .addCase(fetchAllPrescriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchPrescriptionsLabReports.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPrescriptionsLabReports.fulfilled, (state, action) => {
        state.loading = false;
        state.labreports = action?.payload?.data?.data?.items;
      })
      .addCase(fetchPrescriptionsLabReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Prescription Post
      .addCase(addPrescriptions.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(addPrescriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptions = action?.payload?.data?.data?.items;
      })
      .addCase(addPrescriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Prescription Update
      .addCase(updatePrescription.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updatePrescription.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptions = action?.payload?.data?.data?.items;
      })
      .addCase(updatePrescription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // fetchPrescriptionsCount
      .addCase(fetchPrescriptionsCount.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPrescriptionsCount.fulfilled, (state, action) => {
        state.loading = false;
        state.total_prescriptions = action?.payload?.data?.data;
      })
      .addCase(fetchPrescriptionsCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Discharge Summary

      .addCase(fetchPatientsDischargeSummary.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPatientsDischargeSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.dischargeSummary = action?.payload?.data?.data;
      })
      .addCase(fetchPatientsDischargeSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { reset, reset_file, reset_prescription } =
  prescriptionSlice.actions;

export default prescriptionSlice.reducer;
