import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../shared/Utils/api-client";
import {
  ADD_NEW_ADDRESS,
  ADD_NEW_MEDICINE,
  CREATE_PHARMACY_ORDER_REQUEST,
  DELETE_MEDICINE,
  DELETE_PRESCRIPTION_CART,
  FETCH_BRANCHES,
  FETCH_CITY,
  FETCH_MEDICINES_BY_ID,
  FETCH_MEDICINE_ITEMS,
  FETCH_MEDICINE_PRICE_INVENTORY,
  FETCH_ORDERS,
  FETCH_ORDER_DETAILS_BY_ID,
  FETCH_PATIENT_ADDRESS,
  FETCH_PAYMENT_STATUS,
  FETCH_PAYMENT_SUMMARY,
  FETCH_PHARMACY_ORDER_REQUEST,
  FETCH_PRESCRIPTION_BY_ORDER,
  FETCH_PRESCRIPTION_SIGNED_URL,
  FETCH_RX_MASTER_LIST,
  FETCH_STATIONS_FROM_ADDRESS_BY_ID,
  FETCH_STATIONS_LIST,
  FETCH_SUBSTITUTE,
  MAP_PRESCRIPTION_CART,
  ORDER_BY_LEADID,
  PAYMENT_REFUND,
  PLACE_NEW_ORDER,
  PROCEED_QC_REQUEST,
  SUMMARY_TABLE_COUNT,
  UPDATE_MEDICINE_ITEM,
  UPDATE_PHARMACY_DETAIL,
  UPDATE_REQUEST_STATUS,
} from "../../shared/routes/order.routes.constants";

const config = {
  name: "pharmacy",
};

export const fetchPharmacyOrderRequest: any = createAsyncThunk(
  `${config.name}/fetchPharmacyOrderRequest`,
  async (params: any) => {
    return await Client.get(FETCH_PHARMACY_ORDER_REQUEST, {
      params,
      mediURL: true,
    });
  }
);

export const mapPrescriptionToCart: any = createAsyncThunk(
  `${config.name}/link-file-to-request`,
  async (prescription: any) => {
    const id = prescription.request_id;
    return await Client.post(MAP_PRESCRIPTION_CART(id), prescription, {
      mediURL: true,
    });
  }
);

export const deleteMapPrescriptionToCart: any = createAsyncThunk(
  `${config.name}/deleteMapPrescriptionToCart`,
  async (data: any) => {
    const { file_upload_id, requestId } = data;
    return await Client.patch(
      DELETE_PRESCRIPTION_CART(requestId),
      {
        file_upload_id: file_upload_id,
      },
      {
        mediURL: true,
      }
    );
  }
);

export const createPharmacyOrderRequest: any = createAsyncThunk(
  `${config.name}/createPharmacyOrderRequest`,
  async (body: any) => {
    return await Client.post(CREATE_PHARMACY_ORDER_REQUEST, body, {
      mediURL: true,
    });
  }
);

export const fetchMedicinesById: any = createAsyncThunk(
  `${config.name}/fetchMedicinesById`,
  async (id: string) => {
    return await Client.get(FETCH_MEDICINES_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const fetchStationsList: any = createAsyncThunk(
  `${config.name}/fetchStationsList`,
  async (params: any) => {
    return await Client.get(FETCH_STATIONS_LIST, {
      params,
      mediURL: true,
    });
  }
);

export const fetchRxMasterList: any = createAsyncThunk(
  `${config.name}/fetchRxMasterList`,
  async (params: any) => {
    return await Client.get(FETCH_RX_MASTER_LIST, {
      params,
      mediURL: true,
    });
  }
);

export const fetchPatientAddress: any = createAsyncThunk(
  `${config.name}/fetchPatientAddress`,
  async (params: any) => {
    return await Client.get(FETCH_PATIENT_ADDRESS, {
      params,
      mediURL: true,
    });
  }
);

export const fetchMedicineItems: any = createAsyncThunk(
  `${config.name}/fetchMedicineItems`,
  async (params: any) => {
    return await Client.get(FETCH_MEDICINE_ITEMS, {
      params,
      mediURL: true,
    });
  }
);

export const addNewMedicine: any = createAsyncThunk(
  `${config.name}/addNewMedicine`,
  async (data: any) => {
    return await Client.post(ADD_NEW_MEDICINE, data, {
      mediURL: true,
    });
  }
);

export const deleteMedicineById: any = createAsyncThunk(
  `${config.name}/deleteMedicineById`,
  async (data: any) => {
    return await Client.post(DELETE_MEDICINE, data, {
      mediURL: true,
    });
  }
);

export const addNewAddress: any = createAsyncThunk(
  `${config.name}/addNewAddress`,
  async (data: any) => {
    return await Client.post(ADD_NEW_ADDRESS, data, {
      mediURL: true,
    });
  }
);

export const proceedQCRequest: any = createAsyncThunk(
  `${config.name}/proceedQCRequest`,
  async (data: any) => {
    return await Client.patch(PROCEED_QC_REQUEST, data, {
      mediURL: true,
    });
  }
);

export const placeNewOrder: any = createAsyncThunk(
  `${config.name}/placeNewOrder`,
  async (data: any) => {
    return await Client.post(PLACE_NEW_ORDER, data, {
      mediURL: true,
    });
  }
);

export const updateMedicineItem: any = createAsyncThunk(
  `${config.name}/updateMedicineItem`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(UPDATE_MEDICINE_ITEM(id), data, {
      mediURL: true,
    });
  }
);

export const updatePharmacyDetail: any = createAsyncThunk(
  `${config.name}/updatePharmacyDetail`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(UPDATE_PHARMACY_DETAIL(id), data, {
      mediURL: true,
      mediURLMethod: "PATCH",
    });
  }
);

export const fetchStationfromAddress: any = createAsyncThunk(
  `${config.name}/fetchStationfromAddress`,
  async (params: any) => {
    return await Client.get(FETCH_STATIONS_FROM_ADDRESS_BY_ID, {
      params,
      mediURL: true,
    });
  }
);

export const fetchMedicinePriceInventory: any = createAsyncThunk(
  `${config.name}/fetchMedicinePriceInventory`,
  async (params: any) => {
    return await Client.get(FETCH_MEDICINE_PRICE_INVENTORY, {
      params,
      mediURL: true,
    });
  }
);

export const getPrescriptionSignedUrl: any = createAsyncThunk(
  `${config.name}/getImageSignedUrl`,
  async (id: string) => {
    return await Client.get(FETCH_PRESCRIPTION_SIGNED_URL(id), {
      mediURL: true,
    });
  }
);

export const fetchPlacedOrders: any = createAsyncThunk(
  `${config.name}/fetchPlacedOrders`,
  async (params: any) => {
    return await Client.get(FETCH_ORDERS, {
      params,
      mediURL: true,
    });
  }
);

export const fetchInvoicedOrders: any = createAsyncThunk(
  `${config.name}/fetchInvoicedOrders`,
  async (params: any) => {
    return await Client.get(FETCH_ORDERS, {
      params,
      mediURL: true,
    });
  }
);

export const fetchCancelledOrders: any = createAsyncThunk(
  `${config.name}/fetchCancelledOrders`,
  async (params: any) => {
    return await Client.get(FETCH_ORDERS, {
      params,
      mediURL: true,
    });
  }
);

export const fetchAssignedOrders: any = createAsyncThunk(
  `${config.name}/fetchAssignedOrders`,
  async (params: any) => {
    return await Client.get(FETCH_ORDERS, {
      params,
      mediURL: true,
    });
  }
);

export const fetchDeliveredOrders: any = createAsyncThunk(
  `${config.name}/fetchDeliveredOrders`,
  async (params: any) => {
    return await Client.get(FETCH_ORDERS, {
      params,
      mediURL: true,
    });
  }
);

export const fetchReturnedOrders: any = createAsyncThunk(
  `${config.name}/fetchReturnedOrders`,
  async (params: any) => {
    return await Client.get(FETCH_ORDERS, {
      params,
      mediURL: true,
    });
  }
);

export const updateRequestStatus: any = createAsyncThunk(
  `${config.name}/updateRequestStatus`,
  async (data: any) => {
    return await Client.patch(UPDATE_REQUEST_STATUS(data.order_id), data, {
      mediURL: true,
    });
  }
);

export const summaryTableCount: any = createAsyncThunk(
  `${config.name}/summaryTableCount`,
  async (params: any) => {
    return await Client.get(SUMMARY_TABLE_COUNT, {
      mediURL: true,
    });
  }
);

export const fetchCity: any = createAsyncThunk(
  `${config.name}/fetchCity`,
  async (params: any) => {
    return await Client.get(FETCH_CITY, {
      params,
      mediURL: true,
    });
  }
);

export const getPaymentStatus: any = createAsyncThunk(
  `${config.name}/getPaymentStatus`,
  async (id: string) => {
    return await Client.get(FETCH_PAYMENT_STATUS(id), {
      mediURL: true,
    });
  }
);

export const fetchOrderDetailsByID: any = createAsyncThunk(
  `${config.name}/fetchOrderDetailsByID`,
  async (id: string) => {
    return await Client.get(FETCH_ORDER_DETAILS_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const fetchBranches: any = createAsyncThunk(
  `${config.name}/fetchBranches`,
  async (params: any) => {
    return await Client.get(FETCH_BRANCHES, {
      mediURL: true,
    });
  }
);
export const fetchPrescriptionsByOrder: any = createAsyncThunk(
  `${config.name}/fetchPrescriptionsByOrder`,
  async (id: any) => {
    return await Client.get(FETCH_PRESCRIPTION_BY_ORDER(id), {
      mediURL: true,
    });
  }
);

export const fetchPaymentSummary: any = createAsyncThunk(
  `${config.name}/fetchPaymentSummary`,
  async (params: any) => {
    return await Client.get(FETCH_PAYMENT_SUMMARY, {
      params,
      mediURL: true,
    });
  }
);
export const paymentRefund: any = createAsyncThunk(
  `${config.name}/paymentRefund`,
  async (id: any) => {
    return await Client.get(PAYMENT_REFUND(id), {
      mediURL: true,
    });
  }
);

export const fetchOrdersByLeadId: any = createAsyncThunk(
  `${config.name}/fetchOrdersByLeadId`,
  async (id: string) => {
    return await Client.get(ORDER_BY_LEADID(id), {
      mediURL: true,
    });
  }
);

export const fetchSubstitutes: any = createAsyncThunk(
  `${config.name}/get-drug-substitute`,
  async (data: any) => {
    const { item_code: itemCode, station_code: stationCode } = data;
    return await Client.get(FETCH_SUBSTITUTE(itemCode, stationCode), {
      mediURL: true,
    });
  }
);

export const pharmacySlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    error: "" as string,
    pagination: { offset: 0, limit: 10, total: 0 },
    pharmacyOrder: [] as any,
    medicine: [] as any,
    stations: [] as any,
    rxList: [] as any,
    address: [] as any,
    medicines: [] as any,
    pharmacyDetails: {} as any,
    stationName: {} as any,
    stationNameFromAddress: {} as any,
    prescriptionSignedUrl: [] as any,
    medicineInventoryPrice: [] as any,
    placedOrderSummary: [] as any,
    invoicedOrderSummary: [] as any,
    cancelledOrderSummary: [] as any,
    assignedOrderSummary: [] as any,
    deliveredOrderSummary: [] as any,
    returnedOrderSummary: [] as any,
    summaryCount: {} as any,
    fetchPaymentStatus: {} as any,
    fetchOrderDetails: {} as any,
    branchList: [] as any,
    paymentSummary: [] as any,
    substitutes: [] as any,
    comment: "" as string,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = "";
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.pharmacyOrder = [];
      state.medicine = [];
      state.stations = [];
      state.rxList = [];
      state.address = [];
      state.medicines = [];
      state.pharmacyDetails = {};
      state.stationName = {};
      state.stationNameFromAddress = {};
      state.prescriptionSignedUrl = [];
      state.medicineInventoryPrice = [];
      state.placedOrderSummary = [];
      state.invoicedOrderSummary = [];
      state.assignedOrderSummary = [];
      state.deliveredOrderSummary = [];
      state.returnedOrderSummary = [];
      state.summaryCount = {};
      state.fetchPaymentStatus = {};
      state.fetchOrderDetails = {};
      state.branchList = [];
      state.paymentSummary = [];
      state.substitutes = [];
      state.comment = "";
    },
    addComment: (state, action) => {
      state.comment = action.payload;
    },
    resetComment: (state) => {
      state.comment = "";
    },
  },
  extraReducers(builder) {
    builder

      // Fetch Pharmacy Order Request List
      .addCase(fetchPharmacyOrderRequest.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPharmacyOrderRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.pharmacyOrder = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchPharmacyOrderRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create Pharmacy Order Request List
      .addCase(createPharmacyOrderRequest.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createPharmacyOrderRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.pharmacyOrder = action?.payload?.data?.data;
      })
      .addCase(createPharmacyOrderRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch medicine Detail by id
      .addCase(fetchMedicinesById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMedicinesById.fulfilled, (state, action) => {
        state.loading = false;
        state.medicine = action?.payload?.data?.data?.pharmacyItems;
        state.pharmacyDetails = action?.payload?.data?.data?.pharmacy;
      })
      .addCase(fetchMedicinesById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch station list
      .addCase(fetchStationsList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchStationsList.fulfilled, (state, action) => {
        state.loading = false;
        state.stations = action?.payload?.data?.data;
      })
      .addCase(fetchStationsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Rx Master list
      .addCase(fetchRxMasterList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchRxMasterList.fulfilled, (state, action) => {
        state.loading = false;
        state.rxList = action?.payload?.data?.data;
      })
      .addCase(fetchRxMasterList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Patient Address
      .addCase(fetchPatientAddress.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPatientAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.address = action?.payload?.data?.data;
      })
      .addCase(fetchPatientAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch medicine Items
      .addCase(fetchMedicineItems.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMedicineItems.fulfilled, (state, action) => {
        state.loading = false;
        console.log("fetch medicine: ", action);
        state.medicines = action?.payload?.data?.data;
      })
      .addCase(fetchMedicineItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch station Name from Address
      .addCase(fetchStationfromAddress.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchStationfromAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.stationNameFromAddress = action?.payload?.data?.data;
      })
      .addCase(fetchStationfromAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch medicine price
      .addCase(fetchMedicinePriceInventory.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMedicinePriceInventory.fulfilled, (state, action) => {
        state.loading = false;
        state.medicineInventoryPrice = action?.payload?.data?.data?.data;
      })
      .addCase(fetchMedicinePriceInventory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch prescription signed URL
      .addCase(getPrescriptionSignedUrl.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getPrescriptionSignedUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionSignedUrl = action?.payload?.data?.data?.result;
      })
      .addCase(getPrescriptionSignedUrl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch placed Orders
      .addCase(fetchPlacedOrders.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPlacedOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.placedOrderSummary = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchPlacedOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch invoiced Orders
      .addCase(fetchInvoicedOrders.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchInvoicedOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.invoicedOrderSummary = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchInvoicedOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch cancelled Orders
      .addCase(fetchCancelledOrders.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchCancelledOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.cancelledOrderSummary = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchCancelledOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch assigned Orders
      .addCase(fetchAssignedOrders.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAssignedOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.assignedOrderSummary = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchAssignedOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch delivered Orders
      .addCase(fetchDeliveredOrders.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchDeliveredOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.deliveredOrderSummary = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchDeliveredOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch returned Orders
      .addCase(fetchReturnedOrders.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchReturnedOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.returnedOrderSummary = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchReturnedOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Orders
      .addCase(summaryTableCount.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(summaryTableCount.fulfilled, (state, action) => {
        state.loading = false;
        state.summaryCount = action?.payload?.data?.data;
      })
      .addCase(summaryTableCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch payment status
      .addCase(getPaymentStatus.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getPaymentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchPaymentStatus = action?.payload?.data?.data;
      })
      .addCase(getPaymentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch order details by id
      // .addCase(fetchOrderDetailsByID.pending, (state) => {
      //   state.loading = true;
      //   state.error = "";
      // })
      // .addCase(fetchOrderDetailsByID.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.fetchOrderDetails = action?.payload?.data?.data;
      // })
      // .addCase(fetchOrderDetailsByID.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })

      // Fetch Branches
      .addCase(fetchBranches.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchBranches.fulfilled, (state, action) => {
        state.loading = false;
        state.branchList = action?.payload?.data?.data;
      })
      .addCase(fetchBranches.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Payment
      .addCase(fetchPaymentSummary.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPaymentSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentSummary = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchPaymentSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Substitutes
      .addCase(fetchSubstitutes.pending, (state) => {
        state.loading = true;
        state.substitutes = [];
        state.error = "";
      })
      .addCase(fetchSubstitutes.fulfilled, (state, action) => {
        state.loading = false;
        state.substitutes = action.payload.data.data.sort(
          (a: any, b: any) => b.total_quantity - a.total_quantity
        );
        state.error = "";
      })
      .addCase(fetchSubstitutes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { addComment, resetComment } = pharmacySlice.actions;

export default pharmacySlice.reducer;
