import { Col, Input, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
    fetchChatRooms,
    markReadMessagePatient
} from "../../../redux/reducers/chat.slice";
import { RootState } from "../../../shared/constants";
import ChatLayout from "../../../shared/layouts/chat.layout";
import PatientList from "../components/patientList.page";



const _ = require("lodash");
const { Search } = Input;
type Props = {};

const Chat: React.FunctionComponent<Props> = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const audioRef = useRef(null as any);
    const buttonRef = useRef(null as any);
    const [newMessageReceived, setNewMessageReceived] = useState(false);
    const { profile } = useSelector((state: RootState) => state.profile);
    const { room_loading, rooms } = useSelector((state: RootState) => state.chat);
    const { patient } = useSelector((state: RootState) => state.patient);
    const [chat, setChat] = useState(false);
    const [allRooms, setALlRoms] = useState([] as Array<any>);
    const _patientData: any = location?.state || {};
    const { connection } = useSelector((state: RootState) => state.chat);
    const [allMessages, setAllMessages] = useState([] as any);
    const ws = useRef(null as any);
    const { account } = useSelector((state: RootState) => state.auth);
    const app: string = "agent";
    const [chatRoomId, setchatRoomId] = useState(null as any)
    const [patientMobile, setpatientMobile] = useState(null as any)

    const [localRoomsValues, setlocalRoomsValues] = useState(null as any)
    const stateRef = useRef(null as any);
    const [clickedCount, setclickedCount] = useState(0)
    const [oldChatRoomId, setoldChatRoomId] = useState(null as any)
    const [source, setsource] = useState(null as any)

    const [chatCount, setchatCount] = useState(0 as any)

    const params = {
        account_id: account?.id,
        application_id: localStorage?.getItem("application_id"),
        token: localStorage?.getItem("token"),
    }



    useEffect(() => {
        stateRef.current = rooms;
        // setchatCount(rooms?.length)
        // if (rooms.length < chatCount) {
        //     setChat(false)
        // }
    }, [rooms])




    useEffect(() => {
        // Click the button when a new message is received
        if (newMessageReceived) {
            buttonRef.current.click();
            // Reset state after button click
            setNewMessageReceived(false);
        }
    }, [newMessageReceived]);

    useEffect(() => {

        const wsCurrent = connection;
        if (!wsCurrent) return;

        if (connection) {
            const paramss = { ...params }
            connection.onmessage = (e: any) => {
                const _message = JSON.parse(e.data);

                if (_message.title === 'removed' && (chatRoomId === _message.data.chat_room_id)) {
                    setChat(false)
                }

                if (_message.type === 'refresh_user_list') {

                    dispatch(
                        fetchChatRooms({
                            paramss,
                            user_type: `agent`,
                            user_id: profile?.id,
                            room_type: "all",
                        })
                    );
                    // setChat(false)
                }
                else {
                    if (_message.role === 'user') {
                        setNewMessageReceived(true);
                    }
                    setAllMessages((prevState: any) => [...prevState, _message]);
                    var element = document.getElementById("box") as any;
                    element &&
                        element.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "nearest",
                        });
                    dispatch(
                        fetchChatRooms({
                            paramss,
                            user_type: `agent`,
                            user_id: profile?.id,
                            room_type: "all",
                        })
                    );
                }

            };
        }


    });







    useEffect(() => {
        const paramss = { ...params }
        dispatch(
            fetchChatRooms({
                paramss,
                user_type: `agent`,
                // limit: "",
                user_id: profile?.id,
                room_type: "all",
            })
        );
    }, []);

    useEffect(() => {
        rooms && setALlRoms(rooms);
        // rooms?.length > 0 && setsource(rooms[0].source)
    }, [rooms]);

    const showDetails = (data: any) => {
        (async function () {
            setsource(data.source)
            setoldChatRoomId(chatRoomId)
            setclickedCount(clickedCount + 1)
            setchatRoomId(data.chat_room_id)
            setpatientMobile(data?.patient_mobile)
            setChat(true)
            const id = data.chat_room_id
            const newParams = { source: data.source, ...params, }
            dispatch(markReadMessagePatient({ id, newParams }));

        })();

    };



    const getPatientsList = (text: any) => {

        if (text) {
            const myObjects = rooms.filter((obj: any) => obj?.patient_mobile?.includes(text));

            setALlRoms(myObjects)
        }
        else {
            setALlRoms(rooms)
        }

    };

    const playMusic = () => {
        audioRef?.current?.play()
    }


    return (
        <Row>
            <Col
                span={7}
                className="full-height white-background padding20 overflowhide border-right"
                style={{ paddingBottom: "50px" }}
            >
                <Row>
                    <Col span={20}>
                        <h2 className="bold doctor-primary-color mt10 ">My Chats</h2>
                    </Col>

                    <Col span={24}>
                        <Search
                            style={{ width: "100%" }}
                            allowClear
                            size={"large"}
                            placeholder="Search Patient"
                            className="doctor-search border-radius5"
                            onSearch={(evt) => getPatientsList(evt)}
                        ></Search>
                    </Col>
                </Row>
                {
                    // room_loading ? (
                    //     <>
                    //         <Skeleton active className={"mt20"}></Skeleton>{" "}
                    //         <Skeleton active className={"mt20"}></Skeleton>
                    //     </>
                    // ) : 
                    (
                        <>
                            {" "}
                            <Row
                                className="mt20 nomarginLR20 padding20 doctor-light-background borderRightGray"
                                style={{ marginRight: "-20px" }}
                            >
                                <Col span={24}>
                                    {allRooms && (
                                        <h3 className="bold doctor-primary-color mt10 ">
                                            {allRooms.length} Users Found
                                        </h3>
                                    )}
                                </Col>
                            </Row>
                            <Row className={"nomargin"}>
                                <Col span={24} style={{ marginTop: "24px" }}>
                                    <audio ref={audioRef}>
                                        <source src="http://codeskulptor-demos.commondatastorage.googleapis.com/pang/pop.mp3" type="audio/mpeg" />
                                    </audio>
                                    {/* Button element to trigger click event */}
                                    <button style={{ display: 'none' }} ref={buttonRef} onClick={() => playMusic()}>
                                        New Message Received
                                    </button>
                                    <PatientList
                                        // type={4}
                                        selectedAppointment={chatRoomId}
                                        patients={allRooms}
                                        showDetails={(item: any) => showDetails(item)}
                                    ></PatientList>
                                </Col>
                            </Row>
                        </>
                    )}
            </Col>
            <Col span={17}>
                {
                    chat === true &&
                    (
                        <>
                            {/* <Col span={24} className="white-background paddingLR20">
                            <PatientNameCard
                                type={"patient"}
                                details={patient}
                            ></PatientNameCard>
                        </Col> */}
                            <ChatLayout
                                allMessages={allMessages}
                                allData={allRooms}
                                isDrawer={false}
                                show={chat}
                                // details={patient}
                                type={"agent"}
                                source={source}
                                loginUser={"agent"}
                                chat_room_id={chatRoomId}
                                patientMobile={patientMobile}
                                setChat={(value: any) => setChat(value)}
                            ></ChatLayout>
                        </>
                    )}
            </Col>
        </Row>
    );
};

export default Chat;
