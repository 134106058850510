import { LoadingOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type Props = {
    modalVisible: boolean,
    message?: string
}
function SpinLoadingLayout({ modalVisible = false, message = 'Loading...' }: Props) {
    return (
        <Modal
            closable={false}
            maskClosable={false}
            centered
            visible={modalVisible}
            footer={false} style={{ paddingBottom: '0px' }}
        >
            {/* <Row>
                <Col className="text-center">
                    <Spin size="large" indicator={antIcon} />
                    <h4>{message}</h4>
                </Col>
            </Row> */}
            <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                    {
                        <LoadingOutlined style={{ fontSize: '64px', color: '#37C2D8' }} />
                    }
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <p className='center headColor font20 '>{message}</p>
                </Col>
            </Row>
        </Modal>
    )
}
export default SpinLoadingLayout;