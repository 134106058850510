import { Modal } from "antd";
import FormBar from "../../../../../shared/layouts/formbar.layout";

type Props = {
  actionType: string;
  editData: any;
  modalVisible: boolean;
  setmodalVisible: any;
};
function CreateAudience({
  actionType,
  editData,
  modalVisible,
  setmodalVisible,
}: Props) {
  const onSaveEditAudience = (values: any) => {};
  const onSaveAudience = (values: any) => {};
  const formElements = {
    onFinish: actionType === "edit" ? onSaveEditAudience : onSaveAudience,
    onFinishFailed: null,
    initialValues: {},
    buttonText: "Save",
    formElements: [
      {
        label: "Facebook Ad Account",
        type: "Select",
        name: "ad_account_id",
        rules: [{ required: true, message: "Please select Ad Account Id" }],
        colSpan: 12,
        options: [
          {
            name: "Prashant",
            value: "489854236542",
          },
        ],
        disabled:
          actionType === "edit" && editData?.first_name !== null ? true : false,
      },
      {
        label: "Audience Name",
        type: "Input",
        name: "audience_name",
        rules: [{ required: true, message: "Please input Audience Name!" }],
        colSpan: 12,
      },
      {
        label: "Description",
        type: "TextArea",
        name: "audience_description",
        rules: [{ required: true, message: "Please input Audience Name!" }],
        colSpan: 24,
      },
    ],
  };
  return (
    <>
      <Modal
        title={actionType === "edit" ? "Edit Audience" : "Create Audience"}
        centered
        visible={modalVisible}
        onCancel={() => setmodalVisible(false)}
        width={1000}
        footer={false}
        className="modalFormButton form-modal"
        style={{ paddingBottom: "0px" }}
      >
        <FormBar
          actionType={actionType}
          name={"createAudience"}
          // loading={}
          {...formElements}
        ></FormBar>
      </Modal>
    </>
  );
}
export default CreateAudience;
