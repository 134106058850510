/* eslint-disable jsx-a11y/anchor-is-valid */
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  InputNumber,
  Modal,
  Row,
  Select,
  Tag,
  Typography,
  notification
} from "antd";
import moment from "moment/moment";
import { toFixed } from "rc-input-number/es/utils/MiniDecimal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addNewMedicine,
  deleteMedicineById,
  fetchMedicinePriceInventory,
  fetchMedicinesById,
  fetchRxMasterList,
  fetchStationsList,
  fetchSubstitutes,
  updateMedicineItem
} from "../../redux/reducers/pharmacy.slice";
import { RootState } from "../../shared/constants";

type Props = {
  medicineTotalPrice?: any;
  medicineUsedComponent?: any;
  newOrderAuditTrail?: any;
  medicineArrCallBack?: any;
  requestId?: string;
  makeMedicineComponentEditable?: any;
  deliveryAddress?: string;
  getMedicineTotalQuantity?: any;
  addedMedicines?: any;
};
function MedicineComponent(props: Props) {
  const dispatch = useDispatch();
  const {
    medicine,
    medicines,
    rxList,
    pharmacyDetails,
    stationNameFromAddress,
    substitutes,
    loading,
  } = useSelector((state: RootState) => state.pharmacy);
  const { Text } = Typography;
  const { Option } = Select;
  const orderStatus = pharmacyDetails.status;
  // const stationId = pharmacyDetails.station_id;
  const [medicineArr, setMedicineArr] = useState(medicine);
  const [showQuantity, setshowQuantity] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null as any);
  const [stationNameFromAddressArr, setStationNameFromAddressArr] = useState(
    stationNameFromAddress
  );
  let [getItemTotalQuantity, setItemTotalQuantity] = useState([] as any);

  useEffect(() => {
    let clear = 0;
    if (props?.deliveryAddress === null) {
      setStationNameFromAddressArr([]);
      clear = 1;
    } else {
      if (
        deliveryAddress !== props.deliveryAddress &&
        deliveryAddress !== null &&
        deliveryAddress !== false
      ) {
        clear = 1;
      }
      setDeliveryAddress(props.deliveryAddress);
    }
    if (clear === 1) {
      if (medicineArr?.length > 0) {
        for (let k = 0; k < medicineArr.length; ++k) {
          let medPayload = medicinePayload(medicineArr[k]);
          if (medPayload.station_id !== null) {
            medPayload.station_id = null;
            medPayload.station_id = null;
            dispatch(
              updateMedicineItem({ id: medPayload.item_id, data: medPayload })
            );
          }
        }
        setMedicineArr((prevState: any) => {
          return prevState.map((item: any) => {
            return { ...item, station_id: null, station: null };
          });
        });
      }
    }
  }, [props?.deliveryAddress]);
  useEffect(() => {
    if (stationNameFromAddress) {
      setStationNameFromAddressArr(stationNameFromAddress);
    }
  }, [stationNameFromAddress]);

  const [isDeleteMediModalVisible, setIsDeleteMediModalVisible] =
    useState(false);
  const [deletedMedicineData, setDeletedMedicineData] = useState({
    item_name: "",
  });

  // const addQuantity = (data: any) => {
  //   setMedicineArr( (prevState:any) => {
  //     return prevState.map( (item: any) => {
  //       if (item.id === data.id) {
  //         addItem(item)
  //         return { ...item, item_quantity: item.item_quantity+1 }
  //       } else{
  //         return item;
  //       }
  //     });
  //   })
  // }

  // const removeQuantity = (data: any) => {
  //   setMedicineArr( (prevState:any) => {
  //     return prevState.map( (item: any) => {
  //       if (item.item_quantity > 0 && item.id === data.id) {
  //         removeItem(item)
  //         return { ...item, item_quantity: item.item_quantity-1 }
  //       } else{
  //         return item;
  //       }
  //     });
  //   })
  // }

  // const addItem = async(data: any) => {
  //   let medPayload = medicinePayload(data);
  //   medPayload.item_quantity = data.item_quantity+1;
  //   await dispatch(updateMedicineItem( { id: medPayload.item_id, data: medPayload }))
  // }

  // const removeItem = async (data: any) => {
  //   let medPayload = medicinePayload(data);
  //   medPayload.item_quantity = data.item_quantity-1;
  //   await dispatch(updateMedicineItem( { id: medPayload.item_id, data: medPayload }))
  // }

  useEffect(() => {
    dispatch(fetchStationsList());
    dispatch(fetchRxMasterList());
  }, []);

  useEffect(() => {
    processMedicine(medicine);
  }, [medicine]);

  const processMedicine = async (medicine: any) => {
    let medicineArr = JSON.parse(JSON.stringify(medicine));
    let totalMedicineCount: any = []
    for (let k = 0; k < medicine.length; ++k) {
      if (medicine[k].station_id) {
        let dataCount = await dispatch(
          fetchMedicinePriceInventory({
            item_id: medicine[k].item_code,
            station_id: medicine[k].station_id,
          })
        );
        if (dataCount?.payload?.data?.data?.length > 0) {
          medicineArr[k] = {
            ...medicine[k],
            station_code: medicine[k].station.code,
            total_remain_quantity:
              dataCount.payload.data.data[0].total_quantity,
          };
        }
      }
    }
    medicineArr.forEach((medicine: any) => {
      if (medicine?.total_remain_quantity) {
        totalMedicineCount.push({
          item_quantity: medicine?.total_remain_quantity,
          item_id: medicine?.item_id,
        });
      }
    })

    setItemTotalQuantity(totalMedicineCount);
    if (typeof props?.getMedicineTotalQuantity === 'function') {
      props?.getMedicineTotalQuantity(totalMedicineCount);
    }
    if (typeof props?.addedMedicines === 'function') {
      props?.addedMedicines(medicineArr);
    }
    setMedicineArr(medicineArr);
    setshowQuantity(true);
  };

  useEffect(() => {
    // console.log('eeeee 4444',medicineArr)
    getTotalMedicineCost(medicineArr);
    if (props && props?.medicineArrCallBack) {
      props?.medicineArrCallBack(medicineArr);
    }
    if (props && props?.addedMedicines) {
      props?.addedMedicines(medicineArr);
    }
  }, [medicineArr]);

  const showDeleteMediModal = (data: any) => {
    // console.log('eeeee 3334444', data);
    let payload = {
      item_id: data.id,
      item_name: data.item_name,
      item_quantity: data.item_quantity,
      station_id: pharmacyDetails.station_id,
      hospital_id: pharmacyDetails.hospital_id,
      item_price: data.item_price,
      request_id: data.request_id,
      request_date: data.request_date,
      item_code: data.item_code.toString(),
    };
    setIsDeleteMediModalVisible(true);
    setDeletedMedicineData(payload);
  };

  const [isSubstituteModalVisible, setIsSubstituteModalVisible] =
    useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState({} as any);
  const [disabledReplace, setDisabledReplace] = useState(false);
  const [substituteArr, setSubstituteArr] = useState(substitutes);
  const [viewMore, setViewMore] = useState(false);
  const openSubstituteModal = async (data: any) => {
    if (Object.keys(selectedMedicine).length) return;
    const stationName = stationNameFromAddress.stations.filter((station: any) => station?.id == data?.station_id)?.[0]?.name
    setSelectedMedicine({ ...data, station_name: stationName });
    const item_code = data.item_code;
    const station_code = data.station_code || data.station.code;
    let response = await dispatch(fetchSubstitutes({ item_code, station_code }));
    if (response?.payload?.data?.error === false) {
      if (response?.payload?.data?.data.length) {
        setIsSubstituteModalVisible(true);
      } else {
        setSelectedMedicine({});
        notification.error({
          message: 'No any substitute item found.'
        });
      }
    } else {
      setSelectedMedicine({});
    }
  };
  const checkIfMedicineIsSelected = (item: any) => {
    const filteredArr = medicineArr.filter((medicine: any) => item.id === medicine.item_id)
    return !!filteredArr?.length;
  }
  const handleReplaceClick = async (item: any) => {
    setDisabledReplace(true);
    let data = Object.assign({}, selectedMedicine);
    let payload = {
      item_id: data.id,
      item_name: data.item_name,
      item_quantity: data.item_quantity,
      station_id: pharmacyDetails.station_id,
      hospital_id: pharmacyDetails.hospital_id,
      item_price: data.item_price,
      request_id: data.request_id,
      request_date: data.request_date,
      item_code: data.item_code.toString(),
    };
    await dispatch(deleteMedicineById(payload));
    await dispatch(fetchMedicinesById(props.requestId ? props.requestId : RouteParams["id"]));
    const isSelected = checkIfMedicineIsSelected(item);
    if (!isSelected) {
      await getSelectedMedicine(item);
    }
    setIsSubstituteModalVisible(false);
    setSelectedMedicine({});
    if (item.total_quantity < selectedMedicine.item_quantity) {
      notification.error({
        message: "quantity is more than total quantity.",
      });
    }
    setDisabledReplace(false);
    if (isSelected) {
      notification.warning({ message: 'Substitutes is already selected in the order.', duration: 8 })
    } else {
      notification.success({
        message: 'Medicine Replaced',
        placement: 'bottom',
        icon: <></>,
        className: 'medicine-replaced',
        closeIcon: <></>,
      });
    }
  };

  const handleViewMore = async () => {
    setViewMore(!viewMore);
  }
  useEffect(() => {
    if (viewMore) {
      setSubstituteArr(substitutes);
    } else {
      setSubstituteArr(substitutes.slice(0, 5));
    }
  }, [substitutes, viewMore]);

  const getSelectedMedicine = async (item: any) => {
    const request_id = props.requestId ? props.requestId : RouteParams["id"];
    let finalPayload = {
      item_id: item.id,
      item_code: item.item_id,
      item_name: item.item_name,
      item_quantity: Math.min(selectedMedicine?.item_quantity, item?.total_quantity) || 1,
      station_id: selectedMedicine?.station_id,
      hospital_id: pharmacyDetails?.hospital_id,
      item_price: item.item_price,
      request_id: request_id,
      prescription_required: false,
      is_otc: item?.is_prescription === 0,
      request_date: moment().format("YYYY-MM-DD HH:MM:ss"),
      remarks: item?.total_quantity.toString(),
    };
    try {
      await dispatch(addNewMedicine(finalPayload));
      await dispatch(fetchMedicinesById(request_id));
      return;
    } catch (e) {
      console.error("something went wrong", e);
    }
  };

  const handleSubstituteCancel = (e: React.MouseEvent<HTMLElement>) => {
    setIsSubstituteModalVisible(false);
    setSelectedMedicine({});
  };

  const RouteParams: any = useParams();

  const handleDeleteMedicine = async () => {
    await dispatch(deleteMedicineById(deletedMedicineData));
    await dispatch(
      fetchMedicinesById(props.requestId ? props.requestId : RouteParams["id"])
    );
    setIsDeleteMediModalVisible(false);
    setDeletedMedicineData({ item_name: "" });
  };

  const handleDeleteMediCancel = () => {
    setIsDeleteMediModalVisible(false);
    setDeletedMedicineData({ item_name: "" });
  };

  const getTotalMedicineCost = (medi: any) => {
    let totalPrice = 0;
    if (medi && medi.length > 0) {
      medi.forEach((i: any) => {
        totalPrice += i.item_price * i.item_quantity;
      });
      let medicineDetails = {
        totalPrice: totalPrice,
        medicineCount: medi.length,
      };
      props.medicineTotalPrice(medicineDetails);
    } else {
      let medicineDetails = {
        totalPrice: 0,
        medicineCount: 0,
      };
      props.medicineTotalPrice(medicineDetails);
    }
  };

  const medicinePayload = (data: any) => {
    let item = {
      request_id: data.request_id,
      item_id: data.id,
      item_code: data.item_code.toString(),
      item_name: data.item_name,
      item_price: data.item_price,
      item_quantity: data.item_quantity,
      request_date: data.request_date,
      station_id: data.station_id,
      hospital_id: data.hospital_id || null,
      status: data.status,
      prescription_required: data.prescription_required
        ? data.prescription_required
        : false,
      rx_info: data.rx_info,
      appointment_id: pharmacyDetails.appointment_id,
    };
    return item;
  };

  const handlePrescriptionValue = (val: any, data: any) => {
    setMedicineArr((prevState: any) => {
      return prevState.map((item: any) => {
        if (item.id === data.id && val === "yes") {
          let medPayload = medicinePayload(data);
          medPayload.prescription_required = true;
          dispatch(
            updateMedicineItem({ id: medPayload.item_id, data: medPayload })
          );
          return { ...item, prescription_required: true };
        } else if (item.id === data.id && val === "no") {
          let medPayload = medicinePayload(data);
          medPayload.prescription_required = false;
          dispatch(
            updateMedicineItem({ id: medPayload.item_id, data: medPayload })
          );
          return { ...item, prescription_required: false };
        } else {
          return item;
        }
      });
    });
  };

  const handleRxValue = (val: any, data: any) => {
    setMedicineArr((prevState: any) => {
      return prevState.map((item: any) => {
        if (item.id === data.id) {
          let medPayload = medicinePayload(data);
          medPayload.rx_info = val;
          dispatch(
            updateMedicineItem({ id: medPayload.item_id, data: medPayload })
          );
          return { ...item, rx_info: val };
        } else {
          return item;
        }
      });
    });
  };

  const handlePharmacy = async (evt: any, data: any) => {
    let medPayload = medicinePayload(data);
    medPayload.station_id = evt;
    // const prevMed = medicineArr.slice();

    const ret = await handleInventry(data, evt, {
      id: data.id,
      data: medPayload,
    });
    if (ret?.return) {
      setMedicineArr((prevState: any) => {
        return prevState.map((item: any) => {
          if (item.id === data.id) {
            if (ret?.item_quantity < item.item_quantity) {
              notification.error({
                message: "quantity is more than total quantity",
              });
            }

            return {
              ...item,
              station_id: evt,
              total_remain_quantity: ret?.item_quantity,
              station_code: ret?.station_code,
            };
          } else {
            return item;
          }
        });
      });
    } else {
      setMedicineArr((prevState: any) => {
        return prevState.map((item: any) => {
          if (item.id === data.id) {
            let stationCode = stationNameFromAddress.stations.filter(
              (item: any) => item?.id == evt
            );
            stationCode = stationCode.length ? stationCode[0].code : undefined;
            return {
              ...item,
              station_id: evt,
              total_remain_quantity: 0,
              station_code: stationCode,
            };
          } else {
            return item;
          }
        });
      });
    }
  };


  const handleInventry = async (
    data: any,
    station_id: any,
    updatePayload: any
  ) => {
    let res = await dispatch(
      fetchMedicinePriceInventory({ item_id: data.item_code, station_id })
    ); //station_id: data?.station?.code
    if (res?.payload?.data?.statusCode === 200) {
      calculatePrice(res?.payload?.data?.data, data?.id);
      // console.log('eeeeee 111111',  updatePayload)
      updatePayload["data"]["remarks"] =
        res?.payload?.data?.data[0]?.total_quantity.toString();
      dispatch(updateMedicineItem(updatePayload));
      let selectedItem: any = {
        item_quantity: res?.payload?.data?.data[0]?.total_quantity,
        item_id: res?.payload?.data?.data[0]?.item_id,
      };
      // console.log('eeeeee 22222',  selectedItem)
      getItemTotalQuantity.push(selectedItem);
      setItemTotalQuantity(getItemTotalQuantity);
      // console.log('eeeeee 22222',  getItemTotalQuantity)
      props?.getMedicineTotalQuantity(getItemTotalQuantity);
      props?.addedMedicines(medicineArr);
      let resp = {
        item_quantity: res?.payload?.data?.data[0]?.total_quantity,
        station_code: res?.payload?.data?.data[0]?.station_id,
        return: true,
      };
      return resp;
    } else if (res?.payload?.data?.statusCode === 400) {
      let resp = {
        item_quantity: res?.payload?.data?.data[0]?.total_quantity,
        station_code: res?.payload?.data?.data[0]?.station_id,
        return: true,
      };
      return resp;
    } else {
      let resp = {
        item_quantity: res?.payload?.data?.data[0]?.total_quantity,
        station_code: res?.payload?.data?.data[0]?.station_id,
        return: false,
      };
      return resp;
    }
  };

  const calculatePrice = (inventoryArr: any, itemId: string) => {
    if (inventoryArr && inventoryArr.length > 0) {
      setMedicineArr(
        medicineArr.map((item: any) =>
          item.id === itemId
            ? { ...item, item_price: inventoryArr[0]?.MRP }
            : item
        )
      );
    }
  };

  function handleMedQuantity(value: any, data: any) {
    value = Math.floor(value);
    if (value) {
      setMedicineArr((prevState: any) => {
        return prevState.map((item: any) => {
          // console.log('eeee item', item);
          if (
            data?.item_code == item?.item_code &&
            value > item?.total_remain_quantity
          ) {
            notification.error({
              message: "quantity is more than total quantity",
            });
            medicineCal(item, value);
            props?.getMedicineTotalQuantity(getItemTotalQuantity);
            return { ...item, item_quantity: value };
          } else {
            if (item.id === data.id) {
              medicineCal(item, value);
              props?.getMedicineTotalQuantity(getItemTotalQuantity);
              return { ...item, item_quantity: value };
            } else {
              return item;
            }
          }
        });
      });
    }
  }

  const medicineCal = async (data: any, quantity: any) => {
    let medPayload = medicinePayload(data);
    medPayload.item_quantity = quantity;
    await dispatch(
      updateMedicineItem({ id: medPayload.item_id, data: medPayload })
    );
  };

  const formatter = (value: any) => {
    return toFixed(value, ".", 0);
  };

  return (
    <>
      {medicineArr?.length > 0 && showQuantity ? (
        <Row className="full-width pdl10 pdr10">
          <table
            className=""
            style={{
              fontSize: "11px!important",
              width: "100%",
              borderSpacing: "10px",
              borderCollapse: "separate",
            }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Medicine Name</th>
                <th style={{ textAlign: "left" }}>Qty/Price</th>
                {/* <th>As Per Prescription?</th>
                <th>Pharmacy</th> */}
              </tr>
            </thead>
            <tbody>
              {medicineArr?.map((indRow: any, index: any) => (
                <>
                  <tr key={index}>
                    <td
                      style={{
                        textTransform: "uppercase",
                        fontSize: "12px",
                        position: "relative",
                        width: "300px",
                      }}
                    >
                      {index + 1}. {indRow?.item_name ? indRow?.item_name : "-"}
                      <br />
                      <span
                        className="sub-title cursor"
                        style={{
                          top: "28px",
                          left: "10px",
                          fontSize: "10px",
                          textTransform: "capitalize",
                        }}
                      >
                        {indRow?.manufacturerName}
                        {indRow?.is_otc === false ? (
                          <Tag
                            className="solid"
                            color="blue"
                            style={{ marginLeft: "10px", fontSize: "10px" }}
                          >
                            Rx Required
                          </Tag>
                        ) : (
                          ""
                        )}
                      </span>
                    </td>

                    {indRow?.station_id ? (
                      indRow.total_remain_quantity ? (
                        <td>
                          {orderStatus === "pending" ||
                            orderStatus === "new" ||
                            (orderStatus === "open" &&
                              props?.newOrderAuditTrail !==
                              "newOrderAuditTrail") ||
                            (orderStatus === "qc_done" &&
                              props?.makeMedicineComponentEditable ===
                              "handleEditMedicine") ? (
                            <InputNumber
                              formatter={formatter}
                              min={1}
                              value={indRow.item_quantity}
                              defaultValue={indRow.item_quantity || 1}
                              style={{ width: "70px" }}
                              onChange={(e) => {
                                handleMedQuantity(e, indRow);
                              }}
                            />
                          ) : (
                            <InputNumber
                              min={1}
                              disabled={true}
                              defaultValue={indRow.item_quantity || 1}
                              style={{ width: "70px" }}
                            />
                          )}

                          <span
                            style={{
                              padding: "0 15px",
                              position: "relative",
                              display: "inline-block",
                              height: "30px",
                            }}
                          >
                            Rs.{" "}
                            {(
                              indRow?.item_price * indRow?.item_quantity
                            ).toFixed(2)}
                            <span
                              className="sub-title"
                              style={{
                                position: "absolute",
                                top: "18px",
                                left: "13px",
                                fontSize: "10px",
                              }}
                            >
                              (Rs. {indRow?.item_price?.toFixed(2)} *{" "}
                              {indRow?.item_quantity})
                            </span>
                          </span>
                          {orderStatus === "pending" ||
                            orderStatus === "new" ||
                            (orderStatus === "open" &&
                              props?.newOrderAuditTrail !==
                              "newOrderAuditTrail") ||
                            (orderStatus === "qc_done" &&
                              props?.makeMedicineComponentEditable ===
                              "handleEditMedicine") ? (
                            <span className="sub-title cursor">
                              <DeleteFilled
                                onClick={() => {
                                  showDeleteMediModal(indRow);
                                }}
                              />
                            </span>
                          ) : (
                            ""
                          )}
                          <br />
                          {orderStatus === "pending" ||
                            orderStatus === "new" ||
                            (orderStatus === "open" &&
                              props?.newOrderAuditTrail !==
                              "newOrderAuditTrail") ||
                            (orderStatus === "qc_done" &&
                              props?.makeMedicineComponentEditable ===
                              "handleEditMedicine") ? (
                            <span>
                              <b>Total Quantity:</b>{" "}
                              {indRow?.total_remain_quantity || indRow.remarks}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        <td>
                          <small className="bold-600 text-danger">
                            Select Substitutes from Pharmacy
                          </small>
                          <span className="sub-title cursor">
                            <DeleteFilled
                              onClick={() => {
                                showDeleteMediModal(indRow);
                              }}
                            />
                          </span>
                        </td>
                      )
                    ) : (
                      <td>
                        <small className="bold-600 text-danger">
                          Select Pharmacy Station
                        </small>
                        <span className="sub-title cursor">
                          <DeleteFilled
                            onClick={() => {
                              showDeleteMediModal(indRow);
                            }}
                          />
                        </span>
                      </td>
                    )}
                  </tr>
                  {(props?.medicineUsedComponent !== "placeOrdeModal" &&
                    props?.newOrderAuditTrail !== "newOrderAuditTrail") ||
                    (orderStatus === "qc_done" &&
                      props?.makeMedicineComponentEditable ===
                      "handleEditMedicine") ? (
                    <>
                      {indRow?.is_otc === false && (
                        <tr>
                          <Text
                            className=""
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            Is New Prescription Required?
                          </Text>
                          <td>
                            {orderStatus === "pending" ||
                              orderStatus === "new" ||
                              orderStatus === "open" ||
                              (orderStatus === "qc_done" &&
                                props?.makeMedicineComponentEditable ===
                                "handleEditMedicine") ? (
                              <Select
                                disabled={!indRow.station_id}
                                style={{ maxWidth: "70px" }}
                                onChange={(evt: any) =>
                                  handlePrescriptionValue(evt, indRow)
                                }
                                defaultValue={
                                  indRow?.prescription_required === false
                                    ? "no"
                                    : "yes"
                                }
                                value={
                                  indRow?.prescription_required === false
                                    ? "no"
                                    : "yes"
                                }
                                placeholder=""
                              >
                                <Option value="no">No</Option>
                                <Option value="yes">Yes</Option>
                              </Select>
                            ) : (
                              <Text>
                                {indRow?.prescription_required === false
                                  ? "No"
                                  : "Yes"}
                              </Text>
                            )}
                            {orderStatus === "pending" ||
                              orderStatus === "new" ||
                              orderStatus === "open" ||
                              (orderStatus === "qc_done" &&
                                props?.makeMedicineComponentEditable ===
                                "handleEditMedicine") ? (
                              <>
                                {indRow?.prescription_required === true ? (
                                  <Select
                                    disabled={!indRow.station_id}
                                    style={{
                                      paddingLeft: "10px",
                                      maxWidth: "200px",
                                    }}
                                    defaultValue={indRow?.rx_info}
                                    onChange={(evt: any) =>
                                      handleRxValue(evt, indRow)
                                    }
                                    placeholder="Select Option"
                                  >
                                    {rxList?.map((ind: any, index: any) => (
                                      <Option value={ind?.name}>
                                        {ind?.name}
                                      </Option>
                                    ))}
                                  </Select>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <Text>
                                {indRow?.prescription_required === false
                                  ? ""
                                  : indRow?.rx_info
                                    ? " (" + indRow?.rx_info + ")"
                                    : ""}
                              </Text>
                            )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <Text
                          className=""
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          Pharmacy
                        </Text>
                        <td>
                          {orderStatus === "pending" ||
                            orderStatus === "open" ||
                            orderStatus === "new" ||
                            (orderStatus === "qc_done" &&
                              props?.makeMedicineComponentEditable ===
                              "handleEditMedicine") ? (
                            <>
                              <Select
                                onChange={(evt: any) =>
                                  handlePharmacy(evt, indRow)
                                }
                                value={indRow?.station_id}
                                placeholder="Select Option"
                                style={{ width: "200px" }}
                              >
                                {stationNameFromAddressArr?.stations_with_distance &&
                                  stationNameFromAddressArr
                                    ?.stations_with_distance.length > 0 &&
                                  stationNameFromAddressArr?.stations_with_distance?.map(
                                    (indRow: any, index: any) => (
                                      <Option
                                        key={indRow?.station_id}
                                        value={indRow?.station_id}
                                      >
                                        {indRow?.station_name} (
                                        {indRow?.distance}
                                        {"km"})
                                      </Option>
                                    )
                                  )}
                              </Select>
                              <br />
                              {indRow.station_id ? (
                                <a
                                  className={"link-button"}
                                  style={{ textDecoration: "underline" }}
                                  onClick={() => {
                                    openSubstituteModal(indRow);
                                  }}
                                >
                                  Show Medicine Substitutes
                                </a>
                              ) : null}
                            </>
                          ) : (
                            <Text>{indRow?.station?.name}</Text>
                          )}
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td colSpan={2}>
                      <Divider className="mt10 mb-10" />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </Row>
      ) : medicine.length > 0 ? null : (
        <>
          <Row className="sub-title full-width">
            <Col span={9}> </Col>
            <Col span={13}>
              {" "}
              <SearchOutlined style={{ fontSize: "70px" }} />{" "}
            </Col>
          </Row>
          <Row className="sub-title full-width">
            <Col span={8}> </Col>
            <Col span={14}>
              {" "}
              <Text type="secondary" className="bold sub-text">
                No medicines added.
              </Text>
            </Col>
          </Row>
        </>
      )}
      <Modal
        title={deletedMedicineData.item_name}
        visible={isDeleteMediModalVisible}
        onOk={handleDeleteMedicine}
        onCancel={handleDeleteMediCancel}
      >
        Above mentioned medicine will be removed. Do you want to proceed?
      </Modal>

      <Modal
        className="substituteModal"
        visible={isSubstituteModalVisible}
        centered
        width={1000}
        onCancel={handleSubstituteCancel}
        title={[
          <h3 style={{ color: "black", marginBottom: "5px" }}>
            Medicine Substitutes
          </h3>,
          <div className={"substitute-name"}>
            {selectedMedicine?.item_name}
          </div>,
        ]}
        footer={
          substitutes.length <= 5 ? null : <div className={"text-center"}>
            {
              viewMore ?
                <a className={"link-button"} style={{ textDecoration: "underline" }} onClick={handleViewMore}>View Less Medicines</a>
                :
                <a className={"link-button"} style={{ textDecoration: "underline" }} onClick={handleViewMore}>View More Medicines</a>
            }
          </div>
        }
        afterClose={() => handleSubstituteCancel}
      >
        <div className={"table-container"}>
          <table className={"full-width"}>
            <thead>
              <tr>
                <th>Medicine Name</th>
                <th>Available Qty at Station ({selectedMedicine?.station_name})</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {substituteArr.map((item: any, index: number) => {
                return (
                  <tr>
                    <td className="substitute-name">{index + 1}. {item.item_name}</td>
                    <td>{item.total_quantity}</td>
                    <td style={{ whiteSpace: "nowrap" }}>Rs. {item?.item_price?.toFixed(2)}</td>
                    <td className={"p-b-xs"} style={{ paddingLeft: "5px" }}>
                      <Button
                        type="primary"
                        ghost
                        block
                        disabled={disabledReplace}
                        onClick={async () => {
                          await handleReplaceClick(item);
                        }}
                      >
                        Replace
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
}

export default MedicineComponent;
