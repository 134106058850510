import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, PageHeader, Row, Space, Upload, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from "axios";
import { Fragment, useState } from 'react';
import { useDispatch } from "react-redux";
import { uploadDumpSeed } from '../../../redux/reducers/upload.slice';
import { ACCOUNT_PREFIX, BASE_API_URL } from "../../../shared/constants";

const SeedDataUpload = () => {
    const dispatch = useDispatch();
    const [form] = useForm();
    const [fileList, setFileList] = useState([] as any);
    const [downloadDisabled, setdownloadDisabled] = useState(false)
    const [downloadLoading, setdownloadLoading] = useState(false)

    const onRemoveFile = (file: any) => {
        const index = fileList.indexOf(file);
        const newFileList = [...fileList];
        newFileList.splice(index, 1);
        setFileList(newFileList);
    }

    const handleUpload = async (values: any) => {

        try {

            let formData = new FormData();
            formData.append('dumpCsv', fileList[0]?.originFileObj);
            const response = await dispatch(uploadDumpSeed(formData));
            if (response.ok) {
                message.success('Upload successful');
                setFileList([])
            } else {
                message.error('Upload failed');
            }
        } catch (error: any) {
            console.error('Error:', error);
            message.error(error?.message || 'Upload failed');
        }
    }

    const handleChangeFile = (info: any) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList(fileList);
    };

    console.log("fileList", fileList)

    const onBeforeUpload = (file: any) => {
        const maxSizeInBytes = 5 * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            message.warning("File size must be up to 5 MB", 4);
            return false;
        }

        if (fileList.length >= 1) {
            message.error('You can only upload one file at a time!');
            return false;
        }
        return false;
    }

    const downloadCSVss = () => {
        setdownloadDisabled(true)
        setdownloadLoading(true)
        const headers = {
            'Content-Type': 'application/json',
        }
        axios.post(`${BASE_API_URL}bootstrap/${ACCOUNT_PREFIX}/dump/xlsx`, {}, { responseType: 'blob', headers: headers })
            .then((response: any) => {
                if (response?.data) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `seed-data-${ACCOUNT_PREFIX}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    setdownloadDisabled(false)
                    setdownloadLoading(false)
                }
            })
            .catch((error: any) => {
                console.error('Error fetching file:', error);
                message.error(error?.message)
                setTimeout(() => {
                    setdownloadDisabled(false)
                    setdownloadLoading(false)
                }, 3000);
            });


    };

    return (
        <Fragment>
            <PageHeader ghost={false}
                title='Upload/Download Dump'
            >
            </PageHeader>
            <Row className='padding10' gutter={20}>
                <Col span={12}>
                    <Card className='custom-card'>
                        <Space direction="vertical" style={{ width: "100%", textAlign: "center" }}>
                            <Form
                                className="login-form"
                                form={form}
                                onFinish={handleUpload}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="dumpCsv"
                                // getValueFromEvent={(e) => {
                                //     if (Array.isArray(e)) {
                                //         return e;
                                //     }
                                //     return e && e.fileList;
                                // }}
                                >
                                    <Upload
                                        fileList={fileList}
                                        onRemove={onRemoveFile}
                                        onChange={handleChangeFile}
                                        accept=".csv, .xlsx"
                                        beforeUpload={onBeforeUpload}
                                        showUploadList={{ showDownloadIcon: false }}
                                    >
                                        <Button icon={<UploadOutlined />}>Select File</Button>
                                    </Upload>
                                    {/* <br /> */}
                                    <small>(Supported format: Xlsx/CSV upto 5MB.)</small>
                                </Form.Item>
                                <Button
                                    block
                                    type="primary"
                                    disabled={!fileList?.length}
                                    htmlType='submit'
                                >
                                    Upload File
                                </Button>
                            </Form>
                        </Space>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card className='custom-card'>
                        <Space direction="vertical" style={{ width: "100%", textAlign: "center" }}>
                            <Button loading={downloadLoading} type="primary" onClick={downloadCSVss} disabled={downloadDisabled} >Download file</Button>
                            {/* disabled={!csvData} */}
                        </Space>
                    </Card>
                </Col>
            </Row>

        </Fragment >
    );
}

export default SeedDataUpload;
