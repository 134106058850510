import {
  Card,
  Col,
  Divider,
  Pagination,
  Progress,
  Row,
  Skeleton,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as Agent } from "../../../../assets/icons/agent-dashboard.svg";
import { ReactComponent as Phone } from "../../../../assets/icons/phone-dashboard.svg";
import { fetchDashboardData } from "../../../../redux/reducers/dashboard.slice";
import {
  formatDropDownValue,
  secondsToHms,
} from "../../../../shared/Utils/utilities";
import {
  TASK_MODULE_ACTIONS_READ_CALL_LOGS,
  TASK_MODULE_ACTIONS_READ_LEAD,
} from "../../../../shared/components/permission.constants";

export default function LiveStatusTab() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openItems, setOpenItems] = useState(null as any);
  const [slaBreachedItems, setSlaBreachedItems] = useState(null as any);
  const [closedItems, setclosedItems] = useState(null as any);
  const [notClosedItems, setnotClosedItems] = useState(null as any);
  const [agentData, setAgentData] = useState(null as any);
  const [callData, setCallData] = useState(null as any);

  const [dataSetOLBA, setDataSetOLBA] = useState(null as any);
  const [dataSetOTBA, setDataSetOTBA] = useState(null as any);

  const [callDistribution, setCallDistribution] = useState(null as any);

  const [dataSetOpenByAgent, setDataSetOpenByAgent] = useState(null as any);
  const [dataSetOpenByAgentFooter, setDataSetOpenByAgentFooter] = useState(
    null as any
  );

  const [dataLeadCategories, setDataLeadCategories] = useState(null as any);
  const [dataLeadCategoriesFooter, setDataLeadCategoriesFooter] = useState(
    null as any
  );
  const [dataTicketCategories, setDataTicketCategories] = useState(null as any);
  const [dataTicketSource, setdataTicketSource] = useState([] as any);
  const [dataTicketSourceFooter, setdataTicketSourceFooter] = useState(
    null as any
  );
  const [dataTicketCategoriesFooter, setDataTicketCategoriesFooter] = useState(
    null as any
  );

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  useEffect(() => {
    getDashboardData();
  }, []);
  const getDashboardData = async () => {
    const overallData = await dispatch(
      fetchDashboardData({ query_type: "overall" })
    );
    if (overallData?.payload?.data?.data?.length > 0) {
      const overall = overallData?.payload?.data?.data;
      const leadData = overall?.filter((val: any) => {
        return val.mode === "lead";
      });
      const ticketData = overall?.filter((val: any) => {
        return val.mode === "ticket";
      });
      const agentData = overall?.filter((val: any) => {
        return val.mode === "agent";
      });
      const calllogData = overall?.filter((val: any) => {
        return val.mode === "call_log";
      });
      setOpenItems([
        ...(myPermissions?.indexOf("task_module") !== -1 ||
          myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1
          ? [
            {
              title: "Leads",
              count: leadData?.length > 0 ? leadData[0].open || 0 : 0,
            },
          ]
          : []),

        {
          title: `${t("CRM.Tickets.Fields.Tickets")}`,
          count: ticketData?.length > 0 ? ticketData[0].open || 0 : 0,
        },
      ]);
      setSlaBreachedItems([
        ...(myPermissions?.indexOf("task_module") !== -1 ||
          myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1
          ? [
            {
              title: "Leads",
              count: leadData?.length > 0 ? leadData[0].sla_breached || 0 : 0,
            },
          ]
          : []),

        {
          title: `${t("CRM.Tickets.Fields.Tickets")}`,
          count: ticketData?.length > 0 ? ticketData[0].sla_breached || 0 : 0,
        },
      ]);

      setclosedItems([
        ...(myPermissions?.indexOf("task_module") !== -1 ||
          myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1
          ? [
            {
              title: "Leads",
              count: leadData?.length > 0 ? leadData[0].closed || 0 : 0,
            },
          ]
          : []),

        {
          title: `${t("CRM.Tickets.Fields.Tickets")}`,
          count: ticketData?.length > 0 ? ticketData[0].closed || 0 : 0,
        },
      ]);

      setnotClosedItems([
        ...(myPermissions?.indexOf("task_module") !== -1 ||
          myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1
          ? [
            {
              title: "Leads",
              count: leadData?.length > 0 ? leadData[0].not_closed || 0 : 0,
            },
          ]
          : []),

        {
          title: `${t("CRM.Tickets.Fields.Tickets")}`,
          count: ticketData?.length > 0 ? ticketData[0].not_closed || 0 : 0,
        },
      ]);

      setAgentData([
        {
          title: "Live Agents",
          count: agentData?.length > 0 ? agentData[0].live || 0 : 0,
        },
        {
          title: "Paused Agents",
          count: agentData?.length > 0 ? agentData[0].paused || 0 : 0,
        },
      ]);
      setCallData([
        {
          title: "Today's Incoming Calls",
          count: calllogData?.length > 0 ? calllogData[0].incoming || 0 : 0,
        },
        {
          title: "Today's Outgoing Calls",
          count: calllogData?.length > 0 ? calllogData[0].outgoing || 0 : 0,
        },
        {
          title: "Today's Missed Calls",
          count: calllogData?.length > 0 ? calllogData[0].missed || 0 : 0,
        },
      ]);
    }
    const byAgeingData = await dispatch(
      fetchDashboardData({ query_type: "by_ageing" })
    );
    if (byAgeingData?.payload?.data?.data?.length > 0) {
      const byAgeing = byAgeingData?.payload?.data?.data;
      const leadData1 = byAgeing?.filter((val: any) => {
        return val.mode === "lead";
      });
      setDataSetOLBA(
        leadData1.map((val: any) => {
          return [
            formatDropDownValue(val.status),
            val.total,
            val.less_than_2_hrs,
            val.from_2_to_4_hrs,
            val.greater_than_4_hrs,
            val.sla_breached,
          ];
        })
      );

      const ticketData1 = byAgeing?.filter((val: any) => {
        return val.mode === "ticket";
      });
      setDataSetOTBA(
        ticketData1.map((val: any) => {
          return [
            formatDropDownValue(val.status),
            val.total,
            val.less_than_2_hrs,
            val.from_2_to_4_hrs,
            val.greater_than_4_hrs,
            val.sla_breached,
          ];
        })
      );
    }
    else {
      setDataSetOLBA([])
      setDataSetOTBA([])
    }
    const callDistributionData = await dispatch(
      fetchDashboardData({ query_type: "incoming_call_distribution" })
    );
    if (callDistributionData?.payload?.data?.data?.length > 0) {
      const callDistData = callDistributionData?.payload?.data?.data[0];
      setCallDistribution([
        {
          title: "Total Incoming",
          count: callDistData?.total_incoming || 0,
          color: "#37C2D8",
        },
        {
          title: "Contact Linked",
          count: callDistData?.contact_linked || 0,
          color: "#37CB95",
        },
        {
          title: "Leads Linked",
          count: callDistData?.leads_linked || 0,
          color: "#EB872A",
        },
        {
          title: "Tickets Linked",
          count: callDistData?.tickets_linked || 0,
          color: "#EBC62A",
        },
        {
          title: "Ticket/Lead Not Linked (Undisposed Calls)",
          count: callDistData?.not_linked || 0,
          color: "#E1454E",
        },
        {
          title: "Ticket Closed",
          count: callDistData?.ticket_closed || 0,
          color: "#3780D8",
        },
        {
          title: "Lead Converted",
          count: callDistData?.lead_converted || 0,
          color: "#9E37D8",
        },
      ]);
    }
    const dataByAgent = await dispatch(
      fetchDashboardData({ query_type: "by_agents" })
    );
    if (dataByAgent?.payload?.data?.data?.length > 0) {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total"];

      if (
        myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1
      ) {
        footerData.push(0);
      }

      footerData.push(0, 0, "--");
      dataByAgent?.payload?.data?.data?.forEach((val: any) => {
        const openLeadsColumn =
          myPermissions?.indexOf("task_module") !== -1 ||
            myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1
            ? val.open_leads
            : null;
        // debugger

        if (openLeadsColumn) {
          allRows.push([
            val.agent_name,
            openLeadsColumn,
            val.open_tickets,
            val.sla_breached,
            val.status === "live" ? (
              <span className="text-primary">Active</span>
            ) : (
              <span className="text-warning">Inactive</span>
            ),
          ]);
        } else {
          allRows.push([
            val.agent_name,
            val.open_tickets,
            val.sla_breached,
            val.status === "live" ? (
              <span className="text-primary">Active</span>
            ) : (
              <span className="text-warning">Inactive</span>
            ),
          ]);
        }
        if (openLeadsColumn !== null) {
          footerData[1] += parseInt(openLeadsColumn);
          footerData[2] += parseInt(val.open_tickets);
          footerData[3] += parseInt(val.sla_breached);
        } else {
          footerData[1] += parseInt(val.open_tickets);
          footerData[2] += parseInt(val.sla_breached);
        }
      });
      console.log("eeeeeeeee", allRows);
      setDataSetOpenByAgent(allRows);
      setDataSetOpenByAgentFooter(footerData);
    }

    const dataByLeadCat = await dispatch(
      fetchDashboardData({ query_type: "lead_categories" })
    );
    if (dataByLeadCat?.payload?.data?.data?.length > 0) {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0, 0, 0, 0, 0];
      dataByLeadCat?.payload?.data?.data?.forEach((val: any) => {
        allRows.push([
          formatDropDownValue(val.display_name),
          val.open,
          val.less_than_2_hrs,
          val.from_2_to_4_hrs,
          val.greater_than_4_hrs,
          val.sla_breached,
          val.today_new_leads,
          val.today_converted || 0,
          secondsToHms(val.today_avg_closing_time || 0),
        ]);
        footerData[1] += parseInt(val.open);
        footerData[2] += parseInt(val.less_than_2_hrs);
        footerData[3] += parseInt(val.from_2_to_4_hrs);
        footerData[4] += parseInt(val.greater_than_4_hrs);
        footerData[5] += parseInt(val.sla_breached);
        footerData[6] += parseInt(val.today_new_leads);
        footerData[7] += parseInt(val.today_converted || 0);
        footerData[8] += parseInt(val.today_avg_closing_time || 0);
      });
      footerData[0] = "Total (" + allRows?.length + " Categories)";
      footerData[8] = secondsToHms(footerData[8]);
      setDataLeadCategories(allRows);
      setDataLeadCategoriesFooter(footerData);
    }

    const dataByTicketCat = await dispatch(
      fetchDashboardData({ query_type: "ticket_types" }) // old query_type: "ticket_categories"
    );
    if (dataByTicketCat?.payload?.data?.data?.length > 0) {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0, 0, 0, 0];
      dataByTicketCat?.payload?.data?.data?.forEach((val: any) => {
        allRows.push([
          formatDropDownValue(val.display_name),
          val.open,
          val.less_than_2_hrs,
          val.from_2_to_4_hrs,
          val.greater_than_4_hrs,
          val.sla_breached,
          val.today_new_tickets || 0,
          val.today_closed || 0,
          // secondsToHms(val.today_avg_closing_time || 0),
        ]);
        footerData[1] += parseInt(val.open || 0);
        footerData[2] += parseInt(val.less_than_2_hrs || 0);
        footerData[3] += parseInt(val.from_2_to_4_hrs || 0);
        footerData[4] += parseInt(val.greater_than_4_hrs || 0);
        footerData[5] += parseInt(val.sla_breached || 0);
        footerData[6] += parseInt(val.today_new_tickets || 0);
        footerData[7] += parseInt(val.today_closed || 0);
        // footerData[8] += parseInt(val.today_avg_closing_time || 0);
      });
      footerData[0] = "Total (" + allRows?.length + " Categories)";
      // footerData[8] = secondsToHms(footerData[8]);
      console.log("footerData", footerData);
      setDataTicketCategories(allRows);
      setDataTicketCategoriesFooter(footerData);
    }

    // --------------- ticket source dataset

    const dataByTicketSource = await dispatch(
      fetchDashboardData({ query_type: "ticket_source" })
    );
    if (dataByTicketSource?.payload?.data?.data?.length > 0) {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0, 0, 0, 0];
      dataByTicketSource?.payload?.data?.data?.forEach((val: any) => {
        allRows.push([
          formatDropDownValue(val.display_name),
          val.open,
          val.less_than_2_hrs,
          val.from_2_to_4_hrs,
          val.greater_than_4_hrs,
          val.sla_breached,
          val.today_new_tickets || 0,
          val.today_closed || 0,
          // secondsToHms(val.today_avg_closing_time || 0),
        ]);
        footerData[1] += parseInt(val.open);
        footerData[2] += parseInt(val.less_than_2_hrs);
        footerData[3] += parseInt(val.from_2_to_4_hrs);
        footerData[4] += parseInt(val.greater_than_4_hrs);
        footerData[5] += parseInt(val.sla_breached);
        footerData[6] += parseInt(val.today_new_tickets);
        footerData[7] += parseInt(val.today_closed || 0);
        // footerData[8] += parseInt(val.today_avg_closing_time || 0);
      });
      footerData[0] = "Total (" + allRows?.length + " Source)";
      // footerData[8] = secondsToHms(footerData[8]);
      console.log("footerData", footerData);
      setdataTicketSource(allRows);
      setdataTicketSourceFooter(footerData);
    }

    //-------------
  };

  const columnsOLBA = [
    "Status",
    "Total open",
    "0 to 2hrs",
    "2 to 4hrs",
    ">4hrs",
    "SLA breached",
  ];
  const columnsOTBA = [
    "Status",
    "Total open",
    "0 to 2hrs",
    "2 to 4hrs",
    ">4hrs",
    "SLA breached",
  ];

  const columnsOTLBA = [
    "Agent",
    myPermissions?.indexOf("task_module") !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1
      ? "open Leads"
      : "",
    `Open ${t("CRM.Tickets.Fields.Tickets")}`,
    "SLA breached",
    "Status",
  ].filter(Boolean);

  const columnsLeadCategories = [
    "Category",
    "Open",
    "0 to 2hrs",
    "2 to 4hrs",
    ">4hrs",
    "SLA breached",
    "Today's new leads",
    "Today's converted leads",
    "Today's avg. closing time",
  ];
  const columnsTicketCategories = [
    t("CRM.Tickets.Fields.Ticket Type"),
    "Open",
    "0 to 2hrs",
    "2 to 4hrs",
    ">4hrs",
    "SLA breached",
    `Today's new ${t("CRM.Tickets.Fields.Tickets")}`,
    `Today's closed ${t("CRM.Tickets.Fields.Tickets")}`,
    // "Today's Avg. Closing Time",
  ];

  const columnsTicketSource = [
    "Source",
    "Open",
    "0 to 2hrs",
    "2 to 4hrs",
    ">4hrs",
    "SLA breached",
    `Today's new ${t("CRM.Tickets.Fields.Tickets")}`,
    `Today's closed ${t("CRM.Tickets.Fields.Tickets")}`,
    // "Today's Avg. Closing Time",
  ];


  return (
    <>
      <Row gutter={32}>
        <Col span={6}>
          {openItems !== null ? (
            <CardBlock
              title="Open Items"
              items={openItems}
              type="text-success"
            />
          ) : (
            <Skeleton />
          )}
        </Col>
        <Col span={6}>
          {slaBreachedItems !== null ? (
            <CardBlock
              title="Items with SLA breached"
              items={slaBreachedItems}
              type="text-danger"
            />
          ) : (
            <Skeleton />
          )}
        </Col>

        <Col span={6}>
          {closedItems !== null ? (
            <CardBlock title="Closed" items={closedItems} type="text-success" />
          ) : (
            <Skeleton />
          )}
        </Col>
        <Col span={6}>
          {notClosedItems !== null ? (
            <CardBlock
              title="Not closed"
              items={notClosedItems}
              type="text-success"
            />
          ) : (
            <Skeleton />
          )}
        </Col>

      </Row>
      {
        (myPermissions?.indexOf("task_module") !== -1 ||
          myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1) &&
        <>
          {
            dataSetOLBA == null ? (
              <Skeleton />
            ) : dataSetOLBA?.length ? <Row gutter={32} className="mt30">
              <Col span={24}>
                <TableCard
                  columns={columnsOLBA}
                  dataSet={dataSetOLBA}
                  title="Open Leads by ageing"
                  pagination={false}
                />

              </Col>
            </Row> : ""
          }
        </>
      }

      {
        dataSetOTBA == null ? (
          <Skeleton />
        ) : dataSetOTBA?.length ? <Row gutter={32} className="mt30">
          <Col span={24}>
            <TableCard
              columns={columnsOTBA}
              dataSet={dataSetOTBA}
              title={`Open ${t("CRM.Tickets.Fields.Tickets")} by ageing`}
              pagination={false}
            />
          </Col>
        </Row> : ""
      }
      {(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_CALL_LOGS) !== -1) && (
          <Row className="mt30">
            <Col span={24}>
              {callDistribution !== null ? (
                <IncomingCallFacility
                  title="Today's Incoming Calls Distribution"
                  callItems={callDistribution}
                />
              ) : (
                <Skeleton />
              )}
            </Col>
          </Row>
        )}

      <Row className="mt30">
        <Col span={24}>
          {dataSetOpenByAgent !== null && dataSetOpenByAgentFooter !== null ? (
            <TableCard
              columns={columnsOTLBA}
              dataSet={dataSetOpenByAgent}
              footerData={dataSetOpenByAgentFooter}
              title={
                myPermissions?.indexOf("task_module") !== -1 ||
                  myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1
                  ? `Open ${t("CRM.Tickets.Fields.Tickets")}/leads by agents`
                  : `Open ${t("CRM.Tickets.Fields.Tickets")} by agents`
              }
            />
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>
      {(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1) && (
          <Row className="mt30">
            <Col span={24}>
              {dataLeadCategories !== null &&
                dataLeadCategoriesFooter !== null ? (
                <TableCard
                  columns={columnsLeadCategories}
                  dataSet={dataLeadCategories}
                  footerData={dataLeadCategoriesFooter}
                  title={
                    <span>
                      Lead Categories{" "}
                      <span className="text-secondary">
                        (Ageing and today’s performance)
                      </span>
                    </span>
                  }
                />
              ) : (
                <Skeleton />
              )}
            </Col>
          </Row>
        )}

      <Row className="mt30">
        <Col span={24}>
          {dataTicketCategories !== null &&
            dataTicketCategoriesFooter !== null ? (
            <TableCard
              columns={columnsTicketCategories}
              dataSet={dataTicketCategories}
              footerData={dataTicketCategoriesFooter}
              title={
                <span>
                  {t("CRM.Tickets.Fields.Ticket Type")?.toLowerCase().includes("ticket") ?
                    t("CRM.Tickets.Fields.Ticket Type") :
                    `${t("CRM.Tickets.Fields.Ticket")} ` + t("CRM.Tickets.Fields.Ticket Type")}
                  <span className="text-secondary">
                    (Ageing and today’s performance)
                  </span>
                </span>
              }
            />
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>

      <Row className="mt30">
        <Col span={24}>
          {dataTicketSource !== null && dataTicketSourceFooter !== null ? (
            <TableCard
              columns={columnsTicketSource}
              dataSet={dataTicketSource}
              footerData={dataTicketSourceFooter}
              title={
                <span>
                  {`${t("CRM.Tickets.Fields.Ticket")}`} Source{" "}
                  <span className="text-secondary">
                    (Ageing and today’s performance)
                  </span>
                </span>
              }
            />
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>
    </>
  );
}
export function CardBlock({ title, items, type = "text-success" }: any) {
  return (
    <>
      <Card className="dashboard-card-1">
        <h1 className="bold-600 text-dark ">{title}</h1>
        {items?.length > 0 &&
          items.map((val: any) => {
            return <CardItem data={val} type={type} />;
          })}
      </Card>
    </>
  );
}
export function CardItem({ data, type, dot = true }: any) {
  return (
    <>
      <Row align="middle" className="card-item" gutter={16}>
        {dot && (
          <Col>
            <span className="dot success"></span>
          </Col>
        )}
        <Col flex="auto">{data.title}</Col>
        <Col>
          <span className={type + " bold"}>{data.count}</span>
        </Col>
      </Row>
    </>
  );
}
export function IconCard({ agentData, callData }: any) {
  return (
    <Card className="dashboard-card-1">
      <Row align="middle" className="card-item" gutter={16}>
        <Col>
          <div className="icon-container">
            <Agent />
          </div>
        </Col>
        <Col flex="auto">
          {agentData?.length > 0 &&
            agentData.map((val: any) => {
              return <CardItem data={val} type="text-warning" dot={false} />;
            })}
        </Col>
        <Col>
          <Divider type="vertical"></Divider>
        </Col>
        <Col>
          <div className="icon-container">
            <Phone />
          </div>
        </Col>
        <Col flex="auto">
          {callData?.length > 0 &&
            callData.map((val: any) => {
              return <CardItem data={val} type="text-success" dot={false} />;
            })}
        </Col>
      </Row>
    </Card>
  );
}
export function TableCard({
  columns,
  dataSet,
  title,
  pagination = true,
  footerData = [],
  loading = false,
}: any) {
  const [dataSetPaginated, setDataSetPaginated] = useState([] as any);
  const [paginationEnabled, setPaginationEnabled] = useState(false as any);
  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    let _paginationEnabled = false;
    if (dataSet.length > 5 && pagination) {
      _paginationEnabled = true;
    }
    setPageNumber(1);
    setPaginationEnabled(_paginationEnabled);
  }, [dataSet, pagination]);

  useEffect(() => {
    paginationEnabled &&
      setDataSetPaginated(dataSet.slice((pageNumber - 1) * 5, pageNumber * 5));
    !paginationEnabled && setDataSetPaginated(dataSet);
  }, [pageNumber]);

  useEffect(() => {
    setDataSetPaginated(dataSet.slice((pageNumber - 1) * 5, pageNumber * 5));
  }, [dataSet]);

  const pageChange = (e: number) => {
    setPageNumber(e);
  };
  return (
    <>
      <Card className="dashboard-card-1">
        <Row>
          <Col span={paginationEnabled ? 12 : 24}>
            <h1 className="bold-600 text-dark">{title}</h1>
          </Col>
          {paginationEnabled && (
            <Col className="text-right crm-table" span={12}>
              <Pagination
                showSizeChanger={false}
                pageSize={5}
                current={pageNumber}
                total={dataSet.length}
                onChange={(e: any) => pageChange(e)}
              />
            </Col>
          )}
        </Row>
        <div className="table-container">
          <Spin spinning={loading}>
            <table>
              <thead>
                <tr >
                  {columns?.length > 0 &&
                    columns.map((val: any, index: number) => {
                      return (
                        <th
                          style={index ?
                            { width: `calc(100% / ${columns?.length - 1})`, textAlign: "left" }
                            : { width: "20%", textAlign: "left" }}
                        >
                          {val}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {dataSetPaginated?.length > 0 &&
                  dataSetPaginated.map((val: any, index: number) => {
                    return (
                      <tr>
                        {val?.length > 0 &&
                          val.map((val1: any) => {
                            return <td style={{ textAlign: "left" }}>{val1}</td>;
                          })}
                      </tr>
                    );
                  })}
              </tbody>
              {footerData?.length > 0 && (
                <tfoot>
                  <tr>
                    {footerData?.length > 0 &&
                      footerData.map((val: any) => {
                        return <td style={{ textAlign: "left" }}>{val}</td>;
                      })}
                  </tr>
                </tfoot>
              )}
            </table>
          </Spin>
        </div>
      </Card>
    </>
  );
}
export function IncomingCallFacility({ title, callItems }: any) {
  const maxCount = callItems
    .map((val: any) => {
      return val.count;
    })
    .sort(function (a: any, b: any) {
      return !(a - b);
    })[0];
  return (
    <>
      <Card className="dashboard-card-1">
        <h1 className="bold-600 text-dark mb20">{title}</h1>
        {callItems?.length > 0 &&
          callItems.map((val: any) => {
            return (
              <>
                <Row className="mt15" align="middle">
                  <Col flex="auto" className="text-secondary">
                    {val.title}
                  </Col>
                  <Col flex="none" className="text-primary bold-600">
                    {val.count}
                  </Col>
                </Row>
                <Progress
                  showInfo={false}
                  strokeWidth={10}
                  strokeColor={val.color}
                  percent={Math.round((val.count / maxCount) * 100)}
                />
              </>
            );
          })}
      </Card>
    </>
  );
}
