import { Button, Col, Drawer, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchContact } from "../../../../redux/reducers/contacts.slice";
import { addNewPatientFromContact } from "../../../../redux/reducers/patients.slice";
import { fetchMedicinesById } from "../../../../redux/reducers/pharmacy.slice";
import { fetchTicketsDetails } from "../../../../redux/reducers/tickets.slice";
import { RootState } from "../../../../shared/constants";
import PlaceOrderComponent from "../../../../shared/layouts/pharmacy-place-order.layout";
import { formatDropDownValue } from "../../../../shared/Utils/utilities";
import PatientJourney from "../../../dependencies/patient-journey";
import PatientMessageHistory from "../../../dependencies/patient-message-history";
import SelectedMedicine from "../../../dependencies/selected-medicine.component";
import OrderDetailsComponent from "../../BookServices/components/orderdetails";
import OrderMedicineCompoment from "../../BookServices/components/ordermedicine";
import UpdateTicketForm from "../../Tickets/components/updateticketform";
var _ = require("lodash");
const { TabPane } = Tabs;

type Props = {
  ticket?: any;
  loading?: any;
  auditStatus?: any;
};

const TicketDetailsPharmacyQCTab: React.FunctionComponent<Props> = ({
  ticket,
  loading,
  auditStatus,
}) => {
  const dispatch = useDispatch();
  const { contact } = useSelector((state: RootState) => state.contact);
  const [audit, setAuditTrail] = useState(false as any);
  const [isReset, setIsReset] = useState(null as any);
  const [newOrderAuditTrail, setNewOrderAuditTrail] = useState("" as any);
  const [pharmacyDetails, setPharmacyDetails] = useState({} as any);
  const [orderDetails, setorderDetails] = useState(null as any)




  useEffect(() => {
    ticket.contact_id && dispatch(fetchContact(ticket.contact_id));
    // ticket?.services && ticket?.services[0]?.order_id && dispatch(fetchOrderDetailsByID(ticket?.services[0]?.order_id)).then((val: any) => {
    //   setorderDetails(val.payload.data.data)
    // })
  }, [ticket]);

  const onFinishTicket = (values: any) => {
    dispatch(fetchTicketsDetails(ticket.id));
  };
  useEffect(() => {
    if (contact) {
      if (contact?.patient_id) {
      } else {
        dispatch(
          addNewPatientFromContact({ contact_id: ticket.contact_id })
        ).then((val: any) => {
          if (val?.payload?.data?.statusCode == 200) {
            dispatch(fetchContact(ticket.contact_id));
          }
        });
      }
    }
  }, [contact]);
  let closedDisabled: Array<string> = [];
  if (ticket?.services?.length > 0) {
    if (ticket?.services[0]?.order_request_id) {
      closedDisabled =
        ticket?.services[0]?.order_request?.status == "placed"
          ? []
          : ["CLOSED"];
    }
    if (ticket?.services[0]?.order_id) {
      closedDisabled =
        orderDetails?.status == "placed" ? [] : ["CLOSED"];
    }
  }

  const handleAudit = async () => {
    setAuditTrail(true);
    setNewOrderAuditTrail("newOrderAuditTrail");
    let id = ticket?.services[0]?.order_id
      ? ticket?.services[0]?.order_id
      : ticket?.services[0]?.order_request_id;
    let data = await dispatch(fetchMedicinesById(id));
    setPharmacyDetails(data?.payload?.data?.data?.pharmacy);
    setIsReset(null);
  };
  const onCloseAuditTrail = () => {
    setAuditTrail(false);
    setIsReset("reset");
  };

  // console.log(closedDisabled)
  return (
    <>
      {/* { ticket && ticket?.services.length> 0 && (ticket?.services[0]?.order_id || ticket?.services[0]?.order_request_id ) && ticket?.sub_type?.name === 'pharmacy' && <>
             <Row gutter={16}>
                           <Col span={14} className='gutter-row'></Col>
                           <Col span={10} className='text-right'>
                               <Button type="link" onClick={handleAudit}>Audit Trail</Button>
                           </Col>
                       </Row> 
                    </>}  */}
      <Row style={{ maxHeight: "calc(100vh - 155px)", overflowY: "scroll" }}>
        <Col span={19} className="bg-app">
          {formatDropDownValue(ticket?.status?.name) !== "CLOSED" &&
            contact?.patient_id && (
              <OrderMedicineCompoment
                contact={contact}
                ticket={ticket}
                orderId={
                  ticket?.services[0]?.order_id
                    ? ticket?.services[0]?.order_id
                    : ticket?.services[0]?.order_request_id
                      ? ticket?.services[0]?.order_request_id
                      : ""
                }
              ></OrderMedicineCompoment>
            )}
          {formatDropDownValue(ticket?.status?.name) === "CLOSED" &&
            contact?.patient_id && (
              <OrderDetailsComponent
                contact={contact}
                ticket={ticket}
                orderId={
                  ticket?.services[0]?.order_id
                    ? ticket?.services[0]?.order_id
                    : ticket?.services[0]?.order_request_id
                      ? ticket?.services[0]?.order_request_id
                      : ""
                }
              ></OrderDetailsComponent>
            )}
          {ticket &&
            ticket?.services.length > 0 &&
            (ticket?.services[0]?.order_id ||
              ticket?.services[0]?.order_request_id) &&
            ticket?.sub_type?.name === "pharmacy" && (
              <>
                <Button
                  type="link"
                  className="floating-audit bold-600"
                  onClick={handleAudit}
                >
                  <u>View Audit Trail</u>
                </Button>
              </>
            )}
        </Col>
        <Col span={5}>
          <UpdateTicketForm
            ticket={ticket}
            parentCallback={onFinishTicket}
            disableStatus={closedDisabled}
          ></UpdateTicketForm>
        </Col>
      </Row>
      {/*  Audit Trail Drawer */}
      {audit && (
        <Drawer
          title={`Audit Trail`}
          placement="right"
          width={"80%"}
          onClose={onCloseAuditTrail}
          visible={audit}
        >
          <Row className="mt30">
            <Col span={24}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Pharmacy Order" key="1">
                  <Row className="bordered-grey-color">
                    <Col
                      className="pd20 grey-background"
                      span={auditStatus === "newOrders" ? 24 : 14}
                    >
                      <SelectedMedicine
                        newOrderAuditTrail={newOrderAuditTrail}
                      />
                    </Col>
                    <Col span={auditStatus === "newOrders" ? 0 : 10}>
                      <PlaceOrderComponent
                        newOrderAuditTrail={newOrderAuditTrail}
                      />
                    </Col>
                  </Row>
                </TabPane>
                {pharmacyDetails?.appointment_id && (
                  <TabPane tab="Patient Journey" key="2">
                    <PatientJourney
                      appointmentId={pharmacyDetails?.appointment_id}
                      isReset={isReset}
                    ></PatientJourney>
                  </TabPane>
                )}
                <TabPane tab="Message History" key="3">
                  <PatientMessageHistory
                    patient_id={pharmacyDetails?.patient?.id}
                    isReset={isReset}
                  ></PatientMessageHistory>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Drawer>
      )}
    </>
  );
};

export default TicketDetailsPharmacyQCTab;
