import { MsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Spin, notification } from "antd";
import * as dotenv from "dotenv";
import moment from "moment";
import { Suspense, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./App.less";
import { BUILD_TIME } from "./built-time";
import { ScreenType } from "./context/ScreenType";
import { BrowserFcmProvider } from "./firebase";
import { registerDevice } from "./redux/reducers/auth.slice";
import { persistor, store } from "./redux/store";
import RouteGuard from "./route-guards/route.guard";

import {
  ACCOUNT_PREFIX,
  APPLICATION_NAME,
  ENABLE_NOTIFICATION,
  ENABLE_WEB_SOCKET,
  RootState,
  SENTRY_INIT_DSN,
  SENTRY_INIT_NAME,
  TRANSLATION_KEY,
  WEBSOCKET_END_POINT,
} from "./shared/constants";

import { getConnection } from "./redux/reducers/chat.slice";
import { getCookie, setCookie, validateToken } from "./shared/Utils/utilities";
import ResourceNotFoundComponent from "./shared/layouts/404.layout";
import GlobalLayout from "./shared/layouts/global.layout";
import { ROUTE_WEBFORM_LEADS } from "./shared/routes/route.constants";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // 5 min stale time
      cacheTime: 300000, // 5 min cache time
    },
  },
});

document.title = APPLICATION_NAME;
Sentry.init({
  dsn: SENTRY_INIT_DSN,
  release: SENTRY_INIT_NAME + "@" + process.env.npm_package_version,
  integrations: [new Integrations.BrowserTracing()],
  maxBreadcrumbs: 50,
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.1,
});
dotenv.config();

export const getAppSlug: any = () => {
  if (window.location.pathname.split("/")?.length > 0) {
    if (
      window.location.pathname.split("/")[1] !== "" &&
      window.location.pathname.split("/")[1] !== "/"
    )
      return window.location.pathname.split("/")[1];
  }
  return "";
};
function App({ pca }: any) {
  const [configData, setConfigData] = useState(
    localStorage.getItem("config") as any
  );

  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const loadConfig = () => {
    const token = localStorage.getItem("token");
    let origin = window.location.hostname.split(".").join("_");
    let app = localStorage.getItem("account_slug") || "";
    let appurl = getAppSlug();

    if (app == "" || app == "undefined" || app == "null") {
      app = appurl;
    }
    if (app != appurl) {
      app = appurl;
      localStorage.clear();
      window.location.reload();
    }

    // if (app == "" || app == "undefined" || app == "null") {
    //   app = getAppSlug();
    // }
    // if (app == "") {
    //   return;
    // }

    origin = origin === "localhost" ? "betacrm_hlthclub_in" : origin;
    fetch(
      "https://d27zjjrd786p5a.cloudfront.net/medi-ui/crm/" +
        app +
        "/" +
        origin +
        ".json?v=2",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (allData) {
        localStorage.setItem("config", JSON.stringify(allData));
        if (
          !configData &&
          allData.ACCOUNT_PREFIX &&
          window.location?.href?.includes("web-form-leads") &&
          !(token && token !== "")
        ) {
          // console.log(query);
          window.location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            "/" +
            ROUTE_WEBFORM_LEADS(allData.ACCOUNT_PREFIX) +
            window.location.search;
        }
        if (!configData) {
          // setConfigData(JSON.stringify(allData));
          window.location.reload();
        }
        //
      })
      .catch((error) => {
        if (window.location.pathname !== "/404") window.location.href = "/404";
      });
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    const account_id = localStorage.getItem("account_id");
    const account_slug = localStorage.getItem("account_slug");
    let current_account_slug = getAppSlug();
    const old_account_slug = localStorage.getItem("account_slug");
    (!configData ||
      !account_id ||
      account_slug == "undefined" ||
      account_id == "undefined" ||
      (!token && current_account_slug != old_account_slug)) &&
      loadConfig();
  }, []);
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ScreenType.Provider value={screenType}>
          <GlobalLayout>
            {configData !== null && (
              <Router>
                <AppRouted pca={pca} />
              </Router>
            )}
          </GlobalLayout>
        </ScreenType.Provider>
      </PersistGate>
    </Provider>
  );
}

function AppRouted({ pca }: any) {
  const ws = useRef(null as any);
  const dispatch = useDispatch();
  let tokenExpiredErrorDisplayedRef = useRef(false);

  const { account } = useSelector((state: RootState) => state.auth);
  const { profile } = useSelector((state: RootState) => state.profile);
  const { connection } = useSelector((state: RootState) => state.chat);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(TRANSLATION_KEY || "en");
  }, []);

  const app: string = "agent";
  const token = localStorage.getItem("token");

  const setupNotification = async () => {
    try {
      const token = await BrowserFcmProvider.webGetToken();
      let device_id: any = new Date().getTime() + "";

      if (getCookie("device_id")) {
        device_id = getCookie("device_id");
      } else {
        setCookie("device_id", device_id, 100);
      }

      if (token) {
        localStorage.setItem("device_id", device_id + "");
        await dispatch(
          registerDevice({
            app_id: "max_app",
            visitor_id: profile.id,
            user_id: profile.id,
            account_id: "max",
            token: token,
            device_id,
            os: "windows",
            platform: "web",
          })
        );
        BrowserFcmProvider.showMessage();
      }
    } catch (e) {
      console.log("Unable to register firebase");
    }
  };

  useEffect(() => {
    let webSocket = null as any;
    const connectWebSocket = () => {
      if (
        profile &&
        localStorage.getItem("token") &&
        ENABLE_WEB_SOCKET === true
      ) {
        webSocket = new WebSocket(
          `${WEBSOCKET_END_POINT}?name=${
            profile?.name
              ? profile?.name
              : profile?.first_name + " " + profile?.last_name
          }&account_id=${account?.id}&role=${
            profile?.type === "admin" ? "admin" : "agent"
          }&date=${moment().format("YYYY-MM-DD")}&token=${localStorage.getItem(
            "token"
          )}&user_id=${profile?.id}&application_id=${localStorage.getItem(
            "application_id"
          )}
          &auth-type=${localStorage.getItem("auth-type")}`
        );
      }
      // webSocket = new WebSocket('wss://your-websocket-url');
    };
    connectWebSocket();

    if (webSocket) {
      webSocket.onopen = () => {
        console.log("WebSocket connection established.");
        startPingPong();
      };

      webSocket.onmessage = (event: any) => {
        const message = event.data;
        console.log("Received message:", message);
      };

      webSocket.onclose = () => {
        console.log("WebSocket connection closed. Reconnecting...");
        reconnectWebSocket();
      };

      webSocket.onerror = (error: any) => {
        console.error("WebSocket error:", error);
      };
      dispatch(getConnection(webSocket));
    }

    const sendMessage = async (keep_alive = false) => {
      let data = {
        action: "sendMessage",
        message: keep_alive ? "" : "",
        type: keep_alive ? "keep_alive" : "new_msg",
        user_id: profile?.id,
        account_id: account?.id,
        source: "webSocket",
        user_name: profile?.name,
        message_type: "text",
        token: localStorage.getItem("token"),
      };
      if (webSocket.readyState) {
        webSocket?.send(JSON.stringify(data));
      }
    };

    const startPingPong = () => {
      const pingInterval = 30000; // Ping interval in milliseconds (e.g., 30 seconds)
      setInterval(() => {
        if (webSocket.readyState === WebSocket.OPEN) {
          sendMessage(true);
        }
      }, pingInterval);
    };

    const reconnectWebSocket = () => {
      // Reconnect logic goes here, e.g., you can implement a backoff algorithm
      setTimeout(connectWebSocket, 2000);
      // Reconnect after 2 seconds
    };

    if (ENABLE_NOTIFICATION === true) {
      setupNotification();
    }

    return () => {
      // Clean up the WebSocket connection on component unmount
      if (webSocket) {
        webSocket.close();
      }
    };
  }, []);

  const functionToCall = () => {
    if (!validateToken()) {
      if (!tokenExpiredErrorDisplayedRef.current) {
        tokenExpiredErrorDisplayedRef.current = true as any;
        notification.error({ message: "Session Expired" });

        setTimeout(() => {
          const slug = localStorage?.getItem("account_slug");
          window.localStorage.clear();
          window.location.href =
            window.location.origin + "/" + (ACCOUNT_PREFIX || slug);
        }, 500);
      }
      return;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token") as any;
    let currentTimestamp: any, exp: any, differenceInSeconds: any;
    if (token) {
      currentTimestamp = Math.floor(Date.now() / 1000);
      exp = JSON.parse(window.atob(token?.split(".")[1])).exp;
      differenceInSeconds = Math.floor(exp - currentTimestamp);
    }
    const timeoutId = setTimeout(functionToCall, differenceInSeconds * 1000);
    // Clean up the timeout when the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const envPolicyConfig = JSON.parse(
    process.env.REACT_APP_POLICY_CONFIG || "{}"
  );
  return (
    <div>
      <div className={"build-time"}>{BUILD_TIME}</div>
      <Suspense
        fallback={
          <div>
            <Spin />
          </div>
        }
      >
        <MsalProvider instance={pca}>
          <QueryClientProvider client={queryClient}>
            {/*<ReactQueryDevtools initialIsOpen={false}/>*/}

            <Sentry.ErrorBoundary fallback={ResourceNotFoundComponent}>
              <Switch>
                <RouteGuard path="/" app={app} />
              </Switch>
            </Sentry.ErrorBoundary>
          </QueryClientProvider>
        </MsalProvider>
      </Suspense>
    </div>
  );
}

export default App;
