import { DownOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Cascader,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
  Select,
  Tag,
  TimePicker,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
var _ = require("lodash");

type Props = {
  disabedSubmitButton?: any;
  name: string;
  heading?: string;
  onFinish: any;
  onFinishFailed: any;
  formElements: any;
  initialValues: any;
  buttonText: string;
  buttonType?: any;

  loading?: boolean;
  sub_heading?: any;
  actionType?: any;
  onValuesChange?: any;
  setValues?: any;
  onClick?: any;
  filters?: any;
  filtersApplied?: number;
  onDateTimeChange?: any;
  addonBefore?: any;
  disabledTimeValues?: any;
};

function FormBar({
  disabedSubmitButton,
  filters,
  onClick,
  actionType,
  name,
  heading,
  onFinish,
  onFinishFailed,
  formElements,
  buttonText,
  initialValues,
  buttonType = "primary",
  loading = false,
  sub_heading,
  onValuesChange = () => {},
  setValues = null,
  addonBefore,
  filtersApplied = 0,
  onDateTimeChange,
  disabledTimeValues,
}: Props) {
  const [form] = Form.useForm();
  //console.log(filtersApplied)

  console.log("disabedSubmitButton22222222", disabedSubmitButton);

  const [visiblePopover, setvisiblePopover] = useState(false);
  const handleVisibleChange = () => {
    setvisiblePopover(!visiblePopover);
  };
  const [hours, sethours] = useState([] as any);
  const [excludedMinutes, setexcludedMinutes] = useState(null as any);
  const [excludedHours, setexcludedHours] = useState(null as any);
  form.setFieldsValue(setValues);
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };

  // const filterCascader: any = (inputValue: string, path: DefaultOptionType[]) =>
  //   path.some(
  //     (option) =>
  //       (option.label as string)
  //         .toLowerCase()
  //         .indexOf(inputValue.toLowerCase()) > -1
  //   );

  const content = (
    <>
      {heading && (
        <Row
          className="bg-secondary mb-20 headColor"
          style={{ padding: "7px 15px 2px" }}
        >
          <Col>
            <h1 className="headColor">{heading}</h1>
          </Col>
        </Row>
      )}
      {sub_heading && (
        <Row style={{ padding: "7px 15px 2px" }}>
          <Col>
            <p className={"sub-title"}>{sub_heading}</p>
          </Col>
        </Row>
      )}
      <Form
        form={form}
        initialValues={initialValues}
        name={name}
        className="paddingx20  crm-form full-width"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={_.debounce(onValuesChange, 750)}
      >
        <Row gutter={20}>
          {formElements?.map((ele: any, index: number) => {
            return ele.filterType === "moreFilter" ? (
              <Col
                key={index}
                {...(ele.colSpanResponsive
                  ? {}
                  : { span: ele?.colSpan ? 12 : 24 })}
                {...ele.colSpanResponsive}
                style={ele?.type === "hidden" ? { display: "none" } : {}}
              >
                <Form.Item
                  name={ele.name}
                  key={index}
                  label={ele?.type !== "hidden" ? ele.label : null}
                  rules={ele.rules ? ele.rules : []}
                >
                  {ele?.type === "Select" ? (
                    <Select
                      disabled={ele.disabled ? true : false}
                      mode={ele.mode ? ele.mode : ""}
                      className={"capitalize"}
                      showSearch={ele.showSearch ? ele.showSearch : true}
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={
                        ele.onChange ? (e) => ele.onChange(e) : () => {}
                      }
                      placeholder={ele.placeholder ? ele.placeholder : ""}
                      allowClear={ele.allowClear ? ele.allowClear : false}
                    >
                      {ele?.options?.map((option: any, index: number) => {
                        return (
                          <Option
                            className={"capitalize"}
                            key={index}
                            value={option.value}
                          >
                            {option.name}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : ele?.type === "DatePicker" ? (
                    <DatePicker
                      format="DD MMM'YY"
                      style={{ width: "100%" }}
                      allowClear={ele.allowClear ? ele.allowClear : false}
                      disabledDate={
                        ele.disabledDate ? ele.disabledDate : (d) => false
                      }
                    />
                  ) : ele?.type === "TimePicker" ? (
                    <TimePicker
                      disabled={ele.disabled ? true : false}
                      style={{ width: "100%" }}
                      format="HH:mm A"
                      allowClear={ele.allowClear ? ele.allowClear : false}
                    />
                  ) : ele?.type === "DateTimePicker" ? (
                    <DatePicker
                      showTime={{ format: "hh:mm A" }}
                      format="DD MMM'YY hh:mm A"
                      style={{ width: "100%" }}
                      disabled={ele?.disabled}
                      disabledDate={
                        ele.disabledDate ? ele.disabledDate : (d) => false
                      }
                    />
                  ) : ele?.type === "RangePicker" ? (
                    <RangePicker
                      format="DD MMM'YY"
                      style={{ width: "100%" }}
                      disabledDate={
                        ele.disabledDate
                          ? ele.disabledDate
                          : (d: any) => {
                              return d > moment();
                            }
                      }
                    />
                  ) : ele?.type === "Divider" ? (
                    <Divider />
                  ) : ele?.type === "TextArea" ? (
                    <TextArea
                      disabled={ele.disabled ? true : false}
                      placeholder={ele.placeholder}
                      onChange={
                        ele.onChange ? (e) => ele.onChange(e) : () => {}
                      }
                      rows={ele.rows}
                      allowClear
                    />
                  ) : ele?.type === "Input" ? (
                    <Input
                      disabled={ele.disabled ? true : false}
                      placeholder={ele.placeholder ? ele.placeholder : ""}
                      prefix={ele.prefix ? ele.prefix : null}
                      addonBefore={ele.addonBefore ? ele.addonBefore : null}
                    />
                  ) : ele?.type === "Button" ? (
                    <Button
                      disabled={ele.disabled ? true : false}
                      onClick={() => {
                        onClick();
                      }}
                    >
                      {ele.name}
                    </Button>
                  ) : ele?.type === "InputNumber" ? (
                    <InputNumber
                      style={{ width: "100%" }}
                      min={ele.min ? ele.min : null}
                      max={ele.max ? ele.max : null}
                      disabled={ele.disabled ? true : false}
                      placeholder={ele.placeholder ? ele.placeholder : ""}
                      onKeyPress={(event: any) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      addonBefore={ele.addonBefore ? ele.addonBefore : null}
                      onChange={
                        ele.onChange ? (e) => ele.onChange(e) : () => {}
                      }
                    />
                  ) : ele?.type === "Cascader" ? (
                    <Cascader
                      className={"capitalize"}
                      options={ele.data}
                      // showSearch={{ filterCascader }}
                      {...(ele.multiple ? { multiple: true } : {})}
                      showSearch={true}
                      placeholder="Please select"
                    />
                  ) : ele?.type === "Hidden" ? (
                    <></>
                  ) : ele?.type === "Radio" ? (
                    <Radio.Group buttonStyle="solid">
                      {ele?.options?.map((val: any) => {
                        return (
                          <Radio.Button
                            className={val?.name?.toLowerCase()}
                            value={val?.value}
                          >
                            {val?.name}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  ) : ele?.type === "RadioRound" ? (
                    <Radio.Group
                      onChange={
                        ele.onChange ? (e) => ele.onChange(e) : () => {}
                      }
                      disabled={ele.disabled ? true : false}
                      buttonStyle="solid"
                    >
                      {ele?.options?.map((val: any) => {
                        return (
                          <Radio
                            className={val?.name?.toLowerCase()}
                            value={val?.value}
                          >
                            {val?.name}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  ) : ele?.type === "TagType" ? (
                    ele?.options?.map((tag: any, index: any) => {
                      return (
                        <Tag
                          key={tag.key}
                          closable
                          onClose={
                            ele.onChange
                              ? () => ele.onChange(tag.value)
                              : () => {}
                          }
                        >
                          {tag.label}
                        </Tag>
                      );
                    })
                  ) : ele?.type === "TagTypeAdd" ? (
                    <Button onClick={ele.onClick} size="small" type="dashed">
                      + Add new CC
                    </Button>
                  ) : ele?.type === "TagInput" ? (
                    <Input
                      type="text"
                      size="small"
                      style={{ minWidth: 100 }}
                      allowClear
                      // value={ele.inputValue}
                      // defaultValue={''}
                      onChange={
                        ele.onChange ? (e) => ele.onChange(e) : () => {}
                      }
                      onBlur={
                        ele.onChange
                          ? (e) => {
                              ele.onBlur(e);
                            }
                          : () => {}
                      }
                      onPressEnter={
                        ele.onChange ? (e) => ele.onPressEnter(e) : () => {}
                      }
                      autoFocus
                    />
                  ) : ele?.type === "AutoComplete" ? (
                    <AutoComplete
                      dataSource={ele.AutoCompleteData}
                      onSearch={ele.onSearch}
                      onSelect={ele.onSelect}
                      placeholder={ele.placeholder}
                    />
                  ) : ele?.type === "clear_filters" ? (
                    <Button onClick={ele.onClick} type={"link"}>
                      Clear Filters
                    </Button>
                  ) : (
                    ""
                  )}
                </Form.Item>
              </Col>
            ) : (
              ""
            );
          })}
        </Row>
        {buttonText !== "" && (
          <Row className="button-row">
            <Col span={24}>
              <Form.Item>
                <Button
                  className={"doctor-secondary-button boxShadowNone mt20"}
                  loading={loading}
                  type={buttonType}
                  size="large"
                  disabled={disabedSubmitButton}
                  htmlType="submit"
                  block
                >
                  {buttonText}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
  return (
    <>
      {filters === "multiple" ? (
        <Row gutter={20}>
          <Col span={24}>
            <Form
              form={form}
              initialValues={initialValues}
              name={name}
              className="paddingx20  crm-form full-width"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={_.debounce(onValuesChange, 750)}
            >
              <Row gutter={16}>
                {formElements?.map((ele: any, index: number) => {
                  return ele.filterType !== "moreFilter" ? (
                    <Col
                      key={index}
                      span={
                        ele.colSpanResponsive
                          ? null
                          : ele?.colSpan
                          ? ele?.colSpan
                          : ele.flex === "none"
                          ? null
                          : 24
                      }
                      flex={ele.flex ? ele.flex : null}
                      {...ele.colSpanResponsive}
                      style={ele?.type === "hidden" ? { display: "none" } : {}}
                    >
                      <Form.Item
                        name={ele.name}
                        key={index}
                        label={ele?.type !== "hidden" ? ele.label : null}
                        rules={ele.rules ? ele.rules : []}
                      >
                        {ele?.type === "Select" ? (
                          <Select
                            disabled={ele.disabled ? true : false}
                            mode={ele.mode ? ele.mode : ""}
                            showSearch={ele.showSearch ? ele.showSearch : true}
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                              option?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={
                              ele.onChange ? (e) => ele.onChange(e) : () => {}
                            }
                            placeholder={ele.placeholder ? ele.placeholder : ""}
                            allowClear={ele.allowClear ? ele.allowClear : false}
                          >
                            {ele?.options?.map((option: any, index: number) => {
                              return (
                                <Option key={index} value={option.value}>
                                  {option.name}
                                </Option>
                              );
                            })}
                          </Select>
                        ) : ele?.type === "DatePicker" ? (
                          <DatePicker
                            style={{ width: "100%" }}
                            format="DD MMM'YY"
                            allowClear={ele.allowClear ? ele.allowClear : false}
                            disabledDate={
                              ele.disabledDate ? ele.disabledDate : (d) => false
                            }
                          />
                        ) : ele?.type === "TimePicker" ? (
                          <TimePicker
                            disabled={ele.disabled ? true : false}
                            style={{ width: "100%" }}
                            format="hh:mm A"
                            allowClear={ele.allowClear ? ele.allowClear : false}
                          />
                        ) : ele?.type === "DateTimePicker" ? (
                          <DatePicker
                            style={{ width: "100%" }}
                            showTime={{ format: "hh:mm A" }}
                            disabled={ele?.disabled}
                            format="DD MMM'YY hh:mm A"
                            disabledDate={
                              ele.disabledDate ? ele.disabledDate : (d) => false
                            }
                          />
                        ) : ele?.type === "RangePicker" ? (
                          <RangePicker
                            style={{ width: "100%" }}
                            format="DD MMM'YY"
                            disabledDate={
                              ele.disabledDate
                                ? ele.disabledDate
                                : (d: any) => {
                                    return d > moment();
                                  }
                            }
                          />
                        ) : ele?.type === "Divider" ? (
                          <Divider />
                        ) : ele?.type === "TextArea" ? (
                          <TextArea
                            disabled={ele.disabled ? true : false}
                            placeholder={ele.placeholder}
                            onChange={
                              ele.onChange ? (e) => ele.onChange(e) : () => {}
                            }
                            rows={ele.rows}
                            allowClear
                          />
                        ) : ele?.type === "Button" ? (
                          <Button
                            disabled={ele.disabled ? true : false}
                            onClick={() => {
                              onClick();
                            }}
                          >
                            {ele.name}
                          </Button>
                        ) : ele?.type === "Input" ? (
                          <Input
                            disabled={ele.disabled ? true : false}
                            placeholder={ele.placeholder ? ele.placeholder : ""}
                            prefix={ele.prefix ? ele.prefix : null}
                            addonBefore={
                              ele.addonBefore ? ele.addonBefore : null
                            }
                          />
                        ) : ele?.type === "InputNumber" ? (
                          <InputNumber
                            style={{ width: "100%" }}
                            min={ele.min ? ele.min : null}
                            max={ele.max ? ele.max : null}
                            disabled={ele.disabled ? true : false}
                            placeholder={ele.placeholder ? ele.placeholder : ""}
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={
                              ele.onChange ? (e) => ele.onChange(e) : () => {}
                            }
                            addonBefore={
                              ele.addonBefore ? ele.addonBefore : null
                            }
                          />
                        ) : ele?.type === "Cascader" ? (
                          <Cascader
                            className={"capitalize"}
                            options={ele.data}
                            showSearch={true}
                            placeholder="Please select"
                          />
                        ) : ele?.type === "Hidden" ? (
                          <></>
                        ) : ele?.type === "Radio" ? (
                          <Radio.Group buttonStyle="solid">
                            {ele?.options?.map((val: any) => {
                              return (
                                <Radio.Button
                                  className={val?.name?.toLowerCase()}
                                  value={val?.value}
                                >
                                  {val?.name}
                                </Radio.Button>
                              );
                            })}
                          </Radio.Group>
                        ) : ele?.type === "RadioRound" ? (
                          <Radio.Group
                            onChange={
                              ele.onChange ? (e) => ele.onChange(e) : () => {}
                            }
                            disabled={ele.disabled ? true : false}
                            buttonStyle="solid"
                          >
                            {ele?.options?.map((val: any) => {
                              return (
                                <Radio
                                  className={val?.name?.toLowerCase()}
                                  value={val?.value}
                                >
                                  {val?.name}
                                </Radio>
                              );
                            })}
                          </Radio.Group>
                        ) : ele?.type === "TagType" ? (
                          ele?.options?.map((tag: any, index: any) => {
                            return (
                              <Tag
                                key={tag.key}
                                closable
                                onClose={
                                  ele.onChange
                                    ? () => ele.onChange(tag.value)
                                    : () => {}
                                }
                              >
                                {tag.label}
                              </Tag>
                            );
                          })
                        ) : ele?.type === "TagTypeAdd" ? (
                          <Button
                            onClick={ele.onClick}
                            size="small"
                            type="dashed"
                          >
                            + Add new CC
                          </Button>
                        ) : ele?.type === "TagInput" ? (
                          <Input
                            type="text"
                            size="small"
                            style={{ minWidth: 100 }}
                            allowClear
                            // value={ele.inputValue}
                            // defaultValue={''}
                            onChange={
                              ele.onChange ? (e) => ele.onChange(e) : () => {}
                            }
                            onBlur={
                              ele.onChange
                                ? (e) => {
                                    ele.onBlur(e);
                                  }
                                : () => {}
                            }
                            onPressEnter={
                              ele.onChange
                                ? (e) => ele.onPressEnter(e)
                                : () => {}
                            }
                            autoFocus
                          />
                        ) : ele?.type === "AutoComplete" ? (
                          <AutoComplete
                            dataSource={ele.AutoCompleteData}
                            onSearch={ele.onSearch}
                            onSelect={ele.onSelect}
                            placeholder={ele.placeholder}
                          />
                        ) : (
                          ""
                        )}
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  );
                })}
                <Col flex="none">
                  <Popover
                    overlayStyle={{ width: "800px" }}
                    content={content}
                    placement="bottom"
                    title="FILTERS"
                    trigger="click"
                    visible={visiblePopover}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Button type="default" className="more-filters">
                      More {screenType.mobile ? "" : "Filters"}
                      {filtersApplied > 0 && (
                        <Tag
                          className="solid-full filter-applied-count-tag"
                          color="red"
                        >
                          {filtersApplied}
                        </Tag>
                      )}{" "}
                      <DownOutlined />
                    </Button>
                  </Popover>
                </Col>
              </Row>
              {buttonText !== "" && (
                <Row className="button-row">
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        loading={loading}
                        className={"doctor-secondary-button boxShadowNone mt20"}
                        size="large"
                        disabled={disabedSubmitButton}
                        type={buttonType}
                        htmlType="submit"
                        block
                      >
                        {buttonText}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>
      ) : (
        <>
          {heading && (
            <Row
              className="bg-secondary mb-20 headColor"
              style={{ padding: "7px 15px 2px", color: "red" }}
            >
              <Col>
                <h1 className="headColor">{heading}</h1>
              </Col>
            </Row>
          )}
          {sub_heading && (
            <Row style={{ padding: "7px 15px 2px" }}>
              <Col>
                <p className={"sub-title"}>{sub_heading}</p>
              </Col>
            </Row>
          )}
          <Form
            form={form}
            initialValues={initialValues}
            name={name}
            className="paddingx20  crm-form full-width"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={_.debounce(onValuesChange, 750)}
          >
            <Row gutter={20} align="bottom" className="cover">
              {formElements.map((ele: any, index: number) => {
                console.log("xxxxxxxxxxxx", ele.inputValue);
                return (
                  <Col
                    key={index}
                    span={
                      ele?.colSpan === 0 ? 0 : ele?.colSpan ? ele?.colSpan : 24
                    }
                    style={ele?.type === "hidden" ? { display: "none" } : {}}
                  >
                    <Form.Item
                      name={ele.name}
                      key={index}
                      label={ele?.type !== "hidden" ? ele.label : null}
                      rules={ele.rules ? ele.rules : []}
                      validateStatus={
                        ele.validateStatus ? ele.validateStatus : undefined
                      }
                      help={ele.help ? ele.help : undefined}
                    >
                      {ele?.type === "Select" ? (
                        <Select
                          disabled={ele.disabled ? true : false}
                          className={"capitalize"}
                          mode={ele.mode ? ele.mode : ""}
                          showSearch={ele.showSearch ? ele.showSearch : true}
                          optionFilterProp="children"
                          filterOption={(input, option: any) =>
                            option?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={
                            ele.onChange ? (e) => ele.onChange(e) : () => {}
                          }
                          placeholder={ele.placeholder ? ele.placeholder : ""}
                          allowClear={ele.allowClear ? ele.allowClear : false}
                        >
                          {ele?.options?.map((option: any, index: number) => {
                            return (
                              <Option
                                className={"capitalize"}
                                key={index}
                                value={option.value}
                              >
                                {option.name}
                              </Option>
                            );
                          })}
                        </Select>
                      ) : ele?.type === "DatePicker" ? (
                        <DatePicker
                          disabled={ele.disabled ? true : false}
                          style={{ width: "100%" }}
                          format="DD MMM'YY"
                          allowClear={ele.allowClear ? ele.allowClear : false}
                          disabledDate={
                            ele.disabledDate ? ele.disabledDate : (d) => false
                          }
                        />
                      ) : ele?.type === "TimePicker" ? (
                        <TimePicker
                          disabled={ele.disabled ? true : false}
                          style={{ width: "100%" }}
                          format="HH:mm A"
                          allowClear={ele.allowClear ? ele.allowClear : false}
                        />
                      ) : ele?.type === "DateTimePicker" ? (
                        <DatePicker
                          disabled={ele?.disabled}
                          // showTime={{ format: "HH:mm A", defaultValue: moment('23:00', 'HH:mm') }}
                          showTime={{
                            format: "HH:mm A",
                            defaultValue: moment(
                              `${moment().format("hh")}:${moment().format(
                                "mm"
                              )} ${moment().format("A")} `,
                              "HH:mm A"
                            ),
                          }}
                          format="DD MMM'YY hh:mm A"
                          style={{ width: "100%" }}
                          onSelect={onDateTimeChange}
                          disabledTime={disabledTimeValues}
                          disabledDate={
                            ele.disabledDate ? ele.disabledDate : (d) => false
                          }
                        />
                      ) : ele?.type === "RangePicker" ? (
                        <RangePicker
                          style={{ width: "100%" }}
                          format="DD MMM'YY"
                          disabledDate={
                            ele.disabledDate
                              ? ele.disabledDate
                              : (d: any) => {
                                  return d > moment();
                                }
                          }
                        />
                      ) : ele?.type === "Divider" ? (
                        <Divider />
                      ) : ele?.type === "TextArea" ? (
                        <TextArea
                          disabled={ele.disabled ? true : false}
                          placeholder={ele.placeholder}
                          onChange={
                            ele.onChange ? (e) => ele.onChange(e) : () => {}
                          }
                          rows={ele.rows}
                          allowClear
                        />
                      ) : ele?.type === "Input" ? (
                        <Input
                          disabled={ele.disabled ? true : false}
                          placeholder={ele.placeholder ? ele.placeholder : ""}
                          prefix={ele.prefix ? ele.prefix : null}
                          addonBefore={ele.addonBefore ? ele.addonBefore : null}
                        />
                      ) : ele?.type === "Button" ? (
                        <Button
                          disabled={ele.disabled ? true : false}
                          onClick={() => {
                            onClick();
                          }}
                        >
                          {ele.name}
                        </Button>
                      ) : ele?.type === "InputNumber" ? (
                        <InputNumber
                          style={{ width: "100%" }}
                          min={ele.min ? ele.min : null}
                          placeholder={ele.placeholder ? ele.placeholder : ""}
                          max={ele.max ? ele.max : null}
                          disabled={ele.disabled ? true : false}
                          onKeyPress={(event: any) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={
                            ele.onChange ? (e) => ele.onChange(e) : () => {}
                          }
                          addonBefore={ele.addonBefore ? ele.addonBefore : null}
                        />
                      ) : ele?.type === "Cascader" ? (
                        <Cascader
                          className={"capitalize"}
                          options={ele.data}
                          showSearch={true}
                          placeholder="Please select"
                        />
                      ) : ele?.type === "Hidden" ? (
                        <></>
                      ) : ele?.type === "Radio" ? (
                        <Radio.Group buttonStyle="solid">
                          {ele?.options?.map((val: any) => {
                            return (
                              <Radio.Button
                                className={val?.name?.toLowerCase()}
                                value={val?.value}
                              >
                                {val?.name}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      ) : ele?.type === "RadioRound" ? (
                        <Radio.Group
                          onChange={
                            ele.onChange ? (e) => ele.onChange(e) : () => {}
                          }
                          disabled={ele.disabled ? true : false}
                          buttonStyle="solid"
                        >
                          {ele?.options?.map((val: any) => {
                            return (
                              <Radio
                                className={val?.name?.toLowerCase()}
                                value={val?.value}
                              >
                                {val?.name}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      ) : ele?.type === "TagType" ? (
                        ele?.options?.map((tag: any, index: any) => {
                          return (
                            <Tag
                              key={tag.key}
                              closable
                              onClose={
                                ele.onChange
                                  ? () => ele.onChange(tag.value)
                                  : () => {}
                              }
                            >
                              {tag.label}
                            </Tag>
                          );
                        })
                      ) : ele?.type === "TagTypeAdd" ? (
                        <Button
                          onClick={ele.onClick}
                          size="small"
                          type="dashed"
                        >
                          + Add new CC
                        </Button>
                      ) : ele?.type === "TagInput" ? (
                        <Input
                          type="text"
                          size="small"
                          style={{ minWidth: 100 }}
                          allowClear
                          // value={ele.inputValue}
                          // defaultValue={''}
                          onChange={
                            ele.onChange ? (e) => ele.onChange(e) : () => {}
                          }
                          onBlur={
                            ele.onChange
                              ? (e) => {
                                  ele.onBlur(e);
                                }
                              : () => {}
                          }
                          onPressEnter={
                            ele.onChange ? (e) => ele.onPressEnter(e) : () => {}
                          }
                          autoFocus
                        />
                      ) : ele?.type === "AutoComplete" ? (
                        <AutoComplete
                          dataSource={ele.AutoCompleteData}
                          onSearch={ele.onSearch}
                          onSelect={ele.onSelect}
                          placeholder={ele.placeholder}
                        />
                      ) : (
                        ""
                      )}
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
            {buttonText !== "" && (
              <Row className="button-row">
                <Col span={24}>
                  <Form.Item>
                    <Button
                      loading={loading}
                      size="large"
                      className={"doctor-secondary-button boxShadowNone mt20"}
                      type={buttonType}
                      disabled={disabedSubmitButton}
                      htmlType="submit"
                      block
                    >
                      {buttonText}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </>
      )}
    </>
  );
}
export default FormBar;

export const GenericFormItems = ({ formElements }: any) => {
  return (
    <>
      {formElements?.map((ele: any, index: number) => (
        <Col
          key={index}
          {...(ele.colSpanResponsive
            ? {}
            : { span: ele?.colSpan ? ele?.colSpan : 24 })}
          {...ele.colSpanResponsive}
          style={ele?.type === "hidden" ? { display: "none" } : {}}
        >
          <Form.Item
            name={ele.name}
            key={index}
            label={ele?.type !== "hidden" ? ele.label : null}
            rules={ele.rules ? ele.rules : []}
          >
            {ele?.type === "Select" ? (
              <Select
                disabled={ele.disabled ? true : false}
                mode={ele.mode ? ele.mode : ""}
                className={"capitalize"}
                showSearch={ele.showSearch ? ele.showSearch : true}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={ele.onChange ? (e) => ele.onChange(e) : () => {}}
                placeholder={ele.placeholder ? ele.placeholder : ""}
                allowClear={ele.allowClear ? ele.allowClear : false}
              >
                {ele?.options?.map((option: any, index: number) => {
                  return (
                    <Option
                      className={"capitalize"}
                      key={index}
                      value={option.value}
                    >
                      {option.name}
                    </Option>
                  );
                })}
              </Select>
            ) : ele?.type === "DatePicker" ? (
              <DatePicker
                format="DD MMM'YY"
                style={{ width: "100%" }}
                allowClear={ele.allowClear ? ele.allowClear : false}
                disabledDate={
                  ele.disabledDate ? ele.disabledDate : (d) => false
                }
              />
            ) : ele?.type === "TimePicker" ? (
              <TimePicker
                disabled={ele.disabled ? true : false}
                style={{ width: "100%" }}
                format="HH:mm A"
                allowClear={ele.allowClear ? ele.allowClear : false}
              />
            ) : ele?.type === "DateTimePicker" ? (
              <DatePicker
                showTime={{ format: "hh:mm A" }}
                format="DD MMM'YY hh:mm A"
                style={{ width: "100%" }}
                disabled={ele?.disabled}
                disabledDate={
                  ele.disabledDate ? ele.disabledDate : (d) => false
                }
              />
            ) : ele?.type === "RangePicker" ? (
              <RangePicker
                format="DD MMM'YY"
                style={{ width: "100%" }}
                disabledDate={
                  ele.disabledDate
                    ? ele.disabledDate
                    : (d: any) => {
                        return d > moment();
                      }
                }
              />
            ) : ele?.type === "Divider" ? (
              <Divider />
            ) : ele?.type === "TextArea" ? (
              <TextArea
                disabled={ele.disabled ? true : false}
                placeholder={ele.placeholder}
                onChange={ele.onChange ? (e) => ele.onChange(e) : () => {}}
                rows={ele.rows}
                allowClear
              />
            ) : ele?.type === "Input" ? (
              <Input
                disabled={ele.disabled ? true : false}
                placeholder={ele.placeholder ? ele.placeholder : ""}
                prefix={ele.prefix ? ele.prefix : null}
                addonBefore={ele.addonBefore ? ele.addonBefore : null}
              />
            ) : ele?.type === "InputNumber" ? (
              <InputNumber
                style={{ width: "100%" }}
                min={ele.min ? ele.min : null}
                max={ele.max ? ele.max : null}
                disabled={ele.disabled ? true : false}
                placeholder={ele.placeholder ? ele.placeholder : ""}
                onKeyPress={(event: any) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                addonBefore={ele.addonBefore ? ele.addonBefore : null}
                onChange={ele.onChange ? (e) => ele.onChange(e) : () => {}}
              />
            ) : ele?.type === "Cascader" ? (
              <Cascader
                className={"capitalize"}
                options={ele.data}
                // showSearch={{ filterCascader }}
                {...(ele.multiple ? { multiple: true } : {})}
                showSearch={true}
                placeholder="Please select"
              />
            ) : ele?.type === "Hidden" ? (
              <></>
            ) : ele?.type === "Radio" ? (
              <Radio.Group buttonStyle="solid">
                {ele?.options?.map((val: any) => {
                  return (
                    <Radio.Button
                      className={val?.name?.toLowerCase()}
                      value={val?.value}
                    >
                      {val?.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            ) : ele?.type === "RadioRound" ? (
              <Radio.Group
                onChange={ele.onChange ? (e) => ele.onChange(e) : () => {}}
                disabled={ele.disabled ? true : false}
                buttonStyle="solid"
              >
                {ele?.options?.map((val: any) => {
                  return (
                    <Radio
                      className={val?.name?.toLowerCase()}
                      value={val?.value}
                    >
                      {val?.name}
                    </Radio>
                  );
                })}
              </Radio.Group>
            ) : ele?.type === "TagType" ? (
              ele?.options?.map((tag: any, index: any) => {
                return (
                  <Tag
                    key={tag.key}
                    closable
                    onClose={
                      ele.onChange ? () => ele.onChange(tag.value) : () => {}
                    }
                  >
                    {tag.label}
                  </Tag>
                );
              })
            ) : ele?.type === "TagTypeAdd" ? (
              <Button onClick={ele.onClick} size="small" type="dashed">
                + Add new CC
              </Button>
            ) : ele?.type === "TagInput" ? (
              <Input
                type="text"
                size="small"
                style={{ minWidth: 100 }}
                allowClear
                // value={ele.inputValue}
                // defaultValue={''}
                onChange={ele.onChange ? (e) => ele.onChange(e) : () => {}}
                onBlur={
                  ele.onChange
                    ? (e) => {
                        ele.onBlur(e);
                      }
                    : () => {}
                }
                onPressEnter={
                  ele.onChange ? (e) => ele.onPressEnter(e) : () => {}
                }
                autoFocus
              />
            ) : ele?.type === "AutoComplete" ? (
              <AutoComplete
                dataSource={ele.AutoCompleteData}
                onSearch={ele.onSearch}
                onSelect={ele.onSelect}
                placeholder={ele.placeholder}
              />
            ) : ele?.type === "clear_filters" ? (
              <Button onClick={ele.onClick} type={"link"}>
                Clear Filters
              </Button>
            ) : (
              ""
            )}
          </Form.Item>
        </Col>
      ))}
    </>
  );
};
