import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewAppointemnt } from "../../redux/reducers/appointment.slice";
import { fetchDoctorAvailSlots } from "../../redux/reducers/doctor.slice";
import { leadServiceMap } from "../../redux/reducers/leads.slice";
import { RootState } from "../../shared/constants";
import MessageModal from "../../shared/layouts/message-modal.layout";
import { ROUTE_APPOINTMENTS_HISTORY } from "../../shared/routes/admin.routes.constants";
import { ROUTE_CRM_LEAD_DETAILS } from "../../shared/routes/crm.routes.constants";
import { Doctor } from "../../shared/types/doctor.type";
import { dobToAge, getDayFormat } from "../../shared/Utils/utilities";
import DoctorSlots from "./doctor-slots";
var _ = require("lodash");

const { Text, Title } = Typography;

type Props = {
  doctor?: Doctor;
  loading?: string;
  patientDetails?: any | undefined;
  linkType?: any;
  linkDetails?: any;
};

const DoctorAppointmentCard: React.FunctionComponent<Props> = ({
  doctor,
  loading,
  patientDetails,
  linkType,
  linkDetails,
}: Props) => {
  const [bookAppointment, setBookAppointment] = useState(false);
  const [summaryAppointment, setsummaryAppointment] = useState(false as any);
  const [currentYear] = useState(moment().format("YYYYY"));
  const { doctor_avail_slots, slots_loader } = useSelector(
    (state: RootState) => state.doctor || {}
  );
  const [availableSlots, setAvailableSlots] = useState([] as any);
  const dispatch = useDispatch();
  const [appointmentDetails, setAppointmentDetails] = useState("" as any);
  const [selectedDoctor, setSelectedDoctor] = useState("" as any);
  const [showDailog, setShowDailog] = useState(false as any);
  const [oldSlot, setNewSlot] = useState(null as any);

  useEffect(() => {
    if (doctor?.doc_schedule) {
      let data = _.groupBy(doctor?.doc_schedule, "branch");
      setAvailableSlots(data);
    }
  }, [doctor]);

  // const onSubmit = () => {
  //     let _data: any = sessionStorage.getItem("appointment_details")
  //     setAppointmentDetails(JSON.parse(_data))
  //     setsummaryAppointment(true)
  // }
  const onSubmit = (slot: any, procedure: any, branch: any, current: any) => {
    let data: any = {
      source: "web",
      procedure_id: procedure?.procedure_id,
      slot_id: slot?.slot_id,
      slot_time: slot?.s_time,
      procedure_type: procedure?.procedure_name,
      branch_id: branch?.branch_id === undefined ? null : branch?.branch_id,
      fee: branch?.fee,
      slot_date: current,
      external_procedure_id: procedure?.external_procedure_id,
      doctor_id: selectedDoctor?.id,
      patient_id: patientDetails?.id,
    };
    setNewSlot(data);
    setsummaryAppointment(true);
  };

  const getDoctorSlots = async (doctor: any) => {
    setSelectedDoctor(doctor);
    try {
      dispatch(fetchDoctorAvailSlots({ doctor_id: doctor.id }));
      setBookAppointment(true);
    } catch (error) {}
  };

  const bookAppointmentForPatient = () => {
    // let finalData = {
    //     "doctor_id": selectedDoctor?.id,
    //     "branch_id": appointmentDetails?.branch_id,
    //     "patient_id": patientDetails?.id,
    //     "source": appointmentDetails?.source,
    //     "procedure_id": appointmentDetails?.procedure_id,
    //     "slot_id": appointmentDetails?.slot_id,
    // }
    // let data: any = {
    //     "source": "web",
    //     "procedure_id": procedure?.procedure_id,
    //     "slot_id": appointmentDetails?.slot_id,
    //     "slot_time": slot?.s_time,
    //     "procedure_type": procedure?.procedure_name,
    //     "branch_id": branch?.branch_id === undefined ? null : branch?.branch_id,
    //     "fee": branch?.fee,
    //     "slot_date": current,
    //     "external_procedure_id": procedure?.external_procedure_id,
    //     "doctor_id": selectedDoctor?.id,
    //     "patient_id": patientDetails?.id
    // }
    try {
      dispatch(addNewAppointemnt(oldSlot)).then((res: any) => {
        console.log(res);
        if (
          linkType == "lead" &&
          linkDetails.id &&
          res?.payload?.data?.data?.id
        ) {
          dispatch(
            leadServiceMap({
              lead_id: linkDetails.id,
              appointment_id: res?.payload?.data?.data?.id,
            })
          );
        }
      });
      setBookAppointment(false);
      setsummaryAppointment(false);
      setShowDailog(true);

      return;
    } catch (e) {}
  };
  console.log(linkDetails);
  if (loading) {
    return <Skeleton></Skeleton>;
  } else {
    return (
      <Fragment>
        <Col span={24} className="danger-ribbon mt20">
          <p className="primary-color-text">
            Doctor Protocol: <i> 60+ Patients are not allowed </i>
          </p>
        </Col>
        <Card className="mt-10 border-radius">
          <Row className="pd20">
            <Col span={20}>
              <Row gutter={20}>
                <Col span={3}>
                  <Image
                    className="border-radius"
                    preview={false}
                    width="77px"
                    height="77px"
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  />
                </Col>
                <Col span={9}>
                  <Title level={5} className="primary-color-text">
                    Dr. {doctor?.name}
                  </Title>
                  <Text type="secondary">{doctor?.specialities}</Text> <br />
                  <Text type="secondary">
                    Exp:{" "}
                    {parseInt(currentYear) - parseInt(doctor?.practicing_since)}
                    + yrs | Designation: Pending
                  </Text>
                </Col>
                <Col span={12}>
                  {doctor?.consultation_slots && doctor?.vc_slots && (
                    <Title level={5} className="primary-color-text">
                      Next Available
                    </Title>
                  )}
                  <p>
                    <Space>
                      {doctor?.consultation_slots && (
                        <Tag
                          icon={
                            <img
                              src={
                                require("../../assets/icons/inperson green.png")
                                  .default
                              }
                              style={{
                                width: "11%",
                                marginRight: "5px",
                                marginTop: "-3px",
                              }}
                            ></img>
                          }
                          color="#F7F8FA"
                          className="primary-color-text"
                        >
                          {doctor?.consultation_slots?.branch}:{" "}
                          {getDayFormat(doctor?.consultation_slots?.date, true)}{" "}
                          {doctor?.consultation_slots?.slot_time}
                        </Tag>
                      )}

                      {doctor?.vc_slots && (
                        <Tag
                          icon={
                            <img
                              src={
                                require("../../assets/icons/VC blue.png")
                                  .default
                              }
                              style={{
                                width: "15%",
                                marginRight: "5px",
                                marginTop: "-2px",
                              }}
                            ></img>
                          }
                          color="#F7F8FA"
                          className="primary-color-text"
                        >
                          {getDayFormat(doctor?.vc_slots?.date, true)}{" "}
                          {doctor?.vc_slots?.slot_time}
                        </Tag>
                      )}
                    </Space>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col span={4} className="text-right">
              <Button
                type="primary"
                className="mt20"
                onClick={() => getDoctorSlots(doctor)}
              >
                Book Appointment
              </Button>
            </Col>
            {doctor?.doc_schedule && (
              <Col span={24}>
                <Divider></Divider>
              </Col>
            )}

            <Col span={24}>
              {doctor?.doc_schedule && (
                <Title level={5} className="primary-color-text">
                  Schedule for Today
                </Title>
              )}
              <Space>
                {Object.keys(availableSlots).map((schedule: any) => {
                  let _doc_schedule: any = Object.values(
                    availableSlots[schedule]
                  );
                  return (
                    <div className="grey-background padding20">
                      <p className="primary-color-text">
                        {schedule}{" "}
                        <small className="sub-title">
                          <i>(Room no Pending)</i>
                        </small>
                      </p>
                      <p>
                        <Space split={<Divider type="vertical"></Divider>}>
                          {_doc_schedule.map((time: any) => {
                            return (
                              <Tag
                                icon={
                                  time === "Consultation" ? (
                                    <img
                                      src={
                                        require("../../assets/icons/inperson green.png")
                                          .default
                                      }
                                      style={{
                                        width: "11%",
                                        marginRight: "5px",
                                        marginTop: "-3px",
                                      }}
                                    ></img>
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/icons/VC blue.png")
                                          .default
                                      }
                                      style={{
                                        width: "15%",
                                        marginRight: "5px",
                                        marginTop: "-2px",
                                      }}
                                    ></img>
                                  )
                                }
                                color="#F7F8FA"
                                className="primary-color-text nopadding"
                              >
                                Today{" "}
                                {moment(time.start_time, ["h:mm A"]).format(
                                  "hh:mm, a"
                                )}
                              </Tag>
                            );
                          })}
                        </Space>
                      </p>
                    </div>
                  );
                })}
              </Space>
            </Col>
          </Row>
        </Card>
        <Modal
          width={741}
          title="Choose Your Appointment Slot"
          centered
          className="border-radius"
          onCancel={() => setBookAppointment(false)}
          visible={bookAppointment}
          footer={false}
        >
          <DoctorSlots
            slots={doctor_avail_slots}
            slots_loader={slots_loader}
            onSubmit={(a, b, c, d) => onSubmit(a, b, c, d)}
          ></DoctorSlots>
        </Modal>

        <Modal
          width={741}
          title="Appointment Summary"
          centered
          className="appointment-summary border-radius grey-background"
          onCancel={() => setsummaryAppointment(false)}
          visible={summaryAppointment}
          footer={false}
        >
          <Col span={24} style={{ marginTop: "-60px" }}>
            <Card className="white-background border-radius noborder">
              <Row gutter={20}>
                <Col span={3}>
                  <Image
                    className="border-radius"
                    preview={false}
                    width="47px"
                    height="47px"
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  />
                </Col>
                <Col span={20} className="nopadding">
                  <Title level={5} className="primary-color-text">
                    Dr. {doctor?.name}
                  </Title>
                  <Text type="secondary">{doctor?.specialities}</Text> <br />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} style={{ height: "60px" }}>
            <p className="primary-color-text mt10 bold">Appointment Details</p>
          </Col>

          <Row className="white-background nomargin padding24 mt50">
            <Col span={10}>
              <small className="sub-text">1. Patient</small>
              <h3
                className="primary-color-text nopadding"
                style={{ marginBottom: "0px" }}
              >
                {patientDetails?.pii?.first_name}{" "}
                {patientDetails?.pii?.last_name}{" "}
                <small className="sub-text"></small>{" "}
                <small className="sub-text">
                  {dobToAge(patientDetails?.dob)}yrs {patientDetails?.gender}
                </small>{" "}
              </h3>
              <small className="sub-text">{patientDetails?.pii?.mobile}</small>
              <Tag color="#DCF2F1" className="padding20 sub-color-text mt10 ">
                Video call will be enabled on this number
              </Tag>{" "}
              <br />
              <p className="mt10">
                {" "}
                <Text className="sub-color-text cursor font12" underline>
                  + Change Patient
                </Text>
              </p>
            </Col>
            <Col span={2}>
              <Divider type="vertical" style={{ height: "150px" }}></Divider>
            </Col>
            <Col span={10}>
              <small className="sub-text">2. Appointment Type</small>
              <h3
                className="primary-color-text nopadding"
                style={{ marginBottom: "0px" }}
              >
                {appointmentDetails?.procedure_type}{" "}
              </h3>
              <small className="sub-text">
                Wed,{" "}
                {moment(appointmentDetails?.current).format("Do, MMM YYYY")} |{" "}
                {appointmentDetails?.slot_time}, Fee: ₹{appointmentDetails?.fee}
              </small>
              <p className="mt10">
                {" "}
                <Text className="sub-color-text cursor font12" underline>
                  Add Attendant for Video Call
                </Text>
              </p>
            </Col>
          </Row>

          <div className="white-background nomargin  mt50">
            <Row className="padding24 mt50">
              <Col span={12}>
                <p className="primary-color-text mt10 bold">
                  Consultation Fees
                </p>
              </Col>
              <Col span={12} className="text-right">
                <p className="primary-color-text mt10 bold">
                  Rs. ₹{appointmentDetails?.fee}
                </p>
              </Col>

              <Col span={24}>
                <p className="primary-color-text mt10 bold">
                  Apply Coupon Code
                </p>
              </Col>
              <Col span={10}>
                <Input
                  suffix={
                    <Text className="sub-color-text cursor font12" underline>
                      Apply
                    </Text>
                  }
                ></Input>
              </Col>
              <Col span={24} className="mt10">
                <Text className="sub-color-text cursor font12" underline>
                  + Add Note
                </Text>
              </Col>
              <Col span={24} className="mt10">
                <Row className="border-radius secondary-color-background-light padding20">
                  <Col span={12}>
                    <p className="primary-color-text mt10 bold">
                      Consultation Fees
                    </p>
                  </Col>
                  <Col span={12} className="text-right">
                    <p className="primary-color-text mt10 bold">
                      Rs. ₹{appointmentDetails?.fee}
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col span={24} className="mt20">
                <Checkbox>
                  I have informed patient to download Patient App
                </Checkbox>
              </Col>
              <Col span={24} className="mt10">
                <Checkbox>
                  I have informed patient regarding auto check in Feature to
                  skip queue
                </Checkbox>
              </Col>
              <Button
                block
                className="mt20"
                size="large"
                type="primary"
                onClick={() => bookAppointmentForPatient()}
              >
                Book Appointment{" "}
              </Button>
            </Row>
          </div>
        </Modal>

        {showDailog && (
          <MessageModal
            visible={showDailog}
            message="Payment Link Sent"
            sub_heading="Payment link has been sent on SMS"
            type="Success"
            route={
              linkType == "lead"
                ? ROUTE_CRM_LEAD_DETAILS(linkDetails.id)
                : ROUTE_APPOINTMENTS_HISTORY
            }
          />
        )}
      </Fragment>
    );
  }
};

export default DoctorAppointmentCard;
