import { FbClient } from "./fb-client";
const token =
  "EAASrtb49lysBAPBJ2v55jumEzy5h8vhCzdvJB66Oq4cmTlZCM1eTAqrZBUmIcyV5g1GSRrj2DsFK2KWRIrpz4RjIIBXtoiOv1LZCnGJZBCu6qBoY0PIvFwKVIKhqCnJM5W6q8SsHj2K0wymfM3VSkKUvXZCZBkYAUXJjPr6h6DwNG4KslhBFUv";
const pagetoken =
  "EAASrtb49lysBAJ2ijg4QeJ5tERyhGIM1cJfdrssJNsX8S9JDDsZBkA4XofOIl7RJeBqskhgLRc0AVk0fUHbtsO5rQQBg3U8y0GAbmUzW3KWTUVXjrHpqqjN2QT7J0WBSOV0TCuFLZALyC28lPDZAes1OkkVygQl8ec4vCmgAIdyAiTa0wnrdx3EnPR7lCMZD";
export const getAllPosts = async (id: string) => {
  return await FbClient.get(`https://graph.facebook.com/v15.0/${id}/feed`, {
    params: {
      access_token: token,
      fields: "actions,attachments",
    },
  });
};

export const getPostInsights = async (params: any) => {
  FbClient.get("https://graph.facebook.com/v15.0/me", {
    params,
  }).then((res: any) => {});
};

export const getPageAccounts = async (pageId: string, params: any = {}) => {
  let ret: any = [];
  try {
    const data = await FbClient.get(
      `https://graph.facebook.com/v15.0/${pageId}`,
      {
        params: {
          ...params,
          access_token: pagetoken,
          fields: "name,instagram_business_account{id,ig_id,name}",
        },
      }
    );
    if (data?.data?.id) {
      ret.push({
        id: data.data.id,
        name: data.data.name,
        type: "facebook",
      });
    }

    if (data?.data?.instagram_business_account?.id) {
      ret.push({
        id: data.data.instagram_business_account.id,
        name: data.data.instagram_business_account.name,
        type: "instagram",
      });
    }
  } catch (e) {}
  return ret;
};

export const newFacebookPost = async (
  pageId: string,
  payload: any,
  params: any = {}
) => {
  let ret: any = [];
  try {
    const data = await FbClient.post(
      `https://graph.facebook.com/v15.0/${pageId}/feed?access_token=${pagetoken}`,
      payload,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (data?.data?.id) {
      ret = true;
    } else {
      ret = false;
    }
  } catch (e) {}
  return ret;
};
export const newInstagramPost = async (
  pageId: string,
  payload: any,
  params: any = {}
) => {
  let ret: any = [];
  try {
    const data = await FbClient.post(
      `https://graph.facebook.com/v15.0/${pageId}/feed`,
      payload,
      {
        params: {
          ...params,
          access_token: pagetoken,
        },
      }
    );
    // debugger;
  } catch (e) {}
  return ret;
};
