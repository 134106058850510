import {
  InfoCircleOutlined
} from '@ant-design/icons';
import { Card, Col, Modal, Popover, Row, Tag } from "antd";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { fetchAppointmentById, resetAppointments } from "../../../../redux/reducers/appointment.slice";
import { fetchDoctorById, resetDoctor } from "../../../../redux/reducers/doctor.slice";
import { fetchOrderDetailsByID } from "../../../../redux/reducers/pharmacy.slice";
import { fetchHealthProfileDetails, fetchUploadedFileByIds } from "../../../../redux/reducers/tickets.slice";
import {
  formatDate,
  formatDropDownValue,
  metaValues
} from "../../../../shared/Utils/utilities";
import { TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD, TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA, TASK_MODULE_ACTIONS_HIDE_META_TICKETS } from "../../../../shared/components/permission.constants";
import { ACCOUNT_PREFIX, IS_JARVIS, RootState } from "../../../../shared/constants";
import AccentCard from "../../../../shared/layouts/crm-accentcard.layout";
import ListCard from "../../../../shared/layouts/crm-listcard.layout";
import FileViewLayout from "../../../../shared/layouts/file-view.layout";
import TabsLayout from "../../../../shared/layouts/tabs.layout";
import ActivityHistoryTab from "../../components/activityhistorytab";
import AssociatedLeads from "../../components/associatedleadstab";
import TabTitle from "../../components/tabtitle";
import TicketsTab from "../../components/ticketstab";
import TransactionsTab from "../../components/transactionstab";
import TicketDetailsGeneralTab from "./ticketdetailstab-general";

type Props = {
  ticket: any;
  actionmapData?: any;
};

function GeneralTicket({ ticket, actionmapData = {} }: Props) {
  const [doctorData, setDoctorData] = useState(false as any);
  const [patientData, setPatientData] = useState(false as any);
  const { appointment } = useSelector((state: RootState) => state.appointment);
  const { doctor } = useSelector((state: RootState) => state.doctor);

  const [orderDetails, setorderDetails] = useState(null as any)
  const [emailDataState, setemailDataState] = useState(null as any)

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  const [metaDetailsTag, setmetaDetailsTag] = useState(null as any)

  const [npsDetailsAll, setnpsDetailsAll] = useState(null as any)
  const [npsDetailstag, setnpsDetailstag] = useState([] as any)
  const [attachmentsDataAll, setattachmentsDataAll] = useState([] as any)
  const dispatch = useDispatch();
  const [labId, setlabId] = useState(null as any)
  const { t } = useTranslation()
  const [healthDetails, sethealthDetails] = useState(null as any)
  const [tagLength, settagLength] = useState(2)
  let phone = "";
  if (ticket?.contact) {
    phone = ticket?.contact.phone;
  }

  useEffect(() => {
    return () => {
      dispatch(resetAppointments())
      dispatch(resetDoctor())
    }
  }, [])

  var allTabs = [];
  const npsDetails = (val: any) => {
    setlabId(val?.labbillid)
    setnpsDetailsAll(val.data)

  }
  useEffect(() => {
    labId && dispatch(fetchHealthProfileDetails({ labbillid: labId })).then((val: any) => {
      sethealthDetails(val.payload.data.data)
    })
  }, [labId])

  useEffect(() => {
    // only for PATHOLOGY
    let npsDetailsArr: any = npsDetailsAll &&
      npsDetailsAll?.map((val: any) => {
        return (
          {
            title: typeof val.question === 'string' && val?.question?.length > 15 ? <>
              {val?.question.substring(0, 15)}
              {val?.question?.length > 15 && (
                <span>
                  <Popover placement="right" content={val.question}>
                    {" ..."}
                  </Popover>
                </span>
              )}
            </> : val.question ? val.question : "--",
            text: typeof val.answer === 'string' && val?.answer?.length > 10 ? <>
              {val?.answer.substring(0, 10)}
              {val?.answer?.length > 10 && (
                <span>
                  <Popover placement="right" content={val.answer}>
                    {" "} <InfoCircleOutlined />
                  </Popover>
                </span>
              )}
            </> : val.answer ? val.answer : "--",
            type: "text",
          }
        )
      })
    setnpsDetailstag(npsDetailsArr)
  }, [npsDetailsAll])



  allTabs = [
    {
      title: <TabTitle title={`${t("CRM.Tickets.Fields.Ticket")} Details`} />,
      component: (
        <TicketDetailsGeneralTab
          ticket={ticket}
          actionmapData={actionmapData}
          npsDetailss={npsDetails}

        />
      ),
    },
    ...((myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD?.split(".")[0]) !== -1)
      ? [{
        title: <TabTitle title="Associated Leads" />,
        component: <AssociatedLeads phone={phone} />,
      }] : []
    ),

    {
      title: <TabTitle title={`Associated ${t("CRM.Tickets.Fields.Tickets")} `} />,
      component: <TicketsTab excludeTicketId={ticket.id} phone={phone} />,
    },
    ...((myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA?.split(".")[0]) !== -1)
      ? [{
        title: <TabTitle title="Transactions" />,
        component: <TransactionsTab phone={phone} />,
      }] : []
    ),

    // { title: <TabTitle title="Promotional Communication" />, component: <PromotionalCommunicationTab /> },
    {
      title: <TabTitle title="Activity History" />,
      component: <ActivityHistoryTab phone={phone} cols={4} />,
    },
  ];


  let app_details: any = ticket?.services[0]?.appointment_id
    ? [
      {
        title: "Doctor",
        text:
          doctor && doctor?.name,
        type: "text",
      },
      {
        title: "Slot",
        text: appointment
          ? formatDate(
            appointment?.appointment_date,
            "DD MMM'YY"
          ) +
          " " +
          appointment?.start_time
          : "",
        type: "text",
      },
      {
        title: "Location",
        text:
          appointment?.branch?.name,
        type: "text",
      },
      {
        title: "Booked on",
        text:
          appointment &&
          formatDate(appointment?.created_at),
        type: "text",
      },
      {
        title: "Appointment Status",
        text: (
          <Tag className="solid" color="blue">
            {appointment &&
              appointment?.latest_status}
          </Tag>
        ),
        type: "text",
      },
    ]
    : null;

  if (
    ticket.services[0]?.appointment_id &&
    ticket.services[0]?.meta?.slot_date &&
    ticket.services[0]?.meta?.slot_time
  ) {
    app_details.push({
      title: "Requested Slot",
      text:
        formatDate(ticket.services[0]?.meta?.slot_date, "DD MMM'YY") +
        " " +
        ticket.services[0]?.meta?.slot_time,
      type: "text",
    });
  }
  // only for PATHOLOGY
  let healthCard_details: any = healthDetails ?
    [{
      title: "Visit Type",
      text: healthDetails?.visittype,
      type: "text",
    },
    {
      title: "Last Visit Date",
      text: healthDetails?.lastvisitdate ? formatDate(healthDetails?.lastvisitdate) : '',
      type: "text",
    },
    {
      title: "Bill ID:",
      text: healthDetails?.labbillid,
      type: "text",
    },
    {
      title: "Doctor",
      text: healthDetails?.entitydoctorname,
      type: "text",
    },
    {
      title: "Department",
      text: formatDropDownValue(healthDetails?.departments),
      type: "text",
    },
    {
      title: "Unit",
      text: healthDetails?.unit,
      type: "text",
    },
    {
      title: "Channel",
      text: healthDetails?.channels,
      type: "text",
    },
    {
      title: "Health Tags",
      text: <>
        {healthDetails?.tags?.map((val: any, index: any) => {
          return (
            index < tagLength ?
              <Tag>{val}</Tag> : ''
          )
        })}
        {tagLength === 2 && healthDetails?.tags &&
          <Tag
            onClick={() => { settagLength(healthDetails?.tags.length) }}
            style={{ cursor: 'pointer', color: '#37C2D8', borderColor: '#37C2D8', borderRadius: '6px' }}>
            {healthDetails?.tags?.length - 2} {"+"}
          </Tag>
        }    </>
      ,
      type: "text",
    },

    ] : null;
  let order_details: any =
    ticket.services[0]?.order_id &&
      formatDropDownValue(ticket?.sub_type?.name) === "PATHOLOGY"
      ? [
        {
          title: "Order ID",
          text: `#${orderDetails?.order_id}`,
          type: "text",
        },
        {
          title: "Order Date",
          text: formatDate(orderDetails?.order_date),
          type: "text",
        },
        {
          title: "Address",
          text: orderDetails?.address,
          type: "text",
        },
        {
          title: "Collection Time",
          text:
            formatDate(
              orderDetails?.service_date.split("T")[0],
              "DD MMM'YY"
            ) +
            " " +
            orderDetails?.slot,
          type: "text",
        },
        {
          title: "Collection At",
          text: orderDetails?.collection_at,
          type: "text",
        },
        {
          title: "Status",
          text: (
            <>
              {orderDetails?.order_display_status && (
                <Tag color="blue">
                  {formatDropDownValue(
                    orderDetails?.order_display_status
                  )}
                </Tag>
              )}{" "}
              <Tag color="blue">
                {formatDropDownValue(orderDetails?.order_status)}
              </Tag>
            </>
          ),
          type: "text",
        },
        {
          title: "",
          text: (
            <small className={"text-secondary"}>
              {formatDate(
                orderDetails?.last_status_date
                  ? orderDetails?.last_status_date
                  : orderDetails?.order_date
              )}
            </small>
          ),
          type: "text",
        },
      ]
      : null;



  // useEffect(() => {
  //   ticket && ticket?.services?.length > 0 && ticket?.services[0]?.appointment_id && dispatch(fetchAppointmentById(ticket?.services[0]?.appointment_id))
  // }, [ticket])



  useEffect(() => {
    ticket?.services && ticket?.services[0]?.doctor_id && dispatch(fetchDoctorById(ticket?.services[0]?.doctor_id))
    ticket?.services && ticket?.services[0]?.appointment_id && dispatch(fetchAppointmentById(ticket?.services[0]?.appointment_id))
    ticket?.services && ticket?.services[0]?.order_id && dispatch(fetchOrderDetailsByID(ticket?.services[0]?.order_id)).then((val: any) => {
      setorderDetails(val.payload.data.data)
    })
  }, [ticket])

  const [activeFile, setActiveFile] = useState(null as any);
  const [fileUrl, setfileUrl] = useState(null as any);
  const [fileviewer, setFileViewer] = useState(false as any);
  const [fileExtention, setfileExtention] = useState(null as any)




  function viewDocuments(id: any) {
    setActiveFile(id);
    getFileUrl(id);
    setFileViewer(true);
  }

  function getFileUrl(id: any) {
    setfileUrl(null);
    dispatch(
      fetchUploadedFileByIds(id)
    ).then((val: any) => {
      // debugger
      if (val?.payload?.data?.statusCode == 200) {
        const fileExtensions = val?.payload?.data?.data?.name?.split('.').pop();
        setfileUrl(val?.payload?.data?.data);
        setfileExtention(fileExtensions)
      }
    });
  }

  console.log("sssssssssssssss", IS_JARVIS)

  function removeArrays(obj: any) {
    const newObj = {} as any;
    for (const key in obj) {
      if (!Array.isArray(obj[key])) {
        if (typeof obj[key] === 'object') {
          newObj[key] = removeArrays(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  }

  useEffect(() => {
    // debugger
    let emailData = {
      title: 'Email',
      service: [
        ...(ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.date ? [{
          title: 'Date',
          text: ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.date && formatDate(ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.date),
          type: "text"
        }] : []),
        ...(ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.from ? [{
          title: 'From',
          text: ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.from,
          type: "text"
        }] : []),
        ...(ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.to ? [{
          title: 'To',
          text: ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.to,
          type: "text"
        }] : []),
        ...(ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.cc ? [{
          title: 'CC',
          text: ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.cc?.split(";")?.map((val: any) => {
            return <>{val} <br /></>;
          }),
          type: "text"
        }] : []),
        ...(ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.subject ? [{
          title: 'Subject',
          text: ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.subject,
          type: "text"
        }] : []),
        ...(ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.msg_html ? [{
          title: 'Description',
          text: ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.msg_html,
          type: "email"
        }] : []),
        ...(ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.attachments ? [{
          title: 'Attachment',
          text: (<>
            {
              ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.attachments?.map((val: any) => {
                return (
                  <>
                    <b><p><a href="#" onClick={() => { viewDocuments(val.id) }} >View Attachment</a></p></b>
                  </>
                )
              })
            }
          </>),
          type: "text"
        }] : []),
      ]
    }
    let doctorData1: any, patientData1: any;
    let meta: any = null;
    meta = removeArrays(ticket?.meta);
    const type = ticket?.type?.name;
    const subtype = ticket?.sub_type?.name;
    const category = ticket?.category?.name;

    let cardType = "Thb-pharma-doctor-bot";
    if (
      type == "doctor_enquiry" &&
      subtype == "multiple_services" &&
      category == "general"
    ) {
      cardType = "doctor_enquiry";
    }
    if (meta) {
      doctorData1 = {
        name: meta?.doctorObj?.doctorname || meta?.doctorObj?.Name || null,
        speciality: meta?.doctorObj?.doctorspeciality || meta?.doctorObj?.Specialization || null,
        location: meta?.doctorObj?.doctorunit || meta?.doctorObj?.City || null,
        email:
          meta?.doctorObj?.doctoremail &&
            meta?.doctorObj?.doctoremail !== "null" || meta?.doctorObj?.EmailId
            ? meta?.doctorObj?.doctoremail || meta?.doctorObj?.EmailId
            : null,
        phone: meta?.doctorObj?.doctorNumber ? "+" + meta?.doctorObj?.doctorNumber : null,
        doctorid: meta?.doctorObj?.doctorid || null,
      };

      // }
      if (cardType == "doctor_enquiry") {
        patientData1 = {
          title: "Doctor Enquiry",
          service: meta && Object.entries(meta).flatMap(([key, value]) => {

            if (typeof value === 'object' && value) {
              return Object.entries(value).map(([keys, values]) => {
                if (metaValues?.find(es => es.value == keys && es.show === true) && values) {
                  return {
                    title: metaValues?.find(es => es.value == keys)?.label || keys,
                    text: values,
                    type: "text"
                  }
                }

              })
            }
            else {
              if (metaValues?.find(es => es.value == key && es.show === true) && value) {
                if (key === 'referedAt') {
                  if (ACCOUNT_PREFIX === 'regency') {
                    return {
                      title: "Raised at",
                      text: moment.utc(value).utcOffset('+05:30').format("DD MMM'YY, hh:mm A"),
                      type: "text",
                    };
                  }
                  else {
                    return {
                      title:
                        metaValues?.find((es) => es.value == key)?.label || key,
                      text: moment.utc(value).utcOffset('+05:30').format("DD MMM'YY, hh:mm A"),
                      type: "text",
                    };
                  }

                }
                else {
                  return {
                    title: metaValues?.find(es => es.value == key)?.label || key,
                    text: value,
                    type: "text"
                  }
                }

              }

            }
          })
        };
      }

      // setLinkedServices([doctorData, patientData]);
      setDoctorData(Object.values(doctorData1).some(value => value !== null) ? doctorData1 : false);
      setPatientData(patientData1?.service?.length ? patientData1 : false);
    }
    setemailDataState(emailData)
    // debugger


    let metaDetails = {
      title: "Details",
      service: [] as any,
    };



    const firstData = (
      Object.keys(meta).length &&
      Object.entries(meta).flatMap(([key, value]) => {
        if (typeof value === "object" && value) {
          return Object.entries(value).flatMap(([keys, values]) => {
            // if (
            //   metaValues?.find((es) => es.value == keys && es.show === true) &&
            //   values
            // ) {
            //   return {
            //     title: (metaValues?.find((es) => es.value == keys)?.label || keys),
            //     text: values,
            //     type: "text",
            //   };
            // } else {
            //   return null;
            // }
            return {
              title: (metaValues?.find((es) => es.value == keys)?.label || keys),
              text: values,
              type: "text",
            };
          });
        } else {
          // if (
          //   metaValues?.find((es) => es.value == key && es.show === true) &&
          //   value
          // ) {
          //   return {
          //     title: (metaValues?.find((es) => es.value == key)?.label || key),
          //     text: typeof value === 'string' && value?.length > 10 ? (
          //       <>
          //         {value.substring(0, 10)}
          //         {value?.length > 10 && (
          //           <span>
          //             <Popover placement="right" content={value}>
          //               <InfoCircleOutlined />
          //             </Popover>
          //           </span>
          //         )}
          //       </>
          //     ) : value,
          //     type: "text",
          //   };
          // } else {
          //   return null;
          // }
          return {
            title: (metaValues?.find((es) => es.value == key)?.label || key),
            text: typeof value === 'string' && value?.length > 10 ? (
              <>
                {value.substring(0, 10)}
                {value?.length > 10 && (
                  <span>
                    <Popover placement="right" content={value}>
                      <InfoCircleOutlined />
                    </Popover>
                  </span>
                )}
              </>
            ) : value,
            type: "text",
          };
        }
      }).filter(Boolean) // Filter out null or undefined elements
    ) || [];



    // Extracting data from the second part (ticket properties)
    const secondData = (
      // ticket?.type && ticket?.type?.name.includes('incentive_') &&
      [
        ...(ticket?.type && ticket?.type?.name.includes('incentive_') ? [{
          title: "Cluster",
          text: ticket?.branch?.name ? ticket?.branch?.name : "-",
          type: "text",
        }] : []),
        // ...(ticket?.type && ticket?.type?.name.includes('incentive_') ? []: []),
        ...(ticket?.type && ticket?.type?.name.includes('incentive_') ? [{
          title: "Division",
          text: ticket?.contact?.therapy_field ? ticket?.contact?.therapy_field : "--",
          type: "text",
        },] : []),
        ...(ticket?.type && ticket?.type?.name.includes('incentive_') ? [{
          title: "Designation",
          text: ticket?.contact?.revised_unique_role ? ticket?.contact?.revised_unique_role : "--",
          type: "text",
        },] : []),
        ...(ticket?.type && ticket?.type?.name.includes('incentive_') ? [{
          title: "Award",
          text: ticket?.type?.name ? ticket?.type?.name : "--",
          type: "text",
        },] : []),
        ...(ticket?.type && ticket?.type?.name.includes('incentive_') ? [{
          title: "Time Period",
          text: ticket?.meta?.ibl_month ? ticket?.meta?.ibl_month : "--",
          type: "text",
        },] : []),
        ...(ACCOUNT_PREFIX === 'ciplanew' ? [{
          title: 'Attachment(s)',
          text: ticket?.files?.length > 0 ?
            ticket?.files?.map((val: any, index: any) => {
              return (
                <>
                  <p className="mb-2">
                    <a
                      href={val?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>View: </b> {" "}
                      {val?.file?.file_name}
                    </a>
                  </p>

                </>
              )
            })
            : (
              "--"
            ),
          type: "text"
        },] : []),


      ]
    ) || [];

    // Combining both sets of data into the 'service' property of metaDetails
    metaDetails.service = [...((myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_META_TICKETS) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_META_TICKETS?.split(".")[0]) !== -1) ? [] : [...firstData]), ...secondData];



    const aa = metaDetails?.service?.filter((item: any) => item && item?.title)
    setmetaDetailsTag(aa)
    // (myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_DETAILS_CARD_TICKETS) !== -1 ||
    //   myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_DETAILS_CARD_TICKETS?.split(".")[0]) !== -1)



    let attachmentData = [{
      title: 'Attachment(s)',
      text: ticket?.files?.length > 0 ?
        ticket?.files?.map((val: any, index: any) => {
          return (
            <>
              <p className="mb-2">
                <a
                  href={val?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>View: </b> {" "}
                  {val?.file?.file_name}
                </a>
              </p>

            </>
          )
        })
        : (
          "--"
        ),
      type: "text"
    }]
    setattachmentsDataAll(attachmentData || [])
  }, [ticket]);





  console.log("doctorData", doctorData)
  return (
    <div className="pd20  details-page-area">
      <Row gutter={16}>
        <Col
          className="gutter-row"
          {...{ xs: 24, sm: 24, md: 7, lg: 7, xl: 7 }}
        >
          {/* <AccentCard type='contact' details={ticket}></AccentCard> */}
          {!doctorData && (
            <AccentCard
              type="contact"
              details={ticket}
              isJarvis={IS_JARVIS}
              callData={{ source: "ticket", lead_id: ticket?.id }}
            ></AccentCard>
          )}
          {doctorData && (
            <AccentCard
              type="doctor"
              details={doctorData}
              callData={{ source: "ticket", lead_id: ticket?.id }}
            ></AccentCard>
          )}
          {emailDataState && ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data && <ListCard
            details={emailDataState.service}
            title={emailDataState.title}

          ></ListCard>}
          {patientData?.service?.length && (
            <ListCard
              details={patientData.service?.filter((e: any) => e !== undefined)}
              title={patientData.title}
              showMoreBtn={true}
            ></ListCard>
          )}
          {app_details && (
            <ListCard
              details={app_details}
              title={"Appointment Details"}
            ></ListCard>
          )}
          {order_details && (
            <ListCard
              details={order_details?.filter((e: any) => e !== undefined)}
              title={"Order Details"}
            ></ListCard>
          )}
          {healthCard_details && (
            <ListCard
              details={healthCard_details}
              title={"Health Profile"}
            ></ListCard>
          )}
          {
            metaDetailsTag?.length > 0 && (
              <ListCard
                details={metaDetailsTag}
                title={"Details"}
              ></ListCard>
            )
          }
          {
            (attachmentsDataAll?.length > 0 && IS_JARVIS) && (
              <ListCard
                details={attachmentsDataAll}
                title={"Attachments"}
              ></ListCard>
            )
          }
          {/* {
            npsDetailstag?.length && (
              <ListCard
                details={npsDetailstag}
                title={"NPS Details"}
              ></ListCard>
            )
          } */}
        </Col>
        <Col
          className="gutter-row"
          {...{ xs: 24, sm: 24, md: 17, lg: 17, xl: 17 }}
        >
          <Card className="nopaddingcard crm-tabs ticket-details-tabs">
            <TabsLayout AllTabs={allTabs}></TabsLayout>
          </Card>
        </Col>

      </Row>

      <Modal
        centered
        footer={false}
        visible={fileviewer}
        onCancel={() => setFileViewer(false)}
      >
        <Row className="crm-app" style={{ backgroundColor: "#FFFFFF" }}>
          <Col
            span={24}
            style={{ maxHeight: "calc(100vh - 20px)", overflow: "auto" }}
          >
            <Row>
              <Col span={24} className={"padding10"}>
                {/* { activeFile && !fileUrl && <FileViewLayout modal={false} details={activeFile} fileviewer={true} total={0} file={{result: activeFile.result }}></FileViewLayout> } */}
                {fileUrl && (
                  <FileViewLayout
                    modal={false}
                    details={activeFile}
                    fileviewer={true}
                    total={0}
                    file={{ result: fileUrl.url }}
                    fileExtension={fileExtention}
                  ></FileViewLayout>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

    </div>
  );
}

export default GeneralTicket;
