import { Card, Col, message, PageHeader, Row } from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  facebookGetPagesByProfileId,
  facebookGetProfile,
} from "../../../redux/reducers/social.slice";
import { RootState } from "../../../shared/constants";
import FormBar from "../../../shared/layouts/formbar.layout";
import {
  getPageAccounts,
  newFacebookPost,
} from "../../../shared/Utils/fb-utils";
declare global {
  interface Window {
    FB: any;
  }
}
function SocialNewPostPage() {
  const dispatch = useDispatch();
  const accountId = localStorage.getItem("account_id");
  const { profileGetLoading, profileData } = useSelector(
    (state: RootState) => state.social
  );
  const [pages, setPages] = useState([] as any);
  const [selectedPage, setSelectedPage] = useState(null as any);
  const [accountsList, setAccountsList] = useState(null as any);
  const onFinish = async (values: any) => {
    console.log(values);
    const post_to = values.post_to;
    const accountToPost = accountsList.filter((val: any) => {
      return post_to.indexOf(val.id) !== -1;
    });
    for (let k = 0; k < accountToPost.length; ++k) {
      if (accountToPost[k].type === "facebook") {
        const res = await newFacebookPost(selectedPage.page_id, {
          message: values.message,
        });
        message.success("Post added to Facebook successfully...");
      }
      //   if (accountToPost[k].type === "instagram") {
      //     await newInstagramPost(selectedPage.page_id, {
      //       message: values.message,
      //     });
      //   }
    }
  };
  const getFacebookProfile = () => {
    dispatch(facebookGetProfile(accountId));
  };

  useEffect(() => {
    getFacebookProfile();
  }, []);

  useEffect(() => {
    if (profileData != null) {
      dispatch(facebookGetPagesByProfileId(profileData?.fb_id)).then(
        (val: any) => {
          if (val?.payload?.data?.data?.length > 0) {
            setPages(val.payload.data.data);
            setSelectedPage(val.payload.data.data[0]);
          }
        }
      );
    }
  }, [profileData]);

  const pageSelected = (page: any) => {
    setSelectedPage(page);
  };
  useEffect(() => {
    if (selectedPage) {
      getPageObjects();
    }
  }, [selectedPage]);
  const getPageObjects = async () => {
    const fbAccounts = await getPageAccounts(selectedPage.page_id);
    setAccountsList(fbAccounts);
    // debugger;
  };
  const formData = {
    name: "form",
    onFinish: onFinish,
    onFinishFailed: () => {},
    initialValues: {
      publish_type: "ínstant",
    },
    buttonText: "Save",
    formElements: [
      {
        label: "Post to",
        type: "Select",
        name: "post_to",
        colSpan: 16,
        mode: "multiple",
        options: accountsList?.map((val: any) => {
          return {
            value: val.id,
            name: (
              <>
                <img
                  src={
                    require("../../../assets/icons/" + val.type + "-logo.png")
                      .default
                  }
                  style={{ width: "20px" }}
                />{" "}
                {val.name}
              </>
            ),
          };
        }),
        rules: [{ required: true, message: "Please select post to" }],
      },
      {
        label: "Post text",
        type: "TextArea",
        name: "message",
        colSpan: 24,
        rows: 10,
        rules: [{ required: true, message: "Please select post text" }],
      },
      {
        label: "Scheduling Options",
        type: "Radio",
        name: "publish_type",
        // rules: [{ required: true, message: "Please select due date" }],
        options: [
          {
            name: "Publish Now",
            value: "instant",
          },
          {
            name: "Scheduled",
            value: "scheduled",
          },
          {
            name: "Keep Unpublished",
            value: "draft",
          },
        ],
        colSpan: 24,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
      },
    ],
  };
  return (
    <Fragment>
      <PageHeader ghost={false} title="Add New Post"></PageHeader>
      <Row className="crm-dashboard">
        <Col span={24} className="crm-tabs padding10">
          <h2 className="padding10">Facebook Pages</h2>
          <Row className="padding10">
            {pages?.length > 0 &&
              selectedPage &&
              pages?.map((page: any) => {
                return (
                  <Col span={6} className="mb-10">
                    <Card
                      className={
                        selectedPage.page_id == page.page_id ? "selected" : ""
                      }
                      onClick={() => setSelectedPage(page)}
                    >
                      <Row gutter={8} align="middle">
                        <Col>
                          <img
                            src={
                              require("../../../assets/icons/facebook-logo.png")
                                .default
                            }
                            style={{ maxWidth: "30px" }}
                          />
                        </Col>
                        <Col flex="auto">{page.page_name}</Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
          </Row>
          {accountsList && (
            <Row className="padding10">
              <Col span={11}>
                <Card>
                  <FormBar {...formData}></FormBar>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Fragment>
  );
}

export default SocialNewPostPage;
