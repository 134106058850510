import moment from "moment";
//import {User} from "../types/user.type";
import React from "react";
import { useLocation } from "react-router";
import { ROUTE_LANDING } from "../routes/route.constants";
import { _BASE_URL } from "./api-client";
//import {User} from "../types/user.type";

export function latestAllowedRoute() {
  return ROUTE_LANDING;
}

export function generateGreeting() {
  const hour = moment().hour();

  if (hour > 16) {
    return "Good Evening";
  }

  if (hour > 11) {
    return "Good Afternoon";
  }

  return "Good Morning";
}

export const checkString = (str: any) => {
  if (str === undefined || str?.length === 0 || str === null) {
    return false;
  } else return true;
};

export const operationalHoursOptions = [
  { key: "business_hours", value: "Business Hours", label: "Business Hours" },
  { key: "extended_hours", value: "Extended Hours", label: "Extended Hours" },
  { key: "24_7", value: "24/7", label: "24/7" },
  { key: "morning_shift", value: "Morning Shift", label: "Morning Shift" },
  {
    key: "afternoon_shift",
    value: "Afternoon Shift",
    label: "Afternoon Shift",
  },
  { key: "evening_shift", value: "Evening Shift", label: "Evening Shift" },
  { key: "night_shift", value: "Night Shift", label: "Night Shift" },
  { key: "weekdays", value: "Weekdays", label: "Weekdays" },
  { key: "weekends", value: "Weekends", label: "Weekends" },
  { key: "custom_hours", value: "Custom Hours", label: "Custom Hours" },
  { key: "holiday_hours", value: "Holiday Hours", label: "Holiday Hours" },
  { key: "lunch_hours", value: "Lunch Hours", label: "Lunch Hours" },
  { key: "early_morning_hours", value: "Early Morning Hours" },
];

export const timeIntervals = [
  { key: "Immediately", value: "0" },
  { key: "After 30 minutes", value: "30" },
  { key: "After 1 hour", value: "60" },
  { key: "After 2 hours", value: "120" },
  { key: "After 4 hours", value: "240" },
  { key: "After 8 hours", value: "480" },
  { key: "After 12 hours", value: "720" },
  { key: "After 1 day", value: "1440" },
  { key: "After 2 days", value: "2880" },
  { key: "After 3 days", value: "4320" },
  { key: "After 4 days", value: "5760" },
  { key: "After 5 days", value: "7200" },
  { key: "After 1 week", value: "10080" },
  { key: "After 10 days", value: "14400" },
  { key: "After 2 weeks", value: "20160" },
  { key: "After 15 days", value: "21600" },
  { key: "After 1 month", value: "43200" },
];

export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function toCamelCase(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export function toSentenceCase(str: any) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export function setCookie(cname: string, cvalue: any, exdays: any) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// Can not select days before today and today
export function disabledPrevoiusDates(current: any) {
  return current && current < moment().subtract(1, "day").endOf("day");
}

export function checkValues(value: any) {
  if (value === null || value === "" || value === undefined) {
    return false;
  } else {
    return true;
  }
}

export function disabledNonBookingDates(current: any) {
  return current && current.isAfter(moment().add(90, "days"));
}

export function disabledFutureDates(current: any) {
  return current && current.valueOf() > Date.now();
}

export const getJSONFile = (jsonData: any) => {
  const fileData = JSON.stringify(jsonData);
  const blob: any = new Blob([fileData], { type: "text/plain" });
  const fileName = new Date().getTime() + ".json";
  blob["lastModifiedDate"] = new Date();
  blob["name"] = fileName;
  return blob;
};

export function getFullName(user: any, falseReturn = "") {
  return user && (user?.first_name || user?.last_name)
    ? `${user?.first_name || ""} ${user?.last_name || ""}`
    : user?.name
    ? user?.name
    : falseReturn
    ? "NA"
    : "";
}

export function getDayFormat(date: any, show: any) {
  const _date = moment(date);
  //console.log(_date)
  const today = moment().endOf("day");
  const tomorrow = moment().add(1, "day").endOf("day");
  const yesterday = moment().subtract(1, "day").endOf("day");
  const day_before_yesterday = moment().subtract(2, "day").endOf("day");
  // const later = moment().add(2, 'day').endOf('day')
  if (_date < day_before_yesterday) return moment(date).format("dddd");
  if (_date < yesterday) return "Yesterday";
  if (_date < today) return "Today";
  if (_date < tomorrow) return "Tomorrow";
  if (show) {
    return moment(date).format("Do MMM' YYYY");
  } else {
    return moment(date).format("dddd");
  }
}

export function getChatDateFormat(date: any) {
  const _date = moment(date);
  //console.log(_date)
  const today = moment().endOf("day");
  const yesterday = moment().subtract(1, "day").endOf("day");
  const day_before_yesterday = moment().subtract(2, "day").endOf("day");
  // const later = moment().add(2, 'day').endOf('day')
  if (_date < day_before_yesterday) return moment(date).format("Do MMM' YYYY");
  if (_date < yesterday) return "Yesterday";
  if (_date < today) return "Today";
}

export function timeDiffrence(_startTime: any, _endTime: any) {
  var startTime = moment(_startTime, "HH:mm:ss a");
  var endTime = moment(_endTime, "HH:mm:ss a");
  return endTime.diff(startTime, "minutes");
}

export function getAge(year: any) {
  const _date: any = moment(year, "YYYY");
  const _current: any = moment();
  if (year === null) {
    return null;
  }
  return _current.diff(_date, "years");
}

export function dobToAge(dobIn: string) {
  if (dobIn === null) {
    return null;
  }
  return moment().diff(dobIn, "years");
}

export function formatDob(dob: Date) {
  // console.log(`dob >>>>>>>`, dob);
  if (!dob) {
    return null;
  }
  let day: string = `${dob.getDate()}`;
  if (day.length < 2) {
    day = `0${day}`;
  }
  let month: string = `${dob.getMonth() + 1}`;
  if (month.length < 2) {
    month = `0${month}`;
  }
  let year: number = dob.getFullYear();
  return `${year}-${month}-${day}`;
}

export function formatDate(date: string, format: string = "DD MMM'YY, LT") {
  return moment(date).format(format);
}

export function checkEmailOrMobile(value: any) {
  // Regular expressions for email and mobile number validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^[0-9]{10}$/;

  if (emailRegex.test(value)) {
    return "email";
  } else if (mobileRegex.test(value)) {
    return "mobile";
  } else {
    return "Neither email nor mobile";
  }
}

export function getGender(data: any) {
  return data && data.split("")[0].toUpperCase();
}

export function formatTimes(date: string, format: string = "LT") {
  return moment(date).format(format);
}
export function formatOnlyDate(date: string, format: string = "DD MMM'YY") {
  return moment(date).format(format);
}

export function secondsToTime(seconds: number) {
  return moment.utc(seconds * 1000).format("HH:mm:ss");
}

export function secondsToHourMins(seconds: number) {
  var totalMinutes = seconds;
  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;
  if (hours === 0) {
    return minutes + "mins";
  } else {
    return hours + "h " + minutes + "mins";
  }
}

export function contactNameFormat(
  firstName: string,
  lastName: string,
  gender: string,
  dob: string
) {
  let extra = "";
  if (gender || dob) {
    extra = `(${dob ? dobToAge(dob) + "yrs, " : ""}${
      gender ? gender.split("")[0].toUpperCase() : ""
    })`;
  }

  return `${firstName ? firstName : ""} ${lastName ? lastName : ""} ${extra}`;
}
export const validateToken = () => {
  const token = localStorage.getItem("token") as any;

  if (token === "" || token === null || token === undefined) {
    return true;
  }
  const exp = JSON.parse(window.atob(token?.split(".")[1])).exp;
  if (Math.floor(Date.now() / 1000) >= exp) {
    return false;
  }
  return true;
};

export function formatDropDownValue(value: string) {
  // return value ? value.split("_").join(" ").toUpperCase() : value;
  return value
    ? value
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    : value;
}

export function formatDropDownValueInCaps(value: string) {
  return value ? value.split("_").join(" ").toUpperCase() : value;
}

export function convertSecondsInMinutesAndSecond(value: number) {
  var duration = moment.duration(value, "seconds");
  var result = duration.minutes() + " mins " + duration.seconds() + "s";
  return result;
}

export function titleCase(value: any) {
  value = value.toLowerCase().split(" ");
  for (var i = 0; i < value.length; i++) {
    value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
  }
  return value.join(" ");
}

export function checkPhoneNumber(value: any) {
  let letters = /^[A-Za-z]+$/;
  return value.match(letters) ? true : false;
}

export function isUUID(value: any) {
  return value.match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
  )
    ? true
    : false;
}

export function getInitials(string: any) {
  var names = [];
  var initials = [];
  if (string !== undefined) {
    names = string?.split(" ");
    initials = names[0].substring(0, 1).toUpperCase();
  }
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function getYoutubeId(url: any) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

export enum CustomEvents {
  REQUEST_SENT = "REQUEST_SENT",
  REQUEST_SUCCESS = "REQUEST_SUCCESS",
  REQUEST_FAIL = "REQUEST_FAIL",
}

export const sendInternalEvent = (eventType: CustomEvents, data: any = {}) => {
  switch (eventType) {
    case CustomEvents.REQUEST_SENT:
      let requestSent = document.getElementById("req-sent");
      if (requestSent != null) {
        requestSent.innerHTML =
          parseInt(requestSent.innerHTML as string) + 1 + "";
      }

      break;
    case CustomEvents.REQUEST_SUCCESS:
      let requestSuccess = document.getElementById("req-success");
      if (requestSuccess != null) {
        requestSuccess.innerHTML =
          parseInt(requestSuccess.innerHTML as string) + 1 + "";
      }

      break;
    case CustomEvents.REQUEST_FAIL:
      let requestFailed = document.getElementById("req-failed");
      if (requestFailed != null) {
        requestFailed.innerHTML =
          parseInt(requestFailed.innerHTML as string) + 1 + "";
      }

      break;
  }
};
export const getCurrentEnvironmentName = () => {
  const baseUrl = _BASE_URL?.toLocaleLowerCase();
  if (baseUrl.indexOf("localhost") > -1) {
    return "LOCAL";
  }
  if (baseUrl.indexOf("betamedi.hlthclub.in") > -1) {
    return "STAGING";
  }
  if (baseUrl.indexOf("medi.maxhospitals.in") > -1) {
    return "PROD";
  }
  if (
    baseUrl.indexOf("medi.maxhospitals.in") > -1 ||
    baseUrl.indexOf("medi.thbanalytics.in") > -1
  ) {
    return "PROD";
  }
  if (
    baseUrl.indexOf("betamedi.thbanalytics.in") > -1 ||
    baseUrl.indexOf("betamedi.maxhospitals.in") > -1
  ) {
    return "UAT";
  }
  return "NA";
};
/*
    TO detect browser resolution means mobile or web

*/

export const truncateText50 = (text: any, limit: any) => {
  const words = text?.split(" ");
  if (words?.length > limit) {
    return words?.slice(0, limit)?.join(" ") + "...";
  }
  return text;
};

export const metaValues = [
  {
    show: false,
    label: "Source",
    value: "chatbottype",
  },
  {
    show: false,
    label: "Query Type",
    value: "queryType",
  },
  {
    show: false,
    label: "Query Id",
    value: "queryId",
  },
  {
    show: false,
    label: "Server Name",
    value: "servername",
  },
  {
    show: true,
    label: "Doctor Name",
    value: "Name",
  },
  {
    show: true,
    label: "Doctor Email",
    value: "EmailId",
  },
  {
    show: true,
    label: "Doctor City",
    value: "City",
  },
  {
    show: true,
    label: "Doctor Specialization",
    value: "Specialization",
  },
  {
    show: true,
    label: "Speciality",
    value: "speciality",
  },

  {
    show: true,
    label: "Refered At",
    value: "referedAt",
  },
  {
    show: false,
    label: "Doctor Number",
    value: "doctorNumber",
  },
  {
    show: true,
    label: "Product",
    value: "product",
  },
  {
    show: true,
    label: "Unit",
    value: "unit",
  },
  {
    show: true,
    label: "Referred Unit",
    value: "referredUnit",
  },
  {
    show: true,
    label: "Pincode",
    value: "pincode",
  },
  {
    show: true,
    label: "Address",
    value: "address",
  },
  {
    show: true,
    label: "GST No",
    value: "gstNo",
  },
  {
    show: false,
    label: "Environment",
    value: "environment",
  },
  {
    show: true,
    label: "Patient Name",
    value: "patientName",
  },
  {
    show: true,
    label: "Patient Mobile",
    value: "patientMobileNumber",
  },
  {
    show: true,
    label: "Patient Mobile",
    value: "mobileNumber",
  },

  {
    show: true,
    label: "Product Interest",
    value: "ans",
  },
  {
    show: false,
    label: "Accepted Reply",
    value: "acceptedReply",
  },
  {
    show: false,
    label: "nextIndx",
    value: "nextIndx",
  },
  {
    show: true,
    label: "Order Id",
    value: "Order id",
  },
  {
    show: true,
    label: "Query",
    value: "query",
  },
  {
    show: true,
    label: "Referred Unit",
    value: "referred_unit",
  },
  {
    show: true,
    label: "Chief Complaint",
    value: "complaint",
  },
  {
    show: true,
    label: "Doctor Id",
    value: "doctor_id",
  },
  {
    show: true,
    label: "Hospital Name",
    value: "branch_name",
  },
  {
    show: true,
    label: "Doctor Unit",
    value: "doctor_unit",
  },
  {
    show: true,
    label: "Referred Doctor",
    value: "referred_doctor",
  },
  {
    show: true,
    label: "URL",
    value: "url",
  },
  {
    show: false,
    label: "Service Type",
    value: "service_type",
  },
  {
    show: true,
    label: "Email",
    value: "email",
  },
  {
    show: true,
    label: "Product Unavailable ",
    value: "product_unavailable",
  },
  {
    show: true,
    label: "Product complaint",
    value: "product_complaint",
  },
  {
    show: true,
    label: "Product Unit",
    value: "product_unit",
  },
  {
    show: true,
    label: "Brand Name",
    value: "brandname",
  },
  {
    show: true,
    label: "Connect Time",
    value: "connectTime",
  },
  {
    show: true,
    label: "Callback Time",
    value: "callbackTime",
  },

  {
    show: false,
    label: "Name Add",
    value: "patchNameAdd",
  },
  {
    show: false,
    label: "Name Delete",
    value: "patchNameDel",
  },
  {
    show: false,
    label: "Name Replace",
    value: "patchNameRepl",
  },
  {
    show: true,
    label: "Query",
    value: "RaiseQuery",
  },
];
export function getScreenType() {
  /*
    return {
        desktop:useMediaQuery({ minWidth: 992 }),
        tablet:useMediaQuery({ minWidth: 768, maxWidth: 991 }),
        mobile:useMediaQuery({ maxWidth: 767 }),
  
    };
    */
}

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export function secondsToHms(d: any) {
  d = Number(d);
  if (d === 0) return "0 sec";
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
  return hDisplay + mDisplay + sDisplay;
}

/**********Video Logs */
const sortObj = (obj: any, key: string) => {
  return obj.sort(
    (a: any, b: any) => moment(a[key]).valueOf() - moment(b[key]).valueOf()
  );
};
const getlastLoggedIn = (
  eventJoins: any,
  summaryJoins: any,
  summaryWaiting: any
) => {
  let dt = "--";
  if (eventJoins?.length > 0) {
    dt = formatDate(eventJoins[eventJoins.length - 1].created_on);
  } else if (summaryJoins?.length > 0) {
    dt = formatDate(summaryJoins[summaryJoins.length - 1].created_on);
  } else if (summaryWaiting?.length > 0) {
    dt = formatDate(summaryWaiting[summaryWaiting.length - 1].created_on);
  }
  return dt;
};
const getVideoRoomStatus = (event: any, summary: any) => {
  let status = "Not Joined";
  let dt = "";
  if (event?.length > 0) {
    dt = formatDate(event[event.length - 1].created_on, "LT");
    if (event[event.length - 1].event === "joined") {
      status = "In video room (joined at " + dt + ")";
    }
    if (event[event.length - 1].event === "left") {
      status = "Video room left (at " + dt + ")";
    }
  } else if (summary?.length > 0) {
    dt = formatDate(summary[summary.length - 1].created_on, "LT");
    if (summary[summary.length - 1].event === "join") {
      status = "In video room (joined at " + dt + ")";
    }
    if (summary[summary.length - 1].event === "left") {
      status = "Video room left (at " + dt + ")";
    }
    if (summary[summary.length - 1].event === "waiting_room_join") {
      status = "In waiting room (at " + dt + ")";
    }
  }
  return status;
};
const updateCurrentStatus = (allEvents: any) => {
  let currentStatus = "";
  let d = false,
    p = false,
    dt = "",
    ddt = "",
    pdt = "",
    djt = "",
    pjt = "",
    dlt = "",
    plt = "",
    ndj = 0,
    ndl = 0,
    npj = 0,
    npl = 0,
    overlapDt = "";
  allEvents.forEach((val: any) => {
    if (val.publish_type === "doctor") {
      if (val.event === "joined") {
        d = true;
        ++ndj;
        dt = ddt = djt = formatDate(val.created_on, "LT");
      }
      if (val.event === "left") {
        d = false;
        ++ndl;
        dt = ddt = dlt = formatDate(val.created_on, "LT");
      }
    }

    if (val.publish_type === "patient") {
      if (val.event === "joined") {
        p = true;
        ++npj;
        dt = pdt = pjt = formatDate(val.created_on, "LT");
      }
      if (val.event === "left") {
        p = false;
        ++npl;
        dt = pdt = plt = formatDate(val.created_on, "LT");
      }
    }
    if (d && p) {
      // both joined right now - and overlap
      overlapDt = dt;
    }
  });

  if (d && p) {
    currentStatus = "Both Doctor and Patient connected at " + overlapDt;
  }
  if (!d && !p) {
    // both not joined right now
    if (ndj !== 0 && npj !== 0) {
      if (overlapDt == "") {
        currentStatus = `Doctor joined at ${djt}. Patient joined at ${pjt}. Not connected together`;
      } else {
        currentStatus = "Both Doctor and Patient connected at " + overlapDt;
      }
    }
    if (ndj === 0 && npj !== 0)
      currentStatus = `Patient left at  ${plt}. Doctor did not join`;
    if (ndj !== 0 && npj === 0)
      currentStatus = `Doctor left at  ${dlt}. Patient did not join`;
    if (ndj === 0 && npj === 0)
      currentStatus = `Both Doctor and Patient did not join`;
  }
  if (d && !p) {
    // doctor currently in, patient not currently in
    if (npj === 0) {
      currentStatus = `Doctor joined at ${djt}. Patient did not join`;
    } else {
      if (overlapDt == "") {
        currentStatus = `Doctor joined at ${djt}. Patient joined at ${pjt}. Not connected together`;
      } else {
        currentStatus = "Both Doctor and Patient connected at " + overlapDt;
      }
    }
  }
  if (!d && p) {
    // doctor not currently in, patient currently in
    if (ndj === 0) {
      currentStatus = `Patient joined at ${pjt}. Doctor did not join`;
    } else {
      if (overlapDt == "") {
        currentStatus = `Doctor joined at ${djt}. Patient joined at ${pjt}. Not connected together`;
      } else {
        currentStatus = "Both Doctor and Patient connected at " + overlapDt;
      }
    }
  }
  return currentStatus;
};
export const processVideoLogs = (callEventLogs: any, callSummary: any) => {
  if (callEventLogs?.length > 0) {
    callEventLogs = sortObj(callEventLogs, "created_on");
  }
  if (callSummary?.length > 0) {
    callSummary = sortObj(callSummary, "created_on");
  }
  let eventDoctor: any[] = [],
    eventPatient: any[] = [],
    eventDoctorJoin: any[] = [],
    eventPatientJoin: any[] = [],
    eventDoctorLeft: any[] = [],
    eventPatientLeft: any[] = [],
    summaryDoctor: any[] = [],
    summaryPatient: any[] = [],
    summaryDoctorJoin: any[] = [],
    summaryPatientJoin: any[] = [],
    summaryDoctorLeft: any[] = [],
    summaryPatientLeft: any[] = [],
    summaryPatientWaiting: any[] = [],
    patientData = {
      totalAttempts: 0,
      deviceType: "",
      device: "--",
      appVersion: "--",
      appUser: "--",
      lastLoggedIn: "--",
      status: "Not Joined",
      deviceip: "--",
      deviceos: "--",
    },
    doctorData = {
      totalAttempts: 0,
      deviceType: "",
      device: "--",
      appVersion: "--",
      appUser: "--",
      lastLoggedIn: "--",
      status: "Not Joined",
      deviceip: "--",
      deviceos: "--",
    };

  callEventLogs.forEach((val: any) => {
    if (val.publish_type === "doctor") {
      eventDoctor.push(val);
      if (val.event === "joined") {
        eventDoctorJoin.push(val);
      }
      if (val.event === "left") {
        eventDoctorLeft.push(val);
      }
    }

    if (val.publish_type === "patient") {
      eventPatient.push(val);
      if (val.event === "joined") {
        eventPatientJoin.push(val);
      }
      if (val.event === "left") {
        eventPatientLeft.push(val);
      }
    }
  });

  callSummary.forEach((val: any) => {
    if (val.user_type === "doctor") {
      summaryDoctor.push(val);
      if (val.event === "join") {
        summaryDoctorJoin.push(val);
      }
      if (val.event === "left") {
        summaryDoctorLeft.push(val);
      }
    }

    if (val.user_type === "patient") {
      summaryPatient.push(val);
      if (val.event === "join") {
        summaryPatientJoin.push(val);
      }
      if (val.event === "left") {
        summaryPatientLeft.push(val);
      }
      if (val.event === "waiting_room_join") {
        summaryPatientWaiting.push(val);
      }
    }
  });
  /********Patient Data */
  patientData.totalAttempts = eventPatientJoin?.length;
  let temp = summaryPatient
    .map((val: any) => {
      let dev1 = val.device_type?.trim();
      let dev2 = val.device_name?.trim();
      if (dev1 && dev1 !== "") {
        dev1 = dev1 + " ";
      }
      if (dev2 && dev2 !== "") {
        if (dev2.indexOf("-") !== -1 && dev2.indexOf(":") === -1) {
          dev2 = dev2.split("-")[1];
        }
      }
      return (dev1 + " " + dev2).trim();
    })
    .filter((val: any) => {
      return val && val !== "";
    });

  patientData.device = temp?.length > 0 ? temp[temp.length - 1] : "--";
  if (patientData.device !== "--") {
    if (patientData.device.split(" ")[0] === "Browser") {
      patientData.appUser = "No";
    } else {
      patientData.appUser = "Yes";
    }
  }
  patientData.lastLoggedIn = getlastLoggedIn(
    eventPatientJoin,
    summaryPatientJoin,
    summaryPatientWaiting
  );
  patientData.status = getVideoRoomStatus(eventPatient, summaryPatient);
  if (summaryPatient?.length > 0) {
    if (summaryPatient[summaryPatient.length - 1].app_version) {
      patientData.appVersion =
        summaryPatient[summaryPatient.length - 1].app_version;
    }
    if (summaryPatient[summaryPatient.length - 1].device_ip) {
      patientData.deviceip =
        summaryPatient[summaryPatient.length - 1].device_ip;
    }
    if (summaryPatient[summaryPatient.length - 1].device_os) {
      patientData.deviceos =
        summaryPatient[summaryPatient.length - 1].device_os;
    }
  }

  /********Doctor Data */
  doctorData.totalAttempts = eventDoctorJoin?.length;
  let temp1 = summaryDoctor
    .map((val: any) => {
      let dev1 = val.device_type?.trim();
      let dev2 = val.device_name?.trim();
      if (dev1 && dev1 !== "") {
        dev1 = dev1 + " ";
      }
      if (dev2 && dev2 !== "") {
        if (dev2.indexOf("-") !== -1 && dev2.indexOf(":") === -1) {
          dev2 = dev2.split("-")[1];
        }
      }
      return (dev1 + " " + dev2).trim();
    })
    .filter((val: any) => {
      return val && val !== "";
    });

  doctorData.device = temp1?.length > 0 ? temp1[temp1.length - 1] : "--";
  if (doctorData.device !== "--") {
    if (doctorData.device.split(" ")[0] === "Browser") {
      doctorData.appUser = "No";
    } else {
      doctorData.appUser = "Yes";
    }
  }
  doctorData.lastLoggedIn = getlastLoggedIn(
    eventDoctorJoin,
    summaryDoctorJoin,
    []
  );
  doctorData.status = getVideoRoomStatus(eventDoctor, summaryDoctor);
  if (summaryDoctor?.length > 0) {
    if (summaryDoctor[summaryDoctor.length - 1].app_version) {
      doctorData.appVersion =
        summaryDoctor[summaryDoctor.length - 1].app_version;
    }
    if (summaryDoctor[summaryDoctor.length - 1].device_ip) {
      doctorData.deviceip = summaryDoctor[summaryDoctor.length - 1].device_ip;
    }
    if (summaryDoctor[summaryDoctor.length - 1].device_os) {
      doctorData.deviceos = summaryDoctor[summaryDoctor.length - 1].device_os;
    }
  }
  let currentStatus = updateCurrentStatus(callEventLogs);
  return { patientData, doctorData, currentStatus };
};

export const getURLParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

export const convertFileSizeFromBytes = (bytes: number = 0) => {
  let ret = `${bytes}B`;
  if (bytes > 1024) {
    ret = `${Math.round((bytes / 1024) * 100) / 100}KB`;
  }
  if (bytes > 1024 * 1024) {
    ret = `${Math.round((bytes / 1024 / 1024) * 100) / 100}MB`;
  }
  return ret;
};

export const readAsArrayBufferAsync = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export const formatEscapedText = (text: string) => {
  return text.replace(new RegExp("\r?\n", "g"), "<br />");
};
