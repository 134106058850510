import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";


import axios from "axios";
import { uploadFile } from "../../redux/reducers/upload.slice";



type Props = {
  handleCancel: any;
  fileUploaded?: any;
  request_id?: string,
  patient_id?: string,
  getDescription?: any
};

const readAsArrayBufferAsync = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

const UploadPrescription = (componentProps: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const maxFileCountErrorShownRef = useRef(false);


  const handleUpload = async () => {
    try {

      const uploadIds = [] as any;
      componentProps.getDescription(description)
      for (const file of fileList) {
        const { type } = file;
        const { name } = file;
        let ext: any = name?.split(".")[1];
        let uploadFileType = "" as any;
        if (["png", "jpg", "jpeg", "gif"].includes(ext)) {
          uploadFileType = "image";
        } else if (["pdf"].includes(ext)) {
          uploadFileType = "pdf";
        } else if (["xls", "xlsx", "csv"].includes(ext)) {
          uploadFileType = "spreadsheet";
        } else if (["doc", "docx"].includes(ext)) {
          uploadFileType = "document";
        } else {
          message.error("Uploaded file is not valid", 10);
          // removeFile(file);
          return;
        }
        const response = await dispatch(uploadFile({
          file_name: description,
          type: "PATIENT_DOCUMENT",
          content_type: uploadFileType
        }));

        const { data: { data: { id: upload_id, url } } } = response.payload;

        try {
          const binaryFile = await readAsArrayBufferAsync(file as RcFile);
          setUploading(true);
          const fileUploaded = await axios.request({
            headers: { "Content-Type": file.type || "" },
            method: "PUT",
            data: binaryFile,
            url: url,
            timeout: 0
          });

          if (fileUploaded.status === 200) {
            setUploading(false);
            setDescription("");
            setFileList([]);
            // componentProps.fileUploaded(upload_id);
            uploadIds.push(upload_id); // Store the upload ID in the array
          }
        } catch (e) {
          message.error('Something went wrong uploading the file.')
        }
      }
      componentProps.fileUploaded(uploadIds);
      // return uploadIds; // Return array of upload IDs after all files are uploaded
    } catch (e) {
      message.error('Something went wrong.')
    }

  };

  const props: UploadProps = {
    // accept: "image/jpg,image/jpeg,image/png,application/pdf",
    multiple: true,
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file, fsList) => {
      const maxSizeInBytes = 5 * 1024 * 1024; // 15 MB in bytes
      if (file.size > maxSizeInBytes) {
        message.warning("File size must be up to 5 MB", 4);
        return false;
      }
      if (fileList?.length >= 10) {
        message.warning("You can only upload 10 files at a time", 4);
        return false;
      }
      if (fsList?.length > 10 && !maxFileCountErrorShownRef.current) {
        message.warning("You can only upload 10 file at a time", 4);
        maxFileCountErrorShownRef.current = true;
        return false;
      }
      if (fsList?.length >= 10) {
        const updatedFileList = [...fsList, file].slice(0, 9); // Limit to 10 files
        setFileList(updatedFileList);
      }
      const { name } = file;
      let ext: any = name?.split(".");
      ext = ext?.length > 0 ? ext[ext.length - 1] : "";
      if (
        [
          "png",
          "jpg",
          "jpeg",
          "gif",
          "pdf",
          "xls",
          "xlsx",
          "csv",
          "doc",
          "docx",
        ].includes(ext)
      ) {
      } else {
        message.error("Uploaded file is not valid", 10);
        return;
      }
      setFileList(prevFileList => [...prevFileList, file]);
      return false;
    },
    maxCount: 10,
    fileList
  };

  const handleCancels = () => {
    componentProps.handleCancel()
    setFileList([])
  }

  return (
    <>
      <form action="">
        <Row className={"form-group"}>
          <Col span={24}>
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
            {/* <small>(Supported format: Pdf, Image, Doc upto 5MB.)</small> */}
          </Col>
        </Row>
        <Row className={"mt15"}>
          <Col span={24}>
            <label htmlFor="description" className={"bold"}>Description</label> <br />
            <Input size={"middle"} name={"description"} value={description} onChange={(e) => setDescription(e.target.value)} />
          </Col>
        </Row>
        <div className="text-right mt15">
          <Button type={"default"} onClick={handleCancels} disabled={uploading}>Cancel</Button>
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList?.length === 0 || !description}
            loading={uploading}
            style={{ marginLeft: 15 }}
          >
            {uploading ? "Uploading" : "Upload Document"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default UploadPrescription;