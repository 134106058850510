import { CompressOutlined, ExpandOutlined } from "@ant-design/icons";
import { Button, Col, Image, Modal, Row, Skeleton, Typography } from "antd";
import moment from "moment";
//import { DeleteOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import FormView from "../../pages/dependencies/Builder/Components/FormView/FormView";
import CrossIcon from "../icons/CrossIcon";
const { Text } = Typography;
function FileViewLayout({
  details,
  fileviewer,
  setFileViewer,
  file,
  deleteDocuments,
  showNext,
  loader,
  showPrev,
  selectedIndex,
  total,
  id,
  modal,
  isDoctor,
  data,
  showNextBtn,
  showPrevBtn,
  showZoomRotate = false,
  fileExtension
}: any) {
  const [rotate, setRotate] = useState(
    details?.allData?.rotation
      ? parseInt(details?.allData?.rotation)
      : (0 as any)
  );
  const [scale, setScale] = useState(1 as any);
  const [_loader, setLoader] = useState(loader as any);
  const [modalVal, setModal] = useState(modal as any);
  const [selectedTemplateDataRaw, setselectedTemplateDataRaw] = useState(
    null as any
  );
  const [xlsFile, setxlsFile] = useState(null as any)
  console.log("rotate::", details?.img);
  // debugger
  useEffect(() => {
    setselectedTemplateDataRaw(null);
    if (
      (details && details.content_type === "json") ||
      (details && details.img === "json")
    ) {
      (async function () {
        try {
          const result = await getTemplateJsonData(file?.result);
          setselectedTemplateDataRaw(result);
          setLoader(false);
        } catch (e) {
          console.error(e);
        }
      })();
    } else {
      setLoader(false);
    }
  }, [details]);
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "tif", "webp", "svg", "raw", "cr2", "nef"];

  useEffect(() => {
    localStorage.setItem("prescriptionRotation", rotate);
  }, [rotate]);

  const downloadFile = async (file: any) => {
    const originalImage = file;
    const image = await fetch(originalImage);

    //Split image name
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // useEffect(() => {
  //   setxlsFile(file?.result)
  // }, [file])

  async function getTemplateJsonData(url: any) {
    try {
      let selectedTemplateDataResponse = await fetch(url);
      let selectedTemplateDataRaw = await selectedTemplateDataResponse.json();
      return selectedTemplateDataRaw;
    } catch (e) { }
  }
  if (!modal) {

    return (

      <Row
        // style={{ width: '800px' }}
        className={modalVal ? "full-screen-iframe" : ""}
        onClick={() => {
          modalVal && setModal(false);
        }}
      >

        <Col
          span={
            showZoomRotate &&
              (details?.img === "image" ||
                details?.img === "png" ||
                details?.img === "jpg" ||
                details?.img === "jpeg")
              ? 22
              : 24
          }
          // span={22}
          style={{ overflow: "auto" }}
        >
          <div
            style={{
              transform: `rotate(${rotate}deg)`,
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            {_loader ? (
              <Skeleton></Skeleton>
            ) : details?.img === "image" ||
              details?.img === "png" ||
              details?.img === "jpg" ||
              details?.img === "jpeg" ? (
              <Image
                style={{ transform: `scale(${scale})`, overflow: `scroll` }}
                src={file?.result}
                height={"100%"}
                width={"100%"}
              ></Image>
            ) : details?.img === "json" ? (
              <>
                <FormView prescriptionData={selectedTemplateDataRaw} />
              </>
            ) : (
              <>
                {
                  (fileExtension === 'docx' || fileExtension === 'xlsx' || fileExtension === 'csv' ||
                    fileExtension === 'xls' || fileExtension === 'doc' || fileExtension === 'pptx' || fileExtension === 'ppt') ? <>
                    <Row className="text-center" gutter={[0, 20]}>
                      <Col span={24}>
                        <p>We are unable to show file for {fileExtension} extension.</p>
                        <small>Please download file.</small>
                      </Col>
                      {/* <br /> */}
                      <Col span={24}>
                        <Button type="primary" onClick={() => { setxlsFile(file?.result) }}>Download</Button>
                      </Col>
                    </Row>
                    {
                      xlsFile && <div className="expandable-iframe">
                        <iframe
                          title="expandable-iframe"
                          src={xlsFile}
                          style={{
                            border: "none",
                            height: "calc( 100vh - 200px)",
                            width: "100%",
                          }}
                        ></iframe>
                        <Button
                          className={modalVal ? "d-none" : "iframe-hover-button"}
                          size="large"

                          shape="circle"
                          type="link"
                          onClick={() => setModal(true)}
                          icon={<ExpandOutlined />}
                        />
                      </div>
                    }
                  </> :

                    imageExtensions.includes(fileExtension) ? <img style={{ width: ' 100%' }} src={file?.result} alt="" /> : <div className="expandable-iframe">
                      <iframe
                        title="expandable-iframe"
                        src={file?.result}
                        style={{
                          border: "none",
                          height: "calc( 100vh - 200px)",
                          width: "100%",
                        }}
                      ></iframe>
                      <Button
                        className={modalVal ? "d-none" : "iframe-hover-button"}
                        size="large"
                        shape="circle"
                        type="link"
                        onClick={() => setModal(true)}
                        icon={<ExpandOutlined />}
                      />
                    </div>



                }

              </>

            )}
          </div>
        </Col>
        <Button
          className={!modalVal ? "d-none" : "iframe-hover-button-close"}
          size="large"
          shape="circle"
          type="link"
          onClick={() => setModal(false)}
          icon={<CompressOutlined />}
        />
        {/* {details?.img && details?.img === "00" ? ( */}
        <Col
          span={
            showZoomRotate &&
              (details?.img === "image" ||
                details?.img === "png" ||
                details?.img === "jpg" ||
                details?.img === "jpeg")
              ? 2
              : 0
          }
          className="text-center"
        >
          <p>
            <small>Zoom</small>
          </p>
          <p>
            <svg
              width="45"
              onClick={() => setScale(scale + 0.5)}
              className={"cursor"}
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.71875"
                y="0.0859375"
                width="44"
                height="44"
                rx="6.6383"
                fill="#23BCB5"
              />
              <path
                d="M23.4483 24.4199H29.3195V21.9393H23.4483V16.5859H20.877V21.9393H15.0195V24.4199H20.877V29.7859H23.4483V24.4199Z"
                fill="white"
              />
            </svg>
          </p>

          <p>
            <svg
              onClick={() => scale > 1 && setScale(scale - 0.5)}
              className={"cursor"}
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.71875"
                y="0.722656"
                width="44"
                height="44"
                rx="6.6383"
                fill="#23BCB5"
              />
              <path
                d="M29.3195 25.0567H23.4483H20.877H15.0195V22.5762H20.877H23.4483H29.3195V25.0567Z"
                fill="white"
              />
            </svg>
          </p>

          <p>
            <small>Rotate</small>
          </p>
          <p>
            <svg
              width="45"
              onClick={() => setRotate(rotate === 0 ? 270 : rotate - 90)}
              className={"cursor"}
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.71875"
                y="0.0429688"
                width="44"
                height="44"
                rx="6.6383"
                fill="#23BCB5"
              />
              <path
                d="M31.1181 28.589C30.9576 28.589 30.8103 28.4972 30.7473 28.3465C30.701 28.2371 29.5443 25.6427 25.2198 25.1289C24.319 25.0195 23.2402 24.9634 21.93 24.9536V28.1873C21.93 28.3367 21.8494 28.4719 21.7169 28.5434C21.5851 28.6121 21.4274 28.6023 21.3047 28.5203L12.9964 22.9277C12.8843 22.8527 12.8184 22.7265 12.8184 22.594C12.8184 22.4601 12.8843 22.3382 12.9964 22.2596L21.3082 16.6663C21.4309 16.5815 21.5872 16.5759 21.7204 16.646C21.8536 16.7175 21.9328 16.8528 21.9328 16.9979V20.0073C23.74 20.2429 31.5184 21.6407 31.5184 28.1908C31.5184 28.3808 31.3845 28.547 31.1966 28.5848C31.1707 28.589 31.1433 28.589 31.1181 28.589Z"
                fill="white"
              />
            </svg>
          </p>
          <p>
            <svg
              width="45"
              height="45"
              onClick={() => setRotate(rotate === 270 ? 0 : rotate + 90)}
              className={"cursor"}
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.71875"
                y="0.679688"
                width="44"
                height="44"
                rx="6.6383"
                fill="#23BCB5"
              />
              <path
                d="M14.3194 29.2257C14.4799 29.2257 14.6272 29.1339 14.6902 28.9832C14.7365 28.8738 15.8932 26.2794 20.2177 25.7656C21.1185 25.6562 22.1973 25.6001 23.5075 25.5903V28.8241C23.5075 28.9734 23.5881 29.1087 23.7206 29.1802C23.8524 29.2489 24.0101 29.239 24.1328 29.157L32.4411 23.5644C32.5532 23.4894 32.6191 23.3632 32.6191 23.2307C32.6191 23.0968 32.5532 22.9749 32.4411 22.8964L24.1293 17.303C24.0066 17.2182 23.8503 17.2126 23.7171 17.2827C23.5839 17.3542 23.5047 17.4895 23.5047 17.6346V20.644C21.6975 20.8796 13.9191 22.2774 13.9191 28.8276C13.9191 29.0175 14.053 29.1837 14.2409 29.2215C14.2668 29.2257 14.2942 29.2257 14.3194 29.2257Z"
                fill="white"
              />
            </svg>
          </p>
        </Col>
        {/* ) : (
          <></>
        )} */}
      </Row>
    );
  } else {

    return (
      <>
        <Modal
          centered
          className="doctor-app-dark-modal"
          closeIcon={<CrossIcon height="18" />}
          title={
            loader ? (
              <Skeleton.Input style={{ width: 200 }} active size={"small"} />
            ) : (
              details?.name +
              " " +
              moment(details?.created_at).format("DD, MMM YYYY")
            )
          }
          style={{ overflowY: "hidden", paddingBottom: "0px" }}
          width={"100%"}
          footer={false}
          visible={fileviewer}
          maskClosable={false}
          onCancel={() => setFileViewer(false)}
        >
          <Row>
            <Button
              block
              className={"doctor-secondary-button capitalize"}
              target={"_blank"}
              download
              style={{ marginBottom: "20px" }}
              disabled={file?.result !== undefined ? false : true}
              // href={file?.result}
              onClick={() =>
                downloadFile(file?.result)
                  .then(() => { })
                  .catch(() => window.open(file?.result))
              }
              size={"large"}
            >
              Download{" "}
              {details && details?.type && (details?.type).toLowerCase()}
            </Button>

            {/* <Col span={8}></Col> */}

            <Col
              span={22}
              className={"text-center"}
              style={{ overflowX: "hidden", overflowY: "hidden" }}
            >
              <div style={{ transform: `rotate(${rotate}deg)` }}>
                {!loader &&
                  details?.dropdown_value &&
                  details?.dropdown_value?.name === "fail" && (
                    <p>
                      <Text type="danger">
                        QC failed. Please delete and re-upload this document
                      </Text>
                    </p>
                  )}

                {loader ? (
                  <Skeleton></Skeleton>
                ) : details?.content_type === "image" ? (
                  <Image
                    wrapperClassName={
                      (scale > 1 ? "fileOverflowScrolled" : "") +
                      " fileOverflow"
                    }
                    style={{ transform: `scale(${scale})`, overflow: "scroll" }}
                    src={file?.result}
                    preview={false}
                    width={"100%"}
                  ></Image>
                ) : details?.content_type === "json" ? (
                  <>
                    <FormView prescriptionData={selectedTemplateDataRaw} />
                  </>
                ) : (
                  <>
                    <iframe
                      title="file-view-result"
                      src={file?.result}
                      style={{ border: "none", height: "90vh", width: "100%" }}
                    ></iframe>
                  </>
                )}
              </div>
            </Col>
            <Col span={2} className="text-center">
              <p>
                <small>Zoom</small>
              </p>
              <p>
                <svg
                  width="45"
                  onClick={() => setScale(scale + 0.5)}
                  className={"cursor"}
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.71875"
                    y="0.0859375"
                    width="44"
                    height="44"
                    rx="6.6383"
                    fill="#23BCB5"
                  />
                  <path
                    d="M23.4483 24.4199H29.3195V21.9393H23.4483V16.5859H20.877V21.9393H15.0195V24.4199H20.877V29.7859H23.4483V24.4199Z"
                    fill="white"
                  />
                </svg>
              </p>

              <p>
                <svg
                  onClick={() => scale > 1 && setScale(scale - 0.5)}
                  className={"cursor"}
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.71875"
                    y="0.722656"
                    width="44"
                    height="44"
                    rx="6.6383"
                    fill="#23BCB5"
                  />
                  <path
                    d="M29.3195 25.0567H23.4483H20.877H15.0195V22.5762H20.877H23.4483H29.3195V25.0567Z"
                    fill="white"
                  />
                </svg>
              </p>

              <p>
                <small>Rotate</small>
              </p>
              <p>
                <svg
                  width="45"
                  onClick={() => setRotate(rotate - 90)}
                  className={"cursor"}
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.71875"
                    y="0.0429688"
                    width="44"
                    height="44"
                    rx="6.6383"
                    fill="#23BCB5"
                  />
                  <path
                    d="M31.1181 28.589C30.9576 28.589 30.8103 28.4972 30.7473 28.3465C30.701 28.2371 29.5443 25.6427 25.2198 25.1289C24.319 25.0195 23.2402 24.9634 21.93 24.9536V28.1873C21.93 28.3367 21.8494 28.4719 21.7169 28.5434C21.5851 28.6121 21.4274 28.6023 21.3047 28.5203L12.9964 22.9277C12.8843 22.8527 12.8184 22.7265 12.8184 22.594C12.8184 22.4601 12.8843 22.3382 12.9964 22.2596L21.3082 16.6663C21.4309 16.5815 21.5872 16.5759 21.7204 16.646C21.8536 16.7175 21.9328 16.8528 21.9328 16.9979V20.0073C23.74 20.2429 31.5184 21.6407 31.5184 28.1908C31.5184 28.3808 31.3845 28.547 31.1966 28.5848C31.1707 28.589 31.1433 28.589 31.1181 28.589Z"
                    fill="white"
                  />
                </svg>
              </p>
              <p>
                <svg
                  width="45"
                  height="45"
                  onClick={() => setRotate(rotate + 90)}
                  className={"cursor"}
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.71875"
                    y="0.679688"
                    width="44"
                    height="44"
                    rx="6.6383"
                    fill="#23BCB5"
                  />
                  <path
                    d="M14.3194 29.2257C14.4799 29.2257 14.6272 29.1339 14.6902 28.9832C14.7365 28.8738 15.8932 26.2794 20.2177 25.7656C21.1185 25.6562 22.1973 25.6001 23.5075 25.5903V28.8241C23.5075 28.9734 23.5881 29.1087 23.7206 29.1802C23.8524 29.2489 24.0101 29.239 24.1328 29.157L32.4411 23.5644C32.5532 23.4894 32.6191 23.3632 32.6191 23.2307C32.6191 23.0968 32.5532 22.9749 32.4411 22.8964L24.1293 17.303C24.0066 17.2182 23.8503 17.2126 23.7171 17.2827C23.5839 17.3542 23.5047 17.4895 23.5047 17.6346V20.644C21.6975 20.8796 13.9191 22.2774 13.9191 28.8276C13.9191 29.0175 14.053 29.1837 14.2409 29.2215C14.2668 29.2257 14.2942 29.2257 14.3194 29.2257Z"
                    fill="white"
                  />
                </svg>
              </p>
              {typeof showNext === "function" && showNextBtn ? (
                <>
                  <p>
                    <small>Next Doc</small>
                  </p>
                  <p>
                    <svg
                      width="45"
                      height="44"
                      onClick={() => showNext("PRESCRIPTION", 1)}
                      className={"cursor"}
                      viewBox="0 0 45 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.609375"
                        width="44"
                        height="44"
                        rx="6.6383"
                        fill="#23BCB5"
                      />
                      <path
                        d="M14.6094 16L20.6094 21.3981L14.6094 27"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24.6094 16L30.6094 21.3981L24.6094 27"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </>
              ) : (
                ""
              )}
              {typeof showPrev === "function" && showPrevBtn ? (
                <>
                  <p>
                    <small>Prev Doc</small>
                  </p>
                  <p>
                    <svg
                      width="45"
                      onClick={() => showPrev("PRESCRIPTION", 0)}
                      className={"cursor"}
                      height="44"
                      viewBox="0 0 45 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.71875"
                        width="44"
                        height="44"
                        rx="6.6383"
                        fill="#23BCB5"
                      />
                      <path
                        d="M30.6094 17L24.6094 22.3981L30.6094 28"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.6094 17L14.6094 22.3981L20.6094 28"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </>
              ) : (
                ""
              )}
              {typeof deleteDocuments === "function" && !isDoctor ? (
                <>
                  <p>
                    <small>Delete</small>
                  </p>
                  <p>
                    <svg
                      width="48"
                      height="48"
                      onClick={() => deleteDocuments(id)}
                      className={"cursor"}
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.335938"
                        y="0.671387"
                        width="46.8436"
                        height="46.8436"
                        rx="7.06732"
                        fill="white"
                      />
                      <path
                        d="M17.1719 31.0752C17.1719 32.1752 18.0719 33.0752 19.1719 33.0752H27.1719C28.2719 33.0752 29.1719 32.1752 29.1719 31.0752V19.0752H17.1719V31.0752ZM30.1719 16.0752H26.6719L25.6719 15.0752H20.6719L19.6719 16.0752H16.1719V18.0752H30.1719V16.0752Z"
                        fill="#B2403A"
                      />
                    </svg>
                  </p>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default FileViewLayout;
