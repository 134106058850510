import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import AccountRouteFetcher from "../AccountRouteFetcher";
import { ForgetPasswordPage } from "../pages/auth/components/forget-password.page";
import { LoginPage } from "../pages/auth/components/login.page";
import LandingPage from "../pages/landing.page";
import { NotFoundPage } from "../pages/not-found.page";
import { RedirectPage } from "../pages/redirect";
import WebformLeadsPage from "../pages/web-form-leads";
import { useQuery } from "../shared/Utils/utilities";
import { RootState } from "../shared/constants";
import {
  ROUTE_FORGET_PASSWORD,
  ROUTE_LANDING,
  ROUTE_LOGIN,
  ROUTE_PAGE_NOT_FOUND,
  ROUTE_REDIRECT_URL,
  ROUTE_WEBFORM_LEADS,
} from "../shared/routes/route.constants";
import CRMRoutes from "./crm.routes";

function RouteGuard({ ws, app, ...rest }: any) {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);
  const { profile } = useSelector((state: RootState) => state.profile);
  const token2 = localStorage.getItem("token");
  let query = useQuery();
  useEffect(() => {
    checkRedirect();
  }, [token, profile]);
  const checkRedirect = async () => {
    const redirectUrl = query.get("redirect_url") || "";
    const redirectUrlStorage: any = await localStorage.getItem("redirect_url");
    if (redirectUrl && redirectUrl != "") {
      if (token && profile) {
        if (redirectUrlStorage == "" || !redirectUrlStorage) {
          localStorage.removeItem("redirect_url");
          window.location.href = redirectUrl;
        }
      } else {
        localStorage.setItem("redirect_url", redirectUrl);
      }
    }
  };
  const App: any = CRMRoutes;
  console.log("================================", token, profile);
  return (
    <Route
      {...rest}
      render={
        (props) =>
          token && profile ? (
            <AccountRouteFetcher>
              {" "}
              <App {...props} ws={ws} />
            </AccountRouteFetcher>
          ) : (
            <>
              <Route
                path={ROUTE_PAGE_NOT_FOUND()}
                exact={true}
                component={NotFoundPage}
              />
              <Route
                path={ROUTE_LOGIN(":account", ":source")}
                exact={true}
                component={LoginPage}
              />
              <Route
                path={ROUTE_FORGET_PASSWORD(":account", ":source", ":number")}
                exact={true}
                component={ForgetPasswordPage}
              />
              <Route
                path={ROUTE_LANDING(":account")}
                exact={true}
                component={LandingPage}
              />
              <Route
                path={ROUTE_WEBFORM_LEADS(":account")}
                exact={true}
                component={WebformLeadsPage}
              />
              <Route
                path={ROUTE_REDIRECT_URL(":id")}
                exact={true}
                component={RedirectPage}
              />
              {/* <Redirect
                to={ROUTE_LOGIN(ACCOUNT_PREFIX, "crm")} */}
              {/* <Redirect
                to={ROUTE_LANDING(":account")}
                // to={{
                //   // pathname: ROUTE_LOGIN(account?.slug, app),
                //   pathname: "/" + ACCOUNT_PREFIX,
                //   state: { from: rest.location },
                // }}
              /> */}
            </>
          )
        // <App {...props} />
      }
    />
  );
}

export default RouteGuard;
