import { CopyOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useState } from "react";

function TextCopier({ text = "" }: any) {
  const [tooltip, setTooltip] = useState("Copy" as any);
  const copyText = () => {
    setTooltip("Copied");
    navigator.clipboard.writeText(text);
  };
  const resetTooltipText = () => {
    setTimeout(() => {
      setTooltip("Copy");
    }, 200);
  };
  return (
    <>
      {text && (
        <Tooltip onVisibleChange={resetTooltipText} title={tooltip}>
          <Button
            type="link"
            className="copy-answer"
            onClick={() => copyText()}
            icon={<CopyOutlined />}
          ></Button>
          {/* <CopyOutlined onClick={copyText} className="text-secondary" /> */}
        </Tooltip>
      )}
    </>
  );
}

export default TextCopier;
