import { Col, Row } from "antd";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchMedicinesById } from "../../../../redux/reducers/pharmacy.slice";
import { RootState } from "../../../../shared/constants";
import PlaceOrderComponent from "../../../../shared/layouts/pharmacy-place-order.layout";
import SelectedMedicine from "../../../dependencies/selected-medicine.component";
type Props = {
  contact: any;
  lead?: any;
  ticket?: any;
  orderId: any;
};
function OrderDetailsComponent({ contact, lead, ticket, orderId }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pharmacyDetails } = useSelector((state: RootState) => state.pharmacy);
  useEffect(() => {
    orderId && dispatch(fetchMedicinesById(orderId));
  }, [orderId]);
  return (
    <Fragment>
      <div className="pd20">
        {pharmacyDetails && (
          <Row className="bordered-grey-color">
            <Col className="pd20 grey-background" span={14}>
              <SelectedMedicine />
            </Col>
            <Col span={10}>
              <PlaceOrderComponent />
            </Col>
          </Row>
        )}
      </div>
    </Fragment>
  );
}

export default OrderDetailsComponent;
