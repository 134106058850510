///////////// Pharmacy Routes
export const ROUTE_PHARMACY_ORDER_QC = '/order/pharmacy/order-qc';
export const ROUTE_PHARMACY_CREATE_QC = (id: string) => `/order/pharmacy/${id}/create-qc`;
export const ROUTE_PHARMACY_RX_CREATION = '/order/pharmacy/order-rx';
export const ROUTE_PHARMACY_CREATE_RX = (id: string) => `/order/pharmacy/${id}/create-rx`;

// Orders API routes
export const FETCH_PHARMACY_ORDER_REQUEST = "pharmacy/get-order-requests";
export const CREATE_PHARMACY_ORDER_REQUEST = "pharmacy/create-request";
export const FETCH_MEDICINES_BY_ID = (id: string) =>
  `pharmacy/get-request-items/${id}`;
export const FETCH_STATIONS_LIST = "pharmacy/get-station-master";
export const FETCH_RX_MASTER_LIST = "pharmacy/get-rx-master";
export const FETCH_PATIENT_ADDRESS = "patients/get-my-addresses";
export const FETCH_MEDICINE_ITEMS = "pharmacy/item-list";
export const ADD_NEW_MEDICINE = "pharmacy/add-request-items";
export const DELETE_MEDICINE = "pharmacy/delete-items";
export const ADD_NEW_ADDRESS = "patients/add-address";
export const PROCEED_QC_REQUEST = "pharmacy/update-status";
export const PLACE_NEW_ORDER = "pharmacy/place-order";
export const UPDATE_MEDICINE_ITEM = (id: string) =>
  `pharmacy/update-items/${id}`;
export const UPDATE_PHARMACY_DETAIL = (id: string) => `pharmacy/request/${id}`;
export const FETCH_STATIONS_FROM_ADDRESS = `pharmacy/get-hospital-master`;
export const FETCH_STATIONS_FROM_ADDRESS_BY_ID = `pharmacy/get-pharmacies-within-distance`;
export const FETCH_SUBSTITUTE = (itemCode: string, stationCode: string) =>
  `pharmacy/get-drug-substitute?item_code=${itemCode}&station_id=${stationCode}`;
export const FETCH_MEDICINE_PRICE_INVENTORY = `pharmacy/fetch-price-inventory`;
export const FETCH_PRESCRIPTION_SIGNED_URL = (id: string) => `fileuploads/path/${id}`;
export const FETCH_ORDERS = 'orders';
export const UPDATE_REQUEST_STATUS = (id: string) => `orders/update-status/${id}`
export const SUMMARY_TABLE_COUNT = 'orders/order-count';
export const FETCH_CITY = 'master/city-search';
export const FETCH_PAYMENT_STATUS = (id: string) => `payment/verify-payment/${id}`;
export const FETCH_ORDER_DETAILS_BY_ID = (id: string) => `orders/${id}`;
export const FETCH_BRANCHES = 'branches';
export const FETCH_PRESCRIPTION_BY_ORDER = (id: string) => `prescription/order/${id}`;
export const FETCH_PAYMENT_SUMMARY = 'orders/get-payment-detail';
export const PAYMENT_REFUND = (id: string) => `payment/refund/${id}`;
export const ORDER_BY_LEADID = (id: string) =>
  `pharmacy/get-order-by-leadid/${id}`;
export const MAP_PRESCRIPTION_CART = (id: string) => `pharmacy/link-file-to-request/${id}`;
export const DELETE_PRESCRIPTION_CART = (id: string) => `pharmacy/delete-file-from-request/${id}`;