import {
  CheckCircleFilled,
  SettingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  PageHeader,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  facebookGetPagesByProfileId,
  facebookGetProfile,
  facebookSubscribePage,
  getFormsByPageId,
  syncLeadsByFormAndPageId,
} from "../../../../redux/reducers/social.slice";
import { formatDate } from "../../../../shared/Utils/utilities";
import { RootState } from "../../../../shared/constants";
import CreateLeadForm from "../../Leads/components/createleadform";
import AddTicket from "../../Tickets/components/add-ticket";
import HeaderButtons from "../../components/headerbuttons";
import FormSettingsComponent from "./components/form-settings";
import PageSettingsComponent from "./components/page-settings";
declare global {
  interface Window {
    FB: any;
  }
}

function FacebookPages() {
  const dispatch = useDispatch();
  const { profileGetLoading, profileData } = useSelector(
    (state: RootState) => state.social
  );
  const [leadFormVisible, setLeadFormVisible] = useState(false);
  const [ticketFormVisible, setTicketFormVisible] = useState(false);
  const [pageSettings, setPageSettings] = useState(false);
  const [connected, setConnected] = useState(false);
  const [pages, setPages] = useState([] as any);
  const [forms, setForms] = useState([] as any);
  const [selectedPageId, setSelectedPageId] = useState(null as any);
  const [syncDisabled, setSyncDisabled] = useState(false as any);
  const [selectedForm, setSelectedForm] = useState(false as any);
  const [formSettingsModalVisible, setFormSettingsModalVisible] = useState(
    false as any
  );
  // const [userToken, setUserToken] = useState("");
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  const accountId = localStorage.getItem("account_id");
  const FB = window.FB;
  const getFacebookProfile = () => {
    dispatch(facebookGetProfile(accountId));
  };
  useEffect(() => {
    if (profileData?.fb_id) {
    } else {
      getFacebookProfile();
    }
  }, []);

  useEffect(() => {
    if (profileData?.fb_id) {
      getPagesList();
    }
  }, [profileData]);

  useEffect(() => {
    if (pages?.length > 0 && selectedPageId === null) {
      setSelectedPageId(pages[0].page_id);
    }
  }, [pages]);

  useEffect(() => {
    getFormsByPage(selectedPageId);
  }, [selectedPageId]);

  const getPagesList = () => {
    dispatch(facebookGetPagesByProfileId(profileData?.fb_id)).then(
      (val: any) => {
        if (val?.payload?.data?.data?.length > 0) {
          setPages(val.payload.data.data);
        }
      }
    );
  };
  const subscribeLeadPage = (pageId: string) => {
    dispatch(facebookSubscribePage(pageId)).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        message.success("Leads from this page are subscribed!");
        getPagesList();
      }
    });
  };
  const getFormsByPage = (pageId: string) => {
    if (pageId) {
      dispatch(getFormsByPageId(pageId)).then((val: any) => {
        if (val?.payload?.data?.statusCode === 200) {
          setSyncDisabled(false);
          setForms(
            val.payload.data.data?.map((val: any) => {
              return {
                ...val,
                form_questions:
                  typeof val.form_questions == "string"
                    ? JSON.parse(val.form_questions)
                    : val.form_questions,
                question_count:
                  typeof val.form_questions == "string"
                    ? JSON.parse(val.form_questions)?.length
                    : val.form_questions?.length,
                last_synced: "--",
                // lead_count: 20,
              };
            })
          );
        }
      });
    }
  };
  const syncLeads = (data: any) => {
    setSyncDisabled(true);
    dispatch(
      syncLeadsByFormAndPageId({ page_id: data.page_id, form_id: data.form_id })
    ).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        message.success("Sync stated successfully");
        setTimeout(() => {
          getFormsByPage(data.page_id);
        }, 1000);
      }
    });
    // val.form_id
    // val.page_id
  };
  const columns: any = [
    {
      title: "Form Name",
      dataIndex: "form_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "capitalize",
    },
    {
      title: "Questions",
      dataIndex: "question_count",
    },
    {
      title: "No. of Leads Synced",
      dataIndex: "total_leads",
    },
    {
      title: "Last Synced",
      dataIndex: "last_sync_time",
      render: (a: any) => {
        return a !== null ? formatDate(a) : "--";
      },
    },
    {
      title: "Actions",
      fixed: "right",
      render: (a: any) => {
        return (
          <Space size={16}>
            <Tooltip title="Sync Leads">
              <Button
                type="link"
                className="bold-600 text-primary"
                disabled={lastActivityCheck(a) || syncDisabled}
                onClick={() => syncLeads(a)}
                icon={<SyncOutlined />}
              ></Button>
            </Tooltip>
            <Tooltip title="Settings">
              <Button
                type="link"
                className="bold-600 text-primary"
                // disabled={lastActivityCheck(a) || syncDisabled}
                onClick={() => {
                  setSelectedForm(a);
                  setFormSettingsModalVisible(true);
                }}
                icon={<SettingOutlined />}
              ></Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  const lastActivityCheck = (data: any) => {
    if (data?.last_sync_time) {
      if (moment().diff(data.last_sync_time) < 24 * 3600 * 1000) {
        return true;
      }
    }
    return false;
  };
  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title={
          <>
            <Row
              style={screenType.mobile ? { paddingLeft: "25px" } : {}}
              align="middle"
            >
              <Col flex="none">Facebook Lead Pages</Col>
              <Col>
                {connected ? (
                  <span className="follow-up-pending-header">
                    <CheckCircleFilled className="text-success" /> Connected
                  </span>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </>
        }
        extra={
          <HeaderButtons
            setLeadFormVisible={setLeadFormVisible}
            setTicketFormVisible={setTicketFormVisible}
          />
        }
      ></PageHeader>
      {pages?.length > 0 && (
        <Row className="padding20" gutter={16}>
          <Col span={24} className="crm-tabs">
            <Card className="nopaddingcard crm-tabs ticket-details-tabs">
              <Tabs
                defaultActiveKey={pages[0].page_id}
                onChange={(key: string) => {
                  setSelectedPageId(key);
                }}
              >
                {pages.map((page: any) => {
                  return (
                    <>
                      <Tabs.TabPane tab={page.page_name} key={page.page_id}>
                        {page?.is_subscribed &&
                          page?.is_subscribed !== "false" &&
                          forms?.length > 0 && (
                            <Row>
                              <Col span={24} className="pd10">
                                <Row justify="end">
                                  <Col>
                                    <Button
                                      onClick={() => setPageSettings(true)}
                                      type="link"
                                      icon={<SettingOutlined />}
                                    ></Button>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={24}>
                                <Table
                                  className="crm-table"
                                  loading={false}
                                  scroll={{
                                    x: 1200,
                                    y: "calc(100vh - " + 100 + "px)",
                                  }}
                                  dataSource={forms}
                                  columns={columns}
                                />
                              </Col>
                            </Row>
                          )}

                        {(!page?.is_subscribed ||
                          page?.is_subscribed === "false") && (
                          <Row align="middle" style={{ height: "80vh" }}>
                            <Col span={24} className="text-center padding20">
                              <>
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    subscribeLeadPage(page.page_id)
                                  }
                                >
                                  Subscribe Leads
                                </Button>
                              </>
                            </Col>
                          </Row>
                        )}
                      </Tabs.TabPane>
                    </>
                  );
                })}
              </Tabs>
            </Card>
          </Col>
        </Row>
      )}
      {leadFormVisible && (
        <CreateLeadForm
          saveLeadSuccess={() => setLeadFormVisible(false)}
          setLeadFormVisible={(val: any) => setLeadFormVisible(val)}
          leadFormVisible={leadFormVisible}
        ></CreateLeadForm>
      )}
      {ticketFormVisible && (
        <AddTicket
          onTicketSave={() => setTicketFormVisible(false)}
          setTicketFormVisible={(val: any) => setTicketFormVisible(val)}
          ticketFormVisible={ticketFormVisible}
        ></AddTicket>
      )}
      {pageSettings && (
        <PageSettingsComponent
          pageData={
            pages.filter((page: any) => {
              return page.page_id === selectedPageId;
            })[0]
          }
          reloadPages={getPagesList}
          modalVisible={pageSettings}
          setModalVisible={setPageSettings}
        ></PageSettingsComponent>
      )}
      {selectedForm && formSettingsModalVisible && (
        <FormSettingsComponent
          pageData={
            pages.filter((page: any) => {
              return page.page_id === selectedPageId;
            })[0]
          }
          formDetails={selectedForm}
          modalVisible={formSettingsModalVisible}
          setModalVisible={setFormSettingsModalVisible}
        ></FormSettingsComponent>
      )}
    </Fragment>
  );
}

export default FacebookPages;
