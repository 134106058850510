import i18n from "i18next";
import React from "react";
import { render } from "react-dom";
import { initReactI18next } from "react-i18next";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./adConfig";
import AZSSA_JSON from './azssa.json';
import CIPLA_JSON from "./cipla.json";
import "./cognitoConfig";
import EN_JSON from "./en.json";
import GALDERMA_JSON from "./galderma.json";
import HERHEALTH_JSON from './herhealth.json';
import INVISALIGN_JSON from './invisalign.json';
import JARVIS_JSON from "./jarvis.json";
import LUPIN_JSON from "./lupin.json";
import LYFELINE_JSON from "./lyfeline.json";
import MEDANTA_JSON from "./medanta.json";
import NASOHEALTH_JSON from './nasohealth.json';
import REGENCY_JSON from "./regency.json";
import SUNPHARMA_JSON from './sunpharma.json';

import "./cognitoConfig";
import { initFacebookSdk } from "./fbConfig";

export const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  // console.log("acc found", accounts);
  msalInstance.setActiveAccount(accounts[0]);
} else {
  console.log("no accout found");

}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: EN_JSON,
      cipla: CIPLA_JSON,
      lupin: LUPIN_JSON,
      regency: REGENCY_JSON,
      medanta: MEDANTA_JSON,
      sunpharma: SUNPHARMA_JSON,
      galderma: GALDERMA_JSON,
      jarvis: JARVIS_JSON,
      lyfeline: LYFELINE_JSON,
      invisalign: INVISALIGN_JSON,
      azssa: AZSSA_JSON,
      herhealth: HERHEALTH_JSON,
      nasohealth: NASOHEALTH_JSON

    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
// serviceWorkerRegistration.register();

const startApp = () => {
  render(
    <React.StrictMode>
      <App pca={msalInstance} />
    </React.StrictMode>,
    document.getElementById("root")
  );
};
initFacebookSdk().then(startApp);
// serviceWorkerRegistration.register();
// serviceWorkerRegistration.registerFirebase();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
