import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../shared/Utils/api-client";
import {
  ACTIONMAP,
  ACTIONMAPPATCH,
  ASSIGNMENTPATCH,
} from "../../shared/routes/crm.routes.constants";
const config = {
  name: "assignment",
};
export const fetchActionMap: any = createAsyncThunk(
  `${config.name}/fetchActionMap`,
  async (params: any) => {
    return await Client.get(ACTIONMAP, { params });
  }
);

export const addNewActionMap: any = createAsyncThunk(
  `${config.name}/addNewActionMap`,
  async (params: any) => {
    return await Client.post(ACTIONMAP, { ...params });
  }
);

export const editActionMap: any = createAsyncThunk(
  `${config.name}/editActionMap`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(ACTIONMAPPATCH(id), data);
  }
);

export const editAssignment: any = createAsyncThunk(
  `${config.name}/editAssignment`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.post(ASSIGNMENTPATCH(id), data);
  }
);

export const actionmap = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    actionmap: [] as any,
    pagination: { offset: 0, limit: 10, total: 0 },
    error: "" as string,
  },
  reducers: {
    reset: (state) => {
      state.actionmap = [];
      state.loading = false;
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      //  Fetch contacts
      .addCase(fetchActionMap.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchActionMap.fulfilled, (state, action) => {
        console.log("new", action.payload);
        state.loading = false;
        state.actionmap = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchActionMap.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

//export const {} = actionmap.actions;

export default actionmap.reducer;
