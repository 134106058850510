import { Button, Col, Divider, Form, Input, Modal, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import {
  fetchTeamById,
  fetchTeams
} from "../../../../redux/reducers/team.slice";
import { formatDropDownValue } from "../../../../shared/Utils/utilities";
import { TASK_MODULE_ACTIONS_HIDE_BRANCH } from "../../../../shared/components/permission.constants";
import { RootState } from "../../../../shared/constants";


const { Option } = Select;

type Props = {
  onSaveContact: (val: any) => void;
  onEditContact: (val: any) => void;
  onSaveContactFailed: (val: any) => void;
  modalVisible: boolean;
  setmodalVisible: (val: any) => void;
  formLoading: boolean;
  gender?: any;
  nationality?: any;
  typess: any;
  initialValues?: any;
  editData: any;
};

function CreateAction({
  editData,
  typess,
  onSaveContact,
  onEditContact,
  onSaveContactFailed,
  modalVisible,
  setmodalVisible,
  formLoading,
  gender,
  nationality,
  initialValues,
}: Props) {
  const dispatch = useDispatch();
  let { loading, teams, pagination } = useSelector(
    (state: RootState) => state.team
  );
  const { branches } = useSelector((state: RootState) => state.branch);
  const { loadings, team, paginations } = useSelector(
    (state: RootState) => state.team
  );
  const [mode, setmode] = useState(typess === "edit" ? editData?.mode : "");
  const [form] = useForm();

  initialValues = {
    user_id: typess === "edit" ? editData?.user_id : null,
    role: typess === "edit" ? editData?.role : "agent",
    active: typess === "edit" && editData && editData?.active ? true : false,
    agent_id: typess === "edit" ? editData?.agent_id : null,
    branch_id: typess === "edit" ? editData?.branch_id : null,
  };


  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const handleChangeMode = (value: any) => {
    setmode(value);
  };

  console.log("ddddddddddddddddddddddd", myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH))

  useEffect(() => {
    if (branches.length > 0) {
      form.setFieldsValue({
        branch_id: branches[0]?.id,
      });
    }
  }, [branches]);

  const onBranchChange = (branch: any) => {
    form.setFieldsValue({
      branch_id: branch || null
    })
  }

  console.log("branches", branches)

  const handleChangeTeam = (value: any) => {
    dispatch(fetchTeamById(value));
  };

  useEffect(() => {
    if (typess === "edit" && editData?.user?.teams[0]) {
      dispatch(fetchTeamById(editData?.user?.teams[0]?.team_id));
    }
  }, [typess]);

  useEffect(() => {
    branches?.length == 0 && dispatch(fetchBranches({}));
  }, [])


  const handleCloseModal = () => {
    setmodalVisible(false);
    initialValues = {};
    editData = {};
  };

  useEffect(() => {
    dispatch(fetchTeams({ limit: 300 }));
    setmode(typess === "edit" ? editData?.mode : "");
  }, []);

  const onSaveContactLocal = (e: any) => {
    onSaveContact({ ...e });
  };

  return (
    <Modal
      title={typess === "edit" ? "Edit Agent Details" : "Agent Details"}
      centered
      visible={modalVisible}
      onCancel={() => handleCloseModal()}
      footer={null}
      className="modalFormButton"
      style={{ paddingBottom: "0px" }}
    >
      <Form
        form={form}
        className="paddingx20 paddingtop20 crm-form full-width"
        initialValues={initialValues}
        onFinish={typess === "edit" ? onEditContact : onSaveContactLocal}
        onFinishFailed={onSaveContactFailed}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Team"
              rules={[{ required: true, message: "Please choose Team" }]}
            >
              <Select
                allowClear={true}
                showSearch
                optionFilterProp="children"
                className="capitalize"
                disabled={typess === "edit" ? true : false}
                defaultValue={editData?.user?.teams[0]?.team_id}
                onChange={handleChangeTeam}
              >
                {teams?.map((val: any) => {
                  return (
                    <Option value={val.id}>{val.name}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name={"user_id"}
              label="User"
              rules={[{ required: true, message: "Please choose User" }]}
            >
              <Select
                allowClear={true}
                showSearch
                optionFilterProp="children"
                disabled={typess === "edit" ? true : false}
                defaultValue={editData?.user?.teams[0]?.user_id}
                className="capitalize"
              >
                {team?.users.length > 0 &&
                  team?.users?.map((val: any) => {
                    return (
                      <Option className="capitalize" value={val.user_id}>
                        {val?.user?.first_name} {val?.user?.last_name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="agent_id" label="Agent Id">
              <Input type={"text"} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {
            myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH) !== -1 ? [] : <Col span={24}>
              <Form.Item
                name={"branch_id"}
                label="Hospital"
              // rules={[{ required: true, message: "Please select hospital" }]}
              >
                <Select
                  allowClear={true}
                  showSearch
                  onChange={onBranchChange}
                  optionFilterProp="children"
                  className="capitalize"
                >
                  {branches.map((e: any) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {/* <span >{e.name}</span> */}
                        {formatDropDownValue(e.name)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          }

        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="active" label="Active">
              <Select onChange={handleChangeMode}>
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="role" label="Role">
              <Input disabled={true} defaultValue="agent" />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row className="button-row">
          <Col span={24}>
            <Form.Item>
              <Button
                loading={loading}
                size="large"
                type="primary"
                htmlType="submit"
                block
              >
                {" "}
                {typess === "edit" ? `Update` : `Save`}{" "}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* <FormBar name={'CreateAction'} loading={formLoading} onFinish={formElements.onFinish} onFinishFailed={formElements.onFinishFailed} formElements={formElements.formElements} initialValues={formElements.initialValues} buttonText={'Save'}></FormBar> */}
    </Modal>
  );
}

export default CreateAction;
