import { Col, PageHeader, Row } from "antd";
import React, { Fragment, useState } from "react";
import TabsLayout from "../../../shared/layouts/tabs.layout";
import HeaderButtons from "../components/headerbuttons";
import CreateLeadForm from "../Leads/components/createleadform";
import AddTicket from "../Tickets/components/add-ticket";
import LiveStatusTab from "./components/livestatustab";
import PerformanceTrackerTab from "./components/performancetrackertab";

function CRMDashboardPage() {
  const [leadFormVisible, setLeadFormVisible] = useState(false);
  const [ticketFormVisible, setTicketFormVisible] = useState(false);
  const onTabChange = (e: any) => {};

  var allTabs = [
    {
      title: "Live Status",
      component: (
        <Row className="padding20">
          <Col span={24}>
            <LiveStatusTab />
          </Col>
        </Row>
      ),
    },
    {
      title: "Performance Tracker",
      component: (
        <Row className="padding20">
          <Col span={24}>
            <PerformanceTrackerTab />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title="Dashboard"
        extra={
          <HeaderButtons
            setLeadFormVisible={setLeadFormVisible}
            setTicketFormVisible={setTicketFormVisible}
          />
        }
      ></PageHeader>
      <Row className="crm-dashboard">
        <Col span={24} className="crm-tabs">
          <TabsLayout AllTabs={allTabs} onChange={onTabChange}></TabsLayout>
        </Col>
      </Row>
      {leadFormVisible && (
        <CreateLeadForm
          saveLeadSuccess={() => setLeadFormVisible(false)}
          setLeadFormVisible={(val: any) => setLeadFormVisible(val)}
          leadFormVisible={leadFormVisible}
        ></CreateLeadForm>
      )}
      {ticketFormVisible && (
        <AddTicket
          onTicketSave={() => setTicketFormVisible(false)}
          setTicketFormVisible={(val: any) => setTicketFormVisible(val)}
          ticketFormVisible={ticketFormVisible}
        ></AddTicket>
      )}
    </Fragment>
  );
}

export default CRMDashboardPage;
