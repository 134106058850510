import { Button, Col, Empty, message, Modal, Row, Skeleton, Tabs } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import {
  addAppointemntNote, fetchAppointmentById, resetAppointments,
  updateAppointemntNote
} from "../../../../redux/reducers/appointment.slice";
import { fetchMasterTaskDropDown } from "../../../../redux/reducers/master.slice";
import { fetchPatientsDocuments } from "../../../../redux/reducers/patients.slice";
import { fetchPrescriptions } from "../../../../redux/reducers/prescription.slice";
import {
  fetchPatientDocumentFileById,
  fetchUploadedFileById,
  updatePatientDocumentQC
} from "../../../../redux/reducers/upload.slice";
import { CLIENT_NAME, RootState } from "../../../../shared/constants";
import FileViewLayout from "../../../../shared/layouts/file-view.layout";
import FilesScroller from "../../../../shared/layouts/files-scroller.layout";
import FormBar from "../../../../shared/layouts/formbar.layout";
import ReorderComponent from "../../../../shared/layouts/reorder.layout";
import TimelineLayout from "../../../../shared/layouts/timeline.layout";
import { formatDropDownValue } from "../../../../shared/Utils/utilities";
import GeneralTicketActions from "../../components/ticket-general-drawers";
import UpdateTicketForm from "./updateticketform";

const { confirm } = Modal;

var _ = require("lodash");

const { TabPane } = Tabs;

type Props = {
  ticket?: any;
  loading?: any;
  actionmapData?: any;
};

const TicketDetailsPrescriptionTab: React.FunctionComponent<Props> = ({
  ticket,
  actionmapData,
}) => {
  const dispatch = useDispatch();
  const { Documents } = useSelector((state: RootState) => state.patient);
  const [activePrescription, setActivePrescription] = useState(null as any);
  const { signedUrl, loading } = useSelector(
    (state: RootState) => state.upload
  );
  const { t } = useTranslation()
  const [signedUrlData, setSignedUrlData] = useState(null as any);
  const [file, setFile] = useState(null as any);
  const [QCForm, setQCForm] = useState(null as any);
  const { taskDropDown } = useSelector((state: RootState) => state.master);
  const [qcstatus, setQCStatus] = useState([] as any);
  const [dropdown_name_id, setDropdownStateID] = useState(null as any);
  const { prescriptions } = useSelector(
    (state: RootState) => state.prescription
  );
  const { appointment } = useSelector((state: RootState) => state.appointment);
  const [activeTab, setActiveTab] = useState(2 as any);
  const [maxForm, setMaxForm] = useState(null as any);
  const [patientfiles, setpatientfiles] = useState(null as any);
  const [maxfiles, setmaxfiles] = useState(null as any);
  const [shouldBlockNavigation, setshouldBlockNavigation] = useState(false);
  // const [remarksVal, setremarksVal] = useState("");

  const [allQCDone, setAllQCDone] = useState(null as any);
  const [rearrangeDocs, setRearrangeDocs] = useState(false as any);

  const [initAction, setInitAction] = useState(false as any);

  useEffect(() => {
    return () => {
      dispatch(resetAppointments())
    }
  }, [])

  // useEffect(() => {
  //   ticket?.services[0]?.appointment_id &&
  //     dispatch(fetchAppointmentById(ticket?.services[0]?.appointment_id));
  // }, [ticket]);

  useEffect(() => {
    if (taskDropDown.length > 0) {
      let temp;
      temp = taskDropDown.filter((val: any) => {
        return val.name == "qc-status" && val.mode == "ticket";
      });
      setDropdownStateID(temp.id);
      let vals;
      if (temp.length > 0) {
        vals = temp[0].values.map((val: any) => {
          return { name: formatDropDownValue(val.name), value: val.id };
        });
        setQCStatus(vals);
      }
    }
  }, [taskDropDown]);
  useEffect(() => {
    taskDropDown.length == 0 && dispatch(fetchMasterTaskDropDown());
  }, []);
  useEffect(() => {
    return () => {
      localStorage.removeItem("remarksVal");
    };
  }, []);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishTicket = useCallback(
    (values: any) => {
      // dispatch(fetchTicketsDetails(ticket.id));
      setshouldBlockNavigation(false);
      if (actionmapData?.action_name === "case_summary") {
        const remarks = appointment?.notes?.filter(
          (val: any) => {
            return val.type == "case_summary";
          }
        )[0]?.note;
        const remarksVal = localStorage.getItem("remarksVal");
        if (remarksVal !== remarks) {
          addCaseSummary({ remarks: remarksVal });
        }
      }
    },
    [actionmapData]
  );

  useEffect(() => {
    getFiles(ticket);
    ticket?.services && ticket?.services[0]?.appointment_id && dispatch(fetchAppointmentById(ticket?.services[0]?.appointment_id))
    !loading && dispatch(fetchPatientsDocuments({ appointment_id: ticket?.services[0]?.appointment_id, patient_id: appointment?.patient_id }))
  }, [ticket]);


  const getFiles = (ticket: any) => {

    if (actionmapData?.action_name === "case_summary") {
      setActiveTab(1);
      appointment?.patient_id && dispatch(
        fetchPrescriptions({
          patient_id: appointment?.patient_id,
        })
      );
      appointment?.patient_id && ticket?.services[0]?.appointment_id && dispatch(
        fetchPatientsDocuments({
          appointment_id: ticket?.services[0]?.appointment_id,
          patient_id: appointment?.patient_id,
        })
      );
    } else {
      setActiveTab(2);
      appointment?.patient_id && ticket?.services[0]?.appointment_id && dispatch(
        fetchPatientsDocuments({
          appointment_id: ticket?.services[0]?.appointment_id,
          patient_id: appointment?.patient_id,
        })
      );
    }
  };

  const addCaseSummary = (values: any) => {
    if (formatDropDownValue(ticket?.status?.name) !== "CLOSED") {
      if (values.remarks === undefined) {
        message.error("Add Case Summary");
        return;
      } else {
        let remarksExists = appointment?.notes?.filter(
          (val: any) => {
            return val.type == "case_summary";
          }
        );
        if (remarksExists.length > 0) {
          dispatch(
            updateAppointemntNote({
              id: remarksExists[0].id,
              data: {
                note: values.remarks,
                appointment_id: ticket?.services[0]?.appointment_id,
                type: "case_summary",
                note_by: "staff",
              },
            })
          ).then(() => {
            message.success("Updated Successfully");
          });
        } else {
          dispatch(
            addAppointemntNote({
              note: values.remarks,
              appointment_id: ticket?.services[0]?.appointment_id,
              type: "case_summary",
              note_by: "staff",
            })
          ).then(() => {
            message.success("Added Successfully");
          });
        }
      }
    } else {
      message.error("Update Failed! This ticket is closed.");
    }
  };
  useEffect(() => {
    let QCLeft = 0;
    let allDocs = Object.assign(Documents);
    allDocs = allDocs.slice().sort((a: any, b: any) => {
      return a.index > b.index ? 1 : -1;
    });
    if (allDocs.length > 0) {
      setpatientfiles([
        {
          title: "Prescription",
          files: _.filter(allDocs, { type: "PRESCRIPTION" }).map(
            (item: any) => {
              if (!item.dropdown_value_id) {
                ++QCLeft;
              }
              if (item.type === "PRESCRIPTION") {
                return {
                  title: formatDropDownValue(
                    `${item.source || item.doctor_name
                      ? (item.source ? item.source : "") +
                      " " +
                      (item.doctor_name ? item.doctor_name : "")
                      : item.name.substr(0, 15)
                    }`
                  ),
                  subtext: moment(item.date).format("DD MMM'YY"),
                  badge: qcstatus.filter(
                    (a: any) => a.value === item?.dropdown_value_id
                  )[0]?.name,
                  img: item.content_type.split("/")[0],
                  id: item.id,
                  allData: item,
                };
              }
            }
          ),
        },
        {
          title: "Lab Reports",
          files: _.filter(allDocs, { type: "LABREPORT" }).map((item: any) => {
            if (!item.dropdown_value_id) {
              ++QCLeft;
            }
            if (item.type === "LABREPORT") {
              return {
                title: formatDropDownValue(
                  `${item.source || item.test_name
                    ? (item.source ? item.source : "") +
                    " " +
                    (item.test_name ? item.test_name : "")
                    : item.name.substr(0, 15)
                  }`
                ),
                subtext: moment(item.date).format("DD MMM'YY"),
                badge: qcstatus.filter(
                  (a: any) => a.value === item?.dropdown_value_id
                )[0]?.name,
                img: item.content_type.split("/")[0],
                id: item.id,
                allData: item,
              };
            }
          }),
        },
      ]);
    }
    if (QCLeft != 0) {
      setAllQCDone(["CLOSED"]);
    } else {
      setAllQCDone([]);
    }
  }, [Documents, qcstatus]);

  useEffect(() => {
    initPatientFiles();
  }, [patientfiles]);
  function initPatientFiles() {
    if (patientfiles) {
      for (let k = 0; k < patientfiles.length; ++k) {
        for (let l = 0; l < patientfiles[k].files?.length; ++l) {
          if (!patientfiles[k].files[l].badge) {
            getPrescriptionDetails(patientfiles[k]?.files[l], "patient");
            return;
          }
        }
      }
      if (patientfiles[0]?.files.length > 0)
        getPrescriptionDetails(patientfiles[0]?.files[0], "patient");
      else if (patientfiles[1]?.files.length > 0)
        getPrescriptionDetails(patientfiles[1]?.files[0], "patient");
    }
  }
  function initMaxFiles() {
    if (maxfiles) {
      if (maxfiles[0]?.files.length > 0)
        getPrescriptionDetails(maxfiles[0]?.files[0], "doctor");
      else if (maxfiles[1]?.files.length > 0)
        getPrescriptionDetails(maxfiles[1]?.files[0], "doctor");
    }
  }
  useEffect(() => {
    let allDocs = Object.assign(prescriptions);
    allDocs = allDocs.slice().sort((a: any, b: any) => {
      return a.index > b.index ? 1 : -1;
    });

    setmaxfiles([
      {
        title: "Prescription",
        files: _.filter(allDocs, { type: "PRESCRIPTION" }).map((item: any) => {
          if (item.type === "PRESCRIPTION") {
            return {
              title: formatDropDownValue(
                `${item.source ? item.source : ""} ${item.doctor_name ? item.doctor_name : ""
                }`
              ),
              subtext: moment(item.date).format("DD MMM'YY"),
              badge: qcstatus.filter(
                (a: any) => a.value === item?.dropdown_value_id
              )[0]?.name,
              img: item.content_type.split("/")[0],
              id: item.data_id,
              allData: item,
            };
          }
        }),
      },
      {
        title: "Lab Reports",
        files: _.filter(allDocs, { type: "LABREPORT" }).map((item: any) => {
          if (item.type === "LABREPORT") {
            return {
              title: formatDropDownValue(
                `${item.source ? item.source : ""} ${item.test_name ? item.test_name : ""
                }`
              ),
              subtext: moment(item.date).format("DD MMM'YY"),
              badge: qcstatus.filter(
                (a: any) => a.value === item?.dropdown_value_id
              )[0]?.name,
              img: item.content_type.split("/")[0],
              id: item.id,
              allData: item,
            };
          }
        }),
      },
    ]);
  }, [prescriptions, qcstatus]);

  useEffect(() => {
    if (
      ticket &&
      (actionmapData?.action_name === "document_qc" ||
        actionmapData?.action_name === "document_qc_instant_vc") &&
      activePrescription
    ) {
      setQCFormEnvelope(activePrescription?.allData?.type);
    }
  }, [activePrescription]);

  // console.log("hii",maxForm?.initialValues?.remarks)

  function setQCFormEnvelope(e: any) {
    let type = e;
    setQCForm({
      heading: `Update ${t("CRM.Tickets.Fields.Ticket")}`,
      onFinish: onFinishQC,
      onFinishFailed: onFinishFailed,
      initialValues: {
        doc_type: type,
      },
      setValues: {
        doc_type: type,
        source: activePrescription?.allData?.source,
        doc_date: moment(activePrescription?.allData?.date, "YYYY-MM-DD"),
        doctor_name: activePrescription?.allData?.doctor_name,
        test_name: activePrescription?.allData?.test_name,
        remarks: activePrescription?.allData?.qc_comment,
        qc_status: activePrescription?.allData?.dropdown_value_id,
      },
      buttonText: "Save",
      buttonType: "default",
      formElements: [
        {
          label: "Type of Document",
          type: "Select",
          name: "doc_type",
          onChange: setQCFormEnvelope,
          options: [
            { value: "PRESCRIPTION", name: "Prescription" },
            { value: "LABREPORT", name: "Reports" },
          ],
          rules: [
            { required: true, message: "Please select type of document" },
          ],
        },
        type == "PRESCRIPTION"
          ? {
            label: "Prescription Source",
            type: "Select",
            name: "source",
            options: [
              { value: CLIENT_NAME, name: CLIENT_NAME + " Prescription" },
              {
                value: "Non " + CLIENT_NAME,
                name: "Non " + CLIENT_NAME + " Prescription",
              },
            ],
            rules: [
              { required: true, message: "Please select type of source" },
            ],
          }
          : {
            label: "Report Source",
            type: "Select",
            name: "source",
            options: [
              { value: CLIENT_NAME, name: CLIENT_NAME + " Lab" },
              { value: "Lal Path", name: "Lal Path Lab" },
              { value: "Metropolis", name: "Metropolis Lab" },
              { value: "SRL Diagnostics", name: "SRS Diagnostics" },
              { value: "Path Kind", name: "Path Kind Lab" },
              { value: "Thyrocare", name: "Thyrocare" },
              { value: "1Mg Labs", name: "1Mg Labs" },
              { value: "PharmEasy Labs", name: "PharmEasy Labs" },
              { value: "Healthians", name: "Healthians" },
              { value: "Niramaya", name: "Niramaya" },
              { value: "Others", name: "Others" },
            ],
            rules: [
              { required: true, message: "Please select type of source" },
            ],
          },
        {
          label: "Document Date",
          type: "DatePicker",
          name: "doc_date",
          rules: [{ required: true, message: "Please select document date" }],
          disabledDate: (d: any) => {
            return d > moment(moment().format("YYYY-MM-DD"));
          },
        },
        type == "PRESCRIPTION"
          ? {
            label: "Doctor Name",
            type: "Input",
            name: "doctor_name",
            rules: [{ required: true, message: "Please input doctor name" }],
          }
          : {
            label: "Test Name",
            type: "Input",
            name: "test_name",
            rules: [{ required: true, message: "Please input test name" }],
          },
        {
          label: "QC Status",
          type: "Radio",
          name: "qc_status",
          options: qcstatus.map((item: any) => {
            return { value: item.value, name: item.name };
          }),
          rules: [{ required: true, message: "Please select QC status" }],
        },
        {
          label: "Remarks",
          type: "TextArea",
          name: "remarks",
          placeholder: "Type remarks here...",
        },
      ],
    });
  }

  useEffect(() => {
    if (ticket && actionmapData?.action_name === "case_summary") {
      setMaxForm({
        sub_heading: "Complaints and Symptoms shared by patients",
        onFinish: addCaseSummary,
        onFinishFailed: onFinishFailed,
        setValues: {
          remarks: appointment?.notes?.filter(
            (val: any) => {
              return val.type === "case_summary";
            }
          )[0]?.note
        },
        initialValues: {
          // doc_type: 'prescription',
          // p_source: 'max_pres',
          // doc_date: moment(new Date(), 'YYYY-MM-DD'),
          // doctor_name: ticket.services[0]?.appointment?.doctor?.name,
          notes: appointment?.notes?.filter((val: any) => {
            return val.type == "symptom";
          })[0]?.note,
          remarks: appointment?.notes?.filter(
            (val: any) => {
              return val.type == "case_summary";
            }
          )[0]?.note,
        },
        buttonText: "Save",
        buttonType: "default",
        formElements: [
          {
            label: "",
            type: "Input",
            name: "notes",
            disabled: true,
          },
          {
            label: "Case Summary",
            type: "TextArea",
            name: "remarks",
            rows: 15,
            placeholder:
              "Enter Chief Complaints, History of Present Illness, Past History, Medication, Rx",
            onChange: onChangeRemarks,
          },
        ],
      });
    }
  }, [ticket, appointment]);

  const onFinishQC = (values: any) => {
    if (formatDropDownValue(ticket?.status?.name) !== "CLOSED") {
      (async function () {
        try {
          let data = {
            id: activePrescription.id,
            type: values?.doc_type,
            qc_comment: values?.remarks,
            source: values?.source,
            date: moment(values?.doc_date).format("YYYY-MM-DD"),
            doctor_name: values?.doctor_name,
            test_name: values?.test_name,
            dropdown_name_id: dropdown_name_id,
            dropdown_value_id: values.qc_status,
            rotation: localStorage.getItem("prescriptionRotation")
              ? localStorage.getItem("prescriptionRotation")
              : 0,
          };
          const response = await dispatch(updatePatientDocumentQC(data));
          if (
            response.payload.status === 201 ||
            response.payload.status === 200
          ) {
            // getPrescriptionDetails(activePrescription,"patient")
            getFiles(ticket);
            // updateQCStatusLocal(data)
          }
        } catch (e) {
          console.error(e);
        }
      })();
    } else {
      message.error("Update Failed! This ticket is closed.");
    }
  };

  const getPrescriptionDetails = (file: any, type: any) => {
    setFile(file);
    setActivePrescription(file);
    if (file.id === activePrescription?.id) {
      return;
    }
    setSignedUrlData(null);
    let data;
    if (type === "doctor") {
      data = {
        type: file.allData.type,
        id: file.id,
      };
      file = dispatch(fetchUploadedFileById(data));
    } else {
      file = dispatch(fetchPatientDocumentFileById(file.id));
    }
  };
  function tabChanged(e: any) {
    if (e == 1 && actionmapData?.action_name === "case_summary") {
      initPatientFiles();
    } else if (e == 2 && actionmapData?.action_name === "case_summary") {
      initMaxFiles();
    } else if (
      e == 1 &&
      (actionmapData?.action_name === "document_qc" ||
        actionmapData?.action_name === "document_qc_instant_vc")
    ) {
      initPatientFiles();
    }
  }
  useEffect(() => {
    signedUrl && setSignedUrlData(signedUrl);
  }, [signedUrl]);

  // useEffect(() => {
  //   if (shouldBlockNavigation && remarksVal !== "") {
  //     window.onbeforeunload = () => true;
  //   } else {
  //     window.onbeforeunload = () => false;
  //   }
  //   // console.log("window.onbeforeunload", window.onbeforeunload)
  // }, []);

  // useEffect(() => {
  //   if (remarksVal !== "") {
  //     setshouldBlockNavigation(true);
  //   } else {
  //     setshouldBlockNavigation(false);
  //   }
  // }, [remarksVal]);

  const onChangeRemarks = (e: any) => {
    setshouldBlockNavigation(true);
    localStorage.setItem("remarksVal", e.target.value);
    // setremarksVal(e.target.value);
  };

  // const showPropsConfirm = () => {
  //   confirm({
  //     title: "Are you sure delete this task?",
  //     icon: "",
  //     content: "Some descriptions",
  //     okText: "Yes",
  //     okType: "danger",
  //     okButtonProps: {
  //       disabled: true,
  //     },
  //     cancelText: "No",
  //     onOk() {
  //       console.log("OK");
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  return (
    <>
      <Prompt
        when={shouldBlockNavigation}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      <Row style={{ maxHeight: "calc(100vh - 155px)", overflowY: "scroll" }}>
        <Col
          span={actionmapData?.action_name === "case_summary" ? 14 : 19}
          className="bg-app innertabs"
        >
          {rearrangeDocs && (
            <Row>
              <Col span={24}>
                {patientfiles && (
                  <ReorderComponent
                    ticket={ticket}
                    objects={patientfiles}
                    cancel={() => setRearrangeDocs(false)}
                  />
                )}
              </Col>
            </Row>
          )}
          {!rearrangeDocs && (
            <Tabs
              defaultActiveKey={activeTab}
              onChange={(e: any) => tabChanged(e)}
            >
              <TabPane tab="Patient Uploads" key="1">
                {loading && !Documents ? (
                  <Skeleton></Skeleton>
                ) : Documents.length === 0 ? (
                  <Empty className="mt200" />
                ) : (
                  <Row>
                    <Col
                      span={
                        actionmapData?.action_name === "case_summary" ? 24 : 18
                      }
                      className="border-right "
                    >
                      <Row>
                        <Col span={24}>
                          {patientfiles && (
                            <FilesScroller
                              files={patientfiles}
                              active={activePrescription}
                              type={"patient"}
                              separator={
                                actionmapData?.action_name === "case_summary"
                                  ? true
                                  : false
                              }
                              getPrescriptionDetails={(id: any, type: any) =>
                                getPrescriptionDetails(id, type)
                              }
                            ></FilesScroller>
                          )}
                        </Col>

                        <Col span={24} className={"padding10"}>
                          {signedUrlData && (
                            <FileViewLayout
                              modal={false}
                              details={file}
                              fileviewer={true}
                              total={0}
                              setFileViewer={(value: any, type: any) =>
                                getPrescriptionDetails(value, "patient")
                              }
                              showZoomRotate={true}
                              file={signedUrlData}
                            ></FileViewLayout>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {(actionmapData?.action_name === "document_qc" ||
                      actionmapData?.action_name ===
                      "document_qc_instant_vc") && (
                        <Col span={6} className="border-right bg-app">
                          {QCForm && (
                            <FormBar
                              name={"update_document"}
                              sub_heading={maxForm?.sub_heading}
                              initialValues={QCForm.initialValues}
                              onFinish={QCForm.onFinish}
                              onFinishFailed={QCForm.onFinishFailed}
                              formElements={QCForm.formElements}
                              buttonText={QCForm.buttonText}
                              buttonType={QCForm.buttonType}
                              setValues={QCForm.setValues}
                            ></FormBar>
                          )}
                          {Documents.length !== 0 && (
                            <div
                              onClick={() => setRearrangeDocs(true)}
                              className="paddingl20 padding-bottom-20"
                            >
                              <a>Rearrange Documents?</a>
                            </div>
                          )}
                        </Col>
                      )}
                  </Row>
                )}
              </TabPane>
              {actionmapData?.action_name === "case_summary" && (
                <TabPane tab={CLIENT_NAME + " Uploads"} key="2">
                  {loading && !Documents ? (
                    <Skeleton></Skeleton>
                  ) : Documents.length === 0 ? (
                    <Empty className="mt200" />
                  ) : (
                    <Row>
                      <Col span={24} className="border-right ">
                        <Row>
                          <Col span={24}>
                            {maxfiles && (
                              <FilesScroller
                                files={maxfiles}
                                active={activePrescription}
                                type={"doctor"}
                                separator={
                                  actionmapData?.action_name === "case_summary"
                                    ? true
                                    : false
                                }
                                getPrescriptionDetails={(id: any, type: any) =>
                                  getPrescriptionDetails(id, type)
                                }
                              ></FilesScroller>
                            )}
                          </Col>

                          <Col span={24} className={"padding10"}>
                            {signedUrlData && (
                              <FileViewLayout
                                modal={false}
                                details={file}
                                fileviewer={true}
                                total={0}
                                setFileViewer={(value: any, type: any) =>
                                  getPrescriptionDetails(value, "doctor")
                                }
                                file={signedUrlData}
                              ></FileViewLayout>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </TabPane>
              )}
              <TabPane tab={`${t("CRM.Tickets.Fields.Ticket")} History`} key="3">
                <div className="padding20">
                  <TimelineLayout
                    theme={"white"}
                    type="Ticket"
                    details={ticket?.history}
                  />
                </div>
              </TabPane>
            </Tabs>
          )}

          <Button
            type="link"
            onClick={() => setInitAction("audit_trail")}
            className="bold-600 floating-audit"
          >
            <u>View Audit Trail</u>
          </Button>
        </Col>
        {actionmapData?.action_name === "case_summary" && (
          <Col span={5} className="border-right bg-app">
            {maxForm && (
              <FormBar
                sub_heading={maxForm?.sub_heading}
                name={"update_document"}
                initialValues={maxForm.initialValues}
                onFinish={maxForm.onFinish}
                onFinishFailed={maxForm.onFinishFailed}
                formElements={maxForm.formElements}
                buttonText={maxForm.buttonText}
                buttonType={maxForm.buttonType}
                setValues={maxForm.setValues}
              ></FormBar>
            )}
          </Col>
        )}
        <Col span={5}>
          {allQCDone !== null && (
            <UpdateTicketForm
              ticket={ticket}
              disableStatus={allQCDone}
              parentCallback={onFinishTicket}
            ></UpdateTicketForm>
          )}
        </Col>
      </Row>
      {initAction && (
        <GeneralTicketActions
          appointment={appointment}
          action={initAction}
          hideMe={() => setInitAction(false)}
        ></GeneralTicketActions>
      )}
    </>
  );
};

export default TicketDetailsPrescriptionTab;
