import { notification } from "antd";
import {
  ACCOUNT_PREFIX,
  ADD_TOKEN_BASE_URL,
  BASE_API_URL,
  WEBSOCKET_END_POINT,
} from "../constants";
import { CustomEvents, sendInternalEvent } from "./utilities";

export const _BASE_URL = BASE_API_URL;
export const _WEB_SOCKET_ENDPOINT = WEBSOCKET_END_POINT;
const displayError = (message: any, shouldHide: any) => {
  !shouldHide && notification.error({ message: message });
};

// export const ADD_TOKEN_BASE_URL = ADD_TOKEN_BASE_URL
//   process.env.ADD_TOKEN_BASE_URL || "https://uatnotification.maxhospitals.in";
// export const ADD_TOKEN_BASE_URL = "";
export const _REGISTER_DEVICE = ADD_TOKEN_BASE_URL + "/notify/add-token";
// export const _REGISTER_DEVICE = "";

export async function FbClient(
  endpoint: string,
  { body, ...customConfig } = {} as any
) {
  const headers: any = { "Content-Type": "application/json", version: "0.0.1" };

  // if (localStorage.getItem("token")) {
  //   headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  // }
  // if (localStorage.getItem("account_id")) {
  //   headers["account_id"] = localStorage.getItem("account_id");
  // }
  // if (localStorage.getItem("auth-type")) {
  //   headers["auth-type"] = localStorage.getItem("auth-type");
  // }
  // if (localStorage.getItem("application_id")) {
  //   headers["application_id"] = localStorage.getItem("application_id");
  // }
  // let application_slug;
  // if (localStorage.getItem("application_slug")) {
  //   application_slug = localStorage.getItem("application_slug");
  // }
  // switch (application_slug) {
  //   case "admin":
  //     headers["no_auth"] = "enabled";
  //     break;
  //   default:
  //     headers["no_auth"] = "disabled";
  // }
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      // ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  const BASE_URL = localStorage.getItem("baseUrl") || _BASE_URL;
  try {
    sendInternalEvent(CustomEvents.REQUEST_SENT, { endpoint });
    const url =
      endpoint.indexOf("https://") !== -1 ? endpoint : BASE_URL + endpoint;
    const response = await window.fetch(url, config);

    if (response.ok) {
      sendInternalEvent(CustomEvents.REQUEST_SUCCESS, { endpoint });
      if (response.status === 204) {
        return {
          status: response.status,
          headers: response.headers,
          url: response.url,
        };
      }
      data = await response.json();
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    } else if (response.status === 401) {
      sendInternalEvent(CustomEvents.REQUEST_FAIL, { endpoint });
      displayError("Un Authorized", customConfig?.shouldHide);
      const slug = localStorage?.getItem("account_slug");
      window.localStorage.clear();
      window.location.href =
        window.location.origin + "/" + (ACCOUNT_PREFIX || slug);
    } else if (response.status === 403) {
      data = await response.json();
      displayError(data.message || "Forbidden", customConfig?.shouldHide);
      sendInternalEvent(CustomEvents.REQUEST_FAIL, { message: data.message });
      return Promise.reject(data.message || "Forbidden");
    } else if (response.status === 400 || response.status === 404) {
      data = await response.json();
      let message = data.message;
      if (data.errorDetails) {
        message = data.errorDetails
          .map((e: any) => `${e.message || e.error}`)
          .join(", ");
      }
      displayError(message || "Forbidden", customConfig?.shouldHide);
      sendInternalEvent(CustomEvents.REQUEST_FAIL, { message });
      return Promise.reject(message);
    }
    sendInternalEvent(CustomEvents.REQUEST_FAIL, {
      message: response.statusText || "Something went wrong",
    });
    displayError(
      response.statusText || "Something went wrong",
      customConfig?.shouldHide
    );
    return Promise.reject(response.statusText);
  } catch (err: any) {
    //   notification.error({ message: err.message ? err.message : data });
    notification.error({ message: "Please check your internet connection" });
    sendInternalEvent(CustomEvents.REQUEST_FAIL, { message: "Not sent" });
    return Promise.reject(err.message ? err.message : data);
  }
}

FbClient.get = function (endpoint: string, customConfig: any = {}) {
  let params: any = [];
  if (customConfig.params) {
    for (let p in customConfig.params) {
      let key = p;
      if (Array.isArray(customConfig.params[p])) {
        customConfig.params[p].forEach((element: any) => {
          params.push(`${key}=${encodeURIComponent(element)}`);
        });
      } else {
        params.push(`${key}=${encodeURIComponent(customConfig.params[p])}`);
      }
    }
  }
  if (customConfig.params) {
    return FbClient(endpoint + "?" + params.join("&"), {
      ...customConfig,
      method: "GET",
    });
  } else {
    delete customConfig.params;
    return FbClient(endpoint, { ...customConfig, method: "GET" });
  }
};

FbClient.post = function (endpoint: string, body: any, customConfig = {}) {
  return FbClient(endpoint, { ...customConfig, body, method: "POST" });
};

FbClient.put = function (endpoint: string, body: any, customConfig = {}) {
  return FbClient(endpoint, { ...customConfig, body, method: "PUT" });
};

FbClient.patch = function (endpoint: string, body: any, customConfig = {}) {
  return FbClient(endpoint, { ...customConfig, body, method: "PATCH" });
};

FbClient.delete = function (endpoint: string, customConfig = {}) {
  return FbClient(endpoint, { ...customConfig, method: "DELETE" });
};
