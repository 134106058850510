import { useEffect, useState } from "react";

//import usePrescriptionById from '../../hooks/Procedures/usePrescriptionById';
import SectionViewComponent from "../ProcedureFromView/Component/SectionViewComponent";

function FormView(props) {
  // const [formData, setFormData] = useState(formDataJson);
  // const [prescriptionData, setPrescriptionData] = useState(props.prescriptionData);
  const [formRenderingData, setFormRenderingData] = useState([]);
  const prescription = props.prescriptionData;

  useEffect(() => {
    if (prescription) {
      transformPrescriptionViewData();
    }
  }, [props.prescriptionData]);

  const transformPrescriptionViewData = () => {
    let { sections } = prescription;
    if (!sections) {
      return;
    }
    sections.forEach((indSection, sectionIndex) => {
      mappingDataBasedOnShowType(indSection, sectionIndex);
    });
  };

  const mappingDataBasedOnShowType = (indSection, sectionIndex) => {
    if (
      indSection.showType === "table" &&
      indSection.status === "filled" &&
      indSection.formname &&
      (indSection.formname.includes("drug") ||
        indSection.formname.includes("drugs")) &&
      indSection.rows.every((row) => row.type === "row")
    ) {
      mapDataForDrugs(
        indSection.hideShowOff,
        indSection.rows,
        sectionIndex,
        indSection.showType,
        indSection.title,
        indSection.formname,
        indSection.collapse,
        indSection.ordered
      );
      return;
    }
    if (indSection.showType === "xyTable" && indSection.status === "filled") {
      mapxyTableData(
        indSection,
        indSection.hideShowOff,
        indSection.rows,
        sectionIndex,
        indSection.showType,
        indSection.title,
        indSection.formname,
        indSection.collapse,
        indSection.ordered
      );
      return;
    }
    switch (indSection.showType) {
      case "1d":
      case "2d":
      case "3d":
      case "compactTextbox":
        mapShowTypeCompactTextBox(
          indSection.hideShowOff,
          indSection.rows,
          sectionIndex,
          indSection.showType,
          indSection.title,
          indSection.formname,
          indSection.collapse
        );
        break;
      case "compact":
      case "none":
        mapShowTypeCompactData(
          indSection.hideShowOff,
          indSection.rows,
          sectionIndex,
          indSection.showType,
          indSection.title,
          indSection.formname,
          indSection.collapse,
          indSection.ordered
        );
        break;
      case "list":
      case "drug":
        mapShowTypeListData(
          indSection.hideShowOff,
          indSection.rows,
          sectionIndex,
          indSection.showType,
          indSection.title,
          indSection.formname,
          indSection.collapse
        );
        break;
      case "freeText":
        mapFreeTextData(
          indSection.hideShowOff,
          indSection.html,
          sectionIndex,
          indSection.showType,
          indSection.title,
          indSection.formname,
          indSection.collapse
        );
      case "table":
        if (indSection.formname.indexOf("treatmentHistory") !== -1) {
          mapShowTypeCompactData(
            indSection.hideShowOff,
            indSection.rows,
            sectionIndex,
            "none",
            indSection.title,
            indSection.formname,
            indSection.collapse
          );
        } else {
          mapShowTypeListData(
            indSection.hideShowOff,
            indSection.rows,
            sectionIndex,
            indSection.showType,
            indSection.title,
            indSection.formname,
            indSection.collapse
          );
        }
        break;
      case "compactTable":
        mapShowTypeCompactTableData(
          indSection.hideShowOff,
          indSection.header,
          indSection.rows,
          sectionIndex,
          indSection.showType,
          indSection.title,
          indSection.formname,
          indSection.collapse,
          indSection
        );
        break;
      default:
        break;
    }
  };

  const mapxyTableData = (
    section,
    hideShowOff,
    rows,
    sectionIndex,
    showType,
    title,
    formname,
    collapse,
    ordered
  ) => {
    let listConfig = {
      showType: showType,
      formname: formname,
      sectionName: title,
      collapse: collapse,
      hideShowOff: hideShowOff,
      data: [],
    };
    let dataObj = {
      type: "",
      rowTitle: "",
      headerData: (section.colHeaders && section.colHeaders.split(",")) || [],
      bodyData: [],
      ordered: ordered,
    };
    let rowHeader = (section.rowHeaders && section.rowHeaders.split(",")) || [];
    let colHeaders =
      (section.colHeaders && section.colHeaders.split(",")) || [];
    let tableData = [];
    for (let i in rows) {
      if (rows.status === "empty") {
        return;
      }
      let rowData = new Array(colHeaders.length).fill("");
      for (let j in rows[i].columns) {
        let labelYindex = colHeaders.indexOf(rows[i].columns[j].label_y);
        let labelXindex = rowHeader.indexOf(rows[i].columns[j].label_x);
        rowData[labelYindex] = rows[i].columns[j].value;
      }
      rowData.unshift(rows[i].label_x);
      tableData.push(rowData);
    }
    dataObj.bodyData = tableData;
    listConfig.data.push(dataObj);

    if (listConfig.data.length === 0) {
      return;
    }
    setFormRenderingData([...formRenderingData, listConfig]);
  };

  const mapDataForDrugs = (
    hideShowOff,
    rows,
    sectionIndex,
    showType,
    title,
    formname,
    collapse,
    ordered
  ) => {
    let listConfig = {
      showType: showType,
      formname: formname,
      sectionName: title,
      collapse: collapse,
      hideShowOff: hideShowOff,
      data: [],
    };
    let dataObj = {
      type: "",
      rowTitle: "",
      headerData: [],
      bodyData: [],
      ordered: ordered,
    };
    let rowData = formatListRowData(rows, "drugs");
    if (rowData[1][1] || rowData[1][1] === "") {
      dataObj["date"] = rowData[1][1];
      rowData[1].splice(1, 1);
    }
    dataObj.type = "drugs";
    dataObj.headerData = rowData[0];
    if (formname === "drugs") {
      let idx = dataObj.headerData.indexOf("value");
      dataObj.headerData[idx] = "medicine";
    }
    dataObj.bodyData = rowData[1];
    listConfig.data.push(dataObj);

    if (listConfig.data.length === 0) {
      return;
    }

    setFormRenderingData([...formRenderingData, listConfig]);
  };

  const mapFreeTextData = (
    hideShowOff,
    html,
    sectionIndex,
    showType,
    title,
    formname,
    collapse
  ) => {
    if (!html) return;

    let config = {
      showType: showType,
      formname: formname,
      sectionName: title,
      collapse: collapse,
      hideShowOff: hideShowOff,
      data: [],
    };
    let placeHolders = [
      "{{name}}",
      "{{doctor_name}}",
      "{{doctor_signature}}",
      "{{phone}}",
      "{{medicalreg_no}}",
      "{{date_of_birth}}",
      "{{display_address}}",
      "{{email}}",
      "{{gender}}",
    ];
    // for (let i in placeHolders) {
    //     if (html.indexOf(placeHolders[i]) !== -1) {
    //         let patientData = this.props.patientDetail;
    //         let key = placeHolders[i].replace(/\{{|\}}/gi, '');
    //         if (key === "medicalreg_no") {
    //             if (docDetails) {
    //                 html = html.replaceAll(placeHolders[i], docDetails.medicalreg_no ? docDetails.medicalreg_no : '');
    //             }
    //         } else {
    //             html = html.replaceAll(placeHolders[i], patientData[key] ? patientData[key] : '');
    //         }
    //     }
    // }

    let dataObj = {
      type: "",
      rowTitle: "",
      headerData: [],
      bodyData: [html],
    };
    config.data.push(dataObj);

    if (config.data.length === 0) {
      return;
    }
    setFormRenderingData([...formRenderingData, config]);
  };

  const formatMultiTableData = (children) => {
    let finalBodyData = [];
    children.forEach((indChildren, childIndex) => {
      let body = [];
      indChildren.columns.forEach((indColumn, colIndex) => {
        let value =
          indColumn.type === "header" ? indColumn.label : indColumn.value;
        body.push(value);
      });
      finalBodyData.push(body);
    });
    return finalBodyData;
  };

  const getTableHeaderData = (headerStr) => {
    let headerArr = headerStr ? headerStr.split(",") : "";

    let finalArr = [];
    if (!headerArr) {
      return [];
    }
    for (let data of headerArr) {
      let indData = data.trim();
      if (indData) {
        finalArr.push(indData);
      }
    }
    return finalArr;
  };

  const mapShowTypeCompactTableData = (
    hideShowOff,
    headerStr,
    rows,
    sectionIndex,
    showType,
    title,
    formname,
    collapse,
    indSection
  ) => {
    if (indSection.splitTable) {
      let listConfig = {
        showType: showType,
        splitTable: true,
        formname: formname,
        sectionName: title,
        collapse: collapse,
        hideShowOff: hideShowOff,
        tableData: [],
      };
      rows.forEach((row, rowIndex) => {
        let indTableData = {
          gridSize: row.subSectionSize,
          header: getTableHeaderData(row.header),
          body: [],
        };
        if (row.type === "subSection" && row.status !== "empty") {
          let bodyData = formatMultiTableData(row.children, "subSection");
          indTableData.body.push(bodyData);
        }
        listConfig.tableData.push(indTableData);
      });

      let isEmpty = checkForListConfigEmptyData(listConfig);
      if (!isEmpty) {
        return;
      }
      let data = [];
      data.push(listConfig);
      setFormRenderingData({ ...formRenderingData, data });
      return;
    }
    let listConfig = {
      showType: showType,
      splitTable: false,
      formname: formname,
      sectionName: title,
      collapse: collapse,
      hideShowOff: hideShowOff,
      header: headerStr ? headerStr.split(",") : "",
      body: [],
    };
    rows.forEach((row, rowIndex) => {
      let bodyData = [];
      if (
        (row.type === "row" || row.type === "compactRow") &&
        row.status !== "empty"
      ) {
        bodyData = formatCompactTableRowData(row.columns, "row");
        listConfig.body.push(bodyData);
      }
    });
    if (listConfig.body.length === 0) {
      return;
    }
    setFormRenderingData([...formRenderingData, listConfig]);
  };

  const checkForListConfigEmptyData = (listConfig) => {
    for (let index in listConfig.tableData) {
      let indData = listConfig.tableData[index];
      if (indData.body && indData.body.length > 0) {
        return true;
      }
    }
    return false;
  };

  const formatCompactTableRowData = (columns) => {
    let body = [];
    columns.forEach((column, colIndex) => {
      let value = column.type === "header" ? column.label : column.value;
      body.push(value);
    });
    return body;
  };

  const mapShowTypeListData = (
    hideShowOff,
    rows,
    sectionIndex,
    showType,
    title,
    formname,
    collapse
  ) => {
    let listConfig = {
      showType: showType,
      formname: formname,
      sectionName: title,
      collapse: collapse,
      hideShowOff: hideShowOff,
      data: [],
    };
    rows.forEach((row, rowIndex) => {
      let dataObj = {
        type: "",
        rowTitle: "",
        headerData: [],
        bodyData: [],
      };
      if (
        (row.type === "row" || row.type === "compactRow") &&
        row.status !== "empty"
      ) {
        let rowData = formatListRowData(row.columns, "row");
        let rowTitle = row.name ? row.name : "";
        dataObj.type = row.type;
        dataObj.rowTitle = rowTitle;
        dataObj.headerData =
          rowData[1] && rowData[1].length > 0 ? rowData[0] : "";
        dataObj.bodyData = rowData[1];
        if (!rowData[1] || rowData[1].length === 0) {
          listConfig.data = [];
        } else {
          listConfig.data.push(dataObj);
        }
      } else if (row.type === "group" && row.status !== "empty") {
        let rowData = formatListRowData(row.children, "group");
        let rowTitle = row.name;
        if (rowData[1][1] || rowData[1][1] === "") {
          dataObj["date"] = rowData[1][1];
          rowData[1].splice(1, 1);
        }
        dataObj.type = row.type;
        dataObj.rowTitle = rowTitle;
        dataObj.headerData = rowData[0];
        dataObj.bodyData = rowData[1];
        listConfig.data.push(dataObj);
      }
    });
    if (listConfig.data.length === 0) {
      return;
    }
    setFormRenderingData([...formRenderingData, listConfig]);
  };

  const formatListRowData = (data, type) => {
    let header = [];
    let body = [];
    if (type === "row") {
      header = findHeaderDataForList(data);
      body = findBodyDataForList(data, header, type);
    } else {
      header = findHeaderDataOfChildrenForList(data);
      body = findBodyDataForList(data, header, type);
    }
    return [header, body];
  };

  const findBodyDataForList = (data, header, type) => {
    if (type === "row") {
      return findBodyDataForRowList(data, header, type);
    } else {
      return findBodyDataForGroupList(data, header, type);
    }
  };

  const findHeaderDataForList = (columns) => {
    let header = [];
    columns.forEach((column, colIndex) => {
      let label = column.label ? column.label : column.name;
      if (header.length === 0) {
        header.push(label);
      } else if (header.indexOf(label) === -1) {
        header.push(label);
      }
    });
    return header;
  };

  const findHeaderDataOfChildrenForList = (childrens) => {
    let header = [];
    childrens.forEach((children, childIndex) => {
      children.columns.forEach((indColumn, colIndex) => {
        let label =
          indColumn.label && indColumn.label.trim()
            ? indColumn.label
            : indColumn.name;
        if (
          children.rowType !== "2columnDate" &&
          children.columns.length != 1
        ) {
          if (header.length === 0) {
            header.push(label);
          } else if (header.indexOf(label) === -1) {
            header.push(label);
          }
        }
      });
    });
    return header;
  };

  const findBodyDataForGroupList = (childrens, header, type) => {
    let finalArray = [];
    let date = "";
    childrens.forEach((children, childIndex) => {
      if (children.status === "empty") {
        return;
      }
      let body = [];

      children.columns.forEach((column, colIndex) => {
        if (!column.value && column.name === "value") {
          body = [];
          return;
        }
        let label =
          column.label && column.label.trim() ? column.label : column.name;
        let value = column.value
          ? column.value
          : column.values && column.values.length > 0
          ? column.values.toString().split(",")
          : "";
        if (
          (column.autoKey && column.autoKey === "drugs") ||
          column.name === "name"
        ) {
          value = value.toUpperCase();
        }

        if (
          children.rowType === "2columnDate" ||
          children.columns.length == 1
        ) {
          date = label + " : " + value;
        } else {
          if (header.indexOf(label) !== -1) {
            let index = header.indexOf(label);
            body[index] = value;
          }
        }
      });
      if (body.length > 0 && checkIfAllValueFilled(body)) {
        finalArray.push(body);
      }
    });
    return [finalArray, date];
  };

  const checkIfAllValueFilled = (body) => {
    let isFilled = false;
    for (let i in body) {
      if (body[i]) {
        isFilled = true;
      }
    }
    return isFilled;
  };

  const findBodyDataForRowList = (columns, header, type) => {
    let body = [];
    columns.forEach((column, colIndex) => {
      if (!column.value && column.name === "value") {
        body = [];
        return;
      }
      let label = column.label ? column.label : column.name;
      let value = column.value
        ? column.value
        : column.values && column.values.length > 0
        ? column.values.toString().split(",")
        : "";
      if (column.autoKey && column.autoKey === "drugs") {
        value = value.toUpperCase();
      }
      if (header.indexOf(label) !== -1) {
        let index = header.indexOf(label);
        body[index] = value;
      }
    });
    if (checkIfAllValueFilled(body)) {
      return body;
    }
    return [];
  };

  const mapShowTypeCompactTextBox = (
    hideShowOff,
    rows,
    sectionIndex,
    showType,
    title,
    formname,
    collapse
  ) => {
    let compactConfig = {
      showType: showType,
      formname: formname,
      sectionName: title,
      collapse: collapse,
      hideShowOff: hideShowOff,
      data: [],
    };
    let data = [];
    if (!rows || rows.length === 0) {
      return;
    }
    rows.forEach((row, rowIndex) => {
      if (row.type === "row" && row.status !== "empty") {
        let strArray;
        strArray = formatCompactTextBoxData(row.columns, title, hideShowOff);
        data.push({ type: "row", data: strArray });
      } else if (row.type === "group" && row.status !== "empty") {
        let strArray;
        strArray = formatCompactGroupData(
          row.children,
          title,
          hideShowOff,
          showType
        );
        data.push({ type: "group", rowName: row.name, data: strArray });
      }
    });
    compactConfig.data = data;
    if (compactConfig.data.length === 0) {
      return;
    }
    setFormRenderingData([...formRenderingData, compactConfig]);
  };

  const mapShowTypeCompactData = (
    hideShowOff,
    rows,
    sectionIndex,
    showType,
    title,
    formname,
    collapse,
    ordered
  ) => {
    let compactConfig = {
      showType: showType,
      formname: formname,
      sectionName: title,
      collapse: collapse,
      hideShowOff: hideShowOff,
      data: [],
      ordered: ordered,
    };
    let data = [];
    if (!rows || rows.length === 0) {
      return;
    }
    rows.forEach((row) => {
      if (row.type === "row" && row.status === "empty") {
        let strArray;
        strArray = formatCompactRowData(
          row.columns,
          title,
          hideShowOff,
          row.ismultiple
        );
        data.push({ type: "row", data: strArray, ordered: ordered });
      } else if (row.type === "row" && row.status !== "empty") {
        let strArray;
        strArray = formatCompactRowData(
          row.columns,
          title,
          hideShowOff,
          row.ismultiple
        );
        data.push({ type: "row", data: strArray, ordered: ordered });
      } else if (row.type === "group" && row.status !== "empty") {
        let strArray;
        strArray = formatCompactGroupData(
          row.children,
          title,
          hideShowOff,
          showType
        );
        data.push({ type: "group", rowName: row.name, data: strArray });
      }
    });
    compactConfig.data = data;
    if (compactConfig.data.length === 0) {
      return;
    }
    setFormRenderingData([...formRenderingData, compactConfig]);
  };

  const formatCompactGroupData = (rows, title, hideShowOff, showType) => {
    let groupConfigData = [];
    rows.forEach((row) => {
      if (row.status === "empty") {
        return;
      }
      let strArray;
      if (showType === "2d") {
        strArray = format2DRowData(
          row.columns,
          title,
          hideShowOff,
          row.ismultiple
        );
        groupConfigData = strArray;
      } else {
        // format2DRowData
        strArray = formatCompactRowData(
          row.columns,
          title,
          hideShowOff,
          row.ismultiple
        );
        groupConfigData.push(strArray);
      }
    });
    return groupConfigData;
  };

  const formatCompactTextBoxData = (columns, title, hideShowOff) => {
    let data = [];
    columns.forEach((column, colIndex) => {
      let str = "";
      if (column.type === "header") {
        str = str + ": :" + column.label + ": :";
        return;
      }
      if (column.value || (column.values && column.values.length > 0)) {
        if (
          column.name === "value" ||
          column.name === "date" ||
          column.name === "route" ||
          column.name === "secondValue"
        ) {
          let labelStr = "";
          if (!column.label || !column.label.trim()) {
            labelStr = hideShowOff ? title + " : " : column.label;
            str =
              str +
              " " +
              (labelStr ? labelStr : "") +
              (str ? " : " : "") +
              (column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "");
          } else {
            labelStr = column.label;
            str =
              str +
              " " +
              (labelStr ? labelStr : "") +
              (column.label ? " : " : column.name ? " : " : "") +
              (column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "");
          }
        } else if (column.name === "comment") {
          str =
            str +
            " ( " +
            (column.value
              ? column.value
              : column.values && column.values.length > 0
              ? column.values.join(", ")
              : "") +
            " ) ";
        } else if (column.name === "name") {
          str =
            str +
            (column.label ? column.label + ":" : "") +
            (column.value
              ? column.value
              : column.values && column.values.length > 0
              ? column.values.join(", ")
              : "");
        }
      }
      if (!str) {
        return;
      }
      data.push(str);
    });
    return data;
  };

  const format2DRowData = (columns, title, hideShowOff, ismultiple) => {
    let data = [];
    let str = [];
    columns.forEach((column, colIndex) => {
      if (column.type === "header") {
        str.push(": :" + column.label + ": :");
        return;
      }
      if (column.value || (column.values && column.values.length > 0)) {
        if (column.name === "value" || column.name === "route") {
          let labelStr = "";
          if (!column.label || !column.label.trim()) {
            labelStr = column.label;
            str.push(
              (labelStr ? labelStr : "") +
                (str && str.length ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "")
            );
          } else {
            labelStr = column.label;
            str.push(
              (labelStr ? labelStr : "") +
                (column.label ? " : " : column.name ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "")
            );
          }
        } else if (column.name === "drug" || column.name === "drugType") {
          let labelStr = "";
          if (!column.label || !column.label.trim()) {
            labelStr = hideShowOff ? title + " : " : column.label;
            str.push(
              (labelStr ? labelStr : "") +
                (str && str.length ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "")
            );
          } else {
            labelStr = column.label;
            str.push(
              (labelStr ? labelStr : "") +
                (column.label ? " : " : column.name ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "")
            );
          }
        } else if (column.name === "name" || column.name === "childName") {
          str.push(
            (column.label ? column.label : "") +
              (column.label ? " : " : "") +
              (column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "")
          );
        } else if (column.name === "secondValue") {
          let newStr = " ";
          if (data.length > 0) {
            newStr = column.value
              ? column.value
              : column.values && column.values.length > 0
              ? column.values.join(", ") + " "
              : "";
            data[data.length - 1] = data[data.length - 1].concat(`  ${newStr}`);
          } else {
            newStr = column.value
              ? column.value
              : column.values && column.values.length > 0
              ? column.values.join(", ") + " "
              : "";
            data.push(newStr);
          }
        } else if (column.name === "date") {
          let labelStr = "";
          if (!column.label || !column.label.trim()) {
            labelStr = hideShowOff ? title + " : " : column.label;
            str.push(
              column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : ""
            );
          } else {
            labelStr = column.label;
            str.push(
              column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : ""
            );
          }
        }
      }
    });
    if (str) data.push(str);
    return data;
  };
  // format2DRowData
  const formatCompactRowData = (columns, title, hideShowOff, ismultiple) => {
    let data = [];
    if (ismultiple) {
      let str = "";
      columns.forEach((column, colIndex) => {
        if (column.type === "header") {
          str = str + ": :" + column.label + ": :";

          return;
        }
        if (column.value || (column.values && column.values.length > 0)) {
          if (
            column.name === "value" ||
            column.name === "route" ||
            column.name === "brand" ||
            column.name === "molecule" ||
            column.name === "unit" ||
            column.name === "inference" ||
            column.name === "cycle" ||
            column.name === "dosage"
          ) {
            let labelStr = "";
            if (!column.label || !column.label.trim()) {
              labelStr = hideShowOff ? title + " : " : column.label;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (str ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "") +
                (columns.length > 1 && columns.length !== colIndex + 1
                  ? ", "
                  : "");
            } else {
              labelStr = column.label;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (column.label ? " : " : column.name ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "") +
                (columns.length > 1 && columns.length !== colIndex + 1
                  ? ", "
                  : "");
            }
          } else if (column.name === "drug" || column.name === "drugType") {
            let labelStr = "";
            if (!column.label || !column.label.trim()) {
              labelStr = hideShowOff ? title + " : " : column.label;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (str ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "");
            } else {
              labelStr = column.label;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (column.label ? " : " : column.name ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "");
            }
          } else if (column.name === "comment") {
            str =
              str +
              " " +
              " ( " +
              (column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ")
                : "") +
              " ) ";
          } else if (column.name === " ") {
            str =
              str +
              " " +
              " ( " +
              (column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ")
                : "") +
              " ) ";
          } else if (column.name === "name" || column.name === "childName") {
            str =
              str +
              " " +
              (column.label ? column.label : "") +
              (column.label ? " : " : "") +
              (column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "");
          } else if (column.name === "secondValue") {
            let newStr = " ";
            if (data.length > 0) {
              newStr = column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "";
              data[data.length - 1] = data[data.length - 1].concat(
                `  ${newStr}`
              );
            } else {
              newStr = column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "";
              data.push(newStr);
            }
            // str = str + (column.value ? column.value : (column.values && column.values.length > 0) ? column.values.toString() : '')
          } else if (column.name === "date") {
            let labelStr = "";
            if (!column.label || !column.label.trim()) {
              labelStr = hideShowOff ? title + " : " : column.label;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (str ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "");
            } else {
              labelStr = column.label;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (column.label ? " : " : column.name ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "");
            }
          }
        }
      });
      if (str) data.push(str);
    } else {
      let str = "";
      columns.forEach((column, colIndex) => {
        if (column.type === "header" || column.label === "") {
          str = column.label || column.value;
          return;
        }
        if (column.value || (column.values && column.values.length > 0)) {
          if (
            column.name === "value" ||
            column.name === "route" ||
            column.name === "brand" ||
            column.name === "molecule" ||
            column.name === "unit" ||
            column.name === "inference" ||
            column.name === "cycle" ||
            column.name === "dosage"
          ) {
            let labelStr = "";
            if (!column.label || !column.label.trim()) {
              labelStr = column.label;
              //  hideShowOff ? title + " : " : (column.label)
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (str ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "") +
                (columns.length > 1 && columns.length !== colIndex + 1
                  ? ", "
                  : "");
            } else {
              labelStr = column.label || column.value;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (column.label ? " : " : column.name ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "") +
                (columns.length > 1 && columns.length !== colIndex + 1
                  ? ", "
                  : "");
            }
          } else if (column.name === "drug" || column.name === "drugType") {
            let labelStr = "";
            if (!column.label || !column.label.trim()) {
              labelStr = hideShowOff ? title + " : " : column.label;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (str ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "");
            } else {
              labelStr = column.label;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (column.label ? " : " : column.name ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "");
            }
          } else if (column.name === "comment") {
            str =
              str +
              " " +
              " ( " +
              (column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "") +
              " ) ";
          } else if (column.name === "name" || column.name === "childName") {
            str =
              str +
              " " +
              (column.label ? column.label : "") +
              (column.label ? " : " : "") +
              (column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "");
          } else if (column.name === "secondValue") {
            let newStr = " ";
            if (data.length > 0) {
              newStr = column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "";
              data[data.length - 1] = data[data.length - 1].concat(
                `  ${newStr}`
              );
            } else {
              newStr = column.value
                ? column.value
                : column.values && column.values.length > 0
                ? column.values.join(", ") + " "
                : "";
              data.push(newStr);
            }
          } else if (column.name === "date") {
            let labelStr = "";
            if (!column.label || !column.label.trim()) {
              labelStr = hideShowOff ? title + " : " : column.label;
              str =
                str +
                " " +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "");
              //  Utils.getNewFormatedDate(
              // + (labelStr ? labelStr : '') + (str ? ' : ' : '')
            } else {
              labelStr = column.label;
              str =
                str +
                " " +
                (labelStr ? labelStr : "") +
                (labelStr ? " : " : "") +
                (column.value
                  ? column.value
                  : column.values && column.values.length > 0
                  ? column.values.join(", ") + " "
                  : "");
              // + (labelStr ? labelStr : '') + (column.label ? ' : ' : column.name ? ' : ' : '')
            }
          }
        }
      });
      if (str) data.push(str);
    }
    return data;
  };

  return (
    <>
      <>
        {prescription && prescription && (
          <SectionViewComponent
            viewType="view"
            dynamicFormData={prescription}
          ></SectionViewComponent>
        )}
        {/* <FormViewSwitch formRenderingData={formRenderingData} /> */}
      </>
    </>
  );
}

export default FormView;
