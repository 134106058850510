import { Col, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAgents } from "../../../../redux/reducers/agents.slice";
import { fetchDashboardData } from "../../../../redux/reducers/dashboard.slice";
import {
  formatDropDownValue,
  secondsToHms,
} from "../../../../shared/Utils/utilities";
import {
  TASK_MODULE_ACTIONS_READ_CALL_LOGS,
  TASK_MODULE_ACTIONS_READ_LEAD,
} from "../../../../shared/components/permission.constants";
import { RootState } from "../../../../shared/constants";
import { useFetchBranches } from "../../../../shared/hooks/useQuery/useFetchBranches";
import FormBar from "../../../../shared/layouts/formbar.layout";
import { TableCard } from "./livestatustab";

export default function PerformanceTrackerTab() {
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { branches }: any = useFetchBranches({});
  //   const { branches } = useSelector((state: RootState) => state.branch);
  const { agentsAll } = useSelector((state: RootState) => state.agents);

  const [dataLeadPerformance, setDataLeadPerformance] = useState(null as any);
  const [dataLeadPerformanceFooter, setDataLeadPerformanceFooter] = useState(
    null as any
  );
  const [dataLeadPerformanceLoading, setDataLeadPerformanceLoading] = useState(
    false as any
  );
  const [dataTicketPerformance, setDataTicketPerformance] = useState(
    null as any
  );
  const [dataTicketPerformanceFooter, setDataTicketPerformanceFooter] =
    useState(null as any);
  const [dataTicketPerformanceLoading, setDataTicketPerformanceLoading] =
    useState(false as any);
  const [dataCallPerformance, setDataCallPerformance] = useState(null as any);
  const [dataCallPerformanceFooter, setDataCallPerformanceFooter] = useState(
    null as any
  );
  const [dataCallPerformanceLoading, setDataCallPerformanceLoading] = useState(
    false as any
  );
  const [dataLeadDistribution, setDataLeadDistribution] = useState(null as any);
  const [dataLeadDistributionFooter, setDataLeadDistributionFooter] = useState(
    null as any
  );
  const [dataLeadDistributionLoading, setDataLeadDistributionLoading] =
    useState(false as any);
  const [dataTicketDistribution, setDataTicketDistribution] = useState(
    null as any
  );
  const [dataTicketDistributionFooter, setDataTicketDistributionFooter] =
    useState(null as any);
  const [dataTicketDistributionLoading, setDataTicketDistributionLoading] =
    useState(false as any);

  const [locations, setLocations] = useState(null as any);
  const [agentList, setAgentList] = useState(null as any);
  const [selectedAgent, setselectedAgent] = useState(null as any);
  useEffect(() => {
    getDashboardData();
    // branches?.length === 0 && dispatch(fetchBranches());
    agentsAll?.length === 0 && dispatch(fetchAllAgents({ limit: 500 }));
  }, []);
  useEffect(() => {
    if (branches?.length > 0) {
      setLocations(
        branches.map((e: any) => {
          return { name: formatDropDownValue(e.name), value: e.id };
        })
      );
    }
  }, [branches]);
  useEffect(() => {
    setAgentList(
      agentsAll.map((val: any) => {
        return {
          name: `${val?.user?.first_name && val?.user?.first_name !== "null"
            ? val?.user?.first_name
            : ""
            } 
                ${val.user?.last_name && val.user?.last_name !== "null"
              ? val.user?.last_name
              : ""
            }`,
          value: val.user_id,
        };
      })
    );
  }, [agentsAll]);

  console.log("selectedAgent", selectedAgent);

  const getDashboardData = async (filters: any = {}, selectedAg?: any) => {
    setDataLeadPerformanceLoading(true);
    setDataTicketPerformanceLoading(true);
    setDataCallPerformanceLoading(true);
    setDataLeadDistributionLoading(true);
    setDataTicketDistributionLoading(true);

    const dataByLeadPer = await dispatch(
      fetchDashboardData({ query_type: "lead_performance", ...filters })
    );
    if (dataByLeadPer?.payload?.data?.data?.length > 0) {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0, 0];
      dataByLeadPer?.payload?.data?.data?.forEach((val: any) => {
        allRows.push([
          formatDropDownValue(val.agent_name),
          val.past_open || 0,
          val.new_assigned || 0,
          val.sla_breached || 0,
          val.undisposed || 0,
          val.converted || 0,
        ]);

        footerData[1] += val?.past_open ? parseInt(val.past_open) : 0;
        footerData[2] += val?.new_assigned ? parseInt(val.new_assigned) : 0;
        footerData[3] += val?.sla_breached ? parseInt(val.sla_breached) : 0;
        footerData[4] += val?.undisposed ? parseInt(val.undisposed) : 0;
        footerData[5] += val?.converted ? parseInt(val.converted) : 0;
      });
      footerData[0] = "Total (" + allRows?.length + " Agents)";
      console.log("footerDatasssssssssssssss", footerData);
      setDataLeadPerformance(allRows);
      setDataLeadPerformanceFooter(footerData);
    } else {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0, 0];
      allRows.push([selectedAg, 0, 0, 0, 0, 0]);
      footerData[0] = "Total (" + allRows?.length + " Agents)";
      setDataLeadPerformance(allRows);
      setDataLeadPerformanceFooter(footerData);
    }
    setDataLeadPerformanceLoading(false);

    const dataByTicketPer = await dispatch(
      fetchDashboardData({ query_type: "ticket_performance", ...filters })
    );
    if (dataByTicketPer?.payload?.data?.data?.length > 0) {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0];
      dataByTicketPer?.payload?.data?.data?.forEach((val: any) => {
        allRows.push([
          formatDropDownValue(val.agent_name),
          val.past_open || 0,
          val.new_assigned || 0,
          val.sla_breached || 0,
          // val.undisposed,
          val.closed || 0,
        ]);
        // footerData[1] += parseInt(val.past_open);
        // footerData[2] += parseInt(val.new_assigned);
        // footerData[3] += parseInt(val.sla_breached);
        // footerData[4] += parseInt(val.undisposed);
        // footerData[5] += parseInt(val.closed);

        footerData[1] += val?.past_open ? parseInt(val.past_open) : 0;
        footerData[2] += val?.new_assigned ? parseInt(val.new_assigned) : 0;
        footerData[3] += val?.sla_breached ? parseInt(val.sla_breached) : 0;
        // footerData[4] += val?.undisposed ? parseInt(val.undisposed) : 0;
        footerData[4] += val?.closed ? parseInt(val.closed) : 0;
      });
      footerData[0] = "Total (" + allRows?.length + " Agents)";
      setDataTicketPerformance(allRows);
      setDataTicketPerformanceFooter(footerData);
    } else {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0];
      allRows.push([selectedAg, 0, 0, 0, 0]);
      footerData[0] = "Total (" + allRows?.length + " Agents)";
      setDataTicketPerformance(allRows);
      setDataTicketPerformanceFooter(footerData);
    }
    setDataTicketPerformanceLoading(false);

    const dataByCallsPer = await dispatch(
      fetchDashboardData({ query_type: "call_performance", ...filters })
    );
    if (dataByCallsPer?.payload?.data?.data?.length > 0) {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0];
      dataByCallsPer?.payload?.data?.data?.forEach((val: any) => {
        allRows.push([
          formatDropDownValue(val.agent_name),
          val.outgoing,
          val.incoming,
          val.missed,
          val.undisposed,
        ]);
        footerData[1] += parseInt(val.outgoing);
        footerData[2] += parseInt(val.incoming);
        footerData[3] += parseInt(val.missed);
        footerData[4] += parseInt(val.undisposed);
      });
      footerData[0] = "Total (" + allRows?.length + " Agents)";
      setDataCallPerformance(allRows);
      setDataCallPerformanceFooter(footerData);
    } else {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0];
      if (selectedAg) {
        allRows.push([selectedAg, 0, 0, 0, 0]);
        footerData[0] = "Total (" + allRows?.length + " Agents)";
      }
      setDataCallPerformance(allRows);
      setDataCallPerformanceFooter(footerData);
    }
    setDataCallPerformanceLoading(false);

    const dataLeadsP = await dispatch(
      fetchDashboardData({ query_type: "lead_distribution", ...filters })
    );
    if (dataLeadsP?.payload?.data?.data) {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0, 0];
      dataLeadsP?.payload?.data?.data?.forEach((val: any) => {
        allRows.push([
          formatDropDownValue(val.category),
          val.past_open,
          val.new_assigned,
          val.sla_breached,
          val.converted,
          secondsToHms(val.avg_closing_time || 0),
        ]);
        footerData[1] += parseInt(val.past_open);
        footerData[2] += parseInt(val.new_assigned);
        footerData[3] += parseInt(val.sla_breached);
        footerData[4] += parseInt(val.converted);
        footerData[5] += parseInt(val.avg_closing_time || 0);
      });
      footerData[0] = "Total (" + allRows?.length + " Categories)";
      footerData[5] = secondsToHms(footerData[5]);
      setDataLeadDistribution(allRows);
      setDataLeadDistributionFooter(footerData);
    }
    setDataLeadDistributionLoading(false);

    const dataTicketsP = await dispatch(
      fetchDashboardData({ query_type: "ticket_distribution", ...filters })
    );
    if (dataTicketsP?.payload?.data?.data) {
      let allRows: Array<Array<any>> = [];
      let footerData: Array<any> = ["Total", 0, 0, 0, 0];
      dataTicketsP?.payload?.data?.data?.forEach((val: any) => {
        allRows.push([
          formatDropDownValue(val.category),
          val.past_open || 0,
          val.new_assigned || 0,
          val.sla_breached || 0,
          val.closed || 0,
          // secondsToHms(val.avg_closing_time || 0),
        ]);
        footerData[1] += val.past_open ? parseInt(val.past_open) : 0;
        footerData[2] += val.new_assigned ? parseInt(val.new_assigned) : 0;
        footerData[3] += val.sla_breached ? parseInt(val.sla_breached) : 0;
        footerData[4] += val.closed ? parseInt(val.closed) : 0;
        // footerData[5] += parseInt(val.avg_closing_time || 0);
      });
      footerData[0] =
        "Total (" +
        allRows.filter((val: any) => {
          return val[0] !== null;
        })?.length +
        " " +
        t("CRM.Dashboard.Categories") +
        ")";
      // footerData[5] = secondsToHms(footerData[5]);
      setDataTicketDistribution(
        allRows.filter((val: any) => {
          return val[0] !== null;
        })
      );
      setDataTicketDistributionFooter(footerData);
    }
    setDataTicketDistributionLoading(false);
  };

  const columnsLeadPerformance = [
    "Agents",
    "Past Open Leads",
    "New assigned",
    "SLA breached	",
    "Undisposed",
    "Converted",
  ];
  const columnsTicketPerformance = [
    "Agents",
    `Past open ${t("CRM.Tickets.Fields.Tickets")} `,
    "New assigned",
    "SLA breached	",
    // "Undisposed",
    "Closed",
  ];
  const columnsCallPerformance = [
    "Agents",
    <>
      Outgoing
      <br />
      Calls
    </>,
    <>
      Incoming
      <br />
      Calls
    </>,
    <>
      Missed
      <br />
      Calls
    </>,
    <>
      Ticket/Lead Not Linked
      <br />
      (Undisposed Calls)
    </>,
  ];
  const columnsLeadDist = [
    "Agents",
    "Past Open Leads",
    "New assigned",
    "SLA breached	",
    "Converted",
    "Avg. Closing Time",
  ];
  const columnsTicketDist = [
    t("CRM.Dashboard.Agents"),
    `Past open ${t("CRM.Tickets.Fields.Tickets")}`,
    "New assigned",
    "SLA breached	",
    "Closed",
    // "Avg. Closing Time",
  ];
  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] === "" || all[key] === undefined || all[key] === null) {
      } else {
        if (key == "range") {
          filtersObj.date_from = all.range
            ? all.range[0].format("YYYY-MM-DD")
            : null;
          filtersObj.date_to = all.range
            ? all.range[1].format("YYYY-MM-DD")
            : null;
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    let agentName =
      filtersObj?.assigned_user_id &&
      agentList?.filter((val: any) => {
        return val.value === filtersObj?.assigned_user_id;
      });
    // setselectedAgent(formatDropDownValue(agentName && agentName[0]?.name))
    getDashboardData(
      filtersObj,
      formatDropDownValue(agentName && agentName[0]?.name)
    );
    all?.branch_id
      ? dispatch(fetchAllAgents({ limit: 500, branch_id: all?.branch_id }))
      : dispatch(fetchAllAgents({ limit: 500 }));
  }

  console.log("ddddffffffffffffff", agentList);

  const formElements = {
    onFinish: () => { },
    onFinishFailed: () => { },
    onValuesChange: filterFormSubmit,
    initialValues: {},
    setValues: {},
    name: "dash-filter-form",
    buttonText: "",
    formElements: [
      {
        label: "",
        type: "Select",
        name: "branch_id",
        colSpan: 4,
        allowClear: true,
        placeholder: t("CRM.Dashboard.Form.Location"),
        options: locations,
      },
      {
        label: "",
        type: "RangePicker",
        name: "range",
        colSpan: 6,
      },
      {
        label: "",
        type: "Select",
        name: "assigned_user_id",
        colSpan: 4,
        allowClear: true,
        placeholder: "Agent Name",
        options: agentList,
      },
    ],
  };
  return (
    <>
      <FormBar {...formElements} />
      {(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1) && (
          <Row className="">
            <Col span={24}>
              {dataLeadPerformance !== null &&
                dataLeadPerformanceFooter !== null ? (
                <TableCard
                  loading={dataLeadPerformanceLoading}
                  columns={columnsLeadPerformance}
                  dataSet={dataLeadPerformance}
                  footerData={dataLeadPerformanceFooter}
                  title="Lead Performance"
                />
              ) : (
                <Skeleton />
              )}
            </Col>
          </Row>
        )}

      <Row className="mt30">
        <Col span={24}>
          {dataTicketPerformance !== null &&
            dataTicketPerformanceFooter !== null ? (
            <TableCard
              loading={dataTicketPerformanceLoading}
              columns={columnsTicketPerformance}
              dataSet={dataTicketPerformance}
              footerData={dataTicketPerformanceFooter}
              title={`${t("CRM.Tickets.Fields.Ticket")} performance`}
            />
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>
      {(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_CALL_LOGS) !== -1) && (
          <Row className="mt30">
            <Col span={24}>
              {dataCallPerformance !== null &&
                dataCallPerformanceFooter !== null ? (
                <TableCard
                  columns={columnsCallPerformance}
                  loading={dataCallPerformanceLoading}
                  dataSet={dataCallPerformance}
                  footerData={dataCallPerformanceFooter}
                  title="Calls Performance"
                />
              ) : (
                <Skeleton />
              )}
            </Col>
          </Row>
        )}

      {(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1) && (
          <Row className="mt30">
            <Col span={24}>
              {dataLeadDistribution !== null &&
                dataLeadDistributionFooter !== null ? (
                <TableCard
                  columns={columnsLeadDist}
                  loading={dataLeadDistributionLoading}
                  dataSet={dataLeadDistribution}
                  footerData={dataLeadDistributionFooter}
                  title="Lead Distribution by Category"
                />
              ) : (
                <Skeleton />
              )}
            </Col>
          </Row>
        )}

      <Row className="mt30">
        <Col span={24}>
          {dataTicketDistribution !== null &&
            dataTicketDistributionFooter !== null ? (
            <TableCard
              columns={columnsTicketDist}
              loading={dataTicketDistributionLoading}
              dataSet={dataTicketDistribution}
              footerData={dataTicketDistributionFooter}
              title={` ${t("CRM.Tickets.Fields.Ticket")} Distribution by Category`}
            />
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>
    </>
  );
}
