import { Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons"
import { useState } from "react";

function TablePhoneNumberCopier({phone = ''}: any){
    const [tooltip, setTooltip] = useState('Copy' as any);
    const copyNumber = () => {
        setTooltip('Copied')
        phone = phone.split("+91").join("");
        navigator.clipboard.writeText(phone)
    }
    const resetTooltipText = () =>{
        setTimeout(() => {
            setTooltip('Copy')
        }, 200);
    }
    return(
        <>{phone && <Tooltip onVisibleChange={resetTooltipText} title={tooltip} ><span onClick={copyNumber}>{phone} <CopyOutlined className='text-secondary'  /></span></Tooltip>}</>
    )
}

export default TablePhoneNumberCopier;