export type RootState = {
  auth: any;
  user: any;
  branch: any;
  requestLogs: any;
  team: any;
  permission: any;
  department: any;
  role: any;
  messageTemplate: any;
  doctor: any;
  master: any;
  slot: any;
  patient: any;
  appointment: any;
  prescription: any;

  upload: any;
  profile: any;
  chat: any;
  contact: any;
  leads: any;
  metadata: any;
  calllogs: any;
  teamTypes: any;
  pharmacy: any;
  tickets: any;
  assignment: any;
  actionmap: any;
  agents: any;
  settings: any;
  region: any;
  social: any;
};

// export const CONFIG_ENDPOINT_BASE = "https://cdn.maxhospitals.in/medi-ui/crm/";
const data = localStorage.getItem("config");
const allData = JSON.parse(data || "{}");

export const BASE_API_URL = allData?.BASE_API_URL || "";
export const BASE_RAG_API_URL = allData?.BASE_RAG_API_URL || "";
export const BASE_AUTH_API_URL = allData?.BASE_AUTH_API_URL || BASE_API_URL;
export const BASE_API_URL_BETAMEDI = allData?.BASE_API_URL_2 || "";
export const BASE_SOCIAL_API_URL =
  allData?.BASE_SOCIAL_API_URL || "https://betasocialapi.hlthclub.in/vault/v1/";
export const BASE_WEB_URL = allData?.BASE_WEB_URL; //"https://maxalpha-medi.maxhospitals.in/";
export const GLOBAL_LIST_LIMIT = allData?.GLOBAL_LIST_LIMIT; //25;
export const ACCOUNT_PREFIX: string = allData?.ACCOUNT_PREFIX; //"max";
export const SENTRY_INIT_NAME: string = allData?.SENTRY_INIT_NAME; //"max-ui";
export const CLIENT_NAME: string = allData?.CLIENT_NAME; //"Max";
export const APPLICATION_NAME: string = allData?.APPLICATION_NAME || ""; //"Max Hospitals";
export const ENABLE_NOTIFICATION: boolean = allData?.ENABLE_NOTIFICATION; //true;
export const ENABLE_WEB_SOCKET: boolean = allData?.ENABLE_WEB_SOCKET; //true;
export const ADD_TOKEN_BASE_URL: boolean = allData?.ADD_TOKEN_BASE_URL; //true;

export const TNC_URL: string = allData?.TNC_URL; //
// "https://cdn.maxhospitals.in/terms_conditions/max_tnc.html";
export const PRIVACY_POLICY_URL: string = allData?.PRIVACY_POLICY_URL; //
// "https://cdn.maxhospitals.in/terms_conditions/max_privacy_policy.html";
export const SENTRY_INIT_DSN: string = allData?.SENTRY_INIT_DSN; //
// "https://3faba4cf081140be9d341f3e55afd2aa@o1090269.ingest.sentry.io/6274274";
export const CHAT_BASE_URL: string = allData?.CHAT_BASE_URL; //"https://alphawebsocket.maxhospitals.in/";
export const WEBSOCKET_END_POINT: string = allData?.WEBSOCKET_END_POINT; //
// "wss://o6a1gv6qc8.execute-api.ap-south-1.amazonaws.com/dev";
export const SERVICE_LIST = allData?.SERVICE_LIST;
// [
//   // {name: 'book_appointment'},
//   { name: "order_medicines" },
// ];
export const TICKET_APPOINTMENT_ASSOCIATION: boolean =
  allData?.TICKET_APPOINTMENT_ASSOCIATION; //true;
export const LOGO_URL: string = allData?.LOGO_URL; //true;

export const GOOGLE_MAP_API = "AIzaSyDuaDg-vPiU5qfaPr1ZM3t3ldMuRP_IsHc";
export const TRANSLATION_KEY = allData?.TRANSLATION_KEY || "en";
export const MSAL_CLIENT_ID =
  allData?.MSAL_CLIENT_ID || "2e2fb9ef-370b-4fbb-8e02-dd01d6777e39";
export const MSAL_TENANT_ID =
  allData?.MSAL_TENANT_ID || "d671e0e4-ec0a-44c7-9e66-f1746636bc0d"; // "0336464a-c425-408c-a568-15c3416a24b8";
export const MAX_COGNITO_REFRESH_TIMES = 24;

export const TICKET_EXTERNAL_ID_VISIBLE =
  allData?.TICKET_EXTERNAL_ID_VISIBLE || false;
export const IS_JARVIS = allData?.IS_JARVIS || false;
export const IS_CIPLA = allData?.IS_CIPLA || false;

export const ticket_list_column_config: any =
  allData?.TICKET_LIST_COLUMN_CONFIG || {
    contact: 1,
    branch: 2,
    ticket_type: 3,
    ticket_subtype: 4,
    category: 5,
    external_id: 6,
    assignee: 7,
    status: 8,
    created_at: 9,
    due_date: 10,
    source: 11,
    priority: 12,
    follow_up_date: 13,
  };
