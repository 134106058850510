import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../shared/Utils/api-client";
//import { Contact } from '../../shared/types/contact.type';
import {
  ADD_NEW_NOTIFICATION,
  CONTACTS,
  CONTACT_AND_PATIENT_BY_MOBILE,
  CONTACT_BY_ID,
  CONTACT_CREATE_FROM_PATIENT,
  ENABLE_DISABLE_NOTIFICATION,
  GET_NOTIFICATION_SETTINGS_BY_CONTACT,
  GET_NPS_BY_MOBILE_NUMBER,
} from "../../shared/routes/crm.routes.constants";
import { PHONE_TRANSACTIONS } from "../../shared/routes/route.constants";
const config = {
  name: "contacts",
};
export const fetchContactList: any = createAsyncThunk(
  `${config.name}/fetchContactList`,
  async (params: any) => {
    return await Client.get(CONTACTS, { params });
  }
);
export const fetchContactListFiltered: any = createAsyncThunk(
  `${config.name}/fetchContactListFiltered`,
  async (params: any) => {
    return await Client.get(CONTACTS, { params });
  }
);
export const fetchContact: any = createAsyncThunk(
  `${config.name}/fetchContact`,
  async (id: string) => {
    return await Client.get(CONTACT_BY_ID(id), {});
  }
);
export const fetchContactAndPatientByMobile: any = createAsyncThunk(
  `${config.name}/fetchContactAndPatientByMobile`,
  async (phone: string) => {
    return await Client.get(CONTACT_AND_PATIENT_BY_MOBILE(phone), {});
  }
);
export const addNewContact: any = createAsyncThunk(
  `${config.name}/addNewContact`,
  async (body: any) => {
    return await Client.post(CONTACTS, body);
  }
);
export const createContactFromPatient: any = createAsyncThunk(
  `${config.name}/createContactFromPatient`,
  async (body: any) => {
    return await Client.post(CONTACT_CREATE_FROM_PATIENT, body);
  }
);
export const patchContact: any = createAsyncThunk(
  `${config.name}/patchContact`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(CONTACT_BY_ID(id), data);
  }
);
export const getTransactionsByPhone: any = createAsyncThunk(
  `${config.name}/getTransactionsByPhone`,
  async (phone: string) => {
    return await Client.get(PHONE_TRANSACTIONS(phone));
  }
);

export const getNpsByMobile: any = createAsyncThunk(
  `${config.name}/getNpsByMobile`,
  async (phone: string) => {
    return await Client.get(GET_NPS_BY_MOBILE_NUMBER(phone));
  }
);

export const getNotificationSettingsByContact: any = createAsyncThunk(
  `${config.name}/getNotificationSettingsByContact`,
  async (id: string) => {
    return await Client.get(GET_NOTIFICATION_SETTINGS_BY_CONTACT(id));
  }
);
export const addNewNotificationContact: any = createAsyncThunk(
  `${config.name}/addNewNotificationContact`,
  async (body: any) => {
    return await Client.post(ADD_NEW_NOTIFICATION, body);
  }
);
export const enableDisableNotification: any = createAsyncThunk(
  `${config.name}/enableDisableNotification`,
  async ({ id, body }: any) => {
    return await Client.patch(ENABLE_DISABLE_NOTIFICATION(id), body);
  }
);

// export const getTransactionsByPhone: any = createAsyncThunk(
//   `${config.name}/getTransactionsByPhone`,
//   async (phone: string) => {
//     return await Client.get(PHONE_TRANSACTIONS(phone));
//   }
// );

export const contactSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    contacts: [] as any,
    pagination: { offset: 0, limit: 10, total: 0 },
    error: "" as string,
    contact: null as any,
    contactFiltered: [] as any,
    loading_filtered: false,
    cpCombined: [] as any,
    loading_cp: false,
  },
  reducers: {
    resetContact: (state) => {
      state.contacts = [];
      state.loading = false;
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.error = "";
      state.contact = null as any;
      state.contactFiltered = [];
      state.loading_filtered = false;
      state.cpCombined = [];
      state.loading_cp = false;
    },
  },
  extraReducers(builder) {
    builder
      //  Fetch contacts
      .addCase(fetchContactList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchContactList.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchContactList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Contact Filtered
      .addCase(fetchContactListFiltered.pending, (state) => {
        state.loading_filtered = true;
        state.error = "";
      })
      .addCase(fetchContactListFiltered.fulfilled, (state, action) => {
        state.loading_filtered = false;
        state.contactFiltered = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchContactListFiltered.rejected, (state, action) => {
        state.loading_filtered = false;
        state.error = action.error.message;
      })
      // Contact Filtered
      .addCase(fetchContactAndPatientByMobile.pending, (state) => {
        state.loading_cp = true;
        state.error = "";
      })
      .addCase(fetchContactAndPatientByMobile.fulfilled, (state, action) => {
        state.loading_cp = false;
        state.cpCombined = action?.payload?.data?.data;
      })
      .addCase(fetchContactAndPatientByMobile.rejected, (state, action) => {
        state.loading_cp = false;
        state.error = action.error.message;
      })
      // Contact Details
      .addCase(fetchContact.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        state.loading = false;
        state.contact = action?.payload?.data?.data;
      })
      .addCase(fetchContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Add New Contact
      .addCase(addNewContact.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(addNewContact.fulfilled, (state, action) => {
        state.loading = false;
        // state.contacts = action?.payload?.data?.data?.items;
      })
      .addCase(addNewContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Patch call log
      .addCase(patchContact.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(patchContact.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action);
        // state.calllog = action?.payload?.data?.data;
      })
      .addCase(patchContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetContact } = contactSlice.actions;

export default contactSlice.reducer;
