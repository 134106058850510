import { Skeleton } from "antd";
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { createCalllog } from '../../../redux/reducers/calllogs.slice';
import { RootState } from '../../../shared/constants';
import { ROUTE_CRM_CALL_LOGS, ROUTE_CRM_CALL_LOG_DETAILS } from '../../../shared/routes/crm.routes.constants';


function CRMCreateCallLog() {
    const history = useHistory();
    const dispatch = useDispatch();
    const search = useLocation().search;

    const { loading } = useSelector((state: RootState) => state.calllogs);
    const [exec, setExec] = useState(false);

    const call_id = new URLSearchParams(search).get('call_id');
    if (!call_id) {
        history.push(ROUTE_CRM_CALL_LOGS);
    }
    const phone = new URLSearchParams(search).get('calling_number');
    const call_agent_id = new URLSearchParams(search).get('call_agent_id');
    const call_type = new URLSearchParams(search).get('call_type');
    const call_start_time = new URLSearchParams(search).get('call_start_time');
    const priline_number = new URLSearchParams(search).get('priline_number');
    const event_type = new URLSearchParams(search).get('event_type');
    const branch = new URLSearchParams(search).get('branch');
    let data = {
        country_code: 91,
        call_id: call_id,
        phone: phone,
        agent_id: call_agent_id,
        call_type: call_type,
        call_date: call_start_time,
        call_start_time: call_start_time,
        priline_number: priline_number,
        event_type: event_type == '' || !event_type ? 'call_start' : event_type,
        source: 'call_pop',
        branch: branch ? branch : null
    }
    useEffect(() => {
        if (loading === false && exec === false) {
            dispatch(createCalllog(data)).then((val: any) => {
                if (val?.payload?.data?.statusCode == 200) {
                    history.push(ROUTE_CRM_CALL_LOG_DETAILS(val.payload.data.data.id))
                }
            })
            setExec(true)
        }
    }, [loading])

    return (
        <Fragment>
            <Skeleton></Skeleton>
        </Fragment>
    );
}

export default CRMCreateCallLog;
