import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../shared/Utils/api-client";
import {
  BULKUPDATELEAD,
  DOWNLOADCALLLOGDATA,
  GET_PATIENT_ADDRESS,
  LEADS,
  LEADS_COUNT,
  LEAD_BY_ID,
  LEAD_DOWNLOAD,
  LEAD_SERVICE_MAP,
  LEAD_STATUS,
  REASONLISTLEAD,
} from "../../shared/routes/crm.routes.constants";
const config = {
  name: "leads",
};
export const fetchLeadsList: any = createAsyncThunk(
  `${config.name}/fetchLeadsList`,
  async (params: any) => {
    return await Client.get(LEADS, { params });
  }
);
export const fetchReasonListLead: any = createAsyncThunk(
  `${config.name}/fetchReasonListLead`,
  async (params: any) => {
    return await Client.get(REASONLISTLEAD, { params });
  }
);
export const fetchLeadListCount: any = createAsyncThunk(
  `${config.name}/fetchLeadListCount`,
  async () => {
    return await Client.get(LEADS_COUNT);
  }
);
export const fetchLeadsListDownload: any = createAsyncThunk(
  `${config.name}/fetchLeadsListDownload`,
  async (params: any) => {
    return await Client.get(LEADS, { params });
  }
);
export const fetchLeadDetails: any = createAsyncThunk(
  `${config.name}/fetchLeadDetails`,
  async (id: string, params: any) => {
    return await Client.get(LEAD_BY_ID(id), {});
  }
);
export const getPatientAddress: any = createAsyncThunk(
  `${config.name}/getPatientAddress`,
  async (id: string, params: any) => {
    return await Client.get(GET_PATIENT_ADDRESS(id), { mediURL: true });
  }
);
export const createLead: any = createAsyncThunk(
  `${config.name}/createLead`,
  async (params: any) => {
    return await Client.post(LEADS, params);
  }
);
export const patchLead: any = createAsyncThunk(
  `${config.name}/patchLead`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(LEAD_BY_ID(id), data);
  }
);
export const updateLeadStatus: any = createAsyncThunk(
  `${config.name}/updateLeadStatus`,
  async (params: any) => {
    return await Client.post(LEAD_STATUS, params);
  }
);
export const updateLeadBulk: any = createAsyncThunk(
  `${config.name}/updateLeadBulk`,
  async (body: any) => {
    return await Client.patch(BULKUPDATELEAD, body);
  }
);
export const leadServiceMap: any = createAsyncThunk(
  `${config.name}/leadServiceMap`,
  async (params: any) => {
    return await Client.post(LEAD_SERVICE_MAP, params);
  }
);
export const getLeadServiceMap: any = createAsyncThunk(
  `${config.name}/getLeadServiceMap`,
  async (params: any) => {
    return await Client.get(LEAD_SERVICE_MAP, { params });
  }
);

export const downloadCallLogData: any = createAsyncThunk(
  `${config.name}/downloadCallLogData`,
  async (params: any) => {
    return await Client.get(DOWNLOADCALLLOGDATA, { params });
  }
);

export const downloadLeadData: any = createAsyncThunk(
  `${config.name}/downloadLeadData`,
  async (params: any) => {
    return await Client.get(LEAD_DOWNLOAD, { params });
  }
);

export const leadsSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    loading_details: false,
    loading_cu_op: false,
    leads: [] as any,
    lead: {} as any,
    leadServices: [] as any,
    loadingServices: false,
    pagination: { offset: 0, limit: 10, total: 0 },
    error: "" as string,
    filters: null as any,
    selectedStatusIds: null as any,
    sorters: {
      sort_column: "due_date",
      sort_val: null,
    } as any,
  },
  reducers: {
    reset: (state) => {
      state.leads = [];
      state.loading = false;
      state.loading_details = false;
      state.loading_cu_op = false;
      state.leadServices = false;
      state.loadingServices = false;
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.error = "";
      state.lead = {};
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSorters: (state, action) => {
      state.sorters = action.payload;
    },
    setSelectedStatusIds: (state, action) => {
      state.selectedStatusIds = action.payload;
    },
    clearLeadDetails: (state) => {
      state.lead = {} as any;
    },
  },
  extraReducers(builder) {
    builder
      //  Fetch leads
      .addCase(fetchLeadsList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchLeadsList.fulfilled, (state, action) => {
        state.loading = false;
        state.leads = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchLeadsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //create lead
      .addCase(createLead.pending, (state) => {
        state.loading_cu_op = true;
        state.error = "";
      })
      .addCase(createLead.fulfilled, (state, action) => {
        state.loading_cu_op = false;
      })
      .addCase(createLead.rejected, (state, action) => {
        state.loading_cu_op = false;
        state.error = action.error.message;
      })

      //fetch lead
      .addCase(fetchLeadDetails.pending, (state) => {
        state.loading_details = true;
        state.lead = {};
        state.error = "";
      })
      .addCase(fetchLeadDetails.fulfilled, (state, action) => {
        state.loading_details = false;
        state.lead = action?.payload?.data?.data;
      })
      .addCase(fetchLeadDetails.rejected, (state, action) => {
        state.loading_details = false;
        state.lead = {};
        state.error = action.error.message;
      })

      //Patch lead
      .addCase(patchLead.pending, (state) => {
        state.loading_cu_op = true;
        state.error = "";
      })
      .addCase(patchLead.fulfilled, (state, action) => {
        state.loading_cu_op = false;
      })
      .addCase(patchLead.rejected, (state, action) => {
        state.loading_cu_op = false;
        state.error = action.error.message;
      })

      //update lead status
      .addCase(updateLeadStatus.pending, (state) => {
        state.loading_cu_op = true;
        state.error = "";
      })
      .addCase(updateLeadStatus.fulfilled, (state, action) => {
        state.loading_cu_op = false;
      })
      .addCase(updateLeadStatus.rejected, (state, action) => {
        state.loading_cu_op = false;
        state.error = action.error.message;
      })
      //update lead status
      .addCase(getLeadServiceMap.pending, (state) => {
        state.error = "";
        state.loadingServices = true;
      })
      .addCase(getLeadServiceMap.fulfilled, (state, action) => {
        state.leadServices = action?.payload?.data?.data?.items;
        state.loadingServices = false;
      })
      .addCase(getLeadServiceMap.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingServices = false;
      });
  },
});

export const {
  setFilters,
  clearLeadDetails,
  setSorters,
  setSelectedStatusIds,
  reset,
} = leadsSlice.actions;

export default leadsSlice.reducer;
