import {
  Col,
  DatePicker,
  Divider,
  Empty,
  List, Row,
  Select,
  Skeleton
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getTransactionsByPhone } from "../../../redux/reducers/contacts.slice";
import { RootState } from "../../../shared/constants";
import FormBar from "../../../shared/layouts/formbar.layout";
import { formatDate, formatDropDownValue } from "../../../shared/Utils/utilities";
import GeneralTicketActions from "./ticket-general-drawers";

const { RangePicker } = DatePicker;
const { Option } = Select;
type Props = {
  phone: string;
};
function TransactionsTab({ phone = "" }: Props) {
  const dispatch = useDispatch();
  const [initAction, setInitAction] = useState(false as any);
  const [transactionsData, setTransactionsData] = useState(null as any);
  const [appointmentData, setAppointment] = useState(null as any);
  const [appointmentId, setAppointmentId] = useState(null as any);
  const { appointment } = useSelector((state: RootState) => state.appointment);
  const { loading_filtered, contactFiltered } = useSelector(
    (state: RootState) => state.contact
  );
  const [filters, setFilters] = useState(null as any);
  const [loading, setLoading] = useState(false as any);
  const [formElements, setFormElements] = useState(null as any);
  const [contactList, setContactList] = useState([] as any);
  const [billDataContent, setbillDataContent] = useState(null as any);



  const getBillData = (billId: any) => {
    setbillDataContent(null);
  };

  useEffect(() => {
    setLoading(true)
    phone && dispatch(getTransactionsByPhone(phone)).then((val: any) => {
      setLoading(false)
      setTransactionsData(val.payload.data.data ? val.payload.data.data : null)
    })

  }, [])



  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] === "" || all[key] === undefined) {
      } else {
        if (key == "range") {
          filtersObj.from_date = all.range ? all.range[0].format() : null;
          filtersObj.to_date = all.range ? all.range[1].format() : null;
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    setFilters(filtersObj);
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "itemname",
      key: "itemname",
      render: (a: any) => {
        return <>{a ? a : "-"}</>;
      },
      className: "capitalize",
    },
    {
      title: "Type",
      dataIndex: "itemtype",
      key: "itemtype",
      render: (a: any) => {
        return <>{a ? a : "-"}</>;
      },
      className: "capitalize",
    },
    {
      title: "Gross Amount",
      dataIndex: "grossamount",
      key: "grossamount",
      render: (a: any) => {
        return <>{a ? a : "-"}</>;
      },
    },
    {
      title: "Net Amount",
      dataIndex: "netamount",
      key: "netamount",
      render: (a: any) => {
        return <>{a ? a : "-"}</>;
      },
    },
  ];
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };

  return (
    <>
      <Row className="bg-app" align="middle" style={{ paddingTop: "14px" }}>
        <Col span={24}>
          <p className="paddingx20 bold-600 text-secondary">
            All history associated with +91 {phone}
          </p>
          {formElements && !screenType.mobile && (
            <FormBar
              name={"generic_filters"}
              onFinish={formElements.onFinish}
              onFinishFailed={formElements.onFinishFailed}
              formElements={formElements.formElements}
              initialValues={formElements.initialValues}
              onValuesChange={formElements.onValuesChange}
              buttonText={""}
              setValues={formElements.setValues ? formElements.setValues : null}
            ></FormBar>
          )}
        </Col>
      </Row>
      {loading && <Skeleton />}
      {transactionsData ? (
        <div style={{ maxHeight: "calc(100vh - 250px)", overflowY: "scroll" }}>
          {/* <div className='fixed-header-comp'> */}
          <Row>
            <Col span={24} className="padding-bottom-20 paddingx20">
              <List
                itemLayout="vertical"
                pagination={{
                  onChange: (page) => {
                    console.log(page);
                  },
                  pageSize: 4,
                }}
                dataSource={transactionsData}
                renderItem={(item: any) => (
                  <List.Item key={item?.id}>
                    <Row align="middle">
                      <Col
                        {...{
                          xs: 24,
                          sm: 24,
                          md: 8,
                          lg: 8,
                          xl: 8,
                        }}
                        className="capitalize"
                      >
                        {/* <p className="capitalize"> */}
                        <span className="capitalize">
                          <b>{item?.servicetypes && formatDropDownValue(item?.servicetypes)}</b>
                        </span>
                        <br />
                        Patient: <span className="capitalize">
                          {item?.name} {" "}
                        </span>
                        {item?.age || item?.gender ? "(" : ""}
                        {item?.age && (
                          <small className="text-secondary">
                            {(item?.age) + "yrs, "}
                          </small>
                        )}
                        {item?.gender && (
                          <small className="text-secondary">
                            {item?.gender &&
                              item?.gender?.split("")[0]?.toUpperCase()}
                          </small>
                        )}
                        {item?.age || item?.gender ? ")" : ""}
                        <br />
                        Type: {item?.patienttype?.toUpperCase()}
                        {/* </p> */}
                      </Col>
                      <Col
                        {...{
                          xs: 24,
                          sm: 24,
                          md: 9,
                          lg: 9,
                          xl: 9,
                        }}
                        className="text-secondary capitalize"
                      >
                        Transaction Details: <br />
                        <span className="capitalize">
                          {item?.channels} {item?.centrename && <Divider type="vertical"></Divider>}  {item?.centrename} <br />
                        </span>
                        Billing Time: {item?.bookingdate ? formatDate(item?.bookingdate) : ''}

                        {/* <Popover
                          content={
                            billDataContent ? (
                              <Table
                                pagination={{ pageSize: 5 }}
                                dataSource={billDataContent}
                                columns={columns}
                                size="small"
                              />
                            ) : (
                              <LoadingOutlined />
                            )
                          }
                          title="Summary"
                          trigger="click"
                        >
                          {item?.billid && (
                            <EyeFilled
                              onClick={() => getBillData(item?.billid)}
                              className="paddingl10"
                            />
                          )}
                        </Popover> */}
                      </Col>
                      <Col
                        {...{
                          xs: 24,
                          sm: 24,
                          md: 7,
                          lg: 7,
                          xl: 7,
                        }}
                        className="text-secondary text-right"
                      >
                        {/* {item?.packages &&
                          item?.packages?.length > 0 &&
                          item?.packages?.map((val: any) => (
                            <Tag color="green" className="solid-full">
                              <span className="capitalize">{val}</span>
                            </Tag>
                          ))} */}
                        <span className="capitalize">
                          {item?.entitydoctorname && 'Dr. ' + item?.entitydoctorname} {item?.speciality && <Divider type="vertical"></Divider>} {item?.speciality}
                        </span>
                        {/* {item?.referral_doctor} */}
                        {/* <Tag
                          color={
                            ["DONE"].indexOf(item?.payment_status) !== -1
                              ? "gray"
                              : item?.service_status == "CANCELLED"
                              ? "red"
                              : "blue"
                          }
                        >
                          {item?.service_status}
                        </Tag> */}
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </div>
      ) : (

        !loading && <Row>
          <Col span={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>


      )}

      {initAction && appointment && (
        <GeneralTicketActions
          ticket={null}
          appointment={appointment}
          appointmentRequestStatus={null}
          action={initAction}
          hideMe={(a) => {
            setInitAction(false);
          }}
        ></GeneralTicketActions>
      )}
    </>
  );
}
export default TransactionsTab;
