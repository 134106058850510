import { Avatar, Badge, Col, List, Row } from "antd";
import moment from "moment";
import React from "react";
import {
    formatDate, getInitials
} from "../../../shared/Utils/utilities";
var _ = require("lodash");

type Props = {
    appointments?: any;
    patients?: any;
    showDetails?: (values: any) => void;
    selectedAppointment?: any;
};
const PatientList: React.FunctionComponent<Props> = ({
    appointments,
    showDetails,
    patients,
    selectedAppointment,
}) => {
    return (

        <Row>
            {(
                <List
                    style={{ width: "100%" }}
                    dataSource={patients}
                    renderItem={(item: any) => (
                        <List.Item
                            className={
                                selectedAppointment === item.chat_room_id
                                    ? `selected-patient padding20LR`
                                    : "padding20LR"
                            }
                        >
                            <Row onClick={() => showDetails && showDetails(item)}
                                className="full-width pointer">
                                <Col span={3} className="mt5">
                                    <Badge
                                        count={item?.is_new_unread_msg ? " " : 0}
                                        size={"small"}
                                    >
                                        <Avatar
                                            shape={"square"}
                                            size={"large"}
                                            src={
                                                _.find(item.members, { role: "user" })?.profile_pic
                                            }
                                            className="user-frame"
                                        >
                                            {getInitials(
                                                _.find(item.patient_name)
                                                // _.find(item.members, { role: "user" })?.name || 'rajesh'
                                            )}
                                        </Avatar>
                                    </Badge>
                                </Col>
                                <Col span={21} className={"pl10"}>
                                    <Row>
                                        <Col span={16}>
                                            <span
                                                className="doctor-primary-color bold cursor capitalize"
                                            >
                                                {item?.patient_name && `+${item?.patient_name}`} {" "}
                                                {/* <small> {`(${item?.patient_mobile})`} { }</small> */}
                                                {/* <small className="max-subHead-color light-text">
                                                    (
                                                    {["NaNyrs", null, "Null"].indexOf(
                                                        getAge(
                                                            _.find(item.members, { role: "user" })?.age
                                                        )
                                                    ) === -1
                                                        ? getAge(
                                                            _.find(item.members, { role: "user" })?.age
                                                        )
                                                        : _.find(item.members, { role: "user" })
                                                            ?.age}{" "}
                                                    {getGender(
                                                        _.find(item.members, { role: "user" })?.gender
                                                    )}{" "}
                                                    )
                                                </small>{" "} */}
                                            </span>
                                        </Col>
                                        <Col span={8} className="text-right">
                                            <i className="max-subHead-color font12">
                                                {moment(item?.last_msg_time).fromNow()}
                                            </i>
                                        </Col>
                                        <Col span={24}>
                                            <p>
                                                <i className="max-subHead-color font12">
                                                    Last Msg:{formatDate(item?.last_msg_time)}
                                                </i>
                                            </p>
                                        </Col>
                                        <Col span={24} className="mt-10">
                                            <i className="sub-title">{item?.last_msg?.split(' ')?.slice(0, 10).join(' ')}</i>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            )}
        </Row>
    );
};

export default PatientList;
