import { Pagination } from "antd";

type Props = {
  skip?: string;
  take?: string;
  total?: any;
  onChange: (values: string, val: any) => void;
  defaultCurrent?: number;
  showTotal?: boolean;
  onShowSizeChange?: any;
  defaultPageSize?: any;
  showSizeChangerFlag?: boolean
};


function PaginationLayout({
  showTotal = false,
  onChange,
  total,
  defaultCurrent,
  onShowSizeChange,
  defaultPageSize,
  showSizeChangerFlag
}: Props) {

  return (
    <Pagination
      showTotal={(total, range) => {
        return showTotal === true ? `${range[0]}-${range[1]} of ${total} ` : "";
      }}
      current={defaultCurrent}
      responsive={true}
      simple={window.innerWidth <= 900}
      size={window.innerWidth <= 1200 ? "small" : "default"}
      showLessItems
      showSizeChanger={showSizeChangerFlag ? true : false}
      onShowSizeChange={onShowSizeChange}
      defaultCurrent={defaultCurrent}
      defaultPageSize={defaultPageSize}
      total={parseInt(total)}
      onChange={(evt: any, vals: any) => onChange(evt, vals)}
    />
  );
}

export default PaginationLayout;