import { message } from "antd";
import { Client } from "../../shared/Utils/api-client";
import { ACCOUNT_PREFIX } from "../constants";
import { CALL_INITIATE } from "../routes/crm.routes.constants";
declare const ctiMakeCall: any;
export const initiateCallFn = async (phone: any, callData: any = {}) => {
  if (ACCOUNT_PREFIX === "max") {
    await Client.post(CALL_INITIATE, { cli: phone, ...callData });
  }
  if (ACCOUNT_PREFIX === "wockhardt") {
    phone = phone.split("+91").join("");
    await ctiMakeCall(phone, "");
  }
  message.success("Call initiated.");
};
