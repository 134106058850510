import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Upload } from "antd";
import type { UploadProps } from "antd/es/upload/interface";
import { MouseEventHandler, useRef, useState } from "react";

type Props = {
    handleCancel: MouseEventHandler<HTMLElement>;
    getDescription?: any;
    getContentBytes?: any;
    fileData?: any;
};

const UploadPrescriptionBaseSixtyFour = (componentProps: Props) => {
    const [fileList, setFileList] = useState([] as any);
    const [uploading, setUploading] = useState(false);
    const [description, setDescription] = useState("");
    const [fileContentBytes, setFileContentBytes] = useState(null as any);
    // const [maxFileCountErrorShown, setMaxFileCountErrorShown] = useState(false);
    const maxFileCountErrorShownRef = useRef(false);


    const handleUpload = async () => {
        try {
            setUploading(true);
            const filesData = [];

            componentProps.getDescription(description);

            for (const file of fileList) {
                const { type, name, uid } = file;


                // Process non-image files (PDFs)
                filesData.push({
                    content_bytes: fileContentBytes[uid], // Retrieve contentBytes from the map
                    content_type: type || "",
                    file_name: name || "",
                });
            }

            // Ensure that the following code runs after the loop completes
            componentProps.fileData(filesData);
            setUploading(false);
            setDescription("");
            setFileList([]);
            setFileContentBytes({}); // Reset contentBytes map after upload
        } catch (e) {
            message.error('Something went wrong.');
            setUploading(false); // Ensure to set uploading to false in case of errors
        }
    };

    const props: UploadProps = {
        // accept: "image/jpg,image/jpeg,image/png,application/pdf",
        multiple: true,
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: async (file, fsList) => {
            const base64Data = await getBase64(file);

            // Update contentBytes map for each file
            setFileContentBytes((prevState: any) => ({
                ...prevState,
                [file.uid]: base64Data,
            }));

            const maxSizeInBytes = 5 * 1024 * 1024; // 15 MB in bytes
            if (file.size > maxSizeInBytes) {
                message.warning("File size must be up to 5 MB", 4);
                return false;
            }
            if (fsList?.length > 10 && !maxFileCountErrorShownRef.current) {
                message.warning("You can only upload 10 files at a time", 4);
                maxFileCountErrorShownRef.current = true;
                return false;
            }
            if (fsList.length >= 10) {
                const updatedFileList = [...fsList, file].slice(0, 9); // Limit to 10 files
                setFileList(updatedFileList);
            }

            const { name } = file;
            let ext: any = name?.split(".");
            ext = ext?.length > 0 ? ext[ext.length - 1] : "";
            if (
                [
                    "png",
                    "jpg",
                    "jpeg",
                    "gif",
                    "pdf",
                    "xls",
                    "xlsx",
                    "csv",
                    "doc",
                    "docx",
                ].includes(ext)
            ) {
            } else {
                message.error("Uploaded file is not valid", 10);
                return;
            }

            // ... (additional file type validations)

            setFileList((prevFileList: any) => [...prevFileList, file]);
            return false;
        },
        maxCount: 10,
        fileList
    };

    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    return (
        <>
            <form action="">
                <Row className={"form-group"}>
                    <Col span={24}>
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                        <small>(Supported format: Pdf, Image, Doc upto 5MB.)</small>
                    </Col>
                </Row>
                <Row className={"mt15"}>
                    <Col span={24}>
                        <label htmlFor="description" className={"bold"}>Description</label> <br />
                        <Input size={"middle"} name={"description"} value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Col>
                </Row>
                <div className="text-right mt15">
                    <Button type={"default"} onClick={componentProps.handleCancel} disabled={uploading}>Cancel</Button>
                    <Button
                        type="primary"
                        onClick={handleUpload}
                        disabled={fileList.length === 0 || !description}
                        loading={uploading}
                        style={{ marginLeft: 15 }}
                    >
                        {uploading ? "Uploading" : "Upload Document"}
                    </Button>
                </div>
            </form>
        </>
    );
};

export default UploadPrescriptionBaseSixtyFour;
