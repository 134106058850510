import { Button, Col, Empty, notification, Row, Select } from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchLeadDetails } from "../../../../redux/reducers/leads.slice";
import {
  fetchPatientsDocuments,
  fetchPatientsDocumentsByURL
} from "../../../../redux/reducers/patients.slice";
import {
  createPharmacyOrderRequest,
  fetchMedicinesById,
  mapPrescriptionToCart
} from "../../../../redux/reducers/pharmacy.slice";
import { fetchPrescriptions } from "../../../../redux/reducers/prescription.slice";
import {
  fetchPatientDocumentFileById,
  fetchUploadedFileById
} from "../../../../redux/reducers/upload.slice";
import { RootState } from "../../../../shared/constants";
import FileViewLayout from "../../../../shared/layouts/file-view.layout";
import MedicineOrderComponent from "../../../../shared/layouts/pharmacy-medicine-order.layout";
import PlaceOrderComponent from "../../../../shared/layouts/pharmacy-place-order.layout";
import SelectedMedicine from "../../../dependencies/selected-medicine.component";
const Option = Select;
type Props = {
  contact: any;
  lead?: any;
  ticket?: any;
  orderId?: any;
  pharmacyDetailsPre?: boolean;
};
function OrderMedicineCompoment({
  contact,
  lead,
  ticket,
  orderId,
  pharmacyDetailsPre = false,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pharmacyOrder, pharmacyDetails } = useSelector(
    (state: RootState) => state.pharmacy
  );
  const [appointmentId, setAppointmentId] = useState(null as any);
  const [patientId, setPatientId] = useState(null as any);
  const [allPrescriptions, setAllPrescriptions] = useState(null as any);
  const [allPrescriptionsDirect, setAllPrescriptionsDirect] = useState(
    null as any
  );
  const [fileUrl, setfileUrl] = useState(null as any);
  const [activeFile, setActiveFile] = useState(null as any);
  const [isEditMedicine, setIsEditMedicine] = useState(null as any);
  const [prescriptionType, setprescriptionType] = useState(null as any);
  const [drPrescriptions, setdrPrescriptions] = useState(null as any);
  const [patientPrescription, setpatientPrescription] = useState(null as any);
  const [prescriptionMapped, setPrescriptionMapped] = useState(false);
  const [loadingPharmacy, setLoadingPharmacy] = useState(false);

  const prescriptionTypes = [
    {
      name: 'Patient',
      value: 'patient',
      label: 'Patient'
    },
    {
      name: 'Doctor',
      value: 'doctor',
      label: 'Doctor'
    }
  ]

  useEffect(() => {
    setprescriptionType(prescriptionTypes[0]?.value)
  }, [])


  useEffect(() => {
    if (contact?.patient_id && lead?.appointment_id && !orderId) {
      dispatch(
        createPharmacyOrderRequest({
          request_type: "pharmacy",
          customer_id: contact.patient_id,
          item_count: 0,
          source: "CALL_LOG",
          status: "open",
          total_amount: 0,
          comments: "",
          appointment_id: lead?.appointment_id,
          lead_id: lead?.id,
          lead_source: lead?.source?.name || "CALL LOG",
          collection_at: 'HOME'
        })
      );
      // setAppointmentId(lead?.appointment_id)
    }
    if (contact?.patient_id && lead && !lead?.appointment_id && !orderId) {
      dispatch(
        createPharmacyOrderRequest({
          request_type: "pharmacy",
          customer_id: contact.patient_id,
          item_count: 0,
          source: "CALL_LOG",
          status: "open",
          total_amount: 0,
          comments: "",
          lead_id: lead?.id,
          lead_source: lead?.source?.name || "CALL LOG",
          collection_at: 'HOME'
        })
      );
      // setAppointmentId(lead?.appointment_id)
    }
  }, [contact]);

  useEffect(() => {
    if (pharmacyOrder?.id) {
      dispatch(fetchMedicinesById(pharmacyOrder.id)).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          const pharmacy = val?.payload?.data?.data?.pharmacy;
          processPharmacy(pharmacy);
        }
      });
    }
  }, [pharmacyOrder]);
  useEffect(() => {
    setActiveFile(null)
  }, [prescriptionType])

  function processPharmacy(pharmacy: any) {
    if (pharmacy?.appointment_id) {
      setAppointmentId(pharmacy?.appointment_id);
    }
    if (pharmacy?.customer_id) {
      setPatientId(pharmacy?.customer_id);
    }
    if (lead?.files?.length > 0) {
      setAllPrescriptionsDirect({
        title: "PATIENT DOCUMENT",
        files: lead?.files?.map((item: any, index: number) => {
          return {
            result: item?.url,
            title: `Prescription ${index + 1}`,
            subtext: moment(item?.created_at).format("DD MMM'YY"),
            badge: null,
            img: item.file?.content_type
              ? item.file.content_type.split("/")[0]
              : item.content_type
                ? item.content_type.split("/")[0]
                : "",
            id: item?.id,
            allData: item,
          };
        }),
      });
    }
    if (pharmacy?.files?.length > 0) {
      setAllPrescriptionsDirect({
        title: "PATIENT DOCUMENT",
        files: pharmacy?.files?.map((item: any, index: number) => {
          return {
            result: item?.url,
            title: `Prescription ${index + 1}`,
            subtext: moment(item?.created_at).format("DD MMM'YY"),
            badge: null,
            img: item.file?.content_type
              ? item.file.content_type.split("/")[0]
              : item.content_type
                ? item.content_type.split("/")[0]
                : "",
            id: item?.id,
            allData: item,
          };
        }),
      });
    }
    if (pharmacy?.lead_id && !lead) {
      dispatch(fetchLeadDetails(pharmacy?.lead_id)).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          const leadDetails = val?.payload?.data?.data;
          if (leadDetails?.files?.length > 0) {
            setAllPrescriptionsDirect({
              title: "PATIENT DOCUMENT",
              files: leadDetails?.files?.map((item: any, index: number) => {
                return {
                  result: item?.url,
                  title: `Prescription ${index + 1}`,
                  subtext: moment(item?.created_at).format("DD MMM'YY"),
                  badge: null,
                  img: item.file?.content_type
                    ? item.file.content_type.split("/")[0]
                    : item.content_type
                      ? item.content_type.split("/")[0]
                      : "",
                  id: item?.id,
                  allData: item,
                };
              }),
            });
          }
        }
      });
    }
  }
  useEffect(() => {
    if (orderId)
      dispatch(fetchMedicinesById(orderId)).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          const pharmacy = val?.payload?.data?.data?.pharmacy;
          processPharmacy(pharmacy);
          //  if(val?.payload?.data?.data?.S3Urls?.length > 0){
          //     //  sets3URLS(val?.payload?.data?.data?.S3Urls)
          //     dispatch(fetchPatientsDocuments({request_id : orderId})).then((val:any)=>{
          //         if(val?.payload?.data?.statusCode == 200){
          //             setAllPrescriptions({
          //                 title: 'PATIENT DOCUMENT',
          //                 files: val.payload.data.data.items.map((item:any)=>{
          //                     return  { title: item?.name, subtext: moment(item.date).format("DD MMM'YY"), img: (item.content_type).split("/")[0], id: item.id, allData: item }
          //                 })
          //             })
          //         }
          //     })
          //  }else{
          //      if(val?.payload?.data?.data?.pharmacy?.appointment_id){
          //          setAppointmentId(val?.payload?.data?.data?.pharmacy?.appointment_id)
          //      }
          //      if(val?.payload?.data?.data?.pharmacy?.customer_id){
          //          setPatientId(val?.payload?.data?.data?.pharmacy?.customer_id)
          //      }
          //  }
        }
      });
  }, [orderId]);
  useEffect(() => {
    if (pharmacyDetailsPre === true) {
      processPharmacy(pharmacyDetails);
    }
  }, [pharmacyDetailsPre]);

  useEffect(() => {
    if (appointmentId || patientId) {
      const data =
        appointmentId && patientId
          ? {
            patient_id: patientId,
            start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            type: "PRESCRIPTION",
            location: true
          }
          : appointmentId
            ? { appointment_id: appointmentId, location: true }
            : patientId
              ? { patient_id: patientId, location: true }
              : "";
      // patientId ? { patientId: patientId } : ''
      if (data !== "") {
        dispatch(fetchPrescriptions(data)).then((val: any) => {
          let allPres = {
            title: "PATIENT DOCUMENT",
            files: [],
          };
          if (val?.payload?.data?.statusCode == 200) {
            allPres.files = allPres?.files?.concat(
              val.payload.data.data.items
                //filter((item:any)=>{
                //    return  item.type == "PRESCRIPTION" || item.type == "MAX_E_PRESCRIPTION"
                //}).
                .map((item: any) => {
                  return {
                    title: item?.name,
                    subtext: moment(item.date).format("DD MMM'YY"),
                    img: item.content_type.split("/")[0],
                    id: item.data_id,
                    allData: item,
                    baseType: "doctor",
                  };
                })
            );
          }
          dispatch(fetchPatientsDocuments(data)).then((val: any) => {
            if (val?.payload?.data?.statusCode == 200) {
              allPres.files = allPres?.files?.concat(
                val.payload.data.data.items
                  .filter((item: any) => {
                    return item.type == "PRESCRIPTION";
                  })
                  .map((item: any) => {
                    return {
                      title: item?.name,
                      subtext: moment(item.date).format("DD MMM'YY"),
                      img: item.content_type.split("/")[0],
                      id: item.id,
                      allData: item,
                      baseType: "patient",
                    };
                  })
              );
            }
            if (allPres?.files?.length > 0) {
              setAllPrescriptions(allPres);
            }
          });
        });
      }
    }
  }, [appointmentId, patientId]);
  useEffect(() => {
    if (allPrescriptions?.files?.length > 0) {
      (patientPrescription || drPrescriptions) && setActiveFile(allPrescriptions.files[0]);
      (patientPrescription || drPrescriptions) && getFileUrl(allPrescriptions.files[0].id);
    }
  }, [allPrescriptions]);
  function getFileUrl(id: string, type?: any) {
    if (type === 'onchange') {
      setdrPrescriptions(id)
    }
    const data = allPrescriptions.files.filter((val: any) => {
      return val.id === id;
    })[0];
    setActiveFile(data);
    setfileUrl(null);
    if (patientId || appointmentId) {
      if (data?.baseType == "doctor") {
        dispatch(
          fetchUploadedFileById({ id: data?.id, type: data?.allData?.type })
        ).then((val: any) => {
          if (val?.payload?.data?.statusCode == 200) {
            setfileUrl(val?.payload?.data?.data?.result);
          }
        });
      } else {
        dispatch(fetchPatientDocumentFileById(data?.id)).then((val: any) => {
          if (val?.payload?.data?.statusCode == 200) {
            setfileUrl(val?.payload?.data?.data?.result);
          }
        });
      }
    } else {
      dispatch(fetchPatientsDocumentsByURL(data?.id)).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          setfileUrl(val?.payload?.data?.data?.result);
        }
      });
    }
  }

  const setFileUrlDirect = (e: any, type?: any) => {
    const data = allPrescriptionsDirect?.files?.filter((val: any) => {
      return val.id == e;
    });
    if (type === 'onchange') {
      setpatientPrescription(e)
    }
    // debugger
    if (data?.length == 1) {
      setfileUrl(data[0]?.result);
      setActiveFile(data[0]);
    }
  };
  console.log("patientPrescription", patientPrescription, drPrescriptions)
  useEffect(() => {
    if (allPrescriptionsDirect?.files?.length > 0) {
      // setActiveFile(allPrescriptionsDirect.files[0])
      (patientPrescription || drPrescriptions) && setFileUrlDirect(allPrescriptionsDirect.files[0].id);
    }
  }, [allPrescriptionsDirect]);
  const handleEditMedicine = (childData: any) => {
    setIsEditMedicine(childData);
  };
  const onchangePrescriptionType = (value: any) => {
    setprescriptionType(value)
    if (value === 'doctor') {
      setpatientPrescription(null)
      setdrPrescriptions(null)
    }
    else {
      setdrPrescriptions(null)
    }
  };
  useEffect(() => {
    const fileId = activeFile?.allData?.document_data_id ? activeFile?.allData?.document_data_id : activeFile?.allData?.data_id;
    if (fileId) {
      const found = pharmacyDetails.files.find((item: any, index: number) => item.file_upload_id === fileId);
      if (found) {
        setPrescriptionMapped(true);
      } else {
        setPrescriptionMapped(false);
      }
    }
  }, [activeFile]);
  const handleDeletePrescription = async (payload: any) => {
    await dispatch(fetchMedicinesById(pharmacyOrder.id));
    const deleteFileId = payload.file_id;
    const fileId = activeFile?.allData?.document_data_id ? activeFile?.allData?.document_data_id : activeFile?.allData?.data_id;
    if (deleteFileId === fileId) {
      setPrescriptionMapped(false);
    }
  };

  const handleMapPrescriptionToCart = async (event: any, file: any = {}) => {
    try {
      let prescription;
      if (Object.keys(file).length) {
        prescription = {
          file_upload_ids: [{
            id: file?.document_data_id,
            description: file.name,
            file_source: "patient",
            file_type: file.type,
            content_type: file.content_type,
            bucket: file.bucket,
            path: file.path,
            signed_url: file.url
          }],
          request_id: pharmacyOrder.id
        };
      } else {
        prescription = {
          file_upload_ids: [{
            id: activeFile?.baseType === "patient" ? activeFile?.allData?.document_data_id : activeFile?.allData?.data_id,
            description: `${activeFile.title} (${activeFile.subtext})`,
            file_source: activeFile?.baseType,
            file_type: activeFile?.allData?.type,
            content_type: activeFile?.allData?.content_type,
            bucket: activeFile?.baseType === "patient" ? activeFile?.allData?.bucket : activeFile?.allData?.bucket_name,
            path: activeFile?.allData.path,
            signed_url: activeFile?.allData?.url ? activeFile?.allData?.url : ""
          }],
          request_id: pharmacyOrder.id
        };
      }
      setLoadingPharmacy(true);
      const response = await dispatch(mapPrescriptionToCart(prescription));
      setLoadingPharmacy(false);
      if (response?.payload?.data?.error === false) {
        if (!Object.keys(file).length) {
          setPrescriptionMapped(true);
        }
        await dispatch(fetchMedicinesById(pharmacyOrder.id));
        setLoadingPharmacy(false);
        notification.success({
          message: "Prescription Mapped To The Cart",
          placement: "bottom",
          icon: <></>,
          className: "medicine-replaced",
          closeIcon: <></>
        });
      } else {
        notification.error({ message: "Something went wrong" });
      }
    } catch (e) {
      notification.error({ message: "Something went wrong" });
    }
  };
  console.log("allPrescriptions", allPrescriptions, allPrescriptionsDirect);
  console.log("rrrrrrrrrrrrrrr", pharmacyDetails?.status?.toLowerCase())
  return (
    <Fragment>
      {(!allPrescriptions || allPrescriptions?.files?.length == 0) &&
        (!allPrescriptionsDirect ||
          allPrescriptionsDirect?.files?.length == 0) &&
        pharmacyDetailsPre == true && (
          <Empty description={<span>No past prescriptions</span>} />
        )}
      <div className="">
        {pharmacyDetails?.customer_id == contact?.patient_id &&
          pharmacyDetails?.status && (
            <Row className="bordered-grey-color">
              {pharmacyDetails?.status?.toLowerCase() === "qc_done" &&
                !isEditMedicine?.action &&
                pharmacyDetailsPre === false ? (
                <>
                  <Col className="pd20 grey-background" span={14}>
                    <SelectedMedicine handleEditMedicine={handleEditMedicine} />
                  </Col>
                  <Col span={10}>
                    <PlaceOrderComponent
                      leadId={lead?.id}
                      ticketId={ticket?.id}
                    />
                  </Col>
                </>
              ) : (
                <>
                  {(allPrescriptionsDirect?.files?.length > 0 ||
                    allPrescriptions?.files?.length > 0) && (
                      <Col
                        className="pd20 grey-background"
                        md={pharmacyDetailsPre ? 24 : 12}
                        xs={24}
                      >
                        <Row>
                          <Col
                            span={24}
                            style={{
                              maxHeight: "calc(100vh - 100px)",
                              overflow: "auto"
                            }}
                          >

                            <Row>
                              <Col span={24}>
                                Choose Prescription Type
                                <Select
                                  value={prescriptionType}
                                  onChange={onchangePrescriptionType}
                                  style={{ width: "100%" }}
                                  options={prescriptionTypes}

                                />
                              </Col>
                            </Row>
                            {
                              prescriptionType &&
                              <Row>
                                <Col span={24}>
                                  {
                                    prescriptionType === "patient" ?
                                      <>
                                        {allPrescriptionsDirect?.files?.length > 0 &&
                                          <Row>
                                            <Col span={12}>
                                              <span>Uploaded by Patient</span>
                                              <Select
                                                value={patientPrescription}
                                                defaultValue={null}
                                                onChange={(e) => {
                                                  setFileUrlDirect(e, "onchange");
                                                }}
                                                style={{ width: "100%" }}
                                              >
                                                {allPrescriptionsDirect?.files?.map(
                                                  (val: any) => {
                                                    return (
                                                      <Option value={val.id}>
                                                        {val.title +
                                                          " (" +
                                                          val.subtext +
                                                          ")"}
                                                      </Option>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={11}>
                                              <span>&nbsp;</span>
                                              <div style={{ width: "100%" }}>
                                                <Button onClick={handleMapPrescriptionToCart}
                                                  type={(loadingPharmacy || prescriptionMapped || (activeFile === undefined)) ? "default" : "link"}
                                                  disabled={loadingPharmacy || prescriptionMapped || (activeFile === undefined)}>
                                                  Map Prescription To Cart
                                                </Button>
                                              </div>
                                            </Col>
                                          </Row>
                                        }
                                      </> : <>
                                        {allPrescriptions?.files?.length > 0 &&
                                          (
                                            <Row>
                                              <Col span={activeFile ? 12 : 24}>
                                                <span>Uploaded by Doctor</span>
                                                <Select
                                                  value={drPrescriptions}
                                                  onChange={(e) => {
                                                    getFileUrl(e, "onchange");
                                                  }}
                                                  style={{ width: "100%" }}
                                                >
                                                  {allPrescriptions?.files?.map(
                                                    (val: any) => {
                                                      return (
                                                        <Option value={val.id}>
                                                          {val.title +
                                                            " (" +
                                                            val.subtext +
                                                            ")"}
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                              </Col>
                                              {
                                                activeFile && <>
                                                  <Col span={1}></Col>
                                                  <Col span={11}>
                                                    <span>&nbsp;</span>
                                                    <div style={{ width: "100%" }}>
                                                      <Button type={(loadingPharmacy || prescriptionMapped || (activeFile === undefined)) ? "default" : "link"}
                                                        onClick={handleMapPrescriptionToCart}
                                                        disabled={loadingPharmacy || prescriptionMapped || (activeFile === undefined)}>
                                                        Map Prescription To Cart
                                                      </Button>
                                                    </div>
                                                  </Col>
                                                </>
                                              }
                                            </Row>

                                          )}
                                      </>
                                  }
                                </Col>
                                <Col span={24} style={{ marginTop: 15 }}>
                                  {activeFile && fileUrl && (
                                    <FileViewLayout
                                      modal={false}
                                      details={activeFile}
                                      fileviewer={true}
                                      total={0}
                                      file={{ result: fileUrl }}
                                    ></FileViewLayout>
                                  )}
                                </Col>
                              </Row>
                            }
                          </Col>
                        </Row>
                      </Col>
                    )}

                  {pharmacyDetailsPre === false && (
                    <Col
                      md={
                        allPrescriptionsDirect?.files?.length > 0 ||
                          allPrescriptions?.files?.length > 0
                          ? 12
                          : 24
                      }
                      xs={24}
                    >
                      <MedicineOrderComponent
                        request_id={pharmacyDetails.id}
                        makeMedicineComponentEditable={isEditMedicine?.action}
                        handleEditMedicine={handleEditMedicine}
                        handleDeletePrescription={handleDeletePrescription}
                        handleMapPrescriptionToCart={handleMapPrescriptionToCart}
                      />
                    </Col>
                  )}
                </>
              )}
            </Row>
          )}
      </div>
    </Fragment>
  );
}

export default OrderMedicineCompoment;
