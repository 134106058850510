import { AutoComplete } from "antd";
import { useEffect, useState } from "react";

// options, name, placeholder, class, key, value
export function AsyncTypeAhead(props) {
  const [text, setText] = useState(props.value || "");
  const [timeout, setTO] = useState(null);
  const [visibleOptions, setVisibleOptions] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    changeVisibleOptions();
  }, [props.options]);

  useEffect(() => {
    setText(props.value);
  }, [props.value]);

  function changeVisibleOptions() {
    let obj = props.options;
    if (!obj || (Array.isArray(obj) && obj.length === 0)) {
      setVisibleOptions([]);
      setLoading(false);
      return;
    }
    if (Array.isArray(obj) && obj.length) {
      // options = [{a: , b: , c:}, {a: , b: , c:}...]
      if (typeof obj[0] === "object") {
        let keyProp = props.optionKey || "key";
        let valueProp = props.optionValue || "value";
        if (obj[0][keyProp] === undefined) {
          throw "Type Ahead Requires a valid 'optionKey' prop for options with objects or provide a 'key' in your objects";
        }
        if (obj[0][valueProp] === undefined) {
          throw "Type Ahead Requires a valid 'optionValue' prop for options with objects or provide a 'value' in your objects";
        }
        let opts = obj.map((o) => ({ key: o[keyProp], value: o[valueProp] }));
        setVisibleOptions(opts);
        setLoading(false);
        return;
      }
      // options = [a, b, c]
      if (typeof obj[0] === "string") {
        let opts = obj.map((o) => ({ key: o, value: o }));
        setVisibleOptions(opts);
        setLoading(false);
        return;
      }
    }
  }

  function valueClicked(option) {
    setText(option);
    props.emitTypedText({
      target: { name: props.name, value: option, async: false },
    });
  }

  function valueSetByEnter(option, e) {
    if (!e || !e.keyCode || e.keyCode !== 13) return;
    e.currentTarget.click(e);
  }

  function onTextChangeValue(event) {
    let value = event;
    //let { name, value } = event.target;
    setText(value);
    if (value === "") {
      props.emitTypedText({
        target: { name: props.name, value, async: false },
      });
      setLoading(false);
      setVisibleOptions([]);
      return;
    }
    setLoading(true);
    if (timeout) {
      clearTimeout(timeout);
    }
    if (value && value.length <= 4) {
      setTO(
        setTimeout(() => {
          props.emitTypedText({
            target: { name: props.name, value, async: true },
          });
        }, 2000)
      );
    } else {
      props.emitTypedText({ target: { name: props.name, value, async: true } });
    }
  }

  return (
    <>
      <AutoComplete
        value={text}
        // options={options}
        allowClear={true}
        dataSource={visibleOptions}
        style={{ width: "90%" }}
        onSelect={valueClicked.bind(this)}
        onSearch={onTextChangeValue.bind(this)}
        onChange={valueClicked.bind(this)}
        placeholder={props.placeholder || "Type to see options"}
        className=""
      />
      {/*}            
                <div className={`${props.class || 'col-3'} dropdown`} data-toggle="dropdown">
                    <input value={text} autoComplete="off" name={props.name} placeholder={props.placeholder || 'Type for options'} className="form-control" style={{ paddingRight: '30px' }}
                        onChange={onTextChangeValue.bind(this)} onFocus={props.onFocus ? props.onFocus.bind(this) : () => { }} />
                    {loading && <div style={{ position: 'absolute', right: '5px', top: '5px' }}><Spinner width={20} height={20}></Spinner></div>}
                </div>
                    <div className={`p-0 ${props.class} dropdown-menu type-ahead`}>
                        {visibleOptions && visibleOptions.length < 1 && loading && <small className="dropdown-item border-bottom pt-2 pb-2 text-muted" >Loading...</small>}
                        {visibleOptions && visibleOptions.length < 1 && !loading && !text && <small className="dropdown-item border-bottom pt-2 pb-2 text-muted" >Type to see options...</small>}
                        {visibleOptions && visibleOptions.length < 1 && !loading && text && <span className="dropdown-item border-bottom pt-2 pb-2" >Nothing found!</span>}
                        {visibleOptions && visibleOptions.map((opt, idx) => <a key={idx} tabIndex="0" onClick={valueClicked.bind(this, opt)} onKeyDown={valueSetByEnter.bind(this, opt)}
                            className="dropdown-item border-bottom pt-2 pb-2" style={{textTransform:'uppercase'}}>{opt.value}</a>)}
                    </div>
                {*/}
    </>
  );
}

export default AsyncTypeAhead;
