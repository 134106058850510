import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GET_All_CHAT_ROOMS,
  GET_CHAT_MESSAGES,
  GET_CHAT_ROOMS,
  GET_MULTIPLE_CHAT_ROOMS,
  MANAGE_CHAT_ROOM,
  MARK_READ_CHAT,
} from "../../shared/routes/route.constants";
import { USER_CHAT_SETTING } from "../../shared/routes/user.routes.constants";
import { Client } from "../../shared/Utils/api-client";

const axios = require("axios").default;

const config = {
  name: "chat",
};
export const fetchMessages: any = createAsyncThunk(
  `${config.name}/fetchMessages`,
  async (data: any) => {
    return await axios({
      method: "get",
      url: GET_CHAT_MESSAGES(
        data.user_type,
        data.user_id,
        data.room_type,
        data.chat_room_id
      ),
      params: data.parms,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
);

export const fetchChatRooms: any = createAsyncThunk(
  `${config.name}/fetchChatRooms`,
  async (data: any) => {
    return await axios({
      method: "get",
      url: GET_CHAT_ROOMS(data.user_type, data.user_id, data.room_type),
      params: data.paramss,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
);

export const getActiveChatRooms: any = createAsyncThunk(
  `${config.name}/getActiveChatRooms`,
  async (data: any) => {
    return await axios({
      method: "get",
      url: GET_All_CHAT_ROOMS(
        data.user_type,
        data.status,
        data.time_diff,
        data?.date
      ),
      params: data.paramss,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
);
export const getInactiveChatRooms: any = createAsyncThunk(
  `${config.name}/getInactiveChatRooms`,
  async (data: any) => {
    return await axios({
      method: "get",
      url: GET_All_CHAT_ROOMS(
        data.user_type,
        data.status,
        data.time_diff,
        data?.date
      ),
      params: data.paramss,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
);

export const fetchMultipleRooms: any = createAsyncThunk(
  `${config.name}/fetchMultipleRooms`,
  async (data: any) => {
    return await axios({
      method: "post",
      url: GET_MULTIPLE_CHAT_ROOMS,
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
);

export const manage_chat_room: any = createAsyncThunk(
  `${config.name}/manage_chat_room`,
  async (data: any) => {
    console.log("222222222222", data);
    return await axios.post(MANAGE_CHAT_ROOM, data?.datas, {
      params: data.params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
);

export const chatSetting: any = createAsyncThunk(
  `${config.name}/chatSetting`,
  async (params: any) => {
    return await Client.get(USER_CHAT_SETTING, { params });
  }
);

export const getConnection: any = createAsyncThunk(
  `${config.name}/getConnection`,
  async (connection: any) => {
    return connection;
  }
);

export const markReadMessagePatient: any = createAsyncThunk(
  `${config.name}/markReadMessagePatient`,
  async (data: any) => {
    return await axios({
      method: "put",
      url: MARK_READ_CHAT(data.id),
      params: data.newParams,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
);

export const chatSlice = createSlice({
  name: config.name,
  initialState: {
    room_loading: false,
    loading: false,
    loadingActive: false,
    loadinginActive: false,
    messages: [] as Array<any>,
    error: "" as string,
    rooms: [] as any,
    activerooms: [] as any,
    inactiverooms: [] as any,
    ischatAllow: true as any,
    connection: null as any,
  },
  reducers: {
    reset: (state) => {
      state.messages = [];
      state.loadingActive = false;
      state.loadinginActive = false;
      state.room_loading = false;
      state.loading = false;
      state.error = "";
      state.rooms = [];
      state.activerooms = [];
      state.inactiverooms = [];
    },
  },
  extraReducers(builder) {
    builder
      //  Fetch messages
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action?.payload?.data?.result;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //  Fetch Rooms
      .addCase(fetchChatRooms.pending, (state) => {
        state.room_loading = true;
        state.error = "";
      })
      .addCase(fetchChatRooms.fulfilled, (state, action) => {
        state.room_loading = false;
        state.rooms = action?.payload?.data?.result;
      })
      .addCase(fetchChatRooms.rejected, (state, action) => {
        state.room_loading = false;
        state.error = action.error.message;
      })

      //  Fetch active inactive Rooms
      .addCase(getActiveChatRooms.pending, (state) => {
        state.loadingActive = true;
        state.error = "";
      })
      .addCase(getActiveChatRooms.fulfilled, (state, action) => {
        state.loadingActive = false;
        state.activerooms = action?.payload?.data?.result;
      })
      .addCase(getActiveChatRooms.rejected, (state, action) => {
        state.loadingActive = false;
        state.error = action.error.message;
      })

      //  Fetch inactive inactive Rooms
      .addCase(getInactiveChatRooms.pending, (state) => {
        state.loadinginActive = true;
        state.error = "";
      })
      .addCase(getInactiveChatRooms.fulfilled, (state, action) => {
        state.loadinginActive = false;
        state.inactiverooms = action?.payload?.data?.result;
      })
      .addCase(getInactiveChatRooms.rejected, (state, action) => {
        state.loadinginActive = false;
        state.error = action.error.message;
      })

      // fetchMultipleRooms
      .addCase(fetchMultipleRooms.pending, (state) => {
        state.room_loading = true;
        state.error = "";
      })
      .addCase(fetchMultipleRooms.fulfilled, (state, action) => {
        state.room_loading = false;
        state.rooms = action?.payload?.data?.result;
      })
      .addCase(fetchMultipleRooms.rejected, (state, action) => {
        state.room_loading = false;
        state.error = action.error.message;
      })

      // chatSetting
      .addCase(chatSetting.pending, (state) => {
        state.room_loading = true;
        state.error = "";
      })
      .addCase(chatSetting.fulfilled, (state, action) => {
        state.room_loading = false;
        state.ischatAllow = action?.payload?.data?.data?.enable_chat;
      })
      .addCase(chatSetting.rejected, (state, action) => {
        state.room_loading = false;
        state.error = action.error.message;
      })

      .addCase(getConnection.pending, (state) => {
        state.room_loading = true;
        state.error = "";
      })
      .addCase(getConnection.fulfilled, (state, action) => {
        state.room_loading = false;
        state.connection = action?.payload;
      })
      .addCase(getConnection.rejected, (state, action) => {
        state.room_loading = false;
        state.error = action.error.message;
      });
  },
});

export const { reset } = chatSlice.actions;

export default chatSlice.reducer;
