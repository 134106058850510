import { CaretDownOutlined, DeleteFilled } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Drawer,
  Form, Image, Input, Modal, Radio, RadioChangeEvent, Row,
  Select,
  Tag, Tooltip,
  Typography, message, notification
} from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchPatientsDocuments } from "../../redux/reducers/patients.slice";
import {
  addNewAddress,
  addNewMedicine,
  deleteMapPrescriptionToCart,
  fetchCity,
  fetchMedicineItems,
  fetchMedicinesById,
  fetchStationfromAddress,
  proceedQCRequest,
  updatePharmacyDetail
} from "../../redux/reducers/pharmacy.slice";
import { RootState } from "../../shared/constants";
import FileViewLayout from "./file-view.layout";
import GoogleMapSearchBox from "./google-map-searchbox.layout";
import MapComponent from "./map.layout";
import MedicineComponent from "./pharmacy-medicine.layout";
import UploadPrescription from "./uploadprescription";

type Props = {
  request_id?: string;
  makeMedicineComponentEditable?: any;
  requestId?: string;
  handleEditMedicine?: any;
  handleDeletePrescription: any;
  handleMapPrescriptionToCart: any;
};

function MedicineOrderComponent(props: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { medicines, pharmacyDetails, medicine } = useSelector(
    (state: RootState) => state.pharmacy
  );

  const [isOpenAddNewAddress, setIsOpenAddNewAddress] = useState(false);
  const [medicineTtlPrice, setMedicineTotalPrice] = useState(0);
  const [medicineDiscountPrice, setMedicineDiscountPrice] = useState(0);
  const [showMedicineSearch, setShowMedicineSearch] = useState(false);
  // const [showServicesSearch, setShowServicesSearch] = useState(false);
  const [value, setValue] = useState("");
  const [medicineTotalCount, setMedicineTotalCount] = useState(0);
  // const [options, setOptions] = useState<{ value: string }[]>([]);
  const [cancelProceedModalVisible, setProceedRequestModalVisible] =
    useState(false);
  const [proceedModalAction, setProceedModalAction] = useState("");
  const [proceedModalText, setProceedModalText] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState(false as any);
  const [formData, setFormData] = useState(null as any);
  // const [ patientId, setPatientId ] = useState(null as any);
  const [cityId, setCityId] = useState(null as any);
  const [medicineArr, setMedicineArr] = useState([] as any);
  const [autoCompleteCityArr, setAutoCompleteCityArr] = useState<
    { value: string }[]
  >([]);
  const [newlyAddress, setNewlyAddress] = useState(null as any);
  const [isHideNewlyAddedAddressInput, setIsHideNewlyAddedAddressInput] =
    useState(false);
  const [medArr, setMedArr] = useState([] as any);
  const [addressFromMap, setAddressFromMap] = useState(null as any);
  const [cityIdFromMap, setCityIdFromMap] = useState(null as any);
  const [formattedAddrFromMap, setFormattedAddrFromMap] = useState(null as any);
  const [stationCount, setStationCount] = useState(null as any);
  const [pincodeFromMap, setPincodeFromMap] = useState(null as any);
  const [stateFromMap, setStateFromMap] = useState(null as any);
  const [cityNameFromMap, setCityNameFromMap] = useState(null as any);
  const [localityFromMap, setLocalityFromMap] = useState(null as any);
  const [fetchingStations, setFetchingStation] = useState("hide" as string);
  const [addressTypeValues, setAddressTypeValues] = useState("home");
  const [medicineTotalQuantity, setMedicineTotalQuantity] = useState([] as any);
  const [allAddedMedicines, setAllAddedMedicines] = useState([] as any);
  const [isProceedToNext, setIsProceedToNext] = useState(false);
  const [isMapAddressEmptyValue, setIsMapAddressEmptyValue] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePrescription, setVisiblePrescription] = useState(null as any);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = useForm();
  const { Text } = Typography;
  const { Option } = Select;
  const RouteParams: any = useParams();
  let request_id: string = "";

  if (props.request_id === "") {
    request_id = RouteParams["id"];
  } else {
    if (props.request_id) {
      request_id = props.request_id;
    }
  }
  const address = pharmacyDetails?.patient?.addresses;
  if (!props.requestId) {
    request_id = pharmacyDetails?.id;
  }

  const showAddMedicine = (e: any) => {
    e.preventDefault();
    setShowMedicineSearch(true);
  };

  // const showAddServices = () => {
  //   setShowServicesSearch(true);
  // }
  const medicineRXRequired = medicineArr?.filter((val: any) => {
    return val?.prescription_required === true;
  });
  const onCitySearch = async (searchText: string) => {
    if (searchText.length > 2) {

      let param = {}
      if (window.location.hostname.includes("beta") || window.location.hostname.includes("localhost")) {
        param = {
          search_keyword: searchText.replace(/gurugram/gi, "Gurgaon"),
        };
      }
      else {
        param = {
          search_keyword: searchText.replace(/gurgaon/gi, "Gurugram"),
        };
      }
      let cityList = await dispatch(fetchCity(param));
      // debugger
      setAutoCompleteCityArr(cityList?.payload?.data?.data);
    } else {
      setAutoCompleteCityArr([]);
    }
  };

  const onCitySelect = async (data: string) => {
    let selectedItem = autoCompleteCityArr.filter((item: any) => {
      return item.city === data;
    });
    let item = selectedItem[0];
    setCityId(item);
    setAutoCompleteCityArr([]);
  };

  const onSearch = (searchText: string) => {
    if (searchText.length > 3) {
      let param = {
        search_term: searchText,
        search_column: "item_name",
      };
      dispatch(fetchMedicineItems(param));
      setMedArr(medicines);
      // setOptions(medicines)
    } else {
      // setOptions([])
      setMedArr([]);
    }
  };

  useEffect(() => {
    console.log("use effect still running......");
    const allSelected = medicine.map((val: any) => {
      return val?.item_id;
    });
    setMedArr(
      medicines?.filter((val: any) => {
        return allSelected.indexOf(val?.id) === -1;
      })
    );
  }, [medicines]);

  const onSelect = async (data: string) => {
    let selectedItem = medArr.filter((item: any) => {
      return item.item_name === data;
    });
    selectedItem = selectedItem[0];
    let finalPayload = {
      item_id: selectedItem.id,
      item_code: selectedItem.item_id,
      item_name: selectedItem.item_name,
      item_quantity: 1,
      station_id: pharmacyDetails.station_id,
      hospital_id: pharmacyDetails.hospital_id,
      item_price: selectedItem.item_price,
      request_id: request_id,
      prescription_required: false,
      is_otc: selectedItem?.is_prescription === 0 ? true : false,
      request_date: moment().format("YYYY-MM-DD HH:MM:ss"),
    };
    try {
      await dispatch(addNewMedicine(finalPayload));
      await dispatch(fetchMedicinesById(request_id));
      setMedArr([]);
      setValue("");
      return;
    } catch (e) { }
  };

  const onChange = (data: string) => {
    setValue(data);
  };

  const medicinePrice = (childData: any) => {
    setMedicineTotalPrice(childData.totalPrice);
    setMedicineTotalCount(childData.medicineCount);
  };

  const AutoOptions = medArr?.map((items: any) => (
    <Option key={items.id} value={items.item_name}>
      {items.item_name}
    </Option>
  ));

  const cityAutoOptions = autoCompleteCityArr?.map((items: any) => (
    <Option key={items.id} value={items.city}>
      {items.city}
    </Option>
  ));

  const onFinish = async (values: any) => {
    values = {
      ...values,
      city_id: cityId?.id,
      latitude: 0,
      longitude: 0,
      is_primary: values.address_type === "home" ? true : false,
      patient_id: pharmacyDetails?.patient?.id,
    };
    if (!cityId?.id) {
      message.error("City is invalid, Please select city from dropdown", 4);
    } else {
      const address = await dispatch(addNewAddress(values));
      console.log("address", address);
      await dispatch(fetchMedicinesById(request_id));
      if (address?.payload?.data?.statusCode === 200) {
        setDeliveryAddress(address.payload.data.data.id);
        let param = {
          address:
            values?.address_line_1 +
            "," +
            values?.address_line_2 +
            "," +
            values?.locality +
            "," +
            values?.city +
            "," +
            values?.state +
            "," +
            values?.pincode,
        };
        await dispatch(fetchStationfromAddress(param)).then((res: any) => {
          let addrRes = res?.payload?.data?.data;
          if (addrRes?.id) {
            let params = {
              id: request_id,
              delivery_address_id: "",
              address: param.address,
            };
            dispatch(updatePharmacyDetail({ id: request_id, data: params }));
          }
        });
      }
      onCloseAddressModal();
    }
  };

  const onFinishFailed = (errorInfo: any) => { };

  const onCloseAddressModal = (address = null) => {
    emtypAddressForm();
    setIsOpenAddNewAddress(false);
    setIsMapAddressEmptyValue(true);
    if (!address) {
      setDeliveryAddress(address);
    }
  };

  useEffect(() => {
    setFormData({
      heading: "",
      onFinish: onFinish,
      onFinishFailed: onFinishFailed,
      initialValues: {
        address1: "",
        address2: "",
        locality: "",
        city: "",
        state: "",
        pincode: "",
        city_id: "",
        latitude: 0,
        longitude: 0,
        is_primary: true,
        patient_id: "",
      },
      buttonText: "Add",
      formElements: [
        {
          label: "Address Type",
          type: "Radio",
          name: "address_type",
          defaultValue: "home",
          options: [
            { value: "home", name: "Home" },
            { value: "work", name: "Work" },
            { value: "other", name: "Other" },
          ],
        },
        {
          label: "Address 1",
          type: "Input",
          name: "address_line_1",
          rules: [{ required: true, message: "Address1 is mandatory" }],
          colSpan: "12",
        },
        {
          label: "Address 2",
          type: "Input",
          name: "address_line_2",
          rules: [{ required: false, message: "Address2 is mandatory" }],
          colSpan: "12",
        },
        {
          label: "Locality/Colony",
          type: "Input",
          name: "locality",
          rules: [{ required: true, message: "Locality is mandatory" }],
          colSpan: "12",
        },
        {
          label: "City",
          type: "AutoComplete",
          name: "city",
          rules: [{ required: true, message: "City is mandatory" }],
          colSpan: "12",
          placeholder: "Enter City Name",
          onSelect: onCitySelect,
          onSearch: onCitySearch,
          AutoCompleteData: autoCompleteCityArr?.map((items: any) => (
            <Option key={items.id} value={items.city}>
              {items.city}
            </Option>
          )),
        },
        {
          label: "State",
          type: "Input",
          name: "state",
          rules: [{ required: true, message: "State is mandatory" }],
          colSpan: "12",
        },
        {
          label: "Pincode",
          type: "Input",
          name: "pincode",
          rules: [{ required: true, message: "Pincode is mandatory" }],
          colSpan: "12",
        },
      ],
    });
  }, [autoCompleteCityArr]);

  // console.log('eeeee 444555', stateFromMap, localityFromMap);

  const closeCancelOrder = () => {
    setProceedRequestModalVisible(false);
  };

  const handleProceedModal = (action: string) => {
    setProceedModalAction(action);
    if (action === "next") {
      // setProceedModalText('Are you sure, you want to procced this request?')
      handleOrder("next");
    } else {
      setProceedRequestModalVisible(true);
      setProceedModalText("Do you want to cancel this request?");
    }
  };

  const checkMedicineValidation = (items: any) => {
    for (let i = 0; i < items.length; i++) {
      if (!items[i].station_id || !(items[i]?.total_remain_quantity > 0)) {
        return {
          action: false,
          validationFor: "pharmacy",
          message: "Please Select all Pharmacies",
        };
      }
      if (
        (items[i].prescription_required === true ||
          items[i].prescription_required === null) &&
        !items[i].rx_info
      ) {
        return {
          action: false,
          validationFor: "rx_value",
          message: "Please Select all Rx values",
        };
      }
    }
  };

  const handleOrder = async (type = "cancel" as any) => {
    try {
      let valCheck: any = checkMedicineValidation(medicineArr);
      if (proceedModalAction === "next" || type === "next") {
        if (medicine.length === 0) {
          message.error("Please add some medicine", 4);
        } else if (!deliveryAddress) {
          message.error("Please add delivery address", 4);
        } else if (valCheck && valCheck.validationFor === "rx_value") {
          message.error(valCheck.message, 4);
        } else if (valCheck && valCheck.validationFor === "pharmacy") {
          message.error(valCheck.message, 4);
        } else {
          let isQuantityMore = false;
          let exceededMedicineName = "";
          // console.log('eeeee 408 &&&&&&&&&&&&&&&&&', allAddedMedicines, medicineTotalQuantity);
          allAddedMedicines.forEach((medicines: any) => {
            if (
              medicines?.remarks &&
              medicines?.item_quantity > parseInt(medicines?.remarks)
            ) {
              // console.log('eeeee MEDICINE IS MORE', medicines?.item_name);
              exceededMedicineName = medicines?.item_name;
              isQuantityMore = true;
              return;
            }
            medicineTotalQuantity.forEach((quantity: any) => {
              // console.log('eeeee 417 &&&&&&&&&&&&&&&&&', medicines, quantity);
              if (
                medicines?.item_code == quantity?.item_id &&
                medicines?.item_quantity > quantity?.item_quantity
              ) {
                // console.log('eeeee MEDICINE IS MORE', medicines?.item_name);
                exceededMedicineName = medicines?.item_name;
                isQuantityMore = true;
              }
            });
          });
          // console.log('eeeee 419', isQuantityMore);
          if (isQuantityMore) {
            message.error(`${exceededMedicineName} quantity is more`, 4);
          } else {
            setIsProceedToNext(true);
            let param = {
              id: request_id,
              status: "qc_done",
              delivery_address_id: deliveryAddress,
            };
            let param2 = {
              total_amount: Math.ceil(medicineTtlPrice + medicineDiscountPrice),
            };
            await dispatch(
              updatePharmacyDetail({ id: request_id, data: param2 })
            );
            await dispatch(proceedQCRequest(param));
            await dispatch(fetchMedicinesById(request_id));
            let payload = {
              pharmacyDetails,
              action: "",
            };
            props?.handleEditMedicine(payload);
          }
        }
      } else {
        let param = {
          id: request_id,
          status: "cancelled",
          delivery_address_id: deliveryAddress || "",
        };
        dispatch(proceedQCRequest(param));
        notification.success({ message: "Order cancelled successfully." });
        setTimeout(() => {
          history.goBack();
        }, 2000);
      }
      setProceedRequestModalVisible(false);
    } catch (e) {
      setIsProceedToNext(false);
    }
  };

  const handleChooseAddress = (e: any) => {
    let selectedAddress = e ? e : null;
    setDeliveryAddress(selectedAddress);
    if (selectedAddress === "newAddress") {
      setIsOpenAddNewAddress(true);
      setIsMapAddressEmptyValue(false);
      // setPatientId(pharmacyDetails?.patient?.id);
    } else {
      let selectedItem = address?.filter((item: any) => {
        return item.id === selectedAddress;
      });
      setFetchingStation("show");
      if (selectedItem?.length > 0) {
        selectedItem = selectedItem[0];
        console.log("selectedItem", selectedAddress);
        setDeliveryAddress(selectedAddress);
        let param = {
          address:
            selectedItem.address_line_1 +
            "," +
            selectedItem.address_line_2 +
            "," +
            selectedItem.locality +
            "," +
            selectedItem.city +
            "," +
            selectedItem.state +
            "," +
            selectedItem.pincode,
          delivery_address_id: selectedItem?.id,
        };

        dispatch(fetchStationfromAddress(param)).then((res: any) => {
          let addrRes = res?.payload?.data?.data?.stations_with_distance;
          if (addrRes && addrRes?.length > 0) {
            setStationCount(addrRes.length);
            let params = {
              id: request_id,
              delivery_address_id: selectedAddress,
              address: param.address,
            };
            dispatch(updatePharmacyDetail({ id: request_id, data: params }));
            setFetchingStation("hide");
          } else {
            setStationCount(0);
            setFetchingStation("hide");
          }
        });
      }
    }
  };

  const medicineArrCallBack = (childData: any) => {
    setMedicineArr(childData);
  };

  const getMedicineTotalQuantity = (childData: any) => {
    // console.log('eeeeee 333333', childData)
    childData.filter((tag: any, index: any, array: any) =>
      array.findIndex((t: any) => t.item_id == tag.item_id)
    );
    // console.log('eeeeee 44444', childData)
    setMedicineTotalQuantity(childData);
  };

  const addedMedicines = (childData: any) => {
    // console.log('eeeeee setAllAddedMedicines 521', childData)
    setAllAddedMedicines(childData);
  };

  const hideNewlyAddedAddressInput = () => {
    setIsHideNewlyAddedAddressInput(false);
  };

  useEffect(() => {
    if (pharmacyDetails?.delivery_address_id && deliveryAddress === false) {
      handleChooseAddress(pharmacyDetails?.delivery_address_id);
    }
  }, [pharmacyDetails]);

  const onAreaSelect = (data: any) => {
    // console.log('eeeee 2222', data?.formatted_address, data?.location?.lat(), data?.location?.lng(), data?.address_components)
    let coordinates = {
      lat: data?.location?.lat(),
      lng: data?.location?.lng(),
    };
    setAddressFromMap(coordinates);
    setFormattedAddrFromMap(data?.formatted_address);

    data?.address_components.forEach(async (item: any) => {
      if (item.types.includes("locality")) {
        fetchCityId(item.long_name, data?.address_components);
      }
      if (item.types.includes("postal_code")) {
        setPincodeFromMap(item.long_name);
        form.setFieldsValue({
          pincode: item.long_name,
        });
      }
      if (item.types.includes("administrative_area_level_1")) {
        setStateFromMap(item.long_name);
        form.setFieldsValue({
          state: item.long_name,
        });
      }
      if (item.types.includes("sublocality_level_1")) {
        setLocalityFromMap(item.long_name);
        form.setFieldsValue({
          locality: item.long_name,
        });
      } else {
        if (item.types.includes("sublocality_level_2")) {
          setLocalityFromMap(item.long_name);
          form.setFieldsValue({
            locality: item.long_name,
          });
        } else {
          if (item.types.includes("sublocality_level_3")) {
            setLocalityFromMap(item.long_name);
            form.setFieldsValue({
              locality: item.long_name,
            });
          }
        }
      }
    });
  };

  const fetchCityId = async (data: any, other_address: any) => {


    // let param = {
    //   search_keyword: data.replace(/gurgaon/gi, "Gurugram"),
    // };
    let param = {}
    if (window.location.hostname.includes("beta") || window.location.hostname.includes("localhost")) {
      param = {
        search_keyword: data.replace(/gurugram/gi, "Gurgaon"),
      };
    }
    else {
      param = {
        search_keyword: data.replace(/gurgaon/gi, "Gurugram"),
      };
    }
    // debugger
    let cityList = await dispatch(fetchCity(param));
    if (cityList?.payload?.data?.data.length > 0) {
      setCityIdFromMap(cityList?.payload?.data?.data[0].id);
      setCityNameFromMap(cityList?.payload?.data?.data[0].city);
      form.setFieldsValue({
        city: cityList?.payload?.data?.data[0].city,
      });
    } else {
      findCityIdFromAnotherkey(other_address);
    }
  };

  const findCityIdFromAnotherkey = async (data: any) => {
    data?.forEach(async (item: any) => {
      if (item.types.includes("administrative_area_level_2")) {
        let param = {}
        if (window.location.hostname.includes("beta") || window.location.hostname.includes("localhost")) {
          param = {
            search_keyword: item.long_name.replace(/gurugram/gi, "Gurgaon"),
          };
        }
        else {
          param = {
            search_keyword: item.long_name.replace(/gurgaon/gi, "Gurugram"),
          };
        }
        // debugger
        let cityList = await dispatch(fetchCity(param));
        if (cityList?.payload?.data?.data.length > 0) {
          setCityIdFromMap(cityList?.payload?.data?.data[0].id);
          setCityNameFromMap(cityList?.payload?.data?.data[0].city);
          form.setFieldsValue({
            city: cityList?.payload?.data?.data[0].city,
          });
        } else {
          notification.error({ message: "No city id found for this address." });
          form.setFieldsValue({
            city: null,
          });
          setCityNameFromMap(null)
        }
      }
    });
  };

  const addNewAddressFromMap = async (values: any) => {
    let data = {
      address_type: addressTypeValues,
      address_line_1: values?.address_line_1,
      address_line_2: values?.address_line_2,
      locality: values?.locality || localityFromMap,
      city: values?.city || cityNameFromMap,
      state: values?.state || stateFromMap,
      pincode: values?.pincode || pincodeFromMap,
      city_id: cityIdFromMap || cityId?.id,
      latitude: addressFromMap?.lat,
      longitude: addressFromMap?.lng,
      is_primary: false,
      patient_id: pharmacyDetails?.patient?.id,
    };
    // console.log('eeeee 44444', data)
    if (!cityIdFromMap && !cityId?.id) {
      message.error("Can't find city, Please add different place instead", 4);
    } else {
      const address = await dispatch(addNewAddress(data));
      await dispatch(fetchMedicinesById(request_id));
      if (address?.payload?.data?.statusCode === 200) {
        setDeliveryAddress(address.payload.data.data.id);
        let param = {
          delivery_address_id: address?.payload?.data?.data?.id || "",
          address: formattedAddrFromMap,
        };
        await dispatch(fetchStationfromAddress(param)).then((res: any) => {
          let addrRes = res?.payload?.data?.data?.stations_with_distance;
          if (addrRes && addrRes?.length > 0) {
            setStationCount(addrRes.length);
            let params = {
              id: request_id,
              delivery_address_id: address?.payload?.data?.data?.id || "",
              address: param.address,
            };
            dispatch(updatePharmacyDetail({ id: request_id, data: params }));
          } else {
            setStationCount(0);
          }
        });
      }
      onCloseAddressModal(address?.payload?.data?.data?.id);
    }
  };

  const emtypAddressForm = () => {
    form.setFieldsValue({
      address_line_1: "",
      address_line_2: "",
      locality: "",
      city: "",
      state: "",
      pincode: "",
    });
  };

  const onChangeAddressType = ({ target: { value } }: RadioChangeEvent) => {
    setAddressTypeValues(value);
  };
  const handleDeletePrescriptionCart = async (file: any): Promise<boolean> => {
    try {
      const payload = { requestId: file.request_id, file_upload_id: file.id };
      const response = await dispatch(deleteMapPrescriptionToCart(payload));
      if (response?.payload?.data?.error === false) {
        props.handleDeletePrescription(file);
        notification.success({ message: "Prescription deleted" });
        return true;
      } else {
        notification.error({ message: "Something went wrong" });
        return false;
      }
    } catch (e) {
      notification.error({ message: "Something went wrong" });
      return false;
    }
  };

  const optionsWithAddressType = [
    { value: "home", label: "Home" },
    { value: "work", label: "Work" },
    { value: "other", label: "Other" },
  ];

  const prescriptionConfirmation = (action: string) => {
    const otc_medicine = medicineArr.find((item: any, index: number) => item.is_otc === false);
    if (otc_medicine?.is_otc === false && pharmacyDetails.files.length === 0 && medicineArr.length) {
      Modal.confirm({
        centered: true,
        icon: false,
        content: <div>No prescription has been mapped to the OTC medicine <br /> <br />
          Do you want to proceed?</div>,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          handleProceedModal(action);
        }
      });
    } else {
      handleProceedModal(action);
    }
  }
  const fileUploaded = async (document_id: string) => {
    try {
      const data = {
        request_id: request_id,
        patient_id: pharmacyDetails?.patient?.id,
        type: "VCBO_PRESCRIPTION",
        location: true
      };
      const patientPrescription = await dispatch(fetchPatientsDocuments(data));
      const { data: { data: { items } } } = patientPrescription.payload;
      const document = items.find((item: any, index: number) => item.id === document_id);
      props.handleMapPrescriptionToCart(null, document);
      handleCancel();
    } catch (e) {
      console.error("something went wrong.");
    }
  };
  const deleteConfirmation = (file: any) => {
    Modal.confirm({
      title: 'Confirm',
      icon: false,
      content: 'Are you sure to delete this prescription',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      // @ts-ignore
      style: { cssText: "text-align: left !important" },
      onOk: async () => {
        const deleteResult = await handleDeletePrescriptionCart(file);
        if (deleteResult) {
          Modal.destroyAll();
        }
      }
    });
  };


  return (
    <div className={"main-container white-background"}>
      <div className="pd20">
        <Row>
          <Text className="sub-heading">Medicines Order Details</Text>
        </Row>
        <Row>
          <Text type="secondary">
            Order Time:
            {moment(pharmacyDetails?.created_at).format("DD MMM'YY hh:mm A ")}
          </Text>
        </Row>
        <Row>
          <Col span={24} className="mt10 select-address">
            <Text className="sub-heading">Delivery Address</Text>
            {!isHideNewlyAddedAddressInput ? (
              <Row className="mt5">
                <Col span={24}>
                  {/* <Select style={{width: '40%'}} allowClear defaultValue={newlyAddress?.address_line_1 ? newlyAddress?.address_line_1 + ', '+ newlyAddress?.address_line_2 + ', '+ newlyAddress?.locality + ', '+ newlyAddress?.city + ', '+ newlyAddress?.state + ', '+ newlyAddress?.pincode : ''} onChange={(evt:any)=> handleChooseAddress(evt)} placeholder="Select Address"> */}
                  <Select
                    style={{
                      maxWidth: "700px",
                      width: "80%",
                      minWidth: "400px",
                    }}
                    allowClear
                    value={deliveryAddress}
                    defaultValue={
                      pharmacyDetails?.delivery_address_id
                        ? pharmacyDetails?.delivery_address_id
                        : null
                    }
                    onChange={(evt: any) => handleChooseAddress(evt)}
                    placeholder="Select Address"
                  >
                    {address?.map((indRow: any, index: any) => (
                      <Option key={indRow?.id} value={indRow?.id}>
                        {indRow?.address_line_1}, {indRow?.address_line_2}{" "}
                        {indRow?.address_line_2 ? ", " : ""}
                        {indRow?.locality} {indRow?.locality ? ", " : ""}{" "}
                        {indRow?.city} {indRow?.city ? ", " : ""}{" "}
                        {indRow?.state} {indRow?.state ? ", " : ""}
                        {indRow?.pincode}
                      </Option>
                    ))}
                    <Option value="newAddress">
                      <span style={{ color: "044a93" }}>+ Add New Address</span>
                    </Option>
                  </Select>
                </Col>
                <Col span={24}>
                  {deliveryAddress ? (
                    <b className="mt5">
                      {stationCount && stationCount > 0 ? (
                        stationCount + " stations found for this address"
                      ) : fetchingStations === "show" ? (
                        "Fetching Stations..."
                      ) : (
                        <Text type="danger">
                          Error: This address is not servicable. Please choose
                          another address
                        </Text>
                      )}{" "}
                    </b>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            ) : (
              <Row className="mt5">
                <Input
                  style={{ width: "40%" }}
                  value={newlyAddress ? newlyAddress : ""}
                  onClick={hideNewlyAddedAddressInput}
                  suffix={
                    <CaretDownOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  }
                />
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <Divider className="mt10 mb-10" />
      <Row className="mt20">
        <MedicineComponent
          requestId={request_id}
          deliveryAddress={deliveryAddress}
          medicineTotalPrice={medicinePrice}
          medicineArrCallBack={medicineArrCallBack}
          makeMedicineComponentEditable={props.makeMedicineComponentEditable}
          getMedicineTotalQuantity={getMedicineTotalQuantity}
          addedMedicines={addedMedicines}
        />

        {deliveryAddress ? (
          <Row className="mt20 fts12 full-width pdl20">
            <Col span={12}>
              {showMedicineSearch === false ? (
                <a href="" onClick={showAddMedicine}>
                  <u>+Add Medicines</u>
                </a>
              ) : (
                <Row>
                  <Col span={24}>
                    <Text className="sub-heading">
                      Add New Medicine
                      {/* From  */}
                    </Text>
                  </Col>
                  <AutoComplete
                    value={value}
                    allowClear={true}
                    dataSource={AutoOptions}
                    style={{ width: 300 }}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    onChange={onChange}
                    placeholder="Search Medicine Name"
                    className="mt5"
                  />
                </Row>
              )}
            </Col>
            {/* <Col span={12} className="pdl20" >
              {
                showServicesSearch === false ? 
                <a onClick={showAddServices}><u>+Add services</u></a> : 
                <Row>
                  <Text className="sub-heading">Add New Service</Text>
                  <Input placeholder="Search Service Name" className="mt5"/>
                </Row>
              }
            </Col> */}
          </Row>
        ) : (
          <Row className="mt20 fts12 full-width pdl20">
            <Col>
              <Text className="sub-heading">
                Please select Delivery Address to add medicine
              </Text>
            </Col>
          </Row>
        )}
      </Row>
      <Divider />
      <Row className={"pdl15"}>
        <Col span={12}><b>Selected Prescription:</b></Col>
        <Col span={12} className={"text-right"}>
          <Button type={"link"} onClick={showModal} style={{ textDecoration: "underline" }}>+Upload More Prescriptions</Button>
        </Col>
      </Row>
      <Row className={"pdl15"}>
        <Col span={12}>
          {
            pharmacyDetails.files.map((item: any, index: number) => {
              return (
                <Row>
                  <Col span={20}>
                    {
                      item.type === "VCBO_PRESCRIPTION" ?
                        <a onClick={() => {
                          setVisible(true);
                          setVisiblePrescription(item);
                        }}>
                          <span>{item.description} (VCBO PRESCRIPTION)</span>
                        </a> : <span>{item.description}</span>
                    }
                  </Col>
                  <Col span={4}>
                    <Tooltip title={"Delete Prescription"} placement={"bottomLeft"}>
                      <Button type="text" shape="round" size={"small"} onClick={() => deleteConfirmation(item)}>
                        <DeleteFilled />
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              );
            })
          }
        </Col>
      </Row>
      <Divider />
      <Row className="mt20 full-width sub-heading pdl20">
        <Col span={20}>
          <Text>Total Price</Text>
        </Col>
        <Col span={4}>
          <Text>Rs. {Math.ceil(Number(medicineTtlPrice.toFixed(2)))}</Text>
        </Col>
        <Col span={20}>
          <Text>Delivery Charges</Text>
        </Col>
        <Col span={4}>
          <Text>Rs. {medicineDiscountPrice}</Text>
        </Col>
        <Col span={20}>
          <Text className="fts16">Total Amount to be Paid</Text>
        </Col>
        <Col span={4}>
          <Text className="fts16">
            Rs. {Math.ceil(Number(parseFloat(String((medicineTtlPrice + medicineDiscountPrice))).toFixed(2)))}
          </Text>
        </Col>
      </Row>
      <Row className="mt20 full-width sub-heading pd20">
        <Col span={24}>
          {/* <Tag>{medicineTotalCount} Medicines in Order | 2 Medicine Require Prescription</Tag> */}
          <Tag>
            {medicineTotalCount} Medicines in Order{" "}
            {medicineRXRequired?.length > 0 &&
              `| ${medicineRXRequired?.length} Medicine Require Prescription`}
          </Tag>
        </Col>
        <Col className="mt15" span={24}>
          {/* <Button
            onClick={() => {
              handleProceedModal("cancel");
            }}
          >
            Cancel Order
          </Button> */}
          <Button
            disabled={isProceedToNext}
            onClick={() => {
              prescriptionConfirmation("next");
            }}
            className="mrg10"
            type="primary"
          >
            Next
          </Button>
        </Col>
      </Row>

      {/* Add New Address Drawer */}
      <Drawer
        title={`Add New Address`}
        placement="right"
        width={"50%"}
        // footer={<Space>
        //   <Button type="primary" > Add </Button>
        //   <Button onClick={() => setIsOpenAddNewAddress(false)}>Cancel</Button>
        // </Space>}
        onClose={() => {
          onCloseAddressModal();
        }}
        visible={isOpenAddNewAddress}
      >
        <Row className="nomargin pd10">
          <Col span={24} className="grey-background padding20">
            <Row>
              <Col span={24}>
                <GoogleMapSearchBox
                  selectedAddressFromSearchBox={onAreaSelect}
                  onCloseAddressModal={isMapAddressEmptyValue}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <MapComponent selectedAddr={addressFromMap} />
                {/* <Button disabled={!cityIdFromMap} style={{marginTop:'20px'}} type="primary" onClick={addNewAddressFromMap} block>Add</Button> */}
              </Col>
            </Row>
            <Divider />
            {/* <Row className="mt20">
              {formData && (
                <FormBar
                  name={"update_ticket"}
                  initialValues={formData.initialValues}
                  heading={formData.heading}
                  onFinish={formData.onFinish}
                  onFinishFailed={formData.onFinishFailed}
                  formElements={formData.formElements}
                  buttonText={formData.buttonText}
                ></FormBar>
              )}
            </Row> */}
            <Form
              name="basic"
              form={form}
              layout={"vertical"}
              onFinish={(values: any) => {
                addNewAddressFromMap(values);
              }}
              onFinishFailed={() => console.log("")}
              autoComplete="off"
              className="mt20"
            >
              <Col span={24}>
                <Row gutter={20}>
                  <Col span={24}>
                    <Form.Item
                      label="Select Address Type"
                      name="address_type"
                      rules={[{ required: false, message: "" }]}
                    >
                      <Radio.Group
                        options={optionsWithAddressType}
                        onChange={onChangeAddressType}
                        value={addressTypeValues}
                        optionType="button"
                        buttonStyle="solid"
                        defaultValue="home"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Address 1"
                      name="address_line_1"
                      rules={[
                        { required: true, message: "Address1 is mandatory" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Address 2"
                      name="address_line_2"
                      rules={[{ required: false, message: "" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Locality/Colony"
                      name="locality"
                      rules={[
                        { required: true, message: "Locality is mandatory" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[{ required: true, message: "City is mandatory" }]}
                    >
                      <AutoComplete
                        value={value}
                        allowClear={true}
                        dataSource={cityAutoOptions}
                        onSelect={onCitySelect}
                        onSearch={onCitySearch}
                        onChange={onChange}
                        placeholder="Enter City Name"
                        disabled={cityNameFromMap}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        { required: true, message: "State is mandatory" },
                      ]}
                    >
                      <Input disabled={stateFromMap} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Pincode"
                      name="pincode"
                      rules={[
                        { required: true, message: "Pincode is mandatory" },
                      ]}
                    >
                      <Input disabled={pincodeFromMap} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" block htmlType="submit">
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </Drawer>
      <Modal
        visible={cancelProceedModalVisible}
        onOk={handleOrder}
        onCancel={closeCancelOrder}
      >
        {proceedModalText}
      </Modal>

      <Modal title="Upload Document" visible={isModalOpen} onCancel={handleCancel}
        footer={null} centered>
        <UploadPrescription handleCancel={handleCancel} fileUploaded={fileUploaded} request_id={request_id} patient_id={pharmacyDetails.patient?.id} />
      </Modal>
      {
        visiblePrescription && visible && (
          visiblePrescription?.content_type === "pdf" ?
            <Modal title="Upload Prescription"
              visible={visible}
              onCancel={() => setVisible(false)} width={"80%"}
              footer={null} centered>
              <FileViewLayout
                modal={false}
                details={visiblePrescription}
                fileviewer={true}
                total={0}
                file={{ result: visiblePrescription.url }}
              ></FileViewLayout>
            </Modal>
            :
            <Image
              width={200}
              style={{ display: "none" }}
              src={visiblePrescription.url}
              preview={{
                visible,
                src: visiblePrescription.url,
                onVisibleChange: value => {
                  setVisible(value);
                }
              }}
            />)
      }
    </div>
  );
}

export default MedicineOrderComponent;
