import { notification } from "antd";
import {
  ACCOUNT_PREFIX,
  ADD_TOKEN_BASE_URL,
  BASE_API_URL,
  BASE_API_URL_BETAMEDI,
  WEBSOCKET_END_POINT,
} from "../constants";
import { CustomEvents, sendInternalEvent, validateToken } from "./utilities";

export const _BASE_URL = BASE_API_URL;
export const _WEB_SOCKET_ENDPOINT = WEBSOCKET_END_POINT;
const displayError = (message: any, shouldHide: any) => {
  !shouldHide && notification.error({ message: message });
};

// export const ADD_TOKEN_BASE_URL = ADD_TOKEN_BASE_URL
//   process.env.ADD_TOKEN_BASE_URL || "https://uatnotification.maxhospitals.in";
// export const ADD_TOKEN_BASE_URL = "";
export const _REGISTER_DEVICE = ADD_TOKEN_BASE_URL + "/notify/add-token";
// export const _REGISTER_DEVICE = "";

let tokenExpiredErrorDisplayed = false;
// const validateToken = () => {
//   const token = localStorage.getItem("token") as any;

//   if (token === "" || token === null || token === undefined) {
//     return true;
//   }
//   const exp = JSON.parse(window.atob(token?.split(".")[1])).exp;
//   if (Date.now() >= exp * 1000) {
//     return false;
//   }
//   // debugger;
//   return true;
// };

export async function Client(
  endpoint: string,
  { body, method, ...customConfig } = {} as any
) {
  let headers: any = {};
  if (!customConfig?.removeContentType) {
    headers["Content-Type"] = "application/json";
    headers["version"] = "0.0.1";
  }
  if (!validateToken()) {
    if (!tokenExpiredErrorDisplayed) {
      tokenExpiredErrorDisplayed = true;
      notification.error({ message: "Session Expired" });

      setTimeout(() => {
        const slug = localStorage?.getItem("account_slug");
        window.localStorage.clear();
        window.location.href =
          window.location.origin + "/" + (ACCOUNT_PREFIX || slug);
      }, 500);
    }
    return;
  }

  if (localStorage.getItem("token")) {
    headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  }
  if (localStorage.getItem("account_id")) {
    headers["account_id"] = localStorage.getItem("account_id");
  }
  if (localStorage.getItem("auth-type")) {
    headers["auth-type"] = localStorage.getItem("auth-type");
  }
  if (localStorage.getItem("application_id")) {
    headers["application_id"] = localStorage.getItem("application_id");
  }
  let application_slug;
  if (localStorage.getItem("application_slug")) {
    application_slug = localStorage.getItem("application_slug");
  }
  const config = {
    method: method || (body ? "POST" : "GET"),
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (customConfig?.mediURL) {
    body = {
      url: endpoint,
      method: config.method,
      headers: customConfig?.headers || null,
      payload: customConfig?.data || body || null,
    };
    endpoint = "task/integration/medi-req";
    config.method = "POST";
    //customConfig?.mediURLMethod ||
  }
  if (body) {
    config.body = JSON.stringify(body);
  }

  if (customConfig?.removeContentType) {
    config.body = body;
    headers["Content-Type"] = "multipart/form-data";
  }

  let data;
  const betaMediUrl = customConfig?.mediURL ? BASE_API_URL_BETAMEDI : null;
  const BASE_URL =
    customConfig.baseUrl ||
    betaMediUrl ||
    localStorage.getItem("baseUrl") ||
    _BASE_URL;
  delete config.baseUrl;
  try {
    sendInternalEvent(CustomEvents.REQUEST_SENT, { endpoint });
    const response = await window.fetch(BASE_URL + endpoint, config);

    if (response.ok) {
      sendInternalEvent(CustomEvents.REQUEST_SUCCESS, { endpoint });
      if (response.status === 204) {
        return {
          status: response.status,
          headers: response.headers,
          url: response.url,
        };
      }
      data = await response.json();
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    } else if (response.status === 401) {
      sendInternalEvent(CustomEvents.REQUEST_FAIL, { endpoint });
      displayError("Un Authorized", customConfig?.shouldHide);
      const slug = localStorage?.getItem("account_slug");
      window.localStorage.clear();
      window.location.href =
        window.location.origin + "/" + (ACCOUNT_PREFIX || slug);
    } else if (response.status === 403) {
      data = await response.json();
      displayError(data.message || "Forbidden", customConfig?.shouldHide);
      sendInternalEvent(CustomEvents.REQUEST_FAIL, { message: data.message });
      return Promise.reject(data.message || "Forbidden");
    } else if (response.status === 400 || response.status === 404) {
      data = await response.json();
      let message = data.message;
      if (data.errorDetails) {
        message = data.errorDetails
          .map((e: any) => `${e.message || e.error}`)
          .join(", ");
      }
      displayError(message || "Forbidden", customConfig?.shouldHide);
      sendInternalEvent(CustomEvents.REQUEST_FAIL, { message });
      return Promise.reject(message);
    }
    sendInternalEvent(CustomEvents.REQUEST_FAIL, {
      message: response.statusText || "Something went wrong",
    });
    displayError(
      response.statusText || "Something went wrong",
      customConfig?.shouldHide
    );
    return Promise.reject(response.statusText);
  } catch (err: any) {
    console.log(err);
    // debugger;
    //   notification.error({ message: err.message ? err.message : data });
    notification.error({ message: "Please check your internet connection" });
    sendInternalEvent(CustomEvents.REQUEST_FAIL, { message: "Not sent" });
    return Promise.reject(err.message ? err.message : data);
  }
}

Client.get = function (endpoint: string, customConfig: any = {}) {
  let params: any = [];
  if (customConfig.params) {
    for (let p in customConfig.params) {
      let key = p;
      if (Array.isArray(customConfig.params[p])) {
        customConfig.params[p].forEach((element: any) => {
          params.push(`${key}=${encodeURIComponent(element)}`);
        });
      } else {
        params.push(`${key}=${encodeURIComponent(customConfig.params[p])}`);
      }
    }
  }
  if (customConfig.params) {
    return Client(endpoint + "?" + params.join("&"), {
      ...customConfig,
      method: "GET",
    });
  } else {
    delete customConfig.params;
    return Client(endpoint, { ...customConfig, method: "GET" });
  }
};

Client.post = function (endpoint: string, body: any, customConfig = {}) {
  return Client(paramsEndpoint(endpoint, customConfig), {
    ...customConfig,
    body,
    method: "POST",
  });
};

Client.put = function (endpoint: string, body: any, customConfig = {}) {
  return Client(paramsEndpoint(endpoint, customConfig), {
    ...customConfig,
    body,
    method: "PUT",
  });
};

Client.patch = function (endpoint: string, body: any, customConfig = {}) {
  return Client(paramsEndpoint(endpoint, customConfig), {
    ...customConfig,
    body,
    method: "PATCH",
  });
};

Client.delete = function (endpoint: string, customConfig = {}) {
  return Client(paramsEndpoint(endpoint, customConfig), {
    ...customConfig,
    method: "DELETE",
  });
};

const paramsEndpoint = (endpoint: string, customConfig: any) => {
  let params: any = [];
  if (customConfig.params) {
    for (let p in customConfig.params) {
      let key = p;
      if (Array.isArray(customConfig.params[p])) {
        customConfig.params[p].forEach((element: any) => {
          params.push(`${key}=${encodeURIComponent(element)}`);
        });
      } else {
        params.push(`${key}=${encodeURIComponent(customConfig.params[p])}`);
      }
    }
  }
  return endpoint + "?" + params.join("&");
};
