import {
    Button,
    Col,
    DatePicker, Drawer, message, Modal, Row,
    Space
} from "antd";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveChatRooms, getInactiveChatRooms, manage_chat_room } from "../../../redux/reducers/chat.slice";
import { RootState } from "../../../shared/constants";
import ChatLayout from "../../../shared/layouts/chat.layout";
import TableLayout from "../../../shared/layouts/table.layout";
import TabsLayout from "../../../shared/layouts/tabs.layout";
import { formatDate } from "../../../shared/Utils/utilities";
const { RangePicker } = DatePicker;

function ChatAdmin() {
    const dispatch = useDispatch();
    const { loading, calllogs, pagination } = useSelector(
        (state: RootState) => state.calllogs
    );
    let callStatus = ["open chats", "closed chats"];
    const [currentCallStatus, setCurrentCallStatus] = useState(callStatus[0]);
    const { room_loading, rooms } = useSelector((state: RootState) => state.chat);
    const { activerooms, loadingActive } = useSelector((state: RootState) => state.chat);
    const { loadinginActive, inactiverooms } = useSelector((state: RootState) => state.chat);
    const [openChatsDatasource, setopenChatsDatasource] = useState([] as any)
    const [closedChatsDatasource, setclosedChatsDatasource] = useState([] as any)
    const [clickedChatId, setclickedChatId] = useState(null as any)
    const [allMessages, setAllMessages] = useState([] as any);
    const [allRooms, setALlRoms] = useState([] as Array<any>);
    const [showModel, setshowModel] = useState(false as any)
    const [chat, setChat] = useState(false);
    const [modelMessage, setmodelMessage] = useState(null as any)
    const [actionType, setactionType] = useState(null as any)
    const [shouldRunFunction, setShouldRunFunction] = useState(false);
    const [drawerDataAll, setdrawerDataAll] = useState(null as any)



    const [filters, setFilters] = useState({
        date: moment().format("YYYY-MM-DD"),
    } as any);
    const [open, setOpen] = useState(false);

    const { profile } = useSelector((state: RootState) => state.profile);

    const ws = useRef(null as any);

    const { account } = useSelector((state: RootState) => state.auth);
    const app: string = "agent";
    const params = {
        account_id: account?.id,
        application_id: localStorage?.getItem("application_id"),
        token: localStorage?.getItem("token"),
    }


    const showDrawer = (data?: any) => {
        setdrawerDataAll(data)
        setOpen(true);
        setclickedChatId(data.chat_room_id)
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        rooms && setALlRoms(rooms);
    }, [rooms,]);

    let columns: any = [
        {
            title: "Chat Id",
            dataIndex: 'chat_room_id',
            fixed: "left",
        },
        {
            title: "Phone",
            dataIndex: 'patient_mobile',


        },
        {
            title: "Assignee",
            dataIndex: 'agent_name',
        },
        {
            title: "Patient last message on",
            dataIndex: 'user_last_msg_time',
            render: (value: any) => { return value ? formatDate(value) : null }

        },
        {
            title: "Agent last message on",
            dataIndex: 'agent_last_msg_time',
            render: (value: any) => { return value ? formatDate(value) : null }

        },
        {
            title: "Contact Name",
            dataIndex: 'patient_name'
        },
        // {
        //     title: "Last Client text on",
        //     dataIndex: 'lastmsgonpatient'
        // },
        {
            title: "Action",
            // fixed: "right",
            width: '300px',
            render: (a: any) => {
                return (
                    <Space>
                        <Button onClick={() => { showDrawer(a) }} >View Chat </Button>
                        <Button onClick={() => { onReassignChat(a) }}>Re Assign</Button>
                        {
                            currentCallStatus === 'open chats' ? <Button onClick={() => { onCloseChat(a) }}>Close</Button> : ''

                        }
                    </Space>
                )
            }
        },
    ];




    function filterFormSubmit(changed: any, all: any) {
        setFilters({ ...filters, date: changed.date ? moment(changed.date).format('YYYY-MM-DD') : null })
        setShouldRunFunction(moment(changed.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? true : false)
    }


    const onTabChange = (key: any) => {
        setCurrentCallStatus(callStatus[key - 1]);
    };

    const getChatRooms = () => {
        const paramss = { ...params };
        dispatch(
            getActiveChatRooms({
                paramss,
                user_type: `all`,
                status: 'active',
                time_diff: 0,
                date: filters?.date ? moment(filters?.date).format('YYYY-MM-DD') : moment().format("YYYY-MM-DD")
            })
        );
        dispatch(
            getInactiveChatRooms({
                paramss,
                user_type: `all`,
                status: 'inactive',
                time_diff: 0,
                date: filters?.date ? moment(filters?.date).format('YYYY-MM-DD') : moment().format("YYYY-MM-DD")

            })
        );
    }

    useEffect(() => {
        // setInterval(getChatRooms, 30000);
        let intervalId: any;

        if (shouldRunFunction) {
            intervalId = setInterval(() => {
                getChatRooms()
            }, 30 * 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [shouldRunFunction])



    useEffect(() => {
        if (filters?.date) {
            getChatRooms()
        }
        setShouldRunFunction(filters?.date === moment().format('YYYY-MM-DD') ? true : false)

    }, [filters])


    // const ssssssssssss = {
    //     date: moment().format('YYYY-MM-DD')
    // }




    const formElements = {
        onFinish: () => { },
        onFinishFailed: () => { },
        onValuesChange: filterFormSubmit,
        // initialValues: { ssssssssssss },
        setValues: {
            ...filters,
            date: moment(filters.date),
        },
        formElements: [

            {
                label: "",
                type: "DatePicker",
                name: "date",
                colSpan: 7,
                allowClear: false
            },
        ],
    };

    var allTabs = [
        {
            title: "OPEN CHATS",
            component: (
                <Row className="padding10">
                    <Col span={24}>
                        <TableLayout
                            maxWidth={1500}
                            pageType="withTab"
                            // defaultCurrentPage={pageNumber + 1}
                            loading={loadingActive}
                            dataSource={openChatsDatasource}
                            columns={columns}
                            // onChange={(e, ee) => onPageChange(e, ee)}
                            type="genericfilter"
                            total={pagination.total}
                            showTotal={true}
                            pagination={{
                                position: ["none", "none"],
                                pageSize: pagination?.limit,
                            }}
                            // buttonEvt={() => onClickDownload()}
                            formData={formElements}
                        />
                    </Col>
                </Row>
            ),
        },
        {
            title: "CLOSED CHATS",
            component: (
                <Row className="padding10">
                    <Col span={24}>
                        <TableLayout
                            maxWidth={1500}
                            pageType="withTab"
                            // defaultCurrentPage={pageNumber + 1}
                            loading={loadinginActive}
                            dataSource={closedChatsDatasource}
                            columns={columns}
                            // onChange={(e, ee) => onPageChange(e, ee)}
                            type="genericfilter"
                            total={pagination.total}
                            showTotal={true}
                            pagination={{
                                position: ["none", "none"],
                                pageSize: pagination?.limit,
                            }}
                            // buttonEvt={() => onClickDownload()}
                            formData={formElements}
                        />
                    </Col>
                </Row>
            ),
        },
    ];
    // =======

    // Top Bar


    useEffect(() => {
        activerooms && setopenChatsDatasource(activerooms)
    }, [activerooms])

    useEffect(() => {
        inactiverooms && setclosedChatsDatasource(inactiverooms)
    }, [inactiverooms])




    const onOkModel = () => {

        if (actionType === 'close') {
            const datas = {
                chat_room_id: clickedChatId,
                action: 'close',
                source: drawerDataAll?.source
            }
            dispatch(manage_chat_room({ datas, params })).then((val: any) => {
                // setshowModel(false)
                setshowModel(false)
                setOpen(false)
                if (val?.payload?.data?.message === 'success') {
                    message.success({
                        content: 'Chat closed successfully',

                    });
                    // setshowModel(false)
                    getChatRooms()
                }
                else {
                    message.error({
                        content: 'Something went wrong. Please try again.',
                    });
                }
            })
        }
        else {
            const datas = {
                chat_room_id: clickedChatId,
                action: 'reassign',
                source: drawerDataAll?.source

            }
            dispatch(manage_chat_room({ datas, params })).then((val: any) => {
                setshowModel(false)
                setOpen(false)
                if (val?.payload?.data?.message === 'success') {
                    message.success({
                        content: 'Chat Assigned successfully',

                    });

                    getChatRooms()
                }
                else {
                    message.error({
                        content: val?.payload?.data?.data || 'Something went wrong. Please try again.',
                    });
                }
            })
        }



    }
    const oncancelModel = () => {
        setshowModel(false)
    }


    const onCloseChat = (value: any) => {
        setdrawerDataAll(value)
        setactionType('close')
        setmodelMessage(`Do you want to close the chat of chat id: ${value.chat_room_id}`)
        setshowModel(true)
        setclickedChatId(value.chat_room_id)
    }

    const onReassignChat = (value: any) => {
        setdrawerDataAll(value)
        setactionType('reassign')
        setmodelMessage(`Do you want to Re Assign the chat of chat id: ${value.chat_room_id}`)
        setshowModel(true)
        setclickedChatId(value.chat_room_id)

    }

    return (
        <Fragment>

            <Row>
                <Col span={24} className="crm-tabs">
                    <TabsLayout
                        AllTabs={allTabs}
                        onChange={(key) => onTabChange(key)}
                    ></TabsLayout>
                </Col>
            </Row>

            {
                open && <Drawer
                    title={`Chats`}
                    placement="right"
                    width={"50%"}
                    onClose={onClose}
                    visible={open}
                    extra={
                        <Space>
                            <Button type="primary" onClick={() => { onReassignChat(drawerDataAll) }}>Re Assign</Button>
                            <Button danger onClick={() => { onCloseChat(drawerDataAll) }}>
                                Close
                            </Button>
                        </Space>
                    }
                >
                    <ChatLayout
                        allMessages={allMessages}
                        allData={allRooms}
                        chatType={'admin'}
                        isDrawer={false}
                        show={chat}
                        // details={patient}
                        type={"agent"}
                        loginUser={"agent"}
                        chat_room_id={clickedChatId}
                        setChat={(value: any) => setChat(value)}
                        is_admin={true}
                    ></ChatLayout>
                </Drawer>
            }
            {
                showModel && <Modal
                    visible={showModel}
                    onOk={onOkModel}
                    onCancel={oncancelModel}
                >
                    {modelMessage}
                </Modal>
            }

        </Fragment>
    );
}

export default ChatAdmin;
