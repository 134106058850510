import { notification } from "antd";
import { ACCOUNT_PREFIX, BASE_SOCIAL_API_URL } from "../constants";
import { CustomEvents, sendInternalEvent } from "./utilities";

export const _BASE_URL = BASE_SOCIAL_API_URL;
const displayError = (message: any, shouldHide: any) => {
  !shouldHide && notification.error({ message: message });
};

export async function Client(
  endpoint: string,
  { body, ...customConfig } = {} as any
) {
  const headers: any = { "Content-Type": "application/json", version: "0.0.1" };

  if (localStorage.getItem("token")) {
    headers["Authorization"] = `Bearer bjwz9-wm0ks-gkp7r-7qo52`; //${localStorage.getItem("social-token")}`;
  }
  if (localStorage.getItem("account_id")) {
    headers["account_id"] = localStorage.getItem("account_id");
  }
  if (localStorage.getItem("auth-type")) {
    headers["auth-type"] = "social"; //localStorage.getItem("auth-type");
  }
  if (localStorage.getItem("application_id")) {
    headers["application_id"] = localStorage.getItem("application_id");
  }
  let application_slug;
  if (localStorage.getItem("application_slug")) {
    application_slug = localStorage.getItem("application_slug");
  }
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  const BASE_URL = localStorage.getItem("baseUrl") || _BASE_URL;
  try {
    sendInternalEvent(CustomEvents.REQUEST_SENT, { endpoint });
    const response = await window.fetch(BASE_URL + endpoint, config);

    if (response.ok) {
      sendInternalEvent(CustomEvents.REQUEST_SUCCESS, { endpoint });
      if (response.status === 204) {
        return {
          status: response.status,
          headers: response.headers,
          url: response.url,
        };
      }
      data = await response.json();
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    } else if (response.status === 401) {
      sendInternalEvent(CustomEvents.REQUEST_FAIL, { endpoint });
      displayError("Un Authorized", customConfig?.shouldHide);
      const slug = localStorage?.getItem("account_slug");
      window.localStorage.clear();
      window.location.href =
        window.location.origin + "/" + (ACCOUNT_PREFIX || slug);
    } else if (response.status === 403) {
      data = await response.json();
      displayError(data.message || "Forbidden", customConfig?.shouldHide);
      sendInternalEvent(CustomEvents.REQUEST_FAIL, { message: data.message });
      return Promise.reject(data.message || "Forbidden");
    } else if (response.status === 400 || response.status === 404) {
      data = await response.json();
      let message = data.message;
      if (data.errorDetails) {
        message = data.errorDetails
          .map((e: any) => `${e.message || e.error}`)
          .join(", ");
      }
      displayError(message || "Forbidden", customConfig?.shouldHide);
      sendInternalEvent(CustomEvents.REQUEST_FAIL, { message });
      return Promise.reject(message);
    }
    sendInternalEvent(CustomEvents.REQUEST_FAIL, {
      message: response.statusText || "Something went wrong",
    });
    displayError(
      response.statusText || "Something went wrong",
      customConfig?.shouldHide
    );
    return Promise.reject(response.statusText);
  } catch (err: any) {
    //   notification.error({ message: err.message ? err.message : data });
    notification.error({ message: "Please check your internet connection" });
    sendInternalEvent(CustomEvents.REQUEST_FAIL, { message: "Not sent" });
    return Promise.reject(err.message ? err.message : data);
  }
}

Client.get = function (endpoint: string, customConfig: any = {}) {
  let params: any = [];
  if (customConfig.params) {
    for (let p in customConfig.params) {
      let key = p;
      if (Array.isArray(customConfig.params[p])) {
        customConfig.params[p].forEach((element: any) => {
          params.push(`${key}=${encodeURIComponent(element)}`);
        });
      } else {
        params.push(`${key}=${encodeURIComponent(customConfig.params[p])}`);
      }
    }
  }
  if (customConfig.params) {
    return Client(endpoint + "?" + params.join("&"), {
      ...customConfig,
      method: "GET",
    });
  } else {
    delete customConfig.params;
    return Client(endpoint, { ...customConfig, method: "GET" });
  }
};

Client.post = function (endpoint: string, body: any, customConfig = {}) {
  return Client(endpoint, { ...customConfig, body, method: "POST" });
};

Client.put = function (endpoint: string, body: any, customConfig = {}) {
  return Client(endpoint, { ...customConfig, body, method: "PUT" });
};

Client.patch = function (endpoint: string, body: any, customConfig = {}) {
  return Client(endpoint, { ...customConfig, body, method: "PATCH" });
};

Client.delete = function (endpoint: string, customConfig = {}) {
  return Client(endpoint, { ...customConfig, method: "DELETE" });
};
