import { InfoCircleFilled } from "@ant-design/icons";
import { Col, message, Modal, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches } from "../../../../../redux/reducers/branch.slice";
import { patchFacebookPageData } from "../../../../../redux/reducers/social.slice";
import { RootState } from "../../../../../shared/constants";
import FormBar from "../../../../../shared/layouts/formbar.layout";
type Props = {
  pageData: any;
  modalVisible: boolean;
  reloadPages: any;
  setModalVisible: (val: any) => void;
};

function PageSettingsComponent({
  pageData,
  modalVisible,
  reloadPages,
  setModalVisible,
}: Props) {
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(false);
  const { branches } = useSelector((state: RootState) => state.branch);

  useEffect(() => {
    branches?.length == 0 && dispatch(fetchBranches({}));
  }, []);

  const onFinish = (values: any) => {
    delete values.none;
    setFormLoading(true);
    let payload: any = {
      ...values,
    };
    // debugger;
    dispatch(patchFacebookPageData({ pageId: pageData.page_id, payload })).then(
      (val: any) => {
        message.success("Branch mapping updated!");
        setModalVisible(false);
        reloadPages();
      }
    );
  };

  const formBasic = {
    name: "Page Settings Form",
    onFinish: onFinish,
    onFinishFailed: () => { },
    initialValues: {
      branch_id: pageData.branch_id,
    },
    setValues: {},
    buttonText: "Save Settings",
    formElements: [
      {
        label: (
          <div style={{ display: "block" }}>
            <Row>
              <Col span={24}>
                {/* <Divider style={{ marginTop: "10px", marginBottom: "10px" }} /> */}
              </Col>
            </Row>
            <Row>
              <Col span={24}>Page level System Fields Mapping</Col>
            </Row>
          </div>
        ),
        type: "label",
        name: "none",
      },
      {
        label: (
          <>
            Branch Mapping &nbsp;&nbsp;&nbsp;
            <Tooltip title="Leads coming from this form will be mapped to selected branch.">
              <a>
                <InfoCircleFilled />
              </a>
            </Tooltip>
          </>
        ),
        type: "Select",
        name: "branch_id",
        colSpan: 12,
        options: branches?.map((val: any) => {
          return { name: val.name, value: val.id };
        }),
        allowClear: true,
      },
    ],
  };
  console.log(formBasic);
  return (
    <>
      <Modal
        title={`Page Settings: ${pageData.page_name}`}
        centered
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={720}
        footer={false}
        className="form-modal"
        style={{ paddingBottom: "0px" }}
      >
        <FormBar {...formBasic} loading={formLoading}></FormBar>
      </Modal>
    </>
  );
}

export default PageSettingsComponent;
