import { Card, Col, Row, Skeleton } from "antd";
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchContact } from '../../../redux/reducers/contacts.slice';
import { TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD } from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import AccentCard from '../../../shared/layouts/crm-accentcard.layout';
import Topbar from '../../../shared/layouts/crm.topbar';
import TabsLayout from '../../../shared/layouts/tabs.layout';
import CreateLeadForm from '../Leads/components/createleadform';
import AddTicket from '../Tickets/components/add-ticket';
import AssociatedLeads from '../components/associatedleadstab';
import HeaderButtons from '../components/headerbuttons';
import TabTitle from '../components/tabtitle';
import TicketsTab from '../components/ticketstab';

type Props = {

}
const CRMContactDetailsPage: React.FunctionComponent<Props> = () => {
    const dispatch = useDispatch();
    const { contact } = useSelector((state: RootState) => state.contact);
    const [phone, setPhone] = useState(null as any)
    const RouteParams: any = useParams();
    const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
    const allPermissions = permissions && JSON.parse(permissions?.auth);
    const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
    const { t } = useTranslation()

    useEffect(() => {
        RouteParams["id"] && dispatch(fetchContact(RouteParams["id"]))
    }, [RouteParams["id"]])
    useEffect(() => {
        if (contact) {
            setPhone(contact.phone)
        }
    }, [contact])
    var allTabs = [] as any
    allTabs = [
        ...((myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD) !== -1 ||
            myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_ASSOCIATED_LEAD?.split(".")[0]) !== -1) ? [{ title: <TabTitle title="Associated Leads" />, component: <AssociatedLeads phone={phone} /> }] : []),

        { title: <TabTitle title={`Associated ${t("CRM.Tickets.Fields.Tickets")} `} />, component: <TicketsTab phone={phone} /> },
    ]
    const [leadFormVisible, setLeadFormVisible] = useState(false);
    const [ticketFormVisible, setTicketFormVisible] = useState(false);
    return (
        <Fragment>
            <Topbar extra={<HeaderButtons setLeadFormVisible={setLeadFormVisible} setTicketFormVisible={setTicketFormVisible} />} title={'Contact Details'}></Topbar>
            {contact &&
                <div className='pd10'>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={7}>
                            <AccentCard type='contacts' details={contact} callData={{ source: 'contact', contact_id: RouteParams["id"] }}></AccentCard>
                        </Col>
                        <Col className="gutter-row" span={17}>
                            <Card className='nopaddingcard crm-tabs'>
                                {phone && <TabsLayout AllTabs={allTabs}></TabsLayout>}
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
            {
                !contact && <Skeleton />
            }
            {leadFormVisible && <CreateLeadForm saveLeadSuccess={() => setLeadFormVisible(false)} setLeadFormVisible={(val: any) => setLeadFormVisible(val)} leadFormVisible={leadFormVisible} phone={contact?.phone} defaultContactId={contact?.id}></CreateLeadForm>}
            {ticketFormVisible && <AddTicket onTicketSave={() => setTicketFormVisible(false)} setTicketFormVisible={(val: any) => setTicketFormVisible(val)} ticketFormVisible={ticketFormVisible} phoneNumber={contact?.phone} defaultContactId={contact?.id}></AddTicket>}
        </Fragment>
    );
}

export default CRMContactDetailsPage;
