import { Button, Dropdown, Menu, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { ReactComponent as ThreeDotMenu } from "../../../assets/icons/dot-menu.svg";
import {
  TASK_MODULE_ACTIONS_CREATE_LEAD,
  TASK_MODULE_ACTIONS_CREATE_TICKET,
  TASK_MODULE_COMPLETE
} from "../../../shared/components/permission.constants";
import { ROUTE_CRM_SLARULES_ADD } from "../../../shared/routes/crm.routes.constants";
type Props = {
  setTicketFormVisible?: (e: any) => void;
  setLeadFormVisible?: (e: any) => void;
  callLogDisabled?: boolean;
  link?: boolean;
  contactData?: any;
  createRule?: any;
};
export default function HeaderButtons({
  setTicketFormVisible = (e: any) => { },
  setLeadFormVisible = (e: any) => { },
  callLogDisabled = false,
  link = false,
  contactData = {},
  createRule
}: Props) {
  const { t } = useTranslation();
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  const history = useHistory();
  let topButtons = [];
  let dropdownButtons = [];
  if (
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_CREATE_TICKET) !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_COMPLETE) !== -1
  ) {
    topButtons.push(
      <Button key="1" onClick={() => setTicketFormVisible(true)}>
        {t("CRM.Common.Actions.Create Ticket")}
      </Button>
    );
    dropdownButtons.push({
      key: "1",
      label: (
        <a onClick={() => setTicketFormVisible(true)}>
          {t("CRM.Common.Actions.Create Ticket")}
        </a>
      ),
    });
  }
  if (createRule) {
    topButtons?.push(
      <Button key="3" onClick={() => history.push(ROUTE_CRM_SLARULES_ADD)}>
        {'Create Rule'}
      </Button>
    );
  }

  if (
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_CREATE_LEAD) !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_COMPLETE) !== -1
  ) {
    topButtons.push(
      <Button key="2" onClick={() => setLeadFormVisible(true)}>
        {t("CRM.Common.Actions.Create Lead")}
      </Button>
    );
    dropdownButtons.push({
      key: "2",
      label: (
        <a onClick={() => setLeadFormVisible(true)}>
          {t("CRM.Common.Actions.Create Lead")}
        </a>
      ),
    });
  }

  if (callLogDisabled === true) {
    topButtons = [];
    dropdownButtons = [];
    if (
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_CREATE_TICKET) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_COMPLETE) !== -1
    ) {
      topButtons.push(
        <Tooltip
          title="Link a contact with Phone Number to create a ticket"
          color={"#E1454E"}
          key={"#E1454E"}
        >
          <Button>{t("CRM.Common.Actions.Create Ticket")}</Button>
        </Tooltip>
      );
      dropdownButtons.push({
        key: "1",
        label: (
          <Tooltip
            title="Link a contact with Phone Number to create a ticket"
            color={"#E1454E"}
            key={"#E1454E"}
          >
            <a onClick={(e) => e.preventDefault()}>
              {t("CRM.Common.Actions.Create Ticket")}
            </a>
          </Tooltip>
        ),
      });
    }
    if (
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_CREATE_LEAD) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_COMPLETE) !== -1
    ) {
      topButtons.push(
        <Tooltip
          title="Link a contact with Phone Number to create a lead"
          color={"#E1454E"}
          key={"#E1454E"}
        >
          <Button>{t("CRM.Common.Actions.Create Lead")}</Button>
        </Tooltip>
      );
      dropdownButtons.push({
        key: "2",
        label: (
          <Tooltip
            title="Link a contact with Phone Number to create a lead"
            color={"#E1454E"}
            key={"#E1454E"}
          >
            <a onClick={(e) => e.preventDefault()}>
              {t("CRM.Common.Actions.Create Lead")}
            </a>
          </Tooltip>
        ),
      });
    }
  }
  if (link === true) {
    topButtons = [];
    dropdownButtons = [];
    if (
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_CREATE_TICKET) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_COMPLETE) !== -1
    ) {
      topButtons.push(
        <a
          type="link"
          className="bold-600 text-primary"
          onClick={() => setTicketFormVisible(contactData)}
        >
          {t("CRM.Common.Actions.Create Ticket")}
        </a>
      );
      dropdownButtons.push({
        key: "1",
        label: (
          <a onClick={() => setTicketFormVisible(contactData)}>
            {t("CRM.Common.Actions.Create Ticket")}
          </a>
        ),
      });
    }
    if (
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_CREATE_LEAD) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_COMPLETE) !== -1
    ) {
      topButtons.push(
        <a
          type="link"
          className="bold-600 text-success"
          onClick={() => setLeadFormVisible(contactData)}
        >
          {t("CRM.Common.Actions.Create Lead")}
        </a>
      );
      dropdownButtons.push({
        key: "2",
        label: (
          <a onClick={() => setLeadFormVisible(contactData)}>
            {t("CRM.Common.Actions.Create Lead")}
          </a>
        ),
      });
    }
    topButtons = (
      <>
        {topButtons[0]} &nbsp;&nbsp;&nbsp; {topButtons[1]}
      </>
    ) as any;
  }

  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  const menu = <Menu items={dropdownButtons} />;
  return (
    <>
      {screenType.mobile ? (
        <>
          <Dropdown overlay={menu}>
            <a className="header-more-options">
              <ThreeDotMenu />
            </a>
          </Dropdown>
        </>
      ) : (
        topButtons
      )}
    </>
  );
}
