import { Button, Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAgents } from "../../../../redux/reducers/agents.slice";
import {
  fetchTeamIds,
  fetchTeamTypes,
} from "../../../../redux/reducers/assignment.slice";
import { fetchMasterTaskDropDown } from "../../../../redux/reducers/master.slice";
import {
  fetchLabelList,
  fetchTaskCategory,
  fetchTaskSubTypeById,
  fetchTaskType,
} from "../../../../redux/reducers/metadata.slice";
import { TASK_MODULE_ACTIONS_READ_LEAD, TASK_MODULE_ACTIONS_READ_TICKET } from "../../../../shared/components/permission.constants";
import { RootState } from "../../../../shared/constants";

const { Option } = Select;
type Props = {
  onSaveContact: (val: any) => void;
  onEditContact: (val: any) => void;
  onSaveContactFailed: (val: any) => void;
  modalVisible: boolean;
  setmodalVisible: (val: any) => void;
  formLoading: boolean;
  typess: any;
  initialValues?: any;
  editData: any;
};

function CreateAssignmentForm({
  editData,
  typess,
  onSaveContact,
  onEditContact,
  onSaveContactFailed,
  modalVisible,
  setmodalVisible,
  formLoading,
  initialValues,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [opportunitiesList, setOpportunitiesList] = useState([] as any);
  initialValues = {
    mode: typess === "edit" ? editData?.mode : null,
    source_id: typess === "edit" ? editData?.source_id : null,
    type_id: typess === "edit" ? editData?.type?.id : null,
    sub_type_id: typess === "edit" ? editData?.sub_type?.id : null,
    category_id: typess === "edit" ? editData?.category?.id : null,
    priority_id: typess === "edit" ? editData?.priority?.id : null,
    scope: "location",
    tat: typess === "edit" ? editData?.tat : 0,
    assigned_users: typess === "edit" ? editData?.assigned_users : [],
    assigned_team_types: typess === "edit" ? editData?.assigned_team_types : [],
    assigned_teams: typess === "edit" ? editData?.assigned_teams : [],
  };

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const [mode, setmode] = useState(typess === "edit" ? editData?.mode : "");
  const [teamTypeRadio, setteamTypeRadio] = useState(() => {
    let teams = editData?.assigned_teams?.length;
    let teamTypes = editData?.assigned_team_types?.length;
    let users = editData?.assigned_users?.length;

    if (teams > teamTypes && teams > users) {
      return "teamName";
    } else if (teamTypes > users && teamTypes > teams) {
      return "teamType";
    } else {
      return "users";
    }
  });

  const [formState, setformState] = useState(initialValues);

  const [taskTypeArray, settaskTypeArray] = useState([] as any);

  let { loading, metadata, pagination } = useSelector(
    (state: RootState) => state.metadata
  );
  const { loadings, taskType, paginations } = useSelector(
    (state: RootState) => state.metadata
  );
  const { taskDropDown } = useSelector((state: RootState) => state.master);
  let { loadingsss, taskSubTypeById, paginationsss } = useSelector(
    (state: RootState) => state.metadata
  );
  const { loadingssss, taskCategory, paginationssss } = useSelector(
    (state: RootState) => state.metadata
  );
  const { loadingsssss, teamTypes, paginationsssss } = useSelector(
    (state: RootState) => state.assignment
  );
  const { loadingssssss, teamIds, paginationssssss } = useSelector(
    (state: RootState) => state.assignment
  );
  // const { loading1, agents, offset, total } = useSelector((state: RootState) => state.user);
  const { agentsAll } = useSelector((state: RootState) => state.agents);

  const radioOptions = [
    {
      id: "users",
      name: "Users",
    },
    {
      id: "teamName",
      name: "Team Name",
    },
    {
      id: "teamType",
      name: "Team Type",
    },
  ];
  let sourceOptions: any, priorityDataValues: any;

  let sourceData = metadata?.filter((item: any) => {
    return item.name === "source" && item.mode === mode;
  });
  sourceOptions = sourceData && sourceData[0] ? sourceData[0]["values"] : [];

  let priorityData = metadata?.filter((item: any) => {
    return item.mode === mode && item.name === "priority";
  });
  priorityDataValues =
    priorityData && priorityData[0] ? priorityData[0]["values"] : [];

  const handleChangeMode = (value: any) => {
    setmode(value);
  };

  const handleChangeTaskType = (value: any) => {
    // settaskTypeName(value)
    value && dispatch(fetchTaskSubTypeById({ id: value }));
    taskSubTypeById = [];
  };
  const handleChangeTaskSubType = (value: any) => {
    // settaskSubTypeName(value)
    value && dispatch(fetchTaskCategory({ id: value }));
  };
  const onChangeTeamType = (e: any) => {
    const types = e.target.value;
    setteamTypeRadio(types);
  };

  const handleCloseModal = () => {
    setmodalVisible(false);
    initialValues = {};
    editData = {};
    setformState({});
  };

  useEffect(() => {
    settaskTypeArray(
      taskType?.filter((item: any) => {
        return item.mode === mode;
      })
    );
    if (mode == "lead" && opportunitiesList?.length == 0) {
      dispatch(fetchMasterTaskDropDown());
    }
  }, [mode]);
  useEffect(() => {
    if (taskDropDown.length > 0) {
      let temp;

      temp = taskDropDown?.filter((val: any) => {
        return val.name == "opportunity" && val.mode == "lead";
      });
      setOpportunitiesList(temp[0]?.values);
    }
  }, [taskDropDown]);
  useEffect(() => {
    if (typess === "edit") {
      settaskTypeArray(
        taskType?.filter((item: any) => {
          return item.mode === editData?.mode;
        })
      );

      dispatch(fetchTaskType({})).then((item: any) => {
        settaskTypeArray(
          item.payload.data.data?.filter((item: any) => {
            return item.mode === editData?.mode;
          })
        );
      });
    }
  }, [typess]);

  useEffect(() => {
    dispatch(fetchAllAgents({ limit: 15000 }));
  }, []);

  useEffect(() => {
    dispatch(fetchLabelList({}));
    dispatch(fetchTaskType({}));
    dispatch(fetchTeamTypes({ limit: 15000 }));
    dispatch(fetchTeamIds({ limit: 15000 }));
    setmode(typess === "edit" ? editData?.mode : "");
    // dispatch(fetchTaskSubType({}));
    // dispatch(fetchTaskSubTypeById({ id: '4d52bfcb-04f8-4394-9128-346607875601' }));
    // dispatch(fetchTaskCategory({id:record.id}));
  }, []);

  useEffect(() => {
    if (typess === "edit") {
      dispatch(fetchTaskType({}));
      dispatch(
        fetchTaskSubTypeById({
          id: editData && editData.type_id ? editData.type_id : "",
        })
      );
      // dispatch(fetchTaskCategory({id:editData && editData.sub_type_id ? editData.sub_type_id : ''}));
      editData && editData.sub_type_id
        ? dispatch(fetchTaskCategory({ id: editData.sub_type_id }))
        : dispatch(
          fetchTaskSubTypeById({
            id: editData && editData.type_id ? editData.type_id : "",
          })
        );
    }
  }, [typess]);

  return (
    <Modal
      title={typess === "edit" ? "Edit Assignment " : "Assignment Details"}
      centered
      visible={modalVisible}
      onCancel={() => handleCloseModal()}
      // width={1000}
      footer={null}
      className="modalFormButton"
      style={{ paddingBottom: "0px" }}
    >
      <Form
        className="paddingx20 paddingtop20 crm-form full-width"
        initialValues={initialValues}
        onFinish={typess === "edit" ? onEditContact : onSaveContact}
        onFinishFailed={onSaveContactFailed}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              name="mode"
              label="Mode"
              rules={[{ required: true, message: "Please choose Mode" }]}
            >
              <Select onChange={handleChangeMode}>

                {
                  (myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_TICKET) !== -1 ||
                    myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_TICKET?.split(".")[0]) !==
                    -1) ? <><Option value="ticket">Ticket</Option></> : ''
                }

                {
                  (myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1 ||
                    myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD?.split(".")[0]) !==
                    -1) ? <><Option value="lead">Lead</Option></> : ''
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item name="source_id" label="Source">
              {/* rules={[{ required: true, message: 'Please choose Source' }]} */}
              <Select showSearch={true} optionFilterProp="children" allowClear>
                {sourceOptions?.map((val: any) => {
                  return (
                    <Option value={val.id}>{val.name}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {mode !== "" && (
          <Row>
            <Col span={11}>
              <Form.Item
                name="type_id"
                label={
                  mode == "ticket"
                    ? t("CRM.Tickets.Fields.Ticket Type")
                    : "Service Type"
                }
                rules={[
                  {
                    required: true,
                    message:
                      "Please choose " +
                      (mode == "ticket"
                        ? t("CRM.Tickets.Fields.Ticket Type")
                        : "Service Type"),
                  },
                ]}
              >
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  allowClear
                  onChange={handleChangeTaskType}
                >
                  {taskTypeArray?.map((val: any) => {
                    return (
                      <Option value={val.id}>{val.name}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <Form.Item
                name="sub_type_id"
                label={
                  mode == "ticket"
                    ? t("CRM.Tickets.Fields.Ticket Sub Type")
                    : "Service Type"
                }
                rules={[
                  {
                    required: true,
                    message:
                      "Please choose " +
                      (mode == "ticket"
                        ? t("CRM.Tickets.Fields.Ticket Sub Type")
                        : "Service Type"),
                  },
                ]}
              >
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  allowClear
                  onChange={handleChangeTaskSubType}
                >
                  {taskSubTypeById?.map((val: any) => {
                    return (
                      <Option value={val.id}>{val.name}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            {mode == "ticket" && (
              <Form.Item
                name="category_id"
                label={t("CRM.Tickets.Fields.Ticket Category")}
              // rules={[
              //   {
              //     required: true,
              //     message:
              //       "Please choose " +
              //       t("CRM.Tickets.Fields.Ticket Category"),
              //   },
              // ]}
              >
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  allowClear
                >
                  {taskCategory?.map((val: any) => {
                    return (
                      <Option value={val.id}>{val.name}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            {mode == "lead" && false && (
              <Form.Item
                name="opportunities"
                label="Opportunities"
                rules={[
                  { required: true, message: "Please choose Opportunities" },
                ]}
              >
                <Select
                  showSearch={true}
                  mode="multiple"
                  optionFilterProp="children"
                  allowClear
                >
                  {opportunitiesList?.map((val: any) => {
                    return (
                      <Option value={val.id}>{val.name}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="Choose">
              <Radio.Group value={teamTypeRadio} onChange={onChangeTeamType}>
                {radioOptions?.map((val: any) => {
                  return (
                    <Radio value={val.id}>{val.name} </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            {/* {(() => {
  ...
                        })()} */}

            {(() => {
              if (teamTypeRadio === "teamType") {
                return (
                  <Form.Item name="assigned_team_types" label="Team Type">
                    <Select
                      showSearch={true}
                      optionFilterProp="children"
                      allowClear
                      mode="multiple"
                    >
                      {teamTypes?.map((val: any) => {
                        return (
                          <Option value={val.id}>
                            {val.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              } else if (teamTypeRadio === "teamName") {
                return (
                  <Form.Item name="assigned_teams" label="Team Name">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      allowClear
                    >
                      {teamIds?.map((val: any) => {
                        return (
                          <Option value={val.id}>
                            {val.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              } else if (teamTypeRadio === "users") {
                return (
                  <Form.Item name="assigned_users" label="Users">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      allowClear
                    >
                      {agentsAll?.map((val: any) => {
                        return (
                          <Option value={val.user_id}>
                            {val?.user?.first_name}{" "}
                            {val?.user?.last_name !== "null" &&
                              val?.user?.last_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              } else {
                return "";
              }
            })()}
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item name="priority_id" label="Priority">
              <Select defaultValue={editData?.priority_id} allowClear>
                {priorityDataValues?.map((val: any) => {
                  return (
                    <Option value={val.id}>{val.name}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item name="scope" label="Scope">
              <Input type="text" value="location" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item name="tat" label="SLA">
              <Input addonAfter="Minutes" placeholder="30" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="button-row">
          <Col span={24}>
            <Form.Item>
              <Button
                loading={loading}
                size="large"
                type="primary"
                htmlType="submit"
                block
              >
                {" "}
                {typess === "edit" ? `Update` : `Save`}{" "}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* <FormBar name={'CreateAssignmentForm'} loading={formLoading} onFinish={formElements.onFinish} onFinishFailed={formElements.onFinishFailed} formElements={formElements.formElements} initialValues={formElements.initialValues} buttonText={'Save'}></FormBar> */}
    </Modal>
  );
}

export default CreateAssignmentForm;
