import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  List,
  Modal,
  PageHeader,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  message,
} from "antd";
// import { FilterValue } from "antd/lib/table/interface";

import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { fetchAllAgents } from "../../../redux/reducers/agents.slice";
import { fetchBranches } from "../../../redux/reducers/branch.slice";
import {
  fetchMasterAllTaskDropdown,
  fetchMasterTaskDropDown,
  getCrmTypesData,
} from "../../../redux/reducers/master.slice";
// import { fetchAllData } from "../../../redux/reducers/metadata.slice";
import { useTranslation } from "react-i18next";
import {
  downloadTicketData,
  fetchTicketsList,
  fetchTicketsListCount,
  reset,
  setFilters,
  setSelectedStatusIds,
  setSorters,
  updateTicketBulk,
} from "../../../redux/reducers/tickets.slice";
import { MoreFilters } from "../../../shared/Utils/filters";
import {
  contactNameFormat,
  dobToAge,
  formatDate,
  formatDropDownValue,
  getAge,
  isUUID,
} from "../../../shared/Utils/utilities";
import {
  TASK_MODULE_ACTIONS_HIDE_AGE,
  TASK_MODULE_ACTIONS_HIDE_BRANCH,
  TASK_MODULE_ACTIONS_HIDE_GENDER,
  TASK_MODULE_ACTIONS_HIDE_SOURCE,
  TASK_MODULE_ACTIONS_HIDE_STATUS,
  TASK_MODULE_ACTIONS_HIDE_THB_ID,
  TASK_MODULE_ACTIONS_HIDE_UHID,
  TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION,
} from "../../../shared/components/permission.constants";
import {
  IS_JARVIS,
  RootState,
  TICKET_EXTERNAL_ID_VISIBLE,
  ticket_list_column_config,
} from "../../../shared/constants";
import FormBar from "../../../shared/layouts/formbar.layout";
import ResultModalLayout from "../../../shared/layouts/resultmodal.layout";
import SpinLoadingLayout from "../../../shared/layouts/spinloading.layout";
import TablePhoneNumberCopier from "../../../shared/layouts/table-phone-number-copier.layout";
import TableLayout from "../../../shared/layouts/table.layout";
import TabsLayout from "../../../shared/layouts/tabs.layout";
import { ROUTE_CRM_TICKET_DETAILS } from "../../../shared/routes/crm.routes.constants";
import CreateLeadForm from "../Leads/components/createleadform";
import HeaderButtons from "../components/headerbuttons";
import AddTicket from "./components/add-ticket";
const { RangePicker } = DatePicker;
type Props = {};
const CRMTicketListPage: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { connection } = useSelector((state: RootState) => state.chat);
  const { profile } = useSelector((state: RootState) => state.profile);
  const { tickets, loading, pagination, selectedStatusIds, filters, sorters } =
    useSelector((state: RootState) => state.tickets);
  const { agentsAll } = useSelector((state: RootState) => state.agents);
  const { branches } = useSelector((state: RootState) => state.branch);
  const { allTaskDropdown, taskDropDown } = useSelector(
    (state: RootState) => state.master
  );

  // const { allTaskData } = useSelector((state: RootState) => state.metadata);
  const [createTicket, setCreateTicket] = useState(false);
  const [ticketStatusList, setTicketStatusList] = useState([] as any);
  const [selectedStatusId, setSelectedStatusId] = useState("" as any);
  const [ticketsList, setTicketsList] = useState([] as any);
  const [emailModal, setEmailModal] = useState(false);
  const [priorityList, setPriorityList] = useState([] as any);
  const [taskTypesList, setTaskTypes] = useState([] as any);
  const [taskSubTypesList, setTaskSubTypes] = useState([] as any);
  const [taskCategoryList, setCategory] = useState([] as any);
  // const [allTaskCascade, setAllTaskCascade] = useState([] as any);
  // const [allTaskDropdownList, setAllTaskDropdown] = useState([] as any);
  const [downloadReportOptions, setdownloadReportOptions] = useState([] as any);
  const [downloadType, setdownloadType] = useState(null as any);
  const [isRefreshed, setisRefreshed] = useState(true);
  const [ticketCount, setticketCount] = useState(null as any);
  const [currentTabName, setcurrentTabName] = useState("OPEN");
  const [selectedRowKeys, setSelectedRowKeys] = useState([] as any);
  const [opportunitiesList, setOpportunitiesList] = useState(null as any);
  const [hasSelected, sethasSelected] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [linkLoaderMessage, setlinkLoaderMessage] = useState(null as any);
  const [successLoaderMessage, setsuccessLoaderMessage] = useState(null as any);
  const [followupdateFlag, setfollowupdateFlag] = useState(false);
  const [pageSizeCount, setpageSizeCount] = useState(10);
  const [sourceList, setsourceList] = useState(null as any);
  // const hasSelected = selectedRowKeys.length > 0;

  const [excludedMinutes, setexcludedMinutes] = useState(0);
  const [excludedHours, setexcludedHours] = useState(0);

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const [pageNumber, setpageNumber] = useState(
    pagination?.offset && pagination?.limit
      ? parseInt(pagination?.offset) / parseInt(pagination?.limit)
      : 0
  );

  useEffect(() => {
    const pagenumbers =
      parseInt(pagination?.offset) / parseInt(pagination?.limit);
    setpageNumber(pagenumbers);
  }, [pagination]);

  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const [downloadRangePickerValidation, setDownloadRangePickerValidation] =
    useState({ type: "", message: "" } as any);

  const downloadDataOnValuesChange = (_: any, v: any) => {
    if (_.date_range?.length === 2) {
      const start = _.date_range[0];
      const end = _.date_range[1];
      if (end.diff(start, "days") > 30) {
        setDownloadRangePickerValidation({
          type: "error",
          message: "Date range can be maximum 30 days",
        });
      } else {
        setDownloadRangePickerValidation({ type: "", message: "" });
      }
    }
  };

  const reasonList = [
    {
      name: "Doctor Not Available",
      value: "Doctor Not Available",
    },
    {
      name: "Met with same Doctor",
      value: "Met with same Doctor",
    },
    {
      name: "Met with some other Doctor",
      value: "Met with some other Doctor",
    },
    {
      name: "High Doctor Wait time",
      value: "High Doctor Wait time",
    },
  ];

  const handleDateChangeArray = (e: any) => {
    const hourStarter =
      moment().format("A") === "PM" && Number(moment().format("hh")) !== 12
        ? 12
        : 0;
    const selectedDate = e.format("YYYY-MM-DD");
    const selectedHours = Number(e.format("hh"));
    const currentDate = moment().format("YYYY-MM-DD");

    if (selectedDate > currentDate) {
      setexcludedHours(0);
      setexcludedMinutes(0);
    } else {
      setexcludedHours(hourStarter + Number(moment().format("hh")));
      setexcludedMinutes(Number(moment().format("mm")));

      if (Number(moment().format("hh")) !== 12) {
        if (selectedHours > Number(moment().format("hh"))) {
          setexcludedMinutes(0);
        } else {
          setexcludedMinutes(Number(moment().format("mm")));
        }
      } else {
        setexcludedMinutes(Number(moment().format("mm")));
        if (selectedHours === 12) {
          setexcludedMinutes(Number(moment().format("mm")));
        } else {
          setexcludedMinutes(0);
        }
      }
    }
  };

  // const [filters, setFilters] = useState(null as any);
  // const [sorters, setSorters] = useState({
  //   sort_column: "due_date",
  //   sort_val: null,
  // } as any);
  const [userList, setUserList] = useState(null as any);
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };

  const [filterValues, setfilterValues] = useState(null as any);
  const [bulkModal, setbulkModal] = useState(false);

  const showBulkModel = () => {
    setbulkModal(true);
  };

  useEffect(() => {
    const a =
      filters &&
      Object.entries(filters).filter(([key, value]) => value !== null);
    setfilterValues(a && a.length);
  }, [filters]);

  useEffect(() => {
    taskDropDown.length == 0 && dispatch(fetchMasterTaskDropDown());
    branches?.length == 0 && dispatch(fetchBranches({}));
    dispatch(fetchMasterAllTaskDropdown({ mode: "ticket" }));
    // dispatch(fetchAllData({ mode: "ticket" }));
    agentsAll?.length === 0 && dispatch(fetchAllAgents());
  }, []);

  useEffect(() => {
    const wsCurrent = connection;
    if (!wsCurrent) return;
    wsCurrent.onmessage = (e: any) => {
      const _message = JSON.parse(e.data);
      if (
        (_message.type === "ticket_created" ||
          _message.type === "ticket_updated") &&
        isRefreshed
      ) {
        updateRefresh();
        setisRefreshed(false);
        return;
      }
    };
  });

  const updateRefresh = () => {
    setTimeout(() => {
      getTicketList();
      dispatch(fetchTicketsListCount())
        .then((val: any) => {
          if (val?.payload?.data?.statusCode === 200) {
            setticketCount(val?.payload?.data?.data);
          }
        })
        .catch((ex: any) => console.error(ex));
      setisRefreshed(true);
    }, 10000);
  };

  useEffect(() => {
    dispatch(getCrmTypesData({ id: "ticket" })).then((val: any) => {
      setdownloadReportOptions(val.payload.data.data);
      setdownloadType(val.payload.data.data[0].key);
    });
  }, []);

  useEffect(() => {
    if (agentsAll.length > 0) {
      let unAssignedUser = {
        name: `${"Unassigned"}`,
        value: `${"unassigned"}`,
      };
      let agentLists = agentsAll.map((val: any) => {
        return {
          name: `${val?.user?.first_name && val?.user?.first_name !== "null"
            ? val?.user?.first_name
            : ""
            } ${val.user?.last_name && val.user?.last_name !== "null"
              ? val.user?.last_name
              : ""
            }`,
          value: val.user_id,
        };
      });
      setUserList([unAssignedUser, ...agentLists]);
    }
  }, [agentsAll]);
  // useEffect(() => {
  //   // console.log("allTaskData", allTaskData);
  //   if (allTaskData?.length > 0) {
  //     let allCascadeData = [];
  //     for (let k = 0; k < allTaskData?.length; ++k) {
  //       allCascadeData[k] = {
  //         value: allTaskData[k].id,
  //         label: formatDropDownValue(allTaskData[k].name),
  //         children: [] as any,
  //       };
  //       for (let l = 0; l < allTaskData[k]?.sub_type?.length; ++l) {
  //         allCascadeData[k].children[l] = {
  //           value: allTaskData[k].sub_type[l].id,
  //           label: formatDropDownValue(allTaskData[k].sub_type[l].name),
  //           children: [] as any,
  //         } as any;
  //         for (
  //           let m = 0;
  //           m < allTaskData[k]?.sub_type[l]?.category?.length;
  //           ++m
  //         ) {
  //           allCascadeData[k].children[l].children[m] = {
  //             value: allTaskData[k].sub_type[l].category[m].id,
  //             label: formatDropDownValue(
  //               allTaskData[k].sub_type[l].category[m].name
  //             ),
  //           } as any;
  //         }
  //       }
  //       // console.log(allCascadeData);
  //       setAllTaskCascade(allCascadeData);
  //     }
  //   }
  // }, [allTaskData]);
  useEffect(() => {
    // console.log("allTaskDropdown", allTaskDropdown);

    if (allTaskDropdown?.type?.length > 0) {
      setTaskTypes(
        allTaskDropdown?.type.map((val: any) => {
          return {
            name: formatDropDownValue(val.name),
            value: val.name,
            ids: val.id,
          };
        })
      );
    }
    if (allTaskDropdown?.sub_type?.length > 0) {
      setTaskSubTypes(
        allTaskDropdown?.sub_type.map((val: any) => {
          return {
            name: formatDropDownValue(val.name),
            value: val.name,
            ids: val.id,
          };
        })
      );
    }
    if (allTaskDropdown?.category?.length > 0) {
      setCategory(
        allTaskDropdown?.category.map((val: any) => {
          return {
            name: formatDropDownValue(val.name),
            value: val.name,
            ids: val.id,
          };
        })
      );
    }
  }, [allTaskDropdown]);

  useEffect(() => {
    if (taskDropDown.length > 0) {
      let temp = taskDropDown.filter((val: any) => {
        return val.name == "status" && val.mode == "ticket";
      });
      let vals = temp[0].values.map((val: any) => {
        return { name: val.name, value: val.id, key: val.name };
      });
      setTicketStatusList(vals);
      if (vals.length > 0) {
        if (selectedStatusIds) {
          setSelectedStatusId(vals[selectedStatusIds - 1]?.value);
        } else {
          setSelectedStatusId(vals[0]?.value);
        }
      }
      // if (vals.length > 0) setSelectedStatusId(vals[0].value);

      temp = taskDropDown.filter((val: any) => {
        return val.name == "priority" && val.mode == "ticket";
      });
      setPriorityList(
        temp[0].values.map((val: any) => {
          return { name: formatDropDownValue(val.name), value: val.id };
        })
      );
      temp = taskDropDown.filter((val: any) => {
        return val.name == "opportunity" && val.mode == "ticket";
      });
      setOpportunitiesList(temp[0]?.values);
      temp = taskDropDown?.filter((val: any) => {
        return val.name == "source" && val.mode == "ticket";
      });
      const newrrr = temp[0]?.values?.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      });
      const blanks = { name: "NOT DEFINED", value: "null" };
      setsourceList([blanks, ...newrrr]);
    }
  }, [taskDropDown, selectedStatusIds]);

  useEffect(() => {
    if (selectedStatusId && filters) {
      dispatch(fetchTicketsListCount())
        .then((val: any) => {
          if (val?.payload?.data?.statusCode === 200) {
            setticketCount(val?.payload?.data?.data);
          }
        })
        .catch((ex: any) => console.error(ex));
    }
  }, [selectedStatusId, filters]);

  useEffect(() => {
    getTicketList();
  }, [selectedStatusId, filters, sorters]);

  const processFilters = () => {
    let filter: any = filters
      ? Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != null)
      )
      : {};
    if (!filter.search_val) {
      delete filter.search_type;
    }

    Object.keys(filter).forEach((key: any) => {
      if (key === "type_id") {
        if (filter.type_id?.length > 0) {
          let type_id = [] as any;
          let temp = taskTypesList?.filter((val: any) => {
            return filter.type_id.indexOf(val.value) !== -1;
          });
          temp.forEach((val: any) => {
            return type_id.push(...val.ids);
          });
          filter.type_id = type_id;
        }
      } else if (key === "sub_type_id") {
        if (filter.sub_type_id?.length > 0) {
          let sub_type_id = [] as any;
          let temp = taskSubTypesList?.filter((val: any) => {
            return filter.sub_type_id.indexOf(val.value) !== -1;
          });
          temp.forEach((val: any) => {
            return sub_type_id.push(...val.ids);
          });
          filter.sub_type_id = sub_type_id;
        }
      } else if (key === "category_id") {
        if (filter.category_id?.length > 0) {
          let category_id = [] as any;
          let temp = taskCategoryList?.filter((val: any) => {
            return filter.category_id.indexOf(val.value) !== -1;
          });
          temp.forEach((val: any) => {
            return category_id.push(...val.ids);
          });
          filter.category_id = category_id;
        }
      }
      // if (key === "cascader_task") {
      //   if (filter.cascader_task?.length > 0) {
      //     // setFilters({...filters, task_id: [], sub_task_id: [], category_id : []})
      //     let type_id = [] as any;
      //     let sub_type_id = [] as any;
      //     let category_id = [] as any;
      //     for (let k = 0; k < filter.cascader_task?.length; ++k) {
      //       let temp = filter.cascader_task[k];
      //       temp[0] && type_id.push(temp[0]);
      //       temp[1] && sub_type_id.push(temp[1]);
      //       temp[2] && category_id.push(temp[2]);
      //     }
      //     filter.type_id = type_id;
      //     filter.sub_type_id = sub_type_id;
      //     filter.category_id = category_id;
      //     // let temp = taskCategoryList?.filter((val: any) => {
      //     //   return filter.category_id.indexOf(val.value) !== -1;
      //     // });
      //     // temp.forEach((val: any) => {
      //     //   return category_id.push(...val.ids);
      //     // });
      //     // filter.category_id = category_id;
      //   }
      //   delete filter.cascader_task;
      // }
    });
    return filter;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      // This function will be called every 10 seconds
      if (selectedStatusId) {
        getTicketList();
        await dispatch(fetchTicketsListCount())
          .then((val: any) => {
            if (val?.payload?.data?.statusCode === 200) {
              setticketCount(val?.payload?.data?.data);
            }
          })
          .catch((ex: any) => console.error(ex));
      }
    }, 25000); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(interval);
  }, [selectedStatusId, filters]);

  const getTicketList = (offsets: number = 0) => {
    const filter = processFilters();
    let sorter = sorters?.sort_val == null ? {} : sorters;
    // debugger
    selectedStatusId !== "" &&
      (currentTabName === "FOLLOW-UP"
        ? dispatch(
          fetchTicketsList({
            offset: pagination.offset,
            limit: pageSizeCount,
            ...(isUUID(selectedStatusId)
              ? { status_id: selectedStatusId }
              : {}),
            sort_column: "follow_up_date",
            sort_val: "ASC",
            ...filter,
            ...sorter,
          })
        )
        : dispatch(
          fetchTicketsList({
            offset: pagination.offset, // (offsets !== undefined || offsets !== null) ? offsets :
            limit: pageSizeCount,
            ...(isUUID(selectedStatusId)
              ? { status_id: selectedStatusId }
              : {}),
            ...filter,
            ...sorter,
          })
        ));
  };

  useEffect(() => {
    dispatch(fetchTicketsListCount())
      .then((val: any) => {
        if (val?.payload?.data?.statusCode === 200) {
          setticketCount(val?.payload?.data?.data);
        }
      })
      .catch((ex: any) => console.error(ex));
  }, []);

  useEffect(() => {
    if (!loading) setTicketsList(tickets);
  }, [tickets, loading]);

  const addTicket = (key: any) => {
    setCreateTicket(key);
  };

  useEffect(() => {
    const pagenumbers =
      parseInt(pagination?.offset) / parseInt(pagination?.limit);
    setpageNumber(pagenumbers);
  }, [pagination]);

  const onPageChange = (e: any, pageSize: any) => {
    setpageNumber(e - 1);
    setpageSizeCount(pageSize);
    const filter = processFilters();
    let sorter = sorters?.sort_val == null ? {} : sorters;
    selectedStatusId !== "" &&
      (currentTabName === "FOLLOW-UP"
        ? dispatch(
          fetchTicketsList({
            offset: (e - 1) * pageSize,
            limit: pageSize ? pageSize : pagination.limit,
            ...(isUUID(selectedStatusId)
              ? { status_id: selectedStatusId }
              : {}),
            sort_column: "follow_up_date",
            sort_val: "ASC",
            ...filter,
            ...sorter,
          })
        )
        : dispatch(
          fetchTicketsList({
            offset: (e - 1) * pageSize,
            limit: pageSize ? pageSize : pagination.limit,
            ...(isUUID(selectedStatusId)
              ? { status_id: selectedStatusId }
              : {}),
            ...filter,
            ...sorter,
          })
        ));
  };

  const getNameFromAgent = (id: any) => {
    const name = agentsAll?.find((val: any) => { return val.user_id === id })
    return `${name?.user?.first_name && name?.user?.first_name !== "null"
      ? name?.user?.first_name
      : ""
      } ${name?.user?.last_name && name?.user?.last_name !== "null"
        ? name?.user?.last_name
        : ""
      }`

  }

  const allColumns = [
    {
      title: t("CRM.Tickets.Fields.User Details"),
      dataIndex: "contact",
      key: "contact",
      width: 200,
      ...(IS_JARVIS ? {} : { fixed: "left" }),
      className: "capitalize",
      render: (key: any, record: any) => {
        return (
          <>
            {" "}
            <a
              className="bold-600"
              onClick={() => history.push(ROUTE_CRM_TICKET_DETAILS(record.id))}
            >
              {key?.first_name || key?.last_name ? (
                <>{`${key?.first_name || ""} ${key?.last_name || ""}`}</>
              ) : (
                "--"
              )}
              {myPermissions?.indexOf("task_module") !== -1 ||
                myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_AGE) !== -1 ||
                myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_GENDER) !== -1
                ? ""
                : (key?.dob || key?.birth_year) &&
                `(${key?.birth_year
                  ? getAge(key?.birth_year)
                  : dobToAge(key?.dob)
                }yrs, ${key?.gender?.name?.split("")[0].toUpperCase()})`}
            </a>

            {!IS_JARVIS && record?.display_id && (
              <>
                <br />
                <small>
                  {" "}
                  <strong>{"#" + record?.display_id}</strong>{" "}
                </small>
              </>
            )}
            {!IS_JARVIS && key?.uhid &&
              myPermissions?.indexOf("task_module") == -1 &&
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_UHID) == -1 && (
                <>
                  <br />
                  <small>
                    {t("CRM.Common.Fields.UHID")}: {key?.uhid}
                  </small>
                </>
              )}
            {!IS_JARVIS && key?.thb_id &&
              myPermissions?.indexOf("task_module") == -1 &&
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_THB_ID) == -1 && (
                <>
                  <br />
                  <small>THB ID: {key?.thb_id}</small>
                </>
              )}
            <br />
            {!IS_JARVIS && key?.phone && (
              <small>
                Ph:{" "}
                <TablePhoneNumberCopier
                  phone={"+" + key?.country_code + key?.phone}
                ></TablePhoneNumberCopier>
              </small>
            )}
            {/* {
              key?.whatsapp_phone &&
              <small>
                <br />
                Ph (WhatsApp): {"+" + key?.country_code + key?.whatsapp_phone}
              </small>
            } */}
            {/* {
              key?.thb_id &&
              <small>
                <br />
                THB ID: {key?.thb_id}
              </small>
            } */}
          </>
        );
      },
    },
    ...(IS_JARVIS ? [{
      title: "Title",
      render: (key: any) => {
        return (key?.title);
      },
      key: "title",
      width: 150,
      className: "capitalize",
    }] : []),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH) !== -1
      ? []
      : [
        {
          title: t("CRM.Common.Fields.Hospital"),
          dataIndex: ["branch", "name"],
          key: "branch",
          width: 120,
          className: "capitalize",
        },
      ]),
    {
      title: t("CRM.Tickets.Fields.Ticket Type"),
      render: (key: any) => {
        return formatDropDownValue(key?.type?.name);
      },
      key: "ticket_type",
      width: 150,
      className: "capitalize",
    },
    {
      title: t("CRM.Tickets.Fields.Ticket Sub Type"),
      render: (key: any) => {
        return formatDropDownValue(key?.sub_type?.name);
      },
      key: "ticket_subtype",
      width: 150,
      className: "capitalize",
    },
    {
      title: t("CRM.Tickets.Fields.Ticket Category"),
      render: (key: any) => {
        return formatDropDownValue(key?.category?.name);
      },
      key: "category",
      width: 150,
      className: "capitalize",
    },
    ...(TICKET_EXTERNAL_ID_VISIBLE === true
      ? [
        {
          title: "External Id",
          render: (key: any) => {
            return formatDropDownValue(key?.external_id);
          },
          key: "external_id",
          width: 150,
          // className: "capitalize",
        },
      ]
      : []),
    {
      title: t("CRM.Tickets.Fields.Assignee"),
      width: 120,
      key: "assignee",
      dataIndex: "assigned_user",
      className: "capitalize",
      render: (key: any) => {
        return (
          <>
            {key?.first_name ? key?.first_name : ""}{" "}
            {key?.last_name && key?.last_name !== "null" ? key?.last_name : ""}
          </>
        );
      },
    },
    ...(IS_JARVIS ? [{
      title: "Reportee",
      width: 120,
      key: "reportee",
      // dataIndex: "assigned_user",
      className: "capitalize",
      render: (key: any) => {
        return getNameFromAgent(key?.updated_by_id)
      },
    },

    ] : []),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_STATUS) !== -1 ? [] : [{
        title: t("CRM.Tickets.Fields.Status"),
        width: 180,
        key: 'status',
        render: (a: any) => {
          return a?.status?.name !== "follow-up" ? (
            <Tooltip
              placement="right"
              title={formatDropDownValue(a?.status?.name)}
            >
              <Tag
                style={{
                  maxWidth: "100px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {formatDropDownValue(a?.status?.name)}
              </Tag>
            </Tooltip>
          ) : (
            <>
              <Tag>{formatDropDownValue(a?.status?.name)}</Tag> <br />
              <small>
                {a?.follow_up_date
                  ? `${formatDate(a?.follow_up_date, "DD MMM'YY")} ${a?.follow_up_time ? a?.follow_up_time : ""
                  }`
                  : ""}
              </small>{" "}
            </>
          );
        },
        className: "capitalize",
      }]),
    {
      title: t("CRM.Tickets.Fields.Created at"),
      dataIndex: "created_at",
      key: "created_at",
      className: "capitalize",
      width: 180,
      render: (key: any) => {
        return formatDate(key);
      },
      sorter: true,
      ...(sorters?.sort_column === "created_at"
        ? {
          defaultSortOrder:
            sorters?.sort_val === "asc"
              ? "ascend"
              : sorters?.sort_val === "desc"
                ? "descend"
                : false,
        }
        : {}),
      onHeaderCell: (column: any) => {
        return {
          onClick: (e: any) => {
            dispatch(
              setSorters({
                sort_column: "created_at",
                sort_val: getSortDir(sorters.sort_val),
              })
            );
          },
        };
      },
    },
    {
      title: t("CRM.Tickets.Fields.Due Date"),
      width: 180,
      key: "due_date",
      className: "capitalize",
      ...(sorters?.sort_column === "due_date"
        ? {
          defaultSortOrder:
            sorters?.sort_val === "asc"
              ? "ascend"
              : sorters?.sort_val === "desc"
                ? "descend"
                : false,
        }
        : {}),
      render: (a: any) => {
        return a.due_date ? (
          <>
            {formatDate(a.due_date)}{" "}
            {moment(a.due_date).isBefore(moment()) &&
              a?.status?.name !== "closed" && a?.status?.name !== "completed" &&
              a?.status?.name !== "expired" && !IS_JARVIS ? (
              <>
                <br />
                <Tag color="red" className="solid-full">
                  Overdue
                </Tag>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          "--"
        );
      },
      sorter: true,
      onHeaderCell: (column: any) => {
        return {
          onClick: (e: any) => {
            dispatch(
              setSorters({
                sort_column: "due_date",
                sort_val: getSortDir(sorters.sort_val),
              })
            );
          },
        };
      },
    },

    {
      title: t("CRM.Tickets.Fields.Source"),
      key: "source",
      width: 150,
      className: "capitalize",
      render: (key: any) => {
        return formatDropDownValue(key?.source?.name);
      },
    },
    {
      title: t("CRM.Tickets.Fields.Priority"),
      fixed: "right",
      key: "priority",
      width: 100,
      dataIndex: ["priority", "name"],
      render: (key: any) => {
        return <Tag color={"blue"}>{formatDropDownValue(key)}</Tag>;
      },
    },

  ];
  if (currentTabName === "FOLLOW-UP") {
    allColumns.splice(9, 0, {
      title: "Follow up Date",
      dataIndex: "follow_up_date",
      key: "follow_up_date",
      className: "capitalize",
      width: 150,
      render: (key: any) => {
        return key ? formatDate(key) : "";
      },
      sorter: true,
      ...(sorters?.sort_column === "follow_up_date"
        ? {
          defaultSortOrder:
            sorters?.sort_val === "asc"
              ? "ascend"
              : sorters?.sort_val === "desc"
                ? "descend"
                : false,
        }
        : {}),
      onHeaderCell: (column: any) => {
        return {
          onClick: (e: any) => {
            dispatch(
              setSorters({
                sort_column: "follow_up_date",
                sort_val: getSortDir(sorters.sort_val),
              })
            );
          },
        };
      },
    });
  }

  let columns = [] as any;
  let keys = Object.keys(ticket_list_column_config);
  columns = allColumns.filter((col: any) => keys.indexOf(col.key) !== -1).map((col: any) => {
    return { ...col, idx: ticket_list_column_config[col.key] }
  })
  columns.sort((a: any, b: any) => a.idx - b.idx);
  // var allTabs = new Array || []
  function getSortDir(currentSort: any) {
    return currentSort == null ? "asc" : currentSort == "asc" ? "desc" : null;
  }
  const onTabChange = (e: any) => {
    // debugger
    dispatch(setSelectedStatusIds(e));
    setSelectedStatusId(ticketStatusList[e - 1].value);
    setcurrentTabName(ticketStatusList[e - 1]?.name);
    resetSelectedRows();
    // if (ticketStatusList[e - 1]?.name === "FOLLOW-UP") {
    //   setfollowupdateFlag(true);
    // }
    if (ticketStatusList[e - 1]?.name !== currentTabName) {
      dispatch(reset());
    }
  };

  const [leadFormVisible, setLeadFormVisible] = useState(false);
  // function filterFormSubmit(changed: any, all: any) {
  //   let filtersObjOld = JSON.parse(JSON.stringify(filters));
  //   let filtersObj = {} as any;
  //   for (let key of Object.keys(all)) {
  //     if (all[key] === "" || all[key] === undefined) {
  //     } else {
  //       if (key == "range") {
  //         filtersObj.from_date = all.range
  //           ? all.range[0].format("YYYY-MM-DD")
  //           : null;
  //         filtersObj.to_date = all.range
  //           ? all.range[1].format("YYYY-MM-DD")
  //           : null;
  //       } else {
  //         filtersObj[key] = all[key];
  //       }
  //     }
  //   }
  //   const news = { ...filtersObjOld, ...filtersObj };

  //   dispatch(setFilters(filtersObj));
  // }

  function filterFormSubmit(changed: any, all: any) {
    let filtersObjOld = JSON.parse(JSON.stringify(filters));
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] === "" || all[key] === undefined) {
      } else {
        if (key == "range") {
          filtersObj.from_date = all.range
            ? all.range[0].format("YYYY-MM-DD")
            : null;
          filtersObj.to_date = all.range
            ? all.range[1].format("YYYY-MM-DD")
            : null;
        } else if (
          changed.search_type === "contact" ||
          changed.search_type === "display_id"
        ) {
          filtersObj["search_val"] = null;
          filtersObj["search_type"] = changed.search_type;
          // settingSearchType(changed?.search_type)
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    const news = { ...filtersObjOld, ...filtersObj };

    dispatch(setFilters(filtersObj));
  }

  const onShowSizeChange = (current: any, pageSize: any) => {
    // alert("current");
  };

  function resetFilters() {
    let filtersObj = JSON.parse(JSON.stringify(filters));
    if (filtersObj) {
      for (let key of Object.keys(filtersObj)) {
        if (key !== "search_type") {
          filtersObj[key] = null;
        }
        if (key === "search_type") {
          filtersObj[key] = "contact";
        }
        if (
          key === "assigned_user_id" ||
          key === "assigned_team_id" ||
          key === "type_id" ||
          key === "sub_type_id" ||
          key === " category_id"
        ) {
          filtersObj[key] = [] as any;
          // delete filtersObj[key]
        }
      }
      dispatch(setFilters(filtersObj));
    }
  }

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
    // columnTitle: headerCheckbox
  };

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      sethasSelected(true);
    } else {
      sethasSelected(false);
    }
  }, [selectedRowKeys]);

  const fetchCallDet = async () => {
    setResultModal(false);
    setsuccessLoaderMessage(null);
  };

  const updateBulk = (values: any) => {
    setLoadingModal(true);
    setbulkModal(false);
    setlinkLoaderMessage(`Updating...`);
    values.ids = selectedRowKeys;
    if (values.follow_up_date) {
      values.follow_up_time = values.follow_up_date.format("LT");
    }
    dispatch(updateTicketBulk(values))
      .then((val: any) => {
        if (val?.payload?.data?.statusCode === 200) {
          sethasSelected(false);
          dispatch(fetchTicketsListCount())
            .then((val: any) => {
              if (val?.payload?.data?.statusCode === 200) {
                setticketCount(val?.payload?.data?.data);
              }
            })
            .catch((ex: any) => console.error(ex));
          getTicketList();
          setLoadingModal(false);
          setResultModal(true);
          setsuccessLoaderMessage(
            `${selectedRowKeys.length} Tickets updated successfully.`
          );
          setSelectedRowKeys([]);
        } else {
          sethasSelected(false);
          dispatch(fetchTicketsListCount())
            .then((val: any) => {
              if (val?.payload?.data?.statusCode === 200) {
                setticketCount(val?.payload?.data?.data);
              }
            })
            .catch((ex: any) => console.error(ex));
          getTicketList();
          setLoadingModal(false);
          setlinkLoaderMessage(null);
          setSelectedRowKeys([]);
        }
      })
      .catch((ex: any) => console.error("ssssssssss", ex));
  };

  const resetSelectedRows = () => {
    setSelectedRowKeys([]);
    sethasSelected(false);
  };

  const onValueChangeBulkForm = (val: any) => {
    if (val.status_id) {
      const statusName = ticketStatusList?.filter((value: any) => {
        return val.status_id === value.value;
      })[0]?.name;
      if (statusName === "FOLLOW-UP") {
        setfollowupdateFlag(true);
      } else {
        setfollowupdateFlag(false);
      }
    }
  };

  const formElementsBulk = {
    onFinish: (e: any) => {
      updateBulk(e);
    },
    onFinishFailed: () => { },
    onValuesChange: (e: any) => {
      onValueChangeBulkForm(e);
    },
    initialValues: {
      // status_id: ticketStatusList?.filter((val: any) => {
      //   return val.name === currentTabName;
      // })[0]?.value,
    },
    formElements: [
      {
        label: "Assignee",
        type: "Select",
        name: "assigned_user_id",
        colSpan: 12,
        allowClear: true,
        placeholder: "Choose Agent",
        options: userList,
        // colSpanResponsive: { xs: 24, sm: 24, md: 6, lg: 6, xl: 6 },
      },
      {
        label: "Priority",
        type: "Select",
        name: "priority_id",
        colSpan: 12,
        allowClear: true,
        placeholder: "Choose Priority",
        options: priorityList,
        // filterType: "moreFilter",
      },
      {
        label: "Due Date",
        type: "DateTimePicker",
        name: "due_date",
        allowClear: true,
        placeholder: "Choose Date",
        colSpan: 12,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
        // colSpanResponsive: { xs: 14, sm: 14, md: 7, lg: 7, xl: 7 },
      },

      // {
      //   type: "Select",
      //   label: "Reason",
      //   name: "reason",
      //   colSpan: 12,
      //   allowClear: true,
      //   placeholder: "Choose Reason",
      //   options: reasonList,
      // },

      {
        label: "Status",
        type: "Select",
        name: "status_id",
        colSpan: 12,
        // allowClear: true,
        placeholder: "Choose Status",
        options: ticketStatusList,
      },

      {
        label: "Follow up Date",
        type: followupdateFlag ? "DateTimePicker" : "hidden",
        name: "follow_up_date",
        allowClear: true,
        rules: [
          {
            required: followupdateFlag ? true : false,
            message: "Follow up date Required",
          },
        ],
        placeholder: "Choose Date",
        colSpan: 12,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
        // colSpanResponsive: { xs: 14, sm: 14, md: 7, lg: 7, xl: 7 },
      },
    ],
  };

  const options = [
    {
      value: "contact",
      label: `${t("CRM.Contacts.contact")}`,
    },
    {
      value: "display_id",
      label: `${t("CRM.Tickets.Fields.Ticket")} Id`,
    },
  ];

  const prefixSelector = (
    <Form.Item name="search_type" noStyle>
      <Select
        // showSearch
        style={{ width: 120 }}
        defaultValue={"contact"}
      >
        {options?.map((item) => {
          return (
            <Select.Option value={item.value}>
              <Space>
                <span>{item.label}</span>
              </Space>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );



  useEffect(() => {
    if (
      !filters?.from_date &&
      !filters?.to_date &&
      filters?.from_date !== null &&
      filters?.to_date !== null
    ) {
      dispatch(
        setFilters({
          ...filters,
          from_date: moment().subtract(4, "days").format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
          search_type: "contact",
        })
      );
    }
  }, []);




  const formElements = {
    onFinish: () => { },
    onFinishFailed: () => { },
    onValuesChange: filterFormSubmit,
    filtersApplied: filters
      ? Object.keys(
        Object.fromEntries(
          Object.entries(filters).filter(([_, v]) => v != null && v != false)
        )
      ).length
      : 0,
    initialValues: {
      search_val: filters?.search_val,
      search_type: "contact",
    },

    setValues: {
      ...(filters
        ? Object.fromEntries(
          Object.entries(filters).filter(([_, v]) => _ != "search_val")
        )
        : {}),
      range:
        filters?.from_date && filters?.to_date
          ? [moment(filters?.from_date), moment(filters?.to_date)]
          : null,
    },
    formElements: [
      {
        label: "",
        type:
          filters?.search_type === "contact" ||
            !filters ||
            !filters?.search_type
            ? "Input"
            : "InputNumber",
        name: "search_val",
        colSpan: 9,
        placeholder:
          filters?.search_type === "contact" ||
            !filters ||
            !filters?.search_type
            ? "Search by Name, Phone Number"
            : `Search by ${t("CRM.Tickets.Fields.Ticket")} id`,
        colSpanResponsive: { xs: 24, sm: 24, md: 9, lg: 9, xl: 9 },
        // prefix: <SearchOutlineIcon width={"13"} height={"13"} />,
        addonBefore: prefixSelector,
        allowClear: true,
      },
      {
        label: "Agents",
        type: "Select",
        name: "assigned_user_id",
        colSpan: 12,
        allowClear: true,
        mode: "multiple",
        placeholder: "Agents",
        options: userList,
        filterType: "moreFilter",
        // mode: "multiple",
      },
      ...(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH) !== -1
        ? []
        : [
          {
            label: `${t("CRM.Common.Fields.Branch")}`,
            type: "Select",
            name: "branch_id",
            colSpan: 4,
            mode: "multiple",
            allowClear: true,
            filterType: "moreFilter",
            placeholder: `${t("CRM.Common.Fields.Branch")}`,
            options: branches.map((val: any) => {
              return { name: formatDropDownValue(val.name), value: val.id };
            }),
          },
        ]),
      // {
      //   label: "Type/Subtype/Category",
      //   type: "Cascader",
      //   name: "cascader_task",
      //   colSpan: 4,
      //   allowClear: true,
      //   filterType: "moreFilter",
      //   placeholder: "Select Multiple",
      //   data: allTaskCascade,
      //   multiple: true,
      //   // mode: "multiple",
      // },
      {
        type: "Select",
        label: t("CRM.Tickets.Fields.Ticket Type"),
        // ...(screenType.mobile ? { label: "Type" } : {}),
        name: "type_id",
        colSpan: 4,
        allowClear: true,
        placeholder: t("CRM.Tickets.Fields.Ticket Type"),
        options: taskTypesList,
        mode: "multiple",
        filterType: "moreFilter",
        // filterType: screenType.mobile ? "moreFilter" : "",
      },
      {
        label: t("CRM.Tickets.Fields.Ticket Sub Type"),
        type: "Select",
        name: "sub_type_id",
        colSpan: 4,
        allowClear: true,
        placeholder: t("CRM.Tickets.Fields.Ticket Sub Type"),
        options: taskSubTypesList,
        filterType: "moreFilter",
        mode: "multiple",
      },
      {
        label: t("CRM.Tickets.Fields.Ticket Category"),
        type: "Select",
        name: "category_id",
        colSpan: 4,
        allowClear: true,
        placeholder: t("CRM.Tickets.Fields.Ticket Category"),
        options: taskCategoryList,
        filterType: "moreFilter",
        mode: "multiple",
      },
      {
        label: "Priority",
        type: "Select",
        name: "priority_id",
        colSpan: 3,
        allowClear: true,
        placeholder: "Priority",
        options: priorityList,
        mode: "multiple",
        filterType: "moreFilter",
        // mode: "multiple",
      },
      ...(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_SOURCE) !== -1 ? [] : [{
          label: "Source",
          type: "Select",
          name: "source_id",
          colSpan: 3,
          allowClear: true,
          placeholder: "Source",
          options: sourceList,
          filterType: "moreFilter",
          // mode: "multiple",
        },]),

      ...(TICKET_EXTERNAL_ID_VISIBLE === true ? [{
        label: "External Id",
        type: "Input",
        name: "external_id",
        colSpan: 3,
        allowClear: true,
        placeholder: "External Id",
        filterType: "moreFilter",
      },] : []),

      {
        label: "",
        type: "RangePicker",
        name: "range",
        colSpan: 7,
        colSpanResponsive: { xs: 14, sm: 14, md: 7, lg: 7, xl: 7 },
      },
      {
        label: "",
        type: "clear_filters",
        onClick: resetFilters,
        filterType: "moreFilter",
      },
      {
        label: "",
        type: "Button",
        name: (
          <>
            <DownloadOutlined />
          </>
        ),
        colSpan: 4,
        flex: "none",
      },
    ],
  };
  const onClickDownload = () => {
    setEmailModal(true);
  };
  if (screenType.mobile) {
    formElements.formElements = formElements.formElements.slice(0, -1);
  }
  const mobileFilters =
    screenType.mobile && formElements
      ? MoreFilters(
        onPageChange,
        pagination.total,
        formElements,
        onClickDownload,
        "multiple",
        pageNumber + 1,
        true
      )
      : null;
  const onChangeTable = () => { };
  // const onChangeTable = (
  //   _pagination: TablePaginationConfig,
  //   filters: Record<string, FilterValue>,
  //   _sorter: any
  // ) => {
  //   console.log("Filters happened", _pagination, filters, _sorter);
  //   if (filters["2"]?.length > 0) {
  //     setFilters({ ...filters, type_id: filters["2"] });
  //   }
  //   if (filters["3"]?.length > 0) {
  //     setFilters({ ...filters, sub_type_id: filters["3"] });
  //   }
  //   if (filters["4"]?.length > 0) {
  //     setFilters({ ...filters, category_id: filters["4"] });
  //   }
  // };
  var allTabs = ticketStatusList.map((val: any) => {
    return {
      title: (
        <span>
          {formatDropDownValue(val.name)}{" "}
          <span>
            {filterValues && filterValues > 0 ? (
              <span>
                {val.name === currentTabName && `(${pagination.total})`}
              </span>
            ) : (
              <span>
                {ticketCount
                  ? ticketCount[val.key]
                    ? `(${ticketCount[val.key]})`
                    : ""
                  : ""}
              </span>
            )}
          </span>
        </span>
      ),
      key: val.key,
      component: (
        <>
          {screenType.mobile ? (
            <>
              <Row>
                <Col span={24} className="bg-white">
                  {formElements && (
                    <div className="crm-mobile-filters">{mobileFilters}</div>
                  )}
                  {!loading && (
                    <>
                      <List
                        className="crm-mobile-list padding-bottom-20 paddingx20"
                        itemLayout="vertical"
                        dataSource={ticketsList}
                        renderItem={(item: any, index: number) => (
                          <List.Item key={index}>
                            <Row>
                              <Col span={24}>
                                <Row>
                                  <Col style={{ width: "calc(100% - 65px)" }}>
                                    <a
                                      onClick={() =>
                                        history.push(
                                          ROUTE_CRM_TICKET_DETAILS(item.id)
                                        )
                                      }
                                      className="capitalize font16"
                                    >
                                      {(item?.contact?.first_name ||
                                        item?.contact?.last_name) ? contactNameFormat(
                                          item?.contact?.first_name,
                                          item?.contact?.last_name,
                                          item?.contact?.gender?.name,
                                          item?.contact?.dob
                                        ) : "--"}
                                    </a>
                                  </Col>
                                  <Col
                                    style={{ width: "65px" }}
                                    className="text-right"
                                  >
                                    <Tag color={"blue"}>
                                      {formatDropDownValue(
                                        item?.priority?.name
                                      )}
                                    </Tag>
                                  </Col>
                                </Row>
                                Ph:{" "}
                                {
                                  item?.contact?.phone ? <TablePhoneNumberCopier
                                    phone={
                                      "+" +
                                      item?.contact?.country_code +
                                      item?.contact?.phone
                                    }
                                  ></TablePhoneNumberCopier> : "--"
                                }

                                <Divider type="vertical" />
                                <span>
                                  <small>
                                    {" "}
                                    <strong>{"#" + item?.display_id}</strong>{" "}
                                  </small>
                                </span>
                                <br />
                                <span>
                                  {formatDropDownValue(item.type?.name)}{" "}
                                  <Divider type="vertical" /> Assignee:{" "}
                                  {item.assigned_user
                                    ? `${item.assigned_user?.first_name
                                      ? item.assigned_user?.first_name
                                      : ""
                                    } 
                                  ${item.assigned_user?.last_name &&
                                      item.assigned_user?.last_name !== "null"
                                      ? item.assigned_user?.last_name
                                      : ""
                                    }`
                                    : "-"}
                                </span>
                                <Row>
                                  <Col flex={"auto"}>
                                    <span>
                                      Created at: {formatDate(item.created_at)}
                                    </span>
                                  </Col>
                                  {moment(item?.due_date).isBefore(moment()) &&
                                    item.status.name !== "closed" &&
                                    item.status.name !== "expired" ? (
                                    <Col style={{ width: "65px" }}>
                                      <Tag color="red" className="solid-full">
                                        Overdue
                                      </Tag>
                                    </Col>
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    </>
                  )}
                  {loading && (
                    <>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                    </>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="padding10">
                <Col span={24}>
                  <span>
                    {hasSelected ? (
                      <>
                        <span
                          style={{ marginLeft: 8 }}
                          className="primary-color-text bold font16"
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={resetSelectedRows}
                          >
                            <CloseOutlined />
                          </span>
                          <span>
                            {" "}
                            {selectedRowKeys.length} Tickets Selected
                          </span>
                        </span>
                        <Button
                          type="primary"
                          style={{ float: "right" }}
                          onClick={showBulkModel}
                        >
                          Bulk Update
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </span>

                  <TableLayout
                    rowSelection={rowSelection}
                    pageType="withTab"
                    buttonEvt={() => onClickDownload()}
                    defaultCurrentPage={pageNumber + 1}
                    loading={loading}
                    filters="multiple"
                    dataSource={ticketsList}
                    columns={columns}
                    onChange={(e, ee) => onPageChange(e, ee)}
                    type="morefilters"
                    total={pagination.total}
                    showTotal={true}
                    onShowSizeChange={(a: any, b: any) =>
                      onShowSizeChange(a, b)
                    }
                    pagination={{
                      position: ["none", "none"],
                      pageSize: pagination?.limit,
                    }}
                    formData={formElements}
                    maxWidth={1500}
                    defaultPageSize={pageSizeCount}
                    showSizeChangerFlag={true}
                    onChangeTable={onChangeTable}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      ),
    };
  });

  async function sendMailList(data: any) {
    if (
      downloadRangePickerValidation?.type === "error" &&
      myPermissions?.indexOf("task_module") === -1 &&
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION) === -1
    ) {
      return;
    }
    const filter = processFilters();
    let params: any = {};
    const start = data.date_range[0];
    const end = data.date_range[1];
    params.from_date = start.format("YYYY-MM-DD");
    params.to_date = end.format("YYYY-MM-DD");
    params.email = data?.email;
    params.type = downloadType;
    let param = params
      ? Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null))
      : {};
    await dispatch(downloadTicketData({ ...param, ...filter })).then(
      (val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          message.success("Report sent to your email.");
          setEmailModal(false);
          setdownloadType(downloadReportOptions[0].key);
        }
      }
    );
  }

  const changeDownloadType = (e: any) => {
    setdownloadType(e.target.value);
  };

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title={
          <Row
            style={screenType.mobile ? { paddingLeft: "25px" } : {}}
            align="middle"
          >
            <Col flex="none">All {t("CRM.Tickets.Fields.Tickets")}</Col>
            <Col>
              {!screenType.mobile && ticketCount?.follow_up_pending > 0 ? (
                <span className="follow-up-pending-header">
                  Follow-Up Pending: <Tag>{ticketCount.follow_up_pending}</Tag>{" "}
                  Tickets
                </span>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        }
        extra={
          <HeaderButtons
            setLeadFormVisible={setLeadFormVisible}
            setTicketFormVisible={addTicket}
          />
        }
      ></PageHeader>
      <Row className="bg-white">
        <Col span={24} className="crm-tabs">
          <TabsLayout
            activeTab={selectedStatusIds}
            AllTabs={allTabs}
            onChange={onTabChange}
          ></TabsLayout>
        </Col>
      </Row>
      {leadFormVisible && (
        <CreateLeadForm
          saveLeadSuccess={() => setLeadFormVisible(false)}
          setLeadFormVisible={(val: any) => setLeadFormVisible(val)}
          leadFormVisible={leadFormVisible}
        ></CreateLeadForm>
      )}
      {createTicket && (
        <AddTicket
          onTicketSave={addTicket}
          setTicketFormVisible={(val: any) => setCreateTicket(val)}
          ticketFormVisible={createTicket}
          phoneNumber={""}
          defaultContactId={""}
        ></AddTicket>
      )}
      {emailModal && (
        <Modal
          centered
          visible={emailModal}
          onCancel={() => setEmailModal(false)}
          title="Send Ticket List in Email"
          footer={null}
        >
          <Form
            onFinish={sendMailList}
            onValuesChange={downloadDataOnValuesChange}
            className="crm-form"
            initialValues={{ email: profile?.email }}
          >
            <Form.Item name="type" label="Type">
              {/* <Select
                defaultValue={downloadReportOptions[0]}
                onChange={changeDownloadType}
              >
                {
                  downloadReportOptions.map((item: any, index: any) => {
                    return (
                      <Option value={item.key}>{item.name}</Option>
                    )
                  })
                }
              </Select> */}

              <Radio.Group
                onChange={changeDownloadType}
                defaultValue={downloadReportOptions[0].key}
              >
                <Space direction="vertical">
                  {downloadReportOptions.map((item: any, index: any) => {
                    return <Radio value={item.key}>{item.name}</Radio>;
                  })}
                </Space>
              </Radio.Group>
            </Form.Item>
            {myPermissions?.indexOf("task_module") === -1 &&
              myPermissions?.indexOf(
                TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION
              ) === -1 ? (
              <Form.Item
                name="date_range"
                label="Date Range"
                {...(downloadRangePickerValidation?.type === "error"
                  ? {
                    validateStatus: "error",
                    help: downloadRangePickerValidation?.message,
                  }
                  : {})}
                rules={[
                  {
                    required: true,
                    message: "Please select a valid date range.",
                  },
                ]}
              >
                <RangePicker
                  disabledDate={(d: any) => {
                    return d > moment();
                  }}
                  style={{ width: "100%" }}
                  format="DD MMM'YY"
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="date_range"
                label="Date Range"
                rules={[
                  {
                    required: true,
                    //  message: "Please select a valid date range.",
                  },
                ]}
              >
                <RangePicker
                  disabledDate={(d: any) => {
                    return d > moment();
                  }}
                  style={{ width: "100%" }}
                  format="DD MMM'YY"
                />
              </Form.Item>
            )}

            <Form.Item
              name="email"
              label="Email"
              rules={[
                { type: "email", message: "Please enter a valid email Id." },
                { required: true, message: "Please enter an email Id." },
              ]}
            >
              <Input placeholder="Enter email Id" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {bulkModal && (
        <Modal
          title={`Bulk Update (${selectedRowKeys.length} Tickets Selected)`}
          centered
          visible={bulkModal}
          onCancel={() => setbulkModal(false)}
          width={700}
          footer={false}
          className="modalFormButton form-modal"
          style={{ paddingBottom: "0px" }}
        >
          <FormBar
            onDateTimeChange={handleDateChangeArray}
            name={"updateTicket"}
            onFinish={formElementsBulk.onFinish}
            onFinishFailed={formElementsBulk.onFinishFailed}
            onValuesChange={formElementsBulk.onValuesChange}
            formElements={formElementsBulk.formElements}
            initialValues={formElementsBulk.initialValues}
            buttonText={"Update"}
            disabledTimeValues={() => {
              return {
                disabledHours: () => range(0, 24).splice(0, excludedHours),
                disabledMinutes: () => range(0, excludedMinutes),
              };
            }}
          ></FormBar>
        </Modal>
      )}
      {loadingModal && (
        <SpinLoadingLayout
          modalVisible={loadingModal}
          message={linkLoaderMessage}
        ></SpinLoadingLayout>
      )}
      {resultModal && (
        <ResultModalLayout
          type="success"
          modalVisible={resultModal}
          setModalVisible={(data) => fetchCallDet()}
          title={successLoaderMessage}
        // subtitle="Call log status updated to closed"
        ></ResultModalLayout>
      )}
    </Fragment>
  );
};

export default CRMTicketListPage;
