import { CalendarOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Modal,
  PageHeader,
  Row,
  Skeleton,
  Tabs,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchAppointmentById,
  resetAppointments,
} from "../../../redux/reducers/appointment.slice";
import {
  clearLeadDetails,
  fetchLeadDetails,
  getLeadServiceMap,
  getPatientAddress,
} from "../../../redux/reducers/leads.slice";
import { fetchOrderDetailsByID } from "../../../redux/reducers/pharmacy.slice";
import { fetchPrescriptions } from "../../../redux/reducers/prescription.slice";
import {
  fetchPatientDocumentFileById,
  fetchUploadedFileById,
} from "../../../redux/reducers/upload.slice";
import {
  checkValues,
  formatDate,
  formatDropDownValue
} from "../../../shared/Utils/utilities";
import { TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA } from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import AccentCard from "../../../shared/layouts/crm-accentcard.layout";
import ListCard from "../../../shared/layouts/crm-listcard.layout";
import FileViewLayout from "../../../shared/layouts/file-view.layout";
import FilesScroller from "../../../shared/layouts/files-scroller.layout";
import TabsLayout from "../../../shared/layouts/tabs.layout";
import AddTicket from "../Tickets/components/add-ticket";
import ActivityHistoryTab from "../components/activityhistorytab";
import AssociatedLeads from "../components/associatedleadstab";
import HeaderButtons from "../components/headerbuttons";
import TabTitle from "../components/tabtitle";
import TicketsTab from "../components/ticketstab";
import TransactionsTab from "../components/transactionstab";
import CreateLeadForm from "./components/createleadform";
import LeadDetailsTab from "./components/leaddetailstab";
import LinkLeadContactForm from "./components/linkleadcontactform";

function CRMLeadDetailsPage() {
  let params: any = useParams();
  let leadId = params?.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading_details, lead, leadServices, loadingServices } = useSelector(
    (state: RootState) => state.leads
  );
  const { TabPane } = Tabs;
  const { Title, Text } = Typography;

  const { appointment } = useSelector((state: RootState) => state.appointment);

  const [linkContact, setLinkContact] = useState(false as any);
  const [leadDetailsContactLink, setLeadDetailsContactLink] = useState(
    false as any
  );
  const [linkedServices, setLinkedServices] = useState(false as any);
  const [doctorData, setDoctorData] = useState(false as any);
  const [patientData, setPatientData] = useState(false as any);
  const [fileviewer, setFileViewer] = useState(false as any);
  const [fileviewerAttached, setFileviewerAttached] = useState(false as any);
  const [activeFile, setActiveFile] = useState(null as any);
  const [allServiceFiles, setAllServiceFiles] = useState(null as any);
  const [allPrescriptions, setAllPrescriptions] = useState(null as any);
  const [fileUrl, setfileUrl] = useState(null as any);
  const [auditDataforSelectedOrder, setAuditDataforSelectedOrder] = useState(
    null as any
  );
  const [audit, setAuditTrail] = useState(false);
  const [orderDetailss, setorderDetailss] = useState(null as any);
  const [patientAddress, setpatientAddress] = useState(null as any);
  const [newOne, setnewOne] = useState(null as any);

  useEffect(() => {
    return () => {
      dispatch(resetAppointments());
    };
  }, []);

  // console.log("patientAddress", patientAddress)
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  const { t } = useTranslation()

  useEffect(() => {
    lead?.id !== leadId && dispatch(fetchLeadDetails(leadId));
    dispatch(getLeadServiceMap({ lead_id: leadId }));
  }, [leadId]);

  var allTabs = [];
  let phone = "";
  if (lead?.contact) {
    phone = lead?.contact.phone;
  }
  allTabs = [
    {
      title: <TabTitle title="Lead Details" />,
      component: <LeadDetailsTab details={lead} />,
    },
    {
      title: <TabTitle title="Associated Leads" />,
      component: <AssociatedLeads excludeLeadId={leadId} phone={phone} />,
    },
    {
      title: <TabTitle title={`Associated ${t("CRM.Tickets.Fields.Tickets")} `} />,
      component: <TicketsTab phone={phone} />,
    },
    ...((myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA?.split(".")[0]) !== -1) ? [{
        title: <TabTitle title="Transactions" />,
        component: <TransactionsTab phone={phone} />,
      }] : []),

    // {title : <TabTitle title="Promotional Communication" />, component:  <PromotionalCommunicationTab /> },
    {
      title: <TabTitle title="Activity History" />,
      component: <ActivityHistoryTab phone={phone} cols={4} />,
    },
  ];
  const [leadFormVisible, setLeadFormVisible] = useState(false);
  const [ticketFormVisible, setTicketFormVisible] = useState(false);

  let app_details =
    appointment && appointment?.doctor && appointment?.latest_status
      ? [
        { title: "Doctor", text: appointment?.doctor?.name, type: "text" },
        {
          title: "Slot",
          text:
            formatDate(appointment?.appointment_date, "DD MMM'YY") +
            " " +
            appointment?.start_time,
          type: "text",
        },
        { title: "Location", text: appointment?.branch?.name, type: "text" },
        {
          title: "Booked on",
          text: formatDate(appointment?.created_at),
          type: "text",
        },
        {
          title: "Appointment Status",
          text: (
            <Tag color="blue">
              {appointment &&
                appointment?.latest_status &&
                appointment?.latest_status
                ? appointment?.latest_status
                : ""}
            </Tag>
          ),
          type: "text",
        },
        {
          title: " ",
          text: (
            <small className={"text-secondary"}>
              {formatDate(appointment?.updated_at)}
            </small>
          ),
          type: "text",
        },
      ]
      : [];
  console.log("appointmentappointment", appointment);

  function viewDocuments() {
    if (allPrescriptions?.files?.length > 0) {
      setActiveFile(allPrescriptions.files[0]);
      getFileUrl(allPrescriptions.files[0]);
    }
    setFileViewer(true);
  }
  function viewDocumentsAttached() {
    setFileviewerAttached(true);
    setTimeout(() => {
      if (allServiceFiles) {
        if (allServiceFiles[0]?.files.length > 0)
          getFileDetails(allServiceFiles[0]?.files[0]);
      }
    }, 200);
  }
  const getFileDetails = (file: any) => {
    setActiveFile(file);
  };

  useEffect(() => {
    lead?.patient_address_id &&
      dispatch(getPatientAddress(lead?.patient_address_id)).then((val: any) => {
        setpatientAddress(val?.payload?.data?.data);
      });
  }, [lead]);

  /*
    useEffect(()=>{
        if((( leadServices?.length > 0 && !loadingServices) || lead?.meta?.service_types || patientAddress || lead?.files?.length>0  || lead?.meta?.intent_doctor_name)  && lead?.id == leadId ){
            let temp = leadServices.filter((val:any)=>{
                return val.appointment || val.order ? true : false
            })
            let details = temp.map((val:any)=>{ if(val.appointment){
                    return {
                        title: 'Appointment Details', service: [
                            { title: 'Doctor', text: val?.appointment?.doctor?.name, type: 'text' },
                            { title: 'Slot', text: formatDate(val?.appointment?.appointment_date, 'DD MMM\'YY') + " " + val?.appointment?.start_time, type: 'text' },
                            { title: 'Location', text: val?.appointment?.branch?.name, type: 'text' },
                            { title: 'Booked on', text: formatDate(val?.appointment?.created_at), type: 'text' },
                            { title: 'Status', text: <Tag color='blue'>{val?.appointment?.latest_status}</Tag>, type: 'text' },
                            { title: '', text: <small className={'text-secondary'}>{formatDate(val?.appointment?.updated_at)}</small>, type: 'text' }
                        ]
                    }
                }else if(val.order){
                    return {
                        title: 'Order Details', service: [
                            { title: 'Patient Name', text: `${val?.order?.patient?.pii?.first_name} ${val?.order?.patient?.pii?.last_name}`, type: 'text' },
                            { title: 'Order Date', text: formatDate(val?.order?.order_date), type: 'text' },
                            // { title: 'Location', text: val?.order?.branch?.name, type: 'text' },
                            { title: 'Status', text: <Tag color='blue'>{val?.order?.order_status}</Tag>, type: 'text' },
                            { title: '', text: <small className={'text-secondary'}>{formatDate(val?.order?.updated_at)}</small>, type: 'text' }
                        ]
                    }
                }else{
                    return <></>
                }
            })
            if(lead?.meta?.service_types){
                details.push({
                    title: 'Service Details', service: [
                        { title: 'Type', text: formatDropDownValue(lead?.meta?.service_types.join(", ")), type: 'text' },
                        { title: 'Slots', text: `${lead?.meta?.slot_date}, ${formatDropDownValue(lead?.meta?.service_slots[0])}`, type: 'text' },
                        { title: 'Address', text: `${patientAddress?.address_line_1}, ${patientAddress?.address_line_2}, ${patientAddress?.city}, ${patientAddress?.state} ${patientAddress?.pincode} `, type: 'text' },
                        { title: 'Remarks', text: lead?.meta?.remarks, type: 'text' },
                        { title: 'Uploaded Document', text: lead?.files ? <a onClick={viewDocuments} className={'bold-600'}>View Documents ({lead?.files?.length})</a> : 'NA', type: 'text' }
                    ]
                })
            }
            if(lead?.svc_type?.name == 'order' && patientAddress && lead?.files?.length > 0 && details.length == 0 ){
                details.push({
                    title: 'Service Details', service: [
                        { title: 'Type', text: formatDropDownValue(lead?.svc_sub_type?.name), type: 'text' },
                        { title: 'Address', text: `${patientAddress?.address_line_1}, ${patientAddress?.address_line_2}, ${patientAddress?.city}, ${patientAddress?.state} ${patientAddress?.pincode} `, type: 'text' },
                        { title: 'Uploaded Document', text: lead?.files ? <a onClick={viewDocuments} className={'bold-600'}>View Documents ({lead?.files?.length})</a> : 'NA', type: 'text' }
                    ]
                })
            }
            if(lead?.svc_type?.name == 'order' && !patientAddress && lead?.files?.length > 0 && details.length == 0 ){
                details.push({
                    title: 'Service Details', service: [
                        { title: 'Type', text: formatDropDownValue(lead?.svc_sub_type?.name), type: 'text' },
                        { title: 'Uploaded Document', text: lead?.files ? <a onClick={viewDocuments} className={'bold-600'}>View Documents ({lead?.files?.length})</a> : 'NA', type: 'text' }
                    ]
                })
            }
            if(lead?.svc_type?.name == 'cart_dropoff' && lead?.svc_sub_type?.name == 'vc_appointment' && lead?.meta?.intent_doctor_name ){
                details.push({
                    title: 'Service Details', service: [
                        { title: 'Type', text: formatDropDownValue(lead?.svc_sub_type?.name), type: 'text' },
                        { title: 'Doctor Name', text: formatDropDownValue(lead?.meta?.intent_doctor_name), type: 'text' },
                        { title: 'Appointment Date/Time', text: `${formatDate(lead?.meta?.intent_date,  "DD MMM'YY")} ${lead?.meta?.intent_time}`, type: 'text' },
                    ]
                })
            }
            if(lead?.files){
                if(lead?.files?.length > 0){
                    setAllServiceFiles([
                        {
                            title: 'PATIENT DOCUMENT',
                            files: lead?.files?.map((item: any, index: number) => { //let item = itemData?.file;
                                return { result:item?.url, title: `Prescription ${index+1}`, subtext: moment(item?.created_at).format("DD MMM'YY"), badge: null, img: item.file?.content_type ? (item.file.content_type).split("/")[0] : item.content_type ? (item.content_type).split("/")[0] : '', id: item?.id, allData: item }
                            })
                        }
                    ])
                }
            }
            setLinkedServices(details);
        }
    }, [loadingServices, lead])
    */
  useEffect(() => {
    return () => {
      dispatch(clearLeadDetails());
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          ((leadServices?.length > 0 && !loadingServices) ||
            lead?.meta?.service_types ||
            patientAddress ||
            lead?.files?.length > 0 ||
            lead?.meta?.intent_doctor_name ||
            appointment ||
            orderDetailss) &&
          lead?.id == leadId
        ) {
          let details = [] as any;

          if (lead?.meta?.service_types) {
            details.push({
              title: "Service Details",
              service: [
                {
                  title: "Type",
                  text: formatDropDownValue(
                    lead?.meta?.service_types.join(", ")
                  ),
                  type: "text",
                },
                {
                  title: "Slots",
                  text: `${lead?.meta?.slot_date}, ${formatDropDownValue(
                    lead?.meta?.service_slots[0]
                  )}`,
                  type: "text",
                },
                {
                  title: "Address",
                  text: `${checkValues(patientAddress?.address_line_1)
                    ? patientAddress?.address_line_1 + ","
                    : ""
                    } 
                  ${checkValues(patientAddress?.address_line_2)
                      ? patientAddress?.address_line_2 + ","
                      : ""
                    }
                  ${checkValues(patientAddress?.city)
                      ? patientAddress?.city + ","
                      : ""
                    }
                  ${checkValues(patientAddress?.state)
                      ? patientAddress?.state + ","
                      : ""
                    } 
                  ${checkValues(patientAddress?.pincode)
                      ? patientAddress?.pincode
                      : ""
                    } `,
                  type: "text",
                },
                {
                  title: "Remarks",
                  text: lead?.meta?.remarks ? lead?.meta?.remarks : "--",
                  type: "text",
                },
                {
                  title: "Uploaded Document",
                  text:
                    lead?.files?.length > 0 ? (
                      <a onClick={viewDocumentsAttached} className={"bold-600"}>
                        View Documents ({lead?.files?.length})
                      </a>
                    ) : (
                      "NA"
                    ),
                  type: "text",
                },
              ],
            });
          }
          if (
            lead?.svc_type?.name == "order" &&
            patientAddress &&
            lead?.files?.length > 0 &&
            details.length == 0
          ) {
            details.push({
              title: "Service Details",
              service: [
                {
                  title: "Type",
                  text: formatDropDownValue(lead?.svc_sub_type?.name),
                  type: "text",
                },
                {
                  title: "Address",
                  text: `${checkValues(patientAddress?.address_line_1)
                    ? patientAddress?.address_line_1 + ","
                    : ""
                    } 
                  ${checkValues(patientAddress?.address_line_2)
                      ? patientAddress?.address_line_2 + ","
                      : ""
                    }
                  ${checkValues(patientAddress?.city)
                      ? patientAddress?.city + ","
                      : ""
                    }
                  ${checkValues(patientAddress?.state)
                      ? patientAddress?.state + ","
                      : ""
                    } 
                  ${checkValues(patientAddress?.pincode)
                      ? patientAddress?.pincode
                      : ""
                    } `,
                  type: "text",
                },
                {
                  title: "Uploaded Document",
                  text:
                    lead?.files?.length > 0 ? (
                      <a onClick={viewDocumentsAttached} className={"bold-600"}>
                        View Documents ({lead?.files?.length})
                      </a>
                    ) : (
                      "NA"
                    ),
                  type: "text",
                },
              ],
            });
          }
          if (
            lead?.svc_type?.name == "order" &&
            !patientAddress &&
            lead?.files?.length > 0 &&
            details.length == 0
          ) {
            details.push({
              title: "Service Details",
              service: [
                {
                  title: "Type",
                  text: formatDropDownValue(lead?.svc_sub_type?.name),
                  type: "text",
                },
                {
                  title: "Uploaded Document",
                  text:
                    lead?.files?.length > 0 ? (
                      <a onClick={viewDocumentsAttached} className={"bold-600"}>
                        View Documents ({lead?.files?.length})
                      </a>
                    ) : (
                      "NA"
                    ),
                  type: "text",
                },
              ],
            });
          }
          if (
            lead?.svc_type?.name === "cart_dropoff" &&
            (lead?.svc_sub_type?.name === "vc_appointment" ||
              lead?.svc_sub_type?.name === "in_hospital_appointment") &&
            lead?.meta?.intent_doctor_name
          ) {
            details.push({
              title: "Service Details",
              service: [
                {
                  title: "Type",
                  text: formatDropDownValue(lead?.svc_sub_type?.name),
                  type: "text",
                },
                {
                  title: "Doctor Name",
                  text: formatDropDownValue(lead?.meta?.intent_doctor_name),
                  type: "text",
                },
                {
                  title: "Appointment Date/Time",
                  text: `${formatDate(lead?.meta?.intent_date, "DD MMM'YY")} ${lead?.meta?.intent_time
                    }`,
                  type: "text",
                },
              ],
            });
          }
          if (lead?.files) {
            if (lead?.files?.length > 0) {
              setAllServiceFiles([
                {
                  title: "PATIENT DOCUMENT",
                  files: lead?.files?.map((item: any, index: number) => {
                    //let item = itemData?.file;
                    return {
                      result: item?.url,
                      title: `Prescription ${index + 1}`,
                      subtext: moment(item?.created_at).format("DD MMM'YY"),
                      badge: null,
                      img: item.file?.content_type
                        ? item.file.content_type.split("/")[0]
                        : item.content_type
                          ? item.content_type.split("/")[0]
                          : "",
                      id: item?.id,
                      allData: item,
                    };
                  }),
                },
              ]);
            }
          }

          if (leadServices) {
            await Promise.all(
              leadServices?.map(async (val: any) => {
                return (
                  <>
                    {await dispatch(fetchOrderDetailsByID(val.order_id)).then(
                      (val: any) => {
                        let data = val?.payload?.data?.data;
                        details?.push({
                          title: "Order Details",
                          action: "Audit Trail",
                          auditData: data,
                          service: [
                            {
                              title: "HIS Order Id",
                              text: `${data?.order_cart_id ? data?.order_cart_id : "-"
                                }`,
                              type: "text",
                            },
                            {
                              title: "Order Id",
                              text: `${data?.order_id}`,
                              type: "text",
                            },
                            // {
                            //   title: "Patient Name",
                            //   text: `${data?.patient?.pii?.first_name} ${data?.patient?.pii?.last_name}`,
                            //   type: "text",
                            // },
                            {
                              title: "Order Date",
                              text: moment(data?.order_date).format(
                                "DD MMM'YY, LT"
                              ),
                              type: "text",
                            },
                            {
                              title: "Items Added",
                              text: `${data?.items?.length}`,
                              type: "text",
                            },
                            {
                              title: "Total Amount",
                              text: `${Math.ceil(data?.total_amount)}`,
                              type: "text",
                            },
                            {
                              title: "Status",
                              text: (
                                <Tag color="blue">
                                  {data?.order_status}
                                </Tag>
                              ),
                              type: "text",
                            },
                            {
                              title: "",
                              text: JSON.stringify(data),
                              type: "obj",
                            },
                          ],
                        });
                      }
                    )}
                  </>
                );
              })
            );
          }
          if (lead?.meta?.lead?.length > 0) {
            let leadData = lead?.meta?.lead;
            if (typeof leadData === "string") {
              leadData = JSON.parse(leadData.split("'").join('"') || "{}");
            }
            let fbData = {
              title: "Lead Details",
              service: [
                ...(lead?.meta?.form_name
                  ? [
                    {
                      title: "Form Name",
                      text: (
                        <span
                          style={{ textTransform: "none", fontWeight: 700 }}
                        >
                          {lead.meta.form_name}
                        </span>
                      ),
                      type: "text",
                    },
                  ]
                  : []),
                ...leadData?.map((val: any) => {
                  return {
                    title: formatDropDownValue(val.name),
                    text: (
                      <span style={{ textTransform: "none" }}>
                        {val.values?.toString()}
                      </span>
                    ),
                    type: "text",
                  };
                }),
              ],
            };
            details.push(fbData);
            setLeadDetailsContactLink(fbData);
          }

          setLinkedServices(details);
        }
      } catch (error) {
        // Handle error
      }
    };
    fetchData();
  }, [loadingServices, lead, patientAddress]);

  useEffect(() => {
    lead?.appointment_id &&
      dispatch(fetchAppointmentById(lead?.appointment_id));
    lead?.order_id &&
      dispatch(fetchOrderDetailsByID(lead?.order_id)).then((val: any) => {
        setorderDetailss(val?.payload?.data?.data);
      });
  }, [lead]);

  useEffect(() => {
    let doctorData1: any, patientData1: any;
    if ((lead?.meta?.chatbottype === "regency-bot" || lead?.meta?.chatbottype === "wa-bot") && lead?.id == leadId) {
      doctorData1 = {
        name: lead?.meta?.doctorObj?.doctorname || lead?.meta?.doctorObj?.Name,
        speciality:
          lead?.meta?.doctorObj?.doctorspeciality ||
          lead?.meta?.doctorObj?.Specialization,
        location:
          lead?.meta?.doctorObj?.doctorunit || lead?.meta?.doctorObj?.City,
        email:
          lead?.meta?.doctorObj?.doctoremail || lead?.meta?.doctorObj?.EmailId,
        phone:
          "+" + lead?.meta?.doctorObj?.doctorNumber || lead?.meta?.doctorNumber,
        doctorid: lead?.meta?.doctorObj?.doctorid,
      };
      patientData1 = {
        title: "Patient Details",
        // service:
        //   lead?.meta &&
        //   Object.entries(lead?.meta).flatMap(([key, value]) => {
        //     if (typeof value === "object" && value) {
        //       return Object.entries(value).map(([keys, values]) => {
        //         if (
        //           metaValues?.find(
        //             (es) => es.value == keys && es.show === true
        //           ) &&
        //           values
        //         ) {
        //           return {
        //             title:
        //               metaValues?.find((es) => es.value == keys)?.label || keys,
        //             text: values,
        //             type: "text",
        //           };
        //         }
        //       });
        //     } else {
        //       if (
        //         metaValues?.find((es) => es.value == key && es.show === true) &&
        //         value
        //       ) {
        //         if (key === 'referedAt') {
        //           return {
        //             title:
        //               metaValues?.find((es) => es.value == key)?.label || key,
        //             text: moment.utc(value).utcOffset('+05:30').format("DD MMM'YY, hh:mm A"),
        //             type: "text",
        //           };
        //         }
        //         else {
        //           return {
        //             title:
        //               metaValues?.find((es) => es.value == key)?.label || key,
        //             text: value,
        //             type: "text",
        //           };
        //         }

        //       }
        //     }
        //   }),
        service: [
          { title: "Name", text: lead?.meta?.patientName, type: "text" },
          {
            title: "Phone Number",
            text: `${lead?.meta?.mobileNumber ? lead?.meta?.mobileNumber : ""}`,

            type: "text",
          },
          {
            title: "Referred Date & Time",
            text: moment.utc(lead?.meta?.referedAt).utcOffset('+05:30').format("DD MMM'YY, hh:mm A"),
            type: "text",
          },
          {
            title: "Doctor or Speciality Referred",
            text: lead?.meta?.speciality,
            type: "text",
          },
        ],
      };

      setDoctorData(doctorData1);
      setPatientData(patientData1);
    }

    if (lead?.meta?.opdIdRow?.patient_name) {
      doctorData1 = {
        name: (
          <>
            {lead?.meta?.opdIdRow?.patient_name}{" "}
            <small>
              {(lead?.meta?.opdIdRow?.gender || lead?.meta?.opdIdRow?.age) &&
                "("}
              {lead?.meta?.opdIdRow?.gender}, {lead?.meta?.opdIdRow?.age}
              {(lead?.meta?.opdIdRow?.gender || lead?.meta?.opdIdRow?.age) &&
                ")"}
            </small>
          </>
        ),
        phone: lead?.meta?.opdIdRow?.mobile,
      };
      patientData1 = {
        title: "OPD Details",
        service: [
          {
            title: "Doctor Name",
            text: lead?.meta?.opdIdRow?.doctor_name,
            type: "text",
          },
          {
            title: "Meeting Time",
            text: lead?.meta?.opdIdRow?.meeting_time,
            type: "text",
          },
          {
            title: "Fulfillments",
            text:
              lead?.meta?.opdIdRow?.fulfillments?.length > 0
                ? lead?.meta?.opdIdRow?.fulfillments?.map((val: string) => {
                  return <Tag>{val}</Tag>;
                })
                : "--",
            type: "text",
          },
          {
            title: "Document",
            text: lead?.meta?.opdIdRow?.base_image ? (
              <a
                href={lead?.meta?.opdIdRow?.base_image}
                target="_blank"
                rel="noreferrer"
              >
                Download
              </a>
            ) : (
              "--"
            ),
            type: "text",
          },
        ],
      };
      setDoctorData(doctorData1);
      setPatientData(patientData1);
    }
    if (
      !lead?.meta?.chatbottype &&
      !lead?.meta?.opdIdRow?.patient_name &&
      lead?.meta &&
      Object.keys(lead?.meta)?.length > 0 &&
      !lead?.meta?.lead
    ) {
      patientData1 = {
        title: "Lead Details",
        service:
          lead?.meta &&
          Object.entries(lead?.meta).map(([key, value]) => {
            if (typeof value !== "object" && value) {
              return {
                title: formatDropDownValue(key),
                text: <span style={{ textTransform: "none" }}>{value}</span>,
                type: "text",
              };
            }

          }),
      };
      console.log(patientData1);
      setPatientData(patientData1);
    }
  }, [lead]);
  useEffect(() => {
    // if(allServiceFiles){
    //     if(allServiceFiles[0]?.files.length > 0) setActiveFile(allServiceFiles[0]?.files[0])
    // }
  }, [allServiceFiles]);
  useEffect(() => {
    //lead?.svc_type?.name == 'rx_fulfilment' &&
    if (lead?.appointment_id) {
      dispatch(
        fetchPrescriptions({ appointment_id: lead?.appointment_id })
      ).then((val: any) => {
        let allPres = {
          title: "PATIENT DOCUMENT",
          files: [],
        };
        if (val?.payload?.data?.statusCode == 200) {
          allPres.files = allPres?.files?.concat(
            val.payload.data.data.items.map((item: any) => {
              return {
                title: item?.name,
                subtext: moment(item.date).format("DD MMM'YY"),
                img: item.file?.content_type
                  ? item.file.content_type.split("/")[0]
                  : item.content_type
                    ? item.content_type.split("/")[0]
                    : "",
                id: item.data_id,
                allData: item,
                baseType: "doctor",
              };
            })
          );
        }
        setAllPrescriptions(allPres);
        // dispatch(fetchPatientsDocuments({ appointment_id: lead?.appointment_id })).then((val:any)=>{
        //     if(val?.payload?.data?.statusCode == 200){
        //         allPres.files = allPres?.files?.concat(
        //             val.payload.data.data.items.filter((item:any)=>{
        //                 return  item.type == "PRESCRIPTION"
        //             }).map((item:any)=>{
        //                 return  { title: item?.name, subtext: moment(item.date).format("DD MMM'YY"), img: (item.content_type).split("/")[0], id: item.id, allData: item, baseType:'patient' }
        //             })
        //         )
        //     }
        //     if(allPres?.files?.length > 0){
        //         setAllPrescriptions(allPres)
        //     }
        // })
      });
    }
  }, [lead]);

  useEffect(() => {
    // if(allPrescriptions?.files?.length > 0){
    //     setActiveFile(allPrescriptions.files[0])
    //     getFileUrl(allPrescriptions.files[0])
    // }
  }, [allPrescriptions]);

  allPrescriptions?.files?.length > 0 &&
    app_details.push({
      title: "Uploaded Document",
      text: allPrescriptions?.files ? (
        <a onClick={viewDocuments} className={"bold-600"}>
          View Documents ({allPrescriptions?.files?.length})
        </a>
      ) : (
        "NA"
      ),
      type: "text",
    });
  function getFileUrl(data: any) {
    setActiveFile(data);
    setfileUrl(null);
    if (data?.baseType == "doctor") {
      dispatch(
        fetchUploadedFileById({ id: data?.id, type: data?.allData?.type })
      ).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          setfileUrl(val?.payload?.data?.data?.result);
        }
      });
    } else {
      dispatch(fetchPatientDocumentFileById(data?.id)).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          setfileUrl(val?.payload?.data?.data?.result);
        }
      });
    }
  }

  const getAuditTrailData = (childData: any) => {
    setAuditTrail(true);
    setAuditDataforSelectedOrder(childData);
  };
  console.log(app_details)
  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`Lead #${lead?.display_id ? lead?.display_id : ""}`}
        extra={
          <HeaderButtons
            setLeadFormVisible={setLeadFormVisible}
            setTicketFormVisible={setTicketFormVisible}
          />
        }
      ></PageHeader>
      <div className="pd20 details-page-area">
        {!loading_details && (
          <Row gutter={16}>
            <Col
              className="gutter-row"
              {...{ xs: 24, sm: 24, md: 7, lg: 7, xl: 7 }}
            >
              {!lead?.contact_id && (
                <>
                  <Card className="accentCard mb-15">
                    <Button
                      onClick={() => setLinkContact(true)}
                      className="primary-ghost"
                      type="primary"
                      ghost
                      block
                      size="large"
                    >
                      Link Contact Details
                    </Button>
                  </Card>
                </>
              )}
              {!doctorData && lead?.contact_id && (
                <AccentCard
                  type="contact"
                  details={lead}
                  callData={{ source: "lead", lead_id: leadId }}
                ></AccentCard>
              )}
              {doctorData && (
                <AccentCard
                  type="doctor"
                  details={doctorData}
                  callData={{ source: "lead", lead_id: leadId }}
                ></AccentCard>
              )}
              {patientData && (
                <ListCard
                  details={patientData?.service?.filter(
                    (e: any) => e !== undefined
                  )}
                  title={patientData?.title}
                  showMoreBtn={true}
                ></ListCard>
              )}

              {(appointment?.length > 0 || appointment?.id) && (
                <ListCard
                  details={app_details}
                  title={"Appointment Details"}
                ></ListCard>
              )}

              {/* { !loadingServices && linkedServices?.length > 0 && <h1 className='text-secondary'>Booked Services</h1> } */}
              {!loadingServices && linkedServices?.length > 0 ? (
                linkedServices?.map((service: any) => {
                  return (
                    <ListCard
                      details={service?.service}
                      title={service?.title}
                      action={service?.action}
                      auditData={service?.auditData}
                      auditCallback={getAuditTrailData}
                    ></ListCard>
                  );
                })
              ) : (
                <></>
              )}
            </Col>
            <Col
              className="gutter-row"
              {...{ xs: 24, sm: 24, md: 17, lg: 17, xl: 17 }}
            >
              <Card className="nopaddingcard crm-tabs lead-details-tabs">
                <TabsLayout AllTabs={allTabs}></TabsLayout>
              </Card>
            </Col>
          </Row>
        )}
        {loading_details && <Skeleton></Skeleton>}
      </div>
      {leadFormVisible && (
        <CreateLeadForm
          saveLeadSuccess={() => setLeadFormVisible(false)}
          setLeadFormVisible={(val: any) => setLeadFormVisible(val)}
          leadFormVisible={leadFormVisible}
          phone={lead?.contact?.phone}
          defaultContactId={lead?.contact?.id}
        ></CreateLeadForm>
      )}
      {ticketFormVisible && (
        <AddTicket
          onTicketSave={() => setTicketFormVisible(false)}
          setTicketFormVisible={(val: any) => setTicketFormVisible(val)}
          ticketFormVisible={ticketFormVisible}
          phoneNumber={lead?.contact?.phone}
          defaultContactId={lead?.contact?.id}
        ></AddTicket>
      )}
      {linkContact && (
        <LinkLeadContactForm
          saveLeadSuccess={() => {
            setLinkContact(false);
            dispatch(fetchLeadDetails(leadId));
          }}
          setLeadFormVisible={(val: any) => setLinkContact(val)}
          leadFormVisible={linkContact}
          leadId={leadId}
          leadDetails={leadDetailsContactLink}
        ></LinkLeadContactForm>
      )}
      <Modal
        centered
        // footer={false}
        visible={fileviewer}
        onCancel={() => setFileViewer(false)}
      >
        <Row className="crm-app" style={{ backgroundColor: "#FFFFFF" }}>
          <Col
            span={24}
            style={{ maxHeight: "calc(100vh - 20px)", overflow: "auto" }}
          >
            <Row>
              <Col span={24}>
                {/* {allServiceFiles && <FilesScroller files={allServiceFiles} active={activeFile} type={"doctor"} getPrescriptionDetails={(id: any) => getFileDetails(id)}></FilesScroller> } */}
                {allPrescriptions && (
                  <FilesScroller
                    files={[allPrescriptions]}
                    active={activeFile}
                    type={"doctor"}
                    getPrescriptionDetails={(id: any) => getFileUrl(id)}
                  ></FilesScroller>
                )}
              </Col>

              <Col span={24} className={"padding10"}>
                {/* { activeFile && !fileUrl && <FileViewLayout modal={false} details={activeFile} fileviewer={true} total={0} file={{result: activeFile.result }}></FileViewLayout> } */}
                {activeFile && fileUrl && (
                  <FileViewLayout
                    modal={false}
                    details={activeFile}
                    fileviewer={true}
                    total={0}
                    file={{ result: fileUrl }}
                  ></FileViewLayout>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      <Modal
        centered
        footer={false}
        visible={fileviewerAttached}
        onCancel={() => setFileviewerAttached(false)}
      >
        <Row className="crm-app" style={{ backgroundColor: "#FFFFFF" }}>
          <Col
            span={24}
            style={{ maxHeight: "calc(100vh - 20px)", overflow: "auto" }}
          >
            <Row>
              <Col span={24}>
                {allServiceFiles && (
                  <FilesScroller
                    files={allServiceFiles}
                    active={activeFile}
                    type={"doctor"}
                    getPrescriptionDetails={(id: any) => getFileDetails(id)}
                  ></FilesScroller>
                )}
                {/* {allPrescriptions && <FilesScroller files={[allPrescriptions]} active={activeFile} type={"doctor"} getPrescriptionDetails={(id: any) => getFileUrl(id)}></FilesScroller> } */}
              </Col>

              <Col span={24} className={"padding10"}>
                {activeFile && (
                  <FileViewLayout
                    modal={false}
                    details={activeFile}
                    fileviewer={true}
                    total={0}
                    file={{ result: activeFile.result }}
                  ></FileViewLayout>
                )}
                {/* { activeFile && fileUrl && <FileViewLayout modal={false} details={activeFile} fileviewer={true} total={0} file={{result: fileUrl }}></FileViewLayout> } */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      {/*  Audit Trail Drawer */}
      <Drawer
        title={`Audit Trail`}
        className="crm-app-drawer"
        placement="right"
        width={"80%"}
        onClose={() => {
          setAuditTrail(false);
        }}
        visible={audit}
      >
        <Row className="mt20">
          <Col span={24}>
            <Title level={5}>
              {" "}
              <CalendarOutlined /> <span className="pdl5">Order Details</span>
            </Title>
          </Col>
        </Row>
        <Row
          className="mt20"
          style={{
            padding: "10px",
            background: "#c9c4c4",
            borderRadius: "5px",
          }}
        >
          <Col span={6} className="pdh20">
            <Text>HIS Order ID: </Text>
            <Text className="sub-heading capitalize">
              {auditDataforSelectedOrder?.order_cart_id}
            </Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text>Order ID: </Text>
            <Text className="sub-heading capitalize">
              {auditDataforSelectedOrder?.order_id}
            </Text>
          </Col>
          <Col span={6} className="pdh5">
            <Text>Order Date: </Text>
            <Text className="sub-heading">
              {moment(auditDataforSelectedOrder?.order_date).format(
                "DD MMM'YY,hh:mm A"
              )}
            </Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text>Total: </Text>
            <Text className="sub-heading">
              Rs. {Math.ceil(auditDataforSelectedOrder?.total_amount)}
            </Text>
          </Col>
        </Row>
        <Row className="mt20">
          <Col span={18} className="pdh20">
            <Text className="sub-heading">Medicine Details</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text className="sub-heading">Price </Text>
          </Col>
        </Row>
        <Row className="mt10">
          {auditDataforSelectedOrder?.items?.map((item: any, index: any) => (
            <>
              <Col span={18} className="pdh20">
                <Text>
                  {index + 1}. {item?.item_name}
                </Text>
                <Text style={{ paddingLeft: "10px" }}>
                  [<ShoppingCartOutlined />
                  {item?.item_quantity} * ₹ {item?.item_amount?.toFixed(2)}]
                </Text>
                <Text style={{ paddingLeft: "10px" }}>
                  {item?.is_otc === false ? (
                    <Tag
                      className="solid"
                      color="blue"
                      style={{ marginLeft: "5px", fontSize: "8px" }}
                    >
                      Rx Required
                    </Tag>
                  ) : (
                    ""
                  )}
                </Text>
              </Col>
              <Col span={6} className="pdh20">
                <Text>
                  Rs. {Math.ceil(item?.item_amount * item?.item_quantity)}{" "}
                </Text>
              </Col>
            </>
          ))}
        </Row>
        <Row className="mt10">
          <Col span={18} className="pdh20">
            <Text type="secondary">Discount</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text type="secondary">
              -Rs. {auditDataforSelectedOrder?.discount || 0}{" "}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row className="mt10">
          <Col span={18} className="pdh20">
            <Text type="secondary">Patient Name</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text type="secondary">
              {auditDataforSelectedOrder?.patient?.pii?.first_name +
                " " +
                auditDataforSelectedOrder?.patient?.pii?.last_name}
            </Text>
          </Col>
        </Row>
        <Row className="mt10">
          <Col span={18} className="pdh20">
            <Text type="secondary">Delivery Address</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text type="secondary">{auditDataforSelectedOrder?.address}</Text>
          </Col>
        </Row>
        <Divider />
      </Drawer>
    </Fragment>
  );
}

export default CRMLeadDetailsPage;
