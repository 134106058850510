import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  List,
  Modal,
  PageHeader,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  message,
} from "antd";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { fetchBranches } from "../../../redux/reducers/branch.slice";
import {
  downloadLeadData,
  fetchLeadListCount,
  fetchLeadsList,
  reset,
  setFilters,
  setSelectedStatusIds,
  setSorters,
  updateLeadBulk,
} from "../../../redux/reducers/leads.slice";
import {
  fetchMasterAllTaskDropdown,
  fetchMasterTaskDropDown,
  getCrmTypesData,
} from "../../../redux/reducers/master.slice";
import { fetchAgents } from "../../../redux/reducers/user.slice";
import { MoreFilters } from "../../../shared/Utils/filters";
import {
  contactNameFormat,
  formatDate,
  formatDropDownValue,
  isUUID,
  titleCase,
} from "../../../shared/Utils/utilities";
import { TASK_MODULE_ACTIONS_HIDE_BRANCH, TASK_MODULE_ACTIONS_HIDE_THB_ID, TASK_MODULE_ACTIONS_HIDE_UHID, TASK_MODULE_ACTIONS_READ_AGENT_PROFILE, TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION } from "../../../shared/components/permission.constants";
import { ACCOUNT_PREFIX, RootState } from "../../../shared/constants";
import FormBar from "../../../shared/layouts/formbar.layout";
import ResultModalLayout from "../../../shared/layouts/resultmodal.layout";
import SpinLoadingLayout from "../../../shared/layouts/spinloading.layout";
import TablePhoneNumberCopier from "../../../shared/layouts/table-phone-number-copier.layout";
import TableLayout from "../../../shared/layouts/table.layout";
import TabsLayout from "../../../shared/layouts/tabs.layout";
import { ROUTE_CRM_LEAD_DETAILS } from "../../../shared/routes/crm.routes.constants";
import AddTicket from "../Tickets/components/add-ticket";
import HeaderButtons from "../components/headerbuttons";
import CreateLeadForm from "./components/createleadform";
const { RangePicker } = DatePicker;
const { Option } = Select;

function CRMLeadsList() {
  const csvLink = useRef("" as any);
  const history = useHistory();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { connection } = useSelector((state: RootState) => state.chat);
  const { profile } = useSelector((state: RootState) => state.profile);
  const { agents } = useSelector((state: RootState) => state.user);
  const { branches } = useSelector((state: RootState) => state.branch);
  const { loading, leads, selectedStatusIds, pagination, filters, sorters } = useSelector(
    (state: RootState) => state.leads
  );
  const { taskDropDown, allTaskDropdown } = useSelector(
    (state: RootState) => state.master
  );
  const [leadStatusList, setLeadStatusList] = useState([] as any);
  const [selectedStatusId, setSelectedStatusId] = useState("" as any);
  const [leadsList, setLeadsList] = useState([] as any);
  const [leadSource, setLeadSource] = useState([] as any);
  const [priorityList, setPriorityList] = useState([] as any);
  // const [filters, setFilters] = useState(null as any);
  const [taskTypesList, setTaskTypes] = useState([] as any);
  const [taskSubTypesList, setTaskSubTypes] = useState([] as any);
  const [opportunitiesList, setOpportunitiesList] = useState(false as any);
  const [downloadReportOptions, setdownloadReportOptions] = useState([] as any);
  const [downloadType, setdownloadType] = useState(null as any);
  const [emailModal, setEmailModal] = useState(false);
  // const [sorters, setSorters] = useState({
  //   sort_column: "due_date",
  //   sort_val: null,
  // } as any);
  const [userList, setUserList] = useState(null as any);
  const [isRefreshed, setisRefreshed] = useState(true);
  const [leadCount, setleadCount] = useState(null as any);

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  const [currentTabName, setcurrentTabName] = useState("NEW");
  const [filterValues, setfilterValues] = useState(null as any);
  const [bulkModal, setbulkModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, sethasSelected] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [linkLoaderMessage, setlinkLoaderMessage] = useState(null as any);
  const [successLoaderMessage, setsuccessLoaderMessage] = useState(null as any);
  const [followupdateFlag, setfollowupdateFlag] = useState(false);
  const [pageSizeCount, setpageSizeCount] = useState(10);
  const [sourceList, setsourceList] = useState(null as any);

  const [excludedMinutes, setexcludedMinutes] = useState(0);
  const [excludedHours, setexcludedHours] = useState(0);
  const [pageNumber, setpageNumber] = useState(pagination?.offset && pagination?.limit ? parseInt(pagination?.offset) / parseInt(pagination?.limit) : 0)

  useEffect(() => {
    const pagenumbers = parseInt(pagination?.offset) / parseInt(pagination?.limit);
    setpageNumber(pagenumbers)
  }, [pagination])

  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const [downloadRangePickerValidation, setDownloadRangePickerValidation] =
    useState({ type: "", message: "" } as any);

  const downloadDataOnValuesChange = (_: any, v: any) => {
    if (_.date_range?.length === 2) {
      const start = _.date_range[0];
      const end = _.date_range[1];
      if (end.diff(start, "days") > 30) {
        setDownloadRangePickerValidation({
          type: "error",
          message: "Date range can be maximum 30 days",
        });
      } else {
        setDownloadRangePickerValidation({ type: "", message: "" });
      }
    }
  };

  const handleDateChangeArray = (e: any) => {
    const hourStarter =
      moment().format("A") === "PM" && Number(moment().format("hh")) !== 12
        ? 12
        : 0;
    const selectedDate = e.format("YYYY-MM-DD");
    const selectedHours = Number(e.format("hh"));
    const currentDate = moment().format("YYYY-MM-DD");

    if (selectedDate > currentDate) {
      setexcludedHours(0);
      setexcludedMinutes(0);
    } else {
      setexcludedHours(hourStarter + Number(moment().format("hh")));
      setexcludedMinutes(Number(moment().format("mm")));

      if (Number(moment().format("hh")) !== 12) {
        if (selectedHours > Number(moment().format("hh"))) {
          setexcludedMinutes(0);
        } else {
          setexcludedMinutes(Number(moment().format("mm")));
        }
      } else {
        setexcludedMinutes(Number(moment().format("mm")));
        if (selectedHours === 12) {
          setexcludedMinutes(Number(moment().format("mm")));
        } else {
          setexcludedMinutes(0);
        }
      }
    }
  };

  const onValueChangeBulkForm = (val: any) => {
    if (val.status_id) {
      const statusName = leadStatusList?.filter((value: any) => {
        return val.status_id === value.value;
      })[0]?.name;

      if (statusName === "FOLLOW-UP") {
        setfollowupdateFlag(true);
      } else {
        setfollowupdateFlag(false);
      }
    }
  };

  const reasonList = [
    {
      name: "Doctor Not Available",
      value: "Doctor Not Available",
    },
    {
      name: "Met with same Doctor",
      value: "Met with same Doctor",
    },
    {
      name: "Met with some other Doctor",
      value: "Met with some other Doctor",
    },
    {
      name: "High Doctor Wait time",
      value: "High Doctor Wait time",
    },
  ];

  const showBulkModel = () => {
    setbulkModal(true);
  };

  // useEffect(() => {
  //   const a = processFilters();
  //   setfilterValues(a && a.length);
  // }, [filters]);

  useEffect(() => {
    const a =
      filters &&
      Object.entries(filters).filter(([key, value]) => value !== null);
    setfilterValues(a && a.length);
  }, [filters]);

  useEffect(() => {
    taskDropDown?.length == 0 && dispatch(fetchMasterTaskDropDown());
    dispatch(fetchMasterAllTaskDropdown({ mode: "lead" }));
    if (
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_AGENT_PROFILE) === -1 &&
      myPermissions?.indexOf(
        TASK_MODULE_ACTIONS_READ_AGENT_PROFILE?.split(".")[0]
      ) === -1
    ) {
    } else {
      dispatch(fetchAgents({ limit: 500 }));
    }
  }, []);

  useEffect(() => {
    const wsCurrent = connection;
    if (!wsCurrent) return;
    wsCurrent.onmessage = (e: any) => {
      const _message = JSON.parse(e.data);
      if (
        (_message.type === "lead_created" ||
          _message.type === "lead_updated") &&
        isRefreshed
      ) {
        updateRefresh();
        setisRefreshed(false);
        return;
      }
    };
  });

  useEffect(() => {
    branches?.length == 0 && dispatch(fetchBranches({}));
  }, []);

  useEffect(() => {
    dispatch(fetchLeadListCount())
      .then((val: any) => {
        if (val?.payload?.data?.statusCode === 200) {
          setleadCount(val?.payload?.data?.data);
        }
      })
      .catch((ex: any) => console.error(ex));
  }, []);

  const updateRefresh = () => {
    setTimeout(() => {
      getLeadList();
      dispatch(fetchLeadListCount())
        .then((val: any) => {
          if (val?.payload?.data?.statusCode === 200) {
            setleadCount(val?.payload?.data?.data);
          }
        })
        .catch((ex: any) => console.error(ex));
      setisRefreshed(true);
    }, 10000);
  };

  useEffect(() => {
    dispatch(getCrmTypesData({ id: "lead" })).then((val: any) => {
      setdownloadReportOptions(val.payload.data.data);
      setdownloadType(val.payload.data.data[0].key);
    });
  }, []);

  // useEffect(() => {
  //   setTaskTypes(
  //     taskTypes.map((val: any) => {
  //       return { name: formatDropDownValue(val.name), value: val.id };
  //     })
  //   );
  // }, [taskTypes]);
  // useEffect(() => {
  //   setTaskSubTypes(
  //     taskSubTypes.map((val: any) => {
  //       return { name: formatDropDownValue(val.name), value: val.id };
  //     })
  //   );
  // }, [taskSubTypes]);
  useEffect(() => {
    // console.log("allTaskDropdown", allTaskDropdown);
    if (allTaskDropdown?.type?.length > 0) {
      setTaskTypes(
        allTaskDropdown?.type.map((val: any) => {
          return {
            name: formatDropDownValue(val.name),
            value: val.name,
            ids: val.id,
          };
        })
      );
    }
    if (allTaskDropdown?.sub_type?.length > 0) {
      setTaskSubTypes(
        allTaskDropdown?.sub_type.map((val: any) => {
          return {
            name: formatDropDownValue(val.name),
            value: val.name,
            ids: val.id,
          };
        })
      );
    }
  }, [allTaskDropdown]);

  useEffect(() => {
    if (agents.length > 0) {
      let unAssignedUser = {
        name: `${"Unassigned"}`,
        value: `${"unassigned"}`,
      };
      let agentLists = agents.map((val: any) => {
        return {
          name: `${val?.user?.first_name && val?.user?.first_name !== "null"
            ? val?.user?.first_name
            : ""
            } ${val.user?.last_name && val.user?.last_name !== "null"
              ? val.user?.last_name
              : ""
            }`,
          value: val.user_id,
        };
      });
      setUserList([unAssignedUser, ...agentLists]);
    }
  }, [agents]);

  useEffect(() => {
    if (taskDropDown?.length > 0) {
      let temp = taskDropDown?.filter((val: any) => {
        return val.name == "status" && val.mode == "lead";
      });

      let vals = temp[0]?.values?.map((val: any) => {
        return {
          name: formatDropDownValue(val.name),
          value: val.id,
          key: val.name,
        };
      });
      setLeadStatusList(vals);

      if (vals?.length > 0) {
        if (selectedStatusIds) {
          setSelectedStatusId(vals[(selectedStatusIds - 1)]?.value);
        }
        else {
          setSelectedStatusId(vals[0]?.value);
        }
      }

      if (vals?.length > 0) setSelectedStatusId(vals[0].value);

      temp = taskDropDown?.filter((val: any) => {
        return (
          val.name == "source" && (val.mode == "source" || val.mode == "lead")
        );
      });
      setLeadSource(
        temp[0]?.values?.map((val: any) => {
          return { name: formatDropDownValue(val.name), value: val.id };
        })
      );
      temp = taskDropDown?.filter((val: any) => {
        return val.name == "priority" && val.mode == "lead";
      });
      setPriorityList(
        temp[0]?.values?.map((val: any) => {
          return { name: formatDropDownValue(val.name), value: val.id };
        })
      );
      temp = taskDropDown?.filter((val: any) => {
        return val.name == "opportunity" && val.mode == "lead";
      });
      if (temp.length > 0) {
        setOpportunitiesList(
          temp[0]?.values?.map((val: any) => {
            return { name: formatDropDownValue(val.name), value: val.id };
          })
        );
      }
      temp = taskDropDown?.filter((val: any) => {
        return val.name == "source" && val.mode == "lead";
      });
      // const newrrr = temp[0]?.values?.map((val: any) => {
      //   return { name: formatDropDownValue(val.name), value: val.id };
      // });
      // const blanks = { name: "NOT DEFINED", value: "null" };
      // setsourceList([blanks, ...newrrr]);
      setsourceList(temp[0]?.values?.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      }))
    }
  }, [taskDropDown]);

  useEffect(() => {
    getLeadList();
    dispatch(fetchLeadListCount())
      .then((val: any) => {
        if (val?.payload?.data?.statusCode === 200) {
          setleadCount(val?.payload?.data?.data);
        }
      })
      .catch((ex: any) => console.error(ex));
  }, [selectedStatusId, filters, sorters]);

  // useEffect(() => {
  //   const offset = parseInt(pagination?.offset);
  //   const limit = parseInt(pagination.limit);
  //   if (leadsList?.length === 0 && offset > limit - 1) {
  //     onPageChange(offset / limit, limit); //to change
  //   }
  // }, [leadsList, pagination]);

  const processFilters = () => {
    let filter: any = filters
      ? Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != null)
      )
      : {};
    if (!filter.search_val) {
      delete filter.search_type;
    }
    Object.keys(filter).forEach((key: any) => {
      if (key === "svc_type_id") {
        if (filter.svc_type_id?.length > 0) {
          let svc_type_id = [] as any;
          let temp = taskTypesList?.filter((val: any) => {
            return filter.svc_type_id?.indexOf(val.value) !== -1;
          });
          temp.forEach((val: any) => {
            return svc_type_id.push(...val.ids);
          });
          filter.svc_type_id = svc_type_id;
        }
      } else if (key === "svc_sub_type_id") {
        if (filter.svc_sub_type_id?.length > 0) {
          let svc_sub_type_id = [] as any;
          let temp = taskSubTypesList?.filter((val: any) => {
            return filter.svc_sub_type_id?.indexOf(val.value) !== -1;
          });
          temp.forEach((val: any) => {
            return svc_sub_type_id.push(...val.ids);
          });
          filter.svc_sub_type_id = svc_sub_type_id;
        }
      }
    });
    return filter;
  };
  const getLeadList = (offsets: number = 0) => {
    const filter = processFilters();
    let sorter = sorters?.sort_val == null ? {} : sorters;
    selectedStatusId !== "" &&
      (currentTabName === "FOLLOW-UP"
        ? dispatch(
          fetchLeadsList({
            offset: pagination.offset,
            limit: pageSizeCount,
            ...(isUUID(selectedStatusId)
              ? { status_id: selectedStatusId }
              : {}),
            sort_column: "follow_up_date",
            sort_val: "ASC",
            ...filter,
            ...sorter,
          })
        )
        : dispatch(
          fetchLeadsList({
            offset: pagination.offset,
            limit: pageSizeCount,
            ...(isUUID(selectedStatusId)
              ? { status_id: selectedStatusId }
              : {}),
            ...filter,
            ...sorter,
          })
        ));
  };

  useEffect(() => {
    // let refreshTimer = null as any;
    // if(selectedStatusId !== ''){
    //     clearInterval(refreshTimer)
    //     refreshTimer = setInterval(()=>{
    //         getLeadList()
    //     }, 30000)
    // }
    // return () => {
    //     clearInterval(refreshTimer)
    // }
  }, [selectedStatusId, pagination]);

  useEffect(() => {
    if (!loading) setLeadsList(leads);
  }, [leads, loading]);

  const columns: any = [
    {
      title: "Lead Details",
      width: 200,
      fixed: "left",
      className: "capitalize",
      render: (a: any) => {
        return (
          <>
            {a?.contact_id ? (
              <a
                className="bold-600"
                onClick={() => history.push(ROUTE_CRM_LEAD_DETAILS(a.id))}
              >
                {contactNameFormat(
                  a?.contact?.first_name,
                  a?.contact?.last_name,
                  a?.contact?.gender?.name,
                  a?.contact?.dob
                )}
              </a>
            ) : (
              <a
                className="bold-600"
                style={{ width: "100%", height: "60px", display: "block" }}
                onClick={() => history.push(ROUTE_CRM_LEAD_DETAILS(a.id))}
              >
                --
              </a>
            )}
            {a?.display_id && (
              <>
                <br />
                <small> <strong>{"#" + a?.display_id}</strong> </small>
              </>
            )}
            {a.contact?.uhid && myPermissions?.indexOf("task_module") == -1 && myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_UHID) == -1 && (
              <>
                <br />
                <small>{t("CRM.Common.Fields.UHID")}: {a.contact?.uhid}</small>
              </>
            )}
            {a.contact?.thb_id && myPermissions?.indexOf("task_module") == -1 && myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_THB_ID) == -1 && (
              <>
                <br />
                <small>THB ID: {a.contact?.thb_id}</small>
              </>
            )}
            {a?.contact_id ? (
              <>
                <br />
                <small>
                  Ph:{" "}
                  <TablePhoneNumberCopier
                    phone={"+" + a.contact?.country_code + a.contact?.phone}
                  ></TablePhoneNumberCopier>
                </small>
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH) !== -1 ? [] :
      [{
        title: "Hospital",
        render: (a: any) => {
          return formatDropDownValue(a.branch?.name);
        },
      }]
    ),

    {
      title: "Lead Source",
      render: (a: any) => {
        return formatDropDownValue(a.source?.name);
      },
    },
    {
      title: "Service Type",
      render: (a: any) => {
        return formatDropDownValue(a.svc_type.name);
      },
    },
    {
      title: "Service Subtype",
      width: 150,
      render: (a: any) => {
        return formatDropDownValue(a.svc_sub_type.name);
      },
    },
    {
      title: "Assignee",
      className: "capitalize",
      dataIndex: "assigned_user",
      render: (a: any) => {
        return a
          ? `${a?.first_name ? a?.first_name : ""} ${a?.last_name && a?.last_name !== "null" ? a?.last_name : ""
          }`
          : "-";
      },
    },
    {
      title: "Created at",
      render: (a: any) => {
        return formatDate(a.created_at);
      },
      sorter: true,
      ...(sorters?.sort_column === "created_at"
        ? {
          defaultSortOrder:
            sorters?.sort_val === "asc"
              ? "ascend"
              : sorters?.sort_val === "desc"
                ? "descend"
                : false,
        }
        : {}),
      onHeaderCell: (column: any) => {
        return {
          onClick: (e: any) => {
            dispatch(
              setSorters({
                sort_column: "created_at",
                sort_val: getSortDir(sorters.sort_val),
              })
            );
          },
        };
      },
    },
    {
      title: "Due Date",
      render: (a: any) => {
        return a.due_date ? (
          <>
            {formatDate(a.due_date)}{" "}
            {moment(a.due_date).isBefore(moment()) &&
              a.status.name !== "closed" &&
              a.status.name !== "converted" &&
              a.status.name !== "expired" ? (
              <>
                <br />
                <Tag color="red" className="solid-full">
                  Overdue
                </Tag>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          "--"
        );
      },
      sorter: true,
      ...(sorters?.sort_column === "due_date"
        ? {
          defaultSortOrder:
            sorters?.sort_val === "asc"
              ? "ascend"
              : sorters?.sort_val === "desc"
                ? "descend"
                : false,
        }
        : {}),
      onHeaderCell: (column: any) => {
        return {
          onClick: (e: any) => {
            dispatch(
              setSorters({
                sort_column: "due_date",
                sort_val: getSortDir(sorters.sort_val),
              })
            );
          },
        };
      },
    },

    {
      title: "Opportunities",
      width: 200,
      render: (a: any) => {
        return a.opportunities.map((val: any) => {
          return (
            <Tag color="green">
              {formatDropDownValue(val?.opportunity?.name)}
            </Tag>
          );
        });
      },
    },
    {
      title: "Status",
      render: (a: any) => {
        return a.status.name !== "follow-up" ? (
          <Tag>{titleCase(a.status.name)}</Tag>
        ) : (
          <>
            <Tag>{titleCase(a.status.name)}</Tag> <br />
            <small>
              {a?.follow_up_date
                ? `${formatDate(a?.follow_up_date, "DD MMM'YY")} ${a?.follow_up_time ? a?.follow_up_time : ""
                }`
                : ""}
            </small>{" "}
          </>
        );
      },
    },
    {
      title: "Priority",
      fixed: "right",
      render: (a: any) => {
        return <Tag color="blue">{formatDropDownValue(a.priority.name)}</Tag>;
      },
    },
  ];

  console.log("currentTabName", currentTabName);

  if (currentTabName === "FOLLOW-UP") {
    columns.splice(8, 0, {
      title: "Follow up Date",
      dataIndex: "follow_up_date",
      className: "capitalize",
      width: 150,
      render: (key: any) => {
        return key ? formatDate(key) : "";
      },
      sorter: true,
      ...(sorters?.sort_column === "follow_up_date"
        ? {
          defaultSortOrder:
            sorters?.sort_val === "asc"
              ? "ascend"
              : sorters?.sort_val === "desc"
                ? "descend"
                : false,
        }
        : {}),
      onHeaderCell: (column: any) => {
        return {
          onClick: (e: any) => {
            dispatch(
              setSorters({
                sort_column: "follow_up_date",
                sort_val: getSortDir(sorters.sort_val),
              })
            );
          },
        };
      },
    });
  }

  function getSortDir(currentSort: any) {
    return currentSort == null ? "asc" : currentSort == "asc" ? "desc" : null;
  }

  useEffect(() => {
    const pagenumbers = parseInt(pagination?.offset) / parseInt(pagination?.limit);
    setpageNumber(pagenumbers)
  }, [pagination])

  const onPageChange = (e: any, pageSize: any) => {
    setpageNumber(e - 1)
    setpageSizeCount(pageSize);
    const filter = processFilters();
    let sorter = sorters?.sort_val == null ? {} : sorters;
    selectedStatusId !== "" && (currentTabName === "FOLLOW-UP"
      ? dispatch(
        fetchLeadsList({
          offset: (e - 1) * pageSize,
          limit: pageSize ? pageSize : pagination.limit,
          ...(isUUID(selectedStatusId)
            ? { status_id: selectedStatusId }
            : {}),
          sort_column: "follow_up_date",
          sort_val: "ASC",
          ...filter,
          ...sorter,
        })
      )
      : dispatch(
        fetchLeadsList({
          offset: (e - 1) * pageSize,
          limit: pageSize ? pageSize : pagination.limit,
          ...(isUUID(selectedStatusId)
            ? { status_id: selectedStatusId }
            : {}),
          ...filter,
          ...sorter,
        })
      ))

  };

  const [downloadData, setdownloadData] = useState([] as any);

  const onClickDownload = () => {
    setEmailModal(true);
  };
  function sendMailList(data: any) {
    if (downloadRangePickerValidation?.type === "error" && myPermissions?.indexOf("task_module") === -1 && myPermissions?.indexOf(TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION) === -1) {
      return;
    }
    let filter = processFilters()
    let params: any = {};
    const start = data.date_range[0];
    const end = data.date_range[1];
    params.from_date = start.format("YYYY-MM-DD");
    params.to_date = end.format("YYYY-MM-DD");
    // params.from_date = filter?.from_date;
    // params.to_date = filter?.to_date;
    params.email = data?.email;
    params.type = downloadType;
    // params.status_id = selectedStatusId;
    let param = params
      ? Object.fromEntries(Object.entries({ ...params, ...filter }).filter(([_, v]) => v != null))
      : {};
    dispatch(downloadLeadData(param)).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        message.success("Report sent to your email.");
        setEmailModal(false);
        setdownloadType(downloadReportOptions[0].key);
      }
    });
  }

  const onTabChange = (e: any) => {
    dispatch(setSelectedStatusIds(e))
    setSelectedStatusId(leadStatusList[e - 1].value);
    setcurrentTabName(leadStatusList[e - 1]?.name);
    resetSelectedRows();
    setfollowupdateFlag(false);
    if (leadStatusList[e - 1]?.name !== currentTabName) {
      dispatch(reset())
    }
    // if (leadStatusList[e - 1]?.name === "FOLLOW-UP") {
    //   setfollowupdateFlag(true);
    // }
  };

  const [leadFormVisible, setLeadFormVisible] = useState(false);
  const [ticketFormVisible, setTicketFormVisible] = useState(false);

  function filterFormSubmit(changed: any, all: any) {
    // if (Object.keys(changed)?.indexOf("svc_type_id") !== -1) {
    //   if (changed.svc_type_id) {
    //     dispatch(
    //       fetchMasterTaskSubTypes({ task_type_id: changed.svc_type_id })
    //     );
    //   } else {
    //     setTaskSubTypes([]);
    //     all.svc_type_id = null;
    //     all.svc_svc_sub_type_id = null;
    //   }
    // }
    let filtersObjOld = JSON.parse(JSON.stringify(filters));
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] === "" || all[key] === undefined) {
      } else {
        if (key == "range") {
          filtersObj.from_date = all.range
            ? all.range[0].format("YYYY-MM-DD")
            : null;
          filtersObj.to_date = all.range
            ? all.range[1].format("YYYY-MM-DD")
            : null;
        } else if (
          changed.search_type === "contact" ||
          changed.search_type === "display_id"
        ) {
          filtersObj["search_val"] = null;
          filtersObj["search_type"] = changed.search_type;
          // settingSearchType(changed?.search_type)
        } else if (key == "opportunities") {
          filtersObj[key] = all[key];
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    const news = { ...filtersObjOld, ...filtersObj };

    dispatch(setFilters(filtersObj));
  }
  function resetFilters() {
    let filtersObj = JSON.parse(JSON.stringify(filters));
    if (filtersObj) {
      for (let key of Object.keys(filtersObj)) {
        if (key !== "search_type") {
          filtersObj[key] = null;
        }
        if (key === "search_type") {
          filtersObj[key] = "contact";
        }
        if (
          key === "assigned_user_id" ||
          key === "assigned_team_id" ||
          key === "type_id" ||
          key === "sub_type_id" ||
          key === " category_id"
        ) {
          filtersObj[key] = [] as any;
        }
        // else {
        //   filtersObj[key] = null;
        // }
      }
      dispatch(setFilters(filtersObj));
    }
  }

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  console.log("sssssssssssssss", filters)

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      sethasSelected(true);
    } else {
      sethasSelected(false);
    }
  }, [selectedRowKeys]);

  const fetchCallDet = async () => {
    setResultModal(false);
    setsuccessLoaderMessage(null);
  };

  const updateBulk = (values: any) => {
    setLoadingModal(true);
    setbulkModal(false);
    setlinkLoaderMessage(`Updating...`);
    values.ids = selectedRowKeys;
    if (values.follow_up_date) {
      values.follow_up_time = values.follow_up_date.format("LT");
    }
    dispatch(updateLeadBulk(values)).then((val: any) => {
      if (val.payload.data.statusCode === 200) {
        sethasSelected(false);
        // dispatch()
        getLeadList();
        setLoadingModal(false);
        setResultModal(true);
        dispatch(fetchLeadListCount())
          .then((val: any) => {
            if (val?.payload?.data?.statusCode === 200) {
              setleadCount(val?.payload?.data?.data);
            }
          })
          .catch((ex: any) => console.error(ex));
        setsuccessLoaderMessage(
          `${selectedRowKeys.length} Leads updated successfully.`
        );
        setSelectedRowKeys([]);
      } else {
        sethasSelected(false);
        dispatch(fetchLeadListCount())
          .then((val: any) => {
            if (val?.payload?.data?.statusCode === 200) {
              setleadCount(val?.payload?.data?.data);
            }
          })
          .catch((ex: any) => console.error(ex));
        getLeadList();
        setLoadingModal(false);
        setlinkLoaderMessage(null);
        setSelectedRowKeys([]);
      }
    });
  };

  const resetSelectedRows = () => {
    setSelectedRowKeys([]);
    sethasSelected(false);
  };

  const formElementsBulk = {
    onFinish: (e: any) => {
      updateBulk(e);
    },
    onFinishFailed: () => { },
    onValuesChange: (e: any) => {
      onValueChangeBulkForm(e);
    },
    initialValues: {
      // status_id: leadStatusList?.filter((val: any) => {
      //   return val.name === currentTabName;
      // })[0]?.value,
    },
    formElements: [
      {
        label: "Assignee",
        type: "Select",
        name: "assigned_user_id",
        colSpan: 12,
        allowClear: true,
        placeholder: "Choose Agent",
        options: userList || [],
        // colSpanResponsive: { xs: 24, sm: 24, md: 6, lg: 6, xl: 6 },
      },

      {
        label: "Priority",
        type: "Select",
        name: "priority_id",
        colSpan: 12,
        allowClear: true,
        placeholder: "Choose Priority",
        options: priorityList || [],
        // filterType: "moreFilter",
      },

      // {
      //   type: "Select",
      //   label: "Reason",
      //   name: "reason",
      //   colSpan: 12,
      //   allowClear: true,
      //   placeholder: "Choose Reason",
      //   options: reasonList,
      // },
      {
        label: "Due Date",
        type: "DateTimePicker",
        name: "due_date",
        allowClear: true,
        placeholder: "Choose Date",
        colSpan: 12,
        // colSpanResponsive: { xs: 14, sm: 14, md: 7, lg: 7, xl: 7 },
      },

      {
        label: "Opportunity",
        type: "Select",
        name: "opportunity",
        colSpan: 12,
        allowClear: true,
        placeholder: "Choose Opportunity",
        options: opportunitiesList || [],
        // filterType: "moreFilter",
      },
      {
        label: "Status",
        type: "Select",
        name: "status_id",
        colSpan: 12,
        // allowClear: true,
        placeholder: "Choose Status",
        options: leadStatusList || [],
      },
      {
        label: "Follow up Date",
        type: followupdateFlag ? "DateTimePicker" : "hidden",
        name: "follow_up_date",
        allowClear: true,
        rules: [
          {
            required: followupdateFlag ? true : false,
            message: "Follow up date Required",
          },
        ],
        placeholder: "Choose Date",
        colSpan: followupdateFlag ? 12 : 0,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
        // colSpanResponsive: { xs: 14, sm: 14, md: 7, lg: 7, xl: 7 },
      },
    ],
  };

  const options = [
    {
      value: "contact",
      label: "Contact",
    },
    {
      value: "display_id",
      label: "Lead Id",
    },
  ];

  const prefixSelector = (
    <Form.Item name="search_type" noStyle>
      <Select
        // showSearch
        style={{ width: 120 }}
        defaultValue={"contact"}
      >
        {options?.map((item) => {
          return (
            <Select.Option value={item.value}>
              <Space>
                <span>{item.label}</span>
              </Space>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  useEffect(() => {
    if (
      !filters?.from_date &&
      !filters?.to_date &&
      filters?.from_date !== null &&
      filters?.to_date !== null
    ) {
      dispatch(
        setFilters({
          ...filters,
          from_date: moment().subtract(4, "days").format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
          search_type: "contact",
        })
      );
    }
  }, []);

  const formElements = {
    onFinish: () => { },
    onFinishFailed: () => { },
    onValuesChange: filterFormSubmit,

    filtersApplied: filters
      ? Object.keys(
        Object.fromEntries(
          Object.entries(filters).filter(([_, v]) => v != null && v != false)
        )
      ).length
      : 0,
    initialValues: {
      search_val: filters?.search_val,
      search_type: "contact",

    },
    setValues: {
      ...(filters ? Object.fromEntries(Object.entries(filters)) : {}),
      range: filters?.from_date &&
        filters?.to_date && [
          moment(filters?.from_date),
          moment(filters?.to_date),
        ],
    } as any,
    formElements: [
      // {
      //   label: "",
      //   type: "Input",
      //   name: "search_val",
      //   colSpan: 8,
      //   colSpanResponsive: { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 },
      //   placeholder: ACCOUNT_PREFIX === 'lupinconnect' ? "Search by THB ID, Name, WhatsApp Number, Email" : "Search by  Name, Phone Number",
      //   filterType: "",
      //   prefix: <SearchOutlineIcon width={"13"} height={"13"} />,
      // },
      {
        label: "",
        type:
          filters?.search_type === "contact" ||
            !filters ||
            !filters?.search_type
            ? "Input"
            : "InputNumber",
        name: "search_val",
        colSpan: 9,
        placeholder:
          (filters?.search_type === "contact" || !filters || !filters?.search_type)
            ? (ACCOUNT_PREFIX === 'lupinconnect' ? "Search by THB ID, Name, WhatsApp Number, Email" : "Search by  Name, Phone Number")
            : "Search by Lead id",
        colSpanResponsive: { xs: 24, sm: 24, md: 9, lg: 9, xl: 9 },
        // prefix: <SearchOutlineIcon width={"13"} height={"13"} />,
        addonBefore: prefixSelector,
        allowClear: true,
      },
      {
        label: "Agents",
        type: "Select",
        name: "assigned_user_id",
        colSpan: 12,
        mode: "multiple",
        allowClear: true,
        placeholder: "Agents",
        options: userList || [],
        filterType: "moreFilter",
      },
      ...(
        myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH) !== -1 ? [] : [{
          label: `${t("CRM.Common.Fields.Branch")}`,
          type: "Select",
          name: "branch_id",
          colSpan: 4,
          mode: "multiple",
          allowClear: true,
          filterType: "moreFilter",
          placeholder: "Hospital",
          options: branches?.map((val: any) => {
            return { name: formatDropDownValue(val.name), value: val.id };
          }) || [],
        }]
      ),

      {
        label: "Service Type",
        type: "Select",
        name: "svc_type_id",
        colSpan: 12,
        allowClear: true,
        placeholder: "Service Type",
        options: taskTypesList || [],
        mode: "multiple",
        filterType: "moreFilter",
      },
      {
        label: "Service Sub Type",
        type: "Select",
        name: "svc_sub_type_id",
        colSpan: 12,
        allowClear: true,
        mode: "multiple",
        placeholder: "Service Subtype",
        options: taskSubTypesList || [],
        filterType: "moreFilter",
      },
      {
        label: "Priority",
        type: "Select",
        name: "priority_id",
        colSpan: 12,
        allowClear: true,
        mode: "multiple",
        placeholder: "Priority",
        options: priorityList || [],
        filterType: "moreFilter",
      },
      {
        label: "Opportunities",
        type: "Select",
        name: "opportunities",
        mode: "multiple",
        colSpan: 12,
        allowClear: true,
        placeholder: "Opportunities",
        options: opportunitiesList || [],
        filterType: "moreFilter",
      },
      {
        label: "",
        type: "RangePicker",
        name: "range",
        colSpan: 8,
        colSpanResponsive: { xs: 14, sm: 14, md: 8, lg: 8, xl: 8 },
        filterType: "",
      },
      {
        label: "Source",
        type: "Select",
        name: "source_id",
        colSpan: 3,
        allowClear: true,
        placeholder: "Source",
        options: sourceList || [],
        filterType: "moreFilter",
        // mode: "multiple",
      },
      {
        label: "",
        type: "clear_filters",
        onClick: resetFilters,
        filterType: "moreFilter",
      },
      {
        label: "",
        type: "Button",
        name: (
          <>
            <DownloadOutlined />
          </>
        ),
        colSpan: 4,
        flex: "none",
      },
    ],
  };
  if (screenType.mobile) {
    formElements.formElements = formElements.formElements.slice(0, -1);
  }
  const mobileFilters =
    screenType.mobile && formElements
      ? MoreFilters(
        onPageChange,
        pagination.total,
        formElements,
        onClickDownload,
        "multiple",
        1,
        true
      )
      : null;

  console.log("cccccccccccccccccccc", filterValues);

  var allTabs = leadStatusList?.map((val: any) => {
    return {
      title: (
        <span>
          {val.name}{" "}
          <span>
            {filterValues && filterValues > 0 ? (
              <span>
                {val.name === currentTabName && `(${pagination.total})`}
              </span>
            ) : (
              <span>
                {leadCount
                  ? leadCount[val.key]
                    ? `(${leadCount[val.key]})`
                    : ""
                  : ""}
              </span>
            )}
          </span>
        </span>
      ),
      key: val.key,
      component: (
        <>
          {screenType.mobile ? (
            <>
              <Row>
                <Col span={24} className="bg-white">
                  {formElements && (
                    <div className="crm-mobile-filters">{mobileFilters}</div>
                  )}
                  {!loading && (
                    <>
                      <List
                        className="crm-mobile-list padding-bottom-20 paddingx20"
                        itemLayout="vertical"
                        dataSource={leadsList}
                        renderItem={(item: any, index) => (
                          <List.Item key={index}>
                            <Row>
                              <Col span={24}>
                                <Row>
                                  <Col style={{ width: "calc(100% - 65px)" }}>
                                    <a
                                      onClick={() =>
                                        history.push(
                                          ROUTE_CRM_LEAD_DETAILS(item.id)
                                        )
                                      }
                                      className="capitalize font16"
                                    >
                                      {contactNameFormat(
                                        item?.contact?.first_name,
                                        item?.contact?.last_name,
                                        item?.contact?.gender?.name,
                                        item?.contact?.dob
                                      )}
                                    </a>
                                  </Col>
                                  <Col
                                    style={{ width: "65px" }}
                                    className="text-right"
                                  >
                                    <Tag color={"blue"}>
                                      {formatDropDownValue(
                                        item?.priority?.name
                                      )}
                                    </Tag>
                                  </Col>
                                </Row>
                                Ph:{" "}
                                <TablePhoneNumberCopier
                                  phone={
                                    "+" +
                                    item.contact?.country_code +
                                    item.contact?.phone
                                  }
                                ></TablePhoneNumberCopier>
                                <Divider type="vertical" />
                                <span>
                                  <small>
                                    {" "}
                                    <strong>{"#" + item?.display_id}</strong>{" "}
                                  </small>
                                </span>
                                <br />
                                <span>
                                  {formatDropDownValue(item.svc_type?.name)}{" "}
                                  <Divider type="vertical" /> Assignee:{" "}
                                  {item.assigned_user
                                    ? `${item.assigned_user?.first_name
                                      ? item.assigned_user?.first_name
                                      : ""
                                    } 
                                  ${item.assigned_user?.last_name &&
                                      item.assigned_user?.last_name !== "null"
                                      ? item.assigned_user?.last_name
                                      : ""
                                    }`
                                    : "-"}
                                </span>
                                <Row>
                                  <Col flex={"auto"}>
                                    <span>
                                      Created at: {formatDate(item.created_at)}
                                    </span>
                                  </Col>
                                  {moment(item?.due_date).isBefore(moment()) &&
                                    item.status.name !== "closed" &&
                                    item.status.name !== "converted" &&
                                    item.status.name !== "expired" ? (
                                    <Col style={{ width: "65px" }}>
                                      <Tag color="red" className="solid-full">
                                        Overdue
                                      </Tag>
                                    </Col>
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    </>
                  )}
                  {loading && (
                    <>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                    </>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <Row className="padding10 selectionRows">
              <Col span={24}>
                {hasSelected ? (
                  <p className="mb20">
                    <>
                      <span
                        style={{ marginLeft: 8 }}
                        className="primary-color-text bold font16"
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={resetSelectedRows}
                        >
                          <CloseOutlined />
                        </span>
                        <span> {selectedRowKeys.length} Leads Selected</span>
                      </span>
                      <Button
                        type="primary"
                        style={{ float: "right" }}
                        onClick={showBulkModel}
                      >
                        Bulk Update
                      </Button>
                    </>
                  </p>
                ) : (
                  ""
                )}
                <TableLayout
                  current={pageNumber + 1}
                  rowSelection={rowSelection}
                  pageType="withTab"
                  defaultCurrentPage={pageNumber + 1}
                  loading={loading}
                  buttonEvt={() => onClickDownload()}
                  dataSource={leadsList}
                  filters="multiple"
                  columns={columns}
                  onChange={(e, ee) => onPageChange(e, ee)}
                  type="morefilters"
                  total={pagination.total}
                  showTotal={true}
                  pagination={{
                    position: ["none", "none"],
                    pageSize: pagination.limit,
                  }}
                  formData={formElements}
                  maxWidth={1500}
                  defaultPageSize={pageSizeCount}
                  showSizeChangerFlag={true}
                />
              </Col>
            </Row>
          )}
        </>
      ),
    };
  });

  const changeDownloadType = (e: any) => {
    setdownloadType(e.target.value);
  };

  return (
    <Fragment>
      <CSVLink
        data={downloadData}
        filename="LeadList.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
      <PageHeader
        ghost={false}
        title={
          <Row
            style={screenType.mobile ? { paddingLeft: "25px" } : {}}
            align="middle"
          >
            <Col flex="none">Leads List</Col>
            <Col>
              {!screenType.mobile && leadCount?.follow_up_pending > 0 ? (
                <span className="follow-up-pending-header">
                  Follow-Up Pending: <Tag>{leadCount.follow_up_pending}</Tag>{" "}
                  Leads
                </span>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        }
        extra={
          <HeaderButtons
            setLeadFormVisible={setLeadFormVisible}
            setTicketFormVisible={setTicketFormVisible}
          />
        }
      ></PageHeader>

      <Row>
        <Col span={24} className="crm-tabs">
          <TabsLayout activeTab={selectedStatusIds} AllTabs={allTabs} onChange={onTabChange}></TabsLayout>
        </Col>
      </Row>
      {leadFormVisible && (
        <CreateLeadForm
          saveLeadSuccess={() => setLeadFormVisible(false)}
          setLeadFormVisible={(val: any) => setLeadFormVisible(val)}
          leadFormVisible={leadFormVisible}
        ></CreateLeadForm>
      )}
      {ticketFormVisible && (
        <AddTicket
          onTicketSave={() => setTicketFormVisible(false)}
          setTicketFormVisible={(val: any) => setTicketFormVisible(val)}
          ticketFormVisible={ticketFormVisible}
        ></AddTicket>
      )}
      {emailModal && (
        <Modal
          centered
          visible={emailModal}
          onCancel={() => setEmailModal(false)}
          title="Send Lead List in Email"
          footer={null}
        >
          <Form
            onFinish={sendMailList}
            onValuesChange={downloadDataOnValuesChange}
            className="crm-form"
            initialValues={{ email: profile?.email }}
          >
            <Form.Item name="type" label="Type">
              {/* <Select
                defaultValue={downloadReportOptions[0]}
                onChange={changeDownloadType}
              >
                {
                  downloadReportOptions.map((item: any, index: any) => {
                    return (
                      <Option value={item.key}>{item.name}</Option>
                    )
                  })
                }
              </Select> */}
              <Radio.Group
                onChange={changeDownloadType}
                defaultValue={downloadReportOptions[0].key}
              >
                <Space direction="vertical">
                  {downloadReportOptions.map((item: any, index: any) => {
                    return <Radio value={item.key}>{item.name}</Radio>;
                  })}
                </Space>
              </Radio.Group>
            </Form.Item>
            {
              (myPermissions?.indexOf("task_module") === -1 && myPermissions?.indexOf(TASK_MODULE_ACTIONS_REMOVE_DATE_RISTRICTION) === -1) ? (
                <Form.Item
                  name="date_range"
                  label="Date Range"
                  {...(downloadRangePickerValidation?.type === "error"
                    ? {
                      validateStatus: "error",
                      help: downloadRangePickerValidation?.message,
                    }
                    : {}
                  )}
                  rules={[
                    {
                      required: true,
                      message: "Please select a valid date range.",
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={(d: any) => {
                      return d > moment();
                    }}
                    style={{ width: "100%" }}
                    format="DD MMM'YY"
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="date_range"
                  label="Date Range"
                  rules={[
                    {
                      required: true,
                      //  message: "Please select a valid date range.",
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={(d: any) => {
                      return d > moment();
                    }}
                    style={{ width: "100%" }}
                    format="DD MMM'YY"
                  />
                </Form.Item>
              )
            }
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { type: "email", message: "Please enter a valid email Id." },
                { required: true, message: "Please enter an email Id." },
              ]}
            >
              <Input placeholder="Enter email Id" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {bulkModal && (
        <Modal
          title={`Bulk Update (${selectedRowKeys.length} Leads Selected)`}
          centered
          visible={bulkModal}
          onCancel={() => setbulkModal(false)}
          width={700}
          footer={false}
          className="modalFormButton form-modal"
          style={{ paddingBottom: "0px" }}
        >
          <FormBar
            name={"updateLead"}
            onFinish={formElementsBulk.onFinish}
            onFinishFailed={formElementsBulk.onFinishFailed}
            formElements={formElementsBulk.formElements}
            onValuesChange={formElementsBulk.onValuesChange}
            initialValues={formElementsBulk.initialValues}
            buttonText={"Update"}
            onDateTimeChange={handleDateChangeArray}
            disabledTimeValues={() => {
              return {
                disabledHours: () => range(0, 24).splice(0, excludedHours),
                disabledMinutes: () => range(0, excludedMinutes),
              };
            }}
          ></FormBar>
        </Modal>
      )}
      {loadingModal && (
        <SpinLoadingLayout
          modalVisible={loadingModal}
          message={linkLoaderMessage}
        ></SpinLoadingLayout>
      )}
      {resultModal && (
        <ResultModalLayout
          type="success"
          modalVisible={resultModal}
          setModalVisible={(data) => fetchCallDet()}
          title={successLoaderMessage}
        // subtitle="Call log status updated to closed"
        ></ResultModalLayout>
      )}
    </Fragment>
  );
}

export default CRMLeadsList;
