import { Modal, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAllAgents } from "../../../../redux/reducers/agents.slice";
import { updateAppointmentTicketStatusDetails } from "../../../../redux/reducers/appointment.slice";
import { fetchContact } from "../../../../redux/reducers/contacts.slice";
import { fetchReasonListLead } from "../../../../redux/reducers/leads.slice";
import { fetchMasterTaskDropDown } from "../../../../redux/reducers/master.slice";
import { fetchTeamById, fetchTeams, reset } from "../../../../redux/reducers/team.slice";
import { updateGoogleReview, updateTicket, updateTicketRemarksOnly } from "../../../../redux/reducers/tickets.slice";
import { fetchAgents } from "../../../../redux/reducers/user.slice";
import { formatDropDownValue } from "../../../../shared/Utils/utilities";
import { TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS, TASK_MODULE_ACTIONS_CIPLA_FILE_UPLOAD, TASK_MODULE_ACTIONS_CLEAR_REMARKS_IN_EDIT_TICKET, TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET, TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT, TASK_MODULE_ACTIONS_DISABLE_DUE_DATE_UPDATE_TICKET, TASK_MODULE_ACTIONS_DISABLE_PRIORITY_UPDATE_TICKET, TASK_MODULE_ACTIONS_DISABLE_REMARKS_TICKET, TASK_MODULE_ACTIONS_DISABLE_TICKET_STATUS_UPDATE_TICKET, TASK_MODULE_ACTIONS_HIDE_ATTACHMENT, TASK_MODULE_ACTIONS_MANDATORY_REMARKS_TICKET, TASK_MODULE_ACTIONS_REPLY_TO_EMAIL, TASK_MODULE_ACTIONS_SHOW_ADD_REMARKS, TASK_MODULE_ACTIONS_UPDATE_TICKET, TASK_MODULE_ACTIONS_UPDATE_TICKET_REMARKS_ONLY } from "../../../../shared/components/permission.constants";
import { ACCOUNT_PREFIX, RootState } from "../../../../shared/constants";
import FormBar from "../../../../shared/layouts/formbar.layout";
import ResultModalLayout from "../../../../shared/layouts/resultmodal.layout";
import UploadPrescription from "../../../../shared/layouts/uploadprescription";
import UploadPrescriptionBaseSixtyFour from "../../../../shared/layouts/uploadprescriptionbasesixtyfour";
import { ROUTE_CRM_TICKET_LIST } from "../../../../shared/routes/crm.routes.constants";

type Props = {
  ticket: any;
  parentCallback?: (e: any) => void;
  layout?: string;
  disableStatus?: any;
  appointmentRequestStatus?: any;
};

function UpdateTicketForm({
  ticket,
  parentCallback = (e: any) => { },
  layout = "vertical",
  disableStatus = [],
  appointmentRequestStatus,
}: Props) {
  const dispatch = useDispatch();
  const { teams, team } = useSelector((state: RootState) => state.team);

  const history = useHistory();
  const [QCTicketForm, setQCTicketForm] = useState(null as any);
  const { taskDropDown } = useSelector((state: RootState) => state.master);
  const { agentsAll } = useSelector((state: RootState) => state.agents);
  const { agents } = useSelector((state: RootState) => state.user);
  const [priorityList, setPriorityList] = useState([] as any);
  const [statusList, setStatusList] = useState([] as any);
  const [userList, setUserList] = useState([] as any);
  const [teamList, setTeamList] = useState(null as any);
  const [updateBtnDisabled, setupdateBtnDisabled] = useState(null as any)
  const [assigneeList, setAssigneeList] = useState([] as any);
  const [selectedStatus, setSelectedStatus] = useState(
    ticket?.status?.name as any
  );
  const [disabledRemarks, setdisabledRemarks] = useState(false as any)
  const [replyType, setreplyType] = useState('internalNote' as any)
  const [subDispositionData, setsubDispositionData] = useState(null as any)
  const [assignTo, setAssignTo] = useState(null as any);
  const [disableStatusData, setdisableStatus] = useState(disableStatus as any);
  const [modalVisible, setModalVisible] = useState(false as any);
  const [contentBytes, setcontentBytes] = useState(null as any)
  const [hours, sethours] = useState([] as any)
  const [excludedMinutes, setexcludedMinutes] = useState(0)
  const [excludedHours, setexcludedHours] = useState(0)
  const [loading, setloading] = useState(false)
  const [actualReasonList, setactualReasonList] = useState([] as any)
  const [currentStatus, setcurrentStatus] = useState(null as any)
  const [usersByTeam, setusersByTeam] = useState(null as any)
  const [assigneeValue, setassigneeValue] = useState(null as any)
  const [allDisabled, setallDisabled] = useState(false as any)
  const [fileUploadIdsArray, setfileUploadIdsArray] = useState([] as any)
  const { t } = useTranslation();
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  const [mailCCList, setmailCCList] = useState([] as any[])
  const [tagInputVisiable, settagInputVisiable] = useState(false as any)
  const [showCCList, setshowCCList] = useState(false)
  const [tagInputValues, settagInputValues] = useState(null as any)
  const [showDoctorDetails, setshowDoctorDetails] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setfiles] = useState(null as any)
  const [documentName, setdocumentName] = useState(null as any)

  console.log("myPermissions", myPermissions)

  const onClickUpladFile = () => {
    setIsModalOpen(true);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };


  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fileUploaded = async (response: any) => {
    // debugger
    handleCancel()
    setfiles(response)
  };
  const getDescription = (value: any) => {
    // 
    setdocumentName(value)
  }




  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  useEffect(() => {
    setcurrentStatus(ticket?.status_id)
  }, [])




  const handleDateChangeArray = (e: any) => {
    const hourStarter = moment().format("A") === "PM" && Number(moment().format("hh")) !== 12 ? 12 : 0;
    const selectedDate = e.format("YYYY-MM-DD");
    const selectedHours = Number(e.format("hh"));
    const currentDate = moment().format("YYYY-MM-DD");

    if (selectedDate > currentDate) {
      setexcludedHours(0)
      setexcludedMinutes(0)
    }
    else {
      setexcludedHours(hourStarter + Number(moment().format('hh')))
      setexcludedMinutes(Number(moment().format('mm')))

      if (Number(moment().format('hh')) !== 12) {
        if (selectedHours > Number(moment().format("hh"))) {
          setexcludedMinutes(0)
        }
        else {
          setexcludedMinutes(Number(moment().format('mm')))
        }
      }
      else {
        setexcludedMinutes(Number(moment().format('mm')))
        if (selectedHours === 12) {
          setexcludedMinutes(Number(moment().format('mm')))
        }
        else {
          setexcludedMinutes(0)
        }
      }
    }
  }


  useEffect(() => {
    if (taskDropDown.length > 0) {
      let temp, vals;
      temp = taskDropDown.filter((val: any) => {
        return val.name == "priority" && val.mode == "ticket";
      });
      if (temp.length > 0) {
        vals = temp[0].values.map((val: any) => {
          return { name: formatDropDownValue(val.name), value: val.id };
        });
        setPriorityList(vals);
      }
      temp = taskDropDown.filter((val: any) => {
        return val.name == "status" && val.mode == "ticket";
      });
      if (temp.length > 0) {
        vals = temp[0].values.filter((val: any) => {
          return (
            disableStatusData.indexOf(formatDropDownValue(val.name)) === -1
          );
        });
        vals = vals.map((val: any) => {
          return { name: formatDropDownValue(val.name), value: val.id };
        });

        setStatusList(vals);
      }

    }
  }, [taskDropDown, disableStatusData,]);

  useEffect(() => {
    dispatch(fetchReasonListLead()).then((val: any) => {
      setsubDispositionData(val?.payload?.data?.data?.items)
    })
  }, [])


  useEffect(() => {
    let temp;
    temp = taskDropDown?.filter((val: any) => {
      return val.name === "reason" && val.mode === "ticket";
    });
    if (temp.length > 0) {
      let test1 = temp[0]?.values?.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      });
      dispatch(fetchReasonListLead()).then((val: any) => {
        // const data = val?.payload?.data?.data?.items?.filter((val: any) => {
        //   return val.mode === 'ticket' && val.type.id === ticket?.type_id && val.sub_type.id === ticket.sub_type_id &&
        //     val.category_id === ticket?.category_id && val.status_id === currentStatus
        // })[0]?.reason_ids;

        let data = [] as any;
        if (selectedStatus === 'denied' || selectedStatus === 'DENIED') {
          data = val?.payload?.data?.data?.items?.filter((val: any) => {
            return (
              val.mode === "ticket" &&
              val.status_id === currentStatus
            );
          })[0]?.reason_ids;
        }
        else {
          data = val?.payload?.data?.data?.items?.filter((val: any) => {
            return (
              val.mode === 'ticket' &&
              val.type.id === ticket?.type_id &&
              val.sub_type.id === ticket.sub_type_id &&
              val.category_id === ticket?.category_id &&
              val.status_id === currentStatus
            );
          })[0]?.reason_ids;
        }

        const datas = test1?.filter((element: any) =>
          data?.some((e: any) => element?.value === e)
        );

        setactualReasonList(datas ? datas : [])
      })
    }
  }, [currentStatus])







  useEffect(() => {
    if (agentsAll?.length > 0) {
      let vals = agentsAll?.map((val: any) => {
        return {
          name: formatDropDownValue(
            `${val?.user?.first_name && val?.user?.first_name !== null
              ? val?.user?.first_name
              : ""
            } ${val?.user?.last_name && val?.user?.last_name !== null
              ? val?.user?.last_name
              : ""
            }`
          ),
          value: val?.user_id,
        };
      });
      setUserList(vals);
      if (ticket?.assigned_user_id) {
        setAssigneeList(vals || []);
      }
    }
    if (agents?.length > 0) {
      let vals = agents?.map((val: any) => {
        return {
          name: formatDropDownValue(
            `${val?.user?.first_name && val?.user?.first_name !== null
              ? val?.user?.first_name
              : ""
            } ${val?.user?.last_name && val?.user?.last_name !== null
              ? val?.user?.last_name
              : ""
            }`
          ),
          value: val?.user_id,
        };
      });
      setUserList(vals);
      if (ticket?.assigned_user_id) {
        setAssigneeList(vals || []);
      }
    }
  }, [agentsAll, agents]);




  useEffect(() => {

    if (teams?.length) { //&& ticket?.assigned_team_id
      let vals = teams?.map((val: any) => {
        return { name: val?.name ? formatDropDownValue(val?.name) : "", value: val?.id };
      });
      setTeamList(vals || []);
    }

  }, [teams])

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(reset());
    });

    return () => {
      unlisten();
    };
  }, [history]);






  useEffect(() => {
    let vals = team?.users.length > 0 && team?.users?.map((val: any) => {
      return { name: formatDropDownValue(`${val?.user?.first_name ? val?.user?.first_name : ''} ${val?.user?.last_name ? val?.user?.last_name : ''}`), value: val.user_id };
    });
    setAssigneeList(vals || []);
  }, [team])



  useEffect(() => {

    (async () => {
      ticket.contact_id && await dispatch(fetchContact(ticket.contact_id));
      taskDropDown.length == 0 && await dispatch(fetchMasterTaskDropDown());
      if (ACCOUNT_PREFIX === 'ciplanew') {
        await dispatch(fetchAgents({ limit: 15000 }))
      }
      else {
        await dispatch(fetchAllAgents({ limit: 15000 }));
      }

      if (ticket?.assigned_team_id) {
        await dispatch(fetchTeamById(ticket?.assigned_team_id))
        await dispatch(fetchTeams())
      }

      setassigneeValue(ticket?.assigned_user_id)

      !mailCCList?.length && ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.cc?.length > 0 && setmailCCList(ticket?.history?.[ticket?.history?.length - 1]?.ticket_email_data?.cc?.split(";").map((val: any) => {
        return { key: val, value: val, label: val }
      }))
    })()

  }, [ticket]);


  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getFileType = () => {
    const bytes = contentBytes.byteContents;
    const mimeInfo = bytes.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mimeInfo && mimeInfo.length > 1) {
      return mimeInfo[1]
    }
  }



  const onFinishTicket = (values: any) => {
    (async function () {
      try {

        setloading(true)

        if (myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_UPDATE_TICKET_REMARKS_ONLY) !== -1) {
          const response = await dispatch(updateTicketRemarksOnly({ id: ticket.id, data: values }))
          if (response?.payload?.data?.statusCode === 200) {
            // message.success("Updated Successfully")
            setloading(false)
            setModalVisible(true);
            parentCallback({});
            setTimeout(() => {
              onCancelModal();
            }, 2500);
          }
        }

        else {

          if ((ticket?.allowed_permission && ticket?.allowed_permission?.google_my_business_review_reply === true) && values.reply_type === "reviewReply") {
            const data = {
              "comment": values?.remarks
            }
            const response = await dispatch(updateGoogleReview({ id: ticket.id, data: data }))
            if (response?.payload?.data?.statusCode === 200) {
              message.success("Google review remarks successfully updated")
            }
          }

          if (values.due_date) values.due_date = moment(values.due_date).format();
          if (values.follow_up_date) {
            values.follow_up_date = moment(values.follow_up_date).format();
            values.follow_up_time = moment(values.follow_up_date).format("LT");
          }
          // values.reason_id = values.reason_id;
          values.assigned_user_id = values.assignee || null;

          values.assigned_team_id = values.assign_to === "team" ? values.teams : null;

          let selectedStatus = statusList.filter((val: any) => {
            return values.status_id == val.value;
          });
          // if (contentBytes) {
          //   console.log("contentBytes?.byteContents", contentBytes?.byteContents)
          //   values.content_bytes = contentBytes?.byteContents?.split(',')[1];
          //   values.content_type = getFileType()
          //   values.file_name = documentName + "." + contentBytes.ext
          // }

          const ccs = values?.cc?.map((val: any) => {
            return val.key
          }).join(";")
          values.cc = ccs;
          // debugger
          if (fileUploadIdsArray?.length && replyType === "email") {
            values.file_upload_ids = fileUploadIdsArray?.map((val: any) => {
              return { ...val, content_bytes: val.content_bytes?.split(',')[1] }
            })
          }

          else if (files?.length && replyType !== "email") {
            values.file_upload_ids = files
          }

          if (replyType === 'email') {
            values.remarks = values.remarks?.replace(/\n/g, '<br />').replace(/\t/g, '\\t')
          }

          const response = await dispatch(
            updateTicket({ id: ticket.id, data: values })
          );
          if (
            response.payload.status === 200 ||
            response.payload.status === 201
          ) {
            // message.success("Updated Successfully")
            setloading(false)
            setModalVisible(true);
            parentCallback({});
            setTimeout(() => {
              onCancelModal();
            }, 2500);
          }
        }



      } catch (e) {
        console.error(e);
      }
    })();
  };
  const onStatusChange = (e: any) => {
    let selectedStatus = statusList?.filter((val: any) => {
      return val.value === e;
    });
    setSelectedStatus(selectedStatus[0].name);
    setcurrentStatus(selectedStatus[0]?.value)

    if (selectedStatus[0]?.name?.toLowerCase() == "follow-up") {
      setQCTicketForm(formFollowup);
    } else {
      setQCTicketForm(formBasic);
    }
    // debugger

    if (selectedStatus[0].name?.toLowerCase() === "feedback received") {
      setshowDoctorDetails(true)
    }
    else {
      setshowDoctorDetails(false)
    }
  };
  async function changeSelectedAssignType(e: any) {
    setAssignTo(e);
    if (e == "agent") {
      setAssigneeList(userList || []);
      setTeamList(null)
    }
    if (e == "team") {
      // setAssigneeList(teamList);
      setassigneeValue(null)
      await dispatch(fetchTeams({ limit: 15000 }));
      setAssigneeList([])
    }
  }
  async function onChangeAssignee(e: any) {
    if (assignTo == "agent") {
      setassigneeValue(e)
    }
    if (assignTo == "team") {
      setassigneeValue(null)
    }

  }



  const onChangeTeams = async (teamId: any) => {

    await dispatch(fetchTeamById(teamId))

  }














  const onChangeMailCC = (values: any) => {
    const aaa = [...mailCCList]?.filter((val: any) => {
      return val.key !== values;
    });
    setmailCCList([...aaa])
  }

  const onClickAddNewCC = () => {
    settagInputVisiable(true)
  }

  const handleTagInputConfirm = (e: any) => {
    // 
    settagInputValues(null)
    const newEmail = e.target.value;
    const found = mailCCList?.some((el: any) => el.key === newEmail);
    // 
    let emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;


    if (newEmail) {
      if (!mailCCList?.length) {
        setmailCCList([{ key: newEmail, value: newEmail, label: newEmail }])
      }
      else {
        if (!found) {
          if (emailPattern.test(newEmail)) {
            setmailCCList([...mailCCList, { key: newEmail, value: newEmail, label: newEmail }])

          }
          else {
            message.error("Please enter valid email id.");

          }
        }
        else {
          message.error("Same email id already added.");
        }
      }

    }
    settagInputVisiable(false)
  }

  const handleTagInputChange = (e: any) => {
    settagInputValues(e.target.value)
  }



  const onChangeReplyType = (e: any) => {
    setshowCCList(e.target.value === 'email')
    setreplyType(e.target.value)
    setdocumentName(null)
  }

  useEffect(() => {

    if (myPermissions?.indexOf(TASK_MODULE_ACTIONS_UPDATE_TICKET) === -1 || (ticket?.allowed_permission && ticket?.allowed_permission?.update_ticket_view === false)) {
      setupdateBtnDisabled(true)
      setdisabledRemarks(true)

    }
    if (myPermissions?.indexOf(TASK_MODULE_ACTIONS_UPDATE_TICKET_REMARKS_ONLY) !== -1) {
      setupdateBtnDisabled(false)
      setdisabledRemarks(false)
    }
    if (ticket?.allowed_permission && ticket?.allowed_permission?.update_ticket_view === true) {
      setupdateBtnDisabled(false)
      setdisabledRemarks(false)
    }
    if (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_REMARKS_TICKET) !== -1) {
      setdisabledRemarks(true)
    }




    if (myPermissions?.indexOf(TASK_MODULE_ACTIONS_UPDATE_TICKET) === -1) {
      setallDisabled(true)
    }
    if (myPermissions?.indexOf(TASK_MODULE_ACTIONS_UPDATE_TICKET_REMARKS_ONLY) !== -1) {
      setallDisabled(true)
    }

    if (ticket?.status?.name === "feedback_received") {
      setshowDoctorDetails(true)
    }
    else {
      setshowDoctorDetails(false)
    }


  }, [ticket])





  const numbers = Array.from({ length: 10 }, (_, index) => ({ name: `${index + 1}`, value: index + 1 }));


  const formBasic = {
    heading: `Update ${t("CRM.Tickets.Fields.Ticket")}` as any,
    onFinish: onFinishTicket,
    onFinishFailed: onFinishFailed,
    // onValuesChange: filterFormSubmit,
    initialValues: {
      priority_id: ticket?.priority_id,
      cc: mailCCList,
      reason_id: ticket?.reason_id,
      doctor_rating: ticket?.doctor_rating,
      doctor_remarks: ticket?.doctor_remarks,
      status_id: ticket?.status_id,
      assign_to: ticket?.assigned_team_id
        ? "team"
        : ticket?.assigned_user_id
          ? "agent"
          : null,
      teams: ticket?.assigned_team_id,
      assignee: ticket?.assigned_user_id || null,

      due_date:
        ticket?.due_date === undefined
          ? moment(new Date(), "YYYY-MM-DD")
          : moment(ticket?.due_date),
      remarks: myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_CLEAR_REMARKS_IN_EDIT_TICKET) !== -1 ? "" : ticket?.remarks,
      reply_type: (myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS) !== -1) ? 'internalNote' :
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) !== -1 ? 'email' : 'internalNote',
    },
    setValues: {
      assignee: assigneeValue,
      cc: mailCCList
      // reply_type: (myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS) !== -1) ? 'internalNote' :
      //   myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) !== -1 ? 'email' : null,

    },
    buttonText: `Update ${t("CRM.Tickets.Fields.Ticket")}`,
    buttonType: "primary",
    disabedSubmitButton: updateBtnDisabled,
    formElements: [
      {
        label: `${t("CRM.Tickets.Fields.Ticket")} Status`,
        type: "Select",
        name: "status_id",
        colSpan: layout == "vertical" ? 24 : 8,
        options: statusList,
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_TICKET_STATUS_UPDATE_TICKET) !== -1 ? true : false),
        onChange: onStatusChange,
        rules: [{ required: true, message: "Please select status" }],
      },
      ...(
        showDoctorDetails ? [
          {
            label: "Doctor Rating",
            type: "Select",
            name: "doctor_rating",
            colSpan: layout == "vertical" ? 24 : 8,
            options: numbers,

          },
          {
            label: "Doctor Remarks",
            type: "Input",
            name: "doctor_remarks",
            colSpan: layout == "vertical" ? 24 : 8,
            // options: numbers,

          },
        ] : []
      ),
      ...(actualReasonList?.length > 0 ? [{
        label: "Reasons",
        type: "Select",
        name: "reason_id",
        colSpan: layout == "vertical" ? 24 : 8,
        disabled: allDisabled,
        options: actualReasonList,
      }] : []),
      {
        label: "Due Date",
        type: "DateTimePicker",
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_DUE_DATE_UPDATE_TICKET) !== -1 ? true : false),
        name: "due_date",
        rules: [{ required: true, message: "Please select due date" }],
        colSpan: layout == "vertical" ? 24 : 8,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
      },

      {
        label: "Priority",
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_PRIORITY_UPDATE_TICKET) !== -1 ? true : false),
        type: "Select",
        name: "priority_id",
        rules: [{ required: true, message: "Please select priority" }],
        colSpan: layout == "vertical" ? 24 : 8,
        options: priorityList,
      },
      ...(
        replyType === "email" ||
          (myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_ATTACHMENT) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_ATTACHMENT?.split(".")[0]) !== -1)
          ? [] : [{
            label: "Select File",
            type: "Button",
            name: `${documentName ? 'Attached File: ' + documentName : 'Add Attachment'} `,
            colSpan: layout == "vertical" ? 24 : 8,
            disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT) !== -1 ? true : false),
            options: assigneeList,
            // options: leadStatusList,
            onClick: onClickUpladFile,
          },]),

      {
        label: "Assign To",
        type: "Select",
        name: "assign_to",
        rules: [{ required: true, message: "Please select assignment type" }],
        colSpan: layout == "vertical" ? 24 : 8,
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET) !== -1 ? true : false),

        options: [
          { value: "agent", name: "Agent" },
          { value: "team", name: "Team" },
        ],
        onChange: changeSelectedAssignType,
      },
      //show team list 
      ...(teamList?.length > 0 ? [{
        label: "Teams",
        type: "Select",
        name: "teams",
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET) !== -1 ? true : false),
        // rules: [{ required: true, message: "Please select assignee" }],
        colSpan: layout == "vertical" ? 24 : 8,
        options: teamList,
        onChange: onChangeTeams,
      }] : []),

      {
        label: "Assignee",
        type: "Select",
        name: "assignee",
        rules: [{ required: true, message: "Please select assignee" }],
        colSpan: layout == "vertical" ? 24 : 8,

        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET) !== -1 ? true : false),
        options: assigneeList,
        onChange: onChangeAssignee
      },
      ...(ticket?.source?.name === 'email' || (ticket?.allowed_permission && ticket?.allowed_permission?.google_my_business_review_reply === true) ? [{
        label: "Remarks",
        type: 'RadioRound',
        name: "reply_type",
        rules: [{ required: (myPermissions?.indexOf(TASK_MODULE_ACTIONS_MANDATORY_REMARKS_TICKET) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS?.split(".")[0]) !== -1 ? true : false), message: "Please select assignment type" }],
        disabled: disabledRemarks,
        onChange: onChangeReplyType,
        options: [
          ...(myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS?.split(".")[0]) !== -1 ? [
            {
              key: "internalNote",
              value: "internalNote",
              checked: false,
              name: myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_ADD_REMARKS) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_ADD_REMARKS?.split(".")[0]) !== -1 ? "Add Remarks" : "Internal Note"
            }] : []),
          ...((ticket?.allowed_permission && ticket?.allowed_permission?.google_my_business_review_reply === true) ? [
            {
              key: "reviewReply",
              value: "reviewReply",
              checked: false,
              name: "Review Reply"
            }] : []),
          ...(myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL?.split(".")[0]) !== -1 ? [{ key: "email", value: "email", checked: false, name: 'Reply on Email' }] : []),
        ],
        colSpan: layout == "vertical" ? 24 : 8,
      },] : []),

      ...((replyType === 'email' &&
        (myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL?.split(".")[0]) !== -1)) ? [
        {
          label: "Select File",
          type: "Button",
          disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT) !== -1 ? true : false),
          name: `${documentName ? 'Attached File: ' + documentName : 'Add Attachment'} `,
          colSpan: layout == "vertical" ? 24 : 8,
          // options: leadStatusList,
          onClick: onClickUpladFile,
        },
      ] : []),

      ...(showCCList ? [{
        label: "Mail CC List",
        type: 'TagType',
        name: "cc",
        disabled: allDisabled,
        onChange: onChangeMailCC,
        options: mailCCList,
        colSpan: layout == "vertical" ? 24 : 8,
      }] : []),

      ...(showCCList && tagInputVisiable === true ? [{
        label: '',
        type: "TagInput",
        name: "remarkss",
        onBlur: handleTagInputConfirm,
        onPressEnter: handleTagInputConfirm,
        disabled: allDisabled,
        onChange: handleTagInputChange,
        placeholder: "Type email here...",
        colSpan: layout == "vertical" ? 24 : 8,
      }] : showCCList ? [{
        label: "",
        type: 'TagTypeAdd',
        name: "TagTypeAdd",
        options: false,
        disabled: allDisabled,
        onClick: onClickAddNewCC,
        colSpan: layout == "vertical" ? 24 : 8,
      }] : []),
      {
        label: `${(myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) == -1 ||
          myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS) == -1)} ` ? ticket?.source?.name !== 'email' && (!(ticket?.allowed_permission && ticket?.allowed_permission?.google_my_business_review_reply === true)) ? "Remarks" : null : null,
        type: layout == "vertical" ? "TextArea" : "Input",
        name: "remarks",
        placeholder: "Type remarks here...",
        rules: [{ required: (myPermissions?.indexOf(TASK_MODULE_ACTIONS_MANDATORY_REMARKS_TICKET) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS?.split(".")[0]) !== -1 ? true : false), message: "Please select assignment type" }],
        // || myPermissions?.indexOf("task_module") !== -1
        disabled: disabledRemarks,
        colSpan: layout == "vertical" ? 24 : 8,
      },
    ],
  };



  const formFollowup = {
    heading: `Update ${t("CRM.Tickets.Fields.Ticket")}` as any,
    onFinish: onFinishTicket,
    onFinishFailed: onFinishFailed,
    initialValues: {
      priority_id: ticket?.priority_id,
      doctor_rating: ticket?.doctor_rating,
      doctor_remarks: ticket?.doctor_remarks,
      status_id: ticket?.status_id,
      reason_id: ticket?.reason_id,
      assign_to: ticket?.assigned_team_id
        ? "team"
        : ticket?.assigned_user_id
          ? "agent"
          : null,
      assignee: ticket?.assigned_user_id || null,
      teams: ticket?.assigned_team_id,
      due_date:
        ticket?.due_date === undefined
          ? moment(new Date(), "YYYY-MM-DD")
          : moment(ticket?.due_date),
      ...(ticket?.follow_up_date && ticket?.follow_up_time
        ? {
          follow_up_date: moment(
            moment(ticket?.follow_up_date).format("YYYY-MM-DD") +
            " " +
            ticket?.follow_up_time
          ),
        }
        : {}),
      remarks: myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_CLEAR_REMARKS_IN_EDIT_TICKET) !== -1 ? "" : ticket?.remarks,
      cc: mailCCList,
      reply_type: (myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS) !== -1) ? 'internalNote' :
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) !== -1 ? 'email' : 'internalNote',
    },

    setValues: {
      assignee: assigneeValue,
      // !assignTo
      //   ? ticket?.assigned_user_id
      //     ? ticket?.assigned_user_id
      //     : ticket?.assigned_team_id
      //       ? ticket?.assigned_team_id
      //       : null
      //   : null,
      // reply_type: (myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS) !== -1) ? 'internalNote' :
      //   myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) !== -1 ? 'email' : null,
      cc: mailCCList
    },
    buttonText: `Update ${t("CRM.Tickets.Fields.Ticket")}`,
    buttonType: "primary",
    disabedSubmitButton: updateBtnDisabled || myPermissions?.indexOf(TASK_MODULE_ACTIONS_UPDATE_TICKET) === -1,
    formElements: [
      {
        label: `${t("CRM.Tickets.Fields.Ticket")} Status`,
        type: "Select",
        name: "status_id",
        colSpan: layout == "vertical" ? 24 : 8,
        options: statusList,
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_TICKET_STATUS_UPDATE_TICKET) !== -1 ? true : false),
        onChange: onStatusChange,
        rules: [{ required: true, message: "Please select status" }],
      },

      {
        label: "Follow-Up Date Time",
        type: "DateTimePicker",
        name: "follow_up_date",
        disabled: allDisabled,
        rules: [{ required: true, message: "Please select follow up date" }],
        colSpan: layout == "vertical" ? 24 : 8,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
        disabledTime: () => {
          return {
            disabledHours: () => range(0, 24).splice(0, excludedHours),
            disabledMinutes: () => range(0, excludedMinutes),
          }
        }
      },
      ...(
        showDoctorDetails ? [
          {
            label: "Doctor Rating",
            type: "Select",
            name: "doctor_rating",
            colSpan: layout == "vertical" ? 24 : 8,
            options: numbers,

          },
          {
            label: "Doctor Remarks",
            type: "Input",
            name: "doctor_remarks",
            colSpan: layout == "vertical" ? 24 : 8,
            options: numbers,

          },
        ] : []
      ),
      ...(actualReasonList?.length > 0 ? [{
        label: "Reasons",
        type: "Select",
        disabled: allDisabled,
        name: "reason_id",
        colSpan: layout == "vertical" ? 24 : 8,
        options: actualReasonList,
      }] : []),
      {
        label: "Due Date",
        type: "DateTimePicker",
        name: "due_date",
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_DUE_DATE_UPDATE_TICKET) !== -1 ? true : false),
        rules: [{ required: true, message: "Please select due date" }],
        colSpan: layout == "vertical" ? 24 : 8,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
      },
      ...(
        replyType === "email" ||
          (myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_ATTACHMENT) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_ATTACHMENT?.split(".")[0]) !== -1)
          ? [] : [{
            label: "Select File",
            type: "Button",
            name: `${documentName ? 'Attached File: ' + documentName : 'Add Attachment'} `,
            colSpan: layout == "vertical" ? 24 : 8,
            // options: leadStatusList,
            disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT) !== -1 ? true : false),
            options: assigneeList,
            onClick: onClickUpladFile,
          },]),
      {
        label: "Priority",
        type: "Select",
        name: "priority_id",
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_PRIORITY_UPDATE_TICKET) !== -1 ? true : false),
        rules: [{ required: true, message: "Please select priority" }],
        colSpan: layout == "vertical" ? 24 : 8,
        options: priorityList,
      },
      {
        label: "Assign To",
        type: "Select",
        name: "assign_to",
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET) !== -1 ? true : false),
        rules: [{ required: true, message: "Please select assignment type" }],
        colSpan: layout == "vertical" ? 24 : 4,
        options: [
          { value: "agent", name: "Agent" },
          { value: "team", name: "Team" },
        ],
        onChange: changeSelectedAssignType,
      },
      //show team list 
      ...(teamList?.length > 0 ? [{
        label: "Teams",
        type: "Select",
        name: "teams",
        // rules: [{ required: true, message: "Please select assignee" }],
        colSpan: layout == "vertical" ? 24 : 8,
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET) !== -1 ? true : false), options: teamList,
        onChange: onChangeTeams,
      }] : []),
      {
        label: "Assignee",
        type: "Select",
        name: "assignee",
        rules: [{ required: true, message: "Please select assignee" }],
        colSpan: layout == "vertical" ? 24 : 8,
        disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET) !== -1 ? true : false), options: assigneeList,
        onChange: onChangeAssignee
      },
      ...(ticket?.source?.name === 'email' || (ticket?.allowed_permission && ticket?.allowed_permission?.google_my_business_review_reply === true) ? [{
        label: "Remarks",
        type: 'RadioRound',
        name: "reply_type",
        onChange: onChangeReplyType,
        rules: [{ required: (myPermissions?.indexOf(TASK_MODULE_ACTIONS_MANDATORY_REMARKS_TICKET) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS?.split(".")[0]) !== -1 ? true : false), message: "Please select assignment type" }],
        options: [
          ...(myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS?.split(".")[0]) !== -1 ? [{
            key: "internalNote", value: "internalNote", checked: false,
            name: myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_ADD_REMARKS) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_ADD_REMARKS?.split(".")[0]) !== -1 ? "Add Remarks" : "Internal Note"
          }] : []),
          ...((ticket?.allowed_permission && ticket?.allowed_permission?.google_my_business_review_reply === true) ? [
            {
              key: "reviewReply",
              value: "reviewReply",
              checked: false,
              name: "Review Reply"
            }] : []),
          ...(myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL?.split(".")[0]) !== -1 ? [{ key: "email", value: "email", checked: false, name: 'Reply on Email' }] : []),
        ],
        colSpan: layout == "vertical" ? 24 : 8,
      },] : []),


      ...((replyType === 'email' &&
        (myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL?.split(".")[0]) !== -1)) ? [
        {
          label: "Select File",
          type: "Button",
          name: `${documentName ? 'Attached File: ' + documentName : 'Add Attachment'} `,
          colSpan: layout == "vertical" ? 24 : 8,
          disabled: allDisabled ? true : (myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT) !== -1 ? true : false), options: assigneeList,
          // options: leadStatusList,
          onClick: onClickUpladFile,
        },
      ] : []),
      ...(showCCList ? [{
        label: "Mail CC List",
        type: 'TagType',
        name: "cc",
        onChange: onChangeMailCC,
        disabled: allDisabled,
        options: mailCCList,
        colSpan: layout == "vertical" ? 24 : 8,
      }] : []),

      ...(showCCList && tagInputVisiable === true ? [{
        label: '',
        type: "TagInput",
        name: "remarkss",
        onBlur: handleTagInputConfirm,
        disabled: allDisabled,
        onPressEnter: handleTagInputConfirm,
        onChange: handleTagInputChange,
        placeholder: "Type email here...",
        colSpan: layout == "vertical" ? 24 : 8,
      }] : showCCList ? [{
        label: "",
        type: 'TagTypeAdd',
        name: "TagTypeAdd",
        disabled: allDisabled,
        options: false,
        onClick: onClickAddNewCC,
        colSpan: layout == "vertical" ? 24 : 8,
      }] : []),
      {
        label: `${(myPermissions?.indexOf(TASK_MODULE_ACTIONS_REPLY_TO_EMAIL) == -1 ||
          myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS) == -1)} ` ? ticket?.source?.name !== 'email' && (!(ticket?.allowed_permission && ticket?.allowed_permission?.google_my_business_review_reply === true)) ? "Remarks" : null : null,
        type: layout == "vertical" ? "TextArea" : "Input",
        name: "remarks",
        rules: [{ required: (myPermissions?.indexOf(TASK_MODULE_ACTIONS_MANDATORY_REMARKS_TICKET) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_ADD_INTERNAL_REMARKS?.split(".")[0]) !== -1 ? true : false), message: "Please select assignment type" }],
        disabled: disabledRemarks,
        placeholder: "Type remarks here...",
        colSpan: layout == "vertical" ? 24 : 8,
      },
    ],
  };
  if (layout == "horizontal") {
    formFollowup.heading = null;
    formBasic.heading = null;
  }

  useEffect(() => {
    if (selectedStatus) {
      if (selectedStatus?.toLowerCase() == "follow-up")
        setQCTicketForm(formFollowup);
      else setQCTicketForm(formBasic);
    }
  }, [files, actualReasonList, documentName, assignTo, assigneeList, statusList, priorityList, userList, teamList, fileUploadIdsArray, showDoctorDetails, selectedStatus, mailCCList, mailCCList, tagInputVisiable, tagInputValues, showCCList, assigneeValue]);




  const onCancelModal = async () => {
    setModalVisible(false);
    if (appointmentRequestStatus) {
      if (
        appointmentRequestStatus.request_status !== "approved" &&
        formatDropDownValue(selectedStatus[0]?.name) == "CLOSED"
      ) {
        await dispatch(
          updateAppointmentTicketStatusDetails({
            ticket_id: ticket?.id,
            request_status: "rejected",
          })
        );
      }
    }
    history.push(ROUTE_CRM_TICKET_LIST);
  };

  const getContentBytes = (bytes: any) => {
    setcontentBytes(bytes)
    handleCancel()
  }

  const fileData = (datas: any) => {

    setfileUploadIdsArray(datas)
    handleCancel()
  }



  return (
    <>
      {QCTicketForm ? (
        <FormBar
          name={"update_ticket"}
          initialValues={QCTicketForm?.initialValues}
          heading={QCTicketForm?.heading}
          onFinish={QCTicketForm?.onFinish}
          onClick={() => onClickUpladFile()}
          onValuesChange={QCTicketForm.onValuesChange}
          loading={loading}
          onFinishFailed={QCTicketForm?.onFinishFailed}
          formElements={QCTicketForm?.formElements}
          buttonText={QCTicketForm?.buttonText}
          disabedSubmitButton={QCTicketForm?.disabedSubmitButton}
          setValues={QCTicketForm.setValues}
          onDateTimeChange={handleDateChangeArray}
          disabledTimeValues={() => {
            return {
              disabledHours: () => range(0, 24).splice(0, excludedHours),
              disabledMinutes: () => range(0, excludedMinutes),
            }
          }}
        ></FormBar>
      ) : (
        ""
      )}
      <Modal title="Upload Document" visible={isModalOpen} onCancel={handleCancel}
        footer={null} centered>
        {
          (myPermissions.indexOf(TASK_MODULE_ACTIONS_CIPLA_FILE_UPLOAD) !== -1 ||
            myPermissions?.indexOf(TASK_MODULE_ACTIONS_CIPLA_FILE_UPLOAD?.split(".")[0]) !== -1) && replyType === "email"
            ?
            <UploadPrescriptionBaseSixtyFour fileData={fileData} getContentBytes={getContentBytes} getDescription={getDescription} handleCancel={handleCancel} />
            :
            <UploadPrescription getDescription={getDescription} fileUploaded={fileUploaded} handleCancel={handleCancel} />
        }
      </Modal>
      {modalVisible && (
        <ResultModalLayout
          type="success"
          modalVisible={modalVisible}
          message=""
          setModalVisible={onCancelModal}
          title="Success"
          subtitle={`${t("CRM.Tickets.Fields.Ticket")} updated successfully`}
        />
      )}
    </>
  );
}

export default UpdateTicketForm;
