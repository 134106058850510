import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Tag,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { leadServiceMap } from "../../redux/reducers/leads.slice";
import {
  addComment,
  placeNewOrder,
  proceedQCRequest,
  resetComment
} from "../../redux/reducers/pharmacy.slice";
import { updateTicket } from "../../redux/reducers/tickets.slice";
import { RootState } from "../../shared/constants";
import { formatDropDownValue } from "../Utils/utilities";
import MedicineComponent from "./pharmacy-medicine.layout";
import ResultModalLayout from "./resultmodal.layout";

type Props = {
  leadId?: string;
  ticketId?: string;
  newOrderAuditTrail?: any;
};

function PlaceOrderComponent({ leadId, ticketId, newOrderAuditTrail }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  /////For Ticket Close
  const [statusId, setStatusId] = useState([] as any);
  const { taskDropDown } = useSelector((state: RootState) => state.master);
  useEffect(() => {
    if (taskDropDown.length > 0) {
      let temp, vals;
      temp = taskDropDown.filter((val: any) => {
        return val.name === "status" && val.mode === "ticket";
      });
      if (temp.length > 0) {
        vals = temp[0].values.filter((val: any) => {
          return formatDropDownValue(val.name) === "CLOSED";
        });
        setStatusId(vals[0].id);
      }
    }
  }, [taskDropDown]);

  const [resultModal, setResultModal] = useState(false);
  const [patientAddress, setPatientAddress] = useState({
    address_type: "",
    address_line_1: "",
    address_line_2: "",
    locality: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [cancelProceedModalVisible, setProceedRequestModalVisible] =
    useState(false);
  const [rxRequired, setRxRequired] = useState(null as any);

  const { Text } = Typography;
  const { Option } = Select;
  const [isPlaceOrderModalVisible, setIsPlaceOrderModalVisible] = useState(false);
  const { medicine, pharmacyDetails, comment } = useSelector((state: RootState) => state.pharmacy);
  const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
  let medicineData = medicine.map((item: any) => ({
    item_id: item.id,
    item_type: "drugs",
    item_name: item.item_name,
    item_quantity: item.item_quantity,
    item_amount: (item.item_price * item.item_quantity).toFixed(0),
  }));
  useEffect(() => {
    let rxVal;
    if (medicine?.length > 0) {
      rxVal = medicine.filter((val: any) => {
        return val.prescription_required === true;
      });
    }
    if (rxVal?.length > 0) {
      setRxRequired(true);
    } else {
      setRxRequired(false);
    }
  }, [medicine]);

  const handlePlaceOrder = async () => {
    setLoadingPlaceOrder(true);
    try {
      let payload = {
        patient_id: pharmacyDetails?.patient?.id,
        branch_id: pharmacyDetails?.branch_id || null,
        doctor_id: pharmacyDetails?.appointment
          ? pharmacyDetails?.appointment?.doctor?.id
          : null,
        delivery_address_id: pharmacyDetails?.delivery_address_id,
        visit_id: pharmacyDetails?.id,
        source: pharmacyDetails?.source,
        order_status: "open",
        total_amount: pharmacyDetails?.total_amount,
        paid_amount: 0,
        order_date: pharmacyDetails?.updated_at,
        generate_payment: false,
        items: medicineData,
        comments: comment,
        order_type: "pharmacy",
        request_id: pharmacyDetails?.id,
        service_amount: 0,
        rx_required: rxRequired,
        address:
          patientAddress?.address_line_1 +
          ", " +
          patientAddress?.address_line_2 +
          ", " +
          patientAddress?.locality +
          ", " +
          patientAddress?.city +
          ", " +
          patientAddress?.state,
        files: pharmacyDetails.files.map((item: any, index: number) => {
          return { id: item?.id, bucket: item?.bucket_name, path: item?.path };
        })
      };

      let param = {
        id: pharmacyDetails.id,
        status: "placed",
        delivery_address_id: pharmacyDetails.delivery_address_id,
      };
      await dispatch(placeNewOrder(payload)).then(async (res: any) => {
        if (
          res.payload.data.statusCode === 200 ||
          res.payload.data.statusCode === 201
        ) {
          setIsPlaceOrderModalVisible(false);
          setResultModal(true);
          dispatch(resetComment())
        } else {
          notification.error({ message: "Error in placing order" });
        }
        if (leadId && res?.payload?.data?.data?.response?.data?.id) {
          dispatch(
            leadServiceMap({
              lead_id: leadId,
              order_id: res?.payload?.data?.data?.response?.data?.id,
            })
          );
        }
        if (leadId && res?.payload?.data?.data?.services_payload && res?.payload?.data?.data?.services_payload.length > 1) {
          let services = res?.payload?.data?.data?.services_payload;
          services.forEach((data: any) => {
            dispatch(
              leadServiceMap({
                lead_id: leadId,
                order_id: data?.order_id,
              })
            );
          });
        }
        if (ticketId) {
          const response = await dispatch(
            updateTicket({ id: ticketId, data: { status_id: statusId } })
          );
          if (
            response.payload.status === 200 ||
            response.payload.status === 201
          ) {
            // dispatch(fetchTicketsDetails(ticketId))
          }
        }
      });
      const resp = await dispatch(proceedQCRequest(param));
      if (
        resp.payload.data.statusCode === 200 ||
        resp.payload.data.statusCode === 201
      ) {
        if (ticketId) {
        } else {
          setTimeout(() => {
            history.goBack();
          }, 3000);
        }
      } else {
        notification.error({ message: "Error in placing order" });
      }
    } catch (e) {
      notification.error({ message: "Error in placing order" });
      setIsPlaceOrderModalVisible(false);
    }
  };

  useEffect(() => {
    getPatientAddress();
  }, [pharmacyDetails]);

  const getPatientAddress = () => {
    pharmacyDetails?.patient?.addresses?.forEach((addr: any) => {
      if (addr.id === pharmacyDetails.delivery_address_id) {
        setPatientAddress(addr);
      }
    });
  };

  const handleOpenPlaceOrderModal = () => {
    setIsPlaceOrderModalVisible(true);
  };

  const handleClosePlaceOrderModal = () => {
    setIsPlaceOrderModalVisible(false);
  };

  const medicinePrice = (childData: any) => { };

  const handleCloseOrderModal = () => {
    setProceedRequestModalVisible(true);
  };

  const handleCancelOrder = async () => {
    let param = {
      id: pharmacyDetails.id,
      status: "cancelled",
      delivery_address_id: pharmacyDetails.delivery_address_id,
    };
    await dispatch(proceedQCRequest(param));
    setProceedRequestModalVisible(false);
    notification.success({ message: "Order cancelled successfully." });
    setTimeout(() => {
      history.goBack();
    }, 2000);
  };

  const closeCancelOrder = () => {
    setProceedRequestModalVisible(false);
  };

  return (
    <div
      className={"col-md-12 main-container full-height white-background pd20"}
    >
      {pharmacyDetails.status !== "placed" ? (
        <Row>
          <h4>Place Order</h4>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Text className="sub-heading mt10">Patient Details</Text>
          </Col>
          <Col span={24}>
            <Text className="mt5">
              {pharmacyDetails?.patient?.pii?.first_name}{" "}
              {pharmacyDetails?.patient?.pii?.last_name} |{" "}
              {pharmacyDetails?.patient?.pii?.mobile}
            </Text>
          </Col>
        </Row>
      )}
      <Row className="mt10">
        <Col span={24}>
          <Text className="sub-heading mt10">Delivery Address</Text>
        </Col>
        <Col span={24}>
          <Text className="mt5">
            {(patientAddress?.address_line_1
              ? patientAddress?.address_line_1
              : "") +
              (patientAddress?.address_line_2
                ? ", " + patientAddress?.address_line_2
                : "") +
              (patientAddress?.locality
                ? ", " + patientAddress?.locality
                : "") +
              (patientAddress?.city ? ", " + patientAddress?.city : "") +
              (patientAddress?.state ? ", " + patientAddress?.state : "")}{" "}
          </Text>
        </Col>
      </Row>
      <Row className="mt10">
        <Col span={24}>
          <Text className="sub-heading mt10">Pincode</Text>
        </Col>
        <Col span={24}>
          <Text className="mt5">{patientAddress?.pincode}</Text>
        </Col>
        {/* <Col span={12} className="pdl20 mt30">
              <Text className="sub-heading">ETA: 14 Oct'21 by 9:00 PM</Text>
          </Col> */}
      </Row>
      <Divider />
      <Row className="mt20 full-width sub-heading">
        <Col span={20}>
          <Text>Total Price</Text>
        </Col>
        <Col span={4}>
          <Text>Rs. {Math.round(pharmacyDetails?.total_amount)}</Text>
        </Col>
        <Col span={20}>
          <Text>Delivery Charges</Text>
        </Col>
        <Col span={4}>
          <Text>Rs. {deliveryCharge}</Text>
        </Col>
        {/* <Col span={20} >
          <Text style={{position: 'relative'}}>Discount Applied 
              <Text style={{position: 'absolute', top: '20px', left:'0px'}} className="sub-title">(WERT4567)</Text>
          </Text> 
          <Text className="pdl5" style={{color:'#eb272a'}}><u>REMOVE</u></Text>
        </Col>
        <Col span={4} >
          <Text>-Rs. {80}</Text>
        </Col> */}
      </Row>
      {pharmacyDetails.status !== "placed" &&
        newOrderAuditTrail !== "newOrderAuditTrail" ? (
        <Row>
          <Col span={12}>
            {/* <h4 className="mt20">Coupon Code</h4>
              <Search placeholder="Enter Coupon Code" allowClear enterButton="Apply" size="large" /> */}
            <h4 className="mt20">Payment Mode</h4>
            <Select className="full-width" value="COD" placeholder="">
              <Option value="COD">COD</Option>
              {/* <Option value="Online">Online</Option> */}
            </Select>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {pharmacyDetails.status !== "placed" &&
        newOrderAuditTrail !== "newOrderAuditTrail" ? (
        <Row>
          <Col span={24}>
            <h4 className="mt20">Remarks</h4>
            <Input
              onChange={(e) => {
                dispatch(addComment(e.target.value))
              }}
              value={comment}
              placeholder="Type here"
              className="mt5"
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <h4 className="mt20">Remarks</h4>
            <Text> {pharmacyDetails?.comments} </Text>
          </Col>
        </Row>
      )}

      <Divider />
      <Row className="mt20 full-width sub-heading">
        <Col span={20}>
          <Text className=" fts16">Total Amount to be Paid</Text>
        </Col>
        <Col span={4}>
          <Text className=" fts16">
            Rs. {Math.round(pharmacyDetails.total_amount + deliveryCharge)}
          </Text>
        </Col>
      </Row>
      {rxRequired && (
        <Row>
          <Col>
            <Tag>Max@Home Doctor will call patient for Rx Generation</Tag>
          </Col>
        </Row>
      )}
      {pharmacyDetails.status !== "placed" &&
        newOrderAuditTrail !== "newOrderAuditTrail" ? (
        <Row className="mt20 full-width sub-heading">
          {/* <Col span={24} >
            <Tag>Max@Home Doctor will call patient for Rx Generation</Tag>
          </Col>
          <Col span={24} className="mt10">
            <Checkbox onChange={handleTermCondition}>I have informed patient about 3 medicines that were deleted</Checkbox>
          </Col> */}

          <Col className="mt15" span={24}>
            {/* <Button onClick={handleCloseOrderModal}>Cancel Order</Button> */}
            <Button
              onClick={handleOpenPlaceOrderModal}
              className="mrg10"
              type="primary"
            >
              Place Order
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {resultModal && (
        <ResultModalLayout
          type="success"
          modalVisible={resultModal}
          setModalVisible={(data) => {
            setResultModal(data);
            if (ticketId) {
              window.location.reload();
            }
          }}
          title=" Order is Placed"
          subtitle=""
        ></ResultModalLayout>
      )}

      <Modal
        title="Medicine Order Summary"
        visible={isPlaceOrderModalVisible}
        onOk={handlePlaceOrder}
        onCancel={handleClosePlaceOrderModal}
        footer={[
          <>
            <Button
              block
              key="submit"
              type="primary"
              onClick={handlePlaceOrder}
              loading={loadingPlaceOrder}
            >
              Place Order
            </Button>
            {rxRequired && (
              <Row>
                <Col span={24}>
                  <p className="em text-center text-secondary">
                    Max@Home Doctor will call patient for Rx Generation
                  </p>
                </Col>
              </Row>
            )}
          </>,
        ]}
      >
        <MedicineComponent
          requestId={pharmacyDetails?.id}
          medicineTotalPrice={medicinePrice}
          medicineUsedComponent={"placeOrdeModal"}
        />
        <Row
          className="mt20 full-width sub-heading"
          style={{
            padding: "10px",
            background: "#c9c4c4",
            borderRadius: "5px",
          }}
        >
          <Col span={20}>
            <Text>Amount To Be Paid</Text>
          </Col>
          <Col span={4}>
            <Text>Rs. {pharmacyDetails.total_amount}</Text>
          </Col>
        </Row>
        <Row className="mt20 full-width sub-heading">
          <Col span={24}>
            <Text>Deliver To</Text>
          </Col>
          <Col span={24}>
            <Text>
              {(patientAddress?.address_line_1
                ? patientAddress?.address_line_1
                : "") +
                (patientAddress?.address_line_2
                  ? ", " + patientAddress?.address_line_2
                  : "") +
                (patientAddress?.locality
                  ? ", " + patientAddress?.locality
                  : "") +
                (patientAddress?.city ? ", " + patientAddress?.city : "") +
                (patientAddress?.state ? ", " + patientAddress?.state : "")}
            </Text>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={cancelProceedModalVisible}
        onOk={handleCancelOrder}
        onCancel={closeCancelOrder}
      >
        Do you want to cancel this request?
      </Modal>
    </div>
  );
}

export default PlaceOrderComponent;
