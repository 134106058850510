import { Table } from "antd";
import {
  ActionFilter,
  AgentsFilter,
  AssignmentFilter,
  BlankFilter,
  ContactsFilter,
  CoordinatorFilter,
  DiagnosticsSummaryFilter,
  EmptyFilter,
  GenericFilter,
  MetadataFilter,
  MoreFilters,
  OnlyFilters,
  paymentSummaryFilter,
  PharmacyOrderSummaryFilter,
  TransactionFilter,
  VCFilter,
} from "../Utils/filters";
type Props = {
  defaultPageSize?: any;
  loading: boolean;
  dataSource: any[];
  columns: any[];
  title?: any;
  current?: any;
  skip?: any;
  take?: any;
  total?: any;
  type?: string;
  header?: (values: string) => void;
  onChange?: (values: string, val: any) => void;
  buttonEvt?: () => void;
  filterFormSubmit?: any;
  pagination?: any;
  maxWidth?: any;
  formData?: any;
  filters?: any;
  branches?: any;
  downloadFilterData?: any;
  defaultCurrentPage?: any;
  offsetHeight?: number;
  // defaultCurrentPage?: any
  pageType?: any;
  showTotal?: any;
  rowSelection?: any;
  onShowSizeChange?: any;
  showSizeChangerFlag?: any;
  onChangeTable?: any;
};
const typeFunction = (
  onChange: any,
  total: any,
  type: string,
  buttonEvt: any,
  filterFormSubmit: any,
  formData: any,
  filters: any,
  branches: any,
  downloadFilterData: any,
  defaultCurrentPage: number,
  showTotal: boolean = false,
  defaultPageSize?: any,
  showSizeChangerFlag: boolean = false,
  PaginationLayout?: any,
  onShowSizeChange?: any
) => {
  switch (type) {
    case "transactionfilter":
      return TransactionFilter(onChange, total);
    case "contactsfilter":
      return ContactsFilter(
        onChange,
        total,
        buttonEvt,
        filterFormSubmit,
        defaultCurrentPage
      );
    case "pharmacyfilter":
      return PharmacyOrderSummaryFilter(
        filterFormSubmit,
        branches,
        downloadFilterData
      );
    case "metadatafilter":
      return MetadataFilter(onChange, total);
    case "assignmentfilter":
      return AssignmentFilter(onChange, total, buttonEvt, defaultCurrentPage);
    case "actionfilter":
      return ActionFilter(onChange, total, buttonEvt, defaultCurrentPage);
    case "agentsfilter":
      return AgentsFilter(
        onChange,
        total,
        buttonEvt,
        filterFormSubmit,
        defaultCurrentPage
      );
    case "emptyFilter":
      return EmptyFilter(onChange, total);
    case "cordinatorfilter":
      return CoordinatorFilter(onChange, total);
    case "genericfilter":
      return GenericFilter(
        onChange,
        total,
        formData,
        buttonEvt,
        filters,
        defaultCurrentPage,
        showTotal,
        onShowSizeChange,
        defaultPageSize,
        showSizeChangerFlag
      );
    case "morefilters":
      return MoreFilters(
        onChange,
        total,
        formData,
        buttonEvt,
        filters,
        defaultCurrentPage,
        showTotal,
        onShowSizeChange,
        defaultPageSize,
        showSizeChangerFlag
      );
    case "vcfilter":
      return VCFilter(formData, filters, buttonEvt);
    case "only_filter":
      return OnlyFilters(onChange, formData, filters);
    case "paymentSummaryFilter":
      return paymentSummaryFilter(
        onChange,
        total,
        formData,
        buttonEvt,
        filters,
        defaultCurrentPage,
        downloadFilterData
      );
    case "diagnosticsSummaryFilter":
      return DiagnosticsSummaryFilter(
        onChange,
        total,
        formData,
        buttonEvt,
        filters,
        defaultCurrentPage,
        downloadFilterData
      );
    case "blankFilter":
      return BlankFilter(onChange, total);
  }
};

function TableLayout({
  defaultPageSize,
  filters,
  showTotal = false,
  loading,
  title,
  dataSource,
  buttonEvt,
  filterFormSubmit,
  columns,
  onChange,
  total,
  skip,
  current,
  maxWidth,
  pagination,
  type = "transactionfilter",
  formData = {} as any,
  branches,
  downloadFilterData,
  defaultCurrentPage,
  offsetHeight = 230,
  pageType,
  rowSelection,
  showSizeChangerFlag,
  onChangeTable = () => { },
}: Props) {
  // function TableLayout({filters,pageType, loading, title, dataSource, buttonEvt, filterFormSubmit, columns,onChange,total,skip,current,maxWidth, pagination, type = 'transactionfilter', formData= {} as any, branches, downloadFilterData,defaultCurrentPage }: Props) {
  return (
    <div>
      <Table
        className="crm-table"
        rowSelection={rowSelection}
        title={() =>
          typeFunction(
            onChange,
            total,
            type,
            buttonEvt,
            filterFormSubmit,
            formData,
            filters,
            branches,
            downloadFilterData,
            defaultCurrentPage,
            showTotal,
            defaultPageSize,
            showSizeChangerFlag
          )
        }
        pagination={pagination}
        loading={loading}
        scroll={{ x: maxWidth, y: "calc(100vh - " + offsetHeight + "px)" }}
        //  scroll={ pageType === 'withTab' ? { x: maxWidth,y: 'calc(100vh - 230px)'} : { x: maxWidth,y: 'calc(100vh - 160px)'}}
        dataSource={dataSource}
        columns={columns}
        onChange={onChangeTable}
      />
    </div>
  );
}

export default TableLayout;
