import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_DASHBOARD_DATA } from "../../shared/routes/crm.routes.constants";
import { Client } from "../../shared/Utils/api-client";

const config = {
  name: "task",
};

export const fetchDashboardData: any = createAsyncThunk(
  `${config.name}/fetchDashboardData`,
  async (params: any) => {
    return await Client.get(GET_DASHBOARD_DATA, {params});
  }
);
