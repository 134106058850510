import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Drawer, Modal, Row, Tabs } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchContact } from "../../../../redux/reducers/contacts.slice";
import { addNewPatientFromContact } from "../../../../redux/reducers/patients.slice";
import {
  fetchMedicinesById,
  fetchOrderDetailsByID
} from "../../../../redux/reducers/pharmacy.slice";
import {
  addPrescriptions,
  fetchAllTemplates,
  fetchPrescriptionById,
  updatePrescription
} from "../../../../redux/reducers/prescription.slice";
import {
  addTicketService,
  fetchTicketsDetails
} from "../../../../redux/reducers/tickets.slice";
import {
  fetchUploadedFileById,
  uploadFile
} from "../../../../redux/reducers/upload.slice";
import { formatDate } from "../../../../shared/Utils/utilities";
import { RootState } from "../../../../shared/constants";
import PlaceOrderComponent from "../../../../shared/layouts/pharmacy-place-order.layout";
import TimelineLayout from "../../../../shared/layouts/timeline.layout";
import FormView from "../../../dependencies/Builder/Components/FormView/FormView";
import DynamicFormContainer from "../../../dependencies/Builder/Components/ProcedureFromView/DynamicFormContainer";
import PatientJourney from "../../../dependencies/patient-journey";
import PatientMessageHistory from "../../../dependencies/patient-message-history";
import SelectedMedicine from "../../../dependencies/selected-medicine.component";
import OrderMedicineCompoment from "../../BookServices/components/ordermedicine";
import UpdateTicketForm from "./updateticketform";
var _ = require("lodash");
const { Panel } = Collapse;
const { TabPane } = Tabs;
type Props = {
  ticket?: any;
  actionmapData?: any;
  auditStatus?: any;
};

const TicketDetailsRxTab: React.FunctionComponent<Props> = ({
  ticket,
  actionmapData,
  auditStatus,
}) => {
  const dispatch = useDispatch();
  const { contact } = useSelector((state: RootState) => state.contact);
  const { prescriptionTemplate, prescriptionTemplateUrl } = useSelector(
    (state: RootState) => state.prescription
  );
  const [selectedTemplateData, setSelectedTemplateData] = useState(
    false as any
  );
  const { t } = useTranslation()
  const [selectedTemplateId, setSelectedTemplateId] = useState(false as any);
  const [templateFormViewer, setTemplateFormViewer] = useState(false as any);
  const [perscriptionID, setperscriptionID] = useState(false as any);
  const [orderID, setOrderID] = useState(false as any);
  const [isEditRx, setEditRx] = useState(false);
  const [seletectappointment, setAppointment] = useState("" as any);
  const [showPres, setShowPres] = useState(false as any);
  const { pharmacyDetails, medicine } = useSelector(
    (state: RootState) => state.pharmacy
  );
  const [audit, setAuditTrail] = useState(false as any);
  const [isReset, setIsReset] = useState(null as any);
  const [newOrderAuditTrail, setNewOrderAuditTrail] = useState("" as any);
  const [pharmacyData, setPharmacyDetails] = useState({} as any);
  useEffect(() => {
    let orderRequestId = null as any;

    ticket?.services &&
      ticket?.services[0]?.order_id &&
      dispatch(fetchOrderDetailsByID(ticket?.services[0]?.order_id)).then(
        (val: any) => {
          orderRequestId = val.payload.data.data.request_id;
          if (orderRequestId) {
            //ticket?.services?.length > 0
            // const orderService = ticket?.services?.filter((val: any) => {
            //   return val?.order_id;
            // });
            // orderService?.length > 0 &&
            // orderRequestId &&
            dispatch(fetchMedicinesById(orderRequestId));
            // console.log("orderService[0]", orderService[0]);
          }
        }
      );
  }, [ticket]);

  useEffect(() => {
    console.log("medicine==>", medicine);
  }, [medicine]);
  useEffect(() => {
    ticket.contact_id && dispatch(fetchContact(ticket.contact_id));
    dispatch(fetchAllTemplates({ type: "pharmacist" }));
    ticket.services.forEach(async (service: any) => {
      if (service && service.type == "order") {
        setOrderID(service.order_id);
      }

      let prescriptionId = null as any
      if (service?.type === 'prescription') {
        prescriptionId = service?.prescription_id
      }



      if (service?.type == "prescription" && prescriptionId) {
        prescriptionId && await dispatch(fetchPrescriptionById(prescriptionId)).then((val: any) => {
          let service_prescription = val?.payload?.data?.data;
          (async function () {
            let data = await dispatch(
              fetchUploadedFileById({
                type: "PRESCRIPTION",
                id: service_prescription?.data_id,
              })
            );
            if (data && data.payload && data.payload.status == 200) {
              //console.log("selectTemplate Check",data.payload.data.data.result)
              //console.log("selectTemplate",prescriptionTemplateUrl);
              let selectedTemplateDataResponse = await fetch(
                data.payload.data.data.result
              );
              let selectedTemplateDataRaw =
                await selectedTemplateDataResponse.json();
              setTemplateFormViewer(selectedTemplateDataRaw);
              setperscriptionID(service_prescription.id);
              //console.log("selectedTemplateData",templateFormViewer);
            }
          })();
        })
        //console.log("prescription service",service);

      }
    });
  }, [ticket]);

  useEffect(() => {
    if (prescriptionTemplate.length > 0) {
      setSelectedTemplateId(prescriptionTemplate[0].template_data_id);
      (async function () {
        try {
          let selectedTemplateDataResponse = await fetch(
            prescriptionTemplate[0].url
          );
          let selectedTemplateDataRaw =
            await selectedTemplateDataResponse.json();
          setSelectedTemplateData(selectedTemplateDataRaw);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [prescriptionTemplate]);

  const onFinishTicket = (values: any) => {
    dispatch(fetchTicketsDetails(ticket.id));
  };
  useEffect(() => {
    if (contact) {
      if (contact?.patient_id) {
      } else {
        dispatch(
          addNewPatientFromContact({ contact_id: ticket.contact_id })
        ).then((val: any) => {
          if (val?.payload?.data?.statusCode == 200) {
            dispatch(fetchContact(ticket.contact_id));
          }
        });
      }
    }
  }, [contact]);

  const handleAudit = async () => {
    setAuditTrail(true);
    setNewOrderAuditTrail("newOrderAuditTrail");
    let data = await dispatch(
      ticket?.services[0]?.order_request_id &&
      fetchMedicinesById(ticket?.services[0]?.order_request_id)
    );
    setPharmacyDetails(data?.payload?.data?.data?.pharmacy);
    setIsReset(null);
  };
  const onCloseAuditTrail = () => {
    setAuditTrail(false);
    setIsReset("reset");
  };

  return (
    <>
      <Row style={{ maxHeight: "calc(100vh - 155px)", overflowY: "scroll" }}>
        <Col span={19} className="bg-app innertabs">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Prescription Generation" key="1">
              <div className="pd20">
                <h1 className="bold-600 fts24">
                  Prescription Generation for Pharmacy Order
                </h1>
                {templateFormViewer && (
                  <>
                    {isEditRx ? (
                      <>
                        <DynamicFormContainer
                          templateId={selectedTemplateId}
                          onCancel={() => setEditRx(false)}
                          saving={false}
                          onSubmit={async (data: any, submitted?: boolean) => {
                            try {
                              const response = await dispatch(
                                uploadFile({
                                  file_name: selectedTemplateId,
                                  type: "PRESCRIPTION",
                                  content_type: "json",
                                })
                              );
                              const response2 = new Promise(
                                (resolve, reject) => {
                                  resolve(
                                    axios
                                      .request({
                                        headers: {
                                          "Content-Type": "json", // This is set to application/flac
                                        },
                                        method: "PUT",
                                        data: data,
                                        url: response?.payload?.data?.data?.url,
                                        timeout: 0,
                                      })
                                      .then(async function () {
                                        const upload = await dispatch(
                                          updatePrescription({
                                            id: perscriptionID,
                                            data: {
                                              upload_id:
                                                response?.payload?.data?.data
                                                  ?.id,
                                            },
                                          })
                                        );
                                        //console.log("uploadPRESCRIPTION",upload)

                                        setTemplateFormViewer(data);
                                        setEditRx(false);
                                        //dispatch(fetchPrescriptions({ appointment_id: ticket.services[0].id, patient_id: ticket.services[0].patient_id, type: "PRESCRIPTION" }))
                                      })
                                  );
                                }
                              );
                            } catch (e) { }
                            return false;
                          }}
                          formData={
                            templateFormViewer.sections
                              ? templateFormViewer
                              : JSON.parse(templateFormViewer)
                          }
                        />
                      </>
                    ) : (
                      <>
                        {ticket.status.name == "open" && (
                          <Row>
                            <Col span={24}>
                              <Button
                                type="primary"
                                onClick={() => {
                                  setEditRx(true);
                                }}
                                style={{ float: "right" }}
                                icon={<EditOutlined />}
                                size={"large"}
                              />
                            </Col>
                          </Row>
                        )}
                        <FormView prescriptionData={templateFormViewer} />
                      </>
                    )}
                  </>
                )}
                {!templateFormViewer &&
                  selectedTemplateData &&
                  selectedTemplateData.sections &&
                  selectedTemplateId && (
                    <DynamicFormContainer
                      templateId={selectedTemplateId}
                      onCancel={() => setTemplateFormViewer(false)}
                      saving={false}
                      onSubmit={async (data: any, submitted?: boolean) => {
                        try {
                          const response = await dispatch(
                            uploadFile({
                              file_name: selectedTemplateId,
                              type: "PRESCRIPTION",
                              content_type: "json",
                            })
                          );
                          const response2 = new Promise((resolve, reject) => {
                            resolve(
                              axios
                                .request({
                                  headers: {
                                    "Content-Type": "json", // This is set to application/flac
                                  },
                                  method: "PUT",
                                  data: data,
                                  url: response?.payload?.data?.data?.url,
                                  timeout: 0,
                                })
                                .then(async function () {
                                  const upload = await dispatch(
                                    addPrescriptions({
                                      upload_id:
                                        response?.payload?.data?.data?.id,
                                      order_id: orderID,
                                      patient_id: ticket.services[0].patient_id,
                                      date: moment().format("YYYY-MM-DD"),
                                      type: "PRESCRIPTION",
                                    })
                                  );
                                  //console.log("uploadPRESCRIPTION",upload)
                                  const response3 = await dispatch(
                                    addTicketService({
                                      ticket_id: ticket.id,
                                      type: "prescription",

                                      prescription_id:
                                        upload?.payload?.data?.data?.id,
                                      patient_id: ticket.services[0].patient_id,
                                      doctor_id: ticket.services[0].doctor_id,
                                    })
                                  );
                                  setTemplateFormViewer(data);
                                  //dispatch(fetchPrescriptions({ appointment_id: ticket.services[0].id, patient_id: ticket.services[0].patient_id, type: "PRESCRIPTION" }))
                                })
                            );
                          });
                        } catch (e) { }
                        return false;
                      }}
                      formData={
                        selectedTemplateData.sections
                          ? selectedTemplateData
                          : JSON.parse(selectedTemplateData)
                      }
                    />
                  )}
              </div>
            </TabPane>
            <TabPane tab={`${t("CRM.Tickets.Fields.Ticket")} History`} key="2">
              <div className="padding20">
                <TimelineLayout
                  theme={"white"}
                  type="Ticket"
                  details={ticket?.history}
                />
              </div>
            </TabPane>
          </Tabs>
          {ticket &&
            ticket?.services.length > 0 &&
            ticket?.services[0]?.order_request_id &&
            ticket?.sub_type?.name === "pharmacy" && (
              <>
                <Button
                  type="link"
                  className="floating-audit bold-600"
                  onClick={handleAudit}
                >
                  <u>View Audit Trail</u>
                </Button>
              </>
            )}
        </Col>
        <Col span={5}>
          {medicine && pharmacyDetails && (
            <>
              <Row>
                <Col>
                  <div className="pd20">
                    <h3 className="title bold-600 mb-0">
                      {medicine.length} Medicines Ordered
                    </h3>
                    <p className="text-secondary mb-10">
                      Time: {formatDate(pharmacyDetails.updated_at)}
                    </p>
                    <a
                      className="accent"
                      onClick={() => {
                        setShowPres(true);
                      }}
                    >
                      View Past Prescriptions
                    </a>
                  </div>
                </Col>
              </Row>
              <Collapse className="rxcollapse" defaultActiveKey={["2"]}>
                {medicine.filter((val: any) => {
                  return !val.prescription_required;
                })?.length > 0 && (
                    <Panel
                      header={`${medicine.filter((val: any) => {
                        return !val.prescription_required;
                      }).length
                        } Medicine(s) in Prescription`}
                      key="1"
                    >
                      <Row>
                        <Col span={22}></Col>
                        <Col span={2}>Qty</Col>
                      </Row>
                      {medicine
                        .filter((val: any) => {
                          return !val.prescription_required;
                        })
                        .map((val: any, index: number) => {
                          return (
                            <Row className="mb-10">
                              <Col span={22}>
                                <span className="bold-600">
                                  {index + 1}. {val.item_name}
                                </span>
                              </Col>
                              <Col span={2}>{val.item_quantity}</Col>
                            </Row>
                          );
                        })}
                    </Panel>
                  )}
                {medicine.filter((val: any) => {
                  return val.prescription_required;
                })?.length > 0 && (
                    <Panel
                      header={`${medicine.filter((val: any) => {
                        return val.prescription_required;
                      }).length
                        } Medicine(s) not in Prescription`}
                      key="2"
                    >
                      <Row>
                        <Col span={22}></Col>
                        <Col span={2}>Qty</Col>
                      </Row>
                      {medicine
                        .filter((val: any) => {
                          return val.prescription_required;
                        })
                        .map((val: any, index: number) => {
                          return (
                            <Row className="mb-10">
                              <Col span={22}>
                                <span className="bold-600">
                                  {index + 1}. {val.item_name}
                                </span>
                              </Col>
                              <Col span={2}>{val.item_quantity}</Col>
                            </Row>
                          );
                        })}
                    </Panel>
                  )}
              </Collapse>
            </>
          )}
          <UpdateTicketForm
            ticket={ticket}
            parentCallback={onFinishTicket}
          ></UpdateTicketForm>
        </Col>
      </Row>
      {showPres === true && (
        <Modal
          visible={showPres}
          style={{ paddingBottom: "0px" }}
          footer={false}
          width={"60%"}
          centered
          title={"Past Prescriptions"}
          closable
          onCancel={() => setShowPres(false)}
        >
          <OrderMedicineCompoment
            contact={contact}
            pharmacyDetailsPre={showPres}
          ></OrderMedicineCompoment>
        </Modal>
      )}
      {audit && (
        <Drawer
          title={`Audit Trail`}
          placement="right"
          width={"80%"}
          onClose={onCloseAuditTrail}
          visible={audit}
        >
          <Row className="mt30">
            <Col span={24}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Pharmacy Order" key="1">
                  <Row className="bordered-grey-color">
                    <Col
                      className="pd20 grey-background"
                      span={auditStatus === "newOrders" ? 24 : 14}
                    >
                      <SelectedMedicine
                        newOrderAuditTrail={newOrderAuditTrail}
                      />
                    </Col>
                    <Col span={auditStatus === "newOrders" ? 0 : 10}>
                      <PlaceOrderComponent
                        newOrderAuditTrail={newOrderAuditTrail}
                      />
                    </Col>
                  </Row>
                </TabPane>
                {pharmacyDetails?.appointment_id && (
                  <TabPane tab="Patient Journey" key="2">
                    <PatientJourney
                      appointmentId={pharmacyData?.appointment_id}
                      isReset={isReset}
                    ></PatientJourney>
                  </TabPane>
                )}
                <TabPane tab="Message History" key="3">
                  <PatientMessageHistory
                    patient_id={pharmacyData?.patient?.id}
                    isReset={isReset}
                  ></PatientMessageHistory>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Drawer>
      )}
    </>
  );
};

export default TicketDetailsRxTab;
