import { Col, List, Row, Space, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchVideoLogs } from "../../redux/reducers/tickets.slice";
import { convertSecondsInMinutesAndSecond, formatDate, processVideoLogs } from "../../shared/Utils/utilities";
const { Text } = Typography;


const PatientVideoConsultLogs: React.FunctionComponent<any> = (Props) => {
  const [eventData, seteventData] = useState([] as any);
  const [attemptData, setattemptData] = useState();

  const [callSummary, setcallSummary] = useState([] as any);
  let callStatus = {} as any;
  let patientDevice = '', doctorDevice = '';


  if (callSummary?.length > 0 && eventData?.length > 0) {
    callStatus = processVideoLogs(eventData, callSummary);
    if (callStatus?.patientData) {
      patientDevice = callStatus?.patientData?.device + " " + callStatus?.patientData?.deviceip;
    }
    if (callStatus?.doctorData) {
      doctorDevice = callStatus?.doctorData?.device + " " + callStatus?.doctorData?.deviceip;
    }

    // console.log("callStatus", callStatus)

  }
  const callOverallSummary = [
    {
      label: "Appointment",
      record: `${Props?.appointment?.start_time}  |  ${Props?.appointment?.doctor?.name
        }  |  ${moment(Props?.appointment?.appointment_date).format(
          "DD MMM'YY"
        )}`,
    },
    // {
    //     label:"Summary",
    //     record:"Consultation done with 1 valid attempt and total duration of 12 mins & 6 secs"
    // },
    // {
    //     label:"Attempts",
    //     record:"Total Attempts made 3 and total duration of 19 mins & 37 secs but only 1 person was there in remaining attempts"
    // },
    {
      label: "Summary",
      record: (
        <>{callStatus?.currentStatus ? callStatus.currentStatus : "--"}</>
      ),
    },
    {
      label: "Paticipants Joined",
      record: ` Patient: “${Props?.appointment?.patient?.pii?.first_name} ${Props?.appointment?.patient?.pii?.last_name}”, Dr. :  “${Props?.appointment?.doctor?.name}” `,
    },
    {
      label: "Patient Devices",
      record: `${patientDevice}  `,
    },
    {
      label: "Doctor Devices",
      record: `${doctorDevice}  `,
    },
  ];

  const dispatch = useDispatch();

  const attemptcolumns = [
    {
      title: "Participant",
      dataIndex: "participant",

    },
    {
      title: "Start",
      dataIndex: "start",
    },
    {
      title: "End",
      dataIndex: "end",
    },
    {
      title: "Duration",
      dataIndex: "duration",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Attempt",
      dataIndex: "attempt",
    },
  ];

  const eventcolumns = [
    {
      title: "Event",
      dataIndex: "event",
      className: "capitalize",
    },
    {
      title: "Participant",
      dataIndex: "participant",
      className: "capitalize",
    },
    {
      title: "Participant Status",
      dataIndex: "participant_status",
      className: "capitalize",
    },
    // {
    //   title: "Room Status",
    //   dataIndex: "room_status",
    // },
    {
      title: "Room Duration",
      dataIndex: "room_duration",
      render: (a: any) => {
        return convertSecondsInMinutesAndSecond(a)
      }
    },
    {
      title: "Publish Type",
      dataIndex: "publish_type",
      className: "capitalize",
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      render: (a: any) => <p>{formatDate(a)}</p>,
    },
  ];

  useEffect(() => {
    dispatch(
      fetchVideoLogs({
        id: Props.room_id,
        dates: moment(Props.appointment_date).format("YYYY/MM/DD").toString(),
      })
    ).then((item: any) => {
      setcallSummary(
        item?.payload?.data?.data?.call_summary
          ? item?.payload?.data?.data?.call_summary
          : []
      );
      setattemptData(
        item?.payload?.data?.data?.call_attempt_logs
          ? item?.payload?.data?.data?.call_attempt_logs
          : []
      );
      seteventData(
        item?.payload?.data?.data?.call_event_logs
          ? item?.payload?.data?.data?.call_event_logs.filter((val: any) => {
            return val.participant !== 'max'
          })
          : []
      );


    });
  }, []);



  return (
    <Row>
      <Col span={24}>
        <p className="primary-color-text mt10 bold">Call Summary</p>
        <List
          bordered
          size="small"
          className="white-background"
          dataSource={callOverallSummary}
          renderItem={(item) => (
            <List.Item>
              <Col span={6}>
                {" "}
                <Text type="secondary">{item.label}</Text>{" "}
              </Col>
              <Col span={18}>
                <p className="primary-color-text mt10 bold capitalize">
                  {item.record}
                </p>
              </Col>
            </List.Item>
          )}
        />
      </Col>

      {/* <Col span={24} className="mt30">
        <p className="primary-color-text mt10 bold">Call Attempt Logs</p>
        <Table
          loading={false}
          locale={{
            emptyText: (
              <>
                <Space direction={"vertical"} size={10}>
                  <Text>No Records</Text>
                </Space>
              </>
            ),
          }}
          pagination={false}
          scroll={{ x: true }}
          dataSource={attemptData}
          columns={attemptcolumns}
        />
      </Col> */}

      <Col span={24} className="mt30">
        <p className="primary-color-text mt10 bold">Call Event Logs</p>
        <Table
          loading={false}
          locale={{
            emptyText: (
              <>
                <Space direction={"vertical"} size={10}>
                  <Text>No Records</Text>
                </Space>
              </>
            ),
          }}
          pagination={false}
          scroll={{ x: true }}
          dataSource={eventData}
          columns={eventcolumns}
        />
      </Col>
    </Row>
  );
};

export default PatientVideoConsultLogs;
