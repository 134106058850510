export const countryCodes = [
  {
    show: false,
    dial_code: "+1",
    flag: "\ud83c\udde8\ud83c\udde6",
    cc: "CA",
    country: "Canada",
  },
  {
    show: false,
    dial_code: "+1",
    flag: "\ud83c\uddfa\ud83c\uddf8",
    cc: "US",
    country: "United States",
  },
  {
    show: false,
    dial_code: "+1242",
    flag: "\ud83c\udde7\ud83c\uddf8",
    cc: "BS",
    country: "Bahamas",
  },
  {
    show: false,
    dial_code: "+1246",
    flag: "\ud83c\udde7\ud83c\udde7",
    cc: "BB",
    country: "Barbados",
  },
  {
    show: false,
    dial_code: "+1264",
    flag: "\ud83c\udde6\ud83c\uddee",
    cc: "AI",
    country: "Anguilla",
  },
  {
    show: false,
    dial_code: "+1268",
    flag: "\ud83c\udde6\ud83c\uddec",
    cc: "AG",
    country: "Antigua and Barbuda",
  },
  {
    show: false,
    dial_code: "+1284",
    flag: "\ud83c\uddfb\ud83c\uddec",
    cc: "VG",
    country: "Virgin Islands",
  },
  {
    show: false,
    dial_code: "+1340",
    flag: "\ud83c\uddfb\ud83c\uddee",
    cc: "VI",
    country: "Virgin Islands",
  },
  {
    show: false,
    dial_code: "+1441",
    flag: "\ud83c\udde7\ud83c\uddf2",
    cc: "BM",
    country: "Bermuda",
  },
  {
    show: false,
    dial_code: "+1473",
    flag: "\ud83c\uddec\ud83c\udde9",
    cc: "GD",
    country: "Grenada",
  },
  {
    show: false,
    dial_code: "+1649",
    flag: "\ud83c\uddf9\ud83c\udde8",
    cc: "TC",
    country: "Turks and Caicos Islands",
  },
  {
    show: false,
    dial_code: "+1664",
    flag: "\ud83c\uddf2\ud83c\uddf8",
    cc: "MS",
    country: "Montserrat",
  },
  {
    show: false,
    dial_code: "+1670",
    flag: "\ud83c\uddf2\ud83c\uddf5",
    cc: "MP",
    country: "Northern Mariana Islands",
  },
  {
    show: false,
    dial_code: "+1671",
    flag: "\ud83c\uddec\ud83c\uddfa",
    cc: "GU",
    country: "Guam",
  },
  {
    show: false,
    dial_code: "+1684",
    flag: "\ud83c\udde6\ud83c\uddf8",
    cc: "AS",
    country: "American Samoa",
  },
  {
    show: false,
    dial_code: "+1758",
    flag: "\ud83c\uddf1\ud83c\udde8",
    cc: "LC",
    country: "Saint Lucia",
  },
  {
    show: false,
    dial_code: "+1767",
    flag: "\ud83c\udde9\ud83c\uddf2",
    cc: "DM",
    country: "Dominica",
  },
  {
    show: false,
    dial_code: "+1784",
    flag: "\ud83c\uddfb\ud83c\udde8",
    cc: "VC",
    country: "Saint Vincent and the Grenadines",
  },
  {
    show: false,
    dial_code: "+1849",
    flag: "\ud83c\udde9\ud83c\uddf4",
    cc: "DO",
    country: "Dominican Republic",
  },
  {
    show: false,
    dial_code: "+1868",
    flag: "\ud83c\uddf9\ud83c\uddf9",
    cc: "TT",
    country: "Trinidad and Tobago",
  },
  {
    show: false,
    dial_code: "+1869",
    flag: "\ud83c\uddf0\ud83c\uddf3",
    cc: "KN",
    country: "Saint Kitts and Nevis",
  },
  {
    show: false,
    dial_code: "+1876",
    flag: "\ud83c\uddef\ud83c\uddf2",
    cc: "JM",
    country: "Jamaica",
  },
  {
    show: false,
    dial_code: "+1939",
    flag: "\ud83c\uddf5\ud83c\uddf7",
    cc: "PR",
    country: "Puerto Rico",
  },
  {
    show: false,
    dial_code: "+20",
    flag: "\ud83c\uddea\ud83c\uddec",
    cc: "EG",
    country: "Egypt",
  },
  {
    show: false,
    dial_code: "+211",
    flag: "\ud83c\uddf8\ud83c\uddf8",
    cc: "SS",
    country: "South Sudan",
  },
  {
    show: false,
    dial_code: "+212",
    flag: "\ud83c\uddf2\ud83c\udde6",
    cc: "MA",
    country: "Morocco",
  },
  {
    show: false,
    dial_code: "+213",
    flag: "\ud83c\udde9\ud83c\uddff",
    cc: "DZ",
    country: "Algeria",
  },
  {
    show: false,
    dial_code: "+216",
    flag: "\ud83c\uddf9\ud83c\uddf3",
    cc: "TN",
    country: "Tunisia",
  },
  {
    show: false,
    dial_code: "+218",
    flag: "\ud83c\uddf1\ud83c\uddfe",
    cc: "LY",
    country: "Libyan Arab Jamahiriya",
  },
  {
    show: false,
    dial_code: "+220",
    flag: "\ud83c\uddec\ud83c\uddf2",
    cc: "GM",
    country: "Gambia",
  },
  {
    show: false,
    dial_code: "+221",
    flag: "\ud83c\uddf8\ud83c\uddf3",
    cc: "SN",
    country: "Senegal",
  },
  {
    show: false,
    dial_code: "+222",
    flag: "\ud83c\uddf2\ud83c\uddf7",
    cc: "MR",
    country: "Mauritania",
  },
  {
    show: false,
    dial_code: "+223",
    flag: "\ud83c\uddf2\ud83c\uddf1",
    cc: "ML",
    country: "Mali",
  },
  {
    show: false,
    dial_code: "+224",
    flag: "\ud83c\uddec\ud83c\uddf3",
    cc: "GN",
    country: "Guinea",
  },
  {
    show: false,
    dial_code: "+225",
    flag: "\ud83c\udde8\ud83c\uddee",
    cc: "CI",
    country: "Cote d'Ivoire",
  },
  {
    show: false,
    dial_code: "+226",
    flag: "\ud83c\udde7\ud83c\uddeb",
    cc: "BF",
    country: "Burkina Faso",
  },
  {
    show: false,
    dial_code: "+227",
    flag: "\ud83c\uddf3\ud83c\uddea",
    cc: "NE",
    country: "Niger",
  },
  {
    show: false,
    dial_code: "+228",
    flag: "\ud83c\uddf9\ud83c\uddec",
    cc: "TG",
    country: "Togo",
  },
  {
    show: false,
    dial_code: "+229",
    flag: "\ud83c\udde7\ud83c\uddef",
    cc: "BJ",
    country: "Benin",
  },
  {
    show: false,
    dial_code: "+230",
    flag: "\ud83c\uddf2\ud83c\uddfa",
    cc: "MU",
    country: "Mauritius",
  },
  {
    show: false,
    dial_code: "+231",
    flag: "\ud83c\uddf1\ud83c\uddf7",
    cc: "LR",
    country: "Liberia",
  },
  {
    show: false,
    dial_code: "+232",
    flag: "\ud83c\uddf8\ud83c\uddf1",
    cc: "SL",
    country: "Sierra Leone",
  },
  {
    show: false,
    dial_code: "+233",
    flag: "\ud83c\uddec\ud83c\udded",
    cc: "GH",
    country: "Ghana",
  },
  {
    show: false,
    dial_code: "+234",
    flag: "\ud83c\uddf3\ud83c\uddec",
    cc: "NG",
    country: "Nigeria",
  },
  {
    show: false,
    dial_code: "+235",
    flag: "\ud83c\uddf9\ud83c\udde9",
    cc: "TD",
    country: "Chad",
  },
  {
    show: false,
    dial_code: "+236",
    flag: "\ud83c\udde8\ud83c\uddeb",
    cc: "CF",
    country: "Central African Republic",
  },
  {
    show: false,
    dial_code: "+237",
    flag: "\ud83c\udde8\ud83c\uddf2",
    cc: "CM",
    country: "Cameroon",
  },
  {
    show: false,
    dial_code: "+238",
    flag: "\ud83c\udde8\ud83c\uddfb",
    cc: "CV",
    country: "Cape Verde",
  },
  {
    show: false,
    dial_code: "+239",
    flag: "\ud83c\uddf8\ud83c\uddf9",
    cc: "ST",
    country: "Sao Tome and Principe",
  },
  {
    show: false,
    dial_code: "+240",
    flag: "\ud83c\uddec\ud83c\uddf6",
    cc: "GQ",
    country: "Equatorial Guinea",
  },
  {
    show: false,
    dial_code: "+241",
    flag: "\ud83c\uddec\ud83c\udde6",
    cc: "GA",
    country: "Gabon",
  },
  {
    show: false,
    dial_code: "+242",
    flag: "\ud83c\udde8\ud83c\uddec",
    cc: "CG",
    country: "Congo",
  },
  {
    show: false,
    dial_code: "+243",
    flag: "\ud83c\udde8\ud83c\udde9",
    cc: "CD",
    country: "Congo",
  },
  {
    show: false,
    dial_code: "+244",
    flag: "\ud83c\udde6\ud83c\uddf4",
    cc: "AO",
    country: "Angola",
  },
  {
    show: false,
    dial_code: "+245",
    flag: "\ud83c\uddec\ud83c\uddfc",
    cc: "GW",
    country: "Guinea-Bissau",
  },
  {
    show: false,
    dial_code: "+246",
    flag: "\ud83c\uddee\ud83c\uddf4",
    cc: "IO",
    country: "British Indian Ocean Territory",
  },
  {
    show: false,
    dial_code: "+248",
    flag: "\ud83c\uddf8\ud83c\udde8",
    cc: "SC",
    country: "Seychelles",
  },
  {
    show: true,
    dial_code: "+249",
    flag: "\ud83c\uddf8\ud83c\udde9",
    cc: "SD",
    country: "Sudan",
  },
  {
    show: false,
    dial_code: "+250",
    flag: "\ud83c\uddf7\ud83c\uddfc",
    cc: "RW",
    country: "Rwanda",
  },
  {
    show: false,
    dial_code: "+251",
    flag: "\ud83c\uddea\ud83c\uddf9",
    cc: "ET",
    country: "Ethiopia",
  },
  {
    show: false,
    dial_code: "+252",
    flag: "\ud83c\uddf8\ud83c\uddf4",
    cc: "SO",
    country: "Somalia",
  },
  {
    show: false,
    dial_code: "+253",
    flag: "\ud83c\udde9\ud83c\uddef",
    cc: "DJ",
    country: "Djibouti",
  },
  {
    show: false,
    dial_code: "+254",
    flag: "\ud83c\uddf0\ud83c\uddea",
    cc: "KE",
    country: "Kenya",
  },
  {
    show: false,
    dial_code: "+255",
    flag: "\ud83c\uddf9\ud83c\uddff",
    cc: "TZ",
    country: "Tanzania",
  },
  {
    show: false,
    dial_code: "+256",
    flag: "\ud83c\uddfa\ud83c\uddec",
    cc: "UG",
    country: "Uganda",
  },
  {
    show: false,
    dial_code: "+257",
    flag: "\ud83c\udde7\ud83c\uddee",
    cc: "BI",
    country: "Burundi",
  },
  {
    show: false,
    dial_code: "+258",
    flag: "\ud83c\uddf2\ud83c\uddff",
    cc: "MZ",
    country: "Mozambique",
  },
  {
    show: false,
    dial_code: "+260",
    flag: "\ud83c\uddff\ud83c\uddf2",
    cc: "ZM",
    country: "Zambia",
  },
  {
    show: false,
    dial_code: "+261",
    flag: "\ud83c\uddf2\ud83c\uddec",
    cc: "MG",
    country: "Madagascar",
  },
  {
    show: false,
    dial_code: "+262",
    flag: "\ud83c\uddf9\ud83c\uddeb",
    cc: "TF",
    country: "French Southern Territories",
  },
  {
    show: false,
    dial_code: "+262",
    flag: "\ud83c\uddfe\ud83c\uddf9",
    cc: "YT",
    country: "Mayotte",
  },
  {
    show: false,
    dial_code: "+262",
    flag: "\ud83c\uddf7\ud83c\uddea",
    cc: "RE",
    country: "Reunion",
  },
  {
    show: false,
    dial_code: "+263",
    flag: "\ud83c\uddff\ud83c\uddfc",
    cc: "ZW",
    country: "Zimbabwe",
  },
  {
    show: false,
    dial_code: "+264",
    flag: "\ud83c\uddf3\ud83c\udde6",
    cc: "NA",
    country: "Namibia",
  },
  {
    show: false,
    dial_code: "+265",
    flag: "\ud83c\uddf2\ud83c\uddfc",
    cc: "MW",
    country: "Malawi",
  },
  {
    show: false,
    dial_code: "+266",
    flag: "\ud83c\uddf1\ud83c\uddf8",
    cc: "LS",
    country: "Lesotho",
  },
  {
    show: false,
    dial_code: "+267",
    flag: "\ud83c\udde7\ud83c\uddfc",
    cc: "BW",
    country: "Botswana",
  },
  {
    show: false,
    dial_code: "+268",
    flag: "\ud83c\uddf8\ud83c\uddff",
    cc: "SZ",
    country: "Swaziland",
  },
  {
    show: false,
    dial_code: "+269",
    flag: "\ud83c\uddf0\ud83c\uddf2",
    cc: "KM",
    country: "Comoros",
  },
  {
    show: false,
    dial_code: "+27",
    flag: "\ud83c\uddff\ud83c\udde6",
    cc: "ZA",
    country: "South Africa",
  },
  {
    show: false,
    dial_code: "+290",
    flag: "\ud83c\uddf8\ud83c\udded",
    cc: "SH",
    country: "Saint Helena",
  },
  {
    show: false,
    dial_code: "+291",
    flag: "\ud83c\uddea\ud83c\uddf7",
    cc: "ER",
    country: "Eritrea",
  },
  {
    show: false,
    dial_code: "+297",
    flag: "\ud83c\udde6\ud83c\uddfc",
    cc: "AW",
    country: "Aruba",
  },
  {
    show: false,
    dial_code: "+298",
    flag: "\ud83c\uddeb\ud83c\uddf4",
    cc: "FO",
    country: "Faroe Islands",
  },
  {
    show: false,
    dial_code: "+299",
    flag: "\ud83c\uddec\ud83c\uddf1",
    cc: "GL",
    country: "Greenland",
  },
  {
    show: false,
    dial_code: "+30",
    flag: "\ud83c\uddec\ud83c\uddf7",
    cc: "GR",
    country: "Greece",
  },
  {
    show: false,
    dial_code: "+31",
    flag: "\ud83c\uddf3\ud83c\uddf1",
    cc: "NL",
    country: "Netherlands",
  },
  {
    show: false,
    dial_code: "+32",
    flag: "\ud83c\udde7\ud83c\uddea",
    cc: "BE",
    country: "Belgium",
  },
  {
    show: false,
    dial_code: "+33",
    flag: "\ud83c\uddeb\ud83c\uddf7",
    cc: "FR",
    country: "France",
  },
  {
    show: false,
    dial_code: "+34",
    flag: "\ud83c\uddea\ud83c\uddf8",
    cc: "ES",
    country: "Spain",
  },
  {
    show: false,
    dial_code: "+345",
    flag: "\ud83c\uddf0\ud83c\uddfe",
    cc: "KY",
    country: "Cayman Islands",
  },
  {
    show: false,
    dial_code: "+350",
    flag: "\ud83c\uddec\ud83c\uddee",
    cc: "GI",
    country: "Gibraltar",
  },
  {
    show: false,
    dial_code: "+351",
    flag: "\ud83c\uddf5\ud83c\uddf9",
    cc: "PT",
    country: "Portugal",
  },
  {
    show: false,
    dial_code: "+352",
    flag: "\ud83c\uddf1\ud83c\uddfa",
    cc: "LU",
    country: "Luxembourg",
  },
  {
    show: false,
    dial_code: "+353",
    flag: "\ud83c\uddee\ud83c\uddea",
    cc: "IE",
    country: "Ireland",
  },
  {
    show: false,
    dial_code: "+354",
    flag: "\ud83c\uddee\ud83c\uddf8",
    cc: "IS",
    country: "Iceland",
  },
  {
    show: false,
    dial_code: "+355",
    flag: "\ud83c\udde6\ud83c\uddf1",
    cc: "AL",
    country: "Albania",
  },
  {
    show: false,
    dial_code: "+356",
    flag: "\ud83c\uddf2\ud83c\uddf9",
    cc: "MT",
    country: "Malta",
  },
  {
    show: false,
    dial_code: "+357",
    flag: "\ud83c\udde8\ud83c\uddfe",
    cc: "CY",
    country: "Cyprus",
  },
  {
    show: false,
    dial_code: "+358",
    flag: "\ud83c\udde6\ud83c\uddfd",
    cc: "AX",
    country: "\u00c5land Islands",
  },
  {
    show: false,
    dial_code: "+358",
    flag: "\ud83c\uddeb\ud83c\uddee",
    cc: "FI",
    country: "Finland",
  },
  {
    show: false,
    dial_code: "+359",
    flag: "\ud83c\udde7\ud83c\uddec",
    cc: "BG",
    country: "Bulgaria",
  },
  {
    show: false,
    dial_code: "+36",
    flag: "\ud83c\udded\ud83c\uddfa",
    cc: "HU",
    country: "Hungary",
  },
  {
    show: false,
    dial_code: "+370",
    flag: "\ud83c\uddf1\ud83c\uddf9",
    cc: "LT",
    country: "Lithuania",
  },
  {
    show: false,
    dial_code: "+371",
    flag: "\ud83c\uddf1\ud83c\uddfb",
    cc: "LV",
    country: "Latvia",
  },
  {
    show: false,
    dial_code: "+372",
    flag: "\ud83c\uddea\ud83c\uddea",
    cc: "EE",
    country: "Estonia",
  },
  {
    show: false,
    dial_code: "+373",
    flag: "\ud83c\uddf2\ud83c\udde9",
    cc: "MD",
    country: "Moldova",
  },
  {
    show: false,
    dial_code: "+374",
    flag: "\ud83c\udde6\ud83c\uddf2",
    cc: "AM",
    country: "Armenia",
  },
  {
    show: false,
    dial_code: "+375",
    flag: "\ud83c\udde7\ud83c\uddfe",
    cc: "BY",
    country: "Belarus",
  },
  {
    show: false,
    dial_code: "+376",
    flag: "\ud83c\udde6\ud83c\udde9",
    cc: "AD",
    country: "Andorra",
  },
  {
    show: false,
    dial_code: "+377",
    flag: "\ud83c\uddf2\ud83c\udde8",
    cc: "MC",
    country: "Monaco",
  },
  {
    show: false,
    dial_code: "+378",
    flag: "\ud83c\uddf8\ud83c\uddf2",
    cc: "SM",
    country: "San Marino",
  },
  {
    show: false,
    dial_code: "+379",
    flag: "\ud83c\uddfb\ud83c\udde6",
    cc: "VA",
    country: "Holy See (Vatican City State)",
  },
  {
    show: false,
    dial_code: "+380",
    flag: "\ud83c\uddfa\ud83c\udde6",
    cc: "UA",
    country: "Ukraine",
  },
  {
    show: false,
    dial_code: "+381",
    flag: "\ud83c\uddf7\ud83c\uddf8",
    cc: "RS",
    country: "Serbia",
  },
  {
    show: false,
    dial_code: "+382",
    flag: "\ud83c\uddf2\ud83c\uddea",
    cc: "ME",
    country: "Montenegro",
  },
  {
    show: false,
    dial_code: "+383",
    flag: "\ud83c\uddfd\ud83c\uddf0",
    cc: "XK",
    country: "Kosovo",
  },
  {
    show: false,
    dial_code: "+385",
    flag: "\ud83c\udded\ud83c\uddf7",
    cc: "HR",
    country: "Croatia",
  },
  {
    show: false,
    dial_code: "+386",
    flag: "\ud83c\uddf8\ud83c\uddee",
    cc: "SI",
    country: "Slovenia",
  },
  {
    show: false,
    dial_code: "+387",
    flag: "\ud83c\udde7\ud83c\udde6",
    cc: "BA",
    country: "Bosnia and Herzegovina",
  },
  {
    show: false,
    dial_code: "+389",
    flag: "\ud83c\uddf2\ud83c\uddf0",
    cc: "MK",
    country: "Macedonia",
  },
  {
    show: false,
    dial_code: "+39",
    flag: "\ud83c\uddee\ud83c\uddf9",
    cc: "IT",
    country: "Italy",
  },
  {
    show: false,
    dial_code: "+40",
    flag: "\ud83c\uddf7\ud83c\uddf4",
    cc: "RO",
    country: "Romania",
  },
  {
    show: false,
    dial_code: "+41",
    flag: "\ud83c\udde8\ud83c\udded",
    cc: "CH",
    country: "Switzerland",
  },
  {
    show: false,
    dial_code: "+420",
    flag: "\ud83c\udde8\ud83c\uddff",
    cc: "CZ",
    country: "Czech Republic",
  },
  {
    show: false,
    dial_code: "+421",
    flag: "\ud83c\uddf8\ud83c\uddf0",
    cc: "SK",
    country: "Slovakia",
  },
  {
    show: false,
    dial_code: "+423",
    flag: "\ud83c\uddf1\ud83c\uddee",
    cc: "LI",
    country: "Liechtenstein",
  },
  {
    show: false,
    dial_code: "+43",
    flag: "\ud83c\udde6\ud83c\uddf9",
    cc: "AT",
    country: "Austria",
  },
  {
    show: false,
    dial_code: "+44",
    flag: "\ud83c\uddec\ud83c\uddec",
    cc: "GG",
    country: "Guernsey",
  },
  {
    show: false,
    dial_code: "+44",
    flag: "\ud83c\uddee\ud83c\uddf2",
    cc: "IM",
    country: "Isle of Man",
  },
  {
    show: false,
    dial_code: "+44",
    flag: "\ud83c\uddef\ud83c\uddea",
    cc: "JE",
    country: "Jersey",
  },
  {
    show: false,
    dial_code: "+44",
    flag: "\ud83c\uddec\ud83c\udde7",
    cc: "GB",
    country: "United Kingdom",
  },
  {
    show: false,
    dial_code: "+45",
    flag: "\ud83c\udde9\ud83c\uddf0",
    cc: "DK",
    country: "Denmark",
  },
  {
    show: false,
    dial_code: "+46",
    flag: "\ud83c\uddf8\ud83c\uddea",
    cc: "SE",
    country: "Sweden",
  },
  {
    show: false,
    dial_code: "+47",
    flag: "\ud83c\udde7\ud83c\uddfb",
    cc: "BV",
    country: "Bouvet Island",
  },
  {
    show: false,
    dial_code: "+47",
    flag: "\ud83c\uddf3\ud83c\uddf4",
    cc: "NO",
    country: "Norway",
  },
  {
    show: false,
    dial_code: "+47",
    flag: "\ud83c\uddf8\ud83c\uddef",
    cc: "SJ",
    country: "Svalbard and Jan Mayen",
  },
  {
    show: false,
    dial_code: "+48",
    flag: "\ud83c\uddf5\ud83c\uddf1",
    cc: "PL",
    country: "Poland",
  },
  {
    show: false,
    dial_code: "+49",
    flag: "\ud83c\udde9\ud83c\uddea",
    cc: "DE",
    country: "Germany",
  },
  {
    show: false,
    dial_code: "+500",
    flag: "\ud83c\uddeb\ud83c\uddf0",
    cc: "FK",
    country: "Falkland Islands (Malvinas)",
  },
  {
    show: false,
    dial_code: "+500",
    flag: "\ud83c\uddec\ud83c\uddf8",
    cc: "GS",
    country: "South Georgia and the South Sandwich Islands",
  },
  {
    show: false,
    dial_code: "+501",
    flag: "\ud83c\udde7\ud83c\uddff",
    cc: "BZ",
    country: "Belize",
  },
  {
    show: false,
    dial_code: "+502",
    flag: "\ud83c\uddec\ud83c\uddf9",
    cc: "GT",
    country: "Guatemala",
  },
  {
    show: false,
    dial_code: "+503",
    flag: "\ud83c\uddf8\ud83c\uddfb",
    cc: "SV",
    country: "El Salvador",
  },
  {
    show: false,
    dial_code: "+504",
    flag: "\ud83c\udded\ud83c\uddf3",
    cc: "HN",
    country: "Honduras",
  },
  {
    show: false,
    dial_code: "+505",
    flag: "\ud83c\uddf3\ud83c\uddee",
    cc: "NI",
    country: "Nicaragua",
  },
  {
    show: false,
    dial_code: "+506",
    flag: "\ud83c\udde8\ud83c\uddf7",
    cc: "CR",
    country: "Costa Rica",
  },
  {
    show: false,
    dial_code: "+507",
    flag: "\ud83c\uddf5\ud83c\udde6",
    cc: "PA",
    country: "Panama",
  },
  {
    show: false,
    dial_code: "+508",
    flag: "\ud83c\uddf5\ud83c\uddf2",
    cc: "PM",
    country: "Saint Pierre and Miquelon",
  },
  {
    show: false,
    dial_code: "+509",
    flag: "\ud83c\udded\ud83c\uddf9",
    cc: "HT",
    country: "Haiti",
  },
  {
    show: false,
    dial_code: "+51",
    flag: "\ud83c\uddf5\ud83c\uddea",
    cc: "PE",
    country: "Peru",
  },
  {
    show: false,
    dial_code: "+52",
    flag: "\ud83c\uddf2\ud83c\uddfd",
    cc: "MX",
    country: "Mexico",
  },
  {
    show: false,
    dial_code: "+53",
    flag: "\ud83c\udde8\ud83c\uddfa",
    cc: "CU",
    country: "Cuba",
  },
  {
    show: false,
    dial_code: "+54",
    flag: "\ud83c\udde6\ud83c\uddf7",
    cc: "AR",
    country: "Argentina",
  },
  {
    show: false,
    dial_code: "+55",
    flag: "\ud83c\udde7\ud83c\uddf7",
    cc: "BR",
    country: "Brazil",
  },
  {
    show: false,
    dial_code: "+56",
    flag: "\ud83c\udde8\ud83c\uddf1",
    cc: "CL",
    country: "Chile",
  },
  {
    show: false,
    dial_code: "+57",
    flag: "\ud83c\udde8\ud83c\uddf4",
    cc: "CO",
    country: "Colombia",
  },
  {
    show: false,
    dial_code: "+58",
    flag: "\ud83c\uddfb\ud83c\uddea",
    cc: "VE",
    country: "Venezuela",
  },
  {
    show: false,
    dial_code: "+590",
    flag: "\ud83c\uddec\ud83c\uddf5",
    cc: "GP",
    country: "Guadeloupe",
  },
  {
    show: false,
    dial_code: "+590",
    flag: "\ud83c\udde7\ud83c\uddf1",
    cc: "BL",
    country: "Saint Barthelemy",
  },
  {
    show: false,
    dial_code: "+590",
    flag: "\ud83c\uddf2\ud83c\uddeb",
    cc: "MF",
    country: "Saint Martin",
  },
  {
    show: false,
    dial_code: "+591",
    flag: "\ud83c\udde7\ud83c\uddf4",
    cc: "BO",
    country: "Bolivia",
  },
  {
    show: false,
    dial_code: "+592",
    flag: "\ud83c\uddec\ud83c\uddfe",
    cc: "GY",
    country: "Guyana",
  },
  {
    show: false,
    dial_code: "+593",
    flag: "\ud83c\uddea\ud83c\udde8",
    cc: "EC",
    country: "Ecuador",
  },
  {
    show: false,
    dial_code: "+594",
    flag: "\ud83c\uddec\ud83c\uddeb",
    cc: "GF",
    country: "French Guiana",
  },
  {
    show: false,
    dial_code: "+595",
    flag: "\ud83c\uddf5\ud83c\uddfe",
    cc: "PY",
    country: "Paraguay",
  },
  {
    show: false,
    dial_code: "+596",
    flag: "\ud83c\uddf2\ud83c\uddf6",
    cc: "MQ",
    country: "Martinique",
  },
  {
    show: false,
    dial_code: "+597",
    flag: "\ud83c\uddf8\ud83c\uddf7",
    cc: "SR",
    country: "Suriname",
  },
  {
    show: false,
    dial_code: "+598",
    flag: "\ud83c\uddfa\ud83c\uddfe",
    cc: "UY",
    country: "Uruguay",
  },
  {
    show: false,
    dial_code: "+599",
    flag: "",
    cc: "AN",
    country: "Netherlands Antilles",
  },
  {
    show: false,
    dial_code: "+60",
    flag: "\ud83c\uddf2\ud83c\uddfe",
    cc: "MY",
    country: "Malaysia",
  },
  {
    show: false,
    dial_code: "+61",
    flag: "\ud83c\udde6\ud83c\uddfa",
    cc: "AU",
    country: "Australia",
  },
  {
    show: false,
    dial_code: "+61",
    flag: "\ud83c\udde8\ud83c\uddfd",
    cc: "CX",
    country: "Christmas Island",
  },
  {
    show: false,
    dial_code: "+61",
    flag: "\ud83c\udde8\ud83c\udde8",
    cc: "CC",
    country: "Cocos (Keeling) Islands",
  },
  {
    show: false,
    dial_code: "+62",
    flag: "\ud83c\uddee\ud83c\udde9",
    cc: "ID",
    country: "Indonesia",
  },
  {
    show: false,
    dial_code: "+63",
    flag: "\ud83c\uddf5\ud83c\udded",
    cc: "PH",
    country: "Philippines",
  },
  {
    show: false,
    dial_code: "+64",
    flag: "\ud83c\uddf3\ud83c\uddff",
    cc: "NZ",
    country: "New Zealand",
  },
  {
    show: false,
    dial_code: "+64",
    flag: "\ud83c\uddf5\ud83c\uddf3",
    cc: "PN",
    country: "Pitcairn",
  },
  {
    show: false,
    dial_code: "+65",
    flag: "\ud83c\uddf8\ud83c\uddec",
    cc: "SG",
    country: "Singapore",
  },
  {
    show: false,
    dial_code: "+66",
    flag: "\ud83c\uddf9\ud83c\udded",
    cc: "TH",
    country: "Thailand",
  },
  {
    show: false,
    dial_code: "+670",
    flag: "\ud83c\uddf9\ud83c\uddf1",
    cc: "TL",
    country: "Timor-Leste",
  },
  {
    show: false,
    dial_code: "+672",
    flag: "\ud83c\udde6\ud83c\uddf6",
    cc: "AQ",
    country: "Antarctica",
  },
  {
    show: false,
    dial_code: "+672",
    flag: "\ud83c\udded\ud83c\uddf2",
    cc: "HM",
    country: "Heard Island and Mcdonald Islands",
  },
  {
    show: false,
    dial_code: "+672",
    flag: "\ud83c\uddf3\ud83c\uddeb",
    cc: "NF",
    country: "Norfolk Island",
  },
  {
    show: false,
    dial_code: "+673",
    flag: "\ud83c\udde7\ud83c\uddf3",
    cc: "BN",
    country: "Brunei Darussalam",
  },
  {
    show: false,
    dial_code: "+674",
    flag: "\ud83c\uddf3\ud83c\uddf7",
    cc: "NR",
    country: "Nauru",
  },
  {
    show: false,
    dial_code: "+675",
    flag: "\ud83c\uddf5\ud83c\uddec",
    cc: "PG",
    country: "Papua New Guinea",
  },
  {
    show: false,
    dial_code: "+676",
    flag: "\ud83c\uddf9\ud83c\uddf4",
    cc: "TO",
    country: "Tonga",
  },
  {
    show: false,
    dial_code: "+677",
    flag: "\ud83c\uddf8\ud83c\udde7",
    cc: "SB",
    country: "Solomon Islands",
  },
  {
    show: false,
    dial_code: "+678",
    flag: "\ud83c\uddfb\ud83c\uddfa",
    cc: "VU",
    country: "Vanuatu",
  },
  {
    show: false,
    dial_code: "+679",
    flag: "\ud83c\uddeb\ud83c\uddef",
    cc: "FJ",
    country: "Fiji",
  },
  {
    show: false,
    dial_code: "+680",
    flag: "\ud83c\uddf5\ud83c\uddfc",
    cc: "PW",
    country: "Palau",
  },
  {
    show: false,
    dial_code: "+681",
    flag: "\ud83c\uddfc\ud83c\uddeb",
    cc: "WF",
    country: "Wallis and Futuna",
  },
  {
    show: false,
    dial_code: "+682",
    flag: "\ud83c\udde8\ud83c\uddf0",
    cc: "CK",
    country: "Cook Islands",
  },
  {
    show: false,
    dial_code: "+683",
    flag: "\ud83c\uddf3\ud83c\uddfa",
    cc: "NU",
    country: "Niue",
  },
  {
    show: false,
    dial_code: "+685",
    flag: "\ud83c\uddfc\ud83c\uddf8",
    cc: "WS",
    country: "Samoa",
  },
  {
    show: false,
    dial_code: "+686",
    flag: "\ud83c\uddf0\ud83c\uddee",
    cc: "KI",
    country: "Kiribati",
  },
  {
    show: false,
    dial_code: "+687",
    flag: "\ud83c\uddf3\ud83c\udde8",
    cc: "NC",
    country: "New Caledonia",
  },
  {
    show: false,
    dial_code: "+688",
    flag: "\ud83c\uddf9\ud83c\uddfb",
    cc: "TV",
    country: "Tuvalu",
  },
  {
    show: false,
    dial_code: "+689",
    flag: "\ud83c\uddf5\ud83c\uddeb",
    cc: "PF",
    country: "French Polynesia",
  },
  {
    show: false,
    dial_code: "+690",
    flag: "\ud83c\uddf9\ud83c\uddf0",
    cc: "TK",
    country: "Tokelau",
  },
  {
    show: false,
    dial_code: "+691",
    flag: "\ud83c\uddeb\ud83c\uddf2",
    cc: "FM",
    country: "Micronesia",
  },
  {
    show: false,
    dial_code: "+692",
    flag: "\ud83c\uddf2\ud83c\udded",
    cc: "MH",
    country: "Marshall Islands",
  },
  {
    show: false,
    dial_code: "+7",
    flag: "\ud83c\uddf0\ud83c\uddff",
    cc: "KZ",
    country: "Kazakhstan",
  },
  {
    show: false,
    dial_code: "+7",
    flag: "\ud83c\uddf7\ud83c\uddfa",
    cc: "RU",
    country: "Russia",
  },
  {
    show: false,
    dial_code: "+81",
    flag: "\ud83c\uddef\ud83c\uddf5",
    cc: "JP",
    country: "Japan",
  },
  {
    show: false,
    dial_code: "+82",
    flag: "\ud83c\uddf0\ud83c\uddf7",
    cc: "KR",
    country: "Korea",
  },
  {
    show: false,
    dial_code: "+84",
    flag: "\ud83c\uddfb\ud83c\uddf3",
    cc: "VN",
    country: "Vietnam",
  },
  {
    show: false,
    dial_code: "+850",
    flag: "\ud83c\uddf0\ud83c\uddf5",
    cc: "KP",
    country: "Korea",
  },
  {
    show: false,
    dial_code: "+852",
    flag: "\ud83c\udded\ud83c\uddf0",
    cc: "HK",
    country: "Hong Kong",
  },
  {
    show: false,
    dial_code: "+853",
    flag: "\ud83c\uddf2\ud83c\uddf4",
    cc: "MO",
    country: "Macao",
  },
  {
    show: false,
    dial_code: "+855",
    flag: "\ud83c\uddf0\ud83c\udded",
    cc: "KH",
    country: "Cambodia",
  },
  {
    show: false,
    dial_code: "+856",
    flag: "\ud83c\uddf1\ud83c\udde6",
    cc: "LA",
    country: "Laos",
  },
  {
    show: false,
    dial_code: "+86",
    flag: "\ud83c\udde8\ud83c\uddf3",
    cc: "CN",
    country: "China",
  },
  {
    show: false,
    dial_code: "+880",
    flag: "\ud83c\udde7\ud83c\udde9",
    cc: "BD",
    country: "Bangladesh",
  },
  {
    show: false,
    dial_code: "+886",
    flag: "\ud83c\uddf9\ud83c\uddfc",
    cc: "TW",
    country: "Taiwan",
  },
  {
    show: false,
    dial_code: "+90",
    flag: "\ud83c\uddf9\ud83c\uddf7",
    cc: "TR",
    country: "Turkey",
  },
  {
    show: true,
    dial_code: "+91",
    flag: "\ud83c\uddee\ud83c\uddf3",
    cc: "IN",
    country: "India",
  },
  {
    show: false,
    dial_code: "+92",
    flag: "\ud83c\uddf5\ud83c\uddf0",
    cc: "PK",
    country: "Pakistan",
  },
  {
    show: false,
    dial_code: "+93",
    flag: "\ud83c\udde6\ud83c\uddeb",
    cc: "AF",
    country: "Afghanistan",
  },
  {
    show: false,
    dial_code: "+94",
    flag: "\ud83c\uddf1\ud83c\uddf0",
    cc: "LK",
    country: "Sri Lanka",
  },
  {
    show: false,
    dial_code: "+95",
    flag: "\ud83c\uddf2\ud83c\uddf2",
    cc: "MM",
    country: "Myanmar",
  },
  {
    show: false,
    dial_code: "+960",
    flag: "\ud83c\uddf2\ud83c\uddfb",
    cc: "MV",
    country: "Maldives",
  },
  {
    show: false,
    dial_code: "+961",
    flag: "\ud83c\uddf1\ud83c\udde7",
    cc: "LB",
    country: "Lebanon",
  },
  {
    show: false,
    dial_code: "+962",
    flag: "\ud83c\uddef\ud83c\uddf4",
    cc: "JO",
    country: "Jordan",
  },
  {
    show: false,
    dial_code: "+963",
    flag: "\ud83c\uddf8\ud83c\uddfe",
    cc: "SY",
    country: "Syrian Arab Republic",
  },
  {
    show: false,
    dial_code: "+964",
    flag: "\ud83c\uddee\ud83c\uddf6",
    cc: "IQ",
    country: "Iraq",
  },
  {
    show: true,
    dial_code: "+965",
    flag: "\ud83c\uddf0\ud83c\uddfc",
    cc: "KW",
    country: "Kuwait",
  },
  {
    show: true,
    dial_code: "+971",
    flag: "\ud83c\udde6\ud83c\uddea",
    cc: "AE",
    country: "UAE",
  },
  {
    show: true,
    dial_code: "+966",
    flag: "\ud83c\uddf8\ud83c\udde6",
    cc: "SA",
    country: "Saudi Arabia",
  },
  {
    show: false,
    dial_code: "+967",
    flag: "\ud83c\uddfe\ud83c\uddea",
    cc: "YE",
    country: "Yemen",
  },
  {
    show: true,
    dial_code: "+968",
    flag: "\ud83c\uddf4\ud83c\uddf2",
    cc: "OM",
    country: "Oman",
  },
  {
    show: false,
    dial_code: "+970",
    flag: "\ud83c\uddf5\ud83c\uddf8",
    cc: "PS",
    country: "Palestinian Territory",
  },
  {
    show: false,
    dial_code: "+971",
    flag: "\ud83c\udde6\ud83c\uddea",
    cc: "AE",
    country: "United Arab Emirates",
  },
  {
    show: false,
    dial_code: "+972",
    flag: "\ud83c\uddee\ud83c\uddf1",
    cc: "IL",
    country: "Israel",
  },
  {
    show: true,
    dial_code: "+973",
    flag: "\ud83c\udde7\ud83c\udded",
    cc: "BH",
    country: "Bahrain",
  },
  {
    show: true,
    dial_code: "+974",
    flag: "\ud83c\uddf6\ud83c\udde6",
    cc: "QA",
    country: "Qatar",
  },
  {
    show: false,
    dial_code: "+975",
    flag: "\ud83c\udde7\ud83c\uddf9",
    cc: "BT",
    country: "Bhutan",
  },
  {
    show: false,
    dial_code: "+976",
    flag: "\ud83c\uddf2\ud83c\uddf3",
    cc: "MN",
    country: "Mongolia",
  },
  {
    show: false,
    dial_code: "+977",
    flag: "\ud83c\uddf3\ud83c\uddf5",
    cc: "NP",
    country: "Nepal",
  },
  {
    show: false,
    dial_code: "+98",
    flag: "\ud83c\uddee\ud83c\uddf7",
    cc: "IR",
    country: "Iran",
  },
  {
    show: false,
    dial_code: "+992",
    flag: "\ud83c\uddf9\ud83c\uddef",
    cc: "TJ",
    country: "Tajikistan",
  },
  {
    show: false,
    dial_code: "+993",
    flag: "\ud83c\uddf9\ud83c\uddf2",
    cc: "TM",
    country: "Turkmenistan",
  },
  {
    show: false,
    dial_code: "+994",
    flag: "\ud83c\udde6\ud83c\uddff",
    cc: "AZ",
    country: "Azerbaijan",
  },
  {
    show: false,
    dial_code: "+995",
    flag: "\ud83c\uddec\ud83c\uddea",
    cc: "GE",
    country: "Georgia",
  },
  {
    show: false,
    dial_code: "+996",
    flag: "\ud83c\uddf0\ud83c\uddec",
    cc: "KG",
    country: "Kyrgyzstan",
  },
  {
    show: false,
    dial_code: "+998",
    flag: "\ud83c\uddfa\ud83c\uddff",
    cc: "UZ",
    country: "Uzbekistan",
  },
];
