import { Col, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAppointmentAuditTrail } from "../../redux/reducers/appointment.slice";
import { formatDate } from "../../shared/Utils/utilities";
const { Text } = Typography;

const data = [
  {
    action: "Checked Out",
    action_by: "Doctor (Vinayak)",
    email: "vinayak@gmail.com",
    source: "Web",
    date: "23rd Oct 2021",
  },
];

const PatientJourney: React.FunctionComponent<any> = (Props) => {
  const [patientData, setpatientData] = useState();
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      className: "capitalize",
    },
    {
      title: "Action By",
      dataIndex: "action_by",
      className: "capitalize",
    },
    {
      title: "User Email",
      dataIndex: "user_email",
      className: "capitalize",
      // render: (a: any) => { return (<>{formatDate(a)}</>) }
    },
    {
      title: "Source",
      dataIndex: "source",
      className: "capitalize",
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      render: (a: any) => {
        return <>{formatDate(a)}</>;
      },
    },
  ];

  useEffect(() => {
    Props?.appointmentId && dispatch(fetchAppointmentAuditTrail(Props?.appointmentId)).then(
      (val: any) => {
        setpatientData(val?.payload?.data?.data?.filter((val: any) => {
          return val.action !== 'consulting'
        }));
      }
    );
  }, []);

  useEffect(() => {
    if (Props?.isReset !== "reset" && Props?.isReset !== undefined) {
      dispatch(fetchAppointmentAuditTrail(Props?.appointmentId)).then(
        (val: any) => {
          setpatientData(val?.payload?.data?.data);
        }
      );
    }
  }, [Props.isReset]);

  return (
    <Col span={24}>
      <Table
        loading={false}
        locale={{
          emptyText: (
            <>
              <Space direction={"vertical"} size={10}>
                <Text className="max-subHead-color">No Records</Text>
              </Space>
            </>
          ),
        }}
        pagination={false}
        scroll={{ x: true }}
        dataSource={patientData}
        columns={columns}
      />
    </Col>
  );
};

export default PatientJourney;
