import { Divider, PageHeader } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchContact } from "../../../redux/reducers/contacts.slice";
import { fetchLeadDetails } from "../../../redux/reducers/leads.slice";
import { addNewPatientFromContactDirect } from "../../../redux/reducers/patients.slice";
import { contactNameFormat, formatDob } from "../../../shared/Utils/utilities";
import { RootState } from "../../../shared/constants";
import OrderMedicineCompoment from "./components/ordermedicine";

function CRMOrderMedicinePage() {
  let params: any = useParams();
  let leadId = params?.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { lead } = useSelector((state: RootState) => state.leads);
  const { contact } = useSelector((state: RootState) => state.contact);
  const [contactData, setContact] = useState(null as any);

  useEffect(() => {
    lead?.id !== leadId && dispatch(fetchLeadDetails(leadId));
  }, [leadId]);

  useEffect(() => {
    if (lead?.contact_id && lead?.contact_id !== contact?.id) {
      dispatch(fetchContact(lead.contact_id));
    }
  }, [lead]);
  useEffect(() => {
    if (contact) {
      if (contact?.patient_id) {
        setContact(contact);
      } else {
        let patient_create_payload = {
          first_name: contact.first_name,
          last_name: contact.last_name,
          mobile: contact.phone,
          email: "rahultest001@gmail.com",
          gender_id: contact.gender_id,
          nationality_id: contact.nationality_id,
          uhid: contact.uhid ? contact.uhid : null,
          dob: contact.dob ? formatDob(contact.dob) : null,
          age: contact.birth_year ? (new Date().getFullYear() - contact.birth_year) : null
        }
        dispatch(
          addNewPatientFromContactDirect(patient_create_payload)
        ).then((val: any) => {
          if (val?.payload?.data?.statusCode == 200) {
            dispatch(fetchContact(lead.contact_id));
          }
        });
      }
    }
  }, [contact]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        style={{ lineHeight: 1 }}
        onBack={() => window.history.back()}
        title={
          <>
            <span>Order Medicine</span>
            <br />
            {contactData?.patient_id && (
              <p
                className="mb-0 fts14 light-text"
                style={{ lineHeight: "10px" }}
              >
                {contactNameFormat(
                  contactData?.first_name,
                  contactData?.last_name,
                  contactData?.gender?.name,
                  contactData?.dob
                )}{" "}
                <Divider type="vertical"></Divider> {contactData?.phone}
              </p>
            )}
          </>
        }
      ></PageHeader>
      {contactData?.patient_id && (
        <OrderMedicineCompoment
          contact={contactData}
          lead={lead}
        ></OrderMedicineCompoment>
      )}
    </Fragment>
  );
}

export default CRMOrderMedicinePage;
