import { Skeleton } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchActionMap } from "../../../redux/reducers/actionmap.slice";
import { fetchAppointmentById, resetAppointments } from "../../../redux/reducers/appointment.slice";
import { fetchDoctorById, resetDoctor } from "../../../redux/reducers/doctor.slice";
import { fetchPatientsDetailsById, resetPatient } from "../../../redux/reducers/patients.slice";
import {
  clearTicketDetails,
  fetchTicketsDetails
} from "../../../redux/reducers/tickets.slice";
import { formatDropDownValue, getAge } from "../../../shared/Utils/utilities";
import { RootState } from "../../../shared/constants";
import Topbar from "../../../shared/layouts/crm.topbar";
import { ROUTE_CRM_TICKET_LIST } from "../../../shared/routes/crm.routes.constants";
import CreateLeadForm from "../Leads/components/createleadform";
import HeaderButtons from "../components/headerbuttons";
import TopbarNamecard from "../components/topbar.namecard";
import AddTicket from "./components/add-ticket";
import AppointmentTicket from "./components/ticket-appointment";
import GeneralTicket from "./components/ticket-general";
import PharmacyTickets from "./components/ticket-pharmacy";

type Props = {};
const CRMTicketDetailsPage: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const { ticket } = useSelector((state: RootState) => state.tickets);
  const { actionmap } = useSelector((state: RootState) => state.actionmap);
  const { doctor } = useSelector((state: RootState) => state.doctor);
  const { appointment } = useSelector((state: RootState) => state.appointment);
  const { patient } = useSelector((state: RootState) => state.patient);


  const RouteParams: any = useParams();
  const [details, setDetails] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [actionmapData, setActionmapData] = useState(false as any);
  const [leadFormVisible, setLeadFormVisible] = useState(false);
  const [ticketFormVisible, setTicketFormVisible] = useState(false);
  const [component, setComponent] = useState(false as any);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(resetAppointments())
      dispatch(resetDoctor())
      dispatch(resetPatient())
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(clearTicketDetails());
    };
  }, []);
  useEffect(() => {
    RouteParams["id"] && dispatch(fetchTicketsDetails(RouteParams["id"]));
  }, [RouteParams["id"]]);
  useEffect(() => {
    if (details?.display_id) {
      const ticketComponentMap = [
        {
          types: [
            "generic_ticket",
            "appointment_cancel",
            "appointment_reschedule",
            "generic_lead",
            "manual_entry",
            "whatsapp_bot"
          ],
          component: (
            <>
              <Topbar
                extra={topBarExtra}
                back={ROUTE_CRM_TICKET_LIST}
                title={<>All {t("CRM.Tickets.Fields.Tickets")} &gt; #{ticket.display_id}</>}
              ></Topbar>
              <GeneralTicket
                ticket={ticket}
                actionmapData={actionmapData}
              ></GeneralTicket>{" "}
            </>
          ),
        },
        {
          types: [
            "appointment_issues",
            "vc_appointment_issues",
            "missed_call_doctor",
            "missed_call_patient",
            "vc_reminder_call",
          ],
          component: (
            <>
              <Topbar
                extra={topBarExtra}
                title={<TopbarNamecard details={details} loading={loading} />}
              ></Topbar>
              <AppointmentTicket
                ticket={ticket}
                actionmapData={actionmapData}
              ></AppointmentTicket>
            </>
          ),
        },
        {
          types: [
            "document_qc",
            "rx_generation",
            "case_summary",
            "medicine_order_qc",
            "document_qc_instant_vc",
          ],
          component: (
            <>
              <Topbar
                extra={topBarExtra}
                title={<TopbarNamecard details={details} loading={loading} />}
              ></Topbar>
              <PharmacyTickets
                ticket={ticket}
                actionmapData={actionmapData}
                loading={loading}
              ></PharmacyTickets>
            </>
          ),
        },
      ];
      ticketComponentMap.forEach((val: any) => {
        if (
          val?.types.indexOf(actionmapData?.action_name?.toLowerCase()) !== -1
        ) {
          setComponent(val.component);
        }
      });
      if (!actionmapData?.action_name) {
        setComponent(ticketComponentMap[0].component);
      }
    }
  }, [details]);
  console.log("ticketwwwwwwwwwwwwwwww", ticket)


  useEffect(() => {
    if (ticket?.id && actionmapData) {
      setDetails({
        callData: { source: "ticket", ticket_id: ticket?.id },
        display_id: ticket.display_id,
        type: actionmapData?.action_name,
        name: ticket.contact?.first_name + " " + ticket.contact?.last_name,
        age: getAge(ticket.contact?.dob),
        mobile: ticket.contact?.phone,
        uhid: patient?.uhid,
        doctor: appointment?.doctor?.name,
        appTime:
          moment(appointment?.appointment_date).format(
            "DD MMM'YY"
          ) +
          " " +
          appointment?.start_time,
        hospital: formatDropDownValue(ticket?.branch?.name),
        status: appointment?.latest_status,
        doctorPhone: appointment?.doctor?.user?.phone,
        doctorCountryCode:
          appointment?.doctor?.user?.country_code,
      });
      setLoading(false);
    }
  }, [actionmapData, appointment]);
  useEffect(() => {
    actionmap.length > 0 && ticket?.id == RouteParams["id"]
      ? setActionmapData(actionmap[0])
      : setActionmapData(false);
  }, [actionmap]);
  useEffect(() => {
    ticket?.type_id &&
      ticket?.id == RouteParams["id"] &&
      dispatch(
        fetchActionMap({
          mode: "ticket",
          type_id: ticket.type_id,
          sub_type_id: ticket.sub_type_id,
          category_id: ticket.category_id,
        })
      );


  }, [ticket]);

  useEffect(() => {
    if (ticket?.services) {
      ticket?.services[0]?.doctor_id && dispatch(fetchDoctorById(ticket?.services[0]?.doctor_id))
      ticket?.services[0]?.appointment_id && dispatch(fetchAppointmentById(ticket?.services[0]?.appointment_id))
    }
    ticket?.contact?.patient_id && dispatch(fetchPatientsDetailsById(ticket?.contact?.patient_id))

  }, [ticket])



  // const topBarExtra = [
  //     <Button key="1" onClick={()=>setTicketFormVisible(true)}>Create Ticket</Button>,
  //     <Button key="2" onClick={()=>setLeadFormVisible(true)}>Create Lead</Button>
  // ]
  // const permissions = JSON.parse(localStorage.getItem("persist:root") as any)
  // const allPermissions = permissions && JSON.parse(permissions?.auth)
  // const myPermissions = allPermissions?.myPermissions?.scope_values?.actions
  // let topButtons = [];
  // if(myPermissions.indexOf(TASK_MODULE_ACTIONS_CREATE_TICKET) !== -1){
  //     topButtons.push(<Button key="1" onClick={() => setTicketFormVisible(true)}>Create Ticket</Button>)
  // }
  // if(myPermissions.indexOf(TASK_MODULE_ACTIONS_CREATE_LEAD) !== -1){
  //     topButtons.push(<Button key="2" onClick={() => setLeadFormVisible(true)}>Create Lead</Button>)
  // }
  const topBarExtra = (
    <HeaderButtons
      setLeadFormVisible={setLeadFormVisible}
      setTicketFormVisible={setTicketFormVisible}
    />
  );
  return (
    <Fragment>
      {!actionmapData && <Skeleton></Skeleton>}
      {actionmapData && component ? component : <></>}
      {/* { actionmapData?.action_name == 'generic_ticket' && <GeneralTicket ticket={ticket} actionmapData={actionmapData}></GeneralTicket> } */}
      {/* { actionmapData?.action_name == 'rx_generation' && <RXGenerationTicket ticket={ticket} actionmapData={actionmapData}></RXGenerationTicket> } */}
      {/* { ( actionmapData?.action_name == 'appointment_issues' || actionmapData?.action_name == 'missed_call_doctor' || actionmapData?.action_name == 'missed_call_patient' || actionmapData?.action_name == 'vc_reminder_call' ) && <AppointmentTicket ticket={ticket} actionmapData={actionmapData}></AppointmentTicket> } */}
      {/* { ( actionmapData?.action_name == 'document_qc' || actionmapData?.action_name == 'rx_generation' || actionmapData?.action_name == 'case_summary' || actionmapData?.action_name == 'medicine_order_qc') && <PharmacyTickets ticket={ticket} actionmapData={actionmapData} loading={loading}></PharmacyTickets>} */}
      {leadFormVisible && (
        <CreateLeadForm
          saveLeadSuccess={() => setLeadFormVisible(false)}
          setLeadFormVisible={(val: any) => setLeadFormVisible(val)}
          leadFormVisible={leadFormVisible}
          phone={ticket?.contact?.phone}
          defaultContactId={ticket?.contact?.id}
        ></CreateLeadForm>
      )}
      {ticketFormVisible && (
        <AddTicket
          onTicketSave={() => setTicketFormVisible(false)}
          setTicketFormVisible={(val: any) => setTicketFormVisible(val)}
          ticketFormVisible={ticketFormVisible}
          phoneNumber={ticket?.contact?.phone}
          defaultContactId={ticket?.contact?.id}
        ></AddTicket>
      )}
    </Fragment>
  );
};

export default CRMTicketDetailsPage;
