import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addNewNotificationContact,
  enableDisableNotification,
  getNotificationSettingsByContact,
} from "../../../../redux/reducers/contacts.slice";

type Props = {
  contactDetails: any;
  modalVisible: boolean;
  closeModal: () => void;
};

function NotificationSettings({
  contactDetails,
  modalVisible,
  closeModal,
}: Props) {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([] as any);
  const [formValues, setFormValues] = useState(null as any);
  const [addNewNotification, setAddNewNotification] = useState(false as any);

  useEffect(() => {
    return () => {
      setDataSource([]);
      setFormValues(null);
      setAddNewNotification(false);
    };
  }, []);
  useEffect(() => {
    getListData();
  }, [contactDetails]);
  function getListData() {
    dispatch(getNotificationSettingsByContact(contactDetails?.id)).then(
      (val: any) => {
        if (val?.payload?.data?.data) {
          setDataSource(val?.payload?.data?.data);
        }
      }
    );
  }
  const columns = [
    {
      title: "User Type",
      key: "user_type",
      dataIndex: "user_type",
    },
    {
      title: "Channel",
      key: "channel",
      dataIndex: "channel",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Mobile",
      key: "mobile",
      dataIndex: "mobile",
    },
    {
      title: "Is Active",
      key: "1123",
      render: (a: any) => {
        return JSON.stringify(a.is_active);
      },
    },
    {
      title: "Is Active",
      key: "is_active",
      render: (a: any) => {
        return a.is_active == true ? (
          <Switch
            defaultChecked
            onChange={(checked: boolean) => onChangeActive(checked, a)}
          />
        ) : (
          <Switch onChange={(checked: boolean) => onChangeActive(checked, a)} />
        );
      },
    },
  ];
  function onChangeActive(checked: boolean, record: any) {
    console.log(checked, record);
    dispatch(
      enableDisableNotification({ id: record.id, body: { is_active: checked } })
    ).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        message.success("Notification state updated successfully");
        setDataSource([]);
        getListData();
      }
    });
  }
  function onValuesChange(value: any, allValues: any) {
    setFormValues(allValues);
  }
  function onFinish(allValues: any) {
    // setFormValues(allValues)
    const payload = {
      ...allValues,
      contact_id: contactDetails?.id,
      event: "update",
      email: allValues.email || "",
      mobile: allValues.mobile || "",
    };
    dispatch(addNewNotificationContact(payload)).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        message.success("Notification added successfully");
        setAddNewNotification(false);
        getListData();
      }
    });
  }

  return (
    <Modal
      title={
        !addNewNotification ? (
          <>
            Notification Settings{" "}
            <Button type="link" onClick={() => setAddNewNotification(true)}>
              <strong>Add New</strong>
            </Button>
          </>
        ) : (
          <>
            Add Notification{" "}
            <Button type="link" onClick={() => setAddNewNotification(false)}>
              <strong>Cancel</strong>
            </Button>
          </>
        )
      }
      centered
      visible={modalVisible}
      onCancel={() => closeModal()}
      width={1000}
      footer={false}
      className="modalFormButton form-modal"
      style={{ paddingBottom: "0px" }}
    >
      {!addNewNotification && (
        <Table dataSource={dataSource} columns={columns} />
      )}
      {addNewNotification && (
        <Row justify="center">
          <Col span={23} className="pd20">
            <Form
              name="form"
              onFinish={onFinish}
              onValuesChange={onValuesChange}
              className="crm-form"
            >
              <Row gutter={16}>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="user_type"
                    label={"User Type"}
                    rules={[
                      { required: true, message: "User Type is required" },
                    ]}
                  >
                    <Select>
                      <Select.Option value="am">Area Manager</Select.Option>
                      <Select.Option value="mr">
                        Medical Representative
                      </Select.Option>
                      <Select.Option value="rsm">
                        Regional Sales Manager
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="channel"
                    label={"Channel"}
                    rules={[{ required: true, message: "Channel is required" }]}
                  >
                    <Select>
                      <Select.Option value="email">Email</Select.Option>
                      <Select.Option value="sms">SMS</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  {formValues?.channel == "email" && (
                    <Form.Item
                      name="email"
                      label={"Email"}
                      rules={[{ required: true, message: "Email is required" }]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  )}
                  {formValues?.channel == "sms" && (
                    <Form.Item
                      name="mobile"
                      label={"Mobile"}
                      rules={[
                        { required: true, message: "Mobile is required" },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Space>
                <Button onClick={() => setAddNewNotification(false)}>
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Space>
            </Form>
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default NotificationSettings;
