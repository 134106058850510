import { Card, Col, PageHeader, Row, Skeleton } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  clearCalllogDetails,
  fetchCalllogDetails,
  patchCalllogDetails,
} from "../../../redux/reducers/calllogs.slice";
import { TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA } from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import AccentCard from "../../../shared/layouts/crm-accentcard.layout";
import ResultModalLayout from "../../../shared/layouts/resultmodal.layout";
import TabsLayout from "../../../shared/layouts/tabs.layout";
import { ROUTE_CRM_CALL_LOGS } from "../../../shared/routes/crm.routes.constants";
import CreateLeadForm from "../Leads/components/createleadform";
import AddTicket from "../Tickets/components/add-ticket";
import ActivityHistoryTab from "../components/activityhistorytab";
import HeaderButtons from "../components/headerbuttons";
import TabTitle from "../components/tabtitle";
import TransactionsTab from "../components/transactionstab";
import CallLogsDetailsTab from "./components/calllogdetailstab";

const getAccentcardData = (calllog: any) => {
  if (calllog?.contact_id) {
    return {
      type: "contact",
      details: calllog,
    };
  } else {
    return {
      type: "number",
      details: calllog,
    };
  }
};

function CRMCallLogDetailsPage() {
  let params: any = useParams();
  let callLogId = params?.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, calllog } = useSelector(
    (state: RootState) => state.calllogs
  );
  const { gender, nationality } = useSelector(
    (state: RootState) => state.master
  );
  const [statusUpdated, setStatusUpdated] = useState(false as any);
  // const [ calllogdata, setCalllogdata ] = useState([] as any)
  useEffect(() => {
    // gender?.length == 0 && dispatch(fetchMasterGender({}))
    // nationality?.length == 0 && dispatch(fetchMasterNationality({}))
    return () => {
      dispatch(clearCalllogDetails());
    };
  }, []);
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  useEffect(() => {
    callLogId && dispatch(fetchCalllogDetails(callLogId));
  }, [callLogId]);
  useEffect(() => {
    setcontactDetails(getAccentcardData(calllog));
    // if(Object.keys(calllog).length > 0 && !loading){
    //     if(calllog.id == callLogId){
    //         if(calllog.contact_id){
    //             setButtons([
    //                 <Button key="1" onClick={()=>setTicketFormVisible(true)}>Create Ticket</Button>,
    //                 <Button key="2" onClick={()=>setLeadFormVisible(true)}>Create Lead</Button>
    //             ])
    //         }else{
    //             setButtons([
    //                 <Tooltip title="Link a contact with Phone Number to create a ticket" color={'#E1454E'} key={'#E1454E'}>
    //                     <Button>Create Ticket</Button>
    //                 </Tooltip>,
    //                 <Tooltip title="Link a contact with Phone Number to create a lead" color={'#E1454E'} key={'#E1454E'}>
    //                     <Button>Create Lead</Button>
    //                 </Tooltip>
    //             ])
    //         }
    //     }
    // }
  }, [calllog]);

  const [contactDetails, setcontactDetails] = useState({
    type: "",
    details: "",
  });
  const [leadFormVisible, setLeadFormVisible] = useState(false);
  const [ticketFormVisible, setTicketFormVisible] = useState(false);

  var allTabs = [];
  allTabs = [
    {
      title: <TabTitle title="Call Log Details" />,
      component: (
        <CallLogsDetailsTab
          calllog={calllog}
          emitParent={(val) => emitParent(val)}
        />
      ),
    },
    ...((myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA) !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_GET_TRANSACTION_DATA?.split(".")[0]) !== -1)
      ? [{
        title: <TabTitle title="Transactions" />,
        component: <TransactionsTab phone={calllog.phone} />,
      }] : []
    ),
    // {title : <TabTitle title="Promotional Communication" />, component:  <PromotionalCommunicationTab /> },
    {
      title: <TabTitle title="History" />,
      component: <ActivityHistoryTab phone={calllog.phone} cols={4} />,
    },
  ];
  const emitParent = async (data: any) => {
    if (data?.action == "reload_calllog") {
      await dispatch(clearCalllogDetails());
      await dispatch(fetchCalllogDetails(callLogId));
    }
    if (data?.action == "patch_calllog") {
      dispatch(patchCalllogDetails({ id: callLogId, data: data.details })).then(
        async (val: any) => {
          if (val?.payload?.data?.statusCode == 200) {
            await dispatch(clearCalllogDetails());
            await dispatch(fetchCalllogDetails(callLogId));
          }
        }
      );
    }
    if (data?.action == "patch_calllog_status") {
      dispatch(patchCalllogDetails({ id: callLogId, data: data.details })).then(
        async (val: any) => {
          if (val?.payload?.data?.statusCode == 200) {
            setStatusUpdated(true);
          }
        }
      );
    }
  };
  const onCloseStatusModal = async () => {
    setStatusUpdated(false);
    await dispatch(clearCalllogDetails());
    await dispatch(fetchCalllogDetails(callLogId));
  };
  // console.log(buttons)
  return (
    <Fragment>
      {calllog?.call_id ? (
        <PageHeader
          ghost={false}
          onBack={() => history.push(ROUTE_CRM_CALL_LOGS)}
          title={<>{!loading && `Call Log #${calllog?.call_id}`}</>}
          extra={
            calllog.contact_id ? (
              <HeaderButtons
                setLeadFormVisible={setLeadFormVisible}
                setTicketFormVisible={setTicketFormVisible}
              />
            ) : (
              <HeaderButtons callLogDisabled={true} />
            )
          }
        ></PageHeader>
      ) : (
        <Skeleton />
      )}
      <div className="pd10">
        {!loading && (
          <Row gutter={16}>
            <Col className="gutter-row" span={7}>
              <AccentCard
                details={contactDetails.details}
                type={contactDetails.type}
                callData={{ source: "call_log", call_log_id: callLogId }}
              ></AccentCard>
            </Col>
            <Col className="gutter-row" span={17}>
              <Card className="nopaddingcard crm-tabs">
                <TabsLayout AllTabs={allTabs}></TabsLayout>
              </Card>
            </Col>
          </Row>
        )}
        {loading && <Skeleton></Skeleton>}
        {leadFormVisible && (
          <CreateLeadForm
            saveLeadSuccess={() => setLeadFormVisible(false)}
            setLeadFormVisible={(val: any) => setLeadFormVisible(val)}
            leadFormVisible={leadFormVisible}
            phone={calllog.phone}
            defaultContactId={calllog.contact_id ? calllog.contact_id : ""}
            callLogId={callLogId}
          ></CreateLeadForm>
        )}
        {ticketFormVisible && (
          <AddTicket
            onTicketSave={() => setTicketFormVisible(false)}
            setTicketFormVisible={(val: any) => setTicketFormVisible(val)}
            ticketFormVisible={ticketFormVisible}
            phoneNumber={calllog.phone}
            defaultContactId={calllog.contact_id ? calllog.contact_id : ""}
            callLogId={callLogId}
          ></AddTicket>
        )}
      </div>
      {statusUpdated && (
        <ResultModalLayout
          type="success"
          modalVisible={statusUpdated}
          message=""
          setModalVisible={onCloseStatusModal}
          title="Success"
          subtitle="Calllog Status updated successfully"
        />
      )}
    </Fragment>
  );
}

export default CRMCallLogDetailsPage;
