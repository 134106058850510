import React, { Fragment, useState, useEffect } from 'react';
import { addNewLabel, fetchLabelList, addValues, patchNewLabel, patchNewValues, fetchTaskType,patchTaskType,fetchTaskSubType,addTaskSubType,patchTaskSubType,fetchTaskCategory,addTaskCategory,patchTaskCategory } from '../../../redux/reducers/metadata.slice';
import { RootState } from '../../../shared/constants';
import {getDropdownMasterData} from '../../../redux/reducers/master.slice'
import { Row, Col, Button, PageHeader, message, Space, Modal, Input, Table, Typography, InputNumber, Popconfirm, Form, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as SearchOutlineIcon } from '../../../../src/assets/icons/searchOutlineIcon.svg' 
import PaginationLayout from '../../../shared/layouts/pagination.layout';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined, PlusOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import TableLayout from '../../../shared/layouts/table.layout';
const { Text } = Typography;
const { Option } = Select;



interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text' | 'select';
  record: any;
  index: number;
  children: React.ReactNode;
  dropdownData?: any
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children,dropdownData, ...restProps }) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : ( inputType === 'select' ? 
  <Select  style={{ width: 200, textAlign: 'left' }} placeholder='Select Name' optionFilterProp='children' >
             {
               dropdownData?.map((item:any)=>{
                 return <Option value={item.name}>{item.name}</Option>
              })
            }
        </Select>
   : <Input /> );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={title !== 'UI Action' ? [
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ] : []}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function Metadata() {
  let pageNumber = 0;

  let { loading, metadata, pagination } = useSelector((state: RootState) => state.metadata);
  // let [loading, metadata, pagination]   = useSelector((state: RootState) => state.metadata);
  // console.log("metadata",typeof(metadata))
  const { loadings, taskType, paginations } = useSelector((state: RootState) => state.metadata);
  const { loadingsss, taskSubType, paginationsss } = useSelector((state: RootState) => state.metadata);
  const { loadingssss, taskCategory, paginationssss } = useSelector((state: RootState) => state.metadata);
  const { loadingsssss, dropdownMasterData, paginationsssss } = useSelector((state: RootState) => state.master);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [task, settask] = useState('addLabel');
  const [newValues, setnewValues] = useState(false);
  const [editLabelId, seteditLabelId] = useState();
  const [saveBtnAddLabel, setsaveBtnAddLabel] = useState(false);
  const [saveBtnAddValue, setsaveBtnAddValue] = useState(false);
  const [saveBtnSubType, setsaveBtnSubType] = useState(false);
  const [saveBtnCat, setsaveBtnCat] = useState(false);
  const [addLabel, setaddLabel] = useState(false);
  const [addSubType, setaddSubType] = useState(false);
  const [addCatType, setaddCatType] = useState(false);
  const [addValuess, setaddValuess] = useState(false);
  const [addValuesButton, setaddValuesButton] = useState(false);
  const [labelTypeAddValues, setlabelTypeAddValues] = useState('');
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [newRecord, setnewRecord] = useState(false);
  const [task_type_id, settask_type_id] = useState();
  const [task_sub_type_id, settask_sub_type_id] = useState();
  const [addLabelDisabled, setaddLabelDisabled] = useState(true);
  const [mode, setMode] = useState('Select Mode');
  const [subType, setSubType] = useState();
  const [catState, setcatState] = useState(1);
  const [metaAddValues, setmetaAddValues] = useState({
    dropdown_name_id: '',
  });

 console.log("dropdownMasterData",dropdownMasterData)

  

  //sources
  let valLabel: any = [];
  const [dataSourceLabel, setdataSourceLabel] = useState(valLabel);
  let valInitial: any = [];
  const [addValuesDataSource, setaddValuesDataSource] = useState(valInitial);
  let subInitial: any = [];
  const [subTypeDataSource, setsubTypeDataSource] = useState(subInitial);
  let catInitial: any = [];
  const [catTypeDataSource, setcatTypeDataSource] = useState(catInitial);


  const dispatch = useDispatch();
  const isEditing = (record: any) => record.id === editingKey;


  

  const handleAddLabel = () => {
    setsaveBtnAddLabel(true);
    setaddLabel(true);
    setnewRecord(true);
    setEditingKey('5');
    const newData = { name: '', mode: mode,  key: '5', id: '5' };
    // form.setFieldsValue({ name: 'rajesh', mode: '',  key: '5', id: '5' });
    setdataSourceLabel([newData, ...dataSourceLabel])
  };

  const handleAddValues = (record: any) => {
    setEditingKey('6');
    setsaveBtnAddValue(true);
    setnewValues(true);
    const newData = { name: '', mode: mode,  labelType: labelTypeAddValues,order:'',ui_action:'', key: '6', id: '6' };
    // form.setFieldsValue({ name: '', mode: '',order:'',ui_action:'',  key: '6', id: '6' });
    setaddValuesDataSource([newData, ...addValuesDataSource])
    setdataSourceLabel([newData, ...dataSourceLabel])

  };

  const handleSubType = () => {
    setaddSubType(true)
    setsaveBtnSubType(true)
    setEditingKey('8');
    const newData = { name: '', mode: mode,  key: '8',labelType: labelTypeAddValues, id: '8' };
    form.setFieldsValue({ name: '', mode: '',  key: '8',labelType: labelTypeAddValues, id: '8' });
    setsubTypeDataSource([newData, ...subTypeDataSource])
  };

  const handleCatType = () => {
    setaddCatType(true)
    setsaveBtnCat(true)
    setEditingKey('9');
    const newData = { name: '', mode: mode,  key: '9',labelType: labelTypeAddValues,subType:subType, id: '9' };
    form.setFieldsValue({ name: '', mode: '',  key: '9', id: '9' });
    setcatTypeDataSource([newData, ...catTypeDataSource])
  };


  const settingCatdata = () =>{
    let updatedsubCatArray = taskCategory?.map((v: any) => ({
      ...v,
      labelType: labelTypeAddValues,
      subType: subType,
      category:v.name
    }));
    setcatTypeDataSource(updatedsubCatArray)
  }

  const handleCatFromSubType = (record: any & { key: React.Key }) => {
    console.log("recordsssssss",record)
    dispatch(fetchTaskCategory({id:record.id}));
    console.log("taskCategory",taskCategory)
    settask_sub_type_id(record.id)
    settask('category')
    setSubType(record.name)
    // setcatState(catState+1)
    // settingCatdata()


    // let catArr = taskCategory?.filter((item:any)=>{
    // })
    // let updatedsubTypeArray = catArr?.map((v: any) => ({
    //   ...v,
    //   labelType: labelTypeAddValues,
    //   subType:record.name,
    //   category: 'new'
      
    // }));


    // setcatTypeDataSource(updatedsubTypeArray)
    
  };

 

  
  
  

  

  


  let newArr: any;

  console.log("labelType",labelTypeAddValues)
 

  const handleAddValuesLabelList = (record: any) => {

    if (record?.subType) {

      settask('subType')
      settask_type_id(record.id)
    }
    else {
      settask('addValues')
    }
    // setaddValuesss(true);
    
    setMode(record.mode);
    setmetaAddValues({ ...metaAddValues, dropdown_name_id: record.id });
    setlabelTypeAddValues(record.name);
    form.setFieldsValue({ name: '', mode: '',  key: '6' });
    // setEditingKey("6");
    addValuesButton ? setaddValuesButton(false) : setaddValuesButton(true);

    newArr = metadata.filter((data: any, index: any) => {
      return data.id === record.id;
    });

    let resArr = newArr[0]?.values?.map((v: any) => ({
      ...v,
      labelType: record.name,
    }));
    const subTypeArray = taskSubType?.filter((data: any, index: any) => {
      return data.type.name === record.name;
    });
    

    let updatedsubTypeArray = subTypeArray?.map((v: any) => ({
      ...v,
      labelType: record.name,
    }));


    setaddValuesDataSource(resArr);
    setsubTypeDataSource(updatedsubTypeArray)
  };

  
  const handleChangeMode = (value: any) => {
    
    dispatch(getDropdownMasterData({id:value}))
    const newArr = [...taskType,...metadata]

    const filteredData = newArr.filter((item)=>{
     return item.mode === value
    })

    if (value === 'all') {
      setdataSourceLabel([...taskType,...metadata])
      setaddLabelDisabled(true);
    }
    else{
      setdataSourceLabel(filteredData)
      setaddLabelDisabled(false);
    }
    // setaddLabelDisabled(false);
    setMode(value);
  };

  const handleChangeLabelType = (value: any) => {
    setlabelTypeAddValues(value);
  };

  const editAddValues = (record: any & { key: React.Key }) => {
    form.setFieldsValue({ name: '', mode: '',  ...record });
    setEditingKey(record.id);
  };

  const editcategory = (record: any & { key: React.Key }) => {
    form.setFieldsValue({ name: '', mode: '',  ...record });
    setEditingKey(record.id);
  };

  

  const editTaskSubType = (record: any & { key: React.Key }) => {
    form.setFieldsValue({ name: '', mode: '',  ...record });
    setEditingKey(record.id);
  };

  

  const editLabel = (record: any & { key: React.Key }) => {
    console.log("rccccccccccc",record)
    dispatch(getDropdownMasterData({id:record.mode}))
    form.setFieldsValue({ name: '', mode: '',label:'',  ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
    setaddLabel(false);
    setnewRecord(false);
    settask('addLabel')
    setnewRecord(false);
    setsaveBtnAddValue(false)
    setsaveBtnSubType(false)
    setsaveBtnCat(false)
    setaddSubType(false)
  };

  const cancelAddSubType = () => {
    setEditingKey('');
    setaddLabel(false);
    setnewRecord(false);

  };

  const handleDelete = (record: any & { key: React.Key }) => {
    // setEditingKey("");
    // setaddLabel(false);
    // setnewRecord(false);
  };

  const saveAddLabel = async (key: React.Key) => {
    const row = (await form.validateFields()) as any;
    row.mode = mode;
    console.log("dddddddddd",row)
    onSaveLabel(row);
  };

  const saveAddSubType = async (key: React.Key) => {
    
    const row = (await form.validateFields()) as any;
    row.mode = mode;
    row.task_type_id = task_type_id;
    onSaveSubType(row);
    
  };

  const saveAddCategory = async (key: React.Key) => {
    const row = (await form.validateFields()) as any;
    row.name = row.category
    row.task_sub_type_id = task_sub_type_id;
    delete row['category']
    
    onSaveCategory(row);
  };

  const saveEditCategory = async (record: any & { key: React.Key }) => {
    const row = (await form.validateFields()) as any;
    row.task_sub_type_id = task_sub_type_id;
    row.name = row.category
    row.id=record.id
    delete row['category']
    onSaveEditCategory(row);
  };


  const saveEditLabel = async (record: any & { key: React.Key }) => {
    const row = (await form.validateFields()) as any;
    row.key = record.id;

    
    
    if (record.subType) {
      row.id=record.id
      row.mode = record.mode
      row.label = record.label
      onSaveEditLabelTaskType(row);
      // alert('1')
    }
    else{
      row.mode = record.mode
      console.log(row)
      onSaveEditLabel(row);
    }
  };

  const saveAddValues = async (key: React.Key) => {
    const row = (await form.validateFields()) as any;
    row.dropdown_name_id = metaAddValues.dropdown_name_id;
    delete row['value'];
    onSaveValues(row);
  };

  const saveEditValues = async (record: any & { key: React.Key }) => {
    const row = (await form.validateFields()) as any;
    row.key = record.id;
    onSaveEditValues(row);
  };

  const saveEditSubType = async (record: any & { key: React.Key }) => {
    
    const row = (await form.validateFields()) as any;
    row.id = record.id;
    row.task_type_id = task_type_id
    onSaveEditSubType(row);
  };

  



 

  const columns = [
    {
      title: 'Mode',
      dataIndex: 'mode',
      width: '40%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '40%',
      editable: true,
      render: (a:any, record: any) =>{
        const editable = isEditing(record);

        return editable &&  newRecord ? (
          <> abcd
          <Select   style={{ width: 200, textAlign: 'left' }} placeholder='Select Name' optionFilterProp='children' >
            {
              dropdownMasterData?.map((item:any)=>{
                return <Option value={item.name}>{item.name}</Option>
              })
            }
        </Select> 
        </>
        )
        : a
      }
    },
    // {
    //   title: 'Label',
    //   dataIndex: 'label',
    //   width: '20%',
    //   editable: true,
    // },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_: any, record: any) => {
        const editable = isEditing(record);

        return editable || newRecord ? (
          saveBtnAddLabel === true ? (
            <>
              <Button type='primary' disabled={record.id.length !== 1} onClick={() => saveAddLabel(record.key)} style={{ marginRight: 8 }}>
                Save
              </Button>
              <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </>
          ) : (
            <>
              <Button type='primary'  onClick={() => saveEditLabel(record)} style={{ marginRight: 8 }}>
                Save 
              </Button>
              <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </>
          )
        ) : (
          <>
            <Button type='default' className='text-primary' disabled={editingKey !== ''} onClick={() => editLabel(record)}>
              Edit
            </Button>
            <Button className='mrg10 text-success'    disabled={editingKey !== ''} onClick={() => handleAddValuesLabelList(record)}>
              Add Values
            </Button>
          </>
        );
      },
    },
  ];




  const valuesColumns = [
    {
      title: 'Label Type',
      dataIndex: 'labelType',
      width: '20%',
    },
    {
      title: 'Value',
      dataIndex: 'name',
      width: '20%',
      editable: true,
    },
    {
      title: 'Order',
      dataIndex: 'order',
      width: '20%',
      editable: true,
    },
    {
      title: 'UI Action',
      dataIndex: 'ui_action',
      width: '20%',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable || newValues === true ? (
          saveBtnAddValue === true ? (
            <>
              <Button type='primary' disabled={record.id.length !== 1} onClick={() => saveAddValues(record.key)} style={{ marginRight: 8 }}>
                Save 
              </Button>
              <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </>
          ) : (
            <>
              <Button type='primary' onClick={() => saveEditValues(record)} style={{ marginRight: 8 }}>
                Save 
              </Button>
              <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </>
          )
        ) : (
          <>
            <Button type='default' disabled={editingKey !== ''} onClick={() => editAddValues(record)}>
              Edit
            </Button>
            <Button className='mrg10' type='default' disabled={editingKey !== ''} onClick={() => handleDelete(record)}>
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  const subTypeColumns = [
    {
      title: 'Label Type',
      dataIndex: 'labelType',
      width: '50%',
    },
    {
      title: 'Sub Type',
      dataIndex: 'name',
      width: '25%',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable || addSubType === true ? (
          saveBtnSubType === true ? (
            <>
              <Button type='primary' disabled={record.id.length !== 1} onClick={() => saveAddSubType(record.key)} style={{ marginRight: 8 }}>
                Save
              </Button>
              <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </>
          ) : (
            <>
              <Button type='primary' onClick={() => saveEditSubType(record)} style={{ marginRight: 8 }}>
                Save 
              </Button>
              <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </>
          )
        ) : (
          <>
            <Button type='default' disabled={editingKey !== ''} onClick={() => editTaskSubType(record)}>
              Edit
            </Button>
            <Button className='mrg10' type='default' disabled={editingKey !== ''} onClick={() => handleDelete(record)}>
              Delete
            </Button>
            <Button className='mrg10' type='default' disabled={editingKey !== ''} onClick={() => handleCatFromSubType(record)}>
              Add Category
            </Button>
          </>
        );
      },
    },
  ];

  const catTypeColumns = [
    {
      title: 'Label Type',
      dataIndex: 'labelType',
      width: '25%',
    },
    {
      title: 'Sub Type',
      dataIndex: 'subType',
      width: '25%',
      
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: '25%',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable || addCatType === true ? (
          saveBtnCat === true ? (
            <>
              <Button type='primary' disabled={record.id.length !== 1} onClick={() => saveAddCategory(record.key)} style={{ marginRight: 8 }}>
                Save 
              </Button>
              <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </>
          ) : (
            <>
              <Button type='primary' onClick={() => saveEditCategory(record)} style={{ marginRight: 8 }}>
                Save 
              </Button>
              <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </>
          )
        ) : (
          <>
            <Button type='default' disabled={editingKey !== ''} onClick={() => editcategory(record)}>
              Edit
            </Button>
            <Button className='mrg10' type='default' disabled={editingKey !== ''} onClick={() => handleDelete(record)}>
              Delete
            </Button>
          </>
        );
      },
    },
  ];





  const onSaveLabel = (values: any) => {
    values = { ...values };
    console.log("dddddddddddddddddddddddddd",values)
    dispatch(addNewLabel(values)).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        // setmodalVisible(false);
        message.success({
          content: 'Label added successfully',
          style: {
            marginBottom: '10vh',
          },
        }); 
      }
    });
    setEditingKey('');
    setaddLabel(false);
    setnewRecord(false);
    settask('addLabel')
    setMode('All')
    dispatch(fetchLabelList());
    dispatch(fetchTaskType());
  };

  const onSaveSubType = (values: any) => {
    values = { ...values };
    dispatch(addTaskSubType(values)).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        // setmodalVisible(false);
        message.success({
          content: 'SubType added successfully',
          style: {
            marginBottom: '10vh',
          },
        });
      }
    });
    setsaveBtnSubType(false)
    setEditingKey('');
    dispatch(fetchLabelList());
    dispatch(fetchTaskType());
    dispatch(fetchTaskSubType({}));
    settask('addLabel')
    setaddSubType(false)
  };

  const onSaveCategory = (values: any) => {
    values = { ...values };
    console.log("333333333",values)
    dispatch(addTaskCategory(values)).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        // setmodalVisible(false);
        message.success({
          content: 'Category added successfully',
          style: {
            marginBottom: '10vh',
          },
        });
        setEditingKey('');
      }
    });
    setEditingKey('');
    settask('addLabel')
    setsaveBtnCat(false)
    setaddCatType(false)
    dispatch(fetchLabelList());
    dispatch(fetchTaskType());
    dispatch(fetchTaskCategory({id:task_sub_type_id}));
  };

  const onSaveEditLabel = (values: any) => {
    values = { ...values };
    let ids = values.key;
    delete values['key'];
    dispatch(patchNewLabel({ id: ids, data: values })).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        message.success({
          content: 'Label updated successfully',
          style: {
            marginBottom: '10vh',
          },
        });
        
        // setaddLabel(false);
        // setnewRecord(false);
      }
    });
    setEditingKey('');
    settask('addLabel')
    dispatch(fetchLabelList());
    dispatch(fetchTaskType());
  };

  const onSaveEditLabelTaskType = (values: any) => {
    values = { ...values };
    delete values['key']
    
    dispatch(patchTaskType(values )).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        message.success({
          content: 'Label updated successfully',
          style: {
            marginBottom: '10vh',
          },
        });
        setEditingKey('');
        settask('addLabel')
        // setaddLabel(false);
        // setnewRecord(false);
      }
    });
    // dispatch(fetchLabelList());
    
    setTimeout(() => {
      dispatch(fetchTaskType());
    }, 2000);
  };



  const onSaveValues = (values: any) => {
    values = { ...values };
    dispatch(addValues(values)).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        message.success({
          content: 'Values added successfully',
          style: {
            marginBottom: '10vh',
          },
        });
        setEditingKey('');
        setsaveBtnAddValue(false);
        setnewValues(false);
      }
    });
    settask('addLabel')
    dispatch(fetchLabelList());
    dispatch(fetchTaskType());
  };

  const onSaveEditValues = (values: any) => {
    values = { ...values };
    let ids = values.key;
    delete values['key'];
    dispatch(patchNewValues({ id: ids, data: values })).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        message.success({
          content: 'Values updated successfully',
          style: {
            marginBottom: '10vh',
          },
        });
        setEditingKey('');
        // setaddLabel(false);
        // setnewRecord(false);
      }
    });
    dispatch(fetchLabelList());
    dispatch(fetchTaskType());
    settask('addLabel')
  };

  const onSaveEditSubType = (values: any) => {
    values = { ...values };
    dispatch(patchTaskSubType(values )).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        message.success({
          content: 'Sub Type updated successfully',
          style: {
            marginBottom: '10vh',
          },
        });
        setEditingKey('');
        // setaddLabel(false);
        // setnewRecord(false);
      }
    });
    dispatch(fetchLabelList());
    dispatch(fetchTaskType());
  };

  const onSaveEditCategory = (values: any) => {
    values = { ...values };
    dispatch(patchTaskCategory(values )).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        message.success({
          content: 'Category updated successfully',
          style: {
            marginBottom: '10vh',
          },
        });
        setEditingKey('');
        // setaddLabel(false);
        // setnewRecord(false);
      }
    });
    dispatch(fetchLabelList({}));
    dispatch(fetchTaskType());
  };
  


  
  

  

  const mergedColumns = columns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'select',
        dataIndex: col.dataIndex,
        title: col.title ,
        editing: isEditing(record),
        dropdownData:dropdownMasterData
        
        // render: (a:any) =>{
        //   return (
        //     <Select  style={{ width: 200, textAlign: 'left' }} placeholder='Select Name' optionFilterProp='children' >
        //       {
        //         dropdownMasterData?.map((item:any)=>{
        //           return <Option value={item.name}>{item.name}</Option>
        //         })
        //       }
        //   </Select>
        //   )
        // }
        
      }),
    };
  });
  
  

  const addValuesColumnNew = valuesColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addSubTypeColumn = subTypeColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addCatTypeColumn = catTypeColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSearch = (e:any) =>{
    let searchval = e.target.value.toLowerCase();
    let allData = [...taskType,...metadata]

    let filteredData = allData.filter((item) =>{
      return item.name.toLowerCase().indexOf(searchval) !== -1 || 
      item.mode.toLowerCase().indexOf(searchval) !== -1
    })

    setdataSourceLabel(filteredData)
  }

  const handleClickBack = (name:any) =>{
    settask(name)
    setEditingKey('')
    dispatch(fetchLabelList());
    dispatch(fetchTaskType());
    dispatch(fetchTaskSubType({}));
    setaddCatType(false)
    setaddSubType(false)
    setnewValues(false)
    setnewRecord(false)
  }

  useEffect(() => {
    dispatch(fetchLabelList());
    dispatch(fetchTaskType());
    dispatch(fetchTaskSubType());
    
  }, []);

  


  
  useEffect(() => {
    let resArr = taskType?.map((v: any) => ({
      ...v,
      subType: true,
    }));
    setdataSourceLabel([...resArr, ...metadata])
  }, [ ])

  useEffect(() => {
    let resArr = taskType?.map((v: any) => ({
      ...v,
      subType: true,
    }));
    setdataSourceLabel([...resArr, ...metadata])
  }, [ taskType])

  // useEffect(() => {
  //   let resArr = taskType?.map((v: any) => ({
  //     ...v,
  //     subType: true,
  //   }));
  //   setdataSourceLabel([...resArr, ...metadata])
  // }, [ metadata])

  useEffect(() => {

    console.log("taskCategorydddddd",taskCategory)
    let updatedsubCatArray = Array.isArray(taskCategory) && taskCategory?.map((v: any) => ({
      ...v,
      labelType: labelTypeAddValues,
      subType: subType,
      category:v.name
    }));
    setcatTypeDataSource(updatedsubCatArray)
  }, [taskCategory])

  useEffect(() => {

    console.log("taskCategorydddddd",taskCategory)
    let updatedsubCatArray = Array.isArray(taskCategory) && taskCategory?.map((v: any) => ({
      ...v,
      labelType: labelTypeAddValues,
      subType: subType,
      category:v.name
    }));
    setcatTypeDataSource(updatedsubCatArray)
  }, [])

  return (
    <Fragment>
      <PageHeader ghost={false} onBack={() => window.history.back()} title='Metadata'></PageHeader>

      <Row className='padding10' style={{ background: 'white', margin: '10px' }}>
        {/* <Col>
          <b>Label List</b>
        </Col> */}
        <Col flex='auto' > 
        {/* <Col flex='auto' className='text-right'></Col> */}
          <Space>
            <div>
              <Input style={{ width: '200px' }} onChange={(e)=>{handleSearch(e)}} placeholder='Search by Category, Name' prefix={<SearchOutlineIcon width={'13'} height={'13'} />} />
            </div>
            <Select onChange={handleChangeMode} style={{ width: 200, textAlign: 'left' }} placeholder='Select Mode' optionFilterProp='children' value={mode}>
            <Option value='all'>All</Option>
              <Option value='ticket'>Ticket</Option>
              <Option value='lead'>Leads</Option>
              <Option value='generic'>Generic</Option>
              <Option value='app_lead'>App Lead</Option>
            </Select>

            {addValuesButton ? (
              <Select
                onChange={handleChangeLabelType}
                style={{ width: 200, textAlign: 'left' }}
                placeholder='Select Status'
                optionFilterProp='children'
                value={labelTypeAddValues}>
                <Option value='Priority'>Priority</Option>
                <Option value='Status'>Status</Option>
              </Select>
            ) : (
              false
            )}

            {
              task === 'category' ?
              <Select
                // onChange={handleChangeLabelType}
                // style={{ width: 200, textAlign: 'left' }}
                // placeholder='Select Status'
                // optionFilterProp='children'
                disabled={true}
                value={subType}>
                <Option value='Priority'>Priority</Option>
                <Option value='Status'>Status</Option>
              </Select>
              :
              ''
            }

            {(() => {
              if (task === 'addLabel') {
                return (
                  <>
                    <Button type='primary' onClick={handleAddLabel} key='3' disabled={addLabelDisabled}>
                      <PlusOutlined /> ADD LABEL
                    </Button>
                  </>
                )
              }
              else if (task === 'addValues') {
                return(
                  <>
                  <Button type='primary' onClick={handleAddValues} key='3'>
                    <PlusOutlined /> ADD VALUES
                  </Button>
                  <Button type='primary' onClick={() =>{handleClickBack('addLabel')}}  key='3'>
                  <ArrowLeftOutlined /> BACK
                  </Button>
                </>
                )
                
              }
              else if (task === 'subType') {
                return(
                  <>
                  <Button type='primary' onClick={handleSubType} key='3'>
                    <PlusOutlined /> ADD SUBTYPE
                  </Button>
                  <Button type='primary' onClick={() =>{handleClickBack('addLabel')}}  key='3'>
                  <ArrowLeftOutlined /> BACK
                  </Button>
                </>
                )
              }
              else if (task === 'category') {
                return(
                  <>
                  <Button type='primary' onClick={handleCatType} key='3'>
                    <PlusOutlined /> ADD CATEGORY
                  </Button>
                  <Button type='primary' onClick={() =>{handleClickBack('subType')}}  key='3'>
                  <ArrowLeftOutlined /> BACK
                  </Button>
                </>
                )
              }
            })()}


          </Space>
        </Col>

        {/* <Col span={3} className="text-right">
          <PaginationLayout total="1" onChange={(e) => e}></PaginationLayout>
        </Col> */}
      </Row>

      {(() => {
        if (task === 'addLabel') {
          return (
            <>
              <Row className='padding10'>
                <Col span={24}>
                  <Form form={form} component={false}>
                    <Table
                      rowKey={(r) => r.key}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      loading={loading}
                      bordered
                      dataSource={dataSourceLabel}
                      columns={mergedColumns}
                      rowClassName='editable-row'
                      pagination={{ position: ['topRight'] }}
                      
                    />
                  </Form>
                </Col>
              </Row>
            </>
          )
        }
        else if (task === 'addValues') {
          return (
            <>
              <Row className='padding10'>
                <Col span={24}>
                  <Form form={form} component={false}>
                    <Table
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      bordered
                      dataSource={addValuesDataSource}
                      columns={addValuesColumnNew}
                      rowClassName='editable-row'
                      
                    />
                  </Form>
                </Col>
              </Row>
            </>
          )
        }
        else if (task === 'subType') {
          return (
            <>
              <Row className='padding10'>
                <Col span={24}>
                  <Form form={form} component={false}>
                    <Table
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      bordered
                      loading={loadingsss}
                      dataSource={subTypeDataSource}
                      columns={addSubTypeColumn}
                      rowClassName='editable-row'
                     
                    />
                  </Form>
                </Col>
              </Row>
            </>
          )
        }
        else if (task === 'category') {
          return (
            <>
              <Row className='padding10'>
                <Col span={24}>
                  <Form form={form} component={false}>
                    <Table
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      bordered
                      loading={loadingssss}
                      dataSource={catTypeDataSource}
                      columns={addCatTypeColumn}
                      rowClassName='editable-row'
                      
                    />
                  </Form>
                </Col>
              </Row>
            </>
          )
        }
        else {
          return (
            <></>
          )
        }
      })()}



      <div className='pd10'></div>
    </Fragment>
  );
}

export default Metadata;
