import { Button, Col, Collapse, Divider, Row, Select } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchLeadDetails } from "../../../../redux/reducers/leads.slice";
import { formatDropDownValue } from "../../../../shared/Utils/utilities";
import { TASK_MODULE_ACTIONS_HIDE_BRANCH } from "../../../../shared/components/permission.constants";
import { SERVICE_LIST } from "../../../../shared/constants";
import { ListView } from "../../../../shared/layouts/crm-listcard.layout";
import TimelineLayout from "../../../../shared/layouts/timeline.layout";
import { ROUTE_CRM_ORDER_MEDICINE } from "../../../../shared/routes/crm.routes.constants";
import UpdateLeadForm from "./updateleadform";
const { Panel } = Collapse;
const { Option } = Select;

type Props = {
  details: any;
};

function LeadDetailsTab({ details }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = useState(
    "order_medicines" as any
  );

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const leadDetails = [
    {
      title: "Service Type",
      text: details?.svc_type?.name
        ? formatDropDownValue(details?.svc_type?.name)
        : "--",
      type: "text",
    },
    {
      title: "Service Sub Type",
      text: details?.svc_type?.name
        ? formatDropDownValue(details?.svc_sub_type?.name)
        : "--",
      type: "text",
    },
    ...((myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH) !== -1) ? [] : [{
      title: "Hospital",
      text: details?.branch?.name
        ? formatDropDownValue(details?.branch?.name)
        : "--",
      type: "text",
    }])
    ,
    {
      title: "Source",
      text: details?.source?.name || details?.meta?.chatbottype
        ? formatDropDownValue(details?.source?.name || details?.meta?.chatbottype)
        : "--",
      type: "text",
    },
    // {title : 'Doctor EP', text: 'View Documents', type: 'link', path: '' },
    // {title : 'Patient Uploaded Document', text: 'View Documents (5)', type: 'link', path: ''},
    {
      title: "Lead Description",
      text: (
        <>


          {details?.description?.description ? (
            <p>{details.description.description}</p>
          ) : (
            <p>--</p>
          )}
        </>
      ),
      type: "text",
    },
    details?.files?.length > 0 && {
      title: "Attachment",
      text: details?.files?.length > 0 ?
        details?.files?.map((val: any, index: any) => {
          return (
            <>
              <p className="mb-2">
                <a
                  href={val?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  View:
                  {val?.file?.file_name}
                </a>
              </p>

            </>
          )
        })
        : (
          "--"
        ),
      type: "text",
    }
  ];
  const serviceList = SERVICE_LIST;

  const serviceMenu = (val: any, placeholder: string) => {
    return (
      <Select
        placeholder={placeholder}
        defaultValue={"order_medicines"}
        style={{ width: "100%" }}
        onChange={serviceSelect}
      >
        {val.map((v: any, index: number) => {
          return (
            <Option key={index} value={v.name}>
              {formatDropDownValue(v.name)}
            </Option>
          );
        })}
      </Select>
    );
  };
  const serviceSelect = (e: any) => {
    setSelectedService(e);
  };
  const serviceInitAction = () => {
    switch (selectedService) {
      // case 'book_appointment':  history.push(ROUTE_CRM_BOOK_APPOINTMENT(details.id)); break;
      case "order_medicines":
        history.push(ROUTE_CRM_ORDER_MEDICINE(details.id));
        history.go(0)
        break;
    }
  };

  const timelineDetails = details?.history;
  return (
    <div className="details-page-fixed-header-comp">
      {/* <div className='fixed-header-comp'> */}
      <Row>
        <Col
          {...{ xs: 24, sm: 24, md: 17, lg: 17, xl: 17 }}
          className="border-right"
        >
          <div className="padding20 complaint-box">
            <h3 className="bold-600 visible-only-mobile">Lead Details</h3>
            <ListView details={leadDetails}></ListView>
            {details?.opportunities?.length > 0 && (
              <ListView
                details={[
                  {
                    title: "Opportunities",
                    tags: details?.opportunities?.map((val: any) => {
                      return formatDropDownValue(val.opportunity.name);
                    }),
                    type: "tags",
                  },
                ]}
              ></ListView>
            )}
            {formatDropDownValue(details?.status?.name) !== "CLOSED" &&
              serviceList?.length > 0 && (
                <>
                  <Divider />
                  <Row>
                    <Col className="text-secondary">Book Services</Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={14} className="gutter-row">
                      {serviceMenu(serviceList, "Select Service")}
                    </Col>
                    <Col span={10} className="gutter-row">
                      <Button type="primary" block onClick={serviceInitAction}>
                        Book Service
                      </Button>
                    </Col>
                  </Row>{" "}
                </>
              )}
          </div>
          <Collapse defaultActiveKey={["1"]} className="collapse-right-arrow">
            <Panel header="Lead History" key="1">
              <TimelineLayout
                type="Lead"
                details={timelineDetails}
              ></TimelineLayout>
            </Panel>
          </Collapse>
        </Col>
        <Col {...{ xs: 24, sm: 24, md: 7, lg: 7, xl: 7 }}>
          <UpdateLeadForm
            details={details}
            parentCallback={() => dispatch(fetchLeadDetails(details.id))}
          ></UpdateLeadForm>
        </Col>
      </Row>
    </div>
  );
}

export default LeadDetailsTab;
