import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../shared/Utils/api-client";
import {
  NEW_PATIENT_FROM_CONTACT,
  NEW_PATIENT_FROM_CONTACT_DIRECT,
} from "../../shared/routes/crm.routes.constants";
import {
  LINK_PATIENT_BY_NUMBER,
  PATIENTS,
  PATIENTS_BY_NUMBER,
  PATIENTS_DOCUMENTS,
  PATIENTS_DOCUMENTS_ADD,
  PATIENTS_DOCUMENTS_DELETE,
  PATIENTS_RELATIONSHIPS,
  PATIENTS_RELATIONSHIPS_LINK,
  PATIENTS_RELATIONSHIPS_NON_LINKED,
  PATIENT_BY_ID,
} from "../../shared/routes/patient.routes.constants";
import { PATIENTS_DOCUMENTS_URL_BY_ID } from "../../shared/routes/route.constants";
import { Patient } from "../../shared/types/patient.type";

const config = {
  name: "patient",
};

export const fetchPatientsByNumber: any = createAsyncThunk(
  `${config.name}/fetchPatientsByNumber`,
  async (number: any) => {
    return await Client.get(PATIENTS_BY_NUMBER(number), {
      mediURL: true,
    });
  }
);

export const linkPatientByNumber: any = createAsyncThunk(
  `${config.name}/linkPatientByNumber`,
  async (data: any) => {
    return await Client.post(LINK_PATIENT_BY_NUMBER, data, {
      mediURL: true,
    });
  }
);

export const nonLinkedPatientByNumber: any = createAsyncThunk(
  `${config.name}/nonLinkedPatientByNumber`,
  async (data: any) => {
    return await Client.get(PATIENTS_RELATIONSHIPS_NON_LINKED, {
      mediURL: true,
    });
  }
);

export const fetchPatientsDetailsById: any = createAsyncThunk(
  `${config.name}/fetchPatientsDetailsById`,
  async (id: any) => {
    return await Client.get(PATIENT_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const addNewPatient: any = createAsyncThunk(
  `${config.name}/addNewPatient`,
  async (data: any) => {
    return await Client.post(PATIENTS, data, {
      mediURL: true,
    });
  }
);

export const addNewPatientFromContact: any = createAsyncThunk(
  `${config.name}/addNewPatientFromContact`,
  async (body: any) => {
    return await Client.post(NEW_PATIENT_FROM_CONTACT, body, {
      mediURL: true,
    });
  }
);

export const addNewPatientFromContactDirect: any = createAsyncThunk(
  `${config.name}/addNewPatientFromContactDirect`,
  async (body: any) => {
    return await Client.post(NEW_PATIENT_FROM_CONTACT_DIRECT, body, {
      mediURL: true,
    });
  }
);

export const addNewPatientReltionship: any = createAsyncThunk(
  `${config.name}/addNewPatientReltionship`,
  async (data: any) => {
    return await Client.post(PATIENTS_RELATIONSHIPS_LINK, data, {
      mediURL: true,
    });
  }
);

export const fetchPatientsDocuments: any = createAsyncThunk(
  `${config.name}/fetchPatientsDocuments`,
  async (params: any) => {
    return await Client.get(PATIENTS_DOCUMENTS, {
      params,
      mediURL: true,
    });
  }
);
export const fetchPatientsDocumentsByURL: any = createAsyncThunk(
  `${config.name}/fetchPatientsDocumentsByURL`,
  async (id: string) => {
    return await Client.get(PATIENTS_DOCUMENTS_URL_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const addPatientsDocuments: any = createAsyncThunk(
  `${config.name}/addPatientsDocuments`,
  async (data: any) => {
    return await Client.post(PATIENTS_DOCUMENTS_ADD, data, {
      mediURL: true,
    });
  }
);
export const removePatientsDocuments: any = createAsyncThunk(
  `${config.name}/removePatientsDocuments`,
  async (id: any) => {
    return await Client.delete(PATIENTS_DOCUMENTS_DELETE(id), {
      mediURL: true,
    });
  }
);

export const fetchPatientsRelationships: any = createAsyncThunk(
  `${config.name}/fetchPatientsRelationships`,
  async (number: any) => {
    return await Client.get(PATIENTS_RELATIONSHIPS, {
      mediURL: true,
    });
  }
);

export const patientsSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingPatient: false,
    patient: [] as Patient | [],
    error: "" as string,
    Patients: [] as Patient[],
    non_linked_patients: [] as Patient[],
    Documents: [] as any,
  },
  reducers: {
    resetPatient: (state) => {
      state.patient = [];
      state.savingPatient = false;
      state.loading = false;
      state.error = "";
      state.Patients = [];
      state.Documents = [];
      state.non_linked_patients = [];
    },
    resetSearchedPatientList: (state) => {
      state.Patients = [];
    },
    addNewPatientData: (state, payload) => {
      state.Patients = payload?.payload;
    },
  },
  extraReducers(builder) {
    builder
      // Patient List
      .addCase(fetchPatientsByNumber.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPatientsByNumber.fulfilled, (state, action) => {
        state.loading = false;
        state.Patients = action?.payload?.data?.data;
      })
      .addCase(fetchPatientsByNumber.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Patient Details
      .addCase(fetchPatientsDetailsById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPatientsDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.patient = action?.payload?.data?.data;
      })
      .addCase(fetchPatientsDetailsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchPatientsDocuments.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPatientsDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.Documents = action?.payload?.data?.data?.items;
      })
      .addCase(fetchPatientsDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchPatientsRelationships.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPatientsRelationships.fulfilled, (state, action) => {
        state.loading = false;
        state.Patients = action?.payload?.data?.data;
      })
      .addCase(fetchPatientsRelationships.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(nonLinkedPatientByNumber.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(nonLinkedPatientByNumber.fulfilled, (state, action) => {
        state.loading = false;
        state.non_linked_patients = action?.payload?.data?.data;
      })
      .addCase(nonLinkedPatientByNumber.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetPatient, resetSearchedPatientList, addNewPatientData } =
  patientsSlice.actions;

export default patientsSlice.reducer;
