import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client as SvcClient } from "../../shared/Utils/api-client";
import { Client } from "../../shared/Utils/social-client";
import {
  API_GOOGLE_ACCOUNT_CONNECT,
  API_GOOGLE_ACCOUNT_DETAILS,
  FACEBOOK_CAPTURE_PROFILE,
  FACEBOOK_GET_PAGES_BY_PROFILE_ID,
  FACEBOOK_GET_PAGE_BY_PAGE_ID,
  FACEBOOK_GET_PAGE_FORMS,
  FACEBOOK_GET_PROFILE,
  FACEBOOK_SUBSCRIBE_PAGE_BY_PAGE_ID,
  SYNC_LEADS_BYFORM_AND_PAGE_ID,
  UPDATE_FB_CLIENT_TOKEN,
} from "../../shared/routes/social.route.constants";

const config = {
  name: "social",
};
export const facebookCaptureProfile: any = createAsyncThunk(
  `${config.name}/facebookCaptureProfile`,
  async (payload: {
    fb_id: "string";
    name: "string";
    account_id: "string";
    account_name: "string";
    access_token: "string";
    expiry: 0;
  }) => {
    return await Client.post(FACEBOOK_CAPTURE_PROFILE, payload);
  }
);

export const facebookGetProfile: any = createAsyncThunk(
  `${config.name}/facebookGetProfile`,
  async (accountId: string) => {
    return await Client.get(FACEBOOK_GET_PROFILE);
  }
);
export const facebookGetPagesByProfileId: any = createAsyncThunk(
  `${config.name}/facebookGetPagesByProfileId`,
  async (profileId: string) => {
    return await Client.get(FACEBOOK_GET_PAGES_BY_PROFILE_ID(profileId));
  }
);
export const facebookSubscribePage: any = createAsyncThunk(
  `${config.name}/facebookSubscribePage`,
  async (pageId: string) => {
    return await Client.patch(FACEBOOK_SUBSCRIBE_PAGE_BY_PAGE_ID(pageId), {});
  }
);
export const getFormsByPageId: any = createAsyncThunk(
  `${config.name}/getFormsByPageId`,
  async (pageId: string) => {
    return await Client.get(FACEBOOK_GET_PAGE_FORMS(pageId));
  }
);
export const syncLeadsByFormAndPageId: any = createAsyncThunk(
  `${config.name}/syncLeadsByFormAndPageId`,
  async (data: any) => {
    return await Client.get(
      SYNC_LEADS_BYFORM_AND_PAGE_ID(data.page_id, data.form_id)
    );
  }
);
export const updateFbClientToken: any = createAsyncThunk(
  `${config.name}/updateFbClientToken`,
  async ({ access_token, fbId }: any) => {
    return await Client.patch(UPDATE_FB_CLIENT_TOKEN(fbId), {
      access_token: access_token,
    });
  }
);
export const patchFacebookPageData: any = createAsyncThunk(
  `${config.name}/patchFacebookPageData`,
  async ({ payload, pageId }: any) => {
    return await Client.patch(FACEBOOK_GET_PAGE_BY_PAGE_ID(pageId), payload);
  }
);

export const requestGoogleConnect: any = createAsyncThunk(
  `${config.name}/requestGoogleConnect`,
  async (payload: any) => {
    return await SvcClient.post(API_GOOGLE_ACCOUNT_CONNECT, payload);
  }
);
export const requestGoogleAccountDetails: any = createAsyncThunk(
  `${config.name}/requestGoogleAccountDetails`,
  async (payload: any) => {
    return await SvcClient.get(API_GOOGLE_ACCOUNT_DETAILS);
  }
);

export const socialSlice = createSlice({
  name: config.name,
  initialState: {
    profileGetLoading: true,
    profileData: null as any,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(facebookGetProfile.pending, (state) => {
        state.profileGetLoading = true;
      })
      .addCase(facebookGetProfile.fulfilled, (state, action) => {
        if (action?.payload?.data?.data) {
          state.profileGetLoading = false;
          state.profileData = action.payload.data.data;
        }
      })
      .addCase(facebookGetProfile.rejected, (state, action) => {
        state.profileGetLoading = false;
      });
  },
});

export const {} = socialSlice.actions;

export default socialSlice.reducer;
