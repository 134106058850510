import { Button, Card, Col, Row, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { truncateText50 } from "../Utils/utilities";
const { Text } = Typography;

type Props = {
  title: string;
  details?: any;
  showCard?: true;
  action?: any;
  auditData?: any;
  auditCallback?: any;
  showMoreBtn?: boolean;
};
function ListCard({
  title,
  details,
  action,
  auditData,
  auditCallback,
  showMoreBtn = false,
}: Props) {
  //const { t } = useTranslation();
  const handleAuditTrail = (e: any, auditData: any) => {
    e.preventDefault();
    auditCallback(auditData);
  };
  const [listData, setlistData] = useState([] as any);
  const [expanded, setexpanded] = useState(false);

  useEffect(() => {
    showMoreBtn ? setlistData(details?.slice(0, 6)) : setlistData(details);
  }, [details, showMoreBtn]);

  const showAll = () => {
    if (details) {
      details?.length === listData?.length
        ? setlistData(details?.slice(0, 6))
        : setlistData(details);
    }
    setexpanded(!expanded);
  };

  console.log(details);
  return (
    <>
      <Card
        size="small"
        title={title}
        extra={
          <a href="" onClick={(e) => handleAuditTrail(e, auditData)}>
            {action}
          </a>
        }
        className="mb-15 crm-card"
      >
        <ListView details={listData}></ListView>
        {showMoreBtn && details?.length > 5 && (
          <Button onClick={showAll}>
            {expanded ? "Show Less" : "Show More"}{" "}
          </Button>
        )}
      </Card>
    </>
  );
}
function ListView({ details }: any) {
  return details?.map((row: any, index: number) => {
    console.log("aaaaaaaaaaa", row.type === "email" && row.text);
    return (
      <Row className=" list-card" key={index}>
        {row?.title && (
          <Col
            {...{ xs: 24, sm: 24, md: 10, lg: 10, xl: 10 }}
            className="text-secondary mb-10"
            style={{ wordWrap: "break-word" }}
          >
            {row?.title}
          </Col>
        )}

        {row?.type === "text" && (
          <Col
            {...{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}
            className="text-right capitalize breakWord"
          >
            {row?.text}
            {row?.subtext && (
              <>
                <br />
                <small className="text-secondary">{row?.subtext}</small>
              </>
            )}
          </Col>
        )}
        {row?.type === "email" && (
          <Col
            {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
          // className="text-right capitalize"
          >
            <Text>
              <span
                dangerouslySetInnerHTML={{
                  __html: truncateText50(row?.text, 50),
                }}
              ></span>
            </Text>
          </Col>
        )}
        {row?.type === "tags" && (
          <Col
            {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
            className="text-right"
          >
            {row?.tags.map((tag: string, index: number) => {
              return <Tag>{tag}</Tag>;
            })}
            {row?.tagscount && <Tag>+{row?.tagscount}</Tag>}
          </Col>
        )}
        {row?.type === "link" && (
          <Col
            {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
            className="text-right"
          >
            <a href={row?.path}>{row?.text}</a>
          </Col>
        )}
      </Row>
    );
  });
}
export { ListView };
export default ListCard;
