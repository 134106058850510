import { Card, Col, Divider, PageHeader, Row } from "antd";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchContact } from "../../../redux/reducers/contacts.slice";
import { fetchLeadDetails } from "../../../redux/reducers/leads.slice";
import { addNewPatientFromContact } from "../../../redux/reducers/patients.slice";
import { RootState } from "../../../shared/constants";
import { contactNameFormat } from "../../../shared/Utils/utilities";
import BookAppointment from "../../dependencies/book-appointment";

function CRMBookAppointmentPage() {
  let params: any = useParams();
  let leadId = params?.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading_details, lead } = useSelector(
    (state: RootState) => state.leads
  );
  // const [ contactDetails, setContactDetails ] = useState(null as any);
  const { contact } = useSelector((state: RootState) => state.contact);

  useEffect(() => {
    dispatch(fetchLeadDetails(leadId));
  }, [leadId]);
  // useEffect(() => {
  //     if(!loading_details && lead){
  //         setContactDetails({
  //             contact_id: lead.contact_id,
  //             ...lead.contact
  //         })
  //     }
  // }, [lead])
  useEffect(() => {
    lead?.contact_id && dispatch(fetchContact(lead.contact_id));
  }, [lead]);

  useEffect(() => {
    if (contact) {
      if (contact?.patient_id) {
      } else {
        dispatch(
          addNewPatientFromContact({ contact_id: lead.contact_id })
        ).then((val: any) => {
          if (val?.payload?.data?.statusCode == 200) {
            dispatch(fetchContact(lead.contact_id));
          }
        });
      }
    }
  }, [contact]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Book Appointment"
      ></PageHeader>
      <div className="pd20">
        <Card className="nopaddingcard">
          <div className="pd20">
            <Row>
              <Col className="gutter-row">
                <Row>
                  <Col>
                    <h1>Book Appointment</h1>
                  </Col>
                </Row>
                {contact && (
                  <Row>
                    <Col>
                      {contactNameFormat(
                        contact?.first_name,
                        contact?.last_name,
                        contact?.gender?.name,
                        contact?.dob
                      )}{" "}
                      <Divider type="vertical"></Divider> {contact?.phone}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
          {contact?.patient_id && (
            <BookAppointment
              app="crm"
              type="lead"
              details={lead}
            ></BookAppointment>
          )}
        </Card>
      </div>
    </Fragment>
  );
}

export default CRMBookAppointmentPage;
