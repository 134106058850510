import { Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import { TASK_MODULE_ACTIONS_HIDE_AGE, TASK_MODULE_ACTIONS_HIDE_CLIENT_DOCTOR_CODE, TASK_MODULE_ACTIONS_HIDE_GENDER, TASK_MODULE_ACTIONS_HIDE_NATIONALITY, TASK_MODULE_ACTIONS_HIDE_THB_ID, TASK_MODULE_ACTIONS_HIDE_UHID, TASK_MODULE_ACTIONS_PHONE_EDITABLE_IN_EDIT_CONTACT, TASK_MODULE_ACTIONS_SHOW_CITY, TASK_MODULE_ACTIONS_SHOW_CLASS, TASK_MODULE_ACTIONS_SHOW_CLUSTER_IN_CONTACT, TASK_MODULE_ACTIONS_SHOW_COUNTRY, TASK_MODULE_ACTIONS_SHOW_SPECIALIZATION, TASK_MODULE_ACTIONS_SHOW_STATE, TASK_MODULE_ACTIONS_SHOW_USER_TYPE_CONTACT } from "../../../../shared/components/permission.constants";
import { IS_JARVIS, RootState } from "../../../../shared/constants";
import FormBar from "../../../../shared/layouts/formbar.layout";

type Props = {
  onSaveContact: (val: any) => void;
  onSaveEditContact?: (val: any) => void;
  onSaveContactFailed: (val: any) => void;
  modalVisible: boolean;
  setmodalVisible: (val: any) => void;
  formLoading: boolean;
  gender: any;
  nationality: any;
  initialValues?: any;
  buttonText?: any;
  editData?: any;
  actionType?: any;
};

function CreateContactForm({
  actionType,
  editData,
  onSaveContact,
  onSaveEditContact,
  onSaveContactFailed,
  modalVisible,
  setmodalVisible,
  formLoading,
  gender,
  nationality,
  initialValues,
  buttonText = "",
}: Props) {
  const { t } = useTranslation();
  const [phoneValidate1, setPhoneValidate1] = useState(false as any);
  const { branches } = useSelector((state: RootState) => state.branch);
  const [phoneValidateMessage1, setPhoneValidateMessage1] = useState(
    false as any
  );
  const [phoneValidateMessage2, setPhoneValidateMessage2] = useState(
    false as any
  );
  const { profile } = useSelector((state: RootState) => state.profile);
  const [phoneValidate2, setPhoneValidate2] = useState(false as any);
  // console.log("actionType==>",actionType)
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  function onChange1(e: number) {
    if (e > 0) {
      if (e < 1000000000 || e > 9999999999) {
        setPhoneValidate1("error");
        setPhoneValidateMessage1("Please input valid Phone Number!");
        return;
      }
    }
    setPhoneValidate1(false);
    setPhoneValidateMessage1(false);
  }
  function onChange2(e: number) {
    if (e > 0) {
      if (e < 1000000000 || e > 9999999999) {
        setPhoneValidate2("error");
        setPhoneValidateMessage2("Please input valid Phone Number!");
        return;
      }
    }
    setPhoneValidate2(false);
    setPhoneValidateMessage2(false);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(fetchBranches())
    })()
  }, [])




  const formElements = {
    heading:
      actionType === "edit"
        ? `Edit ${t("CRM.Contacts.contact")}`
        : `Create ${t("CRM.Contacts.contact")}`,
    onFinish: actionType === "edit" ? onSaveEditContact : onSaveContact,
    onFinishFailed: onSaveContactFailed,
    initialValues: {
      first_name: actionType === "edit" ? editData?.first_name : null,
      last_name: actionType === "edit" ? editData?.last_name : null,
      phone:
        actionType === "edit"
          ? editData?.phone
          : actionType == "freezephone"
            ? initialValues?.phoneNumber
            : null,
      alternate_phone: actionType === "edit" ? editData?.alternate_phone : null,
      gender_id: actionType === "edit" ? editData?.gender_id : null,
      dob:
        actionType === "edit" && editData?.dob
          ? moment(new Date(editData?.dob), "YYYY-MM-DD")
          : null,
      nationality_id: actionType === "edit" ? editData?.nationality_id : nationality.filter((val: any) => {
        return val.name === 'indian'
      }).map((val: any) => {
        return val.id
      }).toString(),
      email: actionType === "edit" ? editData?.email : null,
      thb_id: actionType === "edit" ? editData?.thb_id : null,
      uhid: actionType === "edit" ? editData?.uhid : null,
      state: actionType === "edit" ? editData?.state : null,
      user_type: actionType === "edit" ? editData?.user_type : null,
      city: actionType === "edit" ? editData?.city : null,
      class: actionType === "edit" ? editData?.class : null,
      specialization: actionType === "edit" ? editData?.specialization : null,
      branch_id: actionType === "edit" ? editData?.branch_id : null,
      country: actionType === "edit" ? editData?.country : null,
    },
    buttonText: buttonText,
    formElements: [
      ...(IS_JARVIS ? [

        {
          label: `${t("CRM.Contacts.contact")}`,
          type: "Input",
          name: "last_name",
          rules: [{ required: true, message: "Please input Last Name!" }],
          colSpan: 8,
          // disabled:actionType === "edit" && editData?.last_name !== null ? true : false,
        }] : [{
          label: t("CRM.Common.Fields.First Name"),
          type: "Input",
          name: "first_name",
          rules: [{ required: true, message: "Please input First Name!" }],
          colSpan: 8,
          // disabled:actionType === "edit" && editData?.first_name !== null ? true : false,
        },
        {
          label: t("CRM.Common.Fields.Last Name"),
          type: "Input",
          name: "last_name",
          rules: [{ required: true, message: "Please input Last Name!" }],
          colSpan: 8,
          // disabled:actionType === "edit" && editData?.last_name !== null ? true : false,
        }]),

      {
        label: t("CRM.Common.Fields.Phone No."),
        type: "InputNumber",
        name: "phone",
        rules: [{ required: true, message: "Please input Phone Number!" }],
        colSpan: 8,
        disabled: (
          actionType === "edit" && editData?.phone !== null
            ? (myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_PHONE_EDITABLE_IN_EDIT_CONTACT) !== -1)
              ? false
              : true
            : false
        ),
        // (actionType === "freezephone" ? true : false),
        onChange: onChange1,
        validateStatus: phoneValidate1,
        help: phoneValidateMessage1,
      },
      {
        label: t("CRM.Common.Fields.Alternate Phone No."),
        type: "InputNumber",
        name: "alternate_phone",
        colSpan: 8,
        onChange: onChange2,
        validateStatus: phoneValidate2,
        help: phoneValidateMessage2,
      },
      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_CLUSTER_IN_CONTACT) !== -1 ? [{
        label: t("CRM.Common.Fields.Branch"),
        type: "Select",
        name: "branch_id",
        colSpan: 8,
        // rules: [{ required: true, message: "Please select Gender!" }],
        // disabled:actionType === "edit" && editData?.gender_id !== null ? true : false,
        options:
          branches?.map((val: any) => {
            return { value: val.id, name: val.name };
          }),
      }] : []),

      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_GENDER) !== -1 ? [] : [{
        label: t("CRM.Common.Fields.Gender"),
        type: "Select",
        name: "gender_id",
        colSpan: 8,
        // rules: [{ required: true, message: "Please select Gender!" }],
        // disabled:actionType === "edit" && editData?.gender_id !== null ? true : false,
        options: gender.map((val: any) => {
          return { value: val.id, name: val.name };
        }),
      }]),
      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_AGE) !== -1 ? [] : [{
        label: t("CRM.Common.Fields.DOB"),
        type: "DatePicker",
        name: "dob",
        colSpan: 8,
        // rules: [{ required: true, message: "Please select Date of birth!" }],
        disabledDate: (d: any) => {
          return d > moment() || d < moment("1900-01-01");
        },
        // disabled: actionType === "edit" && editData?.dob !== null ? true : false,
      }]),
      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_NATIONALITY) !== -1 ? [] : [{
        label: t("CRM.Common.Fields.Nationality"),
        type: "Select",
        name: "nationality_id",
        colSpan: 8,
        rules: [{ required: true, message: "Please select Nationality!" }],
        // disabled:
        //   actionType === "edit" && editData?.nationality_id !== null
        //     ? true
        //     : false,
        options: nationality.map((val: any) => {
          return { value: val.id, name: val.name };
        }),
      }]),
      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_COUNTRY) !== -1 ? [{
        label: t("CRM.Common.Fields.Country"),
        type: "Input",
        name: "country",
        colSpan: 8,

      }] : []),


      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_CITY) !== -1 ? [{
        label: "City",
        type: "Input",
        name: "city",
        colSpan: 8,
        // rules: [{ required: true, message: "Please select Gender!" }],
        // disabled:actionType === "edit" && editData?.gender_id !== null ? true : false,
        options: gender.map((val: any) => {
          return { value: val.id, name: val.name };
        }),
      }] : []),
      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_CLASS) !== -1 ? [{
        label: "Class",
        type: "Input",
        name: "class",
        colSpan: 8,
        // rules: [{ required: true, message: "Please select Date of birth!" }],
        // disabledDate: (d: any) => {
        //   return d > moment() || d < moment("1900-01-01");
        // },
        // disabled: actionType === "edit" && editData?.dob !== null ? true : false,
      }] : []),
      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_SPECIALIZATION) !== -1 ? [{
        label: "Specialization",
        type: "Input",
        name: "specialization",
        colSpan: 8,

      }] : []),
      {
        label: t("CRM.Common.Fields.Email ID"),
        type: "Input",
        name: "email",
        defaultValue: "new",
        rules: [{ type: "email", message: "Please input valid Email ID!" }],
        colSpan: 8,
        // disabled: actionType === "edit" ? true : false,
      },

      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_THB_ID) !== -1 ? [] : [{
        label: "THB Id",
        type: "Input",
        name: "thb_id",
        colSpan: 8,
        disabled: (actionType === 'edit' && profile?.type !== 'admin') ? true : false
      }]),

      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_CLIENT_DOCTOR_CODE) !== -1 ? [] : [
        {
          label: "Client Doctor Code",
          type: "Input",
          name: "uhid",
          colSpan: 8,
          disabled: (actionType === 'edit' && profile?.type !== 'admin') ? true : false
        }
      ]),
      ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_STATE) !== -1 ? [{
        label: "State",
        type: "Input",
        name: "state",
        colSpan: 8,
        disabled: (actionType === 'edit' && profile?.type !== 'admin') ? true : false
      }] : [

      ]),
      ...(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_UHID) !== -1
        ? []
        : [
          {
            label: t("CRM.Common.Fields.UHID"),
            type: "Input",
            name: "uhid",
            colSpan: 8,
          }
        ]),

      ...(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_USER_TYPE_CONTACT) !== -1
        ? [{
          label: "User Type",
          type: "Input",
          name: "user_type",
          colSpan: 8,
        }]
        : [

        ]),


    ],
  };
  return (
    <Modal
      title={actionType === "edit" ? `Edit ${t("CRM.Contacts.contact")}` : t("CRM.Common.Actions.Create Contact")}
      centered
      visible={modalVisible}
      onCancel={() => setmodalVisible(false)}
      width={1000}
      footer={false}
      className="modalFormButton form-modal"
      style={{ paddingBottom: "0px" }}
    >
      <FormBar
        actionType={actionType}
        name={"createContactForm"}
        loading={formLoading}
        onFinish={formElements.onFinish}
        onFinishFailed={formElements.onFinishFailed}
        formElements={formElements.formElements}
        initialValues={formElements.initialValues}
        buttonText={buttonText}
      ></FormBar>
    </Modal>
  );
}

export default CreateContactForm;
