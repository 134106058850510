import {
  DownOutlined,
  FacebookOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  PageHeader,
  Row,
  Space,
  Table,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  facebookCaptureProfile,
  facebookGetProfile,
  updateFbClientToken,
} from "../../../redux/reducers/social.slice";
import { FbClient } from "../../../shared/Utils/fb-client";
import { formatDate } from "../../../shared/Utils/utilities";
import { ACCOUNT_PREFIX, RootState } from "../../../shared/constants";
declare global {
  interface Window {
    FB: any;
  }
}
function SocialProfilesPage() {
  const token =
    "EAASrtb49lysBAPBJ2v55jumEzy5h8vhCzdvJB66Oq4cmTlZCM1eTAqrZBUmIcyV5g1GSRrj2DsFK2KWRIrpz4RjIIBXtoiOv1LZCnGJZBCu6qBoY0PIvFwKVIKhqCnJM5W6q8SsHj2K0wymfM3VSkKUvXZCZBkYAUXJjPr6h6DwNG4KslhBFUv";
  const dispatch = useDispatch();
  const { profileGetLoading, profileData } = useSelector(
    (state: RootState) => state.social
  );
  const [profilesList, setProfilesList] = useState([] as any);
  const FB = window.FB;
  const accountId = localStorage.getItem("account_id");
  const getFacebookProfile = () => {
    dispatch(facebookGetProfile(accountId));
  };

  useEffect(() => {
    if (profileData?.fb_id) {
      setProfilesList([{ ...profileData, platform: "Facebook" }]);
    } else {
      getFacebookProfile();
    }
  }, [profileData]);
  const columns = [
    {
      title: "Profile Name",
      width: 200,
      dataIndex: "name",
    },
    {
      title: "Platform",
      width: 200,
      dataIndex: "platform",
    },
    {
      title: "Added Date",
      width: 200,
      dataIndex: "created_at",
      render: (a: any) => {
        return formatDate(a);
      },
    },
    {
      title: "Token Validity",
      width: 200,
      dataIndex: "updated_at",
      render: (a: any) => {
        return formatDate(moment(a).add(60, "d").format());
      },
    },
    {
      title: "Actions",
      width: 200,
      // fixed: "right",
      render: (a: any) => {
        return (
          <Space size={16}>
            <Tooltip title="Refresh Connection">
              <Button
                type="link"
                className="bold-600 text-primary"
                onClick={fbLoginInit}
                icon={<SyncOutlined />}
              ></Button>
            </Tooltip>
            {/* <Tooltip title="Settings">
              <Button
                type="link"
                className="bold-600 text-primary"
                // disabled={lastActivityCheck(a) || syncDisabled}
                onClick={() => {
                  setSelectedForm(a);
                  setFormSettingsModalVisible(true);
                }}
                icon={<SettingOutlined />}
              ></Button>
            </Tooltip> */}
          </Space>
        );
      },
    },
  ];

  const handleMenuClick = (e: any) => {
    console.log("click", e.key);

    switch (e.key) {
      case "fb":
        fbLoginInit();
        break;
    }
  };
  const fbLoginInit = () => {
    FB.login(
      function (response: any) {
        console.log("Successfully logged in", response);
        getLongLivedToken(
          response.authResponse.accessToken,
          response.authResponse.userID
        );
      },
      {
        scope: [
          "read_insights",
          "pages_messaging",
          // "business_management",
          "ads_read",
          "pages_manage_ads",
          "pages_manage_metadata",
          "pages_read_engagement",
          "pages_read_user_content",
          "pages_manage_posts",
          "pages_manage_engagement",
          "ads_management",
          "leads_retrieval",
          "pages_show_list",
          // "instagram_basic",
          // "instagram_manage_comments",
          // "instagram_manage_insights",
          // "instagram_manage_messages",
          // "instagram_content_publish",
        ],
      }
    );
  };
  const getLongLivedToken = (token: string, userId: string) => {
    FbClient.get("https://graph.facebook.com/v15.0/oauth/access_token", {
      params: {
        grant_type: "fb_exchange_token",
        client_id: "1314696975980331",
        client_secret: "5d54408694038031893b3ce7aa71784d",
        fb_exchange_token: token,
      },
    })
      .then((res: any) => {
        const long_access_token = res.data.access_token;
        FbClient.get("https://graph.facebook.com/v15.0/me", {
          params: {
            access_token: long_access_token,
          },
        }).then((res: any) => {
          const profile_name = res.data.name;
          if (profileData?.fb_id) {
            dispatch(
              updateFbClientToken({
                access_token: long_access_token,
                fbId: profileData?.fb_id,
              })
            ).then((val: any) => {
              if (val?.payload?.data?.statusCode === 200) {
                message.success("Connection renewed successfully!");
                getFacebookProfile();
              }
            });
          } else {
            saveFbClientDetailsBackend(long_access_token, userId, profile_name);
          }
        });
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  const saveFbClientDetailsBackend = (
    token: string,
    userId: string,
    profile_name: string
  ) => {
    dispatch(
      facebookCaptureProfile({
        fb_id: userId,
        name: profile_name,
        account_id: accountId,
        account_name: ACCOUNT_PREFIX,
        access_token: token,
        expiry: "",
        email: "",
      })
    ).then((val: any) => {
      getFacebookProfile();
    });
  };
  //token = "EAASrtb49lysBAPBJ2v55jumEzy5h8vhCzdvJB66Oq4cmTlZCM1eTAqrZBUmIcyV5g1GSRrj2DsFK2KWRIrpz4RjIIBXtoiOv1LZCnGJZBCu6qBoY0PIvFwKVIKhqCnJM5W6q8SsHj2K0wymfM3VSkKUvXZCZBkYAUXJjPr6h6DwNG4KslhBFUv"
  const addProfileMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="fb" icon={<FacebookOutlined />}>
        Facebook
      </Menu.Item>
    </Menu>
  );
  return (
    <Fragment>
      <PageHeader ghost={false} title="Social Profiles"></PageHeader>
      <Row className="crm-dashboard">
        <Col span={24} className="crm-tabs padding10">
          <Row className="padding10" justify="end">
            <Col>
              <Dropdown overlay={addProfileMenu}>
                <Button type="primary">
                  <Space>
                    Add New Profile
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>
          <Row className="padding10">
            <Col span={24}>
              <Table
                className="crm-table"
                loading={false}
                dataSource={profilesList}
                columns={columns}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
}

export default SocialProfilesPage;
