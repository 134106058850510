import { Col, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import { ReactComponent as ERROR } from "../../assets/icons/error.svg";
import { ReactComponent as LOADING } from "../../assets/icons/loader.svg";
import { ReactComponent as PROCESSING } from "../../assets/icons/processing.svg";
import { ReactComponent as SUCCESS } from "../../assets/icons/success.svg";
import { ReactComponent as WARNING } from "../../assets/icons/warning.svg";

function PopupMessageComponent({
  open,
  onCancel,
  title,
  subTitle,
  type,
  buttons,
}: any) {
  return (
    <>
      <Modal
        visible={open}
        onCancel={onCancel}
        footer={false}
        title={false}
        closable={false}
        centered={true}
      >
        <Row justify={"center"}>
          <Col>
            {type == "loading" && <LOADING className="spin" />}
            {type == "processing" && <PROCESSING />}
            {type == "success" && <SUCCESS />}
            {type == "warning" && <WARNING />}
            {type == "error" && <ERROR />}
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <h2>
              <strong>{title}</strong>
            </h2>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <p>{subTitle}</p>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col span={18}>{buttons}</Col>
        </Row>
      </Modal>
    </>
  );
}

export default PopupMessageComponent;
