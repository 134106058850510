import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ADD_NEW_SPECIALIZATION,
  ASSIGN_DOCTOR_SLOT_CONFIG,
  ASSIGN_DOCTOR_SLOT_PROCEDURE,
  DELETE_DOCTOR_PROCEDURE,
  DELETE_SPECIALIZATION,
  DOCTORS,
  DOCTORS_QUERY,
  DOCTOR_AUTOCOMPLETE,
  DOCTOR_AVAIL_SLOTS_BY_ID,
  DOCTOR_BY_ID,
  DOCTOR_COORDINATOR,
  DOCTOR_COORDINATOR_BY_ID,
  DOCTOR_MODIFY_PROPS,
  DOCTOR_PROCEDURE_CONFIG,
  DOCTOR_PROPERTIES,
  DOCTOR_PROPS_BY_ID,
  DOCTOR_SLOTS_BY_ID,
  DOCTOR_SLOT_CONFIG,
  DOCTOR_SPECIALIZATION,
  DOCTOR_UPDATE,
  UPDATE_DOCTOR_PROCEDURE_CONFIG,
  UPDATE_DOCTOR_SLOT_CONFIG,
} from "../../shared/routes/doctor.routes.constants";
import { Doctor } from "../../shared/types/doctor.type";
import { SlotConfigration } from "../../shared/types/slot.type";
import { Client } from "../../shared/Utils/api-client";

const config = {
  name: "doctors",
};

export const fetchDoctors: any = createAsyncThunk(
  `${config.name}/fetchDoctors`,
  async (params: any) => {
    return await Client.get(DOCTORS_QUERY, {
      params,
      mediURL: true,
    });
  }
);

export const fetchDoctorCoordinator: any = createAsyncThunk(
  `${config.name}/fetchDoctorCoordinator`,
  async (params: any) => {
    return await Client.get(DOCTOR_COORDINATOR, {
      params,
      mediURL: true,
    });
  }
);

export const addDoctorCoordinator: any = createAsyncThunk(
  `${config.name}/fetchDoctorCoordinator`,
  async (data: any) => {
    return await Client.post(DOCTOR_COORDINATOR, data, {
      mediURL: true,
    });
  }
);

export const updateDoctorCoordinator: any = createAsyncThunk(
  `${config.name}/updateDoctorCoordinator`,
  async (data: any) => {
    return await Client.patch(DOCTOR_COORDINATOR_BY_ID(data.id), data.data, {
      mediURL: true,
    });
  }
);

export const addNewDoctor: any = createAsyncThunk(
  `${config.name}/addNewDoctor`,
  async (data: Doctor) => {
    return await Client.post(DOCTORS, data, {
      mediURL: true,
    });
  }
);

export const fetchDoctorById: any = createAsyncThunk(
  `${config.name}/fetchDoctorById`,
  async (id: string) => {
    return await Client.get(DOCTOR_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const updateDocterMobile: any = createAsyncThunk(
  `${config.name}/updateDocterMobile`,
  async (data: string) => {
    return await Client.post(DOCTOR_UPDATE, data, {
      mediURL: true,
    });
  }
);

export const updateDocter: any = createAsyncThunk(
  `${config.name}/updateDocter`,
  async (data: Doctor) => {
    return await Client.post(DOCTOR_UPDATE, data, {
      mediURL: true,
    });
  }
);

export const addDoctorSlotConfig: any = createAsyncThunk(
  `${config.name}/addDoctorSlotConfig`,
  async (data: SlotConfigration) => {
    return await Client.post(ASSIGN_DOCTOR_SLOT_CONFIG, data, {
      mediURL: true,
    });
  }
);

export const updateDoctorSlotConfig: any = createAsyncThunk(
  `${config.name}/updateDoctorSlotConfig`,
  async ({ id, data }: { id: string; data: SlotConfigration }) => {
    return await Client.patch(UPDATE_DOCTOR_SLOT_CONFIG(id), data, {
      mediURL: true,
    });
  }
);

export const updateDoctorSlotProcedure: any = createAsyncThunk(
  `${config.name}/updateDoctorSlotProcedure`,
  async (data: SlotConfigration) => {
    return await Client.post(UPDATE_DOCTOR_PROCEDURE_CONFIG, data, {
      mediURL: true,
    });
  }
);

export const addDoctorSlotProcedure: any = createAsyncThunk(
  `${config.name}/addDoctorSlotProcedure`,
  async (data: SlotConfigration) => {
    return await Client.post(ASSIGN_DOCTOR_SLOT_PROCEDURE, data, {
      mediURL: true,
    });
  }
);

export const fetchDoctorSlots: any = createAsyncThunk(
  `${config.name}/fetchDoctorSlots`,
  async (id: string) => {
    return await Client.get(DOCTOR_SLOTS_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const fetchDoctor: any = createAsyncThunk(
  `${config.name}/fetchDoctor`,
  async (doctor: string) => {
    return await Client.get(DOCTOR_AUTOCOMPLETE(doctor), {
      mediURL: true,
    });
  }
);

export const fetchDoctorAvailSlots: any = createAsyncThunk(
  `${config.name}/fetchDoctorAvailSlots`,
  async (params: string) => {
    return await Client.get(DOCTOR_AVAIL_SLOTS_BY_ID, {
      params,
      mediURL: true,
    });
  }
);

export const fetchDoctorProperties: any = createAsyncThunk(
  `${config.name}/fetchDoctorProperties`,
  async (data: any) => {
    return await Client.get(DOCTOR_PROPERTIES(data.id), {
      mediURL: true,
    });
  }
);

export const fetchSpecialization: any = createAsyncThunk(
  `${config.name}/fetchSpecialization`,
  async (id: string) => {
    return await Client.get(DOCTOR_SPECIALIZATION(id), {
      mediURL: true,
    });
  }
);

export const addNewSpecialization: any = createAsyncThunk(
  `${config.name}/addNewSpecialization`,
  async (data: any) => {
    return await Client.post(ADD_NEW_SPECIALIZATION, data, {
      mediURL: true,
    });
  }
);

export const deleteSpecialization: any = createAsyncThunk(
  `${config.name}/deleteSpecialization`,
  async (id: string) => {
    return await Client.delete(DELETE_SPECIALIZATION(id), {
      mediURL: true,
    });
  }
);

export const fetchDoctorPropsById: any = createAsyncThunk(
  `${config.name}/fetchDoctorPropsById`,
  async (id: string) => {
    return await Client.get(DOCTOR_PROPS_BY_ID(id), {
      mediURL: true,
    });
  }
);

export const createUpdateDoctorProps: any = createAsyncThunk(
  `${config.name}/createUpdateDoctorProps`,
  async (data: any) => {
    return await Client.post(DOCTOR_MODIFY_PROPS(data.id), data.body, {
      mediURL: true,
    });
  }
);

export const fetchDoctorSlotConfig: any = createAsyncThunk(
  `${config.name}/fetchDoctorSlotConfig`,
  async (id: string) => {
    return await Client.get(DOCTOR_SLOT_CONFIG(id), {
      mediURL: true,
    });
  }
);

export const fetchDoctorProcedureConfig: any = createAsyncThunk(
  `${config.name}/fetchDoctorProcedureConfig`,
  async (params: string) => {
    return await Client.get(DOCTOR_PROCEDURE_CONFIG, {
      params,
      mediURL: true,
    });
  }
);

export const deleteDoctorProcedure: any = createAsyncThunk(
  `${config.name}/deleteDoctorProcedure`,
  async (id: string) => {
    return await Client.delete(DELETE_DOCTOR_PROCEDURE(id), {
      mediURL: true,
    });
  }
);

export const docterSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingDoctor: false,
    doctor: null as Doctor | null,
    error: "" as string,
    doctors: [] as Doctor[],
    slot_id: "" as string,
    docter_slots_config: [],
    doctor_avail_slots: [] as Array<string>,
    fetchDoctorAvailSlots: false,
    coordinators: [] as any,
    slots_loader: false,
    doctor_props: [],
    specialization: [],
    doctorProps: [],
    procedureList: [],
  },
  reducers: {
    resetDoctor: (state) => {
      state.doctor = null;
      state.savingDoctor = false;
      state.loading = false;
      state.error = "";
      state.doctors = [];
      state.docter_slots_config = [];
      state.doctor_avail_slots = [];
      state.slots_loader = false;
      state.doctor_props = [];
      state.specialization = [];
      state.doctorProps = [];
      state.procedureList = [];
    },
    resetDoctorAvailSlots: (state) => {
      state.doctor_avail_slots = [];
    },
  },
  extraReducers(builder) {
    builder
      // Doctor  List
      .addCase(fetchDoctors.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchDoctors.fulfilled, (state, action) => {
        state.loading = false;
        state.doctors = action.payload?.data?.data;
      })
      .addCase(fetchDoctors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // AutoComplete Doctor
      .addCase(fetchDoctor.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchDoctor.fulfilled, (state, action) => {
        state.loading = false;
        state.doctors = action.payload.data.data;
      })
      .addCase(fetchDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Doctor create
      .addCase(addNewDoctor.pending, (state) => {
        state.savingDoctor = true;
        state.error = "";
      })
      .addCase(addNewDoctor.fulfilled, (state) => {
        state.savingDoctor = false;
      })
      .addCase(addNewDoctor.rejected, (state, action) => {
        state.savingDoctor = false;
        state.error = action.error.message;
      })

      // Doctor Profile
      .addCase(fetchDoctorById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchDoctorById.fulfilled, (state, action) => {
        state.loading = false;
        state.doctor = action?.payload?.data?.data;
      })
      .addCase(fetchDoctorById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Doctor Profile
      .addCase(updateDocter.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateDocter.fulfilled, (state, action) => {
        state.loading = false;
        state.doctor = action?.payload?.data?.data;
      })
      .addCase(updateDocter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Doctor Slot Config
      .addCase(addDoctorSlotConfig.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(addDoctorSlotConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.slot_id = action.payload?.data?.data?.id;
      })
      .addCase(addDoctorSlotConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Doctor Slot Procedure
      .addCase(addDoctorSlotProcedure.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(addDoctorSlotProcedure.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addDoctorSlotProcedure.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Doctor Slot Procedure
      .addCase(fetchDoctorSlots.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchDoctorSlots.fulfilled, (state, action) => {
        state.docter_slots_config = action.payload?.data?.data;
        state.loading = false;
      })
      .addCase(fetchDoctorSlots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update Slot Config
      .addCase(updateDoctorSlotConfig.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateDoctorSlotConfig.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateDoctorSlotConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // fetchDoctorAvailSlots
      .addCase(fetchDoctorAvailSlots.pending, (state) => {
        state.slots_loader = true;
        state.error = "";
      })
      .addCase(fetchDoctorAvailSlots.fulfilled, (state, action) => {
        state.slots_loader = false;
        state.doctor_avail_slots = action.payload?.data?.data;
      })
      .addCase(fetchDoctorAvailSlots.rejected, (state, action) => {
        state.slots_loader = false;
        state.error = action.error.message;
      })

      // fetchDoctorCoordinator
      .addCase(fetchDoctorCoordinator.pending, (state) => {
        state.slots_loader = true;
        state.error = "";
      })
      .addCase(fetchDoctorCoordinator.fulfilled, (state, action) => {
        state.slots_loader = false;
        state.coordinators = action.payload?.data?.data?.items;
      })
      .addCase(fetchDoctorCoordinator.rejected, (state, action) => {
        state.slots_loader = false;
        state.error = action.error.message;
      })

      // fetchDoctorProperties
      .addCase(fetchDoctorProperties.pending, (state) => {
        state.slots_loader = true;
        state.error = "";
      })
      .addCase(fetchDoctorProperties.fulfilled, (state, action) => {
        state.slots_loader = false;
        state.doctor_props = action.payload?.data?.data;
      })
      .addCase(fetchDoctorProperties.rejected, (state, action) => {
        state.slots_loader = false;
        state.error = action.error.message;
      })

      // Doctor specialization
      .addCase(fetchSpecialization.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchSpecialization.fulfilled, (state, action) => {
        state.loading = false;
        state.specialization = action?.payload?.data?.data;
      })
      .addCase(fetchSpecialization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Doctor Props
      .addCase(fetchDoctorPropsById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchDoctorPropsById.fulfilled, (state, action) => {
        state.loading = false;
        state.doctorProps = action?.payload?.data?.data;
      })
      .addCase(fetchDoctorPropsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Doctor  Procedure
      .addCase(fetchDoctorProcedureConfig.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchDoctorProcedureConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.procedureList = action.payload?.data?.data;
      })
      .addCase(fetchDoctorProcedureConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetDoctor, resetDoctorAvailSlots } = docterSlice.actions;

export default docterSlice.reducer;
