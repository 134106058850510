import { store } from './redux/store';

import { Amplify, Auth } from "aws-amplify";
import moment from 'moment';
import { setToken } from './redux/reducers/auth.slice';
import { ACCOUNT_PREFIX, MAX_COGNITO_REFRESH_TIMES } from "./shared/constants";
const data = localStorage.getItem("config");
const allData = JSON.parse(data || "{}");

const AWS_Cognito_Config = allData.AWS_COGNITO_CONFIG || null;
if (AWS_Cognito_Config?.aws_cognito_identity_pool_id) {
  Amplify.configure(AWS_Cognito_Config);
  Amplify.Logger.LOG_LEVEL = "DEBUG";
  currentSession();

  function currentSession() {
    setTimeout(() => {
      if (localStorage.getItem("token") && localStorage.getItem("auth-type")) {
        localStorage.getItem("auth-type") === "cognito" &&
          refreshCognitoSession()
      }
      currentSession();
    }, 50 * 60 * 60);
  }
  if (localStorage.getItem("auth-type") === "cognito") {
    const func = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        refreshCognitoSession();
      } catch {
        const slug = localStorage?.getItem('account_slug');
        window.localStorage.clear();
        window.location.href = window.location.origin + "/" + (ACCOUNT_PREFIX || slug);
      }
    };
    func();

  }
}
export const refreshCognitoSession = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentValidity = sessionStorage.getItem('cognito-validity');
  const cognitoRefreshTimes = sessionStorage.getItem('cognito-refresh-times');
  if (currentValidity && cognitoRefreshTimes < MAX_COGNITO_REFRESH_TIMES) {
    const timeLeft = moment(parseInt(currentValidity) * 1000).diff(moment());
    if (timeLeft < 10 * 60 * 1000) {
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        const { idToken, refreshToken, accessToken } = session;
        const exp = idToken?.payload?.exp;
        const iat = idToken?.payload?.iat;
        sessionStorage.setItem('cognito-validity', exp);
        sessionStorage.setItem('cognito-timestamp', iat);
        const oldTime = sessionStorage.getItem('cognito-refresh-times');
        let newTime = 0;
        if (oldTime) {
          newTime = parseInt(oldTime) + 1;
        }
        sessionStorage.setItem('cognito-refresh-times', newTime);
        localStorage.setItem("token", idToken.jwtToken);
        store.dispatch(setToken(idToken.jwtToken))
      });
    }
  }
}
