import {
  Affix,
  Col,
  Drawer,
  Input,
  message,
  notification,
  Row,
  Skeleton,
  Tag,
} from "antd";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { fetchMessages } from "../../redux/reducers/chat.slice";
import {
  fetchPatientDocumentFileById,
  fetchUploadedFileById,
} from "../../redux/reducers/upload.slice";
import { RootState } from "../../shared/constants";
import { getChatDateFormat, validateToken } from "../Utils/utilities";
import FileViewLayout from "./file-view.layout";
import PatientNameCard from "./name-card";
import NoDataLayout from "./no-data.layout";
var _ = require("lodash");

function ChatLayout({
  show,
  chat_room_id,
  details,
  setChat,
  type,
  loginUser,
  isDrawer,
  appointment,
  allData,
  allMessages,
  is100MS,
  allRooms,
  chatType,
  patientMobile,
  source,
  is_admin,
}: any) {
  const dispatch = useDispatch();
  const [_message, setMessage] = useState("" as any);
  const { profile } = useSelector((state: RootState) => state.profile);
  const { account } = useSelector((state: RootState) => state.auth);
  const { messages, loading, ischatAllow, connection } = useSelector(
    (state: RootState) => state.chat
  );
  const [fileUpload, setFileUpload] = useState(false as any);
  const { signedUrl } = useSelector((state: RootState) => state.upload);
  const [disabledChatBox, setdisabledChatBox] = useState(false);
  const [file, setFile] = useState(null as any);
  const [pendingPatientChatCount, setPendingPatientChatCount] = useState(
    null as any
  );
  const [fullConvesation, setFullConvesation] = useState([] as any);
  const wsCurrent = connection;
  const ws = useRef(null as any);
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };

  const PatientDetails = allData?.filter((item: any) => {
    return item?.chat_room_id === chat_room_id;
  });

  const params = {
    account_id: account?.id,
    application_id: localStorage?.getItem("application_id"),
    token: localStorage?.getItem("token"),
  };

  useEffect(() => {
    // messages?.length === 0 &&
    getMessages();
  }, [chat_room_id]);

  const getMessages = async () => {
    if (loginUser === "agent") {
      const parms = { ...params };
      await dispatch(
        fetchMessages({
          parms,
          user_type: "agent",
          user_id: profile?.id,
          room_type: "all",
          chat_room_id: chat_room_id,
        })
      );
    }
  };

  // useEffect(() => {
  //   const wsCurrent = connection;
  //   if (!wsCurrent) return;
  //   wsCurrent.onmessage = (e: any) => {
  //     const _message = JSON.parse(e.data);
  //     console.log("_message", _message)
  //     getMessages()

  //   };
  // });

  console.log("messages", disabledChatBox);

  useEffect(() => {
    setFullConvesation(messages.slice(0).reverse());
    setTimeout(() => {
      var element = document.getElementById("box") as any;
      element &&
        element?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
    }, 1000);
  }, [messages]);

  useEffect(() => {
    const currentTime = new Date() as any;

    const lastMsgTimeStr = messages?.length && messages[0]?.date_t_time;
    const lastMsgTime = new Date(lastMsgTimeStr) as any;

    // Step 3: Calculate the time difference in milliseconds
    let timeDifferenceInMilliseconds = currentTime - lastMsgTime;

    // Step 4: Convert milliseconds to hours
    const timeDifferenceInHours =
      timeDifferenceInMilliseconds / (1000 * 60 * 60);
    console.log("timeDifferenceInHours", Math.round(timeDifferenceInHours));

    setdisabledChatBox(timeDifferenceInHours > 23);
  }, [messages]);

  useEffect(() => {
    let _newchat = [] as any;
    _newchat = allMessages;
    let _oldChat = messages.slice(0).reverse();
    setFullConvesation(_oldChat.concat(_newchat));
    setTimeout(() => {
      var element = document.getElementById("box") as any;
      element &&
        element?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
    }, 1000);
    console.log("timeDifferenceInHours sssssssssss", allMessages[0]);
    if (chat_room_id === allMessages[0]?.chat_room_id) {
      setdisabledChatBox(false);
    }
    return () => {
      _newchat = [];
    };
  }, [allMessages]);

  useEffect(() => {
    let interval = setInterval(() => {
      sendMessage(true);
      // alert('hi')
    }, 1000 * 60);
    return () => {
      clearInterval(interval);
    };
  }, []);

  console.log("chat_room_id", chat_room_id);

  const sendMessage = async (keep_alive = false) => {
    if (_message === "" && keep_alive === false) {
      message.error("Enter Message");
      return;
    }
    let data = {
      action: "sendMessage",
      message: keep_alive ? "" : _message,
      type: keep_alive ? "keep_alive" : "new_msg",
      user_id: profile?.id,
      account_id: account?.id,
      application_id: localStorage.getItem("application_id"),
      token: localStorage.getItem("token"),
      source: source,
      user_name: profile?.name,
      message_type: "text",
      role: "agent" || loginUser,
      mobile: patientMobile,
      chat_room_id: chat_room_id,
    };

    if (connection.readyState) {
      connection?.send(JSON.stringify(data));
    }

    if (keep_alive === false) {
      setMessage("");
    }
    if (pendingPatientChatCount !== null) {
      setPendingPatientChatCount(pendingPatientChatCount - 1);
    }
    var element = document.getElementById("box") as any;
    element &&
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  };

  const viewFile = (message: any) => {
    (async function () {
      try {
        let response;
        let data;
        if (message.role === "user") {
          data = {
            type: type,
            id: message.document_id,
          };
          response = dispatch(fetchUploadedFileById(data));
        } else {
          response = dispatch(
            fetchPatientDocumentFileById(message.document_id)
          );
        }
        if (
          response.payload.status === 201 ||
          response.payload.status === 200
        ) {
          setFileUpload(true);
          setFile(message);
        }
      } catch (error) {}
    })();
  };

  let chats = _.groupBy(fullConvesation, "date");
  let tokenExpiredErrorDisplayed = false;

  const onPressEnterChat = () => {
    if (!validateToken()) {
      if (!tokenExpiredErrorDisplayed) {
        tokenExpiredErrorDisplayed = true;
        notification.error({ message: "Session Expired" });

        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/";
        }, 500);
      }
      return;
    } else {
      sendMessage();
    }
  };

  return (
    <Fragment>
      {is100MS ? (
        ""
      ) : !isDrawer ? (
        <Row className={"padding20"}>
          <Col span={24} className="doctor-box-shadow doctor-top-border ">
            <Row>
              <Col span={24} className="doctor-color-heading2">
                <Row>
                  <Col span={24} className={""}>
                    {/* <span className="doctor-primary-color font15 bold">
                      {'+ '}{PatientDetails[0]?.patient_name}
                    </span>
                    <p>
                      Last Msg:{" "}
                      {PatientDetails && PatientDetails.length > 0 && formatDate(PatientDetails[0]?.user_last_msg_time)}
                    </p> */}
                  </Col>
                </Row>
              </Col>

              <Col
                span={24}
                className={"white-background padding20"}
                style={{ height: "80vh", overflowY: "scroll" }}
              >
                {loading ? (
                  <Skeleton></Skeleton>
                ) : fullConvesation.length === 0 ? (
                  <NoDataLayout
                    placeholder={"No messages found. Start new conversation"}
                    className={""}
                  ></NoDataLayout>
                ) : (
                  Object.keys(chats).map((_message: any) => {
                    return (
                      <>
                        <p className={"text-center"}>
                          <Tag>
                            {/* className={" date-tag"} */}
                            {getChatDateFormat(_message)}
                          </Tag>
                        </p>
                        {chats[_message]
                          .filter((message: any) => {
                            return (
                              message?.chat_room_id === chat_room_id &&
                              (message?.message !== "" ||
                                message?.message !== null ||
                                message?.message !== undefined)
                              // (message?.message !== "" || message?.message !== null || message?.message !== undefined)
                            );
                          })
                          .map((message: any, index: any) => {
                            if (message?.message_type === "text") {
                              if (message?.role === "agent") {
                                return (
                                  message?.message && (
                                    <Row key={index + 1}>
                                      <Col span={24}>
                                        <p className="right-message">
                                          {message?.message}
                                          <small
                                            style={{
                                              float: "right",
                                              marginLeft: "21px",
                                              opacity: "0.5",
                                            }}
                                          >
                                            {moment(
                                              message?.date_t_time
                                            ).format("LT")}
                                          </small>
                                        </p>
                                      </Col>
                                    </Row>
                                  )
                                );
                              } else {
                                return (
                                  message?.message && (
                                    <Row key={index + 1}>
                                      <Col span={24}>
                                        <p className="left-message">
                                          {message?.message}
                                          <small
                                            style={{
                                              float: "right",
                                              marginLeft: "21px",
                                              opacity: "0.5",
                                            }}
                                          >
                                            {moment(
                                              message?.date_t_time
                                            ).format("LT")}
                                          </small>
                                        </p>
                                      </Col>
                                    </Row>
                                  )
                                );
                              }
                            }

                            if (message.message_type === "document") {
                              if (profile.id === message?.user_id) {
                                return (
                                  <Row key={index + 1}>
                                    <Col span={24}>
                                      <p
                                        className="right-message cursor"
                                        onClick={() => viewFile(message)}
                                      >
                                        View File
                                      </p>
                                    </Col>
                                  </Row>
                                );
                              } else {
                                return (
                                  <Row key={index + 1}>
                                    <Col span={24}>
                                      <p
                                        className="left-message cursor"
                                        onClick={() => viewFile(message)}
                                      >
                                        View File
                                      </p>
                                    </Col>
                                  </Row>
                                );
                              }
                            }
                            return <></>;
                          })}
                      </>
                    );
                  })
                )}

                <div id="box"></div>
              </Col>
              <Col span={24}>
                <Affix offsetBottom={0}>
                  <Row className={"full-width doctor-color-heading3"}>
                    <Col span={24} className="nopadding ">
                      {chatType !== "admin" && (
                        <Row>
                          {/* {!ischatAllow ? (
                          <Col span={24}>
                            <span style={{ color: "red" }}>
                              {" "}
                              {loginUser === "agent"
                                ? "Chat with patient is disabled. Enable it in settings"
                                : "Chat with doctor is disabled"}{" "}
                            </span>
                          </Col>
                        ) : (
                          ""
                        )} */}
                          <Col span={22}>
                            <Input
                              value={_message}
                              // placeholder={
                              //   ischatAllow
                              //     ? "Type a message"
                              //     : "Chat is not allowed"
                              // }
                              // disabled={!ischatAllow || loading}
                              disabled={disabledChatBox}
                              onChange={(evt: any) =>
                                setMessage(evt.target.value)
                              }
                              onPressEnter={onPressEnterChat}
                              size={"large"}
                              // suffix={<UploadLayout setFileData={(file: any) => setFileData(file, type)} fileData={[]} text={<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              //     <path fillRule="evenodd" clipRule="evenodd" d="M12.088 1.20976C13.7561 -0.561768 16.2444 -0.287079 17.8134 1.37974C19.3824 3.04643 19.6416 5.68984 17.9734 7.46201L8.06864 17.9839C7.06385 19.0513 5.42465 19.0513 4.41987 17.9839C3.41508 16.9165 3.41508 15.1752 4.41987 14.1078L11.1295 6.97966C11.4312 6.65914 11.9204 6.65912 12.2221 6.97963C12.5239 7.30014 12.5239 7.8198 12.2222 8.14033L5.51251 15.2685L5.51249 15.2685C5.11114 15.6948 5.11114 16.3968 5.51249 16.8232C5.91384 17.2495 6.57467 17.2495 6.97602 16.8232L16.8808 6.30131C17.8486 5.27311 17.788 3.67411 16.7208 2.54044C15.6538 1.40689 14.1485 1.34261 13.1805 2.37063L2.63687 13.5712C1.18131 15.1174 1.18131 17.6526 2.63687 19.1988C4.09243 20.7451 6.47887 20.7451 7.93443 19.1988L18.4785 7.99778C18.7802 7.67726 19.2694 7.67726 19.5711 7.99778C19.8728 8.31829 19.8728 8.83796 19.5711 9.15848L9.02705 20.3595C6.96805 22.5468 3.60325 22.5468 1.54425 20.3595C-0.51475 18.1722 -0.51475 14.5978 1.54425 12.4105L12.0879 1.20984L12.088 1.20976Z" fill="#4DA9D0" />
                              // </svg>
                              // }></UploadLayout>}
                              style={{ borderRadius: "50px", width: "100%" }}
                            ></Input>
                          </Col>
                          <Col
                            span={2}
                            className={"center"}
                            style={{ textAlign: "center" }}
                          >
                            <svg
                              width="33"
                              className="cursor mt5"
                              onClick={() => sendMessage()}
                              height="28"
                              viewBox="0 0 33 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity={1}
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 19.7166C0 18.504 0.778993 17.4287 1.93116 17.0508L10.7829 14.1474L1.93115 11.244C0.778991 10.866 0 9.7907 0 8.57814V0L33 14L0 28V19.7166Z"
                                fill={_message !== "" ? "#23BCB5" : "#C8CBD4"}
                              />
                            </svg>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Affix>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Drawer
          footerStyle={{
            backgroundColor: "rgb(193, 218, 232)",
            padding: "0px",
          }}
          bodyStyle={{ background: "#E0EBF1" }}
          visible={show}
          closable={false}
          title={
            <Row className="white-background ">
              <Col span={24} className="mt5 paddingLR20">
                <PatientNameCard
                  type={type}
                  showBack={true}
                  onBack={(value: any) => setChat(value)}
                  details={details}
                ></PatientNameCard>
              </Col>
            </Row>
          }
          className={"chat drawerChat"}
          width={screenType.desktop ? "30%" : "100%"}
          footer={
            <Col
              span={24}
              style={{ backgroundColor: "#C1DAE8", padding: "10px 16px" }}
            >
              <Row>
                {!ischatAllow ? (
                  <Col span={24}>
                    <span style={{ color: "red" }}>
                      {" "}
                      {loginUser === "agent"
                        ? "Chat with patient is disabled. Enable it in settings"
                        : "Chat with doctor is disabled"}{" "}
                    </span>
                  </Col>
                ) : (
                  ""
                )}
                <Col span={21}>
                  <Input
                    value={_message}
                    placeholder={
                      ischatAllow ? "Type a message" : "Chat is not allowed"
                    }
                    disabled={!ischatAllow || loading}
                    onChange={(evt: any) => setMessage(evt.target.value)}
                    onPressEnter={() => sendMessage()}
                    size={"large"}
                    // suffix={<UploadLayout setFileData={(file: any) => setFileData(file, type)} fileData={[]} text={<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //     <path fillRule="evenodd" clipRule="evenodd" d="M12.088 1.20976C13.7561 -0.561768 16.2444 -0.287079 17.8134 1.37974C19.3824 3.04643 19.6416 5.68984 17.9734 7.46201L8.06864 17.9839C7.06385 19.0513 5.42465 19.0513 4.41987 17.9839C3.41508 16.9165 3.41508 15.1752 4.41987 14.1078L11.1295 6.97966C11.4312 6.65914 11.9204 6.65912 12.2221 6.97963C12.5239 7.30014 12.5239 7.8198 12.2222 8.14033L5.51251 15.2685L5.51249 15.2685C5.11114 15.6948 5.11114 16.3968 5.51249 16.8232C5.91384 17.2495 6.57467 17.2495 6.97602 16.8232L16.8808 6.30131C17.8486 5.27311 17.788 3.67411 16.7208 2.54044C15.6538 1.40689 14.1485 1.34261 13.1805 2.37063L2.63687 13.5712C1.18131 15.1174 1.18131 17.6526 2.63687 19.1988C4.09243 20.7451 6.47887 20.7451 7.93443 19.1988L18.4785 7.99778C18.7802 7.67726 19.2694 7.67726 19.5711 7.99778C19.8728 8.31829 19.8728 8.83796 19.5711 9.15848L9.02705 20.3595C6.96805 22.5468 3.60325 22.5468 1.54425 20.3595C-0.51475 18.1722 -0.51475 14.5978 1.54425 12.4105L12.0879 1.20984L12.088 1.20976Z" fill="#4DA9D0" />
                    // </svg>
                    // }></UploadLayout>}
                    style={{ borderRadius: "50px", width: "100%" }}
                  ></Input>
                </Col>
                <Col
                  span={3}
                  className={"center"}
                  style={{ textAlign: "center" }}
                >
                  <svg
                    width="33"
                    className="cursor mt5"
                    onClick={() => sendMessage()}
                    height="28"
                    viewBox="0 0 33 28"
                    fill={"none"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity={"1"}
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 19.7166C0 18.504 0.778993 17.4287 1.93116 17.0508L10.7829 14.1474L1.93115 11.244C0.778991 10.866 0 9.7907 0 8.57814V0L33 14L0 28V19.7166Z"
                      fill={_message !== "" ? "#23BCB5" : "#C8CBD4"}
                    />
                  </svg>
                </Col>
              </Row>
            </Col>
          }
        >
          <Row className="mt50">
            <Col span={24}>
              {loading ? (
                <Skeleton></Skeleton>
              ) : fullConvesation.length === 0 ? (
                <NoDataLayout
                  placeholder={"No messages found. Start new conversation"}
                  className={""}
                ></NoDataLayout>
              ) : (
                Object.keys(chats).map((_message: any) => {
                  return (
                    <>
                      <p className={"text-center"}>
                        <Tag>
                          {/* className={" date-tag"} */}
                          {getChatDateFormat(_message)}
                        </Tag>
                      </p>
                      {chats[_message]
                        ?.filter((message: any) => {
                          if (loginUser === "agent") {
                            return (
                              message?.doctor_id === profile?.id &&
                              message?.patient_id === details?.id
                            );
                          } else {
                            return (
                              message?.patient_id === profile?.id &&
                              message?.doctor_id === details?.id
                            );
                          }
                        })
                        .map((message: any, index: any) => {
                          if (message.message_type === "text") {
                            if (profile.id === message.user_id) {
                              return (
                                <Row key={index + 1}>
                                  <Col span={24}>
                                    <p className="right-message">
                                      {message.message}
                                      <small
                                        style={{
                                          float: "right",
                                          marginLeft: "21px",
                                          opacity: "0.5",
                                        }}
                                      >
                                        {moment(message.date_t_time).format(
                                          "LT"
                                        )}
                                      </small>
                                    </p>
                                  </Col>
                                </Row>
                              );
                            } else {
                              return (
                                <Row key={index + 1}>
                                  <Col span={24}>
                                    <p className="left-message">
                                      {message.message}
                                      <small
                                        style={{
                                          float: "right",
                                          marginLeft: "21px",
                                          opacity: "0.5",
                                        }}
                                      >
                                        {moment(message.date_t_time).format(
                                          "LT"
                                        )}
                                      </small>
                                    </p>
                                  </Col>
                                </Row>
                              );
                            }
                          }
                          if (message.message_type === "document") {
                            if (profile.id === message.user_id) {
                              return (
                                <Row key={index + 1}>
                                  <Col span={24}>
                                    <p
                                      className="right-message cursor"
                                      onClick={() => viewFile(message)}
                                    >
                                      View File
                                    </p>
                                  </Col>
                                </Row>
                              );
                            } else {
                              return (
                                <Row key={index + 1}>
                                  <Col span={24}>
                                    <p
                                      className="left-message cursor"
                                      onClick={() => viewFile(message)}
                                    >
                                      View File
                                    </p>
                                  </Col>
                                </Row>
                              );
                            }
                          }
                          return <></>;
                        })}
                    </>
                  );
                })
              )}

              <div id="box"></div>
            </Col>
          </Row>
          {fileUpload && (
            <FileViewLayout
              details={file}
              fileviewer={fileUpload}
              setFileViewer={() => setFileUpload(false)}
              file={signedUrl}
            ></FileViewLayout>
          )}
        </Drawer>
      )}
    </Fragment>
  );
}

export default ChatLayout;
