import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Drawer,
  Empty,
  Form,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  fetchAppointmentById,
  resetAppointments,
} from "../../../../redux/reducers/appointment.slice";
import {
  fetchMasterCategories,
  fetchMasterTaskSubTypes,
  fetchMasterTaskTypes,
} from "../../../../redux/reducers/master.slice";
import { fetchMedicinesById } from "../../../../redux/reducers/pharmacy.slice";
import {
  fetchNpsDetails,
  fetchTicketsDetails,
  updateTicket,
} from "../../../../redux/reducers/tickets.slice";
import {
  TASK_MODULE_ACTIONS_EDIT_TICKET_MODULE,
  TASK_MODULE_ACTIONS_EDIT_TICKET_TYPE,
  TASK_MODULE_ACTIONS_HIDE_TITLE,
  TASK_MODULE_ACTIONS_SHOW_ATTACHMENT_TICKET_DETAILS_TAB,
  TASK_MODULE_ACTIONS_VIEW_CHAT_HISTORY,
  TASK_MODULE_ACTIONS_VIEW_NPS_DETAILS
} from "../../../../shared/components/permission.constants";
import {
  ACCOUNT_PREFIX,
  IS_CIPLA,
  RootState,
  TICKET_EXTERNAL_ID_VISIBLE,
} from "../../../../shared/constants";
import { ListView } from "../../../../shared/layouts/crm-listcard.layout";
import PlaceOrderComponent from "../../../../shared/layouts/pharmacy-place-order.layout";
import TimelineLayout from "../../../../shared/layouts/timeline.layout";
import {
  formatDropDownValue,
  metaValues,
} from "../../../../shared/Utils/utilities";
import PatientJourney from "../../../dependencies/patient-journey";
import PatientMessageHistory from "../../../dependencies/patient-message-history";
import SelectedMedicine from "../../../dependencies/selected-medicine.component";
import GeneralTicketActions from "../../components/ticket-general-drawers";
import UpdateTicketForm from "./updateticketform";
const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

type Props = {
  ticket: any;
  actionmapData?: any;
  auditStatus?: any;
  npsDetailss?: any;
};
function TicketDetailsGeneralTab({
  ticket,
  actionmapData,
  auditStatus,
  npsDetailss,
}: Props) {
  const dispatch = useDispatch();
  const { appointment } = useSelector((state: RootState) => state.appointment);
  const [selectedService, setSelectedService] = useState(
    actionmapData?.ui_actions_dropdown
      ? (actionmapData?.ui_actions_dropdown[0] as any)
      : null
  );
  const [initAction, setInitAction] = useState(false as any);
  const [appointmentRequestStatus, setAppointmentRequestStatus] = useState(
    null as any
  );
  const { t } = useTranslation();
  const [editTicketTypeModal, seteditTicketTypeModal] = useState(false);
  const [showChatModal, setshowChatModal] = useState(false);
  const [showNPSModal, setshowNPSModal] = useState(false);
  const { taskTypes, taskSubTypes, taskCategories, taskDropDown } = useSelector(
    (state: RootState) => state.master
  );
  const [type_id, setType_id] = useState(null as any);
  const [sub_type_id, setSubType_id] = useState(null as any);
  const [initials, setinitials] = useState(null as any);

  const [audit, setAuditTrail] = useState(false as any);
  const [isReset, setIsReset] = useState(null as any);
  const [newOrderAuditTrail, setNewOrderAuditTrail] = useState("" as any);
  const [pharmacyDetails, setPharmacyDetails] = useState({} as any);
  const [npsDetails, setnpsDetails] = useState(null as any);
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  useEffect(() => {
    return () => {
      dispatch(resetAppointments());
    };
  }, []);

  const [form] = useForm();

  const onFinishTicket = (values: any) => {
    dispatch(fetchTicketsDetails(ticket?.id));
  };
  useEffect(() => {
    if (ticket?.nps_id) {
      dispatch(
        fetchNpsDetails({
          nps_id: ticket?.nps_id,
          branch_id: ticket?.branch_id,
        })
      ).then((val: any) => {
        const setval = val.payload.data.data;
        setnpsDetails(setval);
        npsDetailss(setval);
      });
    }
  }, [ticket]);

  useEffect(() => {
    dispatch(fetchMasterTaskTypes({ mode: "ticket" }));
  }, []);

  let serviceList: any = [];
  if (actionmapData?.ui_actions_dropdown) {
    serviceList = actionmapData.ui_actions_dropdown.map((val: any) => {
      return val.toLowerCase();
    });
  }

  const [ticketDetails, setticketDetails] = useState([] as any);
  const chatHistoryButton = {
    title: "Chat History",
    text: (
      <>
        <p>
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => {
              setshowChatModal(true);
            }}
          >
            <strong>View Chat History</strong>
          </Button>
        </p>
      </>
    ),
    type: "text",
    path: "",
  };

  const editTicketDetailsButton = {
    title: ".",
    text: (
      <>
        <p>
          <Button
            style={{ padding: 0 }}
            onClick={() => {
              seteditTicketTypeModal(true);
            }}
            type="link"
            disabled={
              ticket?.allowed_permission &&
                ticket?.allowed_permission?.update_ticket_view === false
                ? true
                : false
            }
          >
            <strong>
              Edit {`${t("CRM.Tickets.Fields.Ticket")} Details`}
            </strong>
          </Button>
        </p>
      </>
    ),
    type: "text",
    path: "",
  };


  useEffect(() => {
    setticketDetails([
      ...(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_TITLE) !== -1
        ? []
        : [
          {
            title: "Title",
            text: ticket.title ? ticket.title : "--",
            type: "text",
          },
        ]),

      {
        title: t("CRM.Tickets.Fields.Ticket Type"),
        text: ticket?.type?.name
          ? formatDropDownValue(ticket?.type?.name)
          : "--",
        type: "text",
      },
      {
        title: t("CRM.Tickets.Fields.Ticket Sub Type"),
        text: ticket?.sub_type?.name
          ? formatDropDownValue(ticket?.sub_type?.name)
          : "--",
        type: "text",
      },
      {
        title: t("CRM.Tickets.Fields.Ticket Category"),
        text: ticket?.category?.name
          ? formatDropDownValue(ticket?.category?.name)
          : "--",
        type: "text",
        path: "",
      },
      {
        title: `${t("CRM.Tickets.Fields.Ticket")} Source`,
        text: ticket?.source?.name
          ? formatDropDownValue(ticket?.source?.name)
          : "--",
        type: "text",
        path: "",
      },
      ...(TICKET_EXTERNAL_ID_VISIBLE === true
        ? [
          {
            title: "External Id",
            text: ticket?.external_id ? ticket?.external_id : "--",
            type: "text",
            // path: "",
          },
        ]
        : []),
      // ...(ticket?.meta?.connect_time
      //   ? [
      //       {
      //         title: "Connect Time",
      //         text: ticket?.meta?.connect_time,
      //         type: "text",
      //         // path: "",
      //       },
      //     ]
      //   : []),
      {
        title: "Description",
        text: (
          <>
            {ticket?.services[0]?.meta &&
              Object.keys(ticket?.services[0]?.meta).length &&
              Object.entries(ticket?.services[0]?.meta).map(
                ([key, value]: any) => {
                  if (metaValues?.filter((e) => e.value === key).length > 0) {
                    //metaValues?.includes(key)
                    if (
                      value !== "" &&
                      value !== null &&
                      typeof value !== "object"
                    ) {
                      if (
                        metaValues?.find(
                          (es) => es.value == key && es.show === true
                        ) &&
                        value
                      ) {
                        if (key === "referedAt") {
                          if (ACCOUNT_PREFIX === "regency") {
                            return (
                              <p
                                style={{
                                  textAlign: "left",
                                  marginBottom: "3px",
                                }}
                              >
                                <span>
                                  <b>Raised at :</b>{" "}
                                  {moment
                                    .utc(value)
                                    .utcOffset("+05:30")
                                    .format("DD MMM'YY, hh:mm A")}
                                </span>
                                <br />
                              </p>
                            );
                          } else {
                            return (
                              <p
                                style={{
                                  textAlign: "left",
                                  marginBottom: "3px",
                                }}
                              >
                                <span>
                                  <b>
                                    {metaValues
                                      ?.filter((e) => e.value === key)
                                      .map((val: any) => {
                                        return val.label;
                                      })}{" "}
                                    :{" "}
                                  </b>{" "}
                                  {moment
                                    .utc(value)
                                    .utcOffset("+05:30")
                                    .format("DD MMM'YY, hh:mm A")}
                                </span>
                                <br />
                              </p>
                            );
                          }
                        } else {
                          return (
                            <p
                              style={{ textAlign: "left", marginBottom: "3px" }}
                            >
                              <span>
                                <b>
                                  {metaValues
                                    ?.filter((e) => e.value === key)
                                    .map((val: any) => {
                                      return val.label;
                                    })}{" "}
                                  :{" "}
                                </b>{" "}
                                {value}
                              </span>
                              <br />
                            </p>
                          );
                        }
                      }
                    }
                  }
                }
              )}
            {typeof ticket?.description?.description === "string" &&
              ticket?.description?.description?.length > 200 ? (
              <>
                {ticket?.description?.description.substring(0, 200)}
                {ticket?.description?.description?.length > 200 && (
                  <span>
                    {" "}
                    <Popover
                      overlayStyle={{ width: "400px" }}
                      showArrow
                      placement="left"
                      content={ticket?.description?.description}
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </span>
                )}
              </>
            ) : (
              ticket?.description?.description
            )}
          </>
        ),
        // npsDetails ? (
        //   <>
        //     {
        //       <p style={{ textAlign: "left" }}>
        //         <span>
        //           <b>NPS:</b> {npsDetails?.overallscore}/10
        //         </span>
        //         <br />
        //       </p>
        //     }

        //     {npsDetails?.tags &&
        //       Object.entries(npsDetails?.tags).map(([key, value]: any) => {
        //         return (
        //           <p style={{ textAlign: "left", marginBottom: "3px" }}>
        //             <span>
        //               <b>{key} - </b>
        //             </span>
        //             <span>{value.join(", ")}</span>
        //             <br />
        //           </p>
        //         );
        //       })}
        //     {
        //       <p style={{ textAlign: "left", marginBottom: "3px" }}>
        //         <span>
        //           <b>Comments: </b> {npsDetails?.comment}
        //         </span>
        //         <br />
        //       </p>
        //     }
        //   </>
        // ) :

        type: "text",
      },

      ...(IS_CIPLA === true && ticket?.source?.name === "whatsapp_bot"
        ? [chatHistoryButton]
        : ticket?.source?.name === "whatsapp_bot" &&
          myPermissions?.indexOf(TASK_MODULE_ACTIONS_VIEW_CHAT_HISTORY) !== -1
          ? [chatHistoryButton]
          : []),
      ...(myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_VIEW_NPS_DETAILS) !== -1
        ? [
          {
            title: "NPS Details",
            text: (
              <>
                <p>
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => {
                      setshowNPSModal(true);
                    }}
                  >
                    <strong>NPS Details</strong>
                  </Button>
                </p>
              </>
            ),
            type: "text",
            path: "",
          },
        ]
        : []),

      ...(IS_CIPLA === true && ticket?.source?.name === "email"
        ? [editTicketDetailsButton]
        : ticket?.source?.name === "email" &&
          myPermissions?.indexOf(TASK_MODULE_ACTIONS_EDIT_TICKET_TYPE) !== -1
          ? [editTicketDetailsButton]
          : []),

      ...((myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(
          TASK_MODULE_ACTIONS_SHOW_ATTACHMENT_TICKET_DETAILS_TAB
        ) !== -1) &&
        ticket?.files?.length > 0
        ? [
          {
            title: "Attachment",
            text:
              ticket?.files?.length > 0
                ? ticket?.files?.map((val: any, index: any) => {
                  return (
                    <>
                      <p className="mb-2">
                        <a href={val?.url} target="_blank" rel="noreferrer">
                          View:
                          {val?.file?.file_name}
                        </a>
                      </p>
                    </>
                  );
                })
                : "--",
            type: "text",
          },
        ]
        : []),
    ]);
  }, [ticket]);

  useEffect(() => {
    if (ticket) {
      setType_id(ticket?.type_id);
      setSubType_id(ticket?.sub_type_id);

      ticket?.type_id &&
        dispatch(fetchMasterTaskSubTypes({ task_type_id: ticket?.type_id }));
      ticket?.sub_type_id &&
        dispatch(
          fetchMasterCategories({ task_sub_type_id: ticket?.sub_type_id })
        );
    }
  }, [ticket]);

  const getSubCategory = (type: any) => {
    setType_id(type);
    type && dispatch(fetchMasterTaskSubTypes({ task_type_id: type }));

    form.setFieldsValue({ sub_type_id: null });
    form.setFieldsValue({ category_id: null });
  };

  const getCategory = (type: any) => {
    setSubType_id(type);
    type && dispatch(fetchMasterCategories({ task_sub_type_id: type }));
    form.setFieldsValue({ category_id: null });
  };

  const timelineDetails = ticket?.history;
  function serviceInitAction() {
    if (
      selectedService == "cancel_appointment" &&
      appointment?.latest_status == "cancelled"
    ) {
      message.error("Cannot cancel appointment.");
      return;
    }
    if (
      selectedService == "reschedule_appointment" &&
      appointment?.latest_status == "cancelled"
    ) {
      message.error("Cannot reschedule appointment.");
      return;
    }
    // if(selectedService == 'reschedule_appointment' && !appointment?.enable_reschedule){
    //     message.error('Cannot reschedule appointment.')
    //     return
    // }
    setInitAction(selectedService);
  }
  const serviceMenu = (val: any, placeholder: string) => {
    return (
      <Select
        placeholder={placeholder}
        defaultValue={serviceList[0]}
        style={{ width: "100%" }}
        onChange={(e) => {
          setSelectedService(e);
          setInitAction(false);
        }}
      >
        {val.map((v: any, index: number) => {
          return (
            <Option key={index} value={v}>
              <small>{formatDropDownValue(v)}</small>
            </Option>
          );
        })}
      </Select>
    );
  };
  useEffect(() => {
    setTicketData();
    setSelectedService("cancel_appointment");
  }, [ticket]);

  function setTicketData() {
    ticket?.services[0]?.appointment_id &&
      dispatch(fetchAppointmentById(ticket?.services[0]?.appointment_id));
    // ticket?.id &&
    //   dispatch(fetchAppointmentTicketStatusDetailsByID(ticket?.id)).then(
    //     (val: any) => {
    //       if (val?.payload?.data?.statusCode == 200) {
    //         setAppointmentRequestStatus(val?.payload?.data?.data);
    //       }
    //     }
    //   );
  }
  const handleAudit = async () => {
    setAuditTrail(true);
    setNewOrderAuditTrail("newOrderAuditTrail");
    let id = ticket?.services[0]?.order_id
      ? ticket?.services[0]?.order_id
      : ticket?.services[0]?.order_request_id;
    let data = await dispatch(fetchMedicinesById(id));
    setPharmacyDetails(data?.payload?.data?.data?.pharmacy);
    setIsReset(null);
  };
  const onCloseAuditTrail = () => {
    setAuditTrail(false);
    setIsReset("reset");
  };

  const handleSumbit = async (values: any) => {
    const response = await dispatch(
      updateTicket({ id: ticket.id, data: values })
    );
    if (response.payload.status === 200 || response.payload.status === 201) {
      seteditTicketTypeModal(false);

      await dispatch(fetchTicketsDetails(ticket?.id));
    }
  };

  const chats = {} as any;

  return (
    <>
      {/* <Row style={{maxHeight:'calc(100vh - 155px)',overflowY:'scroll'}}> */}
      <Row className="details-page-fixed-header-comp">
        <Col
          {...{ xs: 24, sm: 24, md: 17, lg: 17, xl: 17 }}
          className="border-right data-update-form-left"
        >
          <div className="padding20 complaint-box">
            <h3 className="bold-600 visible-only-mobile">{`${t(
              "CRM.Tickets.Fields.Ticket"
            )} Details`}</h3>
            <ListView details={ticketDetails}></ListView>
            {ticket &&
              ticket?.services.length > 0 &&
              (ticket?.services[0]?.order_id ||
                ticket?.services[0]?.order_request_id) &&
              ticket?.sub_type?.name === "pharmacy" && (
                <>
                  <Row justify="end">
                    <Col className="text-right">
                      <a type="link" className="bold-600" onClick={handleAudit}>
                        <u>View Audit Trail</u>
                      </a>
                    </Col>
                  </Row>
                </>
              )}
            {formatDropDownValue(ticket?.status?.name) !== "CLOSED" &&
              serviceList.length > 0 &&
              !screenType.mobile && (
                <>
                  <Divider />
                  <Row>
                    <Col className="text-secondary mb-5">Actions</Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={14} className="gutter-row">
                      {appointment &&
                        serviceMenu(serviceList, "Select Service")}
                    </Col>
                    <Col span={10} className="gutter-row">
                      <Button type="primary" block onClick={serviceInitAction}>
                        Apply
                      </Button>
                    </Col>
                  </Row>{" "}
                </>
              )}
          </div>
          <Collapse defaultActiveKey={["1"]} className="collapse-right-arrow">
            <Panel header={`${t("CRM.Tickets.Fields.Ticket")} History`} key="1">
              <TimelineLayout
                type={t("CRM.Tickets.Fields.Ticket")}
                details={timelineDetails}
              ></TimelineLayout>
            </Panel>
          </Collapse>
        </Col>
        <Col className="data-gap d-none" span={24}></Col>
        <Col
          {...{ xs: 24, sm: 24, md: 7, lg: 7, xl: 7 }}
          className="update-form"
        >
          <UpdateTicketForm
            ticket={ticket}
            appointmentRequestStatus={appointmentRequestStatus}
            parentCallback={onFinishTicket}
          ></UpdateTicketForm>
        </Col>
      </Row>
      {initAction && (
        <GeneralTicketActions
          ticket={ticket}
          appointment={appointment}
          appointmentRequestStatus={appointmentRequestStatus}
          action={initAction}
          hideMe={(a) => {
            if (a == true) {
              dispatch(fetchTicketsDetails(ticket?.id));
            }
            setInitAction(false);
          }}
        ></GeneralTicketActions>
      )}

      {/*  Audit Trail Drawer */}
      {audit && (
        <Drawer
          title={`Audit Trail`}
          placement="right"
          width={"80%"}
          onClose={onCloseAuditTrail}
          visible={audit}
        >
          <Row className="mt30">
            <Col span={24}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Pharmacy Order" key="1">
                  <Row className="bordered-grey-color">
                    <Col
                      className="pd20 grey-background"
                      span={auditStatus === "newOrders" ? 24 : 14}
                    >
                      <SelectedMedicine
                        newOrderAuditTrail={newOrderAuditTrail}
                      />
                    </Col>
                    <Col span={auditStatus === "newOrders" ? 0 : 10}>
                      <PlaceOrderComponent
                        newOrderAuditTrail={newOrderAuditTrail}
                      />
                    </Col>
                  </Row>
                </TabPane>
                {pharmacyDetails?.appointment_id && (
                  <TabPane tab="Patient Journey" key="2">
                    <PatientJourney
                      appointmentId={pharmacyDetails?.appointment_id}
                      isReset={isReset}
                    ></PatientJourney>
                  </TabPane>
                )}
                <TabPane tab="Message History" key="3">
                  <PatientMessageHistory
                    patient_id={pharmacyDetails?.patient?.id}
                    isReset={isReset}
                  ></PatientMessageHistory>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Drawer>
      )}
      {editTicketTypeModal && (
        <Modal
          centered
          visible={editTicketTypeModal}
          onCancel={() => seteditTicketTypeModal(false)}
          title={`Edit ${t("CRM.Tickets.Fields.Ticket")} Details`}
          footer={[
            <Button
              onClick={() => form.submit()}
              block
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>,
          ]}
        >
          <Form
            onFinish={handleSumbit}
            className="crm-form"
            form={form}
            initialValues={{
              type_id: ticket?.type_id,
              sub_type_id: ticket?.sub_type_id,
              category_id: ticket?.category_id,
            }}
          >
            <Form.Item
              name={"type_id"}
              label={t("CRM.Tickets.Fields.Ticket Type")}
            >
              <Select
                allowClear={true}
                showSearch
                disabled={
                  myPermissions?.indexOf("task_module") !== -1 ||
                    myPermissions?.indexOf(TASK_MODULE_ACTIONS_EDIT_TICKET_MODULE) !== -1 ? false : true
                }

                optionFilterProp="children"
                onChange={(evt) => getSubCategory(evt)}
              >
                {taskTypes.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {formatDropDownValue(e.name)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={"sub_type_id"}
              label={t("CRM.Tickets.Fields.Ticket Sub Type")}
              rules={[{ required: true, message: "Please select sub type" }]}
            >
              <Select
                allowClear={true}
                showSearch
                optionFilterProp="children"
                disabled={type_id ? false : true}
                onChange={(evt) => getCategory(evt)}
              >
                {taskSubTypes.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {formatDropDownValue(e.name)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={"category_id"}
              label={t("CRM.Tickets.Fields.Ticket Category")}
              rules={[{ required: true, message: "Please select category" }]}
            >
              <Select
                allowClear={true}
                showSearch
                optionFilterProp="children"
                disabled={sub_type_id ? false : true}
              >
                {taskCategories.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {formatDropDownValue(e.name)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            {/* <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item> */}
          </Form>
        </Modal>
      )}

      {/* ----------------------------------- */}

      {showChatModal && (
        <Modal
          centered
          visible={showChatModal}
          onCancel={() => setshowChatModal(false)}
          title="Chat History"
          footer={false}
        >
          {/* chats will be here */}
          {/* {JSON.stringify(ticket?.meta?.chatHistory)} */}
          {ticket?.meta?.chatHistory?.map((chat: any) => {
            return chat?.question ? (
              <Row>
                <Col span={20}>
                  <Card className="chat-card left" size="small">
                    {chat.question?.split("\n")?.map((line: any) => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
                    {chat.createdAt && (
                      <span className="datetime">
                        {moment(chat.createdAt).format("DD MMM'YY hh:mm A")}
                      </span>
                    )}
                  </Card>
                </Col>
              </Row>
            ) : chat?.answer ? (
              <Row justify="end">
                <Col span={20}>
                  <Card className="chat-card right" size="small">
                    {chat.answer?.split("\n")?.map((line: any) => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
                    {chat.createdAt && (
                      <span className="datetime">
                        {moment(chat.createdAt).format("DD MMM'YY hh:mm A")}{" "}
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 16 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_9039_3579)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.22298 11.9354L15.2031 3.93538L14.0728 2.94922L7.61533 10.3502L7.16303 9.90677L6.11287 10.9778L7.13277 11.9778L7.70037 12.5344L8.22298 11.9354ZM4.24064 11.9144L10.7207 3.91437L9.55515 2.97023L3.59753 10.3252L1.16652 7.91022L0.109375 8.97438L3.12927 11.9744L3.71816 12.5594L4.24064 11.9144Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_9039_3579">
                              <rect
                                width="15"
                                height="14"
                                fill="white"
                                transform="translate(0.109375 0.949219)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    )}
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            );
          })}
          {ticket?.meta?.chatHistory?.length == 0 && <Empty></Empty>}
        </Modal>
      )}

      {/* ----------- */}
      {showNPSModal && (
        <Modal
          centered
          visible={showNPSModal}
          style={{ top: 20 }}
          onCancel={() => setshowNPSModal(false)}
          title="NPS Details"
          footer={null}
          width={1000}
        >
          {/* <Row gutter={20}>
            <Col className="text-center mb-20" span={24}>
              <Tag><b>Overall Score :</b> {npsDetails?.overallscore}</Tag>
            </Col>
          </Row> */}

          {npsDetails?.data?.map((item: any, index: any) => (
            <div className="survey-results">
              <div key={index + 1}>
                {item.question ? (
                  <Row key={index + 1}>
                    <Col span={24}>
                      <p className="left-message-nps">{item.question}</p>
                    </Col>
                  </Row>
                ) : null}
                <Row key={index + 2}>
                  <Col span={24}>
                    <p className="right-message-nps">{item.answer}</p>
                  </Col>
                </Row>
              </div>
            </div>
          ))}
        </Modal>
      )}
    </>
  );
}

export default TicketDetailsGeneralTab;
