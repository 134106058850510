import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../shared/Utils/api-client";
import { AGENTS_ALL } from "../../shared/routes/route.constants";
const config = {
  name: "agents",
};
export const fetchAllAgents: any = createAsyncThunk(
  `${config.name}/fetchAllAgents`,
  async (params: any) => {
    return await Client.get(AGENTS_ALL, { params });
  }
);

export const agents = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    agentsAll: [] as any,
    pagination: { offset: 0, limit: 10, total: 0 },
    error: "" as string,
  },
  reducers: {
    reset: (state) => {
      state.agentsAll = [];
      state.loading = false;
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      //  Fetch contacts
      .addCase(fetchAllAgents.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllAgents.fulfilled, (state, action) => {
        state.loading = false;
        state.agentsAll = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchAllAgents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

//export const {} = agents.actions;

export default agents.reducer;
