import { EyeTwoTone } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Timeline,
  Tooltip
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { resetContact } from "../../../redux/reducers/contacts.slice";
import { resetPatient } from "../../../redux/reducers/patients.slice";
import { fetchActivityHistory } from "../../../redux/reducers/tickets.slice";
import {
  contactNameFormat,
  formatDate,
  formatDropDownValue
} from "../../../shared/Utils/utilities";
import { TASK_MODULE_ACTIONS_READ_CALL_LOGS, TASK_MODULE_ACTIONS_READ_LEAD, TASK_MODULE_ACTIONS_READ_TICKET } from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import FormBar from "../../../shared/layouts/formbar.layout";
import {
  ROUTE_CRM_CALL_LOG_DETAILS,
  ROUTE_CRM_LEAD_DETAILS,
  ROUTE_CRM_TICKET_DETAILS
} from "../../../shared/routes/crm.routes.constants";

type Props = {
  phone: string;
  country_code?: string;
  cols?: any;
};

function ActivityHistoryTab({ phone = "" }: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [cardData, setcardData] = useState([] as any);
  const [monthYear, setmonthYear] = useState([] as any);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [formElements, setFormElements] = useState(null as any);
  const [filters, setFilters] = useState(null as any);
  const { patient } = useSelector((state: RootState) => state.patient);
  const { contact } = useSelector((state: RootState) => state.contact);
  // const monthYear = [] as any;
  console.log("patient", contact)
  const dispatch = useDispatch();
  const limit = 30;

  useEffect(() => {
    return () => {
      dispatch(resetPatient())
      dispatch(resetContact())
    }
  }, [])

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  console.log("rrrrrrr", offset, limit, total)

  const historyType = [


    ...(myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1 && myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_TICKET) !== -1 && myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_CALL_LOGS) !== -1 ? [{
      name: "All",
      value: "",
    },] : []),

    ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_TICKET) !== -1 ? [{
      name: t("CRM.Tickets.Fields.Ticket"),
      value: "ticket",
    },] : []),
    ...(myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_LEAD) !== -1 ? [{
      name: "Lead",
      value: "lead",
    }] : []),
    ...(myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_CALL_LOGS) !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_READ_CALL_LOGS?.split(".")[0]) !==
      -1 ? [{
        name: "Call Logs",
        value: "call_logs",
      }] : []),

  ];
  useEffect(() => {
    // if (offset !== 0) setOffset(0);
    // else 
    getData();
  }, [phone, offset, filters]);



  function getData() {
    let filter = filters
      ? Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != null)
      )
      : {};
    phone !== "" &&
      dispatch(
        fetchActivityHistory({
          limit: limit,
          phone: phone,
          offset: offset,
          ...filter,
        })
      ).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          setTotal(val?.payload?.data?.data?.total);
          let cardDataOld = JSON.parse(JSON.stringify(cardData));
          let newData = [];
          if (offset == 0) newData = val?.payload?.data?.data?.items;
          else newData = cardDataOld.concat(val?.payload?.data?.data?.items);
          setcardData(newData);
        }
      });
  }

  useEffect(() => {
    for (let index = 0; index < 6; index++) {
      setmonthYear((state: any) => [
        ...state,
        moment().subtract(index, "month").format("MMMM YYYY"),
      ]);
    }
  }, []);

  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] === "" || all[key] === undefined) {
      } else {
        if (key == "range") {
          filtersObj.from_date = all.range
            ? formatDates(all.range[0].format())
            : null;
          filtersObj.to_date = all.range
            ? formatDates(all.range[1].format())
            : null;
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    setFilters(filtersObj);
  }

  function formatDates(date: any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  useEffect(() => {
    setFormElements({
      onFinish: () => { },
      onFinishFailed: () => { },
      onValuesChange: filterFormSubmit,
      initialValues: {},
      setValues: {},
      formElements: [
        {
          type: "Select",
          name: "type",
          colSpan: 4,
          allowClear: true,
          placeholder: "History Type",
          options: historyType,
        },
        {
          type: "RangePicker",
          name: "range",
          colSpan: 8,
        },
      ],
    });
  }, []);

  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  return (
    <>
      <Row className="bg-app" align="middle" style={{ paddingTop: "14px" }}>
        <Col span={24}>
          <p className="paddingx20 bold-600 text-secondary">
            All history associated with +91 {phone}
          </p>
          {formElements && !screenType.mobile && (
            <FormBar
              name={"generic_filters"}
              onFinish={formElements.onFinish}
              onFinishFailed={formElements.onFinishFailed}
              formElements={formElements.formElements}
              initialValues={formElements.initialValues}
              onValuesChange={formElements.onValuesChange}
              buttonText={""}
              setValues={formElements.setValues ? formElements.setValues : null}
            ></FormBar>
          )}
        </Col>
      </Row>
      <div style={{ maxHeight: "calc(100vh - 200px)", overflowY: "scroll" }}>
        {cardData?.length > 0 && (
          <Row className=" padding20" justify="center">
            <Col
              span={24}
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
              className="padding-bottom-20  activity-history-list"
            >
              {monthYear.map((monthYears: any, indexx: any) => {
                // if (indexx < loadCount) {
                const monthData = cardData?.filter((itemss: any) => {
                  return (
                    moment(itemss.created_at).format("MMMM YYYY") === monthYears
                  );
                });
                return (
                  <>
                    {monthData.length > 0 && (
                      <h3 className="padding-bottom-20 text-secondary font20">
                        {monthYears}
                      </h3>
                    )}

                    <Timeline>
                      {monthData.length > 0 &&
                        monthData.map((item: any, i: any) => {
                          // if (i < 6 ) {

                          const contactObj = item?.type === 'ticket' ? item?.ticket?.contact : item?.lead?.contact
                          return (
                            <>
                              <Timeline.Item
                                dot={
                                  <Button
                                    shape="circle"
                                    style={{ padding: "0px", border: "none" }}
                                    icon={
                                      item?.type === "ticket" ? (
                                        <Image
                                          src={
                                            require("../../../assets/icons/ticket.svg")
                                              .default
                                          }
                                          preview={false}
                                        />
                                      ) : item?.type === "lead" ? (
                                        <Image
                                          src={
                                            require("../../../assets/icons/lead.svg")
                                              .default
                                          }
                                          preview={false}
                                        />
                                      ) : item?.type === "call" ||
                                        item?.type === "call_logs" ? (
                                        <Image
                                          src={
                                            require("../../../assets/icons/call-log.svg")
                                              .default
                                          }
                                          preview={false}
                                        />
                                      ) : (
                                        ""
                                      )
                                    }
                                  />
                                }
                              >
                                <Row gutter={8}>
                                  <Col
                                    flex="none"
                                    className="gutter-row history-timeline-date"
                                  >
                                    <span>
                                      <small>
                                        {moment(item?.created_at).format(
                                          "D MMM"
                                        )}
                                        <br />
                                        <span className="text-secondary">
                                          {item?.type === "call_logs"
                                            ? moment(
                                              item?.call?.call_start_time
                                            ).format("LT")
                                            : moment(item.created_at).format(
                                              "LT"
                                            )}
                                        </span>
                                      </small>
                                    </span>
                                  </Col>
                                  <Col className="gutter-row history-timeline-details">
                                    <Card className=" paddingx20 paddingy10">
                                      <Row gutter={8}>
                                        <Col
                                          {...{
                                            xs: 24,
                                            sm: 24,
                                            md: 19,
                                            lg: 19,
                                            xl: 19,
                                          }}
                                          className="gutter-row"
                                        >
                                          {item?.type == "ticket" && (
                                            <>
                                              <span className="bold-600 capitalize">
                                                {item?.ticket?.display_id && (
                                                  <>
                                                    <a
                                                      onClick={() =>
                                                        history.push(
                                                          ROUTE_CRM_TICKET_DETAILS(
                                                            item?.ticket?.id
                                                          )
                                                        )
                                                      }
                                                      className="bold-600"
                                                    >
                                                      {item?.ticket?.display_id}
                                                    </a>{" "}
                                                    <Divider
                                                      type="vertical"
                                                      className="dark"
                                                    ></Divider>
                                                  </>
                                                )}
                                                {t("CRM.Tickets.Fields.Ticket")}{" "}
                                                {formatDropDownValue(
                                                  item?.action
                                                ).toLowerCase()}
                                                {item?.ticket_history?.status
                                                  ?.name && (
                                                    <>
                                                      <Divider
                                                        type="vertical"
                                                        className="dark"
                                                      ></Divider>
                                                      {formatDropDownValue(
                                                        item?.ticket_history
                                                          ?.status?.name
                                                      )}
                                                    </>
                                                  )}
                                                {item?.ticket?.title && (
                                                  <>
                                                    <Divider
                                                      type="vertical"
                                                      className="dark"
                                                    ></Divider>{" "}
                                                    {item?.ticket?.title}
                                                  </>
                                                )}
                                                {formatDropDownValue(
                                                  item?.ticket_history?.status
                                                    ?.name
                                                ) == "Follow-up" ? (
                                                  <>
                                                    {" "}
                                                    <Divider
                                                      type="vertical"
                                                      className="dark"
                                                    ></Divider>
                                                    {formatDropDownValue(
                                                      item?.ticket_history
                                                        ?.status?.name
                                                    )}
                                                    {" on "}
                                                    {formatDate(
                                                      item?.ticket_history
                                                        ?.follow_up_date
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {item?.ticket_history
                                                  ?.priority &&
                                                  item?.ticket_history
                                                    ?.priority !== null && (
                                                    <>
                                                      {" "}
                                                      |{" "}
                                                      {item?.ticket_history
                                                        ?.priority?.name
                                                        ? item?.ticket_history
                                                          ?.priority?.name +
                                                        " Priority"
                                                        : ""}
                                                    </>
                                                  )}
                                                {item?.ticket_history
                                                  ?.due_date && (
                                                    <>
                                                      {" "}
                                                      | Due Date{" "}
                                                      {formatDate(
                                                        item?.ticket_history
                                                          ?.due_date
                                                      )}
                                                    </>
                                                  )}


                                                {
                                                  item?.ticket_history?.type?.name && (
                                                    <>
                                                      {" "} | {t("CRM.Tickets.Fields.Ticket Sub Type")}: {formatDropDownValue(item?.ticket_history?.sub_type?.name)}
                                                    </>
                                                  )
                                                }
                                                {
                                                  item?.ticket_history?.sub_type?.name && (
                                                    <>
                                                      {" "} |  {t("CRM.Tickets.Fields.Ticket Category")}: {formatDropDownValue(item?.ticket_history?.category?.name)}
                                                    </>
                                                  )
                                                }

                                                {item?.ticket_history
                                                  ?.assigned_user
                                                  ?.first_name && (
                                                    <>
                                                      {" "}
                                                      | assigned to{" "}
                                                      {item?.ticket_history
                                                        ?.assigned_user
                                                        ?.first_name
                                                        ? item?.ticket_history
                                                          ?.assigned_user
                                                          ?.first_name
                                                        : ""}{" "}
                                                      {item?.ticket_history
                                                        ?.assigned_user?.last_name
                                                        ? item?.ticket_history
                                                          ?.assigned_user
                                                          ?.last_name
                                                        : ""}
                                                    </>
                                                  )}
                                              </span>
                                              {item?.ticket_history?.remarks && (
                                                <>
                                                  <Divider
                                                    type="vertical"
                                                    className="dark"
                                                  ></Divider>
                                                  <Tooltip
                                                    title={
                                                      "Remarks: " +
                                                      item?.ticket_history?.remarks
                                                    }
                                                  >
                                                    <EyeTwoTone />
                                                  </Tooltip>{" "}
                                                </>
                                              )}
                                            </>
                                          )}
                                          {item?.type == "lead" && (
                                            <>
                                              <span className="bold-600 capitalize">
                                                {item?.lead?.display_id && (
                                                  <>
                                                    <a
                                                      onClick={() =>
                                                        history.push(
                                                          ROUTE_CRM_LEAD_DETAILS(
                                                            item?.lead?.id
                                                          )
                                                        )
                                                      }
                                                      className="bold-600"
                                                    >
                                                      {item?.lead?.display_id}
                                                    </a>{" "}
                                                    <Divider
                                                      type="vertical"
                                                      className="dark"
                                                    ></Divider>
                                                  </>
                                                )}
                                                lead{" "}
                                                {formatDropDownValue(
                                                  item?.action
                                                ).toLowerCase()}
                                                {item?.lead_history?.status
                                                  ?.name && (
                                                    <>
                                                      <Divider
                                                        type="vertical"
                                                        className="dark"
                                                      ></Divider>
                                                      {formatDropDownValue(
                                                        item?.lead_history?.status
                                                          ?.name
                                                      )}
                                                    </>
                                                  )}
                                                {item?.lead?.title && (
                                                  <>
                                                    <Divider
                                                      type="vertical"
                                                      className="dark"
                                                    ></Divider>{" "}
                                                    {item?.lead?.title}
                                                  </>
                                                )}
                                                {formatDropDownValue(
                                                  item?.lead_history?.status
                                                    ?.name
                                                ) == "Follow-up" ? (
                                                  <>
                                                    {" "}
                                                    <Divider
                                                      type="vertical"
                                                      className="dark"
                                                    ></Divider>
                                                    {formatDropDownValue(
                                                      item?.lead_history?.status
                                                        ?.name
                                                    )}
                                                    {" on "}
                                                    {formatDate(
                                                      item?.lead_history
                                                        ?.follow_up_date
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {item?.lead_history?.priority &&
                                                  item?.lead_history
                                                    ?.priority !== null && (
                                                    <>
                                                      {" "}
                                                      |{" "}
                                                      {item?.lead_history
                                                        ?.priority?.name
                                                        ? item?.lead_history
                                                          ?.priority?.name +
                                                        " Priority"
                                                        : ""}
                                                    </>
                                                  )}
                                                {item?.lead_history
                                                  ?.due_date && (
                                                    <>
                                                      {" "}
                                                      | Due Date{" "}
                                                      {formatDate(
                                                        item?.lead_history
                                                          ?.due_date
                                                      )}
                                                    </>
                                                  )}

                                                {item?.lead_history
                                                  ?.assigned_user
                                                  ?.first_name && (
                                                    <>
                                                      {" "}
                                                      | assigned to{" "}
                                                      {item?.lead_history
                                                        ?.assigned_user
                                                        ?.first_name
                                                        ? item?.lead_history
                                                          ?.assigned_user
                                                          ?.first_name
                                                        : ""}{" "}
                                                      {item?.lead_history
                                                        ?.assigned_user?.last_name
                                                        ? item?.lead_history
                                                          ?.assigned_user
                                                          ?.last_name
                                                        : ""}
                                                    </>
                                                  )}
                                              </span>
                                              {item?.lead?.remarks && (
                                                <>
                                                  <Divider
                                                    type="vertical"
                                                    className="dark"
                                                  ></Divider>
                                                  <Tooltip
                                                    title={
                                                      "Remarks: " +
                                                      item?.lead?.remarks
                                                    }
                                                  >
                                                    <EyeTwoTone />
                                                  </Tooltip>{" "}
                                                </>
                                              )}
                                            </>
                                          )}
                                          {item?.type == "call_logs" && (
                                            <>
                                              <span className="bold-600 capitalize">
                                                {item?.call?.call_id && (
                                                  <>
                                                    <a
                                                      onClick={() =>
                                                        history.push(
                                                          ROUTE_CRM_CALL_LOG_DETAILS(
                                                            item?.call?.id
                                                          )
                                                        )
                                                      }
                                                      className="bold-600"
                                                    >
                                                      {item?.call?.call_id}
                                                    </a>{" "}
                                                    <Divider
                                                      type="vertical"
                                                      className="dark"
                                                    ></Divider>
                                                  </>
                                                )}
                                                {formatDropDownValue(
                                                  item?.action
                                                ).toLowerCase()}{" "}
                                                Call
                                                {item?.call?.call_duration && (
                                                  <>
                                                    <Divider
                                                      type="vertical"
                                                      className="dark"
                                                    ></Divider>{" "}
                                                    {item?.call?.call_duration}
                                                  </>
                                                )}
                                              </span>
                                              {item?.lead?.remarks && (
                                                <>
                                                  <Divider
                                                    type="vertical"
                                                    className="dark"
                                                  ></Divider>
                                                  <Tooltip
                                                    title={
                                                      "Remarks: " +
                                                      item?.lead?.remarks
                                                    }
                                                  >
                                                    <EyeTwoTone />
                                                  </Tooltip>{" "}
                                                </>
                                              )}
                                            </>
                                          )}
                                          <br />
                                          {item?.type !== "call_logs" &&
                                            (
                                              <span className="text-secondary capitalize">
                                                {contactNameFormat(
                                                  contactObj?.first_name,
                                                  contactObj?.last_name,
                                                  contactObj?.gender
                                                    ?.name,
                                                  contactObj?.dob
                                                )}
                                              </span>
                                            )}
                                        </Col>
                                        <Col
                                          {...{
                                            xs: 24,
                                            sm: 24,
                                            md: 5,
                                            lg: 5,
                                            xl: 5,
                                          }}
                                          className="gutter-row text-right"
                                        >
                                          <span className="text-secondary em capitalize">
                                            {" "}
                                            By{" "}
                                            {item?.action_by?.first_name && (
                                              <>
                                                {item?.action_by?.first_name
                                                  ? item?.action_by?.first_name
                                                  : ""}{" "}
                                                {item?.action_by?.last_name
                                                  ? item?.action_by?.last_name
                                                  : ""}
                                              </>
                                            )}{" "}
                                            {!item?.action_by?.first_name &&
                                              `System`}{" "}
                                          </span>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                </Row>
                              </Timeline.Item>
                            </>
                          );
                          // }
                        })}
                    </Timeline>
                  </>
                );
                // }
              })}
            </Col>
            <Col >
              {offset + limit < total ? (
                <Button
                  className="padding-bottom-20 margin10"
                  onClick={() => {
                    setOffset(offset + limit);
                  }}
                >
                  Load More
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        )}
      </div>

      {/* <Row justify="center">
        <Col>
          {offset + limit < total ? (
            <Button
              className="padding-bottom-20 margin10"
              onClick={() => {
                setOffset(offset + limit);
              }}
            >
              Load More
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row> */}
    </>
  );
}

export default ActivityHistoryTab;
