import React from "react";
// component imports
import HyperLink from "../../../../HyperLink/HyperLink";
import NumericPainScale from "../../../../NumericPainScale/NumericPainScale";
import TypeAheadComponent from "../../../../TypeAhead/TypeaheadComponent";
import CheckboxComponent from "../../../Common/CheckboxComponent";
import DateComponent from "../../../Common/DateComponent";
import HeaderComponent from "../../../Common/HeaderComponent";
import RadioComponent from "../../../Common/RadioComponent";
import SelectComponent from "../../../Common/SelectComponent";
import TextareaComponent from "../../../Common/TextareaComponent";
import TextboxComponent from "../../../Common/TextboxComponent";
import TextBoxUpDownViewComponent from "../../../Common/TextBoxUpDownViewComponent";
import TextFormatterComponent from "../../../Common/TextFormatterComponent";
class ColumnViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      errorStatus: null,
    };
    if (props.indColumnData.formula) {
      props.setConfigForFormula({
        colIndex: props.colIndex,
        localvar: props.indColumnData.localvar.split(","),
        formula: props.indColumnData.formula,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.indColumnData.formula &&
      prevProps.indColumnData.formula !== this.props.indColumnData.formula
    ) {
      this.props.setConfigForFormula({
        colIndex: this.props.colIndex,
        localvar: this.props.indColumnData.localvar.split(","),
        formula: this.props.indColumnData.formula,
      });
    }
  }

  emitTextboxValue = (event) => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true }, () =>
        this.props.emitTextboxValue(event)
      );
    } else this.props.emitTextboxValue(event);
  };

  emitFocusEvent = (event) => {
    this.props.emitFocusEvent(event);
  };

  getEmitedAddNewRowObjectData = (event) => {
    this.props.emitAddNewRowEvent(event);
  };

  getEmitedDeleteRowObjectData = (event) => {
    this.props.emitDeleteRowObjectData(event);
  };

  getEmitedFormalLocalVar = (event) => {
    this.props.emitFormalLocalVar(event);
  };

  getEmitedTypeAheadSuggestionObj = (event) => {
    this.props.emitTypeAheadSuggestionObj(event);
  };

  getEmitedLoadOptionsEvent = (event, callback) => {
    this.props.emitLoadOptionsEvent(event, callback);
  };

  setErrorStatus = (errorStatus) => {
    if (this.state.errorStatus !== errorStatus) {
      this.setState({ errorStatus }, () =>
        this.props.emitErrorStatus(errorStatus)
      );
    }
  };

  render() {
    const {
      type,
      label,
      name,
      placeholder,
      labelSize,
      size,
      options,
      pattern,
      formula,
      showUnit,
      async,
      colId,
      localvar,
      value,
      values,
      auto,
      autoKey,
      readOnly,
      offset,
      validation,
      multiselect,
      info,
      min,
      max,
      bold_label,
      bold_value,
    } = this.props.indColumnData;
    let { isDirty } = this.state;
    const { showType, viewType } = this.props;

    // todo items destructure
    return (
      <React.Fragment>
        {type === "hyper_link" ? (
          <td style={{ padding: "0.1rem", width: "50%" }}>
            <HyperLink
              name={name}
              isView={viewType === "view" ? true : false}
              validation={validation}
              checkForm={this.props.checkForm}
              placeholder={placeholder}
              isDirty={isDirty}
              emitErrorStatus={this.setErrorStatus.bind(this)}
              label={label}
              rowType={this.props.rowType}
              offset={offset}
              labelSize={labelSize}
              size={size}
              type={type}
              value={value}
              options={options}
              emitTextboxValue={this.emitTextboxValue.bind(this)}
            ></HyperLink>
          </td>
        ) : (
          ""
        )}

        {type === "numeric_pain_score" ? (
          <td style={{ padding: "0.1rem", width: "50%" }}>
            <NumericPainScale
              name={name}
              isView={viewType === "view" ? true : false}
              validation={validation}
              checkForm={this.props.checkForm}
              placeholder={placeholder}
              isDirty={isDirty}
              emitErrorStatus={this.setErrorStatus.bind(this)}
              label={label}
              rowType={this.props.rowType}
              offset={offset}
              labelSize={labelSize}
              size={size}
              type={type}
              max={max}
              min={min}
              value={value}
              options={options}
              emitTextboxValue={this.emitTextboxValue.bind(this)}
            ></NumericPainScale>
          </td>
        ) : (
          ""
        )}
        {type === "typeahead" && (
          <TypeAheadComponent
            async={async}
            name={name}
            autoKey={autoKey}
            validation={validation}
            showType={showType}
            viewType={viewType}
            checkForm={this.props.checkForm}
            placeholder={placeholder}
            isDirty={isDirty}
            info={info}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            label={label}
            rowType={this.props.rowType}
            offset={offset}
            labelSize={labelSize}
            size={size}
            type={type}
            value={value}
            options={options}
            emitTypeAheadSuggestionObj={this.getEmitedTypeAheadSuggestionObj.bind(
              this
            )}
            emitFocusEvent={this.emitFocusEvent.bind(this)}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          ></TypeAheadComponent>
        )}
        {type === "textbox" && showType !== "compactTextbox" && (
          <React.Fragment>
            <TextboxComponent
              name={name}
              showType={showType}
              viewType={viewType}
              validation={validation}
              showUnit={showUnit}
              checkForm={this.props.checkForm}
              isDirty={isDirty}
              rowType={this.props.rowType}
              placeholder={placeholder}
              label={label}
              labelSize={labelSize}
              info={info}
              formula={formula}
              colId={colId}
              localvar={localvar}
              size={size}
              type={type}
              offset={offset}
              readOnly={readOnly}
              suggestion={auto}
              value={value}
              bold_label={bold_label}
              bold_value={bold_value}
              emitFocusEvent={this.emitFocusEvent.bind(this)}
              emitErrorStatus={this.setErrorStatus.bind(this)}
              emitTextboxValue={this.emitTextboxValue.bind(this)}
              emitFormalLocalVar={this.getEmitedFormalLocalVar.bind(this)}
            ></TextboxComponent>
          </React.Fragment>
        )}

        {type === "textbox" && showType === "compactTextbox" && (
          <React.Fragment>
            <TextBoxUpDownViewComponent
              name={name}
              showUnit={showUnit}
              viewType={viewType}
              validation={validation}
              showType={showType}
              checkForm={this.props.checkForm}
              isDirty={isDirty}
              rowType={this.props.rowType}
              placeholder={placeholder}
              label={label}
              info={info}
              labelSize={labelSize}
              formula={formula}
              colId={colId}
              localvar={localvar}
              size={size}
              type={type}
              offset={offset}
              readOnly={readOnly}
              suggestion={auto}
              value={value}
              emitErrorStatus={this.setErrorStatus.bind(this)}
              emitTextboxValue={this.emitTextboxValue.bind(this)}
              emitFormalLocalVar={this.getEmitedFormalLocalVar.bind(this)}
            ></TextBoxUpDownViewComponent>
          </React.Fragment>
        )}
        {type === "textFormatter" && showType !== "compactTextbox" && (
          <TextFormatterComponent
            name={name}
            validation={validation}
            checkForm={this.props.checkForm}
            viewType={viewType}
            rowType={this.props.rowType}
            showType={showType}
            placeholder={placeholder}
            label={label}
            labelSize={labelSize}
            info={info}
            isDirty={isDirty}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            offset={offset}
            size={size}
            pattern={pattern}
            readOnly={readOnly}
            type={type}
            value={value}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          ></TextFormatterComponent>
        )}

        {type === "textarea" && showType === "autocompleteTextbox" && (
          <>
            <TextareaComponent
              isFullSize={true}
              name={name}
              validation={validation}
              showType={showType}
              viewType={viewType}
              checkForm={this.props.checkForm}
              rowType={this.props.rowType}
              placeholder={placeholder}
              label={label}
              isDirty={isDirty}
              info={info}
              emitErrorStatus={this.setErrorStatus.bind(this)}
              labelSize={labelSize}
              size={size}
              offset={offset}
              type={type}
              readOnly={readOnly}
              value={value}
              emitFocusEvent={this.emitFocusEvent.bind(this)}
              emitTextboxValue={this.emitTextboxValue.bind(this)}
              emitAddNewRowObjectData={this.getEmitedAddNewRowObjectData.bind(
                this
              )}
              emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(
                this
              )}
            />
          </>
        )}
        {type === "textarea" && showType !== "compactTextbox" && (
          <>
            <TextareaComponent
              name={name}
              validation={validation}
              showUnit={showUnit}
              showType={showType}
              checkForm={this.props.checkForm}
              rowType={this.props.rowType}
              placeholder={placeholder}
              label={label}
              info={info}
              isDirty={isDirty}
              emitErrorStatus={this.setErrorStatus.bind(this)}
              labelSize={labelSize}
              size={size}
              offset={offset}
              suggestion={auto}
              viewType={viewType}
              type={type}
              readOnly={readOnly}
              value={value}
              emitFocusEvent={this.emitFocusEvent.bind(this)}
              emitTextboxValue={this.emitTextboxValue.bind(this)}
              emitAddNewRowObjectData={this.getEmitedAddNewRowObjectData.bind(
                this
              )}
              emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(
                this
              )}
            ></TextareaComponent>
          </>
        )}
        {type === "selection" && showType !== "compactTextbox" && (
          <SelectComponent
            name={name}
            validation={validation}
            showType={showType}
            viewType={viewType}
            checkForm={this.props.checkForm}
            placeholder={placeholder}
            isDirty={isDirty}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            label={label}
            rowType={this.props.rowType}
            offset={offset}
            info={info}
            labelSize={labelSize}
            size={size}
            type={type}
            value={value}
            values={values}
            options={options}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          />
        )}

        {type === "date" && showType !== "compactTextbox" && (
          <DateComponent
            name={name}
            validation={validation}
            checkForm={this.props.checkForm}
            showType={showType}
            viewType={viewType}
            placeholder={placeholder}
            isDirty={isDirty}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            label={label}
            rowType={this.props.rowType}
            offset={offset}
            info={info}
            labelSize={labelSize}
            size={size}
            type={type}
            value={value}
            emitFocusEvent={this.emitFocusEvent.bind(this)}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          ></DateComponent>
        )}
        {type === "radio" && showType !== "compactTextbox" && (
          <RadioComponent
            name={name}
            validation={validation}
            showType={showType}
            checkForm={this.props.checkForm}
            placeholder={placeholder}
            isDirty={isDirty}
            viewType={viewType}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            label={label}
            info={info}
            rowType={this.props.rowType}
            offset={offset}
            labelSize={labelSize}
            size={size}
            values={values}
            type={type}
            value={value}
            options={options}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          ></RadioComponent>
        )}
        {type === "checkbox" && showType !== "compactTextbox" && (
          <CheckboxComponent
            name={name}
            validation={validation}
            showType={showType}
            checkForm={this.props.checkForm}
            placeholder={placeholder}
            isDirty={isDirty}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            label={label}
            info={info}
            viewType={viewType}
            rowType={this.props.rowType}
            offset={offset}
            labelSize={labelSize}
            size={size}
            type={type}
            value={value}
            values={values}
            options={options}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          ></CheckboxComponent>
        )}
        {type === "header" && (
          <HeaderComponent
            name={name}
            label={label}
            viewType={viewType}
            info={info}
          ></HeaderComponent>
        )}
      </React.Fragment>
    );
  }
}

export default ColumnViewComponent;
