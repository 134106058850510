import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchMyPermissions,
  fetchSelf,
  setToken,
} from "../../../redux/reducers/auth.slice";
import { fetchProfile } from "../../../redux/reducers/profile.slice";
import { RootState } from "../../constants";
import { ROUTE_CRM_TICKET_LIST } from "../../routes/crm.routes.constants";
//import {useEffect, useState} from "react";

export const SignInButton = () => {
  const { instance } = useMsal();
  const { account } = useSelector((state: RootState) => state.auth);
  //const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();

  return (
    <Button
      className="submit doctor-secondary-button mt20"
      size={"large"}
      block
      onClick={() => {
        // @ts-ignore
        instance
          .loginPopup()
          .then(async (a) => {
            localStorage.setItem("auth-type", "ad");
            await dispatch(setToken(a.idToken));
            await dispatch(fetchSelf());
            await dispatch(fetchMyPermissions());
            const response = await dispatch(fetchProfile("users"));
            if (response.payload.status === 200) {
              if (RouteParams["source"] === "crm") {
                (async function () {
                  try {
                    await dispatch(fetchSelf());
                    await dispatch(fetchMyPermissions());
                    const response = await dispatch(fetchProfile("users"));
                    let dashboardRoute = "";
                    if (response.payload.status === 200) {
                      if (RouteParams["source"] === "crm") {
                        dashboardRoute = ROUTE_CRM_TICKET_LIST;
                      }
                      const redirectUrl =
                        localStorage.getItem("redirect_url") || "";
                      if (redirectUrl && redirectUrl != "") {
                        localStorage.removeItem("redirect_url");
                        dashboardRoute = redirectUrl;
                      }
                      window.location.href = dashboardRoute;
                    }
                  } catch (e) {
                    console.error(e);
                  }
                })();
              }
              // if (RouteParams["source"] === "crm") {
              //   (async function () {
              //     try {
              //       await dispatch(fetchSelf());
              //       await dispatch(fetchMyPermissions());
              //       const response = await dispatch(fetchProfile("users"));
              //       if (response.payload.status === 200) {
              //         // window.location.href = ROUTE_CRM_TICKET_LIST;
              //         // debugger
              //       }
              //     } catch (e) {
              //       console.error(e);
              //     }
              //   })();
              // }
            }
          })
          .catch((e) => {
            console.error("login failed", e);
          });
      }}
    >
      Microsoft Sign in
    </Button>
  );
};
