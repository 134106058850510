import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addNewContact,
  createContactFromPatient,
  fetchContactAndPatientByMobile, patchContact, resetContact
} from "../../../../redux/reducers/contacts.slice";
import {
  fetchMasterGender,
  fetchMasterNationality,
  fetchMasterTaskDropDown
} from "../../../../redux/reducers/master.slice";
import { RootState } from "../../../../shared/constants";
import {
  dobToAge,
  formatDropDownValue,
  getFullName
} from "../../../../shared/Utils/utilities";
import AssociatedLeads from "../../components/associatedleadstab";
import TicketsTab from "../../components/ticketstab";
import CreateContactForm from "../../Contacts/components/createcontactform";

const { Option } = Select;
type Props = {
  calllog: any;
  emitParent: (val: any) => void;
};

function CallLogsDetailsTab({ calllog, emitParent }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { gender, nationality } = useSelector(
    (state: RootState) => state.master
  );
  // const { loading, contacts, pagination } = useSelector(
  //   (state: RootState) => state.contact
  // );
  const { loading_cp, cpCombined } = useSelector(
    (state: RootState) => state.contact
  );
  const { taskDropDown } = useSelector((state: RootState) => state.master);
  const [selectedContact, setselectedContact] = useState(false as any);
  const [modalVisible, setmodalVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [linkedState, setlinkedState] = useState(false);
  const [remarksInput, showRemarksInput] = useState(false);
  const [phone, setPhone] = useState(false as any);
  const [statusList, setStatusList] = useState([] as any);
  const [linkingTab, setLinkingTab] = useState("associated_leads");
  const [selectedContactValues, setselectedContactValues] = useState(
    null as any
  );

  const [contactLists, setcontactLists] = useState(null as any)

  const [linkButtonDisabled, setlinkButtonDisabled] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetContact())
    }
  }, [])


  useEffect(() => {

    let selectedContactId = '';
    // if (selectedContact) {

    // }
    // else 
    if (cpCombined.length > 0 && cpCombined[0]?.id) {
      setselectedContact(cpCombined.length > 0 && cpCombined[0]?.id);
    }
    else {
      if (cpCombined.length > 0 && (cpCombined[0]?.source === 'patient')) {
        (async () => {
          const res = await dispatch(createContactFromPatient({ patient_id: cpCombined[0]?.patient_id }));
          if (res?.payload?.data?.statusCode == 200) {
            selectedContactId = res?.payload?.data?.data?.id;
          } else {
            return;
          }
          setselectedContact(selectedContactId)
        })();
        dispatch(fetchContactAndPatientByMobile(calllog?.phone))
      }
    }

  }, [cpCombined]);

  console.log("selectedContactId", selectedContact)

  useEffect(() => {
    setcontactLists(
      cpCombined.map((val: any) => {
        return { ...val, id: val.id ? val.id : val.patient_id };
      })
    );

  }, [cpCombined]);

  // console.log("xxxxxxxxxxxxxaaaaaaaaaaaaaaaa", contactLists)


  useEffect(() => {
    gender?.length == 0 && dispatch(fetchMasterGender({}));
    nationality?.length == 0 && dispatch(fetchMasterNationality({}));
    taskDropDown?.length == 0 && dispatch(fetchMasterTaskDropDown());
  }, []);
  useEffect(() => {
    if (taskDropDown.length > 0) {
      let temp = taskDropDown.filter((val: any) => {
        return val.name == "status" && val.mode == "call_logs";
      });
      setStatusList(temp[0]?.values);
    }
  }, [taskDropDown]);
  useEffect(() => {
    (calllog || linkedState) && calllog?.phone && dispatch(fetchContactAndPatientByMobile(calllog?.phone))
  }, []);

  useEffect(() => {
    setlinkedState(calllog.contact_id ? true : false);
    setPhone(calllog.phone);
  }, [calllog]);

  useEffect(() => {
    const prevTitle = document.title;
    const name = calllog?.contact
      ? `${formatDropDownValue(getFullName(calllog?.contact))} |`
      : "";
    const phone = calllog?.phone
      ? "+" + calllog?.country_code + " " + calllog?.phone
      : "";
    document.title = `${name} ${phone}`;
    return () => {
      document.title = prevTitle;
    };
  }, []);

  const handleChange = async (value: any) => {
    let contact_id = value;
    const contactData = contactLists?.filter((val: any) => {
      return val.id === contact_id;
    })[0];
    if (contactData?.source === "patient") {
      const res = await dispatch(
        createContactFromPatient({ patient_id: contact_id })
      );
      if (res?.payload?.data?.statusCode == 200) {
        contact_id = res?.payload?.data?.data?.id;
      } else {
        return;
      }
      dispatch(fetchContactAndPatientByMobile(calllog?.phone))
      setselectedContact(contact_id);

    }
    else {
      setselectedContact(value)
    }
    const aaa =
      cpCombined.length > 0 &&
      cpCombined?.filter((v: any) => {
        return v?.id === value?.id;
      })[0];
    setselectedContactValues(aaa);
    setlinkButtonDisabled(false);
  }

  const editContactCallLog = (e: any) => {
    setmodalVisible(true);
  };

  function linkContact(contactId?: any) {
    emitParent({
      action: "patch_calllog",
      details: { contact_id: contactId ? contactId : selectedContact },
    });
    setlinkButtonDisabled(true);
  }
  function handleTabChange(e: any) {
    setLinkingTab(e.target.value);
  }
  const onSaveContact = (values: any) => {
    values = { ...values, country_code: "91" };
    setFormLoading(true);
    dispatch(addNewContact(values)).then((val: any) => {
      setFormLoading(false);
      if (val?.payload?.data?.statusCode == 200) {
        setselectedContact(val?.payload?.data?.data?.id);
        setmodalVisible(false);
        message.success({
          content: "Contact created successfully",
          style: {
            marginBottom: "10vh",
          },
        });
        linkContact(val?.payload?.data?.data?.id);
        // dispatch(fetchContactList({ phone: calllog?.phone }));
        dispatch(fetchContactAndPatientByMobile(calllog?.phone))
      }
    });
  };
  const onSaveEditContact = (values: any) => {
    values = { ...values, country_code: "91" };
    setFormLoading(true);
    dispatch(patchContact({ id: selectedContactValues.id, data: values })).then(
      (val: any) => {
        setFormLoading(false);
        if (val?.payload?.data?.statusCode == 200) {
          setmodalVisible(false);
          message.success({
            content: "Contact Updated successfully",
            style: {
              marginBottom: "10vh",
            },
          });
          // dispatch(fetchContactList({ phone: calllog?.phone }));
          dispatch(fetchContactAndPatientByMobile(calllog?.phone))
        }
      }
    );
  };

  useEffect(() => {
    setselectedContactValues(cpCombined.length > 0 && cpCombined[0]);
  }, [cpCombined]);
  useEffect(() => {
    const prevTitle = document.title;
    const name = calllog?.contact
      ? `${formatDropDownValue(getFullName(calllog?.contact))} |`
      : "";
    const phone = calllog?.phone
      ? "+" + calllog?.country_code + " " + calllog?.phone
      : "";
    document.title = `${name} ${phone}`;
    return () => {
      document.title = prevTitle;
    };
  }, []);
  useEffect(() => {
    if (linkedState) {
      setlinkButtonDisabled(true);
    }
  }, [linkedState]);


  const onSaveContactFailed = (errorInfo: any) => {
    //console.log('Failed:', errorInfo);
  };

  const onSaveRemarks = (values: any) => {
    // setFormLoading(true);
    emitParent({
      action: "patch_calllog",
      details: { remarks: values.remarks },
    });
    showRemarksInput(false);
  };
  const onSaveRemarksFailed = (errorInfo: any) => {
    //console.log('Failed:', errorInfo);
  };
  const statusChange = (data: any) => {
    emitParent({
      action: "patch_calllog_status",
      details: { status_id: data },
    });
  };
  const onLinkTicketLinkContact = (data: any) => {
    if (data) {
      linkContact();
    }
  };

  return (
    <>
      <div className="">
        <Row className="full-width padding20">
          {!linkedState && (
            <Col span={24}>
              <h3 className="text-dark mb-0">
                Link Contact to proceed furthur
              </h3>
              <p>
                <small className="text-secondary">
                  {cpCombined.length} Contacts found with Phone no.{" "}
                  {calllog.phone}
                </small>
              </p>
              <Row gutter={16}>
                <Col flex="none">
                  <Select
                    className="contact-select"
                    style={{ width: 120 }}
                    onChange={handleChange}
                    allowClear={true}
                    defaultValue={
                      calllog?.contact_id ? calllog?.contact_id : ""
                    }
                    value={selectedContact}
                  >
                    {contactLists?.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {e.first_name ? e.first_name : ""}{" "}
                          {e.last_name ? e.last_name : ""}{" "}
                          {e.dob && (
                            <small className="text-secondary">
                              {dobToAge(e.dob) + "yrs"}
                            </small>
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col>
                  <Space>
                    <Button
                      disabled={!selectedContact}
                      className="text-primary wide-btn border-primary"
                      onClick={() => linkContact()}
                    >
                      Link
                    </Button>
                    {calllog.contact_id && (
                      <Button
                        className="wide-btn"
                        onClick={() => setlinkedState(true)}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      disabled={selectedContact}
                      className="text-primary wide-btn border-primary"
                      onClick={() => setmodalVisible(true)}
                    >
                      Add New Contact
                    </Button>
                  </Space>
                </Col>
                {calllog?.links?.length == 0 && (
                  <Col flex={"auto"} style={{ textAlign: "right" }}>
                    <Select
                      className="contact-select"
                      style={{ width: 120, textAlign: "left" }}
                      onChange={statusChange}
                      defaultValue={calllog?.status_id}
                    >
                      {statusList?.map((e: any) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name === "closed"
                              ? "RNR"
                              : formatDropDownValue(e.name)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                )}
              </Row>
              <Divider />
              <Row className="mb-20">
                <Col span={24}>
                  <p>Showing leads and tickets associated with </p>
                  <Row gutter={16}>
                    <Col>
                      <InputNumber
                        style={{ width: "200px" }}
                        defaultValue={calllog.phone}
                        onPressEnter={(e: any) => {
                          setPhone(e.target.value);
                        }}
                      ></InputNumber>
                    </Col>
                    <Col className="crm-form">
                      <Radio.Group
                        onChange={handleTabChange}
                        className="call-log-associated"
                        defaultValue={linkingTab}
                      >
                        <Space>
                          <Radio.Button value="associated_leads">
                            Associated Leads
                          </Radio.Button>
                          <Radio.Button value="tickets">Tickets</Radio.Button>
                        </Space>
                      </Radio.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginLeft: "-20px", marginRight: "-20px" }}>
                <Col span={24}>
                  {linkingTab == "associated_leads" && phone && (
                    <AssociatedLeads
                      linkedStates={linkedState}
                      phone={phone}
                      callLogDetails={calllog}
                      action="link_lead"
                    ></AssociatedLeads>
                  )}
                  {linkingTab == "tickets" && phone && (
                    <TicketsTab
                      linkedStates={linkedState}
                      phone={phone}
                      callLogDetails={calllog}
                      action="link_ticket"
                    ></TicketsTab>
                  )}
                </Col>
              </Row>
              {/* <Row align='middle' justify='center'>
                                <Col className='text-center' style={{ minHeight: `calc(100vh - 300px)`, alignItems: 'center', display: 'inline-flex' }}>
                                    <Row>
                                        <Col span={24}>
                                            <img width={'100px'} src={require("../../../../assets/icons/no result found.png").default}></img>
                                        </Col>
                                        <br /><br />
                                        <Col span={24}>
                                            <h1 className='text-secondary font18 paddingtop20'>Link contact to view leads or tickets</h1>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}
            </Col>
          )}
          {linkedState && (
            <Col span={24}>
              <h3 className="text-dark mb-0">Linked Contact</h3>
              <p>
                <small className="text-secondary">
                  {cpCombined?.length} Contacts found with Phone no.{" "}
                  {calllog.phone}
                </small>
              </p>
              <Row gutter={16}>
                <Col flex="none">
                  <Select
                    // suffixIcon={
                    //   <EditTwoTone
                    //     onClick={(e: any) => {
                    //       editContactCallLog(e);
                    //     }}
                    //   />
                    // }
                    allowClear
                    className="contact-select"
                    style={{ width: 120 }}
                    onChange={handleChange}
                    defaultValue={calllog?.contact_id}
                  // value={selectedContact}
                  >
                    {contactLists?.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {e.first_name ? e.first_name : ""}{" "}
                          {e.last_name ? e.last_name : ""}{" "}
                          {e.dob && (
                            <small className="text-secondary">
                              {dobToAge(e.dob) + "yrs"}
                            </small>
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col>
                  <Space>
                    <Button
                      disabled={linkButtonDisabled || (selectedContact === undefined)}
                      className="text-primary wide-btn border-primary"
                      onClick={() => linkContact()}
                    >
                      Link
                    </Button>
                    <Button
                      disabled={selectedContact}
                      className="text-primary wide-btn border-primary"
                      onClick={() => setmodalVisible(true)}
                    >
                      Add New Contact
                    </Button>
                  </Space>
                </Col>
                {calllog?.links?.length == 0 && (
                  <Col flex={"auto"} style={{ textAlign: "right" }}>
                    <Select
                      className="contact-select"
                      style={{ width: 120, textAlign: "left" }}
                      onChange={statusChange}
                      defaultValue={calllog?.status_id}
                    >
                      {statusList?.map((e: any) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name === "closed"
                              ? "RNR"
                              : formatDropDownValue(e.name)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                )}
                {/* <Col>
                                <Space>
                                    <Button onClick={()=>setlinkedState(false)} className='text-primary wide-btn'>Delink</Button>
                                </Space>
                            </Col> */}
              </Row>
              {!remarksInput && !calllog.remarks && (
                <Row className="paddingtop20">
                  <Col>
                    <a
                      onClick={() => showRemarksInput(true)}
                      className="underline cursor"
                    >
                      Add Remarks
                    </a>
                  </Col>
                </Row>
              )}
              {(remarksInput || calllog.remarks) && (
                <>
                  <Row className="paddingtop20">
                    <Col span={24}>Remarks</Col>
                  </Row>
                  <Form
                    name="remarks_input"
                    className="crm-form full-width"
                    onFinish={onSaveRemarks}
                    onFinishFailed={onSaveRemarksFailed}
                  >
                    <Row gutter={16}>
                      {!remarksInput && (
                        <>
                          <Col>
                            <Form.Item name={"remarks"} key="1">
                              <Input
                                placeholder="Enter remarks"
                                style={{ width: "200px" }}
                                disabled={true}
                                defaultValue={calllog.remarks}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item>
                              <Button
                                onClick={() => showRemarksInput(true)}
                                className="text-primary border-primary wide-btn"
                              >
                                Edit
                              </Button>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                      {remarksInput && (
                        <>
                          <Col flex={"auto"}>
                            <Form.Item name={"remarks"} key="1">
                              <Input
                                placeholder="Enter remarks"
                                defaultValue={calllog.remarks}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item>
                              <Button
                                loading={formLoading}
                                htmlType="submit"
                                className="text-primary border-primary wide-btn"
                              >
                                Save
                              </Button>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Form>
                </>
              )}
              <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />
              <Row className="mb-20">
                <Col span={24}>
                  <p>Showing leads and tickets associated with </p>
                  <Row gutter={16}>
                    <Col>
                      <InputNumber
                        style={{ width: "200px" }}
                        defaultValue={calllog.phone}
                        onPressEnter={(e: any) => {
                          setPhone(e.target.value);
                        }}
                      ></InputNumber>
                    </Col>
                    <Col className="crm-form">
                      <Radio.Group
                        onChange={handleTabChange}
                        className="call-log-associated"
                        defaultValue={linkingTab}
                      >
                        <Space>
                          <Radio.Button value="associated_leads">
                            Associated Leads
                          </Radio.Button>
                          <Radio.Button value="tickets">Tickets</Radio.Button>
                        </Space>
                      </Radio.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginLeft: "-20px", marginRight: "-20px" }}>
                <Col span={24}>
                  {linkingTab == "associated_leads" && phone && (
                    <AssociatedLeads
                      linkedStates={linkedState}
                      onLinkTicketLinkContact={(e: any) =>
                        onLinkTicketLinkContact(e)
                      }
                      phone={phone}
                      callLogDetails={calllog}
                      action="link_lead"
                    ></AssociatedLeads>
                  )}
                  {linkingTab == "tickets" && phone && (
                    <TicketsTab
                      linkedStates={linkedState}
                      onLinkTicketLinkContact={(e: any) =>
                        onLinkTicketLinkContact(e)
                      }
                      phone={phone}
                      callLogDetails={calllog}
                      action="link_ticket"
                    ></TicketsTab>
                  )}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        {modalVisible && (
          <CreateContactForm
            actionType={selectedContactValues ? "edit" : "freezephone"}
            editData={selectedContactValues}
            initialValues={{ phoneNumber: calllog.phone }}
            onSaveContact={(e: any) => onSaveContact(e)}
            onSaveEditContact={(e: any) => onSaveEditContact(e)}
            onSaveContactFailed={(e: any) => onSaveContactFailed(e)}
            modalVisible={modalVisible}
            setmodalVisible={(e: any) => setmodalVisible(e)}
            formLoading={formLoading}
            gender={gender}
            nationality={nationality}
            buttonText={"Create Contact & Link"}
          ></CreateContactForm>
        )}
      </div>
    </>
  );
}

export default CallLogsDetailsTab;
