import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchAccountByDomain,
  fetchApplications,
} from "../redux/reducers/auth.slice";
import { _BASE_URL } from "../shared/Utils/api-client";
import { RootState } from "../shared/constants";
import { ROUTE_CRM_LOGIN } from "../shared/routes/crm.routes.constants";

function LandingPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const [baseUrl, setBaseUrl] = useState(_BASE_URL);
  useEffect(() => {
    localStorage.clear();
    // !account &&
    dispatch(fetchAccountByDomain());
  }, []);
  useEffect(() => {
    account?.id && localStorage.setItem("account_id", account?.id);
    account?.slug && history.push(ROUTE_CRM_LOGIN(account?.slug));
    if (!applications && account?.id) {
      !applications && dispatch(fetchApplications());
    }
  }, [applications, account]);

  return (
    // <AccountRouteFetcher>
    //   <div>
    //     {t("genDict.landing")}
    //     <Divider />
    //     {applications.length > 0 &&
    //       applications.map((application: any) => {
    //         return (
    //           <Button
    //             onClick={async () => {
    //               await dispatch(
    //                 switchApplication({
    //                   slug: application.slug,
    //                   application_id: application.id,
    //                 })
    //               );
    //               history.push(ROUTE_LOGIN(account.slug, application.slug));
    //             }}
    //           >
    //             Go {application.name} Login
    //           </Button>
    //         );
    //       })}
    //   </div>

    //   <Divider />
    //   <Card>
    //     <Row>
    //       <Col flex={"auto"}>
    //         <Input
    //           value={baseUrl}
    //           onChange={(e) => setBaseUrl(e.target.value)}
    //         />
    //         <div>
    //           Sample = <small>https://medi.hlthclub.in/vault/v1/</small>
    //         </div>
    //       </Col>
    //       <Col>
    //         <Button
    //           onClick={() => {
    //             localStorage.setItem("baseUrl", baseUrl);
    //           }}
    //         >
    //           Update Base Url
    //         </Button>
    //       </Col>
    //     </Row>
    //   </Card>
    // </AccountRouteFetcher>
    <></>
  );
}

export default LandingPage;
