import { Route, Switch } from "react-router-dom";

import ActionMap from "../pages/crm/Actionmap/actionmap.page";
import Agents from "../pages/crm/Agents/agents.page";
import AssisnmentPage from "../pages/crm/Assignment/assignment.page";
import CRMBookAppointmentPage from "../pages/crm/BookServices/bookappointment.page";
import CRMOrderMedicinePage from "../pages/crm/BookServices/ordermedicine.page";
import CRMCallLogDetailsPage from "../pages/crm/Calllogs/calllogdetails.page";
import CRMLCallLogsList from "../pages/crm/Calllogs/calllogslist.page";
import CRMCreateCallLog from "../pages/crm/Calllogs/createcalllog.page";
import Chat from "../pages/crm/Chat/chat.page";
import ChatAdmin from "../pages/crm/Chat/chatadmin.page";
import CRMContactDetailsPage from "../pages/crm/Contacts/contactdetails.page";
import CRMContactsPage from "../pages/crm/Contacts/contacts.page";
import CRMDashboardPage from "../pages/crm/Dashboard/dashboard.page";
import ManageAudiencesPage from "../pages/crm/Facebook/Audience/audiences.page";
import FacebookPages from "../pages/crm/Facebook/Pages/facebook-pages.page";
import GoogleConnectPage from "../pages/crm/GoogleConnect/google-connect.page";
import CRMLeadDetailsPage from "../pages/crm/Leads/leaddetails.page";
import CRMLeadsList from "../pages/crm/Leads/leadslist.page";
import Metadata from "../pages/crm/Metadata/metadata.page";
import ChatBotPage from "../pages/crm/Rag/chat.page";
import KnowledgeBankDocumentListPage from "../pages/crm/Rag/knowledge-bank-document-list.page";
import KnowledgeBankListPage from "../pages/crm/Rag/knowledge-bank-list.page";
import SeedDataUpload from "../pages/crm/SeedUpload/seedFileUpload.page";
import SocialNewPostPage from "../pages/crm/SocialPosts/new-post.page";
import SocialProfilesPage from "../pages/crm/SocialProfiles/social-profiles.page";
import CRMTicketDetailsPage from "../pages/crm/Tickets/ticketdetails.page";
import CRMTicketListPage from "../pages/crm/Tickets/ticketslist.page";
import AddSLARules from "../shared/layouts/addslarules.page";
import CRMDesktopLayout from "../shared/layouts/crm-desktop.layout";
import SLARules from "../shared/layouts/slarules.page";
import {
  ROUTE_CRM_ACTIONMAP,
  ROUTE_CRM_ADMIN_CHAT,
  ROUTE_CRM_AGENTS,
  ROUTE_CRM_ASSIGNMENT,
  ROUTE_CRM_BOOK_APPOINTMENT,
  ROUTE_CRM_BOT_CHAT,
  ROUTE_CRM_CALL_LOGS,
  ROUTE_CRM_CALL_LOG_CREATE,
  ROUTE_CRM_CALL_LOG_DETAILS,
  ROUTE_CRM_CHAT,
  ROUTE_CRM_CONTACTS,
  ROUTE_CRM_CONTACTS_DETAILS,
  ROUTE_CRM_DASHBOARD,
  ROUTE_CRM_FACEBOOK_AUDIENCE_MANAGE,
  ROUTE_CRM_GOOGLE_CONNECT,
  ROUTE_CRM_KNOWLEDGE_BANK_DOCUMENTS,
  ROUTE_CRM_KNOWLEDGE_BANK_LIST,
  ROUTE_CRM_LANDING,
  ROUTE_CRM_LEADS_LIST,
  ROUTE_CRM_LEAD_DETAILS,
  ROUTE_CRM_LEAD_PAGES,
  ROUTE_CRM_MASTER_DATA_UPLOAD,
  ROUTE_CRM_METADATA,
  ROUTE_CRM_ORDER_MEDICINE,
  ROUTE_CRM_POST_NEW,
  ROUTE_CRM_SLARULES,
  ROUTE_CRM_SLARULES_ADD,
  ROUTE_CRM_SOCIAL_PROFILES,
  ROUTE_CRM_TICKET_DETAILS,
  ROUTE_CRM_TICKET_LIST,
} from "../shared/routes/crm.routes.constants";
const ROUTES: any[] = [
  {
    name: "Landing",
    route: ROUTE_CRM_LANDING,
    component: CRMTicketDetailsPage,
  },
  {
    name: "Dashboard",
    route: ROUTE_CRM_DASHBOARD,
    component: CRMDashboardPage,
  },
  {
    name: "Ticket List",
    route: ROUTE_CRM_TICKET_LIST,
    component: CRMTicketListPage,
  },
  {
    name: "Ticket Details",
    route: ROUTE_CRM_TICKET_DETAILS(":id"),
    component: CRMTicketDetailsPage,
  },
  {
    name: "Lead List",
    route: ROUTE_CRM_LEADS_LIST,
    component: CRMLeadsList,
  },
  {
    name: "Lead Details",
    route: ROUTE_CRM_LEAD_DETAILS(":id"),
    component: CRMLeadDetailsPage,
  },
  {
    name: "Contact",
    route: ROUTE_CRM_CONTACTS,
    component: CRMContactsPage,
  },
  {
    name: "Contact Details",
    route: ROUTE_CRM_CONTACTS_DETAILS(":id"),
    component: CRMContactDetailsPage,
  },
  {
    name: "GoogleConnect",
    route: ROUTE_CRM_GOOGLE_CONNECT,
    component: GoogleConnectPage,
  },
  {
    name: "Metadata",
    route: ROUTE_CRM_METADATA,
    component: Metadata,
  },
  {
    name: "Social Profiles",
    route: ROUTE_CRM_SOCIAL_PROFILES,
    component: SocialProfilesPage,
  },
  {
    name: "Social Post New",
    route: ROUTE_CRM_POST_NEW,
    component: SocialNewPostPage,
  },
  {
    name: "Assignment",
    route: ROUTE_CRM_ASSIGNMENT,
    component: AssisnmentPage,
  },
  {
    name: "ActionMap",
    route: ROUTE_CRM_ACTIONMAP,
    component: ActionMap,
  },
  {
    name: "SeedDataUpload",
    route: ROUTE_CRM_MASTER_DATA_UPLOAD,
    component: SeedDataUpload,
  },
  {
    name: "SLARules",
    route: ROUTE_CRM_SLARULES,
    component: SLARules,
  },
  {
    name: "AddSLARules",
    route: ROUTE_CRM_SLARULES_ADD,
    component: AddSLARules,
  },
  {
    name: "Chat",
    route: ROUTE_CRM_CHAT,
    component: Chat,
  },
  {
    name: "Admin Chat",
    route: ROUTE_CRM_ADMIN_CHAT,
    component: ChatAdmin,
  },
  {
    name: "Agents",
    route: ROUTE_CRM_AGENTS,
    component: Agents,
  },
  {
    name: "Call Log",
    route: ROUTE_CRM_CALL_LOGS,
    component: CRMLCallLogsList,
  },
  {
    name: "Call Log Details",
    route: ROUTE_CRM_CALL_LOG_DETAILS(":id"),
    component: CRMCallLogDetailsPage,
  },
  {
    name: "Book Appointment",
    route: ROUTE_CRM_BOOK_APPOINTMENT(":id"),
    component: CRMBookAppointmentPage,
  },
  {
    name: "",
    route: ROUTE_CRM_ORDER_MEDICINE(":id"),
    component: CRMOrderMedicinePage,
  },
  {
    name: "",
    route: ROUTE_CRM_CALL_LOG_CREATE,
    component: CRMCreateCallLog,
  },
  {
    name: "",
    route: ROUTE_CRM_FACEBOOK_AUDIENCE_MANAGE,
    component: ManageAudiencesPage,
  },
  {
    name: "ROUTE_CRM_LEAD_FORMS",
    route: ROUTE_CRM_LEAD_PAGES,
    component: FacebookPages,
  },
  {
    name: "ROUTE_CRM_KNOWLEDGE_BANK_LIST",
    route: ROUTE_CRM_KNOWLEDGE_BANK_LIST,
    component: KnowledgeBankListPage,
  },
  {
    name: "ROUTE_CRM_KNOWLEDGE_BANK_DOCUMENTS",
    route: ROUTE_CRM_KNOWLEDGE_BANK_DOCUMENTS(":bank_name", ":id"),
    component: KnowledgeBankDocumentListPage,
  },
  {
    name: "ROUTE_CRM_BOT_CHAT",
    route: ROUTE_CRM_BOT_CHAT,
    component: ChatBotPage,
  },
];

const CRMRoutes = () => {
  const RoutesPermitted = ROUTES;
  return (
    <CRMDesktopLayout>
      <Switch>
        {RoutesPermitted.map((route: any, index: number) => {
          return (
            <Route
              exact
              path={route.route}
              key={index}
              component={route.component}
            />
          );
        })}
      </Switch>
    </CRMDesktopLayout>
  );
};
export default CRMRoutes;
