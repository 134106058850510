import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Steps,
  Tag,
} from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAppointment } from "../../../../redux/reducers/appointment.slice";
import {
  addNewContact,
  createContactFromPatient,
  fetchContactAndPatientByMobile,
  fetchContactList,
} from "../../../../redux/reducers/contacts.slice";
import {
  fetchMasterGender,
  fetchMasterNationality,
  resetTaskTypesCascade,
} from "../../../../redux/reducers/master.slice";
import { createNewTicket } from "../../../../redux/reducers/tickets.slice";
import { TASK_MODULE_ACTIONS_CREATE_CONTACT } from "../../../../shared/components/permission.constants";
import {
  IS_JARVIS,
  RootState,
  TICKET_APPOINTMENT_ASSOCIATION,
} from "../../../../shared/constants";
import LinkDelink from "../../../../shared/layouts/link-delink.layout";
import NoDataLayout from "../../../../shared/layouts/no-data.layout";
import ResultModalLayout from "../../../../shared/layouts/resultmodal.layout";
import { ROUTE_CRM_TICKET_LIST } from "../../../../shared/routes/crm.routes.constants";
import { countryCodes } from "../../../../shared/Utils/dial-codes";
import {
  checkPhoneNumber,
  contactNameFormat,
  dobToAge,
  getAge,
} from "../../../../shared/Utils/utilities";
import TicketForm from "./ticket-form";
const { Step } = Steps;
const { Search } = Input;
const { Option } = Select;
type Props = {
  onTicketSave: (value: any) => void;
  setTicketFormVisible: (val: any) => void;
  phoneNumber?: string;
  defaultContactId?: string;
  ticketFormVisible: boolean;
  callLogId?: any;
};

const AddTicket: React.FunctionComponent<Props> = ({
  onTicketSave = () => { },
  ticketFormVisible = false,
  setTicketFormVisible,
  phoneNumber = "",
  defaultContactId = "",
  callLogId = null,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const history = useHistory();
  const { loading_cp, cpCombined } = useSelector(
    (state: RootState) => state.contact
  );
  const { loading_filtered, contactFiltered } = useSelector(
    (state: RootState) => state.contact
  );
  const { loading, appointments } = useSelector(
    (state: RootState) => state.appointment
  );
  const { gender, nationality } = useSelector(
    (state: RootState) => state.master
  );
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [_addNewContact, set_addNewContact] = useState(false);
  const [phone, setPhone] = useState(phoneNumber);
  const [selectedService, setSelectedService] = useState(null as any);
  const [contactId, setContactId] = useState(null as any);
  const [ticket, setTicket] = useState(false);
  const { profile } = useSelector((state: RootState) => state.profile);
  const { contacts } = useSelector((state: RootState) => state.contact);
  const [modalVisible, setModalVisible] = useState(false as any);
  const [ticketData, setTicketData] = useState(false as any);
  const [contactList, setContactList] = useState(false as any);
  const [countryCode, setCountryCode] = useState("+91");

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  useEffect(() => {
    gender?.length == 0 && dispatch(fetchMasterGender({}));
    nationality?.length == 0 && dispatch(fetchMasterNationality({}));
    return () => {
      dispatch(resetTaskTypesCascade());
    };
  }, []);
  console.log("ContactList", contacts);
  useEffect(() => {
    (async () => {
      if (phone) {
        await dispatch(fetchContactAndPatientByMobile(phone));
      } else if (IS_JARVIS) {
        await dispatch(fetchContactList());
      }
    })();
  }, [phone]);
  useEffect(() => {
    if (phone !== "") {
      setContactList(
        cpCombined.map((val: any) => {
          return { ...val, id: val.id ? val.id : val.patient_id };
        })
      );
      // setaddNewContactVar(false);
    }
  }, [cpCombined]);

  const validatePhone = (e: any) => {
    if (checkPhoneNumber(e.key)) {
      e.preventDefault();
    }
  };
  const onPhoneSearch = (value: any) => {
    if (value.length < 6 || value.length > 13) {
      message.error("Please provide valid phone number!");
      return;
    }
    setPhone(value);
  };
  const onPhoneChange = (event: any) => {
    let value = event.target.value;
    // setPhone(value);
  };
  useEffect(() => {
    if (nationality.length > 0) {
      form.setFieldsValue({
        nationality_id: nationality
          .filter((val: any) => {
            return val.name === "indian";
          })
          .map((val: any) => {
            return val.id;
          })
          .toString(),
      });
    }
  }, [nationality]);
  const handleContactChange = (value: any) => {
    if (value == "new") {
      set_addNewContact(true);
    } else {
      setContactId(value);
    }
  };
  useEffect(() => {
    if (contactId) {
      const contact = cpCombined.filter((val: any) => {
        return contactId == val.id;
      });

      contact?.length > 0 &&
        TICKET_APPOINTMENT_ASSOCIATION === true &&
        dispatch(
          fetchAppointment({
            patient_phone: phone,
            patient_country_code: contact[0]?.country_code
              ? contact[0]?.country_code
              : "+91",
          })
        );
    }
    set_addNewContact(false);
  }, [contactId]);
  const onNext = () => {
    setSelectedService(selectedService);
    setStep(1);
    setTicket(true);
    console.log(selectedService);
  };

  useEffect(() => {
    setCountryCode(
      cpCombined.length > 0 && cpCombined[0]?.country_code
        ? "+" + cpCombined[0]?.country_code
        : "+91"
    );
  }, [cpCombined]);

  const goBack = () => {
    setStep(0);
    setTicket(false);
  };

  const onSaveContact = (values: any) => {
    (async function () {
      try {
        values.dob = moment(values.dob).format();
        values.phone = phone;
        values.country_code = countryCode.replace("+", "");
        // values.country_code = "+91"
        const response = await dispatch(addNewContact(values));
        if (response.payload.status === 201) {
          dispatch(fetchContactAndPatientByMobile(phone));
          set_addNewContact(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const prefixSelector = (
    // <Form.Item name="country_code" noStyle initialValue={countryCode}>
    <Select
      showSearch
      // disabled
      style={{ width: 100 }}
      value={countryCode}
      onChange={(evt) => setCountryCode(evt)}
      className={"noborder"}
      defaultValue={countryCode}
    >
      {countryCodes.map((item) => {
        return (
          item.show === true && (
            <Select.Option value={item.dial_code}>
              <Space>
                <span>{item.flag}</span>
                <span>{item.dial_code}</span>
              </Space>
            </Select.Option>
          )
        );
      })}
    </Select>
  );

  const createTicket = async (values: any) => {
    let contact_id = contactId;
    const contactData = (contactList || contacts)?.filter((val: any) => {
      return val.id === contact_id;
    })[0];
    if (contactData?.source === "patient") {
      const res = await dispatch(
        createContactFromPatient({ patient_id: contact_id })
      );
      if (res?.payload?.data?.statusCode == 200) {
        contact_id = res?.payload?.data?.data?.id;
      } else {
        return;
      }
    }
    // debugger

    let data = {
      contact_id: contact_id,
      type_id: values.type_id,
      sub_type_id: values.sub_type_id,
      category_id: values.category_id,
      branch_id: selectedService?.branch_id
        ? selectedService?.branch_id
        : values.branch_id,
      department_id: null,
      source: callLogId !== null ? "call_logs" : null,
      source_id: values.source_id,
      status_id: values.status_id,
      follow_up_date: values.follow_up_date,
      follow_up_time: moment(values.follow_up_date).format("LT"),
      title: values.title,
      ticket_description: values.ticket_description,
      priority_id: values.priority_id,
      file_upload_ids: values?.file_upload_ids,
      due_date: values?.due_date?.format(),
      // assigned_user_id: values.assign_to == "agent" ? values.assignee : null,
      assigned_user_id:
        values.assign_to === "agent"
          ? values.assignee
          : values.assign_to === "self"
            ? profile?.id
            : null,
      assigned_team_id: values.assign_to === "team" ? values.assignee : null,
      services: [
        {
          ticket_id: selectedService?.ticket_id,
          type: "appointment",
          appointment_id: selectedService?.id,
          order_id: selectedService?.order_id,
          payment_id: selectedService?.payment_id,
          prescription_id: selectedService?.prescription_id,
          patient_id: selectedService?.patient_id,
          doctor_id: selectedService?.doctor_id,
        },
      ],
    } as any;
    if (values?.meta) {
      data.meta = values?.meta;
    }
    if (callLogId !== null) {
      data = { ...data, call_log_id: callLogId };
    }
    if (values?.doctor_rating) {
      data = {
        ...data,
        doctor_rating: values?.doctor_rating,
      };
    }
    if (values?.doctor_remarks) {
      data = {
        ...data,
        doctor_remarks: values?.doctor_remarks,
      };
    }
    dispatch(createNewTicket(data)).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        // message.success({
        //     content: 'Ticket created successfully',
        //     style: {
        //         marginBottom: '10vh',
        //     },
        // });
        setTicketData(val.payload.data.data);
        setModalVisible(true);
        // history.push(ROUTE_CRM_TICKET_DETAILS(val.payload.data.data.id))
      }
    });
  };
  const onCancelModal = () => {
    setTicketFormVisible(false);
    onTicketSave(false);
    setModalVisible(false);
    // history.push(ROUTE_CRM_TICKET_DETAILS(ticketData?.id));
    history.push(ROUTE_CRM_TICKET_LIST);
  };
  useEffect(() => {
    if (!loading_cp && phoneNumber !== "" && cpCombined.length > 0) {
      // if (cpCombined[0].phone == phone) {
      let setId = cpCombined[0].id;
      if (defaultContactId !== "") {
        let temp = cpCombined.filter((val: any) => {
          return defaultContactId == val.id;
        });
        if (temp.length > 0) {
          setId = temp[0].id;
        }
      }
      setContactId(setId);
      // }
    }
    if (cpCombined.length == 0) {
      setContactId(null);
    }
  }, [cpCombined, loading_cp]);
  function setService(item: any) {
    if (selectedService?.id == item?.id) {
      setSelectedService(null);
    } else {
      setSelectedService(item);
    }
  }
  return (
    <Fragment>
      <Modal
        className="modalFormButton form-modal crm-modal"
        style={{ paddingBottom: "0px" }}
        footer={false}
        visible={ticketFormVisible}
        width={"1000px"}
        centered
        title={t("CRM.Common.Actions.Create Ticket")}
        closable
        onCancel={() => setTicketFormVisible(false)}
      >
        <Row className="bg-white cover mx-10  create-ticket-form" gutter={32}>
          <Col span={16}>
            <Steps current={step}>
              <Step
                title="Step 1"
                // description={ACCOUNT_PREFIX === "lupinconnect" ? "Doctor Details" : "Patient Details"}
                description={t("CRM.Tickets.Patient Details")}
                className="cursor"
                onClick={() => {
                  goBack();
                }}
              />
              <Step
                title="Step 2"
                description={`${t("CRM.Tickets.Fields.Ticket")} Details`}
              />
            </Steps>
          </Col>
          <Divider></Divider>
          {!ticket ? (
            <Fragment>
              <Col span={24}>
                <h4 className="text-dark">
                  Choose {`${t("CRM.Contacts.contact")}`}
                </h4>
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}>
                <label>Phone Number</label> <br />
                <Search
                  placeholder="input search text"
                  defaultValue={phone}
                  onSearch={onPhoneSearch}
                  maxLength={12}
                  onChange={onPhoneChange}
                  addonBefore={prefixSelector}
                  onKeyPress={validatePhone}
                />
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}>
                <label>{`${t("CRM.Contacts.contact")}`} Name</label> <br />
                <Select
                  value={contactId}
                  className={"full-width"}
                  onChange={handleContactChange}
                  placeholder={"Choose Contact"}
                  allowClear={true}
                >
                  {(contactList || contacts)?.map((e: any) => {
                    return (
                      <Option key={e?.id} value={e?.id}>
                        {e?.first_name} {e?.last_name}{" "}
                        {/* {e?.dob || e?.gender ? "(" : ""} 
                          {e?.dob && (
                            <small className="text-secondary">
                              {dobToAge(e?.dob) + "yrs, "}
                            </small>
                          )}
                          {e?.gender && (
                            <small className="text-secondary">
                              {contactList?.length ? e?.gender?.split("")[0]?.toUpperCase() : e?.gender?.name?.split("")[0]?.toUpperCase()}
                            </small>
                          )}
                          {e?.dob || e?.gender ? ")" : ""} */}
                        {`${e?.birth_year || e?.dob || e?.gender ? "(" : ""}
                             ${e?.birth_year
                            ? getAge(e?.birth_year) + "yrs,"
                            : e?.dob
                              ? dobToAge(e?.dob) + "yrs,"
                              : ""
                          } 
                            ${contactList
                            ? e?.gender
                              ? e?.gender?.split("")[0].toUpperCase()
                              : ""
                            : e?.gender?.name
                              ? e?.gender?.name?.split("")[0].toUpperCase()
                              : ""
                          }
                              ${e?.birth_year || e?.dob || e?.gender ? ")" : ""}
                              `}
                      </Option>
                    );
                  })}
                  {myPermissions?.indexOf("task_module") !== -1 ||
                    myPermissions?.indexOf(TASK_MODULE_ACTIONS_CREATE_CONTACT) !==
                    -1 ? (
                    <Option key="new" value="new">
                      Add New Contact
                    </Option>
                  ) : (
                    ""
                  )}
                </Select>
              </Col>
              <Col span={24}>
                {_addNewContact && (
                  <Form
                    name={"contact_form"}
                    form={form}
                    layout={"vertical"}
                    onFinish={(values: any) => onSaveContact(values)}
                  >
                    <Row className="mt20 full-width">
                      <Col>
                        <h4 className="text-dark">Add Contact Details</h4>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                        <Form.Item
                          name={"first_name"}
                          label="First Name"
                          required
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                        <Form.Item
                          name={"last_name"}
                          label="Last Name"
                          required
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                        <Form.Item name={"gender_id"} label="Gender">
                          <Select>
                            {gender.map((e: any) => {
                              return (
                                <Option key={e.id} value={e.id}>
                                  {e.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                        <Form.Item name={"email_id"} label="Email ID">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                        <Form.Item name={"dob"} label="D.O.B.">
                          <DatePicker
                            disabledDate={(d: any) => {
                              return d > moment(moment().format("YYYY-MM-DD"));
                            }}
                            format="DD MMM'YY"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                        <Form.Item name={"nationality_id"} label={t("CRM.Common.Fields.Nationality")}>
                          <Select>
                            {nationality.map((e: any) => {
                              return (
                                <Option key={e.id} value={e.id}>
                                  {e.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        {" "}
                        <Button
                          className={"border-radius5 doctor-border-button"}
                          style={{ marginRight: "10px" }}
                          htmlType={"submit"}
                        >
                          Save Contact
                        </Button>
                        {/* <Button className={"border-radius5"} onClick={() => { set_addNewContact(false); onPhoneSearch(phone) }}>Cancel</Button>  */}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Col>
              <Divider></Divider>
              {!_addNewContact && (
                <>
                  {/* <Row className={"padding10 full-width"}> */}
                  {TICKET_APPOINTMENT_ASSOCIATION === true && (
                    <>
                      <Row className="full-width nomrg" gutter={32}>
                        <Col flex={"auto"}>
                          <h4 className="text-dark mt10">
                            Link This Ticket With A Service
                          </h4>
                        </Col>
                        <Col flex={"none"} placeholder={"Choose Service"}>
                          <Select
                            defaultValue={"Appointment"}
                            style={{ width: "150px" }}
                          >
                            <Option value={"Appointment"}>Appointment</Option>
                          </Select>
                        </Col>
                      </Row>
                      <Row className="full-width nomrg" gutter={32}>
                        <Col
                          span={24}
                          style={{
                            height: "250px",
                            overflowY: "scroll",
                            padding: "5px",
                          }}
                        >
                          {loading ? (
                            <Skeleton></Skeleton>
                          ) : appointments.length === 0 || !contactId ? (
                            <NoDataLayout
                              placeholder={"No services found"}
                              className={"mt50"}
                            ></NoDataLayout>
                          ) : (
                            <LinkDelink
                              data={appointments}
                              selected={selectedService}
                              setSelectedService={(value: any) =>
                                setService(value)
                              }
                            ></LinkDelink>
                          )}
                        </Col>
                      </Row>{" "}
                    </>
                  )}
                  <Row className="button-row" gutter={32}>
                    <Col span={24}>
                      <Button
                        className={"doctor-border-button"}
                        onClick={() => onNext()}
                        block
                        disabled={contactId == null ? true : false}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Row className="full-width padding10">
                {selectedService && (
                  <Col
                    className="grey-background padding10 border-radius5"
                    span={24}
                  >
                    <p className="mb-10">
                      <b>Service Details</b>
                    </p>
                    <Row>
                      <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                        <p className="capitalize">
                          <b>{selectedService?.doctor?.name}</b> (
                          {selectedService?.branch?.name}) <br />{" "}
                          <span className={"sub-title"}>
                            Appointment |{" "}
                            {moment(selectedService.appointment_date).format(
                              "Do, MMMM YYYY"
                            )}
                          </span>
                        </p>
                      </Col>
                      <Col span={4} className={"mt10"}>
                        <Tag color="blue">{selectedService?.latest_status}</Tag>
                      </Col>
                      <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                        <p>
                          <span className={"sub-title"}>Patient </span>
                          <br />{" "}
                          <span>
                            <b>
                              {contactNameFormat(
                                selectedService?.patient?.pii?.first_name,
                                selectedService?.patient?.pii?.last_name,
                                selectedService?.patient?.genderMaster?.name,
                                selectedService?.patient?.dob
                              )}
                            </b>
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col className="mt20" span={24}>
                  <p>
                    <b>{`${t("CRM.Tickets.Fields.Ticket")} Details`}</b>
                  </p>
                  {/* 1: Create 2: Edit */}
                  <TicketForm
                    selectedService={selectedService}
                    createTicket={createTicket}
                    type={1}
                  ></TicketForm>
                </Col>
              </Row>
            </Fragment>
          )}
        </Row>
      </Modal>
      {modalVisible && (
        <ResultModalLayout
          type="success"
          modalVisible={modalVisible}
          message=""
          setModalVisible={onCancelModal}
          title={"Ticket #" + ticketData?.display_id + " created"}
          subtitle=""
        />
      )}
    </Fragment>
  );
};
export default AddTicket;
